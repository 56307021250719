import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  TableCell,
  TableRow,
  TableContainer,
  Table,
  TableHead,
  Paper,
  TableBody,
  Chip,
} from "@mui/material";
import { Link } from "react-router-dom";
import LoadingComp from "../../components/LoadingComp";
import { useState } from "react";
import ArrowButton from "../components/ArrowButton";
import {
  getAllDealCategoryFilterAction,
  getAllSdgsFilterAction,
} from "../private-routes/PrivateActions";

const amountRanges = [
  { amount: "1", min: 100000, max: 500000, label: "1 to 5 Lakhs" },
  { amount: "2", min: 500000, max: 1000000, label: "5 to 10 Lakhs" },
  { amount: "3", min: 1000000, max: 3000000, label: "10 to 30 Lakhs" },
  { amount: "4", min: 3000000, max: 5000000, label: "30 to 50 Lakhs" },
  { amount: "5", min: 5000000, max: 10000000, label: "50 to 1 Crore" },
  { amount: "6", min: 10000000, max: 50000000, label: "1 to 5 Crore" },
  { amount: "7", min: 50000000, max: 100000000, label: "5 to 10 Crore" },
  { amount: "8", min: 100000000, max: Infinity, label: "10 Crore And Above" },
];

const InvestorDetailKyc = () => {
  const [memberInvestorKYCValue, setMemberInvestorKYCValue] = useState(
    JSON.parse(localStorage.getItem("memberInvestorKYC")) || {}
  );

  const { singleMemberProfileSide, loading } = useSelector(
    (state) => state.getSingleMemberProfileside
  );

  const [sectorList, setSectorList] = useState([]);
  const [sdgList, setSdgList] = useState([]);

  const dispatch = useDispatch();

  const { allSdgsFilter } = useSelector((state) => state.getAllSdgsFilterList);

  const { allDealsCategoryFilter } = useSelector(
    (state) => state.getAllDealCategoryFilter
  );

  useEffect(() => {
    if (!allSdgsFilter) {
      dispatch(getAllSdgsFilterAction());
    }
    if (allSdgsFilter) {
      setSdgList(allSdgsFilter?.data);
    }
  }, [allSdgsFilter, dispatch]);

  useEffect(() => {
    if (!allDealsCategoryFilter) {
      dispatch(getAllDealCategoryFilterAction());
    }
    if (allDealsCategoryFilter) {
      setSectorList(allDealsCategoryFilter?.data);
    }
  }, [allDealsCategoryFilter, dispatch]);

  useEffect(() => {
    dispatch(getAllSdgsFilterAction());
    dispatch(getAllDealCategoryFilterAction());
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    localStorage.setItem(
      "memberInvestorKYC",
      JSON.stringify(memberInvestorKYCValue)
    );
  }, [memberInvestorKYCValue]);

  useEffect(() => {
    if (singleMemberProfileSide) {
      setMemberInvestorKYCValue(singleMemberProfileSide?.data?.investor_kyc);
    }
  }, [singleMemberProfileSide, setMemberInvestorKYCValue]);

  const sdgNames = sdgList
    ?.filter((sdg) => memberInvestorKYCValue?.sdgs?.includes(sdg.sdg_id))
    ?.map((sdg) => <Chip className="me-1 mb-2" label={sdg?.sdg_name} />);

  const sectorName = sectorList
    ?.filter((sector) =>
      memberInvestorKYCValue?.invest_in?.includes(sector.deal_category_id)
    )
    ?.map((sector) => (
      <Chip className="me-1 mb-2" label={sector.deal_cat_name} />
    ));

  const amountName = amountRanges
    ?.filter((amount) =>
      memberInvestorKYCValue?.investment_size?.includes(amount.amount)
    )
    ?.map((amount) => <Chip className="me-1 mb-2" label={amount.label} />);

  const handlePrint = () => {
    var divToPrint = document.getElementById("printTable");
    var newWin = window.open("");
    newWin.document.write(divToPrint.outerHTML);
    newWin.print();
    newWin.close();
  };

  return (
    <>
      <div className="page-title">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h1>Investor KYC Detail</h1>
              <Link className="link" to="/">
                Home
              </Link>{" "}
              /{" "}
              <Link className="link" to="/dashboard">
                Dashboard
              </Link>{" "}
              / Investor KYC Detail
            </div>
          </div>
        </div>
      </div>
      <ArrowButton />

      {loading ? (
        <LoadingComp />
      ) : (
        <>
          <div className="page-content">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <p>
                    If you are an investor looking for deals, please fill the
                    form below to show your interest in investing through our
                    platform. Our team at Million Deals will reach out to you to
                    take this forward.
                  </p>

                  <p>
                    Have any questions? Please contact us at:&nbsp;
                    <Link
                      style={{ textDecoration: "none" }}
                      onClick={() =>
                        (window.location = "mailto:info@milliondeals.com.np")
                      }
                    >
                      info@milliondeals.com.np
                    </Link>
                  </p>
                  <>
                    <div id="printTable">
                      <TableContainer
                        component={Paper}
                        sx={{ maxHeight: "100%" }}
                      >
                        <Table
                          stickyHeader
                          aria-label="sticky table"
                          className="table-style "
                        >
                          <TableHead style={{ height: "50px" }}>
                            <TableRow
                              sx={{
                                "& :hover": {
                                  color: "white !important",
                                },
                                "& :focus:not(:focus-visible)": {
                                  color: "white !important",
                                },
                              }}
                            >
                              <TableCell
                                style={{
                                  backgroundColor: "#0b6885",
                                  padding: "0.5vw",
                                  letterSpacing: "0.5px",
                                  fontSize: "16px",
                                  fontWeight: "600",
                                  color: "white",
                                }}
                              >
                                TITLE
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  backgroundColor: "#0b6885",
                                  padding: "0.5vw",
                                  letterSpacing: "0.5px",
                                  fontSize: "16px",
                                  fontWeight: "600",
                                  color: "white",
                                }}
                              >
                                DESCRIPTION
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Full Name
                              </TableCell>
                              <TableCell align="left">
                                {memberInvestorKYCValue.name}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Contact Number
                              </TableCell>
                              <TableCell align="left">
                                {memberInvestorKYCValue.phone}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Email Address
                              </TableCell>
                              <TableCell align="left">
                                {memberInvestorKYCValue.email}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Linkedin profile or other online profile
                              </TableCell>
                              <TableCell align="left">
                                {
                                  memberInvestorKYCValue.linkin_or_online_profile
                                }
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Contact Address
                              </TableCell>
                              <TableCell align="left">
                                {memberInvestorKYCValue.contact_address}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Brief information about their profile and
                                background
                              </TableCell>
                              <TableCell align="left">
                                {memberInvestorKYCValue.bio}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Individual Investor or Institutional Investor
                              </TableCell>
                              <TableCell align="left">
                                {memberInvestorKYCValue.ind_or_ins_investor}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Role in company
                              </TableCell>
                              <TableCell align="left">
                                {memberInvestorKYCValue.company_role}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Company or Organization Associated with
                              </TableCell>
                              <TableCell align="left">
                                {memberInvestorKYCValue.company_name}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Company website or LinkedIn Page link
                              </TableCell>
                              <TableCell align="left">
                                {memberInvestorKYCValue.company_website}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Brief about company's profile and background
                              </TableCell>
                              <TableCell align="left">
                                {memberInvestorKYCValue.company_bio}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Describes the best
                              </TableCell>
                              <TableCell align="left">
                                {memberInvestorKYCValue.describes_your_best}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Preferred Investment Size
                              </TableCell>
                              <TableCell align="left">{amountName}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Preferred Sector to Invest
                              </TableCell>
                              <TableCell align="left">{sectorName}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Wish to have a sustainability or impact focus in
                                investments
                              </TableCell>
                              <TableCell align="left">
                                {memberInvestorKYCValue.is_sustainability
                                  ? "Yes"
                                  : "No"}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                SDGS contribution through investments
                              </TableCell>
                              <TableCell align="left">{sdgNames}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Preferred stage of investment
                              </TableCell>
                              <TableCell align="left">
                                {memberInvestorKYCValue.stage_of_investment ===
                                "1"
                                  ? "Idea Stage"
                                  : ""}
                                {memberInvestorKYCValue.stage_of_investment ===
                                "2"
                                  ? "Growth Stage"
                                  : ""}
                                {memberInvestorKYCValue.stage_of_investment ===
                                "3"
                                  ? "Neutral"
                                  : ""}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Terms Confirmation
                              </TableCell>
                              <TableCell align="left">
                                {memberInvestorKYCValue.terms_confirmation
                                  ? "Yes"
                                  : "No"}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        paddingTop: "5px",
                        marginTop: "5px",
                      }}
                    >
                      <Button
                        variant="contained"
                        style={{
                          margin: "10px 0 0 10px",
                          backgroundColor: "#ed5e5e",
                        }}
                        onClick={handlePrint}
                      >
                        Print
                      </Button>
                    </div>
                  </>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default InvestorDetailKyc;

import { API_URL, tokenConfig, axiosAdminPrivate } from "../../api/axios";
import {
  DELETE_NEW_DEALS_FAIL,
  DELETE_NEW_DEALS_REQUEST,
  DELETE_NEW_DEALS_SUCCESS,
  GET_DETAILED_DEALS_FAIL,
  GET_DETAILED_DEALS_REQUEST,
  GET_DETAILED_DEALS_SUCCESS,
  GET_FILTER_DEALS_FAIL,
  GET_FILTER_DEALS_REQUEST,
  GET_FILTER_DEALS_SUCCESS,
  POST_NEW_DEALS_FAIL,
  POST_NEW_DEALS_REQUEST,
  POST_NEW_DEALS_SUCCESS,
  PUT_NEW_DEALS_FAIL,
  PUT_NEW_DEALS_REQUEST,
  PUT_NEW_DEALS_SUCCESS,
} from "./ManageDealsConstants";

export const getFilterDealsAction =
  (category, limit, page, province, amount) => async (dispatch) => {
    try {
      dispatch({ type: GET_FILTER_DEALS_REQUEST });

      const { data } = await axiosAdminPrivate.get(
        `${API_URL}/api/v1/admin/deals?categories=${category}&limit=${limit}&page=${page}&province=${province}&amount_range=${amount}`
      );

      dispatch({ type: GET_FILTER_DEALS_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: GET_FILTER_DEALS_FAIL,
        payload: error.message ? error.message : error.Message,
      });
    }
  };

export const getDetailDealsAction = (deal_id) => async (dispatch) => {
  try {
    dispatch({ type: GET_DETAILED_DEALS_REQUEST });

    const { data } = await axiosAdminPrivate.get(
      `${API_URL}/api/v1/admin/deals/${deal_id}`
    );

    dispatch({ type: GET_DETAILED_DEALS_SUCCESS, payload: data });
    return data;
  } catch (error) {
    dispatch({
      type: GET_DETAILED_DEALS_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

export const postNewDealsAction = (newDeal) => async (dispatch) => {
  try {
    dispatch({ type: POST_NEW_DEALS_REQUEST });

    const jsonData = JSON.stringify(newDeal);

    const { data } = await axiosAdminPrivate.post(
      `${API_URL}/api/v1/admin/deals`,
      jsonData,
      tokenConfig()
    );

    dispatch({ type: POST_NEW_DEALS_SUCCESS, payload: data });
    return data;
  } catch (error) {
    dispatch({
      type: POST_NEW_DEALS_FAIL,
      payload: error.messsage ? error.message : error.Message,
    });
  }
};

export const putDealsAction = (updateDeal, unique_id) => async (dispatch) => {
  try {
    dispatch({ type: PUT_NEW_DEALS_REQUEST });

    const jsonData = JSON.stringify(updateDeal);

    const { data } = await axiosAdminPrivate.put(
      `${API_URL}/api/v1/admin/deals/${unique_id}`,
      jsonData,
      tokenConfig()
    );

    dispatch({ type: PUT_NEW_DEALS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: PUT_NEW_DEALS_FAIL,
      payload: error.messsage ? error.message : error.Message,
    });
  }
};

export const deleteDealsAction = (unique_id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_NEW_DEALS_REQUEST });

    const { data } = await axiosAdminPrivate.delete(
      `${API_URL}/api/v1/admin/deals/${unique_id}`,
      tokenConfig()
    );

    dispatch({ type: DELETE_NEW_DEALS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: DELETE_NEW_DEALS_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

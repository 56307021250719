import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import ArrowButton from "../../components/ArrowButton";
import DashboardSideMenu from "../../components/DashboardSideMenu";
import { useState } from "react";
import { useEffect } from "react";
import {
  getDetailedPersonalInfoAction,
  getProfileAsDealsByIdAction,
} from "../../private-routes/PrivateActions";
import { getSingleDealsPublicAction } from "../../public-routes/PublicRoutesActions";
import LoadingComp from "../../../components/LoadingComp";
import { Button } from "@mui/material";

const provinceList = [
  {
    id: 1,
    label: "Province 1",
  },
  {
    id: 2,
    label: "Madhesh Pradesh",
  },
  {
    id: 3,
    label: "Bagmati Pradesh",
  },
  { id: 4, label: "Gandaki Pradesh" },
  {
    id: 5,
    label: "Lumbini Pradesh",
  },
  {
    id: 6,
    label: "Karnali Pradesh",
  },
  { id: 7, label: "Sudurpaschim Pradesh" },
];

const MemberDealsTab = () => {
  const [singleDeals, setSingleDeals] = useState("");
  const [profilesId, setProfilesId] = useState([]);
  const [dealsList, setDealsList] = useState([]);

  const { profileAsDealsById, loading } = useSelector(
    (state) => state.getProfileAsDealsById
  );

  const { detailedPersonalInfo } = useSelector(
    (state) => state.getDetailedPersonalInfo
  );

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!detailedPersonalInfo) {
      dispatch(getDetailedPersonalInfoAction());
    } else {
      setProfilesId(detailedPersonalInfo?.data[0].member_id);
    }
  }, [detailedPersonalInfo, dispatch]);

  useEffect(() => {
    if (profilesId) {
      dispatch(getProfileAsDealsByIdAction(profilesId));
    }
  }, [dispatch, profilesId]);

  useEffect(() => {
    dispatch(getDetailedPersonalInfoAction());
  }, [dispatch]);

  useEffect(() => {
    if (profileAsDealsById) {
      setDealsList(profileAsDealsById?.data);
    }
  }, [profileAsDealsById]);

  const singleDealsView = (id) => {
    dispatch(getSingleDealsPublicAction(id)).then((res) => {
      setSingleDeals(res?.data);
      localStorage.setItem("singleDealsClient", JSON.stringify(res?.data));
      navigate("/deals-listing/single-deals", {
        state: { singleDeals: res?.data },
      });
    });
  };

  const handleAddDeals = () => {
    navigate("/enterprise_kyc");
  };

  const filteredDealsListing = dealsList?.filter(
    (value) => value.deal_id !== null
  );

  return (
    <>
      <div className="page-title">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h1>Dashboard</h1>
              <Link className="link" to="/">
                Home
              </Link>{" "}
              {""} /{""}
              <Link className="link" to="/dashboard">
                {" "}
                Dashboard
              </Link>{" "}
              {""} / Deals
            </div>
          </div>
        </div>
      </div>
      <ArrowButton />
      <div className="container pt-5 pb-5 mb-5">
        <div className="row">
          <div className="col-md-3">
            <DashboardSideMenu />
          </div>
          <div className="col-md-9">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h3>Profile as Deal</h3> &nbsp;{" "}
              {dealsList.length > 0 && (
                <Button
                  onClick={handleAddDeals}
                  className="submit-btn"
                  variant="contained"
                  style={{
                    marginTop: "5px",
                    backgroundColor: "#0b6885",
                    color: "#ffffff",
                  }}
                >
                  Add Deals
                </Button>
              )}
            </div>
            {loading ? (
              <LoadingComp />
            ) : (
              <>
                <div className="row">
                  {filteredDealsListing.map((option) => (
                    <div className="col-md-4" key={option.deal_id}>
                      <div className="deal-single">
                        <h2 style={{ cursor: "pointer" }}>
                          {option.deal_title?.slice(0, 20) + "..."}
                        </h2>
                        <font className="blue-color">
                          <i className="bi bi-geo-alt-fill"></i>
                        </font>{" "}
                        {
                          provinceList.find(
                            (province) =>
                              province.id === option?.company_operate
                          )?.label
                        }
                        <br />
                        <font className="blue-color">
                          <i className="bi bi-stack"></i>
                        </font>{" "}
                        {option.deal_category}
                        <h3>Investment Range</h3>
                        {option.amount}
                        <br />
                        <br />
                        <button
                          style={{ cursor: "pointer" }}
                          onClick={() => singleDealsView(option.deal_id)}
                          className="dark-btn link"
                        >
                          More Information
                        </button>
                      </div>
                    </div>
                  ))}
                </div>

                {/* <div className="col-md-12 justify-content-center">
                  <p style={{ color: "#f94848" }}>*No Record Found</p>
                </div> */}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MemberDealsTab;

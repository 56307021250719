import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ArrowButton from "../components/ArrowButton";
import "../../styles/style.css";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

const TermsAndCondition = ({ formData, setFormData, formErrors }) => {
  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="description" content="" />
        <meta name="author" content="" />

        <title>Million Deals</title>
        {/* <!-- Linking the font Poppins--> */}
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />

        {/* <!-- Linking Bootstrap Icon--> */}
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css"
        />
      </Helmet>
      <ArrowButton />

      <div className="page-title">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h1>Investor Form</h1>
              <Link className="link" to="/">
                Home
              </Link>{" "}
              /{" "}
              <Link className="link" to="/dashboard">
                Dashboard
              </Link>{" "}
              / Investor Form
            </div>
          </div>
        </div>
      </div>

      <div className="page-content">
        <div className="container">
          <div className="row">
            <div className="col-md-9">
              <div className="invest-form">
                <form>
                  <b>Agree To Terms</b>
                  <br />I hereby declare that all the information provided and
                  furnished above is true, complete and correct to best of my
                  knowledge. I understand that in the event of my information
                  being found false or incorrect at any stage, I shall be liable
                  and Million Deals will have the authority cancel/ terminate
                  without notice. I would also like to acknowledge that any
                  investments, if ever is made by me or the entity I am
                  associated with on the Enterprises introduced by Million
                  Deals, it will be based on my own judgement.
                  <br />
                  {formErrors.terms_confirmation && (
                    <span className="error">
                      {formErrors.terms_confirmation}
                    </span>
                  )}
                  <div className="form-check">
                    <RadioGroup
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 20,
                        },
                      }}
                      name="terms_confirmation"
                      onChange={handleInputChange}
                    >
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsAndCondition;

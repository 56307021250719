import { LoadingButton } from "@mui/lab";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { API_URL, axiosPublic } from "../../api/axios";
import { Link } from "react-router-dom";
import { Box, Fade, Modal } from "@mui/material";
import LegalDocumentsApproval from "./LegalDocumentsApproval";

const style = {
  position: "absolute",
  maxHeight: "90%",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "27px",
  boxShadow: 24,
  p: 4,
};

const SignUp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const [openTerms, setOpenTerms] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleOpen = () => setOpenTerms(true);
  const handleClose = () => setOpenTerms(false);

  const handleRegister = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (email?.length === 0) {
        toast.error("Email is required!");
        return;
      }
      if (password !== confirmPassword) {
        toast.error("Passwords do not match");
        return;
      }

      if (isChecked) {
        const jsonData = JSON.stringify({ email, password });
        const response = await axiosPublic.post(
          `${API_URL}/api/v1/members/auth/register`,
          jsonData,
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        );

        if (response?.status === 200) {
          toast.success(response?.data?.message);
          await new Promise((resolve) => setTimeout(resolve, 1000)); // add a delay to allow the toast to show up
          window.location.replace("/login");
        }
      } else {
        toast.error("Accept all the Terms & Condition for further process");
      }
    } catch (err) {
      if (!err?.response) {
        toast.error("No Server Response");
      } else if (err.response?.status === 422) {
        const errorMessages = err?.response?.data?.errors.map((error) => (
          <li key={error.msg}>{error.msg}</li>
        ));
        toast.error(<ul>{errorMessages}</ul>);
      } else {
        toast.error("Registration Failed");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div
        className="tab-pane fade show"
        id="signup-div"
        role="tabpanel"
        aria-labelledby="signup-tab"
      >
        <div className="userlog-div">
          <form onSubmit={handleRegister}>
            Email Address
            <br />
            <input
              type="email"
              id="email"
              name="email"
              autoComplete="off"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            Password
            <input
              type="password"
              id="password"
              name="password"
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="off"
              value={password}
            />
            Confirm password
            <input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              onChange={(e) => setConfirmPassword(e.target.value)}
              autoComplete="off"
              value={confirmPassword}
            />
            <label style={{ display: "flex" }}>
              <input
                style={{ height: "16px", width: "16px" }}
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <span style={{ paddingLeft: "10px" }}>
                I agree to the{" "}
                <Link
                  style={{ textDecoration: "none", color: "#0B6885" }}
                  onClick={handleOpen}
                >
                  Million Deals Terms
                </Link>
              </span>
            </label>
            <LoadingButton
              loading={loading}
              loadingPosition="end"
              variant="contained"
              style={{
                marginTop: "5px",
                width: "100%",
                backgroundColor: "#0b6885",
                color: "#ffffff",
              }}
              type="submit"
              value="Sign Up"
              className="submit-btn"
            >
              Sign Up
            </LoadingButton>
            {""}
          </form>
        </div>
        <div className="modal-footer">
          <div className="text-center">
            Connect with more than 5,000+ businesses and more than 500 investors
          </div>
        </div>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openTerms}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={openTerms}>
          <Box sx={style}>
            <LegalDocumentsApproval handleClose={handleClose} />
          </Box>
        </Fade>
      </Modal>
    </>
  );
};
export default SignUp;

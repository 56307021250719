import React, { useEffect, useState } from "react";
import { Button, Grid, MenuItem, OutlinedInput, Select } from "@mui/material";
import { Form } from "../../components/hooks/useForm";
import { useDispatch, useSelector } from "react-redux";
import CheckBoxControl from "../../components/controls/CheckBoxControl";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { postSuccessStoriesAction } from "./SuccessStoriesActions";
import { getAllPartnerAction } from "../about-us/partners/PartnersActions";
import { API_URL } from "../../api/axios";
import { ToastContainer, toast } from "react-toastify";

const SuccessStoriesForm = ({ singleSuccessStories, setOpenPopup }) => {
  const [partnerName, setPartnerName] = useState([]);
  const [partnerId, setPartnerId] = useState("Client Stories");
  const [ssName, setSSName] = useState("");
  const [ssMessage, setSSMessage] = useState("");
  const [ssImage, setSSImage] = useState(null);
  const [imgSrc, setImgSrc] = useState(null);
  const [status, setStatus] = useState(0);

  const dispatch = useDispatch();

  const { allPartner } = useSelector((state) => state.getAllPartner);

  const initialFormValues = {
    partner_id: partnerId || "",
    ss_name: ssName || "",
    ss_message: ssMessage,
    ss_avatar_image: ssImage || "",
    status: status,
  };

  const handleImageChange = (event) => {
    let imageFile = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (x) => {
      setImgSrc(x.target.result);
    };
    reader.readAsDataURL(imageFile);
    setSSImage(event.target.files[0]);
  };

  useEffect(() => {
    if (!allPartner) {
      dispatch(getAllPartnerAction());
    }
    if (allPartner) {
      setPartnerName(allPartner?.data);
    }
  }, [dispatch, allPartner]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (ssMessage !== "") {
      dispatch(postSuccessStoriesAction(initialFormValues, ssImage));
      setOpenPopup(false);
    } else {
      toast.error("Client/User Name and Success Stories Message is required.");
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container spacing={2} style={{ fontSize: "13px" }}>
        <Grid item xs={12}>
          <span
            style={{
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            Select Profile:
          </span>
          <Select
            name="partner_company_name"
            label="Partner Name"
            style={{ width: "100%", marginTop: "6px" }}
            value={partnerId}
            onChange={(e) => setPartnerId(e.target.value)}
          >
            <MenuItem value="Client Stories">Client Stories</MenuItem>
            {partnerName.map((option) => (
              <MenuItem key={option.partner_id} value={option.partner_id}>
                {option.company_name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        {partnerId === "Client Stories" && (
          <Grid item xs={12}>
            <span
              style={{
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              Client/User Name:
            </span>
            <OutlinedInput
              name="ss_name"
              label="Client Name"
              style={{ width: "100%", marginTop: "6px" }}
              value={ssName}
              onChange={(e) => setSSName(e.target.value)}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <span
            style={{
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            Success Stories:
          </span>
          <div className="ckeditor-container" tabIndex="0">
            <CKEditor
              editor={ClassicEditor}
              id="ss_message"
              name="ss_message"
              config={{
                link: {
                  defaultProtocol: "https://",
                  addTargetToExternalLinks: true,
                  decorators: [
                    {
                      attributes: {
                        target: "_blank",
                        rel: "noopener noreferrer",
                      },
                    },
                  ],
                },
              }}
              onReady={(editor) => {
                editor.setData(initialFormValues.ss_message);
              }}
              onChange={(event, editor) => setSSMessage(editor.getData())}
            />
          </div>
        </Grid>
        {partnerId === "Client Stories" && (
          <Grid item xs={12}>
            <span
              style={{
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              Client Avatar:
            </span>
            <input
              type="file"
              name="ss_avatar_image"
              className="form-control"
              onChange={handleImageChange}
            />
            <br />
            <br />
            {imgSrc && imgSrc ? (
              <img
                alt=""
                src={
                  imgSrc
                    ? imgSrc
                    : singleSuccessStories?.ss_avatar_image &&
                      `${API_URL}/uploads/success_stories/${singleSuccessStories?.ss_avatar_image}`
                }
                height={150}
                width={150}
              />
            ) : (
              <span style={{ color: "red" }}>*Choose Image</span>
            )}
          </Grid>
        )}

        <Grid item xs={12}>
          <CheckBoxControl
            name="status"
            label="Active"
            onChange={(e) => setStatus(e.target.value ? 1 : 0)}
          />
        </Grid>
      </Grid>

      <div
        style={{
          display: "flex",
          justifyContent: "end",
          paddingTop: "10px",
          marginTop: "10px",
          borderTop: "1px solid #0b6885",
        }}
      >
        <Button
          variant="contained"
          onClick={() => setOpenPopup(false)}
          style={{ margin: "10px 0 0 10px", backgroundColor: "#ea3f4d" }}
        >
          CANCEL
        </Button>
        <Button
          variant="contained"
          type="submit"
          style={{ margin: "10px 0 0 10px", backgroundColor: "#0b6885" }}
        >
          SUBMIT
        </Button>
        <ToastContainer
          position="top-right"
          autoClose={2000}
          theme="colored"
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
        />
      </div>
    </Form>
  );
};

export default SuccessStoriesForm;

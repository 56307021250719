import React, { useState, useEffect } from "react";
import { Button, TableBody, Toolbar } from "@mui/material";
import useCustomTable from "../../components/hooks/useCustomTable";
import { Add } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import CustomContainer from "../../components/CustomContainer";
import Topbar from "../../components/Topbar";
import Notification from "../../components/Notification";
import LoadingComp from "../../components/LoadingComp";
import Popup from "../../components/Popup";
import {
  DELETE_SUCCESS_STORIES_RESET,
  GET_ALL_SUCCESS_STORIES_RESET,
  GET_SINGLE_SUCCESS_STORIES_RESET,
  POST_SUCCESS_STORIES_RESET,
  PUT_SUCCESS_STORIES_RESET,
} from "./SuccessStoriesConstants";
import {
  deleteSuccessStoriesAction,
  getAllSuccessStoriesAction,
  getSingleSuccessStoriesAction,
} from "./SuccessStoriesActions";
import SuccessStoriesTableCollapse from "./SuccessStoriesTableCollapse";
import SuccessStoriesForm from "./SuccessStoriesForm";
import SuccessStoriesUpdateForm from "./SuccessStoriesUpdateForm";
import SuccessStoriesDetail from "./SuccessStoriesDetail";

const tableHeader = [
  { id: "partner_company_name", label: "User Name" },
  { id: "ss_message", label: "Messages" },
  { id: "created_at", label: "Created Date" },
  { id: "status", label: "Status" },
  { id: "actions", label: "Actions", disableSorting: true },
];

const SuccessStories = () => {
  const [tableData, setTableData] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [openDetailPopup, setOpenDetailPopup] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [filterFn, setFilterFn] = useState({
    fn: (item) => {
      return item;
    },
  });

  const dispatch = useDispatch();

  const { allSuccessStories, error, loading } = useSelector(
    (state) => state.getAllSuccessStories
  );

  const {
    singleSuccessStories,
    error: singleSuccessStoriesError,
    loading: loadingEditForm,
  } = useSelector((state) => state.getSingleSuccessStories);

  const { success: postSuccessStoriesSuccess, error: postSuccessStoriesError } =
    useSelector((state) => state.postSuccessStories);

  const { success: putSuccessStoriesSuccess, error: putSuccessStoriesError } =
    useSelector((state) => state.putSuccessStories);

  const {
    success: deleteSuccessStoriesSuccess,
    error: deleteSuccessStoriesError,
  } = useSelector((state) => state.deleteSuccessStories);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (error) {
    setNotify({
      isOpen: true,
      message: error,
      type: "error",
    });
    dispatch({ type: GET_ALL_SUCCESS_STORIES_RESET });
  }

  if (singleSuccessStoriesError) {
    setNotify({
      isOpen: true,
      message: singleSuccessStoriesError,
      type: "error",
    });
    dispatch({ type: GET_SINGLE_SUCCESS_STORIES_RESET });
  }

  if (postSuccessStoriesError) {
    setNotify({
      isOpen: true,
      message: postSuccessStoriesError,
      type: "error",
    });
    dispatch({ type: POST_SUCCESS_STORIES_RESET });
  }

  if (putSuccessStoriesError) {
    setNotify({
      isOpen: true,
      message: putSuccessStoriesError,
      type: "error",
    });
    dispatch({ type: PUT_SUCCESS_STORIES_RESET });
  }

  if (deleteSuccessStoriesError) {
    setNotify({
      isOpen: true,
      message: deleteSuccessStoriesError,
      type: "error",
    });
    dispatch({ type: DELETE_SUCCESS_STORIES_RESET });
  }

  if (postSuccessStoriesSuccess) {
    dispatch(getAllSuccessStoriesAction());
    setNotify({
      isOpen: true,
      message: "Success-Stories Added Successfully.",
      type: "success",
    });
    dispatch({ type: POST_SUCCESS_STORIES_RESET });
    setOpenPopup(false);
  }

  if (putSuccessStoriesSuccess) {
    dispatch(getAllSuccessStoriesAction());
    setNotify({
      isOpen: true,
      message: "Success-Stories Updated Successfully.",
      type: "success",
    });
    dispatch({ type: PUT_SUCCESS_STORIES_RESET });
    setOpenEditPopup(false);
  }

  if (deleteSuccessStoriesSuccess) {
    dispatch(getAllSuccessStoriesAction());
    setNotify({
      isOpen: true,
      message: "Success-Stories Deleted Successfully.",
      type: "success",
    });
    dispatch({ type: DELETE_SUCCESS_STORIES_RESET });
  }

  useEffect(() => {
    if (!allSuccessStories) {
      dispatch(getAllSuccessStoriesAction());
    } else {
      setTableData(allSuccessStories?.data);
    }
  }, [allSuccessStories, dispatch]);

  useEffect(() => {
    dispatch(getAllSuccessStoriesAction());
  }, [dispatch]);

  const {
    TableContainer,
    TblHead,
    TblPagination,
    tableDataAfterPagingAndSorting,
  } = useCustomTable(tableData, tableHeader, filterFn);

  const addSuccessStoriesHandler = () => {
    dispatch({ type: GET_SINGLE_SUCCESS_STORIES_RESET });
    setOpenPopup(true);
  };

  const updateSuccessStoriesHandler = (id) => {
    dispatch(getSingleSuccessStoriesAction(id));
    setOpenEditPopup(true);
  };

  const detailViewHandler = (id) => {
    dispatch(getSingleSuccessStoriesAction(id));
    setOpenDetailPopup(true);
  };

  const deleteSuccessStoriesHandler = (id) => {
    dispatch(deleteSuccessStoriesAction(id));
  };

  return (
    <>
      <CustomContainer>
        <Toolbar>
          <Topbar
            title="Success Stories"
            subtitle="Manage all your partner's stories here."
          />
          <Button
            variant="contained"
            startIcon={<Add />}
            style={{
              position: "absolute",
              right: "10px",
              backgroundColor: "#2a80f9",
            }}
            onClick={addSuccessStoriesHandler}
          >
            {" "}
            Add Success Stories
          </Button>
        </Toolbar>

        {loading ? (
          <LoadingComp />
        ) : (
          <>
            <TableContainer style={{ margin: "2vw 0.5vw", padding: "24px 0" }}>
              <TblHead />
              <TableBody>
                {tableDataAfterPagingAndSorting().map((item) => (
                  <SuccessStoriesTableCollapse
                    item={item}
                    key={item.ss_uid}
                    detailViewHandler={detailViewHandler}
                    updateSuccessStoriesHandler={updateSuccessStoriesHandler}
                    deleteSuccessStoriesHandler={deleteSuccessStoriesHandler}
                  />
                ))}
              </TableBody>
            </TableContainer>
            <TblPagination />
          </>
        )}
      </CustomContainer>
      <Popup
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        title="Add Success Stories"
      >
        <SuccessStoriesForm
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          singleSuccessStories={
            singleSuccessStories && singleSuccessStories?.data
          }
        />
      </Popup>
      <Popup
        openPopup={openEditPopup}
        setOpenPopup={setOpenEditPopup}
        title="Edit Success Stories"
      >
        <SuccessStoriesUpdateForm
          loadingEditForm={loadingEditForm}
          singleSuccessStories={
            singleSuccessStories && singleSuccessStories?.data
          }
          setOpenEditPopup={setOpenEditPopup}
        />
      </Popup>
      <Popup
        openPopup={openDetailPopup}
        setOpenPopup={setOpenDetailPopup}
        title="Detail View"
      >
        <SuccessStoriesDetail
          loadingEditForm={loadingEditForm}
          singleSuccessStories={
            singleSuccessStories && singleSuccessStories?.data
          }
          setOpenDetailPopup={setOpenDetailPopup}
        />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};

export default SuccessStories;

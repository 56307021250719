import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import React from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ArrowButton from "../components/ArrowButton";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getAllSdgsFilterAction } from "../private-routes/PrivateActions";

const InformationSustainabilityAndImpact = ({
  formData,
  setFormData,
  formErrors,
}) => {
  const [sdgList, setSdgList] = useState([]);

  const dispatch = useDispatch();

  const { allSdgsFilter } = useSelector((state) => state.getAllSdgsFilterList);

  useEffect(() => {
    if (!allSdgsFilter) {
      dispatch(getAllSdgsFilterAction());
    }
    if (allSdgsFilter) {
      setSdgList(allSdgsFilter?.data);
    }
  }, [allSdgsFilter, dispatch]);

  const handleSdgChange = (e) => {
    const { name, checked } = e.target;
    if (checked) {
      setFormData((prevData) => {
        const sdgSupportList = Array.isArray(prevData.sdg) ? prevData.sdg : [];

        if (!sdgSupportList.includes(JSON.parse(name))) {
          sdgSupportList.push(JSON.parse(name));
        }
        return {
          ...prevData,
          sdg: sdgSupportList,
        };
      });
    } else {
      setFormData((prevData) => {
        const sdgSupportList = Array.isArray(prevData.sdg) ? prevData.sdg : [];
        const index = sdgSupportList.indexOf(JSON.parse(name));
        if (index !== -1) {
          sdgSupportList.splice(index, 1);
        }
        return {
          ...prevData,
          sdg: sdgSupportList,
        };
      });
    }
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="description" content="" />
        <meta name="author" content="" />

        <title>Million Deals</title>
        {/* <!-- Linking the font Poppins--> */}
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />

        {/* <!-- Linking Bootstrap Icon--> */}
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css"
        />
      </Helmet>
      <ArrowButton />

      <div className="page-title">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h1>Enterprise Form</h1>
              <Link className="link" to="/">
                Home
              </Link>{" "}
              /{" "}
              <Link className="link" to="/dashboard">
                Dashboard
              </Link>{" "}
              / Enterprise Form
            </div>
          </div>
        </div>
      </div>

      <div className="page-content">
        <div className="container">
          <div className="row">
            <div className="col-9">
              <div className="invest-form">
                <form>
                  <h2>Information on sustainability and Impact</h2>
                  Does your company contribute to any sustainability agenda
                  and/or create positive impact?
                  <br />
                  {formErrors.create_positive_impact && (
                    <span className="error">
                      {formErrors.create_positive_impact}
                    </span>
                  )}
                  <select
                    name="create_positive_impact"
                    id="create_positive_impact"
                    value={formData.create_positive_impact || ""}
                    onChange={handleInputChange}
                    displayempty="true"
                    inputprops={{ "aria-label": "Without label" }}
                  >
                    <option value="">Select an option</option>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                    <option value="3">Maybe</option>
                  </select>
                  {/* <br /> */}
                  {formData.create_positive_impact &&
                    formData.create_positive_impact !== "0" && (
                      <>
                        If your company contributes to sustainability agenda,
                        which of the following SDGs does your company contribute
                        to? <br />
                        {formErrors.sdg && (
                          <span className="error">{formErrors.sdg}</span>
                        )}
                        <div className="form-check">
                          <FormGroup
                            sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }}
                          >
                            {sdgList?.map((option) => (
                              <FormControlLabel
                                key={option?.sdg_id}
                                control={
                                  <Checkbox
                                    checked={formData.sdg.includes(
                                      option.sdg_id
                                    )}
                                    onChange={handleSdgChange}
                                    name={option.sdg_id}
                                    value={option.sdg_id}
                                    inputprops={{ "aria-label": "controlled" }}
                                  />
                                }
                                label={option.sdg_name}
                              />
                            ))}

                            {/* <br /> */}
                          </FormGroup>
                        </div>
                      </>
                    )}
                  Please describe how your company mitigates risks of any
                  negative impact and if your company is creating any positive
                  impact pleas also provide a brief overview of the positive
                  impact.
                  <br />
                  {formErrors.mitigates_risks && (
                    <span className="error">{formErrors.mitigates_risks}</span>
                  )}
                  <textarea
                    type="text"
                    name="mitigates_risks"
                    id="mitigates_risks"
                    defaultValue={formData.mitigates_risks}
                    onChange={handleInputChange}
                    required
                  />
                  {/* <br /> */}
                  Is there anything further you can tell us about your
                  stakeholders, including but not limited to the gender and
                  socio-economic composition of your board, owners, employees,
                  suppliers, and consumers?
                  <br />
                  {formErrors.about_stakeholders && (
                    <span className="error">
                      {formErrors.about_stakeholders}
                    </span>
                  )}
                  <textarea
                    type="text"
                    name="about_stakeholders"
                    id="about_stakeholders"
                    defaultValue={formData.about_stakeholders}
                    onChange={handleInputChange}
                    required
                  />
                  {/* <br /> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InformationSustainabilityAndImpact;

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getMemberProfileSideAction } from "../kyc-state-handler/KycActions";
import CompanyOrgDetails from "./CompanyOrgDetails";
import GeneralInformation from "./GeneralInformation";
import InvestmentDetails from "./InvestmentDetails";
import TermsAndCondition from "./TermsAndCondition";
import Popup from "../../components/Popup";
import ReviewInvestorKycForm from "./ReviewInvestorKycForm";

const steps = [
  {
    id: 1,
    component: GeneralInformation,
  },
  { id: 2, component: CompanyOrgDetails },
  { id: 3, component: InvestmentDetails },
  { id: 4, component: TermsAndCondition },
];

const InvestorKycMain = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    linkin_or_online_profile: "",
    contact_address: "",
    bio: "",
    ind_or_ins_investor: "",
    ind_or_ins_investor_other: "",
    company_role: "",
    company_name: "",
    company_website: "",
    company_bio: "",
    interested_in_companies: "",
    describes_your_best: "",
    describes_your_best_other: "",
    investment_size: [],
    invest_in: "",
    is_sustainability: "",
    sdgs: [],
    stage_of_investment: "",
    terms_confirmation: "",
  });
  const [openPopup, setOpenPopup] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [currentStep, setCurrentStep] = useState(1);

  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  const validateCurrentStep = () => {
    let errors = {};
    switch (currentStep) {
      case 1:
        // validate GeneralInformation component
        if (!formData.name) {
          errors.name = "(*Name is required)";
        }
        if (!formData.phone) {
          errors.phone = "(*Phone Number is required)";
        }
        if (!formData.email) {
          errors.email = "(*Email Address is required)";
        }
        if (!formData.ind_or_ins_investor) {
          errors.ind_or_ins_investor = "(*This field is required)";
        }

        if (
          formData.ind_or_ins_investor === "Other" &&
          !formData.ind_or_ins_investor_other
        ) {
          errors.ind_or_ins_investor_other = "(*This field is required)";
        }
        if (!formData.linkin_or_online_profile) {
          errors.linkin_or_online_profile =
            "(*Linkedin profile or other online profile is required)";
        }
        if (!formData.contact_address) {
          errors.contact_address = "(*Contact Address is required)";
        }
        if (!formData.bio) {
          errors.bio = "(*Brief information is required)";
        }
        if (!formData.interested_in_companies) {
          errors.interested_in_companies = "(*Interest in company is required)";
        }
        if (!formData.company_role) {
          errors.company_role = "(*Role in your company is required)";
        }
        break;
      case 2:
        // validate CompanyOrgDetails component
        if (!formData.company_name) {
          errors.company_name = "(*This field is required)";
        }
        if (!formData.company_website) {
          errors.company_website = "(*Link is required)";
        }
        if (!formData.company_bio) {
          errors.company_bio = "(*Company profile is required)";
        }
        if (!formData.describes_your_best) {
          errors.describes_your_best = "(*This field is required)";
        }
        if (
          formData.describes_your_best === "Other Financial Intermediary" &&
          !formData.describes_your_best_other
        ) {
          errors.describes_your_best_other = "(*This field is required)";
        }
        break;
      case 3:
        // validate InvestmentDetails component
        if (formData.investment_size.length === 0) {
          errors.investment_size = "(*Atleast select one investment size)";
        }
        if (formData.invest_in.length === 0) {
          errors.invest_in = "(*Atleast select one sector)";
        }
        if (!formData.is_sustainability) {
          errors.is_sustainability = "(*This field is required)";
        }

        if (formData.is_sustainability !== "0" && formData.sdgs.length === 0) {
          errors.sdgs = "(*Atleast select one SDG)";
        }

        if (!formData.stage_of_investment) {
          errors.stage_of_investment = "(*Stage of investment is required)";
        }
        break;
      case 4:
        // validate Terms&Condition component
        if (formData.terms_confirmation !== "1") {
          errors.terms_confirmation =
            "(*Please accept all the terms before proceed further)";
        }
        break;
      default:
        break;
    }
    return errors;
  };

  const handleNext = () => {
    const currentStepErrors = validateCurrentStep();
    if (Object.keys(currentStepErrors).length === 0) {
      setCurrentStep(currentStep + 1);
      setFormErrors({});
    } else {
      setFormErrors(currentStepErrors);
    }
  };

  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const currentStepErrors = validateCurrentStep();

    // Check if there are any errors specific to case 4
    if (currentStep === 4 && currentStepErrors.terms_confirmation) {
      setFormErrors(currentStepErrors);
      return;
    }

    setOpenPopup(true);
  };

  useEffect(() => {
    dispatch(getMemberProfileSideAction());
  }, [dispatch]);

  const CurrentStepComponent = steps[currentStep - 1].component;

  return (
    <>
      <CurrentStepComponent
        formData={formData}
        setFormData={setFormData}
        formErrors={formErrors}
      />
      <div className="page-content" style={{ padding: "20px 0px" }}>
        <div className="container">
          <div className="row">
            <div className="col-9">
              <div className="invest-form">
                {currentStep > 1 && (
                  <button className="blue-btn" onClick={handlePrev}>
                    Previous
                  </button>
                )}
                {currentStep < steps.length && (
                  <button className="blue-btn" onClick={handleNext}>
                    Next
                  </button>
                )}
                {currentStep === steps.length && (
                  <button className="blue-btn" onClick={handleSubmit}>
                    Submit Form
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Popup
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        title="KYC FORM (*Check before you submit. Once submitted cannot undo the changes.)"
      >
        <ReviewInvestorKycForm
          formData={formData}
          setFormData={setFormData}
          setOpenPopup={setOpenPopup}
        />
      </Popup>
    </>
  );
};

export default InvestorKycMain;

export const GET_ALL_PARTNERS_REQUEST = "GET_ALL_PARTNERS_REQUEST";
export const GET_ALL_PARTNERS_SUCCESS = "GET_ALL_PARTNERS_SUCCESS";
export const GET_ALL_PARTNERS_FAIL = "GET_ALL_PARTNERS_FAIL";
export const GET_ALL_PARTNERS_RESET = "GET_ALL_PARTNERS_RESET";

export const GET_SINGLE_PARTNERS_REQUEST = "GET_SINGLE_PARTNERS_REQUEST";
export const GET_SINGLE_PARTNERS_SUCCESS = "GET_SINGLE_PARTNERS_SUCCESS";
export const GET_SINGLE_PARTNERS_FAIL = "GET_SINGLE_PARTNERS_FAIL";
export const GET_SINGLE_PARTNERS_RESET = "GET_SINGLE_PARTNERS_RESET";

export const POST_PARTNERS_REQUEST = "POST_PARTNERS_REQUEST";
export const POST_PARTNERS_SUCCESS = "POST_PARTNERS_SUCCESS";
export const POST_PARTNERS_FAIL = "POST_PARTNERS_FAIL";
export const POST_PARTNERS_RESET = "POST_PARTNERS_RESET";

export const PUT_PARTNERS_REQUEST = "PUT_PARTNERS_REQUEST";
export const PUT_PARTNERS_SUCCESS = "PUT_PARTNERS_SUCCESS";
export const PUT_PARTNERS_FAIL = "PUT_PARTNERS_FAIL";
export const PUT_PARTNERS_RESET = "PUT_PARTNERS_RESET";

export const DELETE_PARTNERS_REQUEST = "DELETE_PARTNERS_REQUEST";
export const DELETE_PARTNERS_SUCCESS = "DELETE_PARTNERS_SUCCESS";
export const DELETE_PARTNERS_FAIL = "DELETE_PARTNERS_FAIL";
export const DELETE_PARTNERS_RESET = "DELETE_PARTNERS_RESET";

export const GET_MEMBERS_PROFILES_REQUEST = "GET_MEMBERS_PROFILES_REQUEST";
export const GET_MEMBERS_PROFILES_SUCCESS = "GET_MEMBERS_PROFILES_SUCCESS";
export const GET_MEMBERS_PROFILES_FAIL = "GET_MEMBERS_PROFILES_FAIL";
export const GET_MEMBERS_PROFILES_RESET = "GET_MEMBERS_PROFILES_RESET";

export const POST_CREATE_ENTERPRISE_KYC_REQUEST =
  "POST_CREATE_ENTERPRISE_KYC_REQUEST";
export const POST_CREATE_ENTERPRISE_KYC_SUCCESS =
  "POST_CREATE_ENTERPRISE_KYC_SUCCESS";
export const POST_CREATE_ENTERPRISE_KYC_FAIL =
  "POST_CREATE_ENTERPRISE_KYC_FAIL";
export const POST_CREATE_ENTERPRISE_KYC_RESET =
  "POST_CREATE_ENTERPRISE_KYC_RESET";

export const POST_CREATE_INVESTOR_KYC_REQUEST =
  "POST_CREATE_INVESTOR_KYC_REQUEST";
export const POST_CREATE_INVESTOR_KYC_SUCCESS =
  "POST_CREATE_INVESTOR_KYC_SUCCESS";
export const POST_CREATE_INVESTOR_KYC_FAIL = "POST_CREATE_INVESTOR_KYC_FAIL";
export const POST_CREATE_INVESTOR_KYC_RESET = "POST_CREATE_INVESTOR_KYC_RESET";

export const GET_SINGLE_MEMBERS_PROFILE_REQUEST =
  "GET_SINGLE_MEMBERS_PROFILE_REQUEST";
export const GET_SINGLE_MEMBERS_PROFILE_SUCCESS =
  "GET_SINGLE_MEMBERS_PROFILE_SUCCESS";
export const GET_SINGLE_MEMBERS_PROFILE_FAIL =
  "GET_SINGLE_MEMBERS_PROFILE_FAIL";
export const GET_SINGLE_MEMBERS_PROFILE_RESET =
  "GET_SINGLE_MEMBERS_PROFILE_RESET";

export const GET_ENTERPRISE_BUSINESS_PLAN_DOCS_REQUEST =
  "GET_ENTERPRISE_BUSINESS_PLAN_DOCS_REQUEST";
export const GET_ENTERPRISE_BUSINESS_PLAN_DOCS_SUCCESS =
  "GET_ENTERPRISE_BUSINESS_PLAN_DOCS_SUCCESS";
export const GET_ENTERPRISE_BUSINESS_PLAN_DOCS_FAIL =
  "GET_ENTERPRISE_BUSINESS_PLAN_DOCS_FAIL";
export const GET_ENTERPRISE_BUSINESS_PLAN_DOCS_RESET =
  "GET_ENTERPRISE_BUSINESS_PLAN_DOCS_RESET";

export const GET_ENTERPRISE_REGISTRATION_COMP_DOCS_REQUEST =
  "GET_ENTERPRISE_REGISTRATION_COMP_DOCS_REQUEST";
export const GET_ENTERPRISE_REGISTRATION_COMP_DOCS_SUCCESS =
  "GET_ENTERPRISE_REGISTRATION_COMP_DOCS_SUCCESS";
export const GET_ENTERPRISE_REGISTRATION_COMP_DOCS_FAIL =
  "GET_ENTERPRISE_REGISTRATION_COMP_DOCS_FAIL";
export const GET_ENTERPRISE_REGISTRATION_COMP_DOCS_RESET =
  "GET_ENTERPRISE_REGISTRATION_COMP_DOCS_RESET";

export const POST_ENTERPRISE_BUSINESS_PLAN_DOC_REQUEST =
  "POST_ENTERPRISE_BUSINESS_PLAN_DOC_REQUEST";
export const POST_ENTERPRISE_BUSINESS_PLAN_DOC_SUCCESS =
  "POST_ENTERPRISE_BUSINESS_PLAN_DOC_SUCCESS";
export const POST_ENTERPRISE_BUSINESS_PLAN_DOC_FAIL =
  "POST_ENTERPRISE_BUSINESS_PLAN_DOC_FAIL";
export const POST_ENTERPRISE_BUSINESS_PLAN_DOC_RESET =
  "POST_ENTERPRISE_BUSINESS_PLAN_DOC_RESET";

export const POST_ENTERPRISE_REGISRATION_COMP_DOC_REQUEST =
  "POST_ENTERPRISE_REGISRATION_COMP_DOC_REQUEST";
export const POST_ENTERPRISE_REGISRATION_COMP_DOC_SUCCESS =
  "POST_ENTERPRISE_REGISRATION_COMP_DOC_SUCCESS";
export const POST_ENTERPRISE_REGISRATION_COMP_DOC_FAIL =
  "POST_ENTERPRISE_REGISRATION_COMP_DOC_FAIL";
export const POST_ENTERPRISE_REGISRATION_COMP_DOC_RESET =
  "POST_ENTERPRISE_REGISRATION_COMP_DOC_RESET";

export const POST_PAN_OR_VAT_DOCS_REQUEST = "POST_PAN_OR_VAT_DOCS_REQUEST";
export const POST_PAN_OR_VAT_DOCS_SUCCESS = "POST_PAN_OR_VAT_DOCS_SUCCESS";
export const POST_PAN_OR_VAT_DOCS_FAIL = "POST_PAN_OR_VAT_DOCS_FAIL";
export const POST_PAN_OR_VAT_DOCS_RESET = "POST_PAN_OR_VAT_DOCS_RESET";

export const POST_MOBILIZATION_PLAN_DOCS_REQUEST =
  "POST_MOBILIZATION_PLAN_DOCS_REQUEST";
export const POST_MOBILIZATION_PLAN_DOCS_SUCCESS =
  "POST_MOBILIZATION_PLAN_DOCS_SUCCESS";
export const POST_MOBILIZATION_PLAN_DOCS_FAIL =
  "POST_MOBILIZATION_PLAN_DOCS_FAIL";
export const POST_MOBILIZATION_PLAN_DOCS_RESET =
  "POST_MOBILIZATION_PLAN_DOCS_RESET";

export const POST_REVENUE_PROFITABILITY_DOCS_REQUEST =
  "POST_REVENUE_PROFITABILITY_DOCS_REQUEST";
export const POST_REVENUE_PROFITABILITY_DOCS_SUCCESS =
  "POST_REVENUE_PROFITABILITY_DOCS_SUCCESS";
export const POST_REVENUE_PROFITABILITY_DOCS_FAIL =
  "POST_REVENUE_PROFITABILITY_DOCS_FAIL";
export const POST_REVENUE_PROFITABILITY_DOCS_RESET =
  "POST_REVENUE_PROFITABILITY_DOCS_RESET";

export const GET_PAN_OR_VAT_DOCS_REQUEST = "GET_PAN_OR_VAT_DOCS_REQUEST";
export const GET_PAN_OR_VAT_DOCS_SUCCESS = "GET_PAN_OR_VAT_DOCS_SUCCESS";
export const GET_PAN_OR_VAT_DOCS_FAIL = "GET_PAN_OR_VAT_DOCS_FAIL";
export const GET_PAN_OR_VAT_DOCS_RESET = "GET_PAN_OR_VAT_DOCS_RESET";

export const GET_MOBILIZATION_PLAN_DOCS_REQUEST =
  "GET_MOBILIZATION_PLAN_DOCS_REQUEST";
export const GET_MOBILIZATION_PLAN_DOCS_SUCCESS =
  "GET_MOBILIZATION_PLAN_DOCS_SUCCESS";
export const GET_MOBILIZATION_PLAN_DOCS_FAIL =
  "GET_MOBILIZATION_PLAN_DOCS_FAIL";
export const GET_MOBILIZATION_PLAN_DOCS_RESET =
  "GET_MOBILIZATION_PLAN_DOCS_RESET";

export const GET_REVENUE_PROFITABILITY_DOCS_REQUEST =
  "GET_REVENUE_PROFITABILITY_DOCS_REQUEST";
export const GET_REVENUE_PROFITABILITY_DOCS_SUCCESS =
  "GET_REVENUE_PROFITABILITY_DOCS_SUCCESS";
export const GET_REVENUE_PROFITABILITY_DOCS_FAIL =
  "GET_REVENUE_PROFITABILITY_DOCS_FAIL";
export const GET_REVENUE_PROFITABILITY_DOCS_RESET =
  "GET_REVENUE_PROFITABILITY_DOCS_RESET";

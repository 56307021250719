import { API_URL, axiosAdminPrivate, tokenConfig } from "../../api/axios";
import {
  DELETE_QUESTIONARIE_FAIL,
  DELETE_QUESTIONARIE_REQUEST,
  DELETE_QUESTIONARIE_SUCCESS,
  GET_ALL_QUESTIONARIE_FAIL,
  GET_ALL_QUESTIONARIE_REQUEST,
  GET_ALL_QUESTIONARIE_SUCCESS,
  GET_SINGLE_QUESTIONARIE_FAIL,
  GET_SINGLE_QUESTIONARIE_REQUEST,
  GET_SINGLE_QUESTIONARIE_SUCCESS,
  POST_QUESTIONARIE_FAIL,
  POST_QUESTIONARIE_REQUEST,
  POST_QUESTIONARIE_SUCCESS,
  PUT_QUESTIONARIE_FAIL,
  PUT_QUESTIONARIE_REQUEST,
  PUT_QUESTIONARIE_SUCCESS,
} from "./QuestionarieConstants";

export const getAllQuestionarieAction = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_QUESTIONARIE_REQUEST });

    const { data } = await axiosAdminPrivate.get(
      `${API_URL}/api/v1/admin/questionaires`
    );
    dispatch({ type: GET_ALL_QUESTIONARIE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_ALL_QUESTIONARIE_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

export const getSingleQuestionarieAction = (unique_id) => async (dispatch) => {
  try {
    dispatch({ type: GET_SINGLE_QUESTIONARIE_REQUEST });

    const { data } = await axiosAdminPrivate.get(
      `${API_URL}/api/v1/admin/questionaires/${unique_id}`
    );
    dispatch({ type: GET_SINGLE_QUESTIONARIE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_SINGLE_QUESTIONARIE_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

export const postQuestionarieAction = (addQuestionarie) => async (dispatch) => {
  try {
    dispatch({ type: POST_QUESTIONARIE_REQUEST });

    const jsonData = JSON.stringify(addQuestionarie);

    const { data } = await axiosAdminPrivate.post(
      `${API_URL}/api/v1/admin/questionaires`,
      jsonData,
      tokenConfig()
    );

    dispatch({ type: POST_QUESTIONARIE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: POST_QUESTIONARIE_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

export const putQuestionarieAction =
  (updateQuestionarie, unique_id) => async (dispatch) => {
    try {
      dispatch({ type: PUT_QUESTIONARIE_REQUEST });

      const jsonData = JSON.stringify(updateQuestionarie);

      const { data } = await axiosAdminPrivate.put(
        `${API_URL}/api/v1/admin/questionaires/${unique_id}`,
        jsonData,
        tokenConfig()
      );

      dispatch({ type: PUT_QUESTIONARIE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: PUT_QUESTIONARIE_FAIL,
        payload: error.message ? error.message : error.Message,
      });
    }
  };

export const deleteQuestionarieAction = (unique_id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_QUESTIONARIE_REQUEST });

    const { data } = await axiosAdminPrivate.delete(
      `${API_URL}/api/v1/admin/questionaires/${unique_id}`,
      tokenConfig()
    );

    dispatch({ type: DELETE_QUESTIONARIE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: DELETE_QUESTIONARIE_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

import {
  DELETE_USERS_FAIL,
  DELETE_USERS_REQUEST,
  DELETE_USERS_RESET,
  DELETE_USERS_SUCCESS,
  GET_ALL_USERS_FAIL,
  GET_ALL_USERS_REQUEST,
  GET_ALL_USERS_RESET,
  GET_ALL_USERS_SUCCESS,
  GET_SINGLE_USERS_FAIL,
  GET_SINGLE_USERS_REQUEST,
  GET_SINGLE_USERS_RESET,
  GET_SINGLE_USERS_SUCCESS,
  POST_USERS_FAIL,
  POST_USERS_REQUEST,
  POST_USERS_RESET,
  POST_USERS_SUCCESS,
  PUT_USERS_FAIL,
  PUT_USERS_REQUEST,
  PUT_USERS_RESET,
  PUT_USERS_SUCCESS,
} from "./ManageUsersConstants";

export const getAllUserReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_USERS_REQUEST:
      return { loading: true };
    case GET_ALL_USERS_SUCCESS:
      return { loading: false, allUsers: action.payload };
    case GET_ALL_USERS_FAIL:
      return { loading: false, error: action.payload };
    case GET_ALL_USERS_RESET:
      return {};
    default:
      return state;
  }
};

export const getSingleUserReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_SINGLE_USERS_REQUEST:
      return { loading: true };
    case GET_SINGLE_USERS_SUCCESS:
      return { loading: false, singleUser: action.payload };
    case GET_SINGLE_USERS_FAIL:
      return { loading: false, error: action.payload };
    case GET_SINGLE_USERS_RESET:
      return {};
    default:
      return state;
  }
};

export const postUsersReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_USERS_REQUEST:
      return { loading: true };
    case POST_USERS_SUCCESS:
      return { loading: false, success: true, postUser: action.payload };
    case POST_USERS_FAIL:
      return { loading: false, error: action.payload };
    case POST_USERS_RESET:
      return {};
    default:
      return state;
  }
};

export const putUsersReducer = (state = {}, action) => {
  switch (action.type) {
    case PUT_USERS_REQUEST:
      return { loading: true };
    case PUT_USERS_SUCCESS:
      return { loading: false, success: true };
    case PUT_USERS_FAIL:
      return { loading: false, error: action.payload };
    case PUT_USERS_RESET:
      return {};
    default:
      return state;
  }
};

export const deleteUsersReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_USERS_REQUEST:
      return { loading: true };
    case DELETE_USERS_SUCCESS:
      return { loading: false, success: true };
    case DELETE_USERS_FAIL:
      return { loading: false, error: action.payload };
    case DELETE_USERS_RESET:
      return {};
    default:
      return state;
  }
};

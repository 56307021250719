import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import React from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ArrowButton from "../components/ArrowButton";
import { toast } from "react-toastify";

const nonFinancialList = [
  {
    id: 1,
    label: "Financial projections and valuation",
  },
  { id: 2, label: "Branding" },
  { id: 3, label: "Legal Services" },
  { id: 4, label: "Accounting Services" },
  { id: 5, label: "Auditing and Taxation Services" },
  { id: 6, label: "Not seeking any non-financial support" },
  { id: 7, label: "Others" },
];
const MAX_COUNT = 5;

const FinancialInformation = ({
  formData,
  setFormData,
  mobilizationDocs,
  setMobilizationDocs,
  revenueDocs,
  setRevenueDocs,
  formErrors,
}) => {
  const [mobilizationFiles, setMobilizationFiles] = useState([]);
  const [revenueFiles, setRevenueFiles] = useState([]);
  const [fileLimit, setFileLimit] = useState(false);

  const handleMobFiles = (files) => {
    const uploaded = [...mobilizationFiles];
    let limitExceeded = false;
    files.forEach((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        if (uploaded.length === MAX_COUNT) setFileLimit(true);
        if (uploaded.length > MAX_COUNT) {
          toast.error(`You can only add a maximum of ${MAX_COUNT} files`);
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
      }
    });
    if (!limitExceeded) {
      setMobilizationFiles(uploaded);
      setMobilizationDocs({
        ...mobilizationDocs,
        mobilization_plan_docs: uploaded,
      });
    }
  };

  const handleFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleMobFiles(chosenFiles);
  };

  const handleRevFiles = (files) => {
    const uploaded = [...revenueFiles];
    let limitExceeded = false;
    files.forEach((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        if (uploaded.length === MAX_COUNT) setFileLimit(true);
        if (uploaded.length > MAX_COUNT) {
          toast.error(`You can only add a maximum of ${MAX_COUNT} files`);
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
      }
    });
    if (!limitExceeded) {
      setRevenueFiles(uploaded);
      setRevenueDocs({
        ...revenueDocs,
        revenue_profitability_docs: uploaded,
      });
    }
  };

  const handleFileRevEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleRevFiles(chosenFiles);
  };

  const handleInputChange = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;
    let error = "";
    let errorFinance = "";
    let errorEbidta = "";
    let errorRaise = "";

    if (inputName === "amount_of_investment" && inputValue.length < 6) {
      error = "Amount of investment must be at least 6 digits.";
    }

    if (inputName === "financial_performance" && inputValue.length < 6) {
      errorFinance = "Financial Performance must be at least 6 digits.";
    }

    if (inputName === "financial_performance" && inputValue.length < 6) {
      errorEbidta = "Financial Performance (EBITDA) must be at least 6 digits.";
    }

    if (inputName === "raise_investment" && inputValue.length < 6) {
      errorRaise = "Raise investment must be at least 6 digits.";
    }

    setFormData({
      ...formData,
      [inputName]: inputValue,
      error: error,
      errorFinance: errorFinance,
      errorEbidta: errorEbidta,
      errorRaise: errorRaise,
    });

    setMobilizationDocs({
      ...mobilizationDocs,
      [e.target.name]: e.target.files,
      mobilization_plan_docs: mobilizationFiles,
    });

    setRevenueDocs({
      ...revenueDocs,
      [e.target.name]: e.target.files,
      revenue_profitability_docs: revenueFiles,
    });
  };

  const handleNonFinancialCheckBox = (e) => {
    const { name, checked } = e.target;
    if (checked) {
      setFormData((prevData) => {
        const nonFinancialSupportList = Array.isArray(
          prevData.non_financial_support
        )
          ? prevData.non_financial_support
          : [];
        if (!nonFinancialSupportList.includes(JSON.parse(name))) {
          nonFinancialSupportList.push(JSON.parse(name));
        }
        return {
          ...prevData,
          non_financial_support: nonFinancialSupportList,
        };
      });
    } else {
      setFormData((prevData) => {
        const nonFinancialSupportList = Array.isArray(
          prevData.non_financial_support
        )
          ? prevData.non_financial_support
          : [];
        const index = nonFinancialSupportList.indexOf(JSON.parse(name));
        if (index !== -1) {
          nonFinancialSupportList.splice(index, 1);
        }
        return {
          ...prevData,
          non_financial_support: nonFinancialSupportList,
        };
      });
    }
  };

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="description" content="" />
        <meta name="author" content="" />

        <title>Million Deals</title>
        {/* <!-- Linking the font Poppins--> */}
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />

        {/* <!-- Linking Bootstrap Icon--> */}
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css"
        />
      </Helmet>
      <ArrowButton />
      <div className="page-title">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h1>Enterprise Form</h1>
              <Link className="link" to="/">
                Home
              </Link>{" "}
              /{" "}
              <Link className="link" to="/dashboard">
                Dashboard
              </Link>{" "}
              / Enterprise Form
            </div>
          </div>
        </div>
      </div>

      <div className="page-content">
        <div className="container">
          <div className="row">
            <div className="col-9">
              <div className="invest-form">
                <form>
                  <h2>Financial Information</h2>
                  Total Investment made till date - in NPR (Paid up Capital){" "}
                  {formErrors.amount_of_investment && (
                    <span className="error">
                      {formErrors.amount_of_investment}
                    </span>
                  )}
                  <input
                    type="number"
                    name="amount_of_investment"
                    value={formData.amount_of_investment || ""}
                    onChange={handleInputChange}
                    required
                  />
                  {formData.error && (
                    <div className="error">*{formData.error}</div>
                  )}
                  {/* <br /> */}
                  Company's current financial performance in terms of revenue
                  (please provide revenues of current fiscal year and past two
                  fiscal years OR since inception whichever is shorter)
                  <br />
                  {formErrors.current_financial && (
                    <span className="error">
                      {formErrors.current_financial}
                    </span>
                  )}
                  <input
                    type="number"
                    name="current_financial"
                    value={formData.current_financial || ""}
                    onChange={handleInputChange}
                    required
                  />
                  {formData.errorFinance && (
                    <div className="error">*{formData.errorFinance}</div>
                  )}
                  {/* <br /> */}
                  Company's current financial performance in terms of EBITDA
                  (please provide EBITDA of current fiscal year and past two
                  fiscal years OR since inception whichever is shorter)
                  <br />
                  {formErrors.terms_of_ebitda && (
                    <span className="error">{formErrors.terms_of_ebitda}</span>
                  )}
                  <input
                    type="number"
                    name="terms_of_ebitda"
                    value={formData.terms_of_ebitda || ""}
                    onChange={handleInputChange}
                    required
                  />
                  {formData.errorEbidta && (
                    <div className="error">*{formData.errorEbidta}</div>
                  )}
                  {/* <br /> */}
                  Amount of investment that you are trying to raise? - in NPR{" "}
                  {formErrors.raise_investment && (
                    <span className="error">{formErrors.raise_investment}</span>
                  )}
                  <input
                    type="number"
                    name="raise_investment"
                    value={formData.raise_investment || ""}
                    onChange={handleInputChange}
                    required
                  />
                  {formData.errorRaise && (
                    <div className="error">*{formData.errorRaise}</div>
                  )}
                  {/* <br /> */}
                  {/*break*/}
                  What will that capital be used for? Please provide detailed
                  information and amount or percentage breakdown as far as
                  possible
                  <br />
                  {formErrors.amount_or_percentage_breakdown && (
                    <span className="error">
                      {formErrors.amount_or_percentage_breakdown}
                    </span>
                  )}
                  <textarea
                    type="text"
                    name="amount_or_percentage_breakdown"
                    value={formData.amount_or_percentage_breakdown || ""}
                    onChange={handleInputChange}
                    required
                  />
                  {/* <br /> */}
                  {/*break*/}
                  Please upload a capital mobilization plan (an excel file/
                  spreadsheet showcasing the breakdown of how the investment
                  capital you seek will be used).
                  <input
                    type="file"
                    multiple
                    name="mobilization_plan_docs"
                    className="form-control"
                    onChange={handleFileEvent}
                    disabled={fileLimit}
                  />
                  {mobilizationFiles.length > 0 &&
                    mobilizationFiles.map((file, index) => (
                      <img
                        key={index}
                        src={URL.createObjectURL(file)}
                        alt={`Uploaded File ${index + 1}`}
                        style={{
                          borderRadius: "0px",
                          height: "150px",
                          width: "150px",
                          margin: "5px",
                        }}
                      />
                    ))}
                  {mobilizationFiles.length > 0 && <br />}
                  {/*break*/}
                  Please upload a revenue/ profitability projection for the next
                  five years if available.
                  <input
                    type="file"
                    name="revenue_profitability_docs"
                    multiple
                    className="form-control"
                    onChange={handleFileRevEvent}
                    disabled={fileLimit}
                  />
                  {revenueFiles.length > 0 &&
                    revenueFiles.map((file, index) => (
                      <img
                        key={index}
                        src={URL.createObjectURL(file)}
                        alt={`Uploaded File ${index + 1}`}
                        style={{
                          borderRadius: "0px",
                          height: "150px",
                          width: "150px",
                          margin: "5px",
                        }}
                      />
                    ))}
                  {revenueFiles.length > 0 && <br />}
                  {/*break*/}
                  Preferred mode of funding{" "}
                  {formErrors.mode_of_funding && (
                    <span className="error">{formErrors.mode_of_funding}</span>
                  )}
                  <select
                    name="mode_of_funding"
                    id="mode_of_funding"
                    value={formData.mode_of_funding || ""}
                    onChange={handleInputChange}
                    displayempty="true"
                    inputprops={{ "aria-label": "Without label" }}
                  >
                    <option value="">Select an option</option>
                    <option value="Equity">Equity</option>
                    <option value="Dept">Dept</option>
                    <option value="Others">
                      I am open to other innovating financial structuring
                    </option>
                  </select>
                  {/* <br /> */}
                  {formData?.mode_of_funding === "Others" && (
                    <>
                      Others Preferred mode of funding{" "}
                      {formErrors.mode_of_funding_others && (
                        <span className="error">
                          {formErrors.mode_of_funding_others}
                        </span>
                      )}
                      <input
                        type="text"
                        name="mode_of_funding_others"
                        value={formData.mode_of_funding_others || ""}
                        onChange={handleInputChange}
                        required
                      />
                      {/* <br /> */}
                    </>
                  )}
                  Non-financial support required?{" "}
                  {formErrors.non_financial_support && (
                    <span className="error">
                      {formErrors.non_financial_support}
                    </span>
                  )}
                  <div className="form-check">
                    <FormGroup sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}>
                      {nonFinancialList?.map((option) => (
                        <FormControlLabel
                          key={option?.id}
                          control={
                            <Checkbox
                              checked={formData.non_financial_support.includes(
                                option.id
                              )}
                              onChange={handleNonFinancialCheckBox}
                              name={option.id}
                              value={option.id}
                              id="non_financial_support"
                              inputprops={{ "aria-label": "controlled" }}
                            />
                          }
                          label={option.label}
                        />
                      ))}

                      {/* <br /> */}
                    </FormGroup>
                  </div>
                  {formData?.non_financial_support?.includes(7) && (
                    <>
                      {" "}
                      Please describe non-financial support (*Required){" "}
                      {formErrors.non_financial_support_other && (
                        <span className="error">
                          {formErrors.non_financial_support_other}
                        </span>
                      )}
                      <textarea
                        type="text"
                        name="non_financial_support_other"
                        id="non_financial_support_other"
                        value={formData.non_financial_support_other || ""}
                        onChange={handleInputChange}
                        required
                      />{" "}
                      {/* <br /> */}
                    </>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FinancialInformation;

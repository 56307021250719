import React, { useState } from "react";
import {
  Table,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";

const useCustomTable = (tableData, tableHeader, filterFn) => {
  const pages = [5];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(pages[page]);
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();

  const TableContainer = ({ children }) => (
    <Table sx={{ margin: "0.75vw 0.1vw", padding: "24px 0" }}>{children}</Table>
  );

  const handleSortRequest = (id) => {
    const isAscending = orderBy === id && order === "asc";
    setOrder(isAscending ? "desc" : "asc");
    setOrderBy(id);
  };
  const TblHead = () => (
    <TableHead
      style={{
        backgroundColor: "#0b6885",
        padding: "0.5vw",
      }}
    >
      <TableRow
        sx={{
          "& :hover": {
            color: "white !important",
          },
          "& :focus:not(:focus-visible)": {
            color: "white !important",
          },
        }}
      >
        {tableHeader?.map((item) => (
          <TableCell
            style={{
              letterSpacing: "0.5px",
              fontSize: "14px",
              fontWeight: "600",
              color: "white",
            }}
            key={item.id}
            sortDirection={orderBy === item.id ? order : "asc"}
          >
            {item.disableSorting ? (
              item.label.toUpperCase()
            ) : (
              <TableSortLabel
                className="MuiTableSortLabel-root.Mui-active"
                active={orderBy === item.id}
                direction={orderBy === item.id ? order : "asc"}
                onClick={() => handleSortRequest(item.id)}
                sx={{
                  "& .MuiTableSortLabel-icon": {
                    color: "white !important",
                  },
                }}
              >
                {item.label.toUpperCase()}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };

  const TblPagination = () => (
    <TablePagination
      component="div"
      rowsPerPageOptions={pages}
      rowsPerPage={rowsPerPage}
      page={page}
      count={tableData.length}
      onPageChange={handlePageChange}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );

  const tableSort = (array, comparator) => {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const tableDataAfterPagingAndSorting = () => {
    return tableSort(
      filterFn.fn(tableData),
      getComparator(order, orderBy)
    ).slice(page * rowsPerPage, (page + 1) * rowsPerPage);
  };

  return {
    TableContainer,
    TblHead,
    TblPagination,
    tableDataAfterPagingAndSorting,
  };
};
export default useCustomTable;

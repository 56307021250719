import React, { Suspense, lazy, useEffect } from "react";
import { AppBar, Tabs, Tab, Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useDispatch } from "react-redux";

const ManageDeals = lazy(() => import("../ManageDeals"));

const ManageDealsCategory = lazy(() =>
  import("../manage-deals-category/ManageDealsCategory")
);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function allyProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

const ManageDealsMainPage = () => {
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    dispatch({ type: "GET_LINK", payload: "/manage-deals" });
  }, [dispatch]);
  return (
    <div style={{ padding: "8px" }}>
      <AppBar
        position="static"
        style={{ backgroundColor: "#0b6885", padding: "0px" }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example"
          TabIndicatorProps={{ style: { height: "50px", opacity: 0.2 } }}
        >
          <Tab
            style={{ fontSize: "13px", color: "#ffffff", fontWeight: "600" }}
            label="Manage Deals"
            {...allyProps(0)}
          />

          <Tab
            style={{ fontSize: "13px", color: "#ffffff", fontWeight: "600" }}
            label="Manage Deal Sectors"
            {...allyProps(1)}
          />
        </Tabs>
      </AppBar>
      <Suspense fallback={<div></div>}>
        <TabPanel value={value} index={0}>
          <ManageDeals />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ManageDealsCategory />
        </TabPanel>
      </Suspense>
    </div>
  );
};

export default ManageDealsMainPage;

import React from "react";
import { Button, TableRow, TableCell } from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import Moment from "react-moment";
import { API_URL } from "../../api/axios";

const NewsTableCollapse = ({
  item,
  detailViewHandler,
  updateNewsHandler,
  deleteNewsHandler,
}) => {
  return (
    <TableRow>
      {item.news_image ? (
        <TableCell align="left">
          <img
            alt=""
            height="40px"
            width="40px"
            style={{ cursor: "pointer", borderRadius: "3%" }}
            src={`${API_URL}/uploads/news/${item.news_image}`}
          />
        </TableCell>
      ) : (
        <TableCell>
          <img
            alt=""
            height="40px"
            width="40px"
            style={{ cursor: "pointer", borderRadius: "3%" }}
            src="https://i.postimg.cc/vZvJdnRS/blank-head-profile-pic-for-a-man.jpg"
          />
        </TableCell>
      )}
      <TableCell sx={{ fontSize: "13px" }}>{item.news_title}</TableCell>
      <TableCell sx={{ fontSize: "13px" }}>
        <Moment format="YYYY/MM/DD">{item.created_at.slice(0, 10)}</Moment>
        <br />(<Moment fromNow>{item.created_at}</Moment>)
      </TableCell>
      <TableCell sx={{ fontSize: "13px" }}>
        {item.status ? (
          <CheckIcon style={{ color: " #69CCA4" }} />
        ) : (
          <ClearIcon style={{ color: "red" }} />
        )}
      </TableCell>
      <TableCell>
        <Button
          style={{
            marginRight: "3px",
            padding: "5px",
            minWidth: "10px",
            fontSize: "13px",
            backgroundColor: "#0b6885",
          }}
          variant="contained"
          onClick={() => detailViewHandler(item.unique_id)}
        >
          <VisibilityIcon style={{ fontSize: 13 }} />
        </Button>
        <Button
          style={{
            marginRight: "3px",
            padding: "5px",
            minWidth: "10px",
            fontSize: "13px",
            backgroundColor: "#2a80f9",
          }}
          variant="contained"
          onClick={() => updateNewsHandler(item.unique_id)}
        >
          <Edit style={{ fontSize: 13 }} />
        </Button>
        <Button
          style={{
            marginRight: "1px",
            padding: "5px",
            minWidth: "10px",
            fontSize: "13px",
            backgroundColor: "#ea3f4d",
          }}
          variant="contained"
          onClick={() => deleteNewsHandler(item.unique_id)}
        >
          <Delete style={{ fontSize: 13 }} />
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default NewsTableCollapse;

import {
  axiosAdminPrivate,
  API_URL,
  tokenConfig,
  tokenConfigForm,
} from "../../../api/axios";
import {
  GET_ALL_DEAL_CATEGORY_FILTER_ADMIN_FAIL,
  GET_ALL_DEAL_CATEGORY_FILTER_ADMIN_REQUEST,
  GET_ALL_DEAL_CATEGORY_FILTER_ADMIN_SUCCESS,
  GET_ALL_MEMBERS_LIST_FAIL,
  GET_ALL_MEMBERS_LIST_REQUEST,
  GET_ALL_MEMBERS_LIST_SUCCESS,
  GET_ALL_SDG_FILTER_ADMIN_FAIL,
  GET_ALL_SDG_FILTER_ADMIN_REQUEST,
  GET_ALL_SDG_FILTER_ADMIN_SUCCESS,
  GET_ENTERPRISE_BUSSINESS_DOCS_ADMIN_FAIL,
  GET_ENTERPRISE_BUSSINESS_DOCS_ADMIN_REQUEST,
  GET_ENTERPRISE_BUSSINESS_DOCS_ADMIN_SUCCESS,
  GET_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_FAIL,
  GET_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_REQUEST,
  GET_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_SUCCESS,
  GET_INDIVIDUAL_MEMBERS_LIST_FAIL,
  GET_INDIVIDUAL_MEMBERS_LIST_REQUEST,
  GET_INDIVIDUAL_MEMBERS_LIST_SUCCESS,
  GET_MEMBER_PROFILES_FAIL,
  GET_MEMBER_PROFILES_REQUEST,
  GET_MEMBER_PROFILES_SUCCESS,
  GET_MOBILIZATION_PLAN_DOCS_ADMIN_FAIL,
  GET_MOBILIZATION_PLAN_DOCS_ADMIN_REQUEST,
  GET_MOBILIZATION_PLAN_DOCS_ADMIN_SUCCESS,
  GET_PAN_OR_VAT_DOCS_ADMIN_FAIL,
  GET_PAN_OR_VAT_DOCS_ADMIN_REQUEST,
  GET_PAN_OR_VAT_DOCS_ADMIN_SUCCESS,
  GET_REVENUE_PROFITABILITY_DOCS_ADMIN_FAIL,
  GET_REVENUE_PROFITABILITY_DOCS_ADMIN_REQUEST,
  GET_REVENUE_PROFITABILITY_DOCS_ADMIN_SUCCESS,
  GET_SINGLE_MEMBER_PROFILE_LIST_FAIL,
  GET_SINGLE_MEMBER_PROFILE_LIST_REQUEST,
  GET_SINGLE_MEMBER_PROFILE_LIST_SUCCESS,
  POST_ENTERPRISE_BUSSINESS_DOCS_ADMIN_FAIL,
  POST_ENTERPRISE_BUSSINESS_DOCS_ADMIN_REQUEST,
  POST_ENTERPRISE_BUSSINESS_DOCS_ADMIN_SUCCESS,
  POST_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_FAIL,
  POST_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_REQUEST,
  POST_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_SUCCESS,
  POST_MOBILIZATION_PLAN_DOCS_ADMIN_FAIL,
  POST_MOBILIZATION_PLAN_DOCS_ADMIN_REQUEST,
  POST_MOBILIZATION_PLAN_DOCS_ADMIN_SUCCESS,
  POST_PAN_OR_VAT_DOCS_ADMIN_FAIL,
  POST_PAN_OR_VAT_DOCS_ADMIN_REQUEST,
  POST_PAN_OR_VAT_DOCS_ADMIN_SUCCESS,
  POST_REVENUE_PROFITABILITY_DOCS_ADMIN_FAIL,
  POST_REVENUE_PROFITABILITY_DOCS_ADMIN_REQUEST,
  POST_REVENUE_PROFITABILITY_DOCS_ADMIN_SUCCESS,
  PUT_ENTERPRISE_BUSSINESS_DOCS_ADMIN_FAIL,
  PUT_ENTERPRISE_BUSSINESS_DOCS_ADMIN_REQUEST,
  PUT_ENTERPRISE_BUSSINESS_DOCS_ADMIN_SUCCESS,
  PUT_ENTERPRISE_KYC_FORM_ADMIN_FAIL,
  PUT_ENTERPRISE_KYC_FORM_ADMIN_REQUEST,
  PUT_ENTERPRISE_KYC_FORM_ADMIN_SUCCESS,
  PUT_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_FAIL,
  PUT_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_REQUEST,
  PUT_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_SUCCESS,
  PUT_INVESTOR_KYC_FORM_ADMIN_FAIL,
  PUT_INVESTOR_KYC_FORM_ADMIN_REQUEST,
  PUT_INVESTOR_KYC_FORM_ADMIN_SUCCESS,
  PUT_MOBILIZATION_PLAN_DOCS_ADMIN_FAIL,
  PUT_MOBILIZATION_PLAN_DOCS_ADMIN_REQUEST,
  PUT_MOBILIZATION_PLAN_DOCS_ADMIN_SUCCESS,
  PUT_PAN_OR_VAT_DOCS_ADMIN_FAIL,
  PUT_PAN_OR_VAT_DOCS_ADMIN_REQUEST,
  PUT_PAN_OR_VAT_DOCS_ADMIN_SUCCESS,
  PUT_REVENUE_PROFITABILITY_DOCS_ADMIN_FAIL,
  PUT_REVENUE_PROFITABILITY_DOCS_ADMIN_REQUEST,
  PUT_REVENUE_PROFITABILITY_DOCS_ADMIN_SUCCESS,
  PUT_VERIFIED_MEMBERS_LIST_FAIL,
  PUT_VERIFIED_MEMBERS_LIST_REQUEST,
  PUT_VERIFIED_MEMBERS_LIST_SUCCESS,
} from "./AllMembersConstant";

export const getAllMembersAction = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_MEMBERS_LIST_REQUEST });

    const { data } = await axiosAdminPrivate.get(
      `${API_URL}/api/v1/admin/members`
    );

    dispatch({ type: GET_ALL_MEMBERS_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_ALL_MEMBERS_LIST_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

export const getIndividualMembersListAction =
  (unique_id) => async (dispatch) => {
    try {
      dispatch({ type: GET_INDIVIDUAL_MEMBERS_LIST_REQUEST });

      const { data } = await axiosAdminPrivate.get(
        `${API_URL}/api/v1/admin/members/${unique_id}`
      );
      if (data) {
        await axiosAdminPrivate.get(
          `${API_URL}/api/v1/admin/member_profiles/${data?.data?.member_id}`
        );
      }
      dispatch({
        type: GET_INDIVIDUAL_MEMBERS_LIST_SUCCESS,
        payload: data,
      });
      return data;
    } catch (error) {
      dispatch({
        type: GET_INDIVIDUAL_MEMBERS_LIST_FAIL,
        payload: error.message ? error.message : error.Message,
      });
    }
  };

export const getMemberProfilesAction = (member_id) => async (dispatch) => {
  try {
    dispatch({ type: GET_MEMBER_PROFILES_REQUEST });

    const { data } = await axiosAdminPrivate.get(
      `${API_URL}/api/v1/admin/member_profiles/${member_id}`
    );

    dispatch({ type: GET_MEMBER_PROFILES_SUCCESS, payload: data });
    return data;
  } catch (error) {
    dispatch({
      type: GET_MEMBER_PROFILES_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

export const getSingleMemberProfileAction =
  (member_profile_id) => async (dispatch) => {
    try {
      dispatch({ type: GET_SINGLE_MEMBER_PROFILE_LIST_REQUEST });

      const { data } = await axiosAdminPrivate.get(
        `${API_URL}/api/v1/admin/individual_member_profiles/${member_profile_id}`
      );

      dispatch({ type: GET_SINGLE_MEMBER_PROFILE_LIST_SUCCESS, payload: data });
      return data;
    } catch (error) {
      dispatch({
        type: GET_SINGLE_MEMBER_PROFILE_LIST_FAIL,
        payload: error.message ? error.message : error.Message,
      });
    }
  };

export const putMemberVerifyAction =
  (updateVerify, member_profile_id) => async (dispatch) => {
    try {
      dispatch({ type: PUT_VERIFIED_MEMBERS_LIST_REQUEST });

      const jsonData = JSON.stringify(updateVerify);

      const { data } = await axiosAdminPrivate.put(
        `${API_URL}/api/v1/admin/member_verify/${member_profile_id}`,
        jsonData,
        tokenConfig()
      );

      dispatch({ type: PUT_VERIFIED_MEMBERS_LIST_SUCCESS, payload: data });
      return data;
    } catch (error) {
      dispatch({
        type: PUT_VERIFIED_MEMBERS_LIST_FAIL,
        payload: error.message ? error.message : error.Message,
      });
    }
  };

export const getEnterpriseBusinessPlanDocAdminAction =
  (enterprise_kyc_id) => async (dispatch) => {
    try {
      dispatch({ type: GET_ENTERPRISE_BUSSINESS_DOCS_ADMIN_REQUEST });

      const { data } = await axiosAdminPrivate.get(
        `${API_URL}/api/v1/admin/members/enterprise_business_plan_docs/${enterprise_kyc_id}`
      );

      dispatch({
        type: GET_ENTERPRISE_BUSSINESS_DOCS_ADMIN_SUCCESS,
        payload: data,
      });

      return data;
    } catch (error) {
      dispatch({
        type: GET_ENTERPRISE_BUSSINESS_DOCS_ADMIN_FAIL,
        payload: error.message ? error.message : error.Message,
      });
    }
  };

export const getEnterpriseRegistrationCompDocAdminAction =
  (enterprise_kyc_id) => async (dispatch) => {
    try {
      dispatch({ type: GET_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_REQUEST });

      const { data } = await axiosAdminPrivate.get(
        `${API_URL}/api/v1/admin/members/enterprise_registration_of_company_docs/${enterprise_kyc_id}`
      );

      dispatch({
        type: GET_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_SUCCESS,
        payload: data,
      });

      return data;
    } catch (error) {
      dispatch({
        type: GET_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_FAIL,
        payload: error.message ? error.message : error.Message,
      });
    }
  };

export const getEnterprisePanVatDocAdminAction =
  (enterprise_kyc_id) => async (dispatch) => {
    try {
      dispatch({ type: GET_PAN_OR_VAT_DOCS_ADMIN_REQUEST });

      const { data } = await axiosAdminPrivate.get(
        `${API_URL}/api/v1/admin/members/enterprise_charter_docs/${enterprise_kyc_id}`
      );

      dispatch({
        type: GET_PAN_OR_VAT_DOCS_ADMIN_SUCCESS,
        payload: data,
      });

      return data;
    } catch (error) {
      dispatch({
        type: GET_PAN_OR_VAT_DOCS_ADMIN_FAIL,
        payload: error.message ? error.message : error.Message,
      });
    }
  };

export const getEnterpriseMobilizationPlanDocAdminAction =
  (enterprise_kyc_id) => async (dispatch) => {
    try {
      dispatch({ type: GET_MOBILIZATION_PLAN_DOCS_ADMIN_REQUEST });

      const { data } = await axiosAdminPrivate.get(
        `${API_URL}/api/v1/admin/members/enterprise_mobilization_plan_docs/${enterprise_kyc_id}`
      );

      dispatch({
        type: GET_MOBILIZATION_PLAN_DOCS_ADMIN_SUCCESS,
        payload: data,
      });

      return data;
    } catch (error) {
      dispatch({
        type: GET_MOBILIZATION_PLAN_DOCS_ADMIN_FAIL,
        payload: error.message ? error.message : error.Message,
      });
    }
  };

export const getEnterpriseRevenueProfitabilityDocAdminAction =
  (enterprise_kyc_id) => async (dispatch) => {
    try {
      dispatch({ type: GET_REVENUE_PROFITABILITY_DOCS_ADMIN_REQUEST });

      const { data } = await axiosAdminPrivate.get(
        `${API_URL}/api/v1/admin/members/enterprise_revenue_profitability_docs/${enterprise_kyc_id}`
      );

      dispatch({
        type: GET_REVENUE_PROFITABILITY_DOCS_ADMIN_SUCCESS,
        payload: data,
      });

      return data;
    } catch (error) {
      dispatch({
        type: GET_REVENUE_PROFITABILITY_DOCS_ADMIN_FAIL,
        payload: error.message ? error.message : error.Message,
      });
    }
  };

export const getCategoryListAdminAdminAction = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_DEAL_CATEGORY_FILTER_ADMIN_REQUEST });

    const { data } = await axiosAdminPrivate.get(
      `${API_URL}/api/v1/deal_categories`
    );
    dispatch({
      type: GET_ALL_DEAL_CATEGORY_FILTER_ADMIN_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_DEAL_CATEGORY_FILTER_ADMIN_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

export const getAllSdgsFilterAdminAction = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_SDG_FILTER_ADMIN_REQUEST });

    const { data } = await axiosAdminPrivate.get(
      `${API_URL}/api/v1/admin/sdgs`
    );
    dispatch({ type: GET_ALL_SDG_FILTER_ADMIN_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_ALL_SDG_FILTER_ADMIN_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

export const putEnterpriseKycFormAdminAction =
  (updateEnterpriseKycForm, enterprise_kyc_id) => async (dispatch) => {
    try {
      dispatch({ type: PUT_ENTERPRISE_KYC_FORM_ADMIN_REQUEST });

      const jsonData = JSON.stringify(updateEnterpriseKycForm);

      const { data } = await axiosAdminPrivate.put(
        `${API_URL}/api/v1/admin/members/update_enterprise_kyc/${enterprise_kyc_id}`,
        jsonData,
        tokenConfig()
      );

      dispatch({ type: PUT_ENTERPRISE_KYC_FORM_ADMIN_SUCCESS, payload: data });
      return data;
    } catch (error) {
      dispatch({
        type: PUT_ENTERPRISE_KYC_FORM_ADMIN_FAIL,
        payload: error.message ? error.message : error.Message,
      });
    }
  };

export const putInvestorKycFormAdminAction =
  (updateInvestorKycForm, investor_kyc_id) => async (dispatch) => {
    try {
      dispatch({ type: PUT_INVESTOR_KYC_FORM_ADMIN_REQUEST });

      const jsonData = JSON.stringify(updateInvestorKycForm);

      const { data } = await axiosAdminPrivate.put(
        `${API_URL}/api/v1/admin/members/update_investor_kyc/${investor_kyc_id}`,
        jsonData,
        tokenConfig()
      );

      dispatch({ type: PUT_INVESTOR_KYC_FORM_ADMIN_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: PUT_INVESTOR_KYC_FORM_ADMIN_FAIL,
        payload: error.message ? error.message : error.Message,
      });
    }
  };

export const putEnterpriseBusinessPlanAdminAction =
  (businessDoc, business_plan_doc_id) => async (dispatch) => {
    try {
      dispatch({ type: PUT_ENTERPRISE_BUSSINESS_DOCS_ADMIN_REQUEST });

      const businessData = new FormData();
      businessData.append("enterprise_business_plan_doc", businessDoc);

      const { data } = await axiosAdminPrivate.put(
        `${API_URL}/api/v1/admin/members/upload_enterprise_business_plan_docs/${business_plan_doc_id}`,
        businessData
      );

      dispatch({
        type: PUT_ENTERPRISE_BUSSINESS_DOCS_ADMIN_SUCCESS,
        payload: data,
      });
      return data;
    } catch (error) {
      dispatch({
        type: PUT_ENTERPRISE_BUSSINESS_DOCS_ADMIN_FAIL,
        payload: error.message ? error.message : error.Message,
      });
    }
  };

export const putEnterpriseRegistrationCompAdminAction =
  (company, registration_of_company_doc_id) => async (dispatch) => {
    try {
      dispatch({ type: PUT_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_REQUEST });

      const companyData = new FormData();
      companyData.append("enterprise_registration_of_company_doc", company);

      const { data } = await axiosAdminPrivate.put(
        `${API_URL}/api/v1/admin/members/upload_enterprise_registration_of_company_docs/${registration_of_company_doc_id}`,
        companyData
      );

      dispatch({
        type: PUT_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_SUCCESS,
        payload: data,
      });
      return data;
    } catch (error) {
      dispatch({
        type: PUT_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_FAIL,
        payload: error.message ? error.message : error.Message,
      });
    }
  };

export const putEnterprisePanOrVatDocAdminAction =
  (panVat, charter_doc_id) => async (dispatch) => {
    try {
      dispatch({ type: PUT_PAN_OR_VAT_DOCS_ADMIN_REQUEST });

      const panVatData = new FormData();
      panVatData.append("enterprise_charter_doc", panVat);

      const { data } = await axiosAdminPrivate.put(
        `${API_URL}/api/v1/admin/members/upload_enterprise_charter_docs/${charter_doc_id}`,
        panVatData
      );

      dispatch({
        type: PUT_PAN_OR_VAT_DOCS_ADMIN_SUCCESS,
        payload: data,
      });
      return data;
    } catch (error) {
      dispatch({
        type: PUT_PAN_OR_VAT_DOCS_ADMIN_FAIL,
        payload: error.message ? error.message : error.Message,
      });
    }
  };

export const putEnterpriseMobilizationPlanDocsAdminAction =
  (mobilization, mobilization_plan_doc_id) => async (dispatch) => {
    try {
      dispatch({ type: PUT_MOBILIZATION_PLAN_DOCS_ADMIN_REQUEST });

      const mobilizationData = new FormData();
      mobilizationData.append("enterprise_mobilization_plan_doc", mobilization);

      const { data } = await axiosAdminPrivate.put(
        `${API_URL}/api/v1/admin/members/upload_enterprise_mobilization_plan_docs/${mobilization_plan_doc_id}`,
        mobilizationData
      );

      dispatch({
        type: PUT_MOBILIZATION_PLAN_DOCS_ADMIN_SUCCESS,
        payload: data,
      });
      return data;
    } catch (error) {
      dispatch({
        type: PUT_MOBILIZATION_PLAN_DOCS_ADMIN_FAIL,
        payload: error.message ? error.message : error.Message,
      });
    }
  };

export const putEnterpriseRevenueProfitabilityDocsAdminAction =
  (revenue, revenue_profitability_doc_id) => async (dispatch) => {
    try {
      dispatch({ type: PUT_REVENUE_PROFITABILITY_DOCS_ADMIN_REQUEST });

      const revenueData = new FormData();
      revenueData.append("enterprise_revenue_profitability_doc", revenue);

      const { data } = await axiosAdminPrivate.put(
        `${API_URL}/api/v1/admin/members/upload_enterprise_revenue_profitability_docs/${revenue_profitability_doc_id}`,
        revenueData
      );

      dispatch({
        type: PUT_REVENUE_PROFITABILITY_DOCS_ADMIN_SUCCESS,
        payload: data,
      });
      return data;
    } catch (error) {
      dispatch({
        type: PUT_REVENUE_PROFITABILITY_DOCS_ADMIN_FAIL,
        payload: error.message ? error.message : error.Message,
      });
    }
  };

export const postEnterpriseBusinessPlanAdminAction =
  (businessDoc, enterprise_kyc_id) => async (dispatch) => {
    try {
      dispatch({ type: POST_ENTERPRISE_BUSSINESS_DOCS_ADMIN_REQUEST });

      const businessData = new FormData();
      for (let i = 0; i < businessDoc.length; i++) {
        businessData.append(
          `enterprise_business_plan_doc[${i}]`,
          businessDoc[i]
        );
      }
      businessData.append("enterprise_kyc_id", enterprise_kyc_id);
      const { data } = await axiosAdminPrivate.post(
        `${API_URL}/api/v1/admin/members/add_enterprise_business_plan_docs`,
        businessData
      );

      dispatch({
        type: POST_ENTERPRISE_BUSSINESS_DOCS_ADMIN_SUCCESS,
        payload: data,
      });
      return data;
    } catch (error) {
      dispatch({
        type: POST_ENTERPRISE_BUSSINESS_DOCS_ADMIN_FAIL,
        payload: error.message ? error.message : error.Message,
      });
    }
  };

export const postEnterpriseRegistrationCompAdminAction =
  (company, enterprise_kyc_id) => async (dispatch) => {
    try {
      dispatch({ type: POST_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_REQUEST });

      const companyData = new FormData();
      for (let i = 0; i < company.length; i++) {
        companyData.append(
          `enterprise_registration_of_company_doc[${i}]`,
          company[i]
        );
      }

      companyData.append("enterprise_kyc_id", enterprise_kyc_id);

      const { data } = await axiosAdminPrivate.post(
        `${API_URL}/api/v1/admin/members/add_enterprise_registration_of_company_docs`,
        companyData
      );

      dispatch({
        type: POST_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_SUCCESS,
        payload: data,
      });
      return data;
    } catch (error) {
      dispatch({
        type: POST_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_FAIL,
        payload: error.message ? error.message : error.Message,
      });
    }
  };

export const postEnterprisePanOrVatDocAdminAction =
  (panVat, enterprise_kyc_id) => async (dispatch) => {
    try {
      dispatch({ type: POST_PAN_OR_VAT_DOCS_ADMIN_REQUEST });

      const panVatData = new FormData();
      for (let i = 0; i < panVat.length; i++) {
        panVatData.append(`enterprise_charter_doc[${i}]`, panVat[i]);
      }

      panVatData.append("enterprise_kyc_id", enterprise_kyc_id);

      const { data } = await axiosAdminPrivate.post(
        `${API_URL}/api/v1/admin/members/add_enterprise_charter_docs`,
        panVatData
      );

      dispatch({
        type: POST_PAN_OR_VAT_DOCS_ADMIN_SUCCESS,
        payload: data,
      });
      return data;
    } catch (error) {
      dispatch({
        type: POST_PAN_OR_VAT_DOCS_ADMIN_FAIL,
        payload: error.message ? error.message : error.Message,
      });
    }
  };

export const postEnterpriseMobilizationPlanDocsAdminAction =
  (mobilization, enterprise_kyc_id) => async (dispatch) => {
    try {
      dispatch({ type: POST_MOBILIZATION_PLAN_DOCS_ADMIN_REQUEST });

      const mobilizationData = new FormData();
      for (let i = 0; i < mobilization.length; i++) {
        mobilizationData.append(
          `enterprise_mobilization_plan_doc[${i}]`,
          mobilization[i]
        );
      }

      mobilizationData.append("enterprise_kyc_id", enterprise_kyc_id);

      const { data } = await axiosAdminPrivate.post(
        `${API_URL}/api/v1/admin/members/add_enterprise_mobilization_plan_docs`,
        mobilizationData
      );

      dispatch({
        type: POST_MOBILIZATION_PLAN_DOCS_ADMIN_SUCCESS,
        payload: data,
      });
      return data;
    } catch (error) {
      dispatch({
        type: POST_MOBILIZATION_PLAN_DOCS_ADMIN_FAIL,
        payload: error.message ? error.message : error.Message,
      });
    }
  };

export const postEnterpriseRevenueProfitabilityDocsAdminAction =
  (revenue, enterprise_kyc_id) => async (dispatch) => {
    try {
      dispatch({ type: POST_REVENUE_PROFITABILITY_DOCS_ADMIN_REQUEST });

      const revenueData = new FormData();
      for (let i = 0; i < revenue.length; i++) {
        revenueData.append(
          `enterprise_revenue_profitability_doc[${i}]`,
          revenue[i]
        );
      }

      revenueData.append("enterprise_kyc_id", enterprise_kyc_id);

      const { data } = await axiosAdminPrivate.post(
        `${API_URL}/api/v1/admin/members/add_enterprise_revenue_profitability_docs`,
        revenueData
      );

      dispatch({
        type: POST_REVENUE_PROFITABILITY_DOCS_ADMIN_SUCCESS,
        payload: data,
      });
      return data;
    } catch (error) {
      dispatch({
        type: POST_REVENUE_PROFITABILITY_DOCS_ADMIN_FAIL,
        payload: error.message ? error.message : error.Message,
      });
    }
  };

import React, { useState, useEffect } from "react";
import { Button, Grid, TextField } from "@mui/material";
import { Form, useForm } from "../../components/hooks/useForm";
import { useDispatch } from "react-redux";
import CheckBoxControl from "../../components/controls/CheckBoxControl";
import { API_URL } from "../../api/axios";
import { putPagesAction } from "./ManagePagesActions";
import LoadingComp from "../../components/LoadingComp";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const ManagePagesEditForm = ({
  singlePage,
  loadingEditForm,
  setOpenEditPopup,
}) => {
  const [image, setImage] = useState(null);
  const [imgSrc, setImgSrc] = useState(null);

  const dispatch = useDispatch();

  const initialFormValues = {
    page_image: "",
    page_title: "",
    page_slug: "",
    page_description: "",
    status: 0,
  };

  const { values, setValues, handleInputChange } = useForm(initialFormValues);

  const handleImageChange = (event) => {
    let imageFile = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (x) => {
      setImgSrc(x.target.result);
    };
    reader.readAsDataURL(imageFile);
    setImage(event.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(putPagesAction(values, image, singlePage?.unique_id));
    setOpenEditPopup(false);
  };

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("upload_ck_image", file);
            fetch(`${API_URL}/api/v1/admin/upload_ck`, {
              method: "post",
              body: body,
              headers: {
                authorization: `Bearer ${localStorage.getItem("accessToken")}`,
              },
            })
              .then((res) => res.json())
              .then((res) => {
                console.log("res", res);
                resolve({
                  default: `${res?.data?.url}`,
                });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  useEffect(() => {
    if (singlePage) {
      setValues({ ...singlePage });
    }
  }, [singlePage, setValues]);

  return (
    <Form onSubmit={handleSubmit}>
      {loadingEditForm ? (
        <LoadingComp />
      ) : (
        <>
          <Grid container spacing={2} style={{ fontSize: "12px" }}>
            <Grid item xs={12}>
              <TextField
                name="page_title"
                label="Page Title"
                style={{ width: "100%" }}
                value={values.page_title}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <CKEditor
                editor={ClassicEditor}
                config={{
                  extraPlugins: [uploadPlugin],
                }}
                id="page_description"
                name="page_description"
                onReady={(editor) => {
                  editor.setData(values.page_description);
                }}
                onChange={(event, editor) =>
                  setValues({
                    ...values,
                    page_description: editor.getData(),
                  })
                }
              />
            </Grid>

            <Grid item xs={12}>
              <input
                type="file"
                name="page_image"
                className="form-control"
                style={{ width: "100%" }}
                onChange={handleImageChange}
              />{" "}
              <br />
              <br />
              {singlePage?.page_image && singlePage?.page_image ? (
                <img
                  alt=""
                  src={
                    imgSrc
                      ? imgSrc
                      : singlePage &&
                        `${API_URL}/uploads/pages/${singlePage?.page_image}`
                  }
                  height={100}
                  width={100}
                />
              ) : (
                <span style={{ color: "red" }}>*Choose Image</span>
              )}
            </Grid>
            <Grid item xs={12}>
              <CheckBoxControl
                name="status"
                label="Active"
                value={values.status}
                onChange={(e) =>
                  setValues((prev = {}) => ({
                    ...prev,
                    status: e.target.value ? 1 : 0,
                  }))
                }
              />
            </Grid>
          </Grid>

          <div
            style={{
              display: "flex",
              justifyContent: "end",
              paddingTop: "10px",
              marginTop: "10px",
              borderTop: "1px solid #0b6885",
            }}
          >
            <Button
              variant="contained"
              onClick={() => setOpenEditPopup(false)}
              style={{ margin: "10px 0 0 10px", backgroundColor: "#ea3f4d" }}
            >
              CANCEL
            </Button>
            <Button
              variant="contained"
              type="submit"
              style={{ margin: "10px 0 0 10px", backgroundColor: "#0b6885" }}
            >
              SUBMIT
            </Button>
          </div>
        </>
      )}
    </Form>
  );
};

export default ManagePagesEditForm;

import {
  DELETE_TEAM_FAIL,
  DELETE_TEAM_REQUEST,
  DELETE_TEAM_RESET,
  DELETE_TEAM_SUCCESS,
  GET_ALL_TEAM_FAIL,
  GET_ALL_TEAM_REQUEST,
  GET_ALL_TEAM_RESET,
  GET_ALL_TEAM_SUCCESS,
  GET_SINGLE_TEAM_FAIL,
  GET_SINGLE_TEAM_REQUEST,
  GET_SINGLE_TEAM_RESET,
  GET_SINGLE_TEAM_SUCCESS,
  POST_TEAM_FAIL,
  POST_TEAM_REQUEST,
  POST_TEAM_RESET,
  POST_TEAM_SUCCESS,
  PUT_TEAM_FAIL,
  PUT_TEAM_REQUEST,
  PUT_TEAM_RESET,
  PUT_TEAM_SUCCESS,
} from "./TeamConstants";

export const getAllTeamReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_TEAM_REQUEST:
      return { loading: true };
    case GET_ALL_TEAM_SUCCESS:
      return { loading: false, allTeam: action.payload };
    case GET_ALL_TEAM_FAIL:
      return { loading: false, error: action.payload };
    case GET_ALL_TEAM_RESET:
      return {};
    default:
      return state;
  }
};

export const getSingleTeamReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_SINGLE_TEAM_REQUEST:
      return { loading: true };
    case GET_SINGLE_TEAM_SUCCESS:
      return { loading: false, singleTeam: action.payload };
    case GET_SINGLE_TEAM_FAIL:
      return { loading: false, error: action.payload };
    case GET_SINGLE_TEAM_RESET:
      return {};
    default:
      return state;
  }
};

export const postTeamReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_TEAM_REQUEST:
      return { loading: true };
    case POST_TEAM_SUCCESS:
      return { loading: false, success: action.payload };
    case POST_TEAM_FAIL:
      return { loading: false, error: action.payload };
    case POST_TEAM_RESET:
      return {};
    default:
      return state;
  }
};

export const putTeamReducer = (state = {}, action) => {
  switch (action.type) {
    case PUT_TEAM_REQUEST:
      return { loading: true };
    case PUT_TEAM_SUCCESS:
      return { loading: false, success: action.payload };
    case PUT_TEAM_FAIL:
      return { loading: false, error: action.payload };
    case PUT_TEAM_RESET:
      return {};
    default:
      return state;
  }
};

export const deleteTeamReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_TEAM_REQUEST:
      return { loading: true };
    case DELETE_TEAM_SUCCESS:
      return { loading: false, success: action.payload };
    case DELETE_TEAM_FAIL:
      return { loading: false, error: action.payload };
    case DELETE_TEAM_RESET:
      return {};
    default:
      return state;
  }
};

import React, { useState, useEffect } from "react";
import CustomContainer from "../../../components/CustomContainer";
import Topbar from "../../../components/Topbar";
import { useDispatch, useSelector } from "react-redux";
import useCustomTable from "../../../components/hooks/useCustomTable";
import { Toolbar, TableBody, Grid, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import BackspaceIcon from "@mui/icons-material/Backspace";
import LoadingComp from "../../../components/LoadingComp";
import { toast } from "react-toastify";
import {
  GET_INDIVIDUAL_MEMBERS_LIST_RESET,
  GET_MEMBER_PROFILES_RESET,
} from "./AllMembersConstant";
import {
  getEnterpriseBusinessPlanDocAdminAction,
  getEnterpriseMobilizationPlanDocAdminAction,
  getEnterprisePanVatDocAdminAction,
  getEnterpriseRegistrationCompDocAdminAction,
  getEnterpriseRevenueProfitabilityDocAdminAction,
  getSingleMemberProfileAction,
} from "./AllMembersAction";
import AllMemberProfileTableCollapse from "./AllMemberProfileTableCollapse";

const tableHeader = [
  { id: "profiles", label: "Profiles" },
  { id: "action", label: "Actions", disableSorting: true },
];

const AllMembersProfileDetails = () => {
  const [profileDetail, setProfileDetail] = useState(
    JSON.parse(localStorage.getItem("memberProfiles")) || {}
  );
  const [individualList, setIndividualList] = useState(
    JSON.parse(localStorage.getItem("membersList")) || {}
  );
  const [filterFn, setFilterFn] = useState({
    fn: (item) => {
      return item;
    },
  });
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const {
    individualMembers,
    error: individualMembersError,
    loading,
  } = useSelector((state) => state.getIndividualMembersList);

  const {
    memberProfiles,
    error: memberProfilesError,
    loading: loadingProfile,
  } = useSelector((state) => state.getMemberProfiles);

  if (individualMembersError) {
    toast.error("Error Occured!");
    dispatch({ type: GET_INDIVIDUAL_MEMBERS_LIST_RESET });
  }

  if (memberProfilesError) {
    toast.error("Error Occured!");
    dispatch({ type: GET_MEMBER_PROFILES_RESET });
  }

  const {
    TableContainer,
    TblHead,
    TblPagination,
    tableDataAfterPagingAndSorting,
  } = useCustomTable(profileDetail, tableHeader, filterFn);

  const backSpaceHandler = (e) => {
    e.preventDefault();
    navigate("/admin/members-list");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (individualMembers) {
      setIndividualList(individualMembers?.data);
    }
  }, [individualMembers]);

  useEffect(() => {
    if (memberProfiles) {
      setProfileDetail(memberProfiles?.data);
    }
  }, [memberProfiles]);

  const kycHandler = async (id) => {
    try {
      const res = await dispatch(getSingleMemberProfileAction(id));

      localStorage.setItem("kycValues", JSON.stringify(res?.data));

      const enterprise_kyc_id = res?.data?.enterprise_kyc?.enterprise_kyc_id;
      if (res?.data?.profile_type === "Enterprise") {
        dispatch(
          getEnterpriseBusinessPlanDocAdminAction(enterprise_kyc_id)
        ).then((res) => {
          localStorage.setItem("businessDocAdmin", JSON.stringify(res?.data));
        });
        dispatch(
          getEnterpriseRegistrationCompDocAdminAction(enterprise_kyc_id)
        ).then((res) => {
          localStorage.setItem("regFileAdmin", JSON.stringify(res?.data));
        });
        dispatch(getEnterprisePanVatDocAdminAction(enterprise_kyc_id)).then(
          (res) => {
            localStorage.setItem("panOrVatAdmin", JSON.stringify(res?.data));
          }
        );
        dispatch(
          getEnterpriseMobilizationPlanDocAdminAction(enterprise_kyc_id)
        ).then((res) => {
          localStorage.setItem("mobilizationAdmin", JSON.stringify(res?.data));
        });
        dispatch(
          getEnterpriseRevenueProfitabilityDocAdminAction(enterprise_kyc_id)
        ).then((res) => {
          localStorage.setItem("revenueAdmin", JSON.stringify(res?.data));
        });

        setTimeout(() => {
          navigate("/admin/members-list/profile/enterprise_kyc_profile");
        }, 1000);
      } else if (res?.data?.profile_type === "Investor") {
        navigate("/admin/members-list/profile/investor_kyc_profile");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <CustomContainer>
      <Toolbar>
        <Topbar title="Profile Detail" />{" "}
        <BackspaceIcon
          onClick={backSpaceHandler}
          style={{
            color: "#0b6885",
            marginBottom: "12px",
            cursor: "pointer",
            fontSize: "26px",
          }}
        />{" "}
      </Toolbar>
      {loading ? (
        <LoadingComp />
      ) : (
        <>
          <Grid container spacing={1} style={{ fontSize: "12px" }}>
            <Grid item xs={3}>
              <TextField
                InputProps={{
                  style: {
                    pointerEvents: "none",
                  },
                }}
                name="username"
                label="User Name"
                value={individualList?.username}
                style={{ width: "100%", marginLeft: "2%" }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                InputProps={{
                  style: {
                    pointerEvents: "none",
                  },
                }}
                name="first_name"
                label="First Name"
                style={{ width: "92%", marginLeft: "5%" }}
                value={individualList?.first_name}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                InputProps={{
                  style: {
                    pointerEvents: "none",
                  },
                }}
                name="last_name"
                label="Last Name"
                style={{ width: "92%", marginLeft: "5%" }}
                value={individualList?.last_name}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                InputProps={{
                  style: {
                    pointerEvents: "none",
                  },
                }}
                name="email"
                label="Email"
                value={individualList?.email}
                style={{ width: "100%", marginLeft: "2%" }}
              />
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={1} style={{ fontSize: "12px" }}>
            <Grid item xs={3}>
              <TextField
                InputProps={{
                  style: {
                    pointerEvents: "none",
                  },
                }}
                name="mobile"
                label="Mobile"
                value={individualList?.mobile}
                style={{ width: "100%", marginLeft: "2%" }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                InputProps={{
                  style: {
                    pointerEvents: "none",
                  },
                }}
                name="created_date"
                label="Created Date"
                style={{ width: "92%", marginLeft: "5%" }}
                value={individualList?.created_date?.slice(0, 10)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                InputProps={{
                  style: {
                    pointerEvents: "none",
                  },
                }}
                name="status"
                label="Status"
                style={{ width: "92%", marginLeft: "5%" }}
                value={individualList?.status ? "Active" : "InActive"}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                InputProps={{
                  style: {
                    pointerEvents: "none",
                  },
                }}
                name="is_subscribed"
                label="Subscribed ?"
                value={
                  individualList?.is_subscribed
                    ? "Subscribed"
                    : "Not Subscribed"
                }
                style={{ width: "100%", marginLeft: "2%" }}
              />
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={1} style={{ fontSize: "12px" }}>
            <Grid item xs={3}>
              <TextField
                InputProps={{
                  style: {
                    pointerEvents: "none",
                  },
                }}
                name="is_verified"
                label="Verified ?"
                value={individualList?.status ? "Verified" : "Unverified"}
                style={{ width: "100%", marginLeft: "2%" }}
              />
            </Grid>
          </Grid>
        </>
      )}
      {loadingProfile ? (
        <LoadingComp />
      ) : (
        <>
          <TableContainer style={{ margin: "2vw 0.5vw", padding: "24px 0" }}>
            <TblHead />
            <TableBody>
              {tableDataAfterPagingAndSorting().map((item) => (
                <AllMemberProfileTableCollapse
                  item={item}
                  key={item.unique_id}
                  kycHandler={kycHandler}
                />
              ))}
            </TableBody>
          </TableContainer>
          <TblPagination />
        </>
      )}
    </CustomContainer>
  );
};

export default AllMembersProfileDetails;

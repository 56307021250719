import React, { useEffect } from "react";
import { Grid, TextField } from "@mui/material";
import { Form, useForm } from "../../components/hooks/useForm";
import CheckBoxControl from "../../components/controls/CheckBoxControl";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { API_URL } from "../../api/axios";
import LoadingComp from "../../components/LoadingComp";

const NewsDetail = ({ loadingEditForm, singleNews }) => {
  const initialFormValues = {
    news_image: "",
    news_title: "",
    news_slug: "",
    news_description: "",
    status: 0,
  };

  const { values, setValues } = useForm(initialFormValues);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (singleNews) {
      setValues({ ...singleNews });
    }
  }, [singleNews, setValues]);

  return (
    <Form>
      {loadingEditForm ? (
        <LoadingComp />
      ) : (
        <>
          <Grid container spacing={2} style={{ fontSize: "12px" }}>
            <Grid item xs={12}>
              <TextField
                name="news_title"
                label="News Title"
                style={{ width: "100%" }}
                InputProps={{
                  style: {
                    pointerEvents: "none",
                  },
                }}
                value={values.news_title}
              />
            </Grid>
            <Grid item xs={12}>
              <CKEditor
                editor={ClassicEditor}
                id="news_description"
                disabled
                name="news_description"
                onReady={(editor) => {
                  editor.setData(values.news_description);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <CheckBoxControl
                name="status"
                label="Active"
                value={values.status}
                onChange={() => ""}
              />
            </Grid>

            <Grid item xs={12}>
              {singleNews?.news_image && singleNews?.news_image ? (
                <img
                  alt=""
                  src={
                    singleNews &&
                    `${API_URL}/uploads/news/${singleNews?.news_image}`
                  }
                  height={150}
                  width={150}
                />
              ) : (
                <span style={{ color: "red" }}>*Choose Image</span>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </Form>
  );
};

export default NewsDetail;

import React, { useEffect, useState } from "react";
import { Button, TableBody, Toolbar } from "@mui/material";
import useCustomTable from "../../components/hooks/useCustomTable";
import { Add } from "@mui/icons-material";
import Popup from "../../components/Popup";
import { useDispatch, useSelector } from "react-redux";
import LoadingComp from "../../components/LoadingComp";
import Notification from "../../components/Notification";
import ConfirmDialog from "../../components/ConfirmDialog";
import CustomContainer from "../../components/CustomContainer";
import {
  DELETE_USERS_RESET,
  GET_ALL_USERS_RESET,
  GET_SINGLE_USERS_RESET,
  POST_USERS_RESET,
  PUT_USERS_RESET,
} from "./ManageUsersConstants";
import {
  deleteUserAction,
  getAllUserAction,
  getSingleUserAction,
} from "./ManageUsersActions";
import ManageUsersTableCollapse from "./ManageUsersTableCollapse";
import ManageUsersForm from "./ManageUsersForm";
import Topbar from "../../components/Topbar";
import ManageUsersEditForm from "./ManageUsersEditForm";

const tableHeader = [
  // { id: "profile_image", label: "Profile Image" },
  { id: "first_name", label: "Full Name" },
  { id: "email_address", label: "Email Address" },
  { id: "mobile", label: "Phone Number" },
  { id: "created_date", label: "Created Date" },
  { id: "is_super_admin", label: "Super Admin" },
  { id: "status", label: "Status" },
  { id: "actions", label: "Actions", disableSorting: true },
];

const ManageUsers = () => {
  const [tableData, setTableData] = useState([]);
  const [filterFn, setFilterFn] = useState({
    fn: (item) => {
      return item;
    },
  });
  const [openAddPopup, setOpenAddPopup] = useState(false);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const dispatch = useDispatch();

  const { allUsers, error, loading } = useSelector((state) => state.getAllUser);

  const {
    singleUser,
    error: singleUserError,
    loading: loadingEditForm,
  } = useSelector((state) => state.getSingleUser);

  const {
    postUser,
    success: postUserSuccess,
    error: postUserError,
  } = useSelector((state) => state.postUsers);

  const { success: putUserSuccess, error: putUserError } = useSelector(
    (state) => state.putUsers
  );

  const { success: deleteUserSuccess, error: deleteUserError } = useSelector(
    (state) => state.deleteUsers
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (error) {
    setNotify({
      isOpen: true,
      message: error,
      type: "error",
    });
    dispatch({ type: GET_ALL_USERS_RESET });
  }

  if (singleUserError) {
    setNotify({
      isOpen: true,
      message: singleUserError,
      type: "error",
    });
    dispatch({ type: GET_SINGLE_USERS_RESET });
  }

  if (postUserError) {
    setNotify({
      isOpen: true,
      message: postUserError?.errors?.msg,
      type: "error",
    });
    dispatch({ type: POST_USERS_RESET });
  }

  if (putUserError) {
    setNotify({
      isOpen: true,
      message: putUserError,
      type: "error",
    });
    dispatch({ type: PUT_USERS_RESET });
  }

  if (deleteUserError) {
    setNotify({
      isOpen: true,
      message: deleteUserError,
      type: "error",
    });
    dispatch({ type: DELETE_USERS_RESET });
  }

  if (deleteUserSuccess) {
    dispatch(getAllUserAction());
    setNotify({
      isOpen: true,
      message: "User Deleted Successfully",
      type: "success",
    });
    dispatch({ type: DELETE_USERS_RESET });
  }

  if (postUserSuccess) {
    dispatch(getAllUserAction());
    setNotify({
      isOpen: true,
      message: "User Created Successfully",
      type: "success",
    });
    setOpenAddPopup(false);
    dispatch({ type: POST_USERS_RESET });
  }

  if (putUserSuccess) {
    dispatch(getAllUserAction());
    setNotify({
      isOpen: true,
      message: "User Edited Successfully",
      type: "success",
    });
    setOpenEditPopup(false);
    dispatch({ type: PUT_USERS_RESET });
  }

  useEffect(() => {
    if (!allUsers) {
      dispatch(getAllUserAction());
    }
    if (allUsers) {
      setTableData(allUsers?.data);
    }
  }, [dispatch, allUsers]);

  useEffect(() => {
    dispatch(getAllUserAction());
  }, [dispatch]);

  const {
    TableContainer,
    TblHead,
    TblPagination,
    tableDataAfterPagingAndSorting,
  } = useCustomTable(tableData, tableHeader, filterFn);

  const addUserHandler = () => {
    dispatch({ type: GET_SINGLE_USERS_RESET });
    setOpenAddPopup(true);
  };

  const updateUserHandler = (id) => {
    dispatch(getSingleUserAction(id));
    setOpenEditPopup(true);
  };

  const deleteUserHandler = (id) => {
    dispatch(deleteUserAction(id));
  };

  return (
    <>
      <CustomContainer>
        <Toolbar>
          <Topbar title="Users" subtitle="Manage all your employee here." />
          <Button
            variant="contained"
            startIcon={<Add />}
            style={{
              position: "absolute",
              right: "10px",
              backgroundColor: "#2a80f9",
            }}
            onClick={addUserHandler}
          >
            {" "}
            Add User
          </Button>
        </Toolbar>
        {loading ? (
          <LoadingComp />
        ) : (
          <>
            <TableContainer style={{ margin: "2vw 0.5vw", padding: "24px 0" }}>
              <TblHead />

              <TableBody>
                {tableDataAfterPagingAndSorting().map((item) => (
                  <ManageUsersTableCollapse
                    item={item}
                    key={item.unique_id}
                    updateUserHandler={updateUserHandler}
                    deleteUserHandler={deleteUserHandler}
                  />
                ))}
              </TableBody>
            </TableContainer>
            <TblPagination />
          </>
        )}
      </CustomContainer>
      <Popup
        openPopup={openAddPopup}
        setOpenPopup={setOpenAddPopup}
        title="Add User"
      >
        <ManageUsersForm
          postUser={postUser && postUser}
          singleUser={singleUser && singleUser?.data}
          setOpenAddPopup={setOpenAddPopup}
        />
      </Popup>
      <Popup
        openPopup={openEditPopup}
        setOpenPopup={setOpenEditPopup}
        title="Edit User"
      >
        <ManageUsersEditForm
          singleUser={singleUser && singleUser?.data}
          loadingEditForm={loadingEditForm}
          setOpenEditPopup={setOpenEditPopup}
        />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
};

export default ManageUsers;

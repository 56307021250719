import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { API_URL, axiosPublic } from "../api/axios";

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const returnLoginPage = (e) => {
    e.preventDefault();
    navigate("/admin/login");
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data } = await axiosPublic.post(
        `${API_URL}/api/v1/admin/auth/forgot_password`,
        {
          email: email,
        }
      );

      if (data?.status === 200) {
        toast.success(data.message);
        setTimeout(() => {
          navigate("/admin/reset_password");
        }, 1000);
      } else {
        setLoading(false);
        toast.error(data.message);
      }
    } catch (error) {
      // Handle any other errors that may occur during the request
      if (error.response) {
        // If the server responded with an error, display the error message
        toast.error(error.response.data.message);
      } else {
        // If the error is not from the server response, display a generic error message
        toast.error("An error occurred. Please try again later.");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container vh-100 d-flex align-items-center justify-content-center">
      <div className="auth" style={{ width: "40%", marginTop: "1px" }}>
        <div className="auth-body">
          <h4
            className="text-center"
            style={{ color: "#0b6885", fontSize: "26px" }}
          >
            Forgot Password?
          </h4>
          <br />
          <div className="mb-3">
            <label
              htmlFor="exampleInputEmail1"
              className="form-label"
              style={{ color: "black", fontSize: "18px" }}
            >
              Enter your Email to get Reset Link
            </label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="form-control"
              placeholder="@example: John@email.com"
              id="exampleInputEmail1"
              autoComplete="off"
              required
            />
          </div>

          <LoadingButton
            loading={loading}
            loadingPosition="end"
            disabled={!email}
            type="submit"
            onClick={submitHandler}
            className="btn"
            style={{
              width: "25%",
              backgroundColor: "#0b6885",
              color: "white",
              fontSize: "12px",
            }}
          >
            Submit
          </LoadingButton>
          <ToastContainer
            position="top-right"
            autoClose={2000}
            theme="colored"
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
          />
          <div>
            <Button
              onClick={returnLoginPage}
              style={{
                backgroundColor: "white",
                color: "black",
                fontSize: "14px",
                fontFamily: "inherit",
                textDecorationLine: "underline",
              }}
            >
              Return back to Login Page.
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;

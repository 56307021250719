import {
  DELETE_NEW_DEALS_FAIL,
  DELETE_NEW_DEALS_REQUEST,
  DELETE_NEW_DEALS_RESET,
  DELETE_NEW_DEALS_SUCCESS,
  GET_DETAILED_DEALS_FAIL,
  GET_DETAILED_DEALS_REQUEST,
  GET_DETAILED_DEALS_RESET,
  GET_DETAILED_DEALS_SUCCESS,
  GET_FILTER_DEALS_FAIL,
  GET_FILTER_DEALS_REQUEST,
  GET_FILTER_DEALS_RESET,
  GET_FILTER_DEALS_SUCCESS,
  POST_NEW_DEALS_FAIL,
  POST_NEW_DEALS_REQUEST,
  POST_NEW_DEALS_RESET,
  POST_NEW_DEALS_SUCCESS,
  PUT_NEW_DEALS_FAIL,
  PUT_NEW_DEALS_REQUEST,
  PUT_NEW_DEALS_RESET,
  PUT_NEW_DEALS_SUCCESS,
} from "./ManageDealsConstants";

export const getFilterDealsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_FILTER_DEALS_REQUEST:
      return { loading: true };
    case GET_FILTER_DEALS_SUCCESS:
      return { loading: false, filterDeals: action.payload };
    case GET_FILTER_DEALS_FAIL:
      return { loading: false, error: action.payload };
    case GET_FILTER_DEALS_RESET:
      return {};
    default:
      return state;
  }
};

export const getDetailDealsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_DETAILED_DEALS_REQUEST:
      return { loading: true };
    case GET_DETAILED_DEALS_SUCCESS:
      return { loading: false, detailDeal: action.payload };
    case GET_DETAILED_DEALS_FAIL:
      return { loading: false, error: action.payload };
    case GET_DETAILED_DEALS_RESET:
      return {};
    default:
      return state;
  }
};

export const postNewDealsReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_NEW_DEALS_REQUEST:
      return { loading: true };
    case POST_NEW_DEALS_SUCCESS:
      return { loading: false, success: true };
    case POST_NEW_DEALS_FAIL:
      return { loading: false, error: action.payload };
    case POST_NEW_DEALS_RESET:
      return {};
    default:
      return state;
  }
};

export const putDealsReducer = (state = {}, action) => {
  switch (action.type) {
    case PUT_NEW_DEALS_REQUEST:
      return { loading: true };
    case PUT_NEW_DEALS_SUCCESS:
      return { loading: false, success: true };
    case PUT_NEW_DEALS_FAIL:
      return { loading: false, error: action.payload };
    case PUT_NEW_DEALS_RESET:
      return {};
    default:
      return state;
  }
};

export const deleteDealsReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_NEW_DEALS_REQUEST:
      return { loading: true };
    case DELETE_NEW_DEALS_SUCCESS:
      return { loading: false, success: true };
    case DELETE_NEW_DEALS_FAIL:
      return { loading: false, error: action.payload };
    case DELETE_NEW_DEALS_RESET:
      return {};
    default:
      return state;
  }
};

import React, { useState, useRef, useEffect } from "react";
import UserHeader from "../components/UserHeader";
import UserFooter from "../components/UserFooter";
import { Helmet } from "react-helmet";
import ArrowButton from "../components/ArrowButton";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getAdvisorPublicAction,
  getAllPartnerPublicAction,
  getTeamsPublicAction,
} from "../public-routes/PublicRoutesActions";
import { API_URL } from "../../api/axios";
import { Tooltip } from "@mui/material";
import { useParams } from "react-router-dom";

const OurTeam = () => {
  const [aboutTeams, setAboutTeams] = useState([]);
  const [aboutAdvisor, setAboutAdvisor] = useState([]);
  const [aboutPartner, setAboutPartner] = useState([]);

  const cofounderRef1 = useRef();
  const cofounderRef2 = useRef();
  const { index } = useParams();

  const dispatch = useDispatch();

  const { allTeamsPublic } = useSelector((state) => state.getTeamsPublic);

  const { allAdvisorsPublic } = useSelector((state) => state.getAdvisorsPublic);

  const { allPartnersPublic } = useSelector((state) => state.getPartnersPublic);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (index !== undefined) {
      const parsedIndex = parseInt(index);
      if (!isNaN(parsedIndex)) {
        setTimeout(() => scrollToTeam(parsedIndex), 500);
      }
    }
  }, [index]);

  const scrollToTeam = (index) => {
    if (index === 0 && cofounderRef1.current) {
      setTimeout(() => {
        cofounderRef1.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 0);
    } else if (index === 1 && cofounderRef2.current) {
      setTimeout(() => {
        cofounderRef2.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 0);
    }
  };

  useEffect(() => {
    if (!allTeamsPublic) {
      dispatch(getTeamsPublicAction());
    } else {
      setAboutTeams(allTeamsPublic?.data);
    }
  }, [dispatch, allTeamsPublic]);

  useEffect(() => {
    if (!allAdvisorsPublic) {
      dispatch(getAdvisorPublicAction());
    } else {
      setAboutAdvisor(allAdvisorsPublic?.data);
    }
  }, [dispatch, allAdvisorsPublic]);

  useEffect(() => {
    if (!allPartnersPublic) {
      dispatch(getAllPartnerPublicAction());
    } else {
      setAboutPartner(allPartnersPublic?.data);
    }
  }, [dispatch, allPartnersPublic]);

  const cofounderFilter = aboutTeams.filter(
    (value) =>
      value.designation === "Co-Founder" || value.designation === "Co-founder"
  );

  useEffect(() => {
    dispatch(getTeamsPublicAction());
    dispatch(getAdvisorPublicAction());
    dispatch(getAllPartnerPublicAction());
  }, [dispatch]);

  return (
    <>
      <UserHeader />
      <Helmet>
        <meta charset="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="description" content="" />
        <meta name="author" content="" />

        <title>Million Deals</title>
        {/* <!-- Linking the font Poppins--> */}
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />

        {/* <!-- Linking Bootstrap Icon--> */}
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css"
        />
      </Helmet>
      <ArrowButton />
      <div className="page-title">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h1>Our Team</h1>
              <Link className="link" to="/">
                Home
              </Link>
              {""} / Our Team
            </div>
          </div>
        </div>
      </div>

      <div className="about-team" ref={cofounderRef1}>
        <div className="container">
          <div className="row">
            {cofounderFilter &&
              cofounderFilter?.slice(0, 1).map((value) => (
                <>
                  <div className="col-md-5" key={value.team_id}>
                    {value.image ? (
                      <img
                        alt=""
                        style={{ objectFit: "cover" }}
                        src={`${API_URL}/uploads/teams/${value.image}`}
                      />
                    ) : (
                      <img
                        alt=""
                        style={{ objectFit: "cover" }}
                        src="https://i.postimg.cc/vZvJdnRS/blank-head-profile-pic-for-a-man.jpg"
                      />
                    )}
                  </div>
                  <div className="col-md-7">
                    <h2 className="section-title">{value?.name}</h2>
                    <h3 className="section-subtitle">{value?.designation}</h3>
                    <a
                      href="https://www.linkedin.com/in/swatipujari/"
                      className="linked-inn"
                      rel="noreferrer"
                    >
                      <i className="bi bi-linkedin"></i>
                    </a>
                    <p
                      className="section-text-team"
                      style={{ textAlign: "justify" }}
                      dangerouslySetInnerHTML={{ __html: value?.description }}
                    ></p>
                  </div>
                </>
              ))}
          </div>
        </div>
      </div>

      <div className="about-team" ref={cofounderRef2}>
        <div className="container">
          <div className="row">
            {cofounderFilter &&
              cofounderFilter?.slice(1, 2).map((value) => (
                <>
                  <div className="col-md-7" key={value.team_id}>
                    <h2 className="section-title">{value?.name}</h2>
                    <h3 className="section-subtitle">{value?.designation}</h3>
                    <a
                      href="https://www.linkedin.com/in/ronia-tandukar-ba0b26106/"
                      className="linked-inn"
                      rel="noreferrer"
                    >
                      <i className="bi bi-linkedin"></i>
                    </a>
                    <p
                      className="section-text-team"
                      style={{ textAlign: "justify" }}
                      dangerouslySetInnerHTML={{ __html: value?.description }}
                    ></p>
                  </div>
                  <div className="col-md-5">
                    {value.image ? (
                      <img
                        alt=""
                        style={{ objectFit: "cover" }}
                        src={`${API_URL}/uploads/teams/${value.image}`}
                      />
                    ) : (
                      <img
                        alt=""
                        style={{ objectFit: "cover" }}
                        src="https://i.postimg.cc/vZvJdnRS/blank-head-profile-pic-for-a-man.jpg"
                      />
                    )}
                  </div>
                </>
              ))}
          </div>
        </div>
      </div>

      <div className="team">
        {aboutAdvisor?.length > 0 && (
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <h2 className="section-title">Our Advisors </h2>
              </div>
            </div>
            <div className="row pt-3 justify-content-center">
              {aboutAdvisor &&
                aboutAdvisor?.map((item) => (
                  <div
                    className="col-md-6 col-lg-6 col-xl-3"
                    key={item.advisor_id}
                  >
                    <div className="team-single">
                      {item.image ? (
                        <img
                          alt=""
                          src={`${API_URL}/uploads/advisors/${item.image}`}
                        />
                      ) : (
                        <img
                          alt=""
                          src="https://i.postimg.cc/vZvJdnRS/blank-head-profile-pic-for-a-man.jpg"
                        />
                      )}
                      <h3>{item?.name}</h3>
                      <Tooltip title={item?.designation}>
                        <h5>
                          {item?.designation.length > 15
                            ? item?.designation.slice(0, 15) + "..."
                            : item?.designation}
                        </h5>
                      </Tooltip>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>

      <div className="partner">
        {aboutPartner?.length > 0 && (
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <h2 className="section-title">Our Partners</h2>
              </div>
            </div>
            <div className="row pt-4 justify-content-center">
              {aboutPartner?.map((item) => (
                <div
                  className="col-md-6 col-lg-6 col-xl-3"
                  key={item.partner_id}
                >
                  <div className="partner-single">
                    {item.logo_image ? (
                      <img
                        alt=""
                        src={`${API_URL}/uploads/partners/${item.logo_image}`}
                        style={{ aspectRatio: "2/1", borderRadius: "9%" }}
                      />
                    ) : (
                      <img
                        alt=""
                        style={{ aspectRatio: "2/1", borderRadius: "9%" }}
                        src="https://i.postimg.cc/vZvJdnRS/blank-head-profile-pic-for-a-man.jpg"
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <UserFooter />
    </>
  );
};

export default OurTeam;

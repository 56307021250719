export const GET_DETAILED_DEALS_PRIVATE_REQUEST =
  "GET_DETAILED_DEALS_PRIVATE_REQUEST";
export const GET_DETAILED_DEALS_PRIVATE_SUCCESS =
  "GET_DETAILED_DEALS_PRIVATE_SUCCESS";
export const GET_DETAILED_DEALS_PRIVATE_FAIL =
  "GET_DETAILED_DEALS_PRIVATE_FAIL";
export const GET_DETAILED_DEALS_PRIVATE_RESET =
  "GET_DETAILED_DEALS_PRIVATE_RESET";

export const POST_PROFILE_DATA_REQUEST = "POST_PROFILE_DATA_REQUEST";
export const POST_PROFILE_DATA_SUCCESS = "POST_PROFILE_DATA_SUCCESS";
export const POST_PROFILE_DATA_FAIL = "POST_PROFILE_DATA_FAIL";
export const POST_PROFILE_DATA_RESET = "POST_PROFILE_DATA_RESET";

export const POST_CHANGE_PASSWORD_REQUEST = "POST_CHANGE_PASSWORD_REQUEST";
export const POST_CHANGE_PASSWORD_SUCCESS = "POST_CHANGE_PASSWORD_SUCCESS";
export const POST_CHANGE_PASSWORD_FAIL = "POST_CHANGE_PASSWORD_FAIL";
export const POST_CHANGE_PASSWORD_RESET = "POST_CHANGE_PASSWORD_RESET";

export const POST_SHOW_INTEREST_REQUEST = "POST_SHOW_INTEREST_REQUEST";
export const POST_SHOW_INTEREST_SUCCESS = "POST_SHOW_INTEREST_SUCCESS";
export const POST_SHOW_INTEREST_FAIL = "POST_SHOW_INTEREST_FAIL";
export const POST_SHOW_INTEREST_RESET = "POST_SHOW_INTEREST_RESET";

export const GET_ALL_DEAL_CATEGORY_FILTER_REQUEST =
  "GET_ALL_DEAL_CATEGORY_FILTER_REQUEST";
export const GET_ALL_DEAL_CATEGORY_FILTER_SUCCESS =
  "GET_ALL_DEAL_CATEGORY_FILTER_SUCCESS";
export const GET_ALL_DEAL_CATEGORY_FILTER_FAIL =
  "GET_ALL_DEAL_CATEGORY_FILTER_FAIL";
export const GET_ALL_DEAL_CATEGORY_FILTER_RESET =
  "GET_ALL_DEAL_CATEGORY_FILTER_RESET";

export const GET_ALL_SDGS_FILTER_LIST_REQUEST =
  "GET_ALL_SDGS_FILTER_LIST_REQUEST";
export const GET_ALL_SDGS_FILTER_LIST_SUCCESS =
  "GET_ALL_SDGS_FILTER_LIST_SUCCESS";
export const GET_ALL_SDGS_FILTER_LIST_FAIL = "GET_ALL_SDGS_FILTER_LIST_FAIL";
export const GET_ALL_SDGS_FILTER_LIST_RESET = "GET_ALL_SDGS_FILTER_LIST_RESET";

export const GET_DETAILED_PERSONAL_INFO_REQUEST =
  "GET_DETAILED_PERSONAL_INFO_REQUEST";
export const GET_DETAILED_PERSONAL_INFO_SUCCESS =
  "GET_DETAILED_PERSONAL_INFO_SUCCESS";
export const GET_DETAILED_PERSONAL_INFO_FAIL =
  "GET_DETAILED_PERSONAL_INFO_FAIL";
export const GET_DETAILED_PERSONAL_INFO_RESET =
  "GET_DETAILED_PERSONAL_INFO_RESET";

export const GET_SHOW_INTERESTED_DEALS_REQUEST =
  "GET_SHOW_INTERESTED_DEALS_REQUEST";
export const GET_SHOW_INTERESTED_DEALS_SUCCESS =
  "GET_SHOW_INTERESTED_DEALS_SUCCESS";
export const GET_SHOW_INTERESTED_DEALS_FAIL = "GET_SHOW_INTERESTED_DEALS_FAIL";
export const GET_SHOW_INTERESTED_DEALS_RESET =
  "GET_SHOW_INTERESTED_DEALS_RESET";

export const GET_PROFILE_AS_DEALS_BY_ID_REQUEST =
  "GET_PROFILE_AS_DEALS_BY_ID_REQUEST";
export const GET_PROFILE_AS_DEALS_BY_ID_SUCCESS =
  "GET_PROFILE_AS_DEALS_BY_ID_SUCCESS";
export const GET_PROFILE_AS_DEALS_BY_ID_FAIL =
  "GET_PROFILE_AS_DEALS_BY_ID_FAIL";
export const GET_PROFILE_AS_DEALS_BY_ID_RESET =
  "GET_PROFILE_AS_DEALS_BY_ID_RESET";

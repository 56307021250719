import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import LoadingComp from "../../components/LoadingComp";
import ArrowButton from "../components/ArrowButton";
import {
  getAllSdgsFilterAction,
  getDetailedPersonalInfoAction,
  postShowInterestAction,
} from "../private-routes/PrivateActions";
import { Chip } from "@mui/material";

const provinceList = [
  { province_id: 1, label: "Province No. 1" },
  { province_id: 2, label: "Madhesh Pradesh" },
  { province_id: 3, label: "Bagmati Pradesh" },
  { province_id: 4, label: "Gandaki Pradesh" },
  { province_id: 5, label: "Lumbini Pradesh" },
  { province_id: 6, label: "Karnali Pradesh" },
  { province_id: 7, label: "Sudur Pashchim Pradesh" },
];

const SingleDeals = () => {
  const [singleDeals, setSingleDeals] = useState(
    JSON.parse(localStorage.getItem("singleDealsClient")) || {}
  );
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState("");
  const [values, setValues] = useState({});
  const [sdgList, setSdgList] = useState([]);

  const dispatch = useDispatch();

  const { allSdgsFilter } = useSelector((state) => state.getAllSdgsFilterList);

  const { detailedPersonalInfo } = useSelector(
    (state) => state.getDetailedPersonalInfo
  );

  const { detailDealPrivate, loading } = useSelector(
    (state) => state.getDetailDealsPrivate
  );

  const initialFormValues = {
    deal_id: singleDeals?.deal_id,
    member_id: values?.member_id,
    message: message,
  };

  useEffect(() => {
    if (!allSdgsFilter) {
      dispatch(getAllSdgsFilterAction());
    }
    if (allSdgsFilter) {
      setSdgList(allSdgsFilter?.data);
    }
  }, [allSdgsFilter, dispatch]);

  useEffect(() => {
    if (detailDealPrivate) {
      setSingleDeals(detailDealPrivate?.data);
    }
  }, [dispatch, detailDealPrivate]);

  useEffect(() => {
    if (!detailedPersonalInfo) {
      dispatch(getDetailedPersonalInfoAction());
    }
    if (detailedPersonalInfo) {
      setValues(detailedPersonalInfo?.data[0]);
    }
  }, [detailedPersonalInfo, dispatch]);

  useEffect(() => {
    dispatch(getAllSdgsFilterAction());
    dispatch(getDetailedPersonalInfoAction());
  }, [dispatch]);

  const sdgNames = sdgList
    ?.filter((sdg) => singleDeals?.sdg?.includes(sdg?.sdg_id))
    ?.map((sdg) => (
      <Chip
        style={{ backgroundColor: "#0b6885", color: "#fff" }}
        className="me-1 mb-2"
        label={sdg?.sdg_name}
      />
    ));

  const submitInterestHandler = async (e) => {
    e.preventDefault();
    let errors = {};
    if (!message) {
      errors["message"] = "Message is required";
    }
    setErrors(errors);

    if (Object.keys(errors).length === 0) {
      try {
        // Dispatch your action here
        dispatch(postShowInterestAction(initialFormValues));

        // If the action is successful, show the toast message
        toast.success("Thank you for your interest");
        setMessage("");
      } catch (error) {
        // Handle any error that might occur during the action dispatch
        console.error("Error:", error);
      }
    }
  };

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="description" content="" />
        <meta name="author" content="" />

        <title>Million Deals</title>
        {/* <!-- Linking the font Poppins--> */}
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />

        {/* <!-- Linking Bootstrap Icon--> */}
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css"
        />
      </Helmet>
      <ArrowButton />
      <div className="page-title">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h1>Single Deal</h1>
              <Link className="link" to="/">
                Home
              </Link>
              {""} /{" "}
              <Link className="link" to="/deals-listing">
                Deals Listings
              </Link>
              {""} / Single Deals
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <LoadingComp />
      ) : (
        <>
          <div className="page-content">
            <div className="container">
              <div className="row">
                <div className="col-md-8">
                  <div className="single-deal">
                    <h2>{singleDeals?.deal_title}</h2>
                    <div className="deal-details">
                      <div className="row pt-3">
                        <div className="col-6">
                          <b>Deal Title:</b>
                        </div>
                        <div className="col-6">{singleDeals?.deal_title}</div>
                      </div>
                      <div className="row pt-3">
                        <div className="col-6">
                          <b>Deal Overview:</b>
                        </div>
                        <div className="col-6">
                          {singleDeals?.deal_overview}
                        </div>
                      </div>
                      <div className="row pt-3">
                        <div className="col-6">
                          <b>Company Name:</b>
                        </div>
                        <div className="col-6">Available after connect</div>
                      </div>
                      <div className="row pt-3">
                        <div className="col-6">
                          <b>Contact Details:</b>
                        </div>
                        <div className="col-6">Available after connect</div>
                      </div>

                      <div className="row pt-3">
                        <div className="col-6">
                          <b>Sector:</b>
                        </div>
                        <div className="col-6">
                          {singleDeals?.deal_category}
                        </div>
                      </div>

                      <div className="row pt-3">
                        <div className="col-6">
                          <b>Company Operate (Province):</b>
                        </div>
                        <div className="col-6">
                          {
                            provinceList.find(
                              (province) =>
                                province.province_id ===
                                singleDeals?.company_operate
                            )?.label
                          }
                        </div>
                      </div>

                      <div className="row pt-3">
                        <div className="col-6">
                          <b>Enterprise structure:</b>
                        </div>
                        <div className="col-6">
                          {/* {singleDeals?.location} */}Idea Stage
                        </div>
                      </div>

                      <div className="row pt-3">
                        <div className="col-6">
                          <b>Years since incorporation:</b>
                        </div>
                        <div className="col-6">
                          {singleDeals.established_date.slice(0, 10)}
                        </div>
                      </div>

                      <div className="row pt-3">
                        <div className="col-6">
                          <b>HQ Location:</b>
                        </div>
                        <div className="col-6">{singleDeals?.location}</div>
                      </div>

                      <div className="row pt-3">
                        <div className="col-6">
                          <b>Sustainability focus:</b>
                        </div>
                        <div className="col-6">{sdgNames}</div>
                      </div>

                      <div className="row pt-3">
                        <div className="col-6">
                          <b>Investment Size:</b>
                        </div>
                        <div className="col-6">{singleDeals?.amount}</div>
                      </div>

                      <div className="row pt-3">
                        <div className="col-6">
                          <b>Preferred investment structure:</b>
                        </div>
                        <div className="col-6">Equity</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="show-interest">
                    <h4>Show Interest</h4>
                    <form onSubmit={submitInterestHandler}>
                      Message or Query
                      <textarea
                        className={`form-control ${
                          errors["message"] ? "is-invalid" : ""
                        }`}
                        id="message"
                        value={message}
                        placeholder="Explain the service you are looking for..."
                        onChange={(e) => setMessage(e.target.value)}
                      >
                        {" "}
                      </textarea>
                      {errors["message"] && (
                        <div className="invalid-feedback">
                          {errors["message"]}
                        </div>
                      )}
                      <button
                        type="submit"
                        className="blue-btn"
                        style={{ marginTop: "10%", width: "100%" }}
                      >
                        Continue
                      </button>
                      <ToastContainer
                        position="top-right"
                        autoClose={2000}
                        theme="colored"
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SingleDeals;

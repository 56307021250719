import { API_URL, tokenConfig, axiosAdminPrivate } from "../../api/axios";
import {
  GET_ALL_CONTACTS_FAIL,
  GET_ALL_CONTACTS_REQUEST,
  GET_ALL_CONTACTS_SUCCESS,
  GET_SINGLE_CONTACTS_FAIL,
  GET_SINGLE_CONTACTS_REQUEST,
  GET_SINGLE_CONTACTS_SUCCESS,
} from "./ManageContactsConstants";

export const getAllContactsAction = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_CONTACTS_REQUEST });

    const { data } = await axiosAdminPrivate.get(
      `${API_URL}/api/v1/admin/contacts`
    );

    dispatch({ type: GET_ALL_CONTACTS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_ALL_CONTACTS_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

export const getSingleContactsAction = (unique_id) => async (dispatch) => {
  try {
    dispatch({ type: GET_SINGLE_CONTACTS_REQUEST });

    const { data } = await axiosAdminPrivate.get(
      `${API_URL}/api/v1/admin/contacts/${unique_id}`
    );

    dispatch({ type: GET_SINGLE_CONTACTS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_SINGLE_CONTACTS_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

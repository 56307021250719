import {
  GET_ALL_CONTACTS_FAIL,
  GET_ALL_CONTACTS_REQUEST,
  GET_ALL_CONTACTS_RESET,
  GET_ALL_CONTACTS_SUCCESS,
  GET_SINGLE_CONTACTS_FAIL,
  GET_SINGLE_CONTACTS_REQUEST,
  GET_SINGLE_CONTACTS_RESET,
  GET_SINGLE_CONTACTS_SUCCESS,
} from "./ManageContactsConstants";

export const getAllContactsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_CONTACTS_REQUEST:
      return { loading: true };
    case GET_ALL_CONTACTS_SUCCESS:
      return { loading: false, allContacts: action.payload };
    case GET_ALL_CONTACTS_FAIL:
      return { loading: false, error: action.payload };
    case GET_ALL_CONTACTS_RESET:
      return {};
    default:
      return state;
  }
};

export const getSingleContactReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_SINGLE_CONTACTS_REQUEST:
      return { loading: true };
    case GET_SINGLE_CONTACTS_SUCCESS:
      return { loading: false, singleContact: action.payload };
    case GET_SINGLE_CONTACTS_FAIL:
      return { loading: false, error: action.payload };
    case GET_SINGLE_CONTACTS_RESET:
      return {};
    default:
      return state;
  }
};

import React, { useState, useEffect, useMemo, createRef } from "react";
import ArrowButton from "../../../frontend/components/ArrowButton";
import { useDispatch, useSelector } from "react-redux";
import { getAllDealCategoryAction } from "../../manage-deals/manage-deals-category/ManageDealsCategoryActions";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import FilterIcon from "@mui/icons-material/Filter";
import {
  getAllSdgsFilterAdminAction,
  getEnterpriseMobilizationPlanDocAdminAction,
  postEnterpriseBusinessPlanAdminAction,
  postEnterpriseMobilizationPlanDocsAdminAction,
  postEnterprisePanOrVatDocAdminAction,
  postEnterpriseRegistrationCompAdminAction,
  postEnterpriseRevenueProfitabilityDocsAdminAction,
  putEnterpriseBusinessPlanAdminAction,
  putEnterpriseKycFormAdminAction,
  putEnterpriseMobilizationPlanDocsAdminAction,
  putEnterprisePanOrVatDocAdminAction,
  putEnterpriseRegistrationCompAdminAction,
  putEnterpriseRevenueProfitabilityDocsAdminAction,
} from "./AllMembersAction";
import { ToastContainer, toast } from "react-toastify";
import { API_URL } from "../../../api/axios";
import { useNavigate } from "react-router-dom";
import { getEnterpriseBusinessPlanDocAdminAction } from "./AllMembersAction";
import { getEnterpriseRegistrationCompDocAdminAction } from "./AllMembersAction";
import { getEnterprisePanVatDocAdminAction } from "./AllMembersAction";
import { getEnterpriseRevenueProfitabilityDocAdminAction } from "./AllMembersAction";

const nonFinancialList = [
  {
    id: 1,
    label: "Financial projections and valuation",
  },
  { id: 2, label: "Branding" },
  { id: 3, label: "Legal Services" },
  { id: 4, label: "Accounting Services" },
  { id: 5, label: "Auditing and Taxation Services" },
  { id: 6, label: "Not seeking any non-financial support" },
  { id: 7, label: "Others" },
];

const provinceList = [
  {
    id: 1,
    label: "Province 1",
  },
  {
    id: 2,
    label: "Madhesh Pradesh",
  },
  {
    id: 3,
    label: "Bagmati Pradesh",
  },
  { id: 4, label: "Gandaki Pradesh" },
  {
    id: 5,
    label: "Lumbini Pradesh",
  },
  {
    id: 6,
    label: "Karnali Pradesh",
  },
  { id: 7, label: "Sudurpaschim Pradesh" },
];
const MAX_COUNT = 5;

const UpdateEnterpriseKYCFormAdmin = ({
  formData,
  setFormData,

  setOpenEnterpriseKycPopup,
}) => {
  //docs states
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [panOrVatFiles, setPanOrVatFiles] = useState([]);
  const [regFiles, setRegFiles] = useState([]);
  const [mobFiles, setMobFiles] = useState([]);
  const [revFiles, setRevFiles] = useState([]);
  const [fileLimit, setFileLimit] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [sdgList, setSdgList] = useState([]);

  //api get docs
  const [adminMob, setAdminMob] = useState([]);
  const [adminReg, setAdminReg] = useState([]);
  const [adminRev, setAdminRev] = useState([]);
  const [adminPan, setAdminPan] = useState([]);
  const [adminBuss, setAdminBuss] = useState([]);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { sdgListAdmin } = useSelector((state) => state.getSdgListAdmin);

  const { allDealsCategory } = useSelector((state) => state.getAllDealCategory);

  const { adminBusinessDoc } = useSelector(
    (state) => state.getEnterpriseBusinessPlanDocAdmin
  );
  const { adminRegDoc } = useSelector(
    (state) => state.getEnterpriseRegistrationCompDocAdmin
  );
  const { adminPanVatDoc } = useSelector(
    (state) => state.getEnterprisePanVatDocAdmin
  );
  const { adminMobilization } = useSelector(
    (state) => state.getEnterpriseMobilizationPlanDocAdmin
  );
  const { adminRevenue } = useSelector(
    (state) => state.getEnterpriseRevenueProfitabilityDocAdmin
  );

  const enterpriseId = formData?.enterprise_kyc_id;

  useEffect(() => {
    if (!adminBusinessDoc) {
      dispatch(getEnterpriseBusinessPlanDocAdminAction(enterpriseId));
    } else {
      setAdminBuss(adminBusinessDoc?.data);
    }
  }, [dispatch, adminBusinessDoc, enterpriseId]);

  useEffect(() => {
    if (!adminRegDoc) {
      dispatch(getEnterpriseRegistrationCompDocAdminAction(enterpriseId));
    } else {
      setAdminReg(adminRegDoc?.data);
    }
  }, [dispatch, adminRegDoc, enterpriseId]);

  useEffect(() => {
    if (!adminPanVatDoc) {
      dispatch(getEnterprisePanVatDocAdminAction(enterpriseId));
    } else {
      setAdminPan(adminPanVatDoc?.data);
    }
  }, [dispatch, adminPanVatDoc, enterpriseId]);

  useEffect(() => {
    if (!adminMobilization) {
      dispatch(getEnterpriseMobilizationPlanDocAdminAction(enterpriseId));
    } else {
      setAdminMob(adminMobilization?.data);
    }
  }, [dispatch, adminMobilization, enterpriseId]);

  useEffect(() => {
    if (!adminRevenue) {
      dispatch(getEnterpriseRevenueProfitabilityDocAdminAction(enterpriseId));
    } else {
      setAdminRev(adminRevenue?.data);
    }
  }, [dispatch, adminRevenue, enterpriseId]);

  useEffect(() => {
    if (!allDealsCategory) {
      dispatch(getAllDealCategoryAction());
    }
    if (allDealsCategory) {
      setCategoryList(allDealsCategory?.data);
    }
  }, [allDealsCategory, dispatch]);

  useEffect(() => {
    if (!sdgListAdmin) {
      dispatch(getAllSdgsFilterAdminAction());
    }
    if (sdgListAdmin) {
      setSdgList(sdgListAdmin?.data);
    }
  }, [sdgListAdmin, dispatch]);

  const handleSdgChange = (e) => {
    const { name, checked } = e.target;
    if (checked) {
      setFormData((prevData) => {
        const sdgSupportList = Array.isArray(prevData.sdg) ? prevData.sdg : [];

        if (!sdgSupportList.includes(JSON.parse(name))) {
          sdgSupportList.push(JSON.parse(name));
        }
        return {
          ...prevData,
          sdg: sdgSupportList,
        };
      });
    } else {
      setFormData((prevData) => {
        const sdgSupportList = Array.isArray(prevData.sdg) ? prevData.sdg : [];
        const index = sdgSupportList.indexOf(JSON.parse(name));
        if (index !== -1) {
          sdgSupportList.splice(index, 1);
        }
        return {
          ...prevData,
          sdg: sdgSupportList,
        };
      });
    }
  };

  const handleNonFinancialChange = (e) => {
    const { name, checked } = e.target;
    if (checked) {
      setFormData((prevData) => {
        const nonFinancialSupportList = Array.isArray(
          prevData.non_financial_support
        )
          ? prevData.non_financial_support
          : [];
        if (!nonFinancialSupportList.includes(Number(name))) {
          nonFinancialSupportList.push(Number(name));
        }
        return {
          ...prevData,
          non_financial_support: nonFinancialSupportList,
        };
      });
    } else {
      setFormData((prevData) => {
        const nonFinancialSupportList = Array.isArray(
          prevData.non_financial_support
        )
          ? prevData.non_financial_support
          : [];
        const index = nonFinancialSupportList.indexOf(Number(name));
        if (index !== -1) {
          nonFinancialSupportList.splice(index, 1);
        }
        return {
          ...prevData,
          non_financial_support: nonFinancialSupportList,
        };
      });
    }
  };

  const handleUploadFiles = (files) => {
    const uploaded = [...regFiles];
    let limitExceeded = false;
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        if (uploaded.length === MAX_COUNT) setFileLimit(true);
        if (uploaded.length > MAX_COUNT) {
          toast.error(`You can only add a maximum of ${MAX_COUNT} files`);
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
      }
    });
    if (!limitExceeded) {
      setRegFiles(uploaded);
      setAdminReg({
        ...adminReg,
        registration_of_company_docs: uploaded,
      });
    }
  };

  const handleFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };

  const fileRegInputRefs = useMemo(
    () =>
      Object.values(adminReg)?.map((data) =>
        createRef(data.registration_of_company_doc_id)
      ),
    [adminReg]
  );

  const handleUpdateRegFile = (e, id) => {
    let inputFile = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(inputFile);
    dispatch(putEnterpriseRegistrationCompAdminAction(inputFile, id));
  };

  const handleUploadBusinessDoc = (files) => {
    const uploaded = [...uploadedFiles];
    let limitExceeded = false;
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        if (uploaded.length === MAX_COUNT) setFileLimit(true);
        if (uploaded.length > MAX_COUNT) {
          toast.error(`You can only add a maximum of ${MAX_COUNT} files`);
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
      }
    });
    if (!limitExceeded) {
      setUploadedFiles(uploaded);
      setAdminBuss({
        ...adminBuss,
        business_plan_docs: uploaded,
      });
    }
  };

  const handleBusinessFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadBusinessDoc(chosenFiles);
  };

  const fileBussInputRefs = useMemo(
    () =>
      Object.values(adminBuss)?.map((data) =>
        createRef(data.business_plan_doc_id)
      ),
    [adminBuss]
  );

  const handleUpdateBussFile = (e, id) => {
    let inputFile = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(inputFile);
    dispatch(putEnterpriseBusinessPlanAdminAction(inputFile, id));
  };

  const handleUploadPanVatFiles = (files) => {
    const uploaded = [...panOrVatFiles];
    let limitExceeded = false;
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        if (uploaded.length === MAX_COUNT) setFileLimit(true);
        if (uploaded.length > MAX_COUNT) {
          toast.error(`You can only add a maximum of ${MAX_COUNT} files`);
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
      }
    });
    if (!limitExceeded) {
      setPanOrVatFiles(uploaded);
      setAdminPan({
        ...adminPan,
        charter_docs: uploaded,
      });
    }
  };

  const handlePanVatFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadPanVatFiles(chosenFiles);
  };

  const filePanInputRefs = useMemo(
    () =>
      Object.values(adminPan)?.map((data) => createRef(data.charter_doc_id)),
    [adminPan]
  );

  const handleUpdatePanFile = (e, id) => {
    let inputFile = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(inputFile);
    dispatch(putEnterprisePanOrVatDocAdminAction(inputFile, id));
  };

  const handleUploadRevenueFiles = (files) => {
    const uploaded = [...revFiles];
    let limitExceeded = false;
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        if (uploaded.length === MAX_COUNT) setFileLimit(true);
        if (uploaded.length > MAX_COUNT) {
          toast.error(`You can only add a maximum of ${MAX_COUNT} files`);
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
      }
    });
    if (!limitExceeded) {
      setRevFiles(uploaded);
      setAdminRev({
        ...adminRev,
        revenue_profitability_docs: uploaded,
      });
    }
  };

  const handleRevenueFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadRevenueFiles(chosenFiles);
  };

  const fileRevInputRefs = useMemo(
    () => Object.values(adminRev)?.map((data) => createRef()),
    [adminRev]
  );

  const handleUpdateRevFile = (e, id) => {
    let inputFile = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(inputFile);
    dispatch(putEnterpriseRevenueProfitabilityDocsAdminAction(inputFile, id));
  };

  const handleUploadMobFiles = (files) => {
    const uploaded = [...mobFiles];
    let limitExceeded = false;
    files.some((file) => {
      const index = uploaded.findIndex((f) => f.name === file.name);
      if (index === -1) {
        uploaded.push(file);
        if (uploaded.length === MAX_COUNT) setFileLimit(true);
        if (uploaded.length > MAX_COUNT) {
          toast.error(`You can only add a maximum of ${MAX_COUNT} files`);
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
      }
    });

    if (!limitExceeded) {
      setMobFiles(uploaded);

      setAdminMob({
        ...adminMob,
        mobilization_plan_docs: uploaded,
      });
    }
  };

  const handleMobFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadMobFiles(chosenFiles);
  };

  const fileInputRefs = useMemo(
    () => Object.values(adminMob)?.map((data) => createRef()),
    [adminMob]
  );

  const handleUpdateMobFile = (e, id) => {
    let inputFile = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(inputFile);
    dispatch(putEnterpriseMobilizationPlanDocsAdminAction(inputFile, id));
  };

  useEffect(() => {
    dispatch(getAllDealCategoryAction());
    dispatch(getAllSdgsFilterAdminAction());
  }, [dispatch, enterpriseId]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (Object.keys(formData).length === 0 || formData === "") {
      window.alert("Please fill out the form!");
    } else {
      dispatch(
        putEnterpriseKycFormAdminAction(formData, formData?.enterprise_kyc_id)
      ).then((res) => {
        const enterpriseKycId = res?.data?.enterprise_kyc_id;

        if (adminPan?.charter_docs) {
          const actionCreator = adminPan?.charter_doc_id
            ? putEnterprisePanOrVatDocAdminAction
            : postEnterprisePanOrVatDocAdminAction;

          dispatch(actionCreator(adminPan.charter_docs, enterpriseKycId));
        }

        if (adminMob?.mobilization_plan_docs) {
          const actionCreator = adminMob.mobilization_plan_doc_id
            ? putEnterpriseMobilizationPlanDocsAdminAction
            : postEnterpriseMobilizationPlanDocsAdminAction;

          dispatch(
            actionCreator(adminMob?.mobilization_plan_docs, enterpriseKycId)
          );
        }

        if (adminRev?.revenue_profitability_docs) {
          const actionCreator = adminRev?.revenue_profitability_doc_id
            ? putEnterpriseRevenueProfitabilityDocsAdminAction
            : postEnterpriseRevenueProfitabilityDocsAdminAction;

          dispatch(
            actionCreator(adminRev?.revenue_profitability_docs, enterpriseKycId)
          );
        }

        if (adminBuss?.business_plan_docs) {
          const actionCreator = adminBuss?.business_plan_doc_id
            ? putEnterpriseBusinessPlanAdminAction
            : postEnterpriseBusinessPlanAdminAction;

          dispatch(
            actionCreator(adminBuss?.business_plan_docs, enterpriseKycId)
          );
        }

        if (adminReg?.registration_of_company_docs) {
          const actionCreator = adminReg?.registration_of_company_doc_id
            ? putEnterpriseRegistrationCompAdminAction
            : postEnterpriseRegistrationCompAdminAction;

          dispatch(
            actionCreator(
              adminReg.registration_of_company_docs,
              enterpriseKycId
            )
          );
        }

        if (res?.status === 200) {
          toast.success("KYC Form Updated Successfully");

          setTimeout(() => {
            setOpenEnterpriseKycPopup(false);
            navigate("/admin/members-list/profile");
          }, 1500);
        } else {
          // Handle error case where enterpriseKycId is null or undefined
          toast.error("Error: enterpriseKycId is null or undefined");
        }
      });
    }
  };

  return (
    <>
      <ArrowButton />
      <div className="page-content-review">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="invest-form">
                <form onSubmit={handleSubmit} enctype="multipart/form-data">
                  <h2>General Information</h2>
                  Full Name
                  <input
                    type="text"
                    name="full_name"
                    value={formData?.full_name || ""}
                    onChange={handleInputChange}
                  />
                  Company Name
                  <input
                    type="text"
                    name="company_name"
                    value={formData?.company_name || ""}
                    onChange={handleInputChange}
                    required
                  />
                  Are you founder/co-founder of the company?
                  <select
                    name="is_founder_or_cofounder"
                    id="is_founder_or_cofounder"
                    onChange={handleInputChange}
                    inputprops={{ "aria-label": "Without label" }}
                  >
                    <option
                      value="1"
                      selected={formData?.is_founder_or_cofounder === "1"}
                    >
                      Yes
                    </option>
                    <option
                      value="0"
                      selected={formData?.is_founder_or_cofounder === "0"}
                    >
                      No
                    </option>
                  </select>
                  <br />
                  <br />
                  {formData?.is_founder_or_cofounder === "0" && (
                    <>
                      <h2>Company's Information</h2>
                      Founder's Name
                      <input
                        type="text"
                        name="founder_or_cofounder_name"
                        value={formData?.founder_or_cofounder_name || ""}
                        onChange={handleInputChange}
                        required
                      />
                      What is your designation in the company?
                      <input
                        type="text"
                        name="designation"
                        value={formData?.designation || ""}
                        onChange={handleInputChange}
                        required
                      />
                      Email Address
                      <input
                        type="email"
                        name="company_email"
                        value={formData?.company_email || ""}
                        onChange={handleInputChange}
                        required
                      />
                      Contact Number
                      <input
                        type="number"
                        name="contact_number"
                        value={formData?.contact_number || ""}
                        onChange={handleInputChange}
                        required
                      />
                      Link to company's website, Linkedin page, or other social
                      media page
                      <input
                        type="text"
                        name="company_website_or_social"
                        value={formData?.company_website_or_social || ""}
                        onChange={handleInputChange}
                        required
                      />
                      No. of Founders
                      <select
                        value={formData?.no_of_founders || ""}
                        name="no_of_founders"
                        id="no_of_founders"
                        onChange={handleInputChange}
                        displayempty="true"
                        inputprops={{ "aria-label": "Without label" }}
                      >
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="More Than 5">More Than 5</option>
                      </select>
                    </>
                  )}{" "}
                  {formData?.is_founder_or_cofounder === "1" && (
                    <>
                      <h2>Company's Information</h2>
                      Email Address
                      <input
                        type="email"
                        name="company_email"
                        value={formData?.company_email || ""}
                        onChange={handleInputChange}
                        required
                      />
                      Contact Number
                      <input
                        type="number"
                        name="contact_number"
                        value={formData?.contact_number || ""}
                        onChange={handleInputChange}
                        required
                      />
                      Link to company's website, Linkedin page, or other social
                      media page
                      <input
                        type="text"
                        name="company_website_or_social"
                        value={formData?.company_website_or_social || ""}
                        onChange={handleInputChange}
                        required
                      />
                      No. of Founders
                      <select
                        value={formData?.no_of_founders || ""}
                        name="no_of_founders"
                        id="no_of_founders"
                        onChange={handleInputChange}
                        displayempty="true"
                        inputprops={{ "aria-label": "Without label" }}
                      >
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="More Than 5">More Than 5</option>
                      </select>
                    </>
                  )}
                  {formData?.is_founder_or_cofounder !== "" &&
                    formData?.no_of_founders !== "" && (
                      <>
                        Structure of the Enterprise
                        <select
                          value={formData?.structure_of_enterprise || ""}
                          id="structure_of_enterprise"
                          name="structure_of_enterprise"
                          onChange={handleInputChange}
                          displayempty="true"
                          inputprops={{ "aria-label": "Without label" }}
                        >
                          <option value="Sole Proprietorship">
                            Sole Proprietorship
                          </option>
                          <option value="Private Limited">
                            Private Limited
                          </option>
                          <option value="Partnership Firm">
                            Partnership Firm
                          </option>
                          <option value="Not registered/Idea Stage">
                            Not registered/Idea Stage
                          </option>
                          <option value="Other">Other</option>
                        </select>
                      </>
                    )}
                  {formData.structure_of_enterprise === "Other" && (
                    <>
                      Please describe the other Structure of the Enterprise
                      (Required*)
                      <input
                        type="text"
                        name="structure_of_enterprise_other"
                        value={formData.structure_of_enterprise_other || ""}
                        onChange={handleInputChange}
                        required
                      />
                    </>
                  )}
                  Date of Incorporation of the company
                  <input
                    type="date"
                    name="established_date"
                    value={formData?.established_date?.slice(0, 10) || ""}
                    onChange={handleInputChange}
                  />
                  Registered Address
                  <input
                    type="text"
                    name="registered_address"
                    value={formData?.registered_address || ""}
                    onChange={handleInputChange}
                    required
                  />
                  {formData.structure_of_enterprise !==
                    "Not registered/Idea Stage" &&
                    formData.structure_of_enterprise !== "Other" &&
                    formData.structure_of_enterprise?.length > 0 && (
                      <>
                        Registration details of the company - Company
                        registration number and PAN/ VAT number
                        <br />
                        <textarea
                          type="number"
                          name="pan_or_vat"
                          value={formData.pan_or_vat || ""}
                          onChange={handleInputChange}
                          required
                        />
                      </>
                    )}
                  Please upload your company's charter documents - Company
                  registration certificate and PAN/VAT certificate
                  <input
                    name="charter_docs"
                    className="form-control"
                    type="file"
                    multiple
                    onChange={handlePanVatFileEvent}
                    disabled={fileLimit}
                  />
                  {Object.keys(adminPan).length > 0 && (
                    <span style={{ color: "#b1af66" }}>Previous Files:</span>
                  )}
                  <div className="row">
                    {adminPan &&
                      Object.keys(adminPan).length > 0 &&
                      Object.values(adminPan)
                        ?.slice(0, 5)
                        ?.map((data, index) => (
                          <div className="col-2">
                            <input
                              type="hidden"
                              name={`charter_docs_${data.charter_doc_id}`}
                              id={`charter_docs_${data.charter_doc_id}`}
                              value={data.charter_doc_file}
                            />
                            <input
                              type="file"
                              name={`charter_docs_${data.charter_doc_id}`}
                              id={`charter_docs_${data.charter_doc_id}`}
                              className="form-control"
                              ref={filePanInputRefs[index]}
                              style={{ display: "none" }}
                              onChange={(e) =>
                                handleUpdatePanFile(e, data.charter_doc_id)
                              }
                            />
                            {data.charter_doc_file ? (
                              <div>
                                {data.charter_doc_file
                                  .toLowerCase()
                                  .endsWith(".pdf") ? (
                                  <>
                                    <div
                                      onClick={() =>
                                        filePanInputRefs[index]?.current.click()
                                      }
                                    >
                                      <EditIcon
                                        name={`charter_docs_${data.charter_doc_id}`}
                                        id={`charter_docs_${data.charter_doc_id}`}
                                        style={{
                                          position: "absolute",
                                          color: "#0b6885",
                                          backgroundColor: "azure",
                                          marginLeft: "42%",
                                          fontSize: "25px",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </div>
                                    <a
                                      href={`${API_URL}/uploads/kyc/enterprise/charter_docs/${data.charter_doc_file}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      View PDF
                                    </a>
                                  </>
                                ) : data.charter_doc_file
                                    .toLowerCase()
                                    .endsWith(".xlsx") ? (
                                  <>
                                    <div
                                      onClick={() =>
                                        filePanInputRefs[index]?.current.click()
                                      }
                                    >
                                      <EditIcon
                                        name={`charter_docs_${data.charter_doc_id}`}
                                        id={`charter_docs_${data.charter_doc_id}`}
                                        style={{
                                          position: "absolute",
                                          color: "#0b6885",
                                          backgroundColor: "azure",
                                          marginLeft: "45%",
                                          fontSize: "25px",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </div>
                                    <a
                                      href={`${API_URL}/uploads/kyc/enterprise/charter_docs/${data.charter_doc_file}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      View Excel
                                    </a>
                                  </>
                                ) : data.charter_doc_file
                                    .toLowerCase()
                                    .endsWith(".docx") ? (
                                  <>
                                    <div
                                      onClick={() =>
                                        filePanInputRefs[index]?.current.click()
                                      }
                                    >
                                      <EditIcon
                                        name={`charter_docs_${data.charter_doc_id}`}
                                        id={`charter_docs_${data.charter_doc_id}`}
                                        style={{
                                          position: "absolute",
                                          color: "#0b6885",
                                          backgroundColor: "azure",
                                          marginLeft: "45%",
                                          fontSize: "25px",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </div>
                                    <a
                                      href={`${API_URL}/uploads/kyc/enterprise/charter_docs/${data.charter_doc_file}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      View Docs
                                    </a>
                                  </>
                                ) : (
                                  <>
                                    <div
                                      onClick={() =>
                                        filePanInputRefs[index]?.current.click()
                                      }
                                    >
                                      <FilterIcon
                                        name={`charter_docs_${data.charter_doc_id}`}
                                        id={`charter_docs_${data.charter_doc_id}`}
                                        style={{
                                          position: "absolute",
                                          color: "#0b6885",
                                          backgroundColor: "azure",
                                          marginLeft: "72%",
                                          fontSize: "25px",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </div>
                                    <img
                                      src={`${API_URL}/uploads/kyc/enterprise/charter_docs/${data.charter_doc_file}`}
                                      alt=""
                                      height="150px"
                                      width="150px"
                                      style={{
                                        cursor: "pointer",
                                        objectFit: "cover",
                                      }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        const url = e.target.src;
                                        window.open(url, "_blank");
                                      }}
                                    />
                                  </>
                                )}
                              </div>
                            ) : (
                              <span style={{ color: "red" }}>
                                No document available
                              </span>
                            )}
                          </div>
                        ))}
                  </div>
                  <br />
                  Profile of a founder/ co-founder (either type in the profile
                  or provide a link to online profile)
                  <textarea
                    type="text"
                    name="profile_of_founder_or_cofounder"
                    value={formData?.profile_of_founder_or_cofounder || ""}
                    onChange={handleInputChange}
                    required
                  />
                  No. of Employees
                  <select
                    value={formData?.no_of_employees}
                    id="no_of_employees"
                    name="no_of_employees"
                    onChange={handleInputChange}
                    displayempty="true"
                    inputprops={{ "aria-label": "Without label" }}
                  >
                    <option value="0-5">0-5</option>
                    <option value="5-10">5-10</option>
                    <option value="10-20">10-20</option>
                    <option value="20-50">20-50</option>
                    <option value="50-100">50-100</option>
                    <option value="101 or more">101 or more</option>
                  </select>
                  Title for your Deal
                  <input
                    type="text"
                    name="deal_title"
                    value={formData?.deal_title || ""}
                    onChange={handleInputChange}
                    required
                  />
                  Deal Overview (optional)
                  <input
                    type="text"
                    name="deal_overview"
                    value={formData.deal_overview || ""}
                    onChange={handleInputChange}
                  />
                  Which primary sector is your company in?
                  <br />
                  <select
                    id="sector"
                    name="sector"
                    value={formData?.sector || ""}
                    onChange={handleInputChange}
                    displayempty="true"
                    inputprops={{ "aria-label": "Without label" }}
                  >
                    {categoryList?.length > 0 &&
                      categoryList?.map((option) => (
                        <option
                          key={option.deal_category_id}
                          value={option.deal_category_id}
                        >
                          {option.deal_cat_name}
                        </option>
                      ))}
                  </select>
                  Tell us more about your company/ idea (Include information on
                  your products, services, business model and value proposition)
                  <textarea
                    type="text"
                    name="about_company"
                    value={formData?.about_company || ""}
                    onChange={handleInputChange}
                    required
                  />
                  Tell us more about your product/ service's target market
                  (Include information about your market research and what you
                  have found about the size of the market and competition)
                  <textarea
                    type="text"
                    name="target_product_description"
                    value={formData?.target_product_description || ""}
                    onChange={handleInputChange}
                    required
                  />
                  Where is your company headquarters located (if different from
                  the registered address)?
                  <input
                    type="text"
                    value={formData?.headquarters || ""}
                    name="headquarters"
                    required
                    onChange={handleInputChange}
                  />
                  Where does your company operate?
                  <select
                    name="company_operate"
                    id="company_operate"
                    value={formData?.company_operate}
                    onChange={handleInputChange}
                    displayempty="true"
                    inputprops={{ "aria-label": "Without label" }}
                  >
                    {provinceList?.length > 0 &&
                      provinceList?.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.label}
                        </option>
                      ))}
                  </select>
                  <br />
                  <br />
                  <h2>Information on sustainability and Impact</h2>
                  Does your company contribute to any sustainability agenda
                  and/or create positive impact?
                  <select
                    value={formData?.create_positive_impact}
                    name="create_positive_impact"
                    id="create_positive_impact"
                    onChange={handleInputChange}
                    displayempty="true"
                    inputprops={{ "aria-label": "Without label" }}
                  >
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                    <option value="3">Maybe</option>
                  </select>
                  {formData?.create_positive_impact !== "" &&
                    formData?.create_positive_impact !== "0" && (
                      <>
                        If your company contributes to sustainability agenda,
                        which of the following SDGs does your company contribute
                        to?{" "}
                        <div className="form-check">
                          <FormGroup
                            sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }}
                          >
                            {sdgList?.map((option) => (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={formData.sdg.includes(
                                      option.sdg_id
                                    )}
                                    onChange={handleSdgChange}
                                    name={option?.sdg_id}
                                    value={option?.sdg_id}
                                    inputprops={{ "aria-label": "controlled" }}
                                  />
                                }
                                label={option?.sdg_name}
                              />
                            ))}
                          </FormGroup>
                        </div>
                      </>
                    )}
                  <br />
                  Please describe how your company mitigates risks of any
                  negative impact and if your company is creating any positive
                  impact pleas also provide a brief overview of the positive
                  impact.
                  <textarea
                    type="text"
                    name="mitigates_risks"
                    value={formData?.mitigates_risks || ""}
                    onChange={handleInputChange}
                    required
                  />
                  Is there anything further you can tell us about your
                  stakeholders, including but not limited to the gender and
                  socio-economic composition of your board, owners, employees,
                  suppliers, and consumers?
                  <textarea
                    type="text"
                    name="about_stakeholders"
                    value={formData?.about_stakeholders}
                    onChange={handleInputChange}
                    required
                  />
                  <br />
                  <br />
                  <h2>Financial Information</h2>
                  Total Investment made till date - in NPR (Paid up Capital) *
                  <input
                    type="number"
                    name="amount_of_investment"
                    value={formData.amount_of_investment || ""}
                    onChange={handleInputChange}
                    required
                  />
                  Company's current financial performance in terms of revenue
                  (please provide revenues of current fiscal year and past two
                  fiscal years OR since inception whichever is shorter)
                  <input
                    type="number"
                    name="current_financial"
                    value={formData.current_financial || ""}
                    onChange={handleInputChange}
                    required
                  />
                  Company's current financial performance in terms of EBITDA
                  (please provide EBITDA of current fiscal year and past two
                  fiscal years OR since inception whichever is shorter)
                  <input
                    type="number"
                    name="terms_of_ebitda"
                    value={formData.terms_of_ebitda || ""}
                    onChange={handleInputChange}
                    required
                  />
                  Amount of investment that you are trying to raise? (should be
                  a numerical value) *
                  <input
                    type="number"
                    name="raise_investment"
                    value={formData.raise_investment || ""}
                    onChange={handleInputChange}
                    required
                  />
                  What will that capital be used for? Please provide detailed
                  information and amount or percentage breakdown as far as
                  possible (can this field be a spreadsheet where the total is
                  populated from the previous answer - the investment amount
                  they are trying to raise?) *
                  <textarea
                    type="text"
                    name="amount_or_percentage_breakdown"
                    value={formData.amount_or_percentage_breakdown || ""}
                    onChange={handleInputChange}
                    required
                  />
                  Please upload a capital mobilization plan (an excel file/
                  spreadsheet showcasing the breakdown of how the investment
                  capital you seek will be used).
                  <input
                    type="file"
                    multiple
                    name="mobilization_plan_docs"
                    className="form-control"
                    onChange={handleMobFileEvent}
                    disabled={fileLimit}
                  />
                  {Object.keys(adminMob).length > 0 && (
                    <span style={{ color: "#b1af66" }}>Previous Files:</span>
                  )}
                  <div className="row">
                    {adminMob &&
                      Object.keys(adminMob).length > 0 &&
                      Object.values(adminMob)
                        ?.slice(0, 5)
                        ?.map((data, index) => (
                          <div className="col-2">
                            <input
                              type="hidden"
                              name={`mobilization_plan_docs_${data.mobilization_plan_doc_id}`}
                              id={`mobilization_plan_docs_${data.mobilization_plan_doc_id}`}
                              value={data.mobilization_plan_doc_file}
                            />

                            <input
                              type="file"
                              name={`mobilization_plan_docs_${data.mobilization_plan_doc_id}`}
                              id={`mobilization_plan_docs_${data.mobilization_plan_doc_id}`}
                              className="form-control"
                              ref={fileInputRefs[index]}
                              style={{ display: "none" }}
                              onChange={(e) =>
                                handleUpdateMobFile(
                                  e,
                                  data.mobilization_plan_doc_id
                                )
                              }
                            />

                            {data.mobilization_plan_doc_file ? (
                              <div>
                                {data.mobilization_plan_doc_file
                                  .toLowerCase()
                                  .endsWith(".pdf") ? (
                                  <>
                                    <div
                                      onClick={() =>
                                        fileInputRefs[index]?.current.click()
                                      }
                                    >
                                      <EditIcon
                                        name={`mobilization_plan_docs_${data.mobilization_plan_doc_id}`}
                                        id={`mobilization_plan_docs_${data.mobilization_plan_doc_id}`}
                                        style={{
                                          position: "absolute",
                                          color: "#0b6885",
                                          backgroundColor: "azure",
                                          marginLeft: "42%",
                                          fontSize: "25px",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </div>
                                    <a
                                      href={`${API_URL}/uploads/kyc/enterprise/mobilization_plan_docs/${data.mobilization_plan_doc_file}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      View PDF
                                    </a>
                                  </>
                                ) : data.mobilization_plan_doc_file
                                    .toLowerCase()
                                    .endsWith(".xlsx") ? (
                                  <>
                                    <div
                                      onClick={() =>
                                        fileInputRefs[index]?.current.click()
                                      }
                                    >
                                      <EditIcon
                                        name={`mobilization_plan_docs_${data.mobilization_plan_doc_id}`}
                                        id={`mobilization_plan_docs_${data.mobilization_plan_doc_id}`}
                                        style={{
                                          position: "absolute",
                                          color: "#0b6885",
                                          backgroundColor: "azure",
                                          marginLeft: "45%",
                                          fontSize: "25px",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </div>
                                    <a
                                      href={`${API_URL}/uploads/kyc/enterprise/mobilization_plan_docs/${data.mobilization_plan_doc_file}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      View Excel
                                    </a>
                                  </>
                                ) : data.mobilization_plan_doc_file
                                    .toLowerCase()
                                    .endsWith(".docx") ? (
                                  <>
                                    <div
                                      onClick={() =>
                                        fileInputRefs[index]?.current.click()
                                      }
                                    >
                                      <EditIcon
                                        name={`mobilization_plan_docs_${data.mobilization_plan_doc_id}`}
                                        id={`mobilization_plan_docs_${data.mobilization_plan_doc_id}`}
                                        style={{
                                          position: "absolute",
                                          color: "#0b6885",
                                          backgroundColor: "azure",
                                          marginLeft: "42%",
                                          fontSize: "25px",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </div>
                                    <a
                                      href={`${API_URL}/uploads/kyc/enterprise/mobilization_plan_docs/${data.mobilization_plan_doc_file}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      View Docs
                                    </a>
                                  </>
                                ) : (
                                  <>
                                    <div
                                      onClick={() =>
                                        fileInputRefs[index]?.current.click()
                                      }
                                    >
                                      <FilterIcon
                                        name={`mobilization_plan_docs_${data.mobilization_plan_doc_id}`}
                                        id={`mobilization_plan_docs_${data.mobilization_plan_doc_id}`}
                                        style={{
                                          position: "absolute",
                                          color: "#0b6885",
                                          backgroundColor: "azure",
                                          marginLeft: "72%",
                                          fontSize: "25px",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </div>
                                    <img
                                      src={`${API_URL}/uploads/kyc/enterprise/mobilization_plan_docs/${data.mobilization_plan_doc_file}`}
                                      alt=""
                                      height="150px"
                                      width="150px"
                                      style={{
                                        cursor: "pointer",
                                        objectFit: "cover",
                                      }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        const url = e.target.src;
                                        window.open(url, "_blank");
                                      }}
                                    />
                                  </>
                                )}
                              </div>
                            ) : (
                              <span style={{ color: "red" }}>
                                No document available
                              </span>
                            )}
                          </div>
                        ))}
                  </div>
                  <br />
                  Please upload a revenue/ profitability projection for the next
                  five years if available.
                  <input
                    type="file"
                    name="revenue_profitability_docs"
                    multiple
                    className="form-control"
                    onChange={handleRevenueFileEvent}
                    disabled={fileLimit}
                  />
                  {Object.keys(adminRev).length > 0 && (
                    <span style={{ color: "#b1af66" }}>Previous Files:</span>
                  )}
                  <div className="row">
                    {adminRev &&
                      Object.keys(adminRev).length > 0 &&
                      Object.values(adminRev)
                        ?.slice(0, 5)
                        ?.map((doc, index) => (
                          <div className="col-2">
                            <input
                              type="hidden"
                              name={`revenue_profitability_docs_${doc.revenue_profitability_doc_id}`}
                              id={`revenue_profitability_docs_${doc.revenue_profitability_doc_id}`}
                              value={doc.revenue_profitability_doc_file}
                            />
                            <input
                              type="file"
                              name={`revenue_profitability_docs_${doc.revenue_profitability_doc_id}`}
                              id={`revenue_profitability_docs_${doc.revenue_profitability_doc_id}`}
                              className="form-control"
                              ref={fileRevInputRefs[index]}
                              style={{ display: "none" }}
                              onChange={(e) =>
                                handleUpdateRevFile(
                                  e,
                                  doc.revenue_profitability_doc_id
                                )
                              }
                            />
                            {doc.revenue_profitability_doc_file ? (
                              <div>
                                {doc.revenue_profitability_doc_file
                                  .toLowerCase()
                                  .endsWith(".pdf") ? (
                                  <>
                                    <div
                                      onClick={() =>
                                        fileRevInputRefs[index]?.current.click()
                                      }
                                    >
                                      <EditIcon
                                        name={`revenue_profitability_docs_${doc.revenue_profitability_doc_id}`}
                                        id={`revenue_profitability_docs_${doc.revenue_profitability_doc_id}`}
                                        style={{
                                          position: "absolute",
                                          color: "#0b6885",
                                          backgroundColor: "azure",
                                          marginLeft: "42%",
                                          fontSize: "25px",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </div>
                                    <a
                                      href={`${API_URL}/uploads/kyc/enterprise/revenue_profitability_docs/${doc.revenue_profitability_doc_file}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      View PDF
                                    </a>
                                  </>
                                ) : doc.revenue_profitability_doc_file
                                    .toLowerCase()
                                    .endsWith(".xlsx") ? (
                                  <>
                                    <div
                                      onClick={() =>
                                        fileRevInputRefs[index]?.current.click()
                                      }
                                    >
                                      <EditIcon
                                        name={`revenue_profitability_docs_${doc.revenue_profitability_doc_id}`}
                                        id={`revenue_profitability_docs_${doc.revenue_profitability_doc_id}`}
                                        style={{
                                          position: "absolute",
                                          color: "#0b6885",
                                          backgroundColor: "azure",
                                          marginLeft: "45%",
                                          fontSize: "25px",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </div>
                                    <a
                                      href={`${API_URL}/uploads/kyc/enterprise/revenue_profitability_docs/${doc.revenue_profitability_doc_file}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      View Excel
                                    </a>
                                  </>
                                ) : doc.revenue_profitability_doc_file
                                    .toLowerCase()
                                    .endsWith(".docx") ? (
                                  <>
                                    <div
                                      onClick={() =>
                                        fileRevInputRefs[index]?.current.click()
                                      }
                                    >
                                      <EditIcon
                                        name={`revenue_profitability_docs_${doc.revenue_profitability_doc_id}`}
                                        id={`revenue_profitability_docs_${doc.revenue_profitability_doc_id}`}
                                        style={{
                                          position: "absolute",
                                          color: "#0b6885",
                                          backgroundColor: "azure",
                                          marginLeft: "42%",
                                          fontSize: "25px",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </div>
                                    <a
                                      href={`${API_URL}/uploads/kyc/enterprise/revenue_profitability_docs/${doc.revenue_profitability_doc_file}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      View Docs
                                    </a>
                                  </>
                                ) : (
                                  <>
                                    <div
                                      onClick={() =>
                                        fileRevInputRefs[index]?.current.click()
                                      }
                                    >
                                      <FilterIcon
                                        name={`revenue_profitability_docs_${doc.revenue_profitability_doc_id}`}
                                        id={`revenue_profitability_docs_${doc.revenue_profitability_doc_id}`}
                                        style={{
                                          position: "absolute",
                                          color: "#0b6885",
                                          backgroundColor: "azure",
                                          marginLeft: "72%",
                                          fontSize: "25px",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </div>
                                    <img
                                      src={`${API_URL}/uploads/kyc/enterprise/revenue_profitability_docs/${doc.revenue_profitability_doc_file}`}
                                      alt=""
                                      height="150px"
                                      width="150px"
                                      style={{
                                        cursor: "pointer",
                                        objectFit: "cover",
                                      }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        const url = e.target.src;
                                        window.open(url, "_blank");
                                      }}
                                    />
                                  </>
                                )}
                              </div>
                            ) : (
                              <span style={{ color: "red" }}>
                                No document available
                              </span>
                            )}
                          </div>
                        ))}
                  </div>
                  <br />
                  Preferred mode of funding
                  <select
                    name="mode_of_funding"
                    id="mode_of_funding"
                    value={formData.mode_of_funding || ""}
                    onChange={handleInputChange}
                    displayempty="true"
                    inputprops={{ "aria-label": "Without label" }}
                  >
                    <option value="Equity">Equity</option>
                    <option value="Dept">Dept</option>
                    <option value="Others">
                      I am open to other innovating financial structuring
                    </option>
                  </select>
                  {formData?.mode_of_funding === "Others" && (
                    <>
                      Other Mode of Fundings
                      <input
                        type="text"
                        name="mode_of_funding_others"
                        value={formData?.mode_of_funding_others}
                        onChange={handleInputChange}
                        required
                      />
                    </>
                  )}
                  Non-financial support required?
                  <div className="form-check">
                    <FormGroup sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}>
                      {nonFinancialList?.map((option) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={formData.non_financial_support.includes(
                                option.id
                              )}
                              onChange={handleNonFinancialChange}
                              name={option.id}
                              value={option.id}
                              inputprops={{ "aria-label": "controlled" }}
                            />
                          }
                          label={option.label}
                        />
                      ))}
                    </FormGroup>
                  </div>
                  {formData?.non_financial_support.includes(7) && (
                    <>
                      Please describe non-financial support required
                      <textarea
                        type="text"
                        name="non_financial_support_other"
                        id="non_financial_support_other"
                        value={formData?.non_financial_support_other}
                        onChange={handleInputChange}
                        required
                      />
                    </>
                  )}
                  <br />
                  <h2>Documents & Uploads</h2>
                  Please upload your company's charter documents ( MOA, AOA),
                  audit report for at least 3 years, tax clearance and
                  shareholder register (Maximum 5 Files)
                  <input
                    name="registration_of_company_docs"
                    className="form-control"
                    type="file"
                    multiple
                    onChange={handleFileEvent}
                    disabled={fileLimit}
                  />
                  {Object.keys(adminReg).length > 0 && (
                    <span style={{ color: "#b1af66" }}>Previous Files:</span>
                  )}
                  <div className="row">
                    {adminReg &&
                      Object.keys(adminReg).length > 0 &&
                      Object.values(adminReg)
                        ?.slice(0, 5)
                        ?.map((doc, index) => (
                          <div className="col-2">
                            <input
                              type="hidden"
                              name={`registration_of_company_docs_${doc.registration_of_company_doc_id}`}
                              id={`registration_of_company_docs_${doc.registration_of_company_doc_id}`}
                              value={doc.registration_of_company_doc_file}
                            />
                            <input
                              type="file"
                              name={`registration_of_company_docs_${doc.registration_of_company_doc_id}`}
                              id={`registration_of_company_docs_${doc.registration_of_company_doc_id}`}
                              className="form-control"
                              ref={fileRegInputRefs[index]}
                              style={{ display: "none" }}
                              onChange={(e) =>
                                handleUpdateRegFile(
                                  e,
                                  doc.registration_of_company_doc_id
                                )
                              }
                            />
                            {doc.registration_of_company_doc_file ? (
                              <div>
                                {doc.registration_of_company_doc_file
                                  .toLowerCase()
                                  .endsWith(".pdf") ? (
                                  <>
                                    <div
                                      onClick={() =>
                                        fileRegInputRefs[index]?.current.click()
                                      }
                                    >
                                      <EditIcon
                                        name={`registration_of_company_docs_${doc.registration_of_company_doc_id}`}
                                        id={`registration_of_company_docs_${doc.registration_of_company_doc_id}`}
                                        style={{
                                          position: "absolute",
                                          color: "#0b6885",
                                          backgroundColor: "azure",
                                          marginLeft: "42%",
                                          fontSize: "25px",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </div>
                                    <a
                                      href={`${API_URL}/uploads/kyc/enterprise/registration_of_company_docs/${doc.registration_of_company_doc_file}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      View PDF
                                    </a>
                                  </>
                                ) : doc.registration_of_company_doc_file
                                    .toLowerCase()
                                    .endsWith(".xlsx") ? (
                                  <>
                                    <div
                                      onClick={() =>
                                        fileRegInputRefs[index]?.current.click()
                                      }
                                    >
                                      <EditIcon
                                        name={`registration_of_company_docs_${doc.registration_of_company_doc_id}`}
                                        id={`registration_of_company_docs_${doc.registration_of_company_doc_id}`}
                                        style={{
                                          position: "absolute",
                                          color: "#0b6885",
                                          backgroundColor: "azure",
                                          marginLeft: "45%",
                                          fontSize: "25px",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </div>
                                    <a
                                      href={`${API_URL}/uploads/kyc/enterprise/registration_of_company_docs/${doc.registration_of_company_doc_file}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      View Excel
                                    </a>
                                  </>
                                ) : doc.registration_of_company_doc_file
                                    .toLowerCase()
                                    .endsWith(".docx") ? (
                                  <>
                                    <div
                                      onClick={() =>
                                        fileRegInputRefs[index]?.current.click()
                                      }
                                    >
                                      <EditIcon
                                        name={`registration_of_company_docs_${doc.registration_of_company_doc_id}`}
                                        id={`registration_of_company_docs_${doc.registration_of_company_doc_id}`}
                                        style={{
                                          position: "absolute",
                                          color: "#0b6885",
                                          backgroundColor: "azure",
                                          marginLeft: "42%",
                                          fontSize: "25px",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </div>
                                    <a
                                      href={`${API_URL}/uploads/kyc/enterprise/registration_of_company_docs/${doc.registration_of_company_doc_file}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      View Docs
                                    </a>
                                  </>
                                ) : (
                                  <>
                                    <div
                                      onClick={() =>
                                        fileRegInputRefs[index]?.current.click()
                                      }
                                    >
                                      <FilterIcon
                                        name={`registration_of_company_docs_${doc.registration_of_company_doc_id}`}
                                        id={`registration_of_company_docs_${doc.registration_of_company_doc_id}`}
                                        style={{
                                          position: "absolute",
                                          color: "#0b6885",
                                          backgroundColor: "azure",
                                          marginLeft: "72%",
                                          fontSize: "25px",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </div>
                                    <img
                                      src={`${API_URL}/uploads/kyc/enterprise/registration_of_company_docs/${doc.registration_of_company_doc_file}`}
                                      alt=""
                                      height="150px"
                                      width="150px"
                                      style={{
                                        cursor: "pointer",
                                        objectFit: "cover",
                                      }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        const url = e.target.src;
                                        window.open(url, "_blank");
                                      }}
                                    />
                                  </>
                                )}
                              </div>
                            ) : (
                              <span style={{ color: "red" }}>
                                No document available
                              </span>
                            )}
                          </div>
                        ))}
                  </div>
                  <br />
                  Please upload business plan, pitch deck including financial
                  projections, or any other material you have developed for
                  pitching your investment (Maximum 5 Files)
                  <input
                    name="business_plan_docs"
                    className="form-control"
                    type="file"
                    multiple
                    onChange={handleBusinessFileEvent}
                    disabled={fileLimit}
                  />
                  {Object.keys(adminBuss).length > 0 && (
                    <span style={{ color: "#b1af66" }}>Previous Files:</span>
                  )}
                  <div className="row">
                    {adminBuss &&
                      Object.keys(adminBuss).length > 0 &&
                      Object.values(adminBuss)
                        ?.slice(0, 5)
                        ?.map((doc, index) => (
                          <div className="col-2">
                            <input
                              type="hidden"
                              name={`business_plan_docs_${doc.business_plan_doc_id}`}
                              id={`business_plan_docs_${doc.business_plan_doc_id}`}
                              value={doc.business_plan_doc_file}
                            />

                            <input
                              type="file"
                              name={`business_plan_docs_${doc.business_plan_doc_id}`}
                              id={`business_plan_docs_${doc.business_plan_doc_id}`}
                              className="form-control"
                              ref={fileBussInputRefs[index]}
                              style={{ display: "none" }}
                              onChange={(e) =>
                                handleUpdateBussFile(
                                  e,
                                  doc.business_plan_doc_id
                                )
                              }
                            />
                            {doc.business_plan_doc_file ? (
                              <div>
                                {doc.business_plan_doc_file
                                  .toLowerCase()
                                  .endsWith(".pdf") ? (
                                  <>
                                    <div
                                      onClick={() =>
                                        fileBussInputRefs[
                                          index
                                        ]?.current.click()
                                      }
                                    >
                                      <EditIcon
                                        name={`business_plan_docs_${doc.business_plan_doc_id}`}
                                        id={`business_plan_docs_${doc.business_plan_doc_id}`}
                                        style={{
                                          position: "absolute",
                                          color: "#0b6885",
                                          backgroundColor: "azure",
                                          marginLeft: "42%",
                                          fontSize: "25px",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </div>
                                    <a
                                      href={`${API_URL}/uploads/kyc/enterprise/business_plan_docs/${doc.business_plan_doc_file}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      View PDF
                                    </a>
                                  </>
                                ) : doc.business_plan_doc_file
                                    .toLowerCase()
                                    .endsWith(".xlsx") ||
                                  doc.business_plan_doc_file
                                    .toLowerCase()
                                    .endsWith(".xls") ? (
                                  <>
                                    <div
                                      onClick={() =>
                                        fileBussInputRefs[
                                          index
                                        ]?.current.click()
                                      }
                                    >
                                      <EditIcon
                                        name={`business_plan_docs_${doc.business_plan_doc_id}`}
                                        id={`business_plan_docs_${doc.business_plan_doc_id}`}
                                        style={{
                                          position: "absolute",
                                          color: "#0b6885",
                                          backgroundColor: "azure",
                                          marginLeft: "45%",
                                          fontSize: "25px",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </div>
                                    <a
                                      href={`${API_URL}/uploads/kyc/enterprise/business_plan_docs/${doc.business_plan_doc_file}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      View Excel
                                    </a>
                                  </>
                                ) : doc.business_plan_doc_file
                                    .toLowerCase()
                                    .endsWith(".docx") ||
                                  doc.business_plan_doc_file
                                    .toLowerCase()
                                    .endsWith(".doc") ? (
                                  <>
                                    <div
                                      onClick={() =>
                                        fileBussInputRefs[
                                          index
                                        ]?.current.click()
                                      }
                                    >
                                      <EditIcon
                                        name={`business_plan_docs_${doc.business_plan_doc_id}`}
                                        id={`business_plan_docs_${doc.business_plan_doc_id}`}
                                        style={{
                                          position: "absolute",
                                          color: "#0b6885",
                                          backgroundColor: "azure",
                                          marginLeft: "42%",
                                          fontSize: "25px",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </div>
                                    <a
                                      href={`${API_URL}/uploads/kyc/enterprise/business_plan_docs/${doc.business_plan_doc_file}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      View Doc
                                    </a>
                                  </>
                                ) : (
                                  <>
                                    <div
                                      onClick={() =>
                                        fileBussInputRefs[
                                          index
                                        ]?.current.click()
                                      }
                                    >
                                      <FilterIcon
                                        name={`business_plan_docs_${doc.business_plan_doc_id}`}
                                        id={`business_plan_docs_${doc.business_plan_doc_id}`}
                                        style={{
                                          position: "absolute",
                                          color: "#0b6885",
                                          backgroundColor: "azure",
                                          marginLeft: "72%",
                                          fontSize: "25px",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </div>
                                    <img
                                      src={`${API_URL}/uploads/kyc/enterprise/business_plan_docs/${doc.business_plan_doc_file}`}
                                      alt=""
                                      height="150px"
                                      width="150px"
                                      style={{
                                        cursor: "pointer",
                                        objectFit: "cover",
                                      }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        const url = e.target.src;
                                        window.open(url, "_blank");
                                      }}
                                    />
                                  </>
                                )}
                              </div>
                            ) : (
                              <span style={{ color: "red" }}>
                                No document available
                              </span>
                            )}
                          </div>
                        ))}
                  </div>
                  <br />
                  <button className="blue-btn" type="submit">
                    Update KYC Form
                  </button>
                  <ToastContainer
                    position="top-right"
                    autoClose={2000}
                    theme="colored"
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateEnterpriseKYCFormAdmin;

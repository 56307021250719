import React, { useEffect, useState } from "react";
import { Button, Grid, MenuItem, Select, TextField } from "@mui/material";
import { Form } from "../../components/hooks/useForm";
import { useDispatch, useSelector } from "react-redux";
import CheckBoxControl from "../../components/controls/CheckBoxControl";
import LoadingComp from "../../components/LoadingComp";
import { getAllDealCategoryAction } from "./manage-deals-category/ManageDealsCategoryActions";

const ManageDealsDetailView = ({
  detailDeal,
  loadingUpdate,
  setOpenDetailPopup,
}) => {
  const [values, setValues] = useState(detailDeal);
  const [category, setCategory] = useState([]);

  const dispatch = useDispatch();

  const { allDealsCategory } = useSelector((state) => state.getAllDealCategory);

  useEffect(() => {
    if (!allDealsCategory) {
      dispatch(getAllDealCategoryAction());
    }
    if (allDealsCategory) {
      setCategory(allDealsCategory?.data);
    }
  }, [dispatch, allDealsCategory]);

  useEffect(() => {
    if (detailDeal) {
      setValues({ ...detailDeal });
    }
  }, [detailDeal, setValues]);

  return (
    <Form>
      {loadingUpdate ? (
        <LoadingComp />
      ) : (
        <>
          <Grid container spacing={2} style={{ fontSize: "12px" }}>
            <Grid item xs={6}>
              <Select
                disabled
                name="deal_cat_name"
                label="Category Name"
                value={values.sector}
                style={{ width: "100%" }}
              >
                {category.map((option) => (
                  <MenuItem
                    key={option.deal_cat_id}
                    value={option.deal_category_id}
                  >
                    {option.deal_cat_name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={6}>
              <Select
                disabled
                name="province_id"
                label="Province"
                value={values.company_operate}
                style={{ width: "100%" }}
              >
                <MenuItem value={1}>Province No 1.</MenuItem>
                <MenuItem value={2}>Madhesh Pradesh</MenuItem>
                <MenuItem value={3}>Bagmati Pradesh</MenuItem>
                <MenuItem value={4}>Gandaki Pradesh</MenuItem>
                <MenuItem value={5}>Lumbini Pradesh</MenuItem>
                <MenuItem value={6}>Karnali Pradesh</MenuItem>
                <MenuItem value={7}>Sudur Pashchim Pradesh</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={6}>
              <TextField
                disabled
                name="deal_title"
                label="Title"
                value={values.deal_title}
                style={{ width: "100%" }}
                required
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                disabled
                name="deal_overview"
                label="Overview"
                value={values.deal_overview}
                style={{ width: "100%" }}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                disabled
                type="number"
                name="amount"
                value={values.amount}
                label="Amount"
                onWheel={(e) => e.target.blur()}
                style={{ width: "100%" }}
                required
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                disabled
                name="location"
                label="Location"
                value={values.location}
                style={{ width: "100%" }}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                disabled
                type="text"
                name="full_name"
                label="Created By"
                value={values.full_name}
                onWheel={(e) => e.target.blur()}
                style={{ width: "49.2%" }}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <CheckBoxControl
                name="is_featured"
                label="Featured"
                value={values.is_featured}
              />
            </Grid>

            <Grid item xs={6}>
              <CheckBoxControl
                name="status"
                label="Active"
                value={values.deal_status}
              />
            </Grid>
          </Grid>

          <div
            style={{
              display: "flex",
              justifyContent: "end",
              paddingTop: "10px",
              marginTop: "10px",
              borderTop: "1px solid #0b6885",
            }}
          >
            <Button
              variant="contained"
              onClick={() => setOpenDetailPopup(false)}
              style={{ margin: "10px 0 0 10px", backgroundColor: "#ea3f4d" }}
            >
              CANCEL
            </Button>
          </div>
        </>
      )}
    </Form>
  );
};

export default ManageDealsDetailView;

import {
  getAccessToken,
  getAdminUserType,
  getRefreshToken,
  setAccessToken,
  setAdminUserType,
  setRefreshToken,
} from "../../services/storage";

const useAdminAuthentication = () => {
  const loginAdmin = ({ refreshToken, accessToken }) => {
    setAccessToken(accessToken);
    setRefreshToken(refreshToken);
    setAdminUserType("admin");
  };

  const accessToken = getAccessToken();
  const refreshToken = getRefreshToken();
  const type = getAdminUserType();

  const isAdminAuthenticated = accessToken && refreshToken && type === "admin";
  return {
    loginAdmin,
    isAdminAuthenticated,
  };
};

export default useAdminAuthentication;

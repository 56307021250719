export const GET_ALL_ENTERPRISE_FAQS_REQUEST =
  "GET_ALL_ENTERPRISE_FAQS_REQUEST";
export const GET_ALL_ENTERPRISE_FAQS_SUCCESS =
  "GET_ALL_ENTERPRISE_FAQS_SUCCESS";
export const GET_ALL_ENTERPRISE_FAQS_FAIL = "GET_ALL_ENTERPRISE_FAQS_FAIL";
export const GET_ALL_ENTERPRISE_FAQS_RESET = "GET_ALL_ENTERPRISE_FAQS_RESET";

export const GET_ALL_INVESTOR_FAQS_REQUEST = "GET_ALL_INVESTOR_FAQS_REQUEST";
export const GET_ALL_INVESTOR_FAQS_SUCCESS = "GET_ALL_INVESTOR_FAQS_SUCCESS";
export const GET_ALL_INVESTOR_FAQS_FAIL = "GET_ALL_INVESTOR_FAQS_FAIL";
export const GET_ALL_INVESTOR_FAQS_RESET = "GET_ALL_INVESTOR_FAQS_RESET";

export const GET_SINGLE_FAQS_REQUEST = "GET_SINGLE_FAQS_REQUEST";
export const GET_SINGLE_FAQS_SUCCESS = "GET_SINGLE_FAQS_SUCCESS";
export const GET_SINGLE_FAQS_FAIL = "GET_SINGLE_FAQS_FAIL";
export const GET_SINGLE_FAQS_RESET = "GET_SINGLE_FAQS_RESET";

export const POST_FAQS_REQUEST = "POST_FAQS_REQUEST";
export const POST_FAQS_SUCCESS = "POST_FAQS_SUCCESS";
export const POST_FAQS_FAIL = "POST_FAQS_FAIL";
export const POST_FAQS_RESET = "POST_FAQS_RESET";

export const PUT_FAQS_REQUEST = "PUT_FAQS_REQUEST";
export const PUT_FAQS_SUCCESS = "PUT_FAQS_SUCCESS";
export const PUT_FAQS_FAIL = "PUT_FAQS_FAIL";
export const PUT_FAQS_RESET = "PUT_FAQS_RESET";

export const DELETE_FAQS_REQUEST = "DELETE_FAQS_REQUEST";
export const DELETE_FAQS_SUCCESS = "DELETE_FAQS_SUCCESS";
export const DELETE_FAQS_FAIL = "DELETE_FAQS_FAIL";
export const DELETE_FAQS_RESET = "DELETE_FAQS_RESET";

import {
  getMemberAccessToken,
  getMemberRefreshToken,
  setMemberAccessToken,
  setMemberRefreshToken,
  getUserType,
  setUserType,
} from "../../services/storage";

const useMemberAuthentication = () => {
  const login = ({ refreshToken, accessToken }) => {
    setMemberAccessToken(accessToken);
    setMemberRefreshToken(refreshToken);
    setUserType("member");
  };

  const accessToken = getMemberAccessToken();
  const refreshToken = getMemberRefreshToken();
  const type = getUserType();

  const isAuthenticated = accessToken && refreshToken && type === "member";

  return {
    login,
    isAuthenticated,
  };
};

export default useMemberAuthentication;

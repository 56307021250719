import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ArrowButton from "../components/ArrowButton";
import "../../styles/style.css";

const GeneralInformation = ({ formData, setFormData, formErrors }) => {
  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="description" content="" />
        <meta name="author" content="" />

        <title>Million Deals</title>
        {/* <!-- Linking the font Poppins--> */}
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />

        {/* <!-- Linking Bootstrap Icon--> */}
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css"
        />
      </Helmet>
      <ArrowButton />

      <div className="page-title">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h1>Investor Form</h1>
              <Link className="link" to="/">
                Home
              </Link>{" "}
              /{" "}
              <Link className="link" to="/dashboard">
                Dashboard
              </Link>{" "}
              / Investor Form
            </div>
          </div>
        </div>
      </div>

      <div className="page-content">
        <div className="container">
          <div className="row">
            <div className="col-md-9">
              <p>
                If you are an investor looking for deals, please fill the form
                below to show your interest in investing through our platform.
                Our team at Million Deals will reach out to you to take this
                forward.
              </p>
              <p>
                Have any questions? Please contact us at:&nbsp;
                <Link
                  to="info@milliondeals.com.np"
                  title="info@milliondeals.com.np"
                >
                  info@milliondeals.com.np
                </Link>
              </p>

              <div className="invest-form">
                <form>
                  <h2>General Information</h2>
                  Full Name{" "}
                  {formErrors.name && (
                    <span className="error">{formErrors.name}</span>
                  )}
                  <input
                    type="text"
                    name="name"
                    value={formData.name || ""}
                    onChange={handleInputChange}
                  />
                  Phone Number{" "}
                  {formErrors.phone && (
                    <span className="error">{formErrors.phone}</span>
                  )}
                  <input
                    type="number"
                    name="phone"
                    value={formData.phone || ""}
                    onChange={handleInputChange}
                  />
                  Email Address{" "}
                  {formErrors.email && (
                    <span className="error">{formErrors.email}</span>
                  )}
                  <input
                    type="email"
                    name="email"
                    value={formData.email || ""}
                    onChange={handleInputChange}
                  />
                  Are you an individual investor or institutional investor?{" "}
                  {formErrors.ind_or_ins_investor && (
                    <span className="error">
                      {formErrors.ind_or_ins_investor}
                    </span>
                  )}
                  <select
                    name="ind_or_ins_investor"
                    id="ind_or_ins_investor"
                    value={formData.ind_or_ins_investor || ""}
                    onChange={handleInputChange}
                  >
                    <option value="">Select an option</option>
                    <option value="Individual">
                      Individual (Inlcuding Angel Investors)
                    </option>
                    <option value="Institutional">Institutional</option>
                    <option value="Other">Other</option>
                  </select>
                  {formData.ind_or_ins_investor === "Other" && (
                    <>
                      Please describe if any others{" "}
                      {formErrors.ind_or_ins_investor_other && (
                        <span className="error">
                          {formErrors.ind_or_ins_investor_other}
                        </span>
                      )}
                      <input
                        type="text"
                        name="ind_or_ins_investor_other"
                        id="ind_or_ins_investor_other"
                        value={formData.ind_or_ins_investor_other || ""}
                        onChange={handleInputChange}
                      />
                    </>
                  )}
                  Your Linkedin profile or other online profile{" "}
                  {formErrors.linkin_or_online_profile && (
                    <span className="error">
                      {formErrors.linkin_or_online_profile}
                    </span>
                  )}
                  <input
                    type="text"
                    name="linkin_or_online_profile"
                    value={formData.linkin_or_online_profile || ""}
                    onChange={handleInputChange}
                  />
                  Contact Address{" "}
                  {formErrors.contact_address && (
                    <span className="error">{formErrors.contact_address}</span>
                  )}
                  <input
                    type="text"
                    name="contact_address"
                    value={formData.contact_address || ""}
                    onChange={handleInputChange}
                  />
                  Please provide a brief information about your profile and
                  background{" "}
                  {formErrors.bio && (
                    <span className="error">{formErrors.bio}</span>
                  )}
                  <textarea
                    type="text"
                    name="bio"
                    value={formData.bio || ""}
                    onChange={handleInputChange}
                  />
                  Please state your investment objective - why are you
                  interested in investing in companies and projects in Nepal?
                  Please also state your risk appetite and return expectations.
                  <br />
                  {formErrors.interested_in_companies && (
                    <span className="error">
                      {formErrors.interested_in_companies}
                    </span>
                  )}
                  <textarea
                    type="text"
                    name="interested_in_companies"
                    value={formData.interested_in_companies || ""}
                    onChange={handleInputChange}
                  />
                  What is your role in your company?{" "}
                  {formErrors.company_role && (
                    <span className="error">{formErrors.company_role}</span>
                  )}
                  <input
                    type="text"
                    name="company_role"
                    value={formData.company_role || ""}
                    onChange={handleInputChange}
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GeneralInformation;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import LoadingComp from "../../../components/LoadingComp";
import ArrowButton from "../../components/ArrowButton";
import DashboardSideMenu from "../../components/DashboardSideMenu";

const provinceList = [
  { province_id: 1, label: "Province No. 1" },
  { province_id: 2, label: "Madhesh Pradesh" },
  { province_id: 3, label: "Bagmati Pradesh" },
  { province_id: 4, label: "Gandaki Pradesh" },
  { province_id: 5, label: "Lumbini Pradesh" },
  { province_id: 6, label: "Karnali Pradesh" },
  { province_id: 7, label: "Sudur Pashchim Pradesh" },
];

const SingleShowInterestDeal = () => {
  const [singleDeals, setSingleDeals] = useState(
    JSON.parse(localStorage.getItem("singleIntrestedDealsClient")) || {}
  );

  const dispatch = useDispatch();

  const { detailDealPrivate, loading } = useSelector(
    (state) => state.getDetailDealsPrivate
  );

  useEffect(() => {
    if (detailDealPrivate) {
      setSingleDeals(detailDealPrivate?.data);
    }
  }, [dispatch, detailDealPrivate]);

  return (
    <>
      <div className="page-title">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h1>Dashboard</h1>
              <Link className="link" to="/">
                Home
              </Link>{" "}
              {""} /{""}
              <Link className="link" to="/dashboard">
                {" "}
                Dashboard
              </Link>{" "}
              {""} /{""}
              <Link className="link" to="/dashboard/interested-deals">
                Interested Deals
              </Link>
              {""} / {""}
              {singleDeals?.deal_title}
            </div>
          </div>
        </div>
      </div>
      <ArrowButton />

      <div className="container pt-4 pb-5 mb-5">
        <div className="row">
          <div className="col-md-3">
            <DashboardSideMenu />
          </div>
          {loading ? (
            <LoadingComp />
          ) : (
            <>
              <div className="col-md-9 mt-5">
                <div className="single-deal">
                  <h2>{singleDeals?.deal_title}</h2>
                  <div className="deal-details">
                    <div className="row pt-3">
                      <div className="col-6">
                        <b>Deal Title:</b>
                      </div>
                      <div className="col-6">{singleDeals?.deal_title}</div>
                    </div>
                    <div className="row pt-3">
                      <div className="col-6">
                        <b>Deal Overview:</b>
                      </div>
                      <div className="col-6">{singleDeals?.deal_overview}</div>
                    </div>
                    <div className="row pt-3">
                      <div className="col-6">
                        <b>Company Name:</b>
                      </div>
                      <div className="col-6">Available after connect</div>
                    </div>
                    <div className="row pt-3">
                      <div className="col-6">
                        <b>Contact Details:</b>
                      </div>
                      <div className="col-6">Available after connect</div>
                    </div>

                    <div className="row pt-3">
                      <div className="col-6">
                        <b>Sector:</b>
                      </div>
                      <div className="col-6">{singleDeals?.deal_category}</div>
                    </div>

                    <div className="row pt-3">
                      <div className="col-6">
                        <b>Company Operate (Province):</b>
                      </div>
                      <div className="col-6">
                        {
                          provinceList.find(
                            (province) =>
                              province.province_id ===
                              singleDeals?.company_operate
                          )?.label
                        }
                      </div>
                    </div>

                    <div className="row pt-3">
                      <div className="col-6">
                        <b>Enterprise structure:</b>
                      </div>
                      <div className="col-6">
                        {/* {singleDeals?.location} */}Idea Stage
                      </div>
                    </div>

                    <div className="row pt-3">
                      <div className="col-6">
                        <b>Years since incorporation:</b>
                      </div>
                      <div className="col-6">1</div>
                    </div>

                    <div className="row pt-3">
                      <div className="col-6">
                        <b>HQ Location:</b>
                      </div>
                      <div className="col-6">{singleDeals?.location}</div>
                    </div>

                    <div className="row pt-3">
                      <div className="col-6">
                        <b>Sustainability focus:</b>
                      </div>
                      <div className="col-6">
                        {singleDeals?.sdg_name?.length > 0 ? (
                          <>{singleDeals?.sdg_name}</>
                        ) : (
                          "N/A"
                        )}
                      </div>
                    </div>

                    <div className="row pt-3">
                      <div className="col-6">
                        <b>Investment Size:</b>
                      </div>
                      <div className="col-6">{singleDeals?.amount}</div>
                    </div>

                    <div className="row pt-3">
                      <div className="col-6">
                        <b>Preferred investment structure:</b>
                      </div>
                      <div className="col-6">Equity</div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default SingleShowInterestDeal;

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import logo1 from "./front-end-images/1.png";
import logo2 from "./front-end-images/2.jpg";
import logo3 from "./front-end-images/146.jpg";
import logo4 from "./front-end-images/222.jpg";
import LoadingComp from "../components/LoadingComp";
import UserFooter from "./components/UserFooter";
import UserHeader from "./components/UserHeader";
import { API_URL } from "../api/axios";
import "../styles/style.css";
import {
  getAllAboutPublicAction,
  getAllBlogsPublicAction,
  getAllDealCategoryPublicAction,
  getAllEnterpriseFaqsPublicAction,
  getAllInvestorFaqsPublicAction,
  getAllNewsPublicAction,
  getAllSuccessStoriesPublicAction,
  postContactFormAction,
} from "./public-routes/PublicRoutesActions";
import Moment from "react-moment";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import Notification from "../components/Notification";
import { toast, ToastContainer } from "react-toastify";
import ArrowButton from "./components/ArrowButton";
import { Carousel } from "react-bootstrap";

const HomePage = () => {
  const [aboutValues, setAboutValaues] = useState({});
  const [category, setCategory] = useState([]);
  const [successStories, setSuccessStories] = useState([]);
  const [showFullSuccess, setShowFullSuccess] = useState(false);
  const [showFull, setShowFull] = useState(false);
  const [showAboutUsFull, setShowAboutUsFull] = useState(false);
  const [showBlogsData, setShowBlogsData] = useState(false);
  const [showNewsData, setShowNewsData] = useState(true);
  const [blogs, setBlogs] = useState([]);
  const [news, setNews] = useState([]);
  const [faqsEnterprise, setFaqsEnterprise] = useState([]);
  const [faqsInvestor, setFaqsInvestor] = useState([]);
  const [showEnterprise, setShowEnterprise] = useState(true);
  const [showInvestor, setShowInvestor] = useState(false);
  const [activeButton, setActiveButton] = useState("news");
  const [activeFaqs, setActiveFaqs] = useState("enterprise");

  const [orgName, setOrgName] = useState("");
  const [contact, setContact] = useState();
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [selectedIndex, setSelectedIndex] = useState(-1);
  const dispatch = useDispatch();
  const { allAboutPublic, loading } = useSelector(
    (state) => state.getAboutCompanyPublic
  );

  const { allDealsCategoryPublic } = useSelector(
    (state) => state.getAllDealCategoryPublic
  );

  const { allSuccessStoriesPublic } = useSelector(
    (state) => state.getAllSuccessStoriesPublic
  );

  const { allNewsPublic } = useSelector((state) => state.getAllNewsPublic);

  const { allBlogsPublic } = useSelector((state) => state.getAllBlogsPublic);

  const { allInvestorFaqsPublic } = useSelector(
    (state) => state.getAllInvestorFaqsPublic
  );

  const { allEnterpriseFaqsPublic } = useSelector(
    (state) => state.getAllEnterpriseFaqsPublic
  );

  const initialFormValues = {
    full_name: orgName,
    phone: contact,
    email: email,
    message: message,
  };

  const filteredCategory = category.filter((item) => item.status === 1);
  const filteredStories = successStories.filter((item) => item.status === 1);
  const filteredNews = news.filter((item) => item.status === 1);
  const filteredBlogs = blogs.filter((item) => item.status === 1);
  const filteredEnterprise = faqsEnterprise.filter((item) => item.status === 1);
  const filteredInvestor = faqsInvestor.filter((item) => item.status === 1);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (!allAboutPublic) {
      dispatch(getAllAboutPublicAction());
    }
    if (allAboutPublic) {
      setAboutValaues(allAboutPublic?.data);
    }
  }, [dispatch, allAboutPublic]);

  useEffect(() => {
    if (!allDealsCategoryPublic) {
      dispatch(getAllDealCategoryPublicAction());
    }
    if (allDealsCategoryPublic) {
      setCategory(allDealsCategoryPublic?.data);
    }
  }, [dispatch, allDealsCategoryPublic]);

  useEffect(() => {
    if (!allSuccessStoriesPublic) {
      dispatch(getAllSuccessStoriesPublicAction());
    }
    if (allSuccessStoriesPublic) {
      setSuccessStories(allSuccessStoriesPublic?.data);
    }
  }, [dispatch, allSuccessStoriesPublic]);

  useEffect(() => {
    if (!allNewsPublic) {
      dispatch(getAllNewsPublicAction());
    }
    if (allNewsPublic) {
      setNews(allNewsPublic?.data);
    }
  }, [dispatch, allNewsPublic]);

  useEffect(() => {
    if (!allBlogsPublic) {
      dispatch(getAllBlogsPublicAction());
    }
    if (allBlogsPublic) {
      setBlogs(allBlogsPublic?.data);
    }
  }, [dispatch, allBlogsPublic]);

  useEffect(() => {
    if (!allInvestorFaqsPublic) {
      dispatch(getAllInvestorFaqsPublicAction());
    }
    if (allInvestorFaqsPublic) {
      setFaqsInvestor(allInvestorFaqsPublic?.data);
    }
  }, [dispatch, allInvestorFaqsPublic]);

  useEffect(() => {
    if (!allEnterpriseFaqsPublic) {
      dispatch(getAllEnterpriseFaqsPublicAction());
    }
    if (allEnterpriseFaqsPublic) {
      setFaqsEnterprise(allEnterpriseFaqsPublic?.data);
    }
  }, [dispatch, allEnterpriseFaqsPublic]);

  useEffect(() => {
    dispatch(getAllAboutPublicAction());
    dispatch(getAllDealCategoryPublicAction());
    dispatch(getAllSuccessStoriesPublicAction());
    dispatch(getAllBlogsPublicAction());
    dispatch(getAllNewsPublicAction());
    dispatch(getAllEnterpriseFaqsPublicAction());
    dispatch(getAllInvestorFaqsPublicAction());
  }, [dispatch]);

  const handleButtonClick = (buttonType) => {
    setActiveButton(buttonType);
    setShowNewsData(buttonType === "news");
    setShowBlogsData(buttonType === "blogs");
    dispatch(getAllBlogsPublicAction());
    dispatch(getAllNewsPublicAction());
  };

  const handleFaqsButton = (buttonType) => {
    setActiveFaqs(buttonType);
    setShowEnterprise(buttonType === "enterprise");
    setShowInvestor(buttonType === "investor");
    dispatch(getAllEnterpriseFaqsPublicAction());
    dispatch(getAllInvestorFaqsPublicAction());
  };

  const handleNewsBlogsClick = (buttonName) => {
    handleButtonClick(buttonName);
  };

  const handleFaqsBothClick = (buttonName) => {
    handleFaqsButton(buttonName);
  };

  const handleContactFormSubmit = (e) => {
    e.preventDefault();
    let errors = {};
    if (!orgName) {
      errors["orgName"] = "Bussiness/Your name is required";
    }
    if (!contact) {
      errors["contact"] = "Contact number is required";
    }
    if (!email) {
      errors["email"] = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors["email"] = "Email is invalid";
    }
    if (!message) {
      errors["message"] = "Message is required";
    }

    setErrors(errors);

    if (Object.keys(errors).length === 0) {
      dispatch(postContactFormAction(initialFormValues)).then(() => {
        toast.success("Your Feedback has been Submitted Successfully.");
      });
      setOrgName("");
      setContact("");
      setEmail("");
      setMessage("");
    }
  };

  return (
    <>
      <UserHeader />
      <Helmet>
        <meta charset="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="description" content="million deals" />
        <meta name="author" content="million deals" />

        <title>Million Deals</title>
        {/* <!-- Linking the font Poppins--> */}
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />

        {/* <!-- Linking Bootstrap Icon--> */}
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css"
        />
      </Helmet>
      <ArrowButton />
      <div className="slider">
        <div className="container">
          <div className="row position-relative">
            <div className="col-lg-6 col-md-12">
              <h2 className="section-title">
                We connect Investors & Enterprises
              </h2>
              <p
                className="section-text white-text"
                style={{ textAlign: "justify" }}
              >
                We know that for many investors origination and pipeline
                building are pressing challenges, while many high potential
                companies are not able to connect to the right investors and the
                right type of capital. At Million Deals, we help private angel
                and institutional investors source a pipeline by showcasing high
                potential high impact local enterprises and projects trying to
                raise capital. We also support enterprises with bespoke
                consulting services and access to expertise to fulfill any
                structural and capacity gaps that may exist.
              </p>
              <b>
                <i>
                  Investing in Nepal has never been easier. Come join our
                  network of Local Enterprises and Investors!
                </i>
              </b>
              <br />
              <br />
              <button
                className={`slide-btn mb-3 ${
                  activeFaqs === "enterprise" ? "active-btn" : ""
                }`}
                onClick={() => {
                  handleFaqsBothClick("enterprise");
                }}
              >
                I am an Enterprise
              </button>
              <button
                className={`slide-btn ${
                  activeFaqs === "investor" ? "active-btn" : ""
                }`}
                onClick={() => {
                  handleFaqsBothClick("investor");
                }}
              >
                I am an Investor
              </button>
            </div>

            <div className="col-lg-6 col-md-8">
              <img alt="" src={logo1} />
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <LoadingComp />
      ) : (
        <>
          <div className="about">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  {aboutValues?.about_image && aboutValues?.about_image ? (
                    <img
                      alt=""
                      src={`${API_URL}/uploads/about/${aboutValues?.about_image}`}
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-6">
                  <h3 className="section-subtitle">{aboutValues?.title}</h3>
                  {/* <h2 className="section-title">
                    All {allAboutPublic?.message}
                  </h2> */}
                  <p
                    className="section-text"
                    style={{ textAlign: "justify" }}
                    dangerouslySetInnerHTML={{
                      __html: showAboutUsFull
                        ? aboutValues?.description
                        : aboutValues?.description?.length > 400
                        ? aboutValues?.description.slice(0, 400) + "....."
                        : aboutValues?.description,
                    }}
                  ></p>
                  {showAboutUsFull && (
                    <Link onClick={() => setShowAboutUsFull(true)}></Link>
                  )}
                  <Link
                    to="/about-us"
                    style={{ textDecoration: "none", color: "#ffffff" }}
                    className="dark-btn"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="process">
            <div className="container">
              <div className="row">
                <div className="col-md-7">
                  <h3 className="section-subtitle">Our Process</h3>
                  <h2 className="section-title">How We Work & Connect</h2>
                </div>

                <div className="col-md-5 text-right">
                  <button
                    className={`dark-btn ${
                      activeFaqs === "enterprise" ? "active-btn" : ""
                    }`}
                    onClick={() => {
                      handleFaqsBothClick("enterprise");
                    }}
                  >
                    I am an Enterprise
                  </button>
                  <button
                    className={`dark-btn ${
                      activeFaqs === "investor" ? "active-btn" : ""
                    }`}
                    onClick={() => {
                      handleFaqsBothClick("investor");
                    }}
                  >
                    I am an Investor
                  </button>
                </div>
              </div>
              {showEnterprise ? (
                <Carousel variant="dark" slide={false}>
                  <Carousel.Item style={{ marginBottom: "50px" }}>
                    <div className="row pt-3">
                      <div className="col-md-4">
                        <div className="process-single">
                          <img alt="" src={logo4} />
                          <h2>1. Register on our Platform</h2>
                          <p
                            className="section-text"
                            style={{ textAlign: "justify" }}
                          >
                            Create an account on our platform, using your email,
                            to access a wealth of resources, support, and
                            potential investors....
                          </p>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="process-single">
                          <img alt="" src={logo3} />
                          <h2>2. Create your Deal</h2>
                          <p
                            className="section-text"
                            style={{ textAlign: "justify" }}
                          >
                            Once registered, create your deal by answering our
                            questionnaire to let us know more about your
                            business....
                          </p>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="process-single">
                          <img alt="" src={logo2} />
                          <h2>3. Get Verified</h2>
                          <p
                            className="section-text"
                            style={{ textAlign: "justify" }}
                          >
                            Our team will review your answers and conduct
                            initial checks to verify your company and to ensure
                            your deal meets....
                          </p>
                        </div>
                      </div>
                    </div>
                  </Carousel.Item>

                  <Carousel.Item style={{ marginBottom: "50px" }}>
                    <div className="row pt-3">
                      <div className="col-md-4">
                        <div className="process-single">
                          <img alt="" src={logo2} />
                          <h2>4. Engage with our Due Diligence Team</h2>
                          <p
                            className="section-text"
                            style={{ textAlign: "justify" }}
                          >
                            Once your deal is verified we will contact you to
                            discuss specific details and to ensure that all
                            aspects of your business....
                          </p>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="process-single">
                          <img alt="" src={logo3} />
                          <h2>5. Get Listed and Ready to meet Investors</h2>
                          <p
                            className="section-text"
                            style={{ textAlign: "justify" }}
                          >
                            Once your deal is verified and our Due Diligence
                            team has undergone initial engagement with you, your
                            deal....
                          </p>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="process-single">
                          <img alt="" src={logo4} />
                          <h2>6. Access continual support from our team</h2>
                          <p
                            className="section-text"
                            style={{ textAlign: "justify" }}
                          >
                            Our team will be there to support you throughout the
                            investment process as well as to provide you
                            additional....
                          </p>
                        </div>
                      </div>
                    </div>
                  </Carousel.Item>
                </Carousel>
              ) : showInvestor ? (
                <Carousel variant="dark" slide={false}>
                  <Carousel.Item style={{ marginBottom: "50px" }}>
                    <div className="row pt-3">
                      <div className="col-md-4">
                        <div className="process-single">
                          <img alt="" src={logo2} />
                          <h2>1. Register on our Platform</h2>
                          <p
                            className="section-text"
                            style={{ textAlign: "justify" }}
                          >
                            Create an account on our platform, using your email,
                            to access a wealth of investment opportunities,
                            resources, and support....
                          </p>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="process-single">
                          <img alt="" src={logo3} />
                          <h2>2. Get Verified</h2>
                          <p
                            className="section-text"
                            style={{ textAlign: "justify" }}
                          >
                            Our team will review your answers and conduct
                            initial checks to verify your account and to ensure
                            that investors on our....
                          </p>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="process-single">
                          <img alt="" src={logo4} />
                          <h2>3. Access Vetted Deals</h2>
                          <p
                            className="section-text"
                            style={{ textAlign: "justify" }}
                          >
                            Once your account is verified, you can access our
                            network of vetted deals, carefully selected by our
                            due diligence team to....
                          </p>
                        </div>
                      </div>
                    </div>
                  </Carousel.Item>

                  <Carousel.Item style={{ marginBottom: "50px" }}>
                    <div className="row pt-3">
                      <div className="col-md-4">
                        <div className="process-single">
                          <img alt="" src={logo3} />
                          <h2>4. Show interest in particular Deals</h2>
                          <p
                            className="section-text"
                            style={{ textAlign: "justify" }}
                          >
                            You can identify investment opportunities that align
                            with your investment criteria using our filters by
                            sector, geography....
                          </p>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="process-single">
                          <img alt="" src={logo4} />
                          <h2>5. Access detailed information</h2>
                          <p
                            className="section-text"
                            style={{ textAlign: "justify" }}
                          >
                            Once you have shown interest in a particular deal
                            our team will reach out to you to ensure
                            confidentiality, following....
                          </p>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="process-single">
                          <img alt="" src={logo2} />
                          <h2>6. Start Investing</h2>
                          <p
                            className="section-text"
                            style={{ textAlign: "justify" }}
                          >
                            Once you have reviewed the due diligence report, and
                            made the decision to invest, our team will be there
                            to support you in finalizing....
                          </p>
                        </div>
                      </div>
                    </div>
                  </Carousel.Item>
                </Carousel>
              ) : (
                "No Process Found"
              )}
            </div>
          </div>

          {category.length > 0 ? (
            <div className="home-listings">
              <div className="container">
                <div className="row">
                  <div className="col-md-7">
                    <h3 className="section-subtitle">Popular Listings</h3>
                    <h2 className="section-title">
                      Multilple Sectors of Businesses
                    </h2>
                  </div>

                  <div className="col-md-5 text-right">
                    <Link
                      className="dark-btn"
                      style={{ textDecoration: "none", color: "#ffffff" }}
                      to="/deals-listing"
                    >
                      View All ({category.length})
                    </Link>
                  </div>
                </div>

                <div className="row pt-3">
                  {filteredCategory.slice(0, 8).map((option) => (
                    <div className="col-md-3" key={option.deal_category_id}>
                      <div className="list-single">
                        {option.deal_cat_image ? (
                          <Link
                            // to="/deals-listing"
                            to={{
                              pathname: "/deals-listing",
                              search: `?category=${option.deal_category_id}`,
                            }}
                            style={{
                              color: "black",
                              textDecoration: "none",
                            }}
                          >
                            <img
                              alt=""
                              style={{
                                aspectRatio: "3/2",
                              }}
                              src={`${API_URL}/uploads/deal_categories/${option.deal_cat_image}`}
                            />
                            <h3>{option.deal_cat_name}</h3>
                          </Link>
                        ) : (
                          <Link
                            // to="/deals-listing"
                            to={{
                              pathname: "/deals-listing",
                              search: `?category=${option.deal_category_id}`,
                            }}
                            style={{
                              color: "black",
                              textDecoration: "none",
                            }}
                          >
                            <img
                              alt=""
                              src="https://i.postimg.cc/vZvJdnRS/blank-head-profile-pic-for-a-man.jpg"
                              style={{
                                aspectRatio: "3/2",
                              }}
                            />
                            <h3>{option.deal_cat_name}</h3>
                          </Link>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          {successStories.length > 0 ? (
            <div className="success-story">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 text-center">
                    <h3 className="section-subtitle">Success Stories</h3>
                    <h2 className="section-title">
                      Here's what our clients say
                    </h2>
                  </div>
                </div>

                <Carousel slide={false}>
                  {filteredStories?.map((option, index) => {
                    const startIndex = index * 2;
                    const endIndex = startIndex + 2;
                    const storiesToRender = filteredStories.slice(
                      startIndex,
                      endIndex
                    );

                    if (storiesToRender.length === 0) return null;

                    return (
                      <Carousel.Item
                        key={option.ss_uid}
                        style={{ marginBottom: "65px" }}
                      >
                        <div className="row pt-4">
                          {storiesToRender.map((option) => (
                            <div className="col-md-6" key={option.ss_uid}>
                              <div className="story-single">
                                <p
                                  className="section-text"
                                  style={{ textAlign: "justify" }}
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      showFullSuccess === option.ss_uid
                                        ? option?.ss_message
                                        : option?.ss_message?.length > 300
                                        ? option?.ss_message.slice(0, 300) +
                                          "......"
                                        : option?.ss_message,
                                  }}
                                ></p>
                                <div
                                  style={{
                                    marginTop: "-30px",
                                    marginBottom: "20px",
                                  }}
                                >
                                  {" "}
                                  {showFullSuccess !== option.ss_uid &&
                                    option?.ss_message?.length > 300 && (
                                      <Link
                                        style={{ textDecoration: "none" }}
                                        onClick={() =>
                                          setShowFullSuccess(option.ss_uid)
                                        }
                                      >
                                        Read More{">>"}
                                      </Link>
                                    )}
                                  {showFullSuccess === option.ss_uid && (
                                    <Link
                                      style={{ textDecoration: "none" }}
                                      onClick={() => setShowFullSuccess(false)}
                                    >
                                      {"<<"}Show Less
                                    </Link>
                                  )}
                                </div>
                                {option?.partner_logo_image &&
                                option?.partner_logo_image ? (
                                  <img
                                    alt=""
                                    src={`${API_URL}/uploads/partners/${option?.partner_logo_image}`}
                                  />
                                ) : (
                                  <img
                                    alt=""
                                    src={`${API_URL}/uploads/success_stories/${option?.ss_avatar_image}`}
                                  />
                                )}
                                <h3>
                                  {option.partner_company_name
                                    ? option.partner_company_name
                                    : option.ss_name}
                                </h3>
                              </div>
                            </div>
                          ))}
                        </div>
                      </Carousel.Item>
                    );
                  })}
                </Carousel>
              </div>
            </div>
          ) : (
            ""
          )}

          {blogs.length > 0 || news.length > 0 ? (
            <div className="news">
              <div className="container">
                <div className="row">
                  <div className="col-md-7">
                    <Link
                      to="/news-&-blogs"
                      className="section-subtitle"
                      style={{ textDecoration: "none", color: "#0B6885" }}
                    >
                      News & Blogs
                    </Link>
                    <h2 className="section-title">Stay Updated With Us</h2>
                  </div>

                  <div className="col-md-5 text-right">
                    <button
                      className={`dark-btn ${
                        activeButton === "news" ? "active-btn" : ""
                      }`}
                      onClick={() => {
                        handleNewsBlogsClick("news");
                      }}
                    >
                      Us in News
                    </button>
                    <button
                      className={`dark-btn ${
                        activeButton === "blogs" ? "active-btn" : ""
                      }`}
                      onClick={() => {
                        handleNewsBlogsClick("blogs");
                      }}
                    >
                      Blog Posts
                    </button>
                  </div>
                </div>
                {showBlogsData ? (
                  blogs.length > 0 ? (
                    <div className="row pt-4">
                      {filteredBlogs.slice(0, 3).map((option, index) => (
                        <div className="col-md-4" key={index}>
                          <div className="news-single">
                            {option?.blog_image && option?.blog_image ? (
                              <img
                                alt=""
                                src={`${API_URL}/uploads/blogs/${option.blog_image}`}
                              />
                            ) : (
                              <img
                                alt=""
                                src="https://i.postimg.cc/vZvJdnRS/blank-head-profile-pic-for-a-man.jpg"
                              />
                            )}
                            <h4>
                              <i className="bi bi-calendar-fill"></i> &nbsp;
                              <Moment format="MMM D, YYYY">
                                {option.created_at}
                              </Moment>
                            </h4>
                            <h2>{option.blog_name}</h2>
                            <p
                              className="section-text section-text-blog"
                              dangerouslySetInnerHTML={{
                                __html:
                                  showFull === index
                                    ? option?.blog_description
                                    : option?.blog_description?.length > 300
                                    ? option?.blog_description.slice(0, 300) +
                                      "....."
                                    : option?.blog_description,
                              }}
                            ></p>
                            {showFull !== index &&
                              option?.blog_description?.length > 300 && (
                                <Link
                                  style={{ textDecoration: "none" }}
                                  onClick={() => setShowFull(index)}
                                >
                                  Read More{">>"}
                                </Link>
                              )}

                            {showFull === index && (
                              <Link
                                style={{ textDecoration: "none" }}
                                onClick={() => setShowFull(false)}
                              >
                                {"<<"} Show Less
                              </Link>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <span className="error">*No Blogs Found</span>
                  )
                ) : showNewsData ? (
                  news.length > 0 ? (
                    <div className="row pt-4">
                      {filteredNews.slice(0, 3).map((option, index) => (
                        <div className="col-md-4" key={index}>
                          <div className="news-single">
                            {option?.news_image && option?.news_image ? (
                              <img
                                alt=""
                                src={`${API_URL}/uploads/news/${option.news_image}`}
                              />
                            ) : (
                              <img
                                alt=""
                                src="https://i.postimg.cc/vZvJdnRS/blank-head-profile-pic-for-a-man.jpg"
                              />
                            )}
                            <h4>
                              <i className="bi bi-calendar-fill"></i> &nbsp;
                              <Moment format="MMM D, YYYY">
                                {option.created_at}
                              </Moment>
                            </h4>
                            <h2>{option.news_title}</h2>
                            <p
                              className="section-text section-text-blog"
                              dangerouslySetInnerHTML={{
                                __html:
                                  showFull === index
                                    ? option?.news_description
                                    : option?.news_description?.length > 300
                                    ? option?.news_description.slice(0, 300) +
                                      "....."
                                    : option?.news_description,
                              }}
                            ></p>
                            {showFull !== index &&
                              option?.news_description?.length > 300 && (
                                <Link
                                  style={{ textDecoration: "none" }}
                                  onClick={() => setShowFull(index)}
                                >
                                  Read More{">>"}
                                </Link>
                              )}

                            {showFull === index && (
                              <Link
                                style={{ textDecoration: "none" }}
                                onClick={() => setShowFull(false)}
                              >
                                {"<<"}Show Less
                              </Link>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <span className="error">*No News Found</span>
                  )
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="home-faq">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <div className="row">
                    <div className="col-lg-6 col-md-5">
                      <h3 className="section-subtitle">
                        Frequently Asked Questions
                      </h3>
                      <h2 className="section-title">Clear All Doubts</h2>
                    </div>

                    <div className="col-lg-6 col-md-7 text-right">
                      <button
                        className={`dark-btn ${
                          activeFaqs === "enterprise" ? "active-btn" : ""
                        }`}
                        onClick={() => {
                          handleFaqsBothClick("enterprise");
                        }}
                      >
                        For Enterprise
                      </button>
                      <button
                        className={`dark-btn ${
                          activeFaqs === "investor" ? "active-btn" : ""
                        }`}
                        onClick={() => {
                          handleFaqsBothClick("investor");
                        }}
                      >
                        For Investor
                      </button>
                    </div>
                  </div>
                  {showEnterprise ? (
                    filteredEnterprise.length > 0 ? (
                      filteredEnterprise.slice(0, 3).map((item, index) => (
                        <div className="row pt-2" key={item.id}>
                          <div
                            className="accordion"
                            id={`accordionExample${index}`}
                          >
                            <div className="card card-responsive">
                              <Accordion
                                TransitionProps={{ unmountOnExit: true }}
                              >
                                <AccordionSummary
                                  style={{
                                    backgroundColor: "#0b6885",
                                    color: "#ffffff",
                                  }}
                                  expandIcon={
                                    <AddIcon
                                      style={{
                                        color: "#ffffff",
                                      }}
                                    />
                                  }
                                  onClick={() => {
                                    setSelectedIndex(index);
                                  }}
                                >
                                  <Typography
                                    className="mb-0"
                                    variant="h5"
                                    fontSize={18}
                                    letterSpacing={1}
                                  >
                                    {item.question}
                                  </Typography>
                                </AccordionSummary>
                                <div
                                  id={`collapse${index}`}
                                  className={
                                    selectedIndex === index
                                      ? "collapse show"
                                      : "collapse"
                                  }
                                  aria-labelledby={`heading${index}`}
                                  data-parent={`#accordionExample${index}`}
                                >
                                  <AccordionDetails>
                                    <Typography>
                                      <p
                                        className="section-text"
                                        style={{ textAlign: "justify" }}
                                        dangerouslySetInnerHTML={{
                                          __html: item?.text,
                                        }}
                                      ></p>
                                    </Typography>
                                  </AccordionDetails>
                                </div>
                              </Accordion>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <span className="error">*No FAQs Found</span>
                    )
                  ) : showInvestor ? (
                    filteredInvestor.length > 0 ? (
                      filteredInvestor.slice(0, 3).map((item, index) => (
                        <div className="row pt-2" key={item.id}>
                          <div
                            className="accordion"
                            id={`accordionExample${index}`}
                          >
                            <div className="card card-responsive">
                              <Accordion
                                TransitionProps={{ unmountOnExit: true }}
                              >
                                <AccordionSummary
                                  style={{
                                    backgroundColor: "#0b6885",
                                    color: "#ffffff",
                                  }}
                                  expandIcon={
                                    <AddIcon
                                      style={{
                                        color: "#ffffff",
                                      }}
                                    />
                                  }
                                  onClick={() => {
                                    setSelectedIndex(index);
                                  }}
                                >
                                  <Typography
                                    className="mb-0"
                                    variant="h5"
                                    fontSize={18}
                                    letterSpacing={1}
                                  >
                                    {item.question}
                                  </Typography>
                                </AccordionSummary>
                                <div
                                  id={`collapse${index}`}
                                  className={
                                    selectedIndex === index
                                      ? "collapse show"
                                      : "collapse"
                                  }
                                  aria-labelledby={`heading${index}`}
                                  data-parent={`#accordionExample${index}`}
                                >
                                  <AccordionDetails>
                                    <Typography>
                                      <p
                                        className="section-text"
                                        style={{ textAlign: "justify" }}
                                        dangerouslySetInnerHTML={{
                                          __html: item?.text,
                                        }}
                                      ></p>
                                    </Typography>
                                  </AccordionDetails>
                                </div>
                              </Accordion>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <span className="error">*No FAQs Found</span>
                    )
                  ) : (
                    <span className="error">*No Data Found</span>
                  )}
                </div>

                <div className="col-lg-4 mt-3">
                  <div className="short-form">
                    <h3>Have Us Contact You</h3>
                    <p>Fill up the form and have us contact you</p>

                    <form onSubmit={handleContactFormSubmit}>
                      <input
                        type="text"
                        id="orgName"
                        value={orgName}
                        className={`form-control ${
                          errors["orgName"] ? "is-invalid" : ""
                        }`}
                        placeholder="Your Business / Your Name"
                        onChange={(e) => setOrgName(e.target.value)}
                      />
                      {errors["orgName"] && (
                        <div className="invalid-feedback">
                          *{errors["orgName"]}
                        </div>
                      )}

                      <input
                        type="number"
                        id="contact"
                        value={contact}
                        className={`form-control ${
                          errors["contact"] ? "is-invalid" : ""
                        }`}
                        placeholder="Phone Number"
                        onChange={(e) => setContact(e.target.value)}
                      />
                      {errors["contact"] && (
                        <div className="invalid-feedback">
                          *{errors["contact"]}
                        </div>
                      )}

                      <input
                        type="email"
                        placeholder="Email Address"
                        className={`form-control ${
                          errors["email"] ? "is-invalid" : ""
                        }`}
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      {errors["email"] && (
                        <div className="invalid-feedback">
                          *{errors["email"]}
                        </div>
                      )}
                      <textarea
                        className={`form-control ${
                          errors["message"] ? "is-invalid" : ""
                        }`}
                        id="message"
                        value={message}
                        placeholder="Your Message or Concern"
                        onChange={(e) => setMessage(e.target.value)}
                      ></textarea>
                      {errors["message"] && (
                        <div className="invalid-feedback">
                          *{errors["message"]}
                        </div>
                      )}
                      <Button
                        type="submit"
                        style={{
                          backgroundColor: "#0b6885",
                          width: "92%",
                        }}
                        className="send-button"
                        variant="contained"
                      >
                        Send
                      </Button>
                      <ToastContainer
                        position="top-right"
                        autoClose={2000}
                        theme="colored"
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Notification notify={notify} setNotify={setNotify} />
        </>
      )}

      <UserFooter />
    </>
  );
};

export default HomePage;

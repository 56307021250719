import { toast } from "react-toastify";
import {
  API_URL,
  membertokenConfig,
  axiosPrivate,
  membertokenConfigForm,
} from "../../api/axios";
import {
  GET_ALL_DEAL_CATEGORY_FILTER_FAIL,
  GET_ALL_DEAL_CATEGORY_FILTER_REQUEST,
  GET_ALL_DEAL_CATEGORY_FILTER_SUCCESS,
  GET_ALL_SDGS_FILTER_LIST_FAIL,
  GET_ALL_SDGS_FILTER_LIST_REQUEST,
  GET_ALL_SDGS_FILTER_LIST_SUCCESS,
  GET_DETAILED_DEALS_PRIVATE_FAIL,
  GET_DETAILED_DEALS_PRIVATE_REQUEST,
  GET_DETAILED_DEALS_PRIVATE_SUCCESS,
  GET_DETAILED_PERSONAL_INFO_FAIL,
  GET_DETAILED_PERSONAL_INFO_REQUEST,
  GET_DETAILED_PERSONAL_INFO_SUCCESS,
  GET_PROFILE_AS_DEALS_BY_ID_FAIL,
  GET_PROFILE_AS_DEALS_BY_ID_REQUEST,
  GET_PROFILE_AS_DEALS_BY_ID_SUCCESS,
  GET_SHOW_INTERESTED_DEALS_FAIL,
  GET_SHOW_INTERESTED_DEALS_REQUEST,
  GET_SHOW_INTERESTED_DEALS_SUCCESS,
  POST_CHANGE_PASSWORD_FAIL,
  POST_CHANGE_PASSWORD_REQUEST,
  POST_CHANGE_PASSWORD_SUCCESS,
  POST_PROFILE_DATA_FAIL,
  POST_PROFILE_DATA_REQUEST,
  POST_PROFILE_DATA_SUCCESS,
  POST_SHOW_INTEREST_FAIL,
  POST_SHOW_INTEREST_REQUEST,
  POST_SHOW_INTEREST_SUCCESS,
} from "./PrivateConstants";

export const getDetailDealsPrivateAction = (unique_id) => async (dispatch) => {
  try {
    dispatch({ type: GET_DETAILED_DEALS_PRIVATE_REQUEST });

    const { data } = await axiosPrivate.get(
      `${API_URL}/api/v1/admin/deals/${unique_id}`
    );

    dispatch({ type: GET_DETAILED_DEALS_PRIVATE_SUCCESS, payload: data });
    return data;
  } catch (error) {
    dispatch({
      type: GET_DETAILED_DEALS_PRIVATE_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

export const postProfileDataAction =
  (addProfile, image) => async (dispatch) => {
    try {
      dispatch({ type: POST_PROFILE_DATA_REQUEST });

      const jsonData = JSON.stringify(addProfile);

      let updateProfile = false;
      for (const key in addProfile) {
        if (addProfile.hasOwnProperty(key)) {
          if (addProfile[key] !== "" && addProfile[key] !== null) {
            updateProfile = true;
            break;
          }
        }
      }

      if (updateProfile) {
        await axiosPrivate.put(
          `${API_URL}/api/v1/members/update_profile`,
          jsonData,
          membertokenConfig()
        );
      }

      if (image !== null) {
        const formData = new FormData();
        formData.append("profile_image", image);

        await axiosPrivate.post(
          `${API_URL}/api/v1/members/profiles/upload_profile_image`,
          formData
        );
      }

      dispatch({ type: POST_PROFILE_DATA_SUCCESS, payload: updateProfile });
      toast.success("Profile updated successfully.");
      return updateProfile;
    } catch (error) {
      dispatch({
        type: POST_PROFILE_DATA_FAIL,
        payload: error.message ? error.message : error.Message,
      });
      toast.error(error.response.data.message);
    }
  };

export const postChangePasswordAction =
  (changePassword) => async (dispatch) => {
    try {
      dispatch({ type: POST_CHANGE_PASSWORD_REQUEST });

      const jsonData = JSON.stringify(changePassword);
      const { data } = await axiosPrivate.post(
        `${API_URL}/api/v1/members/auth/change_password`,
        jsonData,
        membertokenConfig()
      );

      dispatch({ type: POST_CHANGE_PASSWORD_SUCCESS, payload: data });
      return data;
    } catch (error) {
      dispatch({
        type: POST_CHANGE_PASSWORD_FAIL,
        payload: error.message ? error.message : error.Message,
      });
    }
  };

export const postShowInterestAction = (showInterest) => async (dispatch) => {
  try {
    dispatch({ type: POST_SHOW_INTEREST_REQUEST });

    const jsonData = JSON.stringify(showInterest);

    const { data } = await axiosPrivate.post(
      `${API_URL}/api/v1/show_interest`,
      jsonData,
      membertokenConfig()
    );

    dispatch({ type: POST_SHOW_INTEREST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: POST_SHOW_INTEREST_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

export const getAllDealCategoryFilterAction = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_DEAL_CATEGORY_FILTER_REQUEST });

    const { data } = await axiosPrivate.get(
      `${API_URL}/api/v1/deal_categories`
    );
    dispatch({ type: GET_ALL_DEAL_CATEGORY_FILTER_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_ALL_DEAL_CATEGORY_FILTER_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

export const getAllSdgsFilterAction = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_SDGS_FILTER_LIST_REQUEST });

    const { data } = await axiosPrivate.get(`${API_URL}/api/v1/sdgs`);
    dispatch({ type: GET_ALL_SDGS_FILTER_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_ALL_SDGS_FILTER_LIST_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

export const getDetailedPersonalInfoAction = () => async (dispatch) => {
  try {
    dispatch({ type: GET_DETAILED_PERSONAL_INFO_REQUEST });

    const { data } = await axiosPrivate.get(
      `${API_URL}/api/v1/members/personal_profile`
    );
    dispatch({ type: GET_DETAILED_PERSONAL_INFO_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_DETAILED_PERSONAL_INFO_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

export const getShowInterestedDealsAction = () => async (dispatch) => {
  try {
    dispatch({ type: GET_SHOW_INTERESTED_DEALS_REQUEST });

    const { data } = await axiosPrivate.get(`${API_URL}/api/v1/show_interest`);

    dispatch({ type: GET_SHOW_INTERESTED_DEALS_SUCCESS, payload: data });
    return data;
  } catch (error) {
    dispatch({
      type: GET_SHOW_INTERESTED_DEALS_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

export const getProfileAsDealsByIdAction = (member_id) => async (dispatch) => {
  try {
    dispatch({ type: GET_PROFILE_AS_DEALS_BY_ID_REQUEST });

    const { data } = await axiosPrivate.get(
      `${API_URL}/api/v1/members/all_deals/${member_id}`
    );

    dispatch({ type: GET_PROFILE_AS_DEALS_BY_ID_SUCCESS, payload: data });
    return data;
  } catch (error) {
    dispatch({
      type: GET_PROFILE_AS_DEALS_BY_ID_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

import {
  API_URL,
  axiosAdminPrivate,
  tokenConfig,
  tokenConfigForm,
} from "../../api/axios";
import {
  DELETE_BLOGS_FAIL,
  DELETE_BLOGS_REQUEST,
  DELETE_BLOGS_SUCCESS,
  GET_ALL_BLOGS_FAIL,
  GET_ALL_BLOGS_REQUEST,
  GET_ALL_BLOGS_SUCCESS,
  GET_SINGLE_BLOGS_FAIL,
  GET_SINGLE_BLOGS_REQUEST,
  GET_SINGLE_BLOGS_SUCCESS,
  POST_BLOGS_FAIL,
  POST_BLOGS_REQUEST,
  POST_BLOGS_SUCCESS,
  PUT_BLOGS_FAIL,
  PUT_BLOGS_REQUEST,
  PUT_BLOGS_SUCCESS,
} from "./BlogsConstants";

export const getAllBlogsAction = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_BLOGS_REQUEST });

    const { data } = await axiosAdminPrivate.get(
      `${API_URL}/api/v1/admin/blogs`
    );

    dispatch({ type: GET_ALL_BLOGS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_ALL_BLOGS_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

export const getSingleBlogsAction = (unique_id) => async (dispatch) => {
  try {
    dispatch({ type: GET_SINGLE_BLOGS_REQUEST });

    const { data } = await axiosAdminPrivate.get(
      `${API_URL}/api/v1/admin/blogs/${unique_id}`
    );

    dispatch({ type: GET_SINGLE_BLOGS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_SINGLE_BLOGS_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

export const postBlogsAction = (addBlogs, image) => async (dispatch) => {
  try {
    dispatch({ type: POST_BLOGS_REQUEST });

    const formData = new FormData();
    formData.append("blog_image", image);
    formData.append("blog_name", addBlogs?.blog_name);
    formData.append("blog_slug", addBlogs?.blog_slug);
    formData.append("blog_description", addBlogs?.blog_description);
    formData.append("status", addBlogs?.status);

    const { data } = await axiosAdminPrivate.post(
      `${API_URL}/api/v1/admin/blogs`,
      formData
    );

    dispatch({ type: POST_BLOGS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: POST_BLOGS_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

export const putBlogsAction =
  (updateBlogs, image, unique_id) => async (dispatch) => {
    try {
      dispatch({ type: PUT_BLOGS_REQUEST });

      const formData = new FormData();
      formData.append("blog_image", image);
      formData.append("blog_name", updateBlogs?.blog_name);
      formData.append("blog_slug", updateBlogs?.blog_slug);
      formData.append("blog_description", updateBlogs?.blog_description);
      formData.append("status", updateBlogs?.status);

      const { data } = await axiosAdminPrivate.put(
        `${API_URL}/api/v1/admin/blogs/${unique_id}`,
        formData
      );

      dispatch({ type: PUT_BLOGS_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: PUT_BLOGS_FAIL,
        payload: error.message ? error.message : error.Message,
      });
    }
  };

export const deleteBlogsAction = (unique_id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_BLOGS_REQUEST });

    const { data } = await axiosAdminPrivate.delete(
      `${API_URL}/api/v1/admin/blogs/${unique_id}`,
      tokenConfig()
    );

    dispatch({ type: DELETE_BLOGS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: DELETE_BLOGS_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { postCreateInvestorKycAction } from "../kyc-state-handler/KycActions";
import {
  Button,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useState } from "react";
import LoadingComp from "../../components/LoadingComp";
import {
  getAllDealCategoryFilterAction,
  getAllSdgsFilterAction,
} from "../private-routes/PrivateActions";
import { ToastContainer, toast } from "react-toastify";
import Popup from "../../components/Popup";
import UpdateInvestorKycForm from "./UpdateInvestorKycForm";

const amountRanges = [
  { amount: "1", min: 100000, max: 500000, label: "1 to 5 Lakhs" },
  { amount: "2", min: 500000, max: 1000000, label: "5 to 10 Lakhs" },
  { amount: "3", min: 1000000, max: 3000000, label: "10 to 30 Lakhs" },
  { amount: "4", min: 3000000, max: 5000000, label: "30 to 50 Lakhs" },
  { amount: "5", min: 5000000, max: 10000000, label: "50 to 1 Crore" },
  { amount: "6", min: 10000000, max: 50000000, label: "1 to 5 Crore" },
  { amount: "7", min: 50000000, max: 100000000, label: "5 to 10 Crore" },
  { amount: "8", min: 100000000, max: Infinity, label: "10 Crore And Above" },
];

const ReviewInvestorKycForm = ({ formData, setFormData }) => {
  const [loading, setLoading] = useState(false);
  const [sdgList, setSdgList] = useState([]);
  const [sectorList, setSectorList] = useState([]);
  const [openEditKycPopup, setOpenEditKycPopup] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { allSdgsFilter } = useSelector((state) => state.getAllSdgsFilterList);

  const { allDealsCategoryFilter } = useSelector(
    (state) => state.getAllDealCategoryFilter
  );

  useEffect(() => {
    if (!allSdgsFilter) {
      dispatch(getAllSdgsFilterAction());
    }
    if (allSdgsFilter) {
      setSdgList(allSdgsFilter?.data);
    }
  }, [allSdgsFilter, dispatch]);

  useEffect(() => {
    if (!allDealsCategoryFilter) {
      dispatch(getAllDealCategoryFilterAction());
    }
    if (allDealsCategoryFilter) {
      setSectorList(allDealsCategoryFilter?.data);
    }
  }, [allDealsCategoryFilter, dispatch]);

  useEffect(() => {
    dispatch(getAllSdgsFilterAction());
    dispatch(getAllDealCategoryFilterAction());
  }, [dispatch]);

  const sdgNames = sdgList
    ?.filter((sdg) => formData?.sdgs?.includes(sdg.sdg_id))
    ?.map((sdg) => <Chip className="me-1 mb-2" label={sdg?.sdg_name} />);

  const sectorName = sectorList
    ?.filter((sector) => formData?.invest_in?.includes(sector.deal_category_id))
    ?.map((sector) => (
      <Chip className="me-1 mb-2" label={sector.deal_cat_name} />
    ));

  const amountName = amountRanges
    ?.filter((amount) => formData?.investment_size?.includes(amount.amount))
    ?.map((amount) => <Chip className="me-1 mb-2" label={amount.label} />);

  const handleEdit = (e) => {
    e.preventDefault();
    setOpenEditKycPopup(true);
  };

  const handleSubmit = () => {
    if (Object.keys(formData).length === 0 || formData === "") {
      window.alert("Please fill out all the required form!");
    } else {
      dispatch(postCreateInvestorKycAction(formData)).then((res) => {
        if (res?.status === 200) {
          toast.success(
            "Thank you for joining Million Deals, we will verify your information to fully activate your account; this process may take up to 2 business days."
          );

          setTimeout(() => {
            navigate("/dashboard");
          }, 1500);
        } else {
          // Handle error case where enterpriseKycId is null or undefined
          console.log("Error: enterpriseKycId is null or undefined");
        }
      });
    }
  };
  return (
    <>
      {loading ? (
        <LoadingComp />
      ) : (
        <>
          <div className="page-content-review">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <p>
                    If you are an investor looking for deals, please fill the
                    form below to show your interest in investing through our
                    platform. Our team at Million Deals will reach out to you to
                    take this forward.
                  </p>

                  <p>
                    Have any questions? Please contact us at:&nbsp;
                    <Link
                      to="info@milliondeals.com.np"
                      title="info@milliondeals.com.np"
                    >
                      info@milliondeals.com.np
                    </Link>
                  </p>
                  <>
                    <div id="printTable">
                      <TableContainer
                        component={Paper}
                        sx={{ maxHeight: "100%" }}
                      >
                        <Table
                          stickyHeader
                          aria-label="sticky table"
                          className="table-style "
                        >
                          <TableHead style={{ height: "50px" }}>
                            <TableRow
                              sx={{
                                "& :hover": {
                                  color: "white !important",
                                },
                                "& :focus:not(:focus-visible)": {
                                  color: "white !important",
                                },
                              }}
                            >
                              <TableCell
                                style={{
                                  backgroundColor: "#0b6885",
                                  padding: "0.5vw",
                                  letterSpacing: "0.5px",
                                  fontSize: "16px",
                                  fontWeight: "600",
                                  color: "white",
                                }}
                              >
                                TITLE
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  backgroundColor: "#0b6885",
                                  padding: "0.5vw",
                                  letterSpacing: "0.5px",
                                  fontSize: "16px",
                                  fontWeight: "600",
                                  color: "white",
                                }}
                              >
                                DESCRIPTION
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Full Name
                              </TableCell>
                              <TableCell align="left">
                                {formData.name}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Contact Number
                              </TableCell>
                              <TableCell align="left">
                                {formData.phone}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Email Address
                              </TableCell>
                              <TableCell align="left">
                                {formData.email}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Linkedin profile or other online profile
                              </TableCell>
                              <TableCell align="left">
                                {formData.linkin_or_online_profile}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Contact Address
                              </TableCell>
                              <TableCell align="left">
                                {formData.contact_address}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Brief information about their profile and
                                background
                              </TableCell>
                              <TableCell align="left">{formData.bio}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Individual Investor or Institutional Investor
                              </TableCell>
                              <TableCell align="left">
                                {formData.ind_or_ins_investor}
                              </TableCell>
                            </TableRow>
                            {formData.ind_or_ins_investor === "Other" && (
                              <>
                                <TableRow>
                                  <TableCell
                                    component="th"
                                    style={{ fontWeight: "600" }}
                                    scope="row"
                                  >
                                    Please describe if any others
                                  </TableCell>
                                  <TableCell align="left">
                                    {formData.ind_or_ins_investor_other}
                                  </TableCell>
                                </TableRow>
                              </>
                            )}
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Role in company
                              </TableCell>
                              <TableCell align="left">
                                {formData.company_role}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Company or Organization Associated with
                              </TableCell>
                              <TableCell align="left">
                                {formData.company_name}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Company website or LinkedIn Page link
                              </TableCell>
                              <TableCell align="left">
                                {formData.company_website}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Brief about company's profile and background
                              </TableCell>
                              <TableCell align="left">
                                {formData.company_bio}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Describes the best
                              </TableCell>
                              <TableCell align="left">
                                {formData.describes_your_best}
                              </TableCell>
                            </TableRow>
                            {formData.describes_your_best ===
                              "Other Financial Intermediary" && (
                              <TableRow>
                                <TableCell
                                  component="th"
                                  style={{ fontWeight: "600" }}
                                  scope="row"
                                >
                                  Which of the following describes you the best
                                  if others
                                </TableCell>
                                <TableCell align="left">
                                  {formData.describes_your_best_other}
                                </TableCell>
                              </TableRow>
                            )}
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Preferred Investment Size
                              </TableCell>
                              <TableCell align="left">{amountName}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Preferred Sector to Invest
                              </TableCell>
                              <TableCell align="left">{sectorName}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Wish to have a sustainability or impact focus in
                                investments
                              </TableCell>
                              <TableCell align="left">
                                {formData.is_sustainability === "1"
                                  ? "Yes"
                                  : "No"}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                SDGS contribution through investments
                              </TableCell>
                              <TableCell align="left">
                                {formData.is_sustainability === "1"
                                  ? sdgNames
                                  : "N/A"}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Preferred stage of investment
                              </TableCell>
                              <TableCell align="left">
                                {formData.stage_of_investment === "1"
                                  ? "Idea Stage"
                                  : ""}
                                {formData.stage_of_investment === "2"
                                  ? "Growth Stage"
                                  : ""}
                                {formData.stage_of_investment === "3"
                                  ? "Neutral"
                                  : ""}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Terms Confirmation
                              </TableCell>
                              <TableCell align="left">
                                {formData.terms_confirmation ? "Yes" : "No"}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        paddingTop: "5px",
                        marginTop: "5px",
                      }}
                    >
                      <Button
                        variant="contained"
                        onClick={handleEdit}
                        style={{
                          margin: "10px 0 0 10px",
                          backgroundColor: "rgb(42, 128, 249)",
                          color: "#ffffff",
                        }}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="contained"
                        style={{
                          margin: "10px 0 0 10px",
                          backgroundColor: "#0b6885",
                        }}
                        onClick={handleSubmit}
                      >
                        Submit
                      </Button>
                      <ToastContainer
                        position="top-right"
                        autoClose={2000}
                        theme="colored"
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                      />
                    </div>
                  </>
                </div>
              </div>
            </div>
          </div>
          <Popup
            openPopup={openEditKycPopup}
            setOpenPopup={setOpenEditKycPopup}
            title="Edit KYC Form (*Click X button to save your changes.)"
          >
            <UpdateInvestorKycForm
              formData={formData}
              setFormData={setFormData}
              sectorList={sectorList}
              sdgList={sdgList}
              setOpenEditKycPopup={setOpenEditKycPopup}
            />
          </Popup>
        </>
      )}
    </>
  );
};

export default ReviewInvestorKycForm;

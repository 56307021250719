import React, { useState } from "react";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ArrowButton from "../components/ArrowButton";
import { toast, ToastContainer } from "react-toastify";

const MAX_COUNT = 5;

const DocumentsAndUploads = ({
  formData,
  setFormData,
  formDataDoc,
  setFormDataDoc,
  registerDoc,
  setRegisterDoc,
  formErrors,
}) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileLimit, setFileLimit] = useState(false);
  const [uploadBusinessDoc, setUploadBusinessDoc] = useState([]);

  const handleUploadFiles = (files) => {
    const uploaded = [...uploadedFiles];
    let limitExceeded = false;
    files.forEach((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        if (uploaded.length === MAX_COUNT) setFileLimit(true);
        if (uploaded.length > MAX_COUNT) {
          toast.error(`You can only add a maximum of ${MAX_COUNT} files`);
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
      }
    });
    if (!limitExceeded) {
      setUploadedFiles(uploaded);
      setRegisterDoc({
        ...registerDoc,
        registration_of_company_docs: uploaded,
      });
    }
  };

  const handleUploadBusinessDoc = (files) => {
    const uploaded = [...uploadBusinessDoc];
    let limitExceeded = false;
    files.forEach((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        if (uploaded.length === MAX_COUNT) setFileLimit(true);
        if (uploaded.length > MAX_COUNT) {
          toast.error(`You can only add a maximum of ${MAX_COUNT} files`);
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
      }
    });
    if (!limitExceeded) {
      setUploadBusinessDoc(uploaded);
      setFormDataDoc({
        ...formDataDoc,
        business_plan_docs: uploaded,
      });
    }
  };

  const handleFileEvent = (e) => {
    const chosenFiles = Array.from(e.target.files);
    handleUploadFiles(chosenFiles);
  };

  const handleBusinessFileEvent = (e) => {
    const chosenFiles = Array.from(e.target.files);
    handleUploadBusinessDoc(chosenFiles);
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    setFormDataDoc({
      ...formDataDoc,
      [e.target.name]: e.target.files,
      business_plan_docs: uploadBusinessDoc,
    });
    setRegisterDoc({
      ...registerDoc,
      [e.target.name]: e.target.files,
      registration_of_company_docs: uploadedFiles,
    });
  };

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="description" content="" />
        <meta name="author" content="" />

        <title>Million Deals</title>
        {/* <!-- Linking the font Poppins--> */}
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />

        {/* <!-- Linking Bootstrap Icon--> */}
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css"
        />
      </Helmet>
      <ArrowButton />

      <div className="page-title">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h1>Enterprise Form</h1>
              <Link className="link" to="/">
                Home
              </Link>{" "}
              /{" "}
              <Link className="link" to="/dashboard">
                Dashboard
              </Link>{" "}
              / Enterprise Form
            </div>
          </div>
        </div>
      </div>

      <div className="page-content">
        <div className="container">
          <div className="row">
            <div className="col-9">
              <div className="invest-form">
                <form encType="multipart/form">
                  <h2>Documents & Uploads</h2>
                  Please upload your company's charter documents ( MOA, AOA),
                  audit report for at least 3 years, tax clearance and
                  shareholder register (Maximum 5 Files)
                  <input
                    name="registration_of_company_docs"
                    className="form-control"
                    type="file"
                    multiple
                    onChange={handleFileEvent}
                    disabled={fileLimit}
                  />
                  {uploadedFiles.length > 0 &&
                    uploadedFiles.map((file, index) => (
                      <img
                        key={index}
                        src={URL.createObjectURL(file)}
                        alt={`Uploaded File ${index + 1}`}
                        style={{
                          borderRadius: "0px",
                          height: "150px",
                          width: "150px",
                          margin: "5px",
                        }}
                      />
                    ))}
                  {uploadedFiles.length > 0 && <br />}
                  Please upload business plan, pitch deck including financial
                  projections, or any other material you have developed for
                  pitching your investment (Maximum 5 Files)
                  <input
                    name="business_plan_docs"
                    className="form-control"
                    type="file"
                    multiple
                    onChange={handleBusinessFileEvent}
                    disabled={fileLimit}
                  />
                  {uploadBusinessDoc.length > 0 &&
                    uploadBusinessDoc.map((file, index) => (
                      <img
                        key={index}
                        src={URL.createObjectURL(file)}
                        alt={`Uploaded File ${index + 1}`}
                        style={{
                          borderRadius: "0px",
                          height: "150px",
                          width: "150px",
                          margin: "5px",
                        }}
                      />
                    ))}
                  {uploadBusinessDoc.length > 0 && <br />}
                  <b>Agree To Terms</b>
                  <br />I hereby declare that all the information provided and
                  furnished above is true, complete and correct to best of my
                  knowledge. I understand that in the event of my information
                  being found false or incorrect at any stage, I shall be liable
                  and Million Deals will have the authority cancel/ terminate
                  without notice
                  <br />
                  {formErrors.terms_confirmation && (
                    <span className="error">
                      {formErrors.terms_confirmation}
                    </span>
                  )}
                  <div className="form-check">
                    <RadioGroup
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 20,
                        },
                      }}
                      name="terms_confirmation"
                      value={formData?.terms_confirmation}
                      onChange={handleInputChange}
                    >
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>

                    <ToastContainer
                      position="top-right"
                      autoClose={2000}
                      theme="colored"
                      hideProgressBar={false}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DocumentsAndUploads;

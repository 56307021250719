import {
  DELETE_PAGES_FAIL,
  DELETE_PAGES_REQUEST,
  DELETE_PAGES_RESET,
  DELETE_PAGES_SUCCESS,
  GET_ALL_PAGES_FAIL,
  GET_ALL_PAGES_REQUEST,
  GET_ALL_PAGES_RESET,
  GET_ALL_PAGES_SUCCESS,
  GET_SINGLE_PAGES_FAIL,
  GET_SINGLE_PAGES_REQUEST,
  GET_SINGLE_PAGES_RESET,
  GET_SINGLE_PAGES_SUCCESS,
  POST_PAGES_FAIL,
  POST_PAGES_REQUEST,
  POST_PAGES_RESET,
  POST_PAGES_SUCCESS,
  PUT_PAGES_FAIL,
  PUT_PAGES_REQUEST,
  PUT_PAGES_RESET,
  PUT_PAGES_SUCCESS,
} from "./ManagePagesConstants";

export const getAllPagesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_PAGES_REQUEST:
      return { loading: true };
    case GET_ALL_PAGES_SUCCESS:
      return { loading: false, allPages: action.payload };
    case GET_ALL_PAGES_FAIL:
      return { loading: false, error: action.payload };
    case GET_ALL_PAGES_RESET:
      return {};
    default:
      return state;
  }
};

export const getSinglePagesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_SINGLE_PAGES_REQUEST:
      return { loading: true };
    case GET_SINGLE_PAGES_SUCCESS:
      return { loading: false, singlePage: action.payload };
    case GET_SINGLE_PAGES_FAIL:
      return { loading: false, error: action.payload };
    case GET_SINGLE_PAGES_RESET:
      return {};
    default:
      return state;
  }
};

export const postPagesReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_PAGES_REQUEST:
      return { loading: true };
    case POST_PAGES_SUCCESS:
      return { loading: false, success: true };
    case POST_PAGES_FAIL:
      return { loading: false, error: action.payload };
    case POST_PAGES_RESET:
      return {};
    default:
      return state;
  }
};

export const putPagesReducer = (state = {}, action) => {
  switch (action.type) {
    case PUT_PAGES_REQUEST:
      return { loading: true };
    case PUT_PAGES_SUCCESS:
      return { loading: false, success: true };
    case PUT_PAGES_FAIL:
      return { loading: false, error: action.payload };
    case PUT_PAGES_RESET:
      return {};
    default:
      return state;
  }
};

export const deletePagesReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_PAGES_REQUEST:
      return { loading: true };
    case DELETE_PAGES_SUCCESS:
      return { loading: false, success: true };
    case DELETE_PAGES_FAIL:
      return { loading: false, error: action.payload };
    case DELETE_PAGES_RESET:
      return {};
    default:
      return state;
  }
};

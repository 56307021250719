import {
  API_URL,
  axiosAdminPrivate,
  tokenConfig,
  tokenConfigForm,
} from "../../api/axios";

import {
  DELETE_USERS_FAIL,
  DELETE_USERS_REQUEST,
  DELETE_USERS_SUCCESS,
  GET_ALL_USERS_FAIL,
  GET_ALL_USERS_REQUEST,
  GET_ALL_USERS_SUCCESS,
  GET_SINGLE_USERS_FAIL,
  GET_SINGLE_USERS_REQUEST,
  GET_SINGLE_USERS_SUCCESS,
  POST_USERS_FAIL,
  POST_USERS_REQUEST,
  POST_USERS_SUCCESS,
  PUT_USERS_FAIL,
  PUT_USERS_REQUEST,
  PUT_USERS_SUCCESS,
} from "./ManageUsersConstants";

export const getAllUserAction = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_USERS_REQUEST });

    const { data } = await axiosAdminPrivate.get(
      `${API_URL}/api/v1/admin/users`
    );

    dispatch({ type: GET_ALL_USERS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_ALL_USERS_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

export const getSingleUserAction = (unique_id) => async (dispatch) => {
  try {
    dispatch({ type: GET_SINGLE_USERS_REQUEST });

    const { data } = await axiosAdminPrivate.get(
      `${API_URL}/api/v1/admin/users/${unique_id}`
    );

    dispatch({ type: GET_SINGLE_USERS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_SINGLE_USERS_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

export const postUserAction = (addUser, profile_image) => async (dispatch) => {
  try {
    dispatch({ type: POST_USERS_REQUEST });

    const jsonData = JSON.stringify(addUser);

    const { data } = await axiosAdminPrivate.post(
      `${API_URL}/api/v1/admin/users`,
      jsonData,
      tokenConfig()
    );

    const formData = new FormData();
    formData.append("profile_image", profile_image);

    await axiosAdminPrivate.put(
      `${API_URL}/api/v1/admin/users/upload_profile_image/${data?.data?.unique_id}`,
      formData,
      tokenConfigForm()
    );

    dispatch({ type: POST_USERS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: POST_USERS_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

export const putUserAction =
  (updateUser, id, profile_image) => async (dispatch) => {
    try {
      dispatch({ type: PUT_USERS_REQUEST });

      const jsonData = JSON.stringify(updateUser);

      const { data } = await axiosAdminPrivate.put(
        `${API_URL}/api/v1/admin/users/${id}`,
        jsonData,
        tokenConfig()
      );
      if (profile_image !== null) {
        const formData = new FormData();
        formData.append("profile_image", profile_image);

        await axiosAdminPrivate.put(
          `${API_URL}/api/v1/admin/users/upload_profile_image/${updateUser?.unique_id}`,
          formData,
          tokenConfigForm()
        );
      }

      dispatch({ type: PUT_USERS_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: PUT_USERS_FAIL,
        payload: error.message ? error.message : error.Message,
      });
    }
  };

export const deleteUserAction = (unique_id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_USERS_REQUEST });

    const { data } = await axiosAdminPrivate.delete(
      `${API_URL}/api/v1/admin/users/${unique_id}`,
      tokenConfig()
    );

    dispatch({ type: DELETE_USERS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: DELETE_USERS_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
} from "@mui/material";
import { Logout, Settings } from "@mui/icons-material";
import ConfirmDialog from "./ConfirmDialog";
import { getAccessToken } from "../services/storage";
import jwtDecode from "jwt-decode";
import {
  API_URL,
  axiosAdminPrivate,
  axiosPrivate,
  tokenConfig,
} from "../api/axios";
import moment from "moment";

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
  });
  const [currentDate, setCurrentDate] = useState(
    moment().format("MMMM Do YYYY")
  );
  const [currentTime, setCurrentTime] = useState(moment().format("h:mm A"));

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDate(moment().format("MMMM Do YYYY"));
      setCurrentTime(moment().format("h:mm A"));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const clockStyle = {
    fontSize: "15px",
    fontWeight: "bold",
    color: "black",
    textAlign: "center",
    marginTop: "16px",
  };

  const accessToken = getAccessToken();

  //Decode the access token
  const decodedToken = jwtDecode(accessToken);

  // Extract the payload from the decoded token
  const payload = decodedToken;

  const open = Boolean(anchorEl);

  const navigate = useNavigate();

  const handleChangePassword = (e) => {
    e.preventDefault();
    navigate("/admin/change_password");
  };

  const handleToken = () => {
    return {
      token: `${localStorage.getItem("refreshToken")}`,
    };
  };

  const handleLogout = async (e) => {
    e.preventDefault();
    const { data } = await axiosAdminPrivate.post(
      `${API_URL}/api/v1/admin/auth/logout`,
      handleToken(),
      tokenConfig()
    );

    if (data?.status === 200) {
      // Remove the session from localStorage
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("kycValues");
      localStorage.removeItem("memberProfiles");
      localStorage.removeItem("dealsDetails");
      localStorage.removeItem("membersList");
      localStorage.removeItem("kycValues");
      localStorage.removeItem("buttonValues");
      localStorage.removeItem("revenueAdmin");
      localStorage.removeItem("mobilizationAdmin");
      localStorage.removeItem("regFileAdmin");
      localStorage.removeItem("panOrVatAdmin");
      localStorage.removeItem("businessDocAdmin");
      localStorage.removeItem("adminMemberType");
      // Redirect to the login page
      navigate("/admin/login");
    } else {
      navigate("/admin");
    }
  };

  const handleProfile = (e) => {
    navigate("/admin");
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      p={0.5}
      alignItems="center"
      margin={"0 10px"}
    >
      <p style={clockStyle}>
        {currentDate}, {currentTime}
      </p>

      {/* ICONS */}
      <Box display="flex">
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          {payload?.profile_image ? (
            <img
              alt=""
              width="35px"
              height="35px"
              src={`${API_URL}/uploads/admin/profile_images/${payload?.profile_image}`}
              style={{ cursor: "pointer", borderRadius: "50%" }}
            />
          ) : (
            <img
              alt=""
              width="35px"
              height="35px"
              src="https://i.postimg.cc/vZvJdnRS/blank-head-profile-pic-for-a-man.jpg"
              style={{ cursor: "pointer", borderRadius: "50%" }}
            />
          )}
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleProfile}>
          {payload?.profile_image ? (
            <img
              alt=""
              width="30px"
              height="30px"
              src={`${API_URL}/uploads/admin/profile_images/${payload?.profile_image}`}
              style={{ cursor: "pointer", borderRadius: "50%" }}
            />
          ) : (
            <img
              alt=""
              width="30px"
              height="30px"
              src="https://i.postimg.cc/vZvJdnRS/blank-head-profile-pic-for-a-man.jpg"
              style={{ cursor: "pointer", borderRadius: "50%" }}
            />
          )}
          &nbsp; &nbsp;{" "}
          <b style={{ color: "#444141" }}>
            {payload?.first_name} {payload?.last_name}
          </b>
        </MenuItem>
        {/* <MenuItem onClick={handleResetPassword}>
          <LockResetIcon fontSize="medium" style={{ color: "#707070" }} />
          &nbsp;&nbsp;&nbsp;&nbsp; Reset Password
        </MenuItem> */}
        <Divider />

        <MenuItem onClick={handleChangePassword}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Change Password
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Box>
  );
};

export default Header;

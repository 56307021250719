export const GET_FILTER_DEALS_REQUEST = "GET_FILTER_DEALS_REQUEST";
export const GET_FILTER_DEALS_SUCCESS = "GET_FILTER_DEALS_SUCCESS";
export const GET_FILTER_DEALS_FAIL = "GET_FILTER_DEALS_FAIL";
export const GET_FILTER_DEALS_RESET = "GET_FILTER_DEALS_RESET";

export const GET_DETAILED_DEALS_REQUEST = "GET_DETAILED_DEALS_REQUEST";
export const GET_DETAILED_DEALS_SUCCESS = "GET_DETAILED_DEALS_SUCCESS";
export const GET_DETAILED_DEALS_FAIL = "GET_DETAILED_DEALS_FAIL";
export const GET_DETAILED_DEALS_RESET = "GET_DETAILED_DEALS_RESET";

export const POST_NEW_DEALS_REQUEST = "POST_NEW_DEALS_REQUEST";
export const POST_NEW_DEALS_SUCCESS = "POST_NEW_DEALS_SUCCESS";
export const POST_NEW_DEALS_FAIL = "POST_NEW_DEALS_FAIL";
export const POST_NEW_DEALS_RESET = "POST_NEW_DEALS_RESET";

export const PUT_NEW_DEALS_REQUEST = "PUT_NEW_DEALS_REQUEST";
export const PUT_NEW_DEALS_SUCCESS = "PUT_NEW_DEALS_SUCCESS";
export const PUT_NEW_DEALS_FAIL = "PUT_NEW_DEALS_FAIL";
export const PUT_NEW_DEALS_RESET = "PUT_NEW_DEALS_RESET";

export const DELETE_NEW_DEALS_REQUEST = "DELETE_NEW_DEALS_REQUEST";
export const DELETE_NEW_DEALS_SUCCESS = "DELETE_NEW_DEALS_SUCCESS";
export const DELETE_NEW_DEALS_FAIL = "DELETE_NEW_DEALS_FAIL";
export const DELETE_NEW_DEALS_RESET = "DELETE_NEW_DEALS_RESET";

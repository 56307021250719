import {
  DELETE_DEAL_CATEGORY_FAIL,
  DELETE_DEAL_CATEGORY_REQUEST,
  DELETE_DEAL_CATEGORY_RESET,
  DELETE_DEAL_CATEGORY_SUCCESS,
  GET_ALL_DEAL_CATEGORY_FAIL,
  GET_ALL_DEAL_CATEGORY_REQUEST,
  GET_ALL_DEAL_CATEGORY_RESET,
  GET_ALL_DEAL_CATEGORY_SUCCESS,
  GET_SINGLE_DEAL_CATEGORY_FAIL,
  GET_SINGLE_DEAL_CATEGORY_REQUEST,
  GET_SINGLE_DEAL_CATEGORY_RESET,
  GET_SINGLE_DEAL_CATEGORY_SUCCESS,
  POST_DEAL_CATEGORY_FAIL,
  POST_DEAL_CATEGORY_REQUEST,
  POST_DEAL_CATEGORY_RESET,
  POST_DEAL_CATEGORY_SUCCESS,
  PUT_DEAL_CATEGORY_FAIL,
  PUT_DEAL_CATEGORY_REQUEST,
  PUT_DEAL_CATEGORY_RESET,
  PUT_DEAL_CATEGORY_SUCCESS,
} from "./ManageDealsCategoryConstants";

export const getAllDealCategoryReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_DEAL_CATEGORY_REQUEST:
      return { loading: true };
    case GET_ALL_DEAL_CATEGORY_SUCCESS:
      return { loading: false, allDealsCategory: action.payload };
    case GET_ALL_DEAL_CATEGORY_FAIL:
      return { loading: false, error: action.payload };
    case GET_ALL_DEAL_CATEGORY_RESET:
      return {};
    default:
      return state;
  }
};

export const getSingleDealCategoryReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_SINGLE_DEAL_CATEGORY_REQUEST:
      return { loading: true };
    case GET_SINGLE_DEAL_CATEGORY_SUCCESS:
      return { loading: false, singleDealCategory: action.payload };
    case GET_SINGLE_DEAL_CATEGORY_FAIL:
      return { loading: false, error: action.payload };
    case GET_SINGLE_DEAL_CATEGORY_RESET:
      return {};
    default:
      return state;
  }
};

export const postDealCategoryReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_DEAL_CATEGORY_REQUEST:
      return { loading: true };
    case POST_DEAL_CATEGORY_SUCCESS:
      return { loading: false, success: action.payload };
    case POST_DEAL_CATEGORY_FAIL:
      return { loading: false, error: action.payload };
    case POST_DEAL_CATEGORY_RESET:
      return {};
    default:
      return state;
  }
};

export const putDealCategoryReducer = (state = {}, action) => {
  switch (action.type) {
    case PUT_DEAL_CATEGORY_REQUEST:
      return { loading: true };
    case PUT_DEAL_CATEGORY_SUCCESS:
      return { loading: false, success: action.payload };
    case PUT_DEAL_CATEGORY_FAIL:
      return { loading: false, error: action.payload };
    case PUT_DEAL_CATEGORY_RESET:
      return {};
    default:
      return state;
  }
};

export const deleteDealCategoryReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_DEAL_CATEGORY_REQUEST:
      return { loading: true };
    case DELETE_DEAL_CATEGORY_SUCCESS:
      return { loading: false, success: action.payload };
    case DELETE_DEAL_CATEGORY_FAIL:
      return { loading: false, error: action.payload };
    case DELETE_DEAL_CATEGORY_RESET:
      return {};
    default:
      return state;
  }
};

import React, { Suspense, lazy, useEffect } from "react";
import { AppBar, Tabs, Tab, Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useDispatch } from "react-redux";

const FAQsEnterprise = lazy(() => import("./FAQs"));
const FAQsInvestor = lazy(() => import("./FAQsInvestor"));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function allyProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

const FAQsMain = () => {
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    dispatch({ type: "GET_LINK", payload: "/FAQs" });
  }, [dispatch]);

  return (
    <div style={{ marginTop: "5px", padding: "8px" }}>
      <AppBar
        position="static"
        style={{ backgroundColor: "#0b6885", padding: "0px" }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example"
          style={{ borderRadius: 0 }}
          TabIndicatorProps={{ style: { height: "50px", opacity: 0.2 } }}
        >
          <Tab
            style={{ fontSize: "13px", color: "#ffffff", fontWeight: "600" }}
            label="FAQs Enterprise"
            {...allyProps(0)}
          />

          <Tab
            style={{ fontSize: "13px", color: "#ffffff", fontWeight: "600" }}
            label="FAQs Investor"
            {...allyProps(1)}
          />
        </Tabs>
      </AppBar>
      <Suspense fallback={<div></div>}>
        <TabPanel value={value} index={0}>
          <FAQsEnterprise />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <FAQsInvestor />
        </TabPanel>
      </Suspense>
    </div>
  );
};

export default FAQsMain;

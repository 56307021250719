import React, { useState, useEffect } from "react";
import { API_URL } from "../../api/axios";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import LockResetIcon from "@mui/icons-material/LockReset";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getDetailedPersonalInfoAction } from "../private-routes/PrivateActions";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { getMemberProfileSideAction } from "../kyc-state-handler/KycActions";

const DashboardSideMenu = () => {
  const [values, setValues] = useState([]);
  const [profileType, setProfileType] = useState([]);
  const [prevMemberProfileSide, setPrevMemberProfileSide] = useState(null);

  const dispatch = useDispatch();

  const { detailedPersonalInfo } = useSelector(
    (state) => state.getDetailedPersonalInfo
  );

  const { memberProfileSide } = useSelector(
    (state) => state.getMemberProfileside
  );

  useEffect(() => {
    if (!detailedPersonalInfo) {
      dispatch(getDetailedPersonalInfoAction());
    }
    if (detailedPersonalInfo) {
      setValues(detailedPersonalInfo?.data[0]);
    }
  }, [detailedPersonalInfo, dispatch]);

  useEffect(() => {
    dispatch(getDetailedPersonalInfoAction());
  }, [dispatch]);

  useEffect(() => {
    if (memberProfileSide && memberProfileSide !== prevMemberProfileSide) {
      setProfileType(memberProfileSide?.data);
    }
  }, [memberProfileSide, prevMemberProfileSide]);

  useEffect(() => {
    setPrevMemberProfileSide(memberProfileSide);
  }, [memberProfileSide]);

  useEffect(() => {
    dispatch(getMemberProfileSideAction());
  }, [dispatch]);

  const dataEnterpriseArray = profileType?.find(
    (value) => value.profile_type === "Enterprise"
  );
  const dataInvestorArray = profileType?.find(
    (value) => value.profile_type === "Investor"
  );

  return (
    <>
      <div className="dash-menus">
        <div className="dash-profile">
          {values?.profile_image ? (
            <img
              alt=""
              width="50px"
              height="50px"
              src={`${API_URL}/uploads/members/profile_images/${values?.profile_image}`}
              style={{ cursor: "pointer", borderRadius: "50%" }}
            />
          ) : (
            <img
              alt=""
              width="50px"
              height="50px"
              src="https://i.postimg.cc/vZvJdnRS/blank-head-profile-pic-for-a-man.jpg"
              style={{ cursor: "pointer", borderRadius: "50%" }}
            />
          )}
          <h3>
            {values?.username ? (
              values?.username
            ) : (
              <>
                {values?.email?.split(
                  "@gmail.com" ||
                    "@yahoo.com" ||
                    "@hotmail.com" ||
                    "@outlook.com"
                )}
              </>
            )}
          </h3>
        </div>
        <ul>
          <li>
            <Link to="/dashboard" style={{ textDecoration: "none" }}>
              <DashboardIcon style={{ fontSize: "20px" }} /> Dashboard
            </Link>
          </li>
          <li>
            <Link to="/dashboard/profile" style={{ textDecoration: "none" }}>
              <AccountCircleIcon style={{ fontSize: "20px" }} /> Profile
            </Link>
          </li>
          {dataEnterpriseArray && (
            <li>
              <Link
                to="/dashboard/memberdeals"
                style={{ textDecoration: "none" }}
              >
                <LocalOfferIcon style={{ fontSize: "20px" }} /> Deals
              </Link>
            </li>
          )}

          {dataInvestorArray && (
            <li>
              <Link
                to="/dashboard/interested-deals"
                style={{ textDecoration: "none" }}
              >
                <FavoriteIcon style={{ fontSize: "20px" }} /> Interested Deals
              </Link>
            </li>
          )}

          <li>
            <Link
              to="/dashboard/change-password"
              style={{ textDecoration: "none" }}
            >
              <LockResetIcon style={{ fontSize: "20px" }} /> Change Password
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default DashboardSideMenu;

import { combineReducers } from "redux";
import {
  getAllAboutMillionDealsReducer,
  putAllAboutMillionDealsReducer,
} from "./admin-portal/about-us/about-million-deals/AboutMillionDealsReducers";
import {
  deleteAdvisorReducer,
  getAllAdvisorReducer,
  getSingleAdvisorReducer,
  postAdvisorReducer,
  putAdvisorReducer,
} from "./admin-portal/about-us/advisors/AdvisorsReducers";
import {
  deletePartnerReducer,
  getAllPartnerReducer,
  getSinglePartnerReducer,
  postPartnerReducer,
  putPartnerReducer,
} from "./admin-portal/about-us/partners/PartnersRedcuers";
import {
  deleteTeamReducer,
  getAllTeamReducer,
  getSingleTeamReducer,
  postTeamReducer,
  putTeamReducer,
} from "./admin-portal/about-us/teams/TeamReducers";
import {
  getWorkWithUsReducer,
  putWorkWithUsReducer,
} from "./admin-portal/about-us/work-with-us/WorkWithUsReducers";
import {
  deleteBlogsReducer,
  getAllBlogsReducer,
  getSingleBlogsReducer,
  postBlogsReducer,
  putBlogsReducer,
} from "./admin-portal/blogs/BlogsReducers";
import {
  deleteFaqsReducer,
  getAllEnterpriseFaqsReducer,
  getAllInvestorFaqsReducer,
  getSingleFaqsReducer,
  postFaqsReducer,
  putFaqsReducer,
} from "./admin-portal/faqs/FAQsReducers";
import {
  getAllContactsReducer,
  getSingleContactReducer,
} from "./admin-portal/manage-contacts/ManageContactsReducers";
import {
  deleteDealCategoryReducer,
  getAllDealCategoryReducer,
  getSingleDealCategoryReducer,
  postDealCategoryReducer,
  putDealCategoryReducer,
} from "./admin-portal/manage-deals/manage-deals-category/ManageDealsCategoryReducers";
import {
  deleteDealsReducer,
  getDetailDealsReducer,
  getFilterDealsReducer,
  postNewDealsReducer,
  putDealsReducer,
} from "./admin-portal/manage-deals/ManageDealsReducers";
import {
  deletePagesReducer,
  getAllPagesReducer,
  getSinglePagesReducer,
  postPagesReducer,
  putPagesReducer,
} from "./admin-portal/manage-pages/ManagePagesReducers";
import {
  deleteUsersReducer,
  getAllUserReducer,
  getSingleUserReducer,
  postUsersReducer,
  putUsersReducer,
} from "./admin-portal/manage-users/ManageUsersReducers";
import {
  getAllMembersReducer,
  getCategoryListAdminReducer,
  getEnterpriseBusinessPlanDocAdminReducer,
  getEnterpriseMobilizationPlanDocAdminReducer,
  getEnterprisePanVatDocAdminReducer,
  getEnterpriseRegistrationCompDocAdminReducer,
  getEnterpriseRevenueProfitabilityDocAdminReducer,
  getIndividualMembersListReducer,
  getMemberProfilesReducer,
  getSdgListAdminReducer,
  getSingleMemberProfileListReducer,
  postEnterpriseBusinessDocsAdminReducer,
  postEnterpriseMobilizationDocsAdminReducer,
  postEnterprisePanOrVatAdminReducer,
  postEnterpriseProfitablityDocsAdminReducer,
  postEnterpriseRegistrationDocsAdminReducer,
  putEnterpriseBusinessDocsAdminReducer,
  putEnterpriseKycFormAdminReducer,
  putEnterpriseMobilizationDocsAdminReducer,
  putEnterprisePanOrVatAdminReducer,
  putEnterpriseProfitablityDocsAdminReducer,
  putEnterpriseRegistrationDocsAdminReducer,
  putInvestorKycFormAdminReducer,
  putVerifiedMembersListReducer,
} from "./admin-portal/members-list/all-members/AllMembersReducer";
import { getAllVerifiedMembersReducer } from "./admin-portal/members-list/verified-members/VerifiedMembersReducer";
import {
  deleteNewsReducer,
  getAllNewsReducer,
  getSingleNewsReducer,
  postNewsReducer,
  putNewsReducer,
} from "./admin-portal/news/NewsReducers";
import {
  deleteQuestionarieReducer,
  getAllQuestionarieReducer,
  getSingleQuestionarieReducer,
  postQuestionarieReducer,
  putQuestionarieReducer,
} from "./admin-portal/questionarie/QuestionarieReducers";
import {
  deleteSuccessStoriesReducer,
  getAllSuccessStoriesReducer,
  getSingleSuccessStoriesReducer,
  postSuccessStoriesReducer,
  putSuccessStoriesReducer,
} from "./admin-portal/success-stories/SuccessStoriesReducers";
import {
  getEnterpriseBusinessPlanDocReducer,
  getEnterpriseMobilizationPlanDocReducer,
  getEnterprisePanVatDocReducer,
  getEnterpriseRegistrationCompDocReducer,
  getEnterpriseRevenueProfitabilityDocReducer,
  getMemberProfilesideReducer,
  getSingleMemberProfilesideReducer,
  postCreateEnterpriseKycReducer,
  postCreateInvestorKycReducer,
  postEnterpriseBusinessPlanReducer,
  postEnterpriseMobilizationPlanDocsReducer,
  postEnterprisePanOrVatDocReducer,
  postEnterpriseRegistrationCompReducer,
  postEnterpriseRevenueProfitabilityDocsReducer,
} from "./frontend/kyc-state-handler/KycReducers";
import {
  getAllDealCategoryFilterReducer,
  getAllSdgsFilterListReducer,
  getDetailDealsPrivateReducer,
  getDetailedPersonalInfoReducer,
  getProfileAsDealsByIdReducer,
  getShowInterestedDealsReducer,
  postChangePasswordReducer,
  postProfileDataReducer,
  postShowInterestReducer,
} from "./frontend/private-routes/PrivateReducers";
import {
  getAboutCompanyPublicReducer,
  getAdvisorsPublicReducer,
  getAllBlogsPublicReducer,
  getAllContactDetailsPublicReducer,
  getAllDealCategoryPublicReducer,
  getAllDealsPublicReducer,
  getAllEnterpriseFaqsPublicReducer,
  getAllInvestorFaqsPublicReducer,
  getAllNewsPublicReducer,
  getAllSuccessStoriesPublicReducer,
  getBlogsSlugPublicReducer,
  getNewsSlugPublicReducer,
  getPartnersPublicReducer,
  getSingleAdvisorCompanyPublicReducer,
  getSingleDealsPublicReducer,
  getSinglePartnerPublicReducer,
  getSingleTeamPublicReducer,
  getTeamsPublicReducer,
  getWorkWithUsPublicReducer,
  postContactFormPublicReducer,
  postVerifyMemberReducer,
} from "./frontend/public-routes/PublicRoutesReducers";

export const reducers = combineReducers({
  //MANAGE USERS REDUCERS
  getAllUser: getAllUserReducer,
  getSingleUser: getSingleUserReducer,
  postUsers: postUsersReducer,
  putUsers: putUsersReducer,
  deleteUsers: deleteUsersReducer,

  //ALL MEMBERS REDUCERS
  getAllMembers: getAllMembersReducer,
  getIndividualMembersList: getIndividualMembersListReducer,
  putVerifiedMembersList: putVerifiedMembersListReducer,
  getSingleMemberProfileList: getSingleMemberProfileListReducer,
  getMemberProfiles: getMemberProfilesReducer,
  getEnterpriseBusinessPlanDocAdmin: getEnterpriseBusinessPlanDocAdminReducer,
  getEnterpriseRegistrationCompDocAdmin:
    getEnterpriseRegistrationCompDocAdminReducer,
  getEnterprisePanVatDocAdmin: getEnterprisePanVatDocAdminReducer,
  getEnterpriseMobilizationPlanDocAdmin:
    getEnterpriseMobilizationPlanDocAdminReducer,
  getEnterpriseRevenueProfitabilityDocAdmin:
    getEnterpriseRevenueProfitabilityDocAdminReducer,
  getCategoryListAdmin: getCategoryListAdminReducer,
  getSdgListAdmin: getSdgListAdminReducer,

  //VERIFIED MEMBERS REDUCERS
  getAllVerifiedMembers: getAllVerifiedMembersReducer,

  //ABOUT MILLION DEALS REDUCERS
  getAllAboutMillionDeals: getAllAboutMillionDealsReducer,
  putAllAboutMillionDeals: putAllAboutMillionDealsReducer,

  //WORK WITH US REDUCERS
  getWorkWithUs: getWorkWithUsReducer,
  putWorkWithUs: putWorkWithUsReducer,

  //OUR TEAMS REDUCERS
  getAllTeam: getAllTeamReducer,
  getSingleTeam: getSingleTeamReducer,
  postTeam: postTeamReducer,
  putTeam: putTeamReducer,
  deleteTeam: deleteTeamReducer,

  //OUR ADVISORS REDUCERS
  getAllAdvisor: getAllAdvisorReducer,
  getSingleAdvisor: getSingleAdvisorReducer,
  postAdvisor: postAdvisorReducer,
  putAdvisor: putAdvisorReducer,
  deleteAdvisor: deleteAdvisorReducer,

  //OUR PARTNERS REDUCERS
  getAllPartner: getAllPartnerReducer,
  getSinglePartner: getSinglePartnerReducer,
  postPartner: postPartnerReducer,
  putPartner: putPartnerReducer,
  deletePartner: deletePartnerReducer,

  //FAQS REDUCERS
  getAllEnterpriseFaqs: getAllEnterpriseFaqsReducer,
  getAllInvestorFaqs: getAllInvestorFaqsReducer,
  getSingleFaqs: getSingleFaqsReducer,
  postFaqs: postFaqsReducer,
  putFaqs: putFaqsReducer,
  deleteFaqs: deleteFaqsReducer,

  //SUCCESS_STORIES REDUCERS
  getAllSuccessStories: getAllSuccessStoriesReducer,
  getSingleSuccessStories: getSingleSuccessStoriesReducer,
  postSuccessStories: postSuccessStoriesReducer,
  putSuccessStories: putSuccessStoriesReducer,
  deleteSuccessStories: deleteSuccessStoriesReducer,

  //BLOGS REDUCERS
  getAllBlogs: getAllBlogsReducer,
  getSingleBlogs: getSingleBlogsReducer,
  postBlogs: postBlogsReducer,
  putBlogs: putBlogsReducer,
  deleteBlogs: deleteBlogsReducer,

  //NEWS REDUCERS
  getAllNews: getAllNewsReducer,
  getSingleNews: getSingleNewsReducer,
  postNews: postNewsReducer,
  putNews: putNewsReducer,
  deleteNews: deleteNewsReducer,

  //QUESTIONARIE REDUCERS
  getAllQuestionarie: getAllQuestionarieReducer,
  getSingleQuestionarie: getSingleQuestionarieReducer,
  postQuestionarie: postQuestionarieReducer,
  putQuestionarie: putQuestionarieReducer,
  deleteQuestionarie: deleteQuestionarieReducer,

  //MANAGE-PAGES REDUCERS
  getAllPages: getAllPagesReducer,
  getSinglePages: getSinglePagesReducer,
  postPages: postPagesReducer,
  putPages: putPagesReducer,
  deletePages: deletePagesReducer,

  //MANAGE-CONTACTS REDUCERS
  getAllContacts: getAllContactsReducer,
  getSingleContact: getSingleContactReducer,

  //MANAGE_DEALS REDUCERS
  getFilterDeals: getFilterDealsReducer,
  getDetailDeals: getDetailDealsReducer,
  postNewDeals: postNewDealsReducer,
  putDeals: putDealsReducer,
  deleteDeals: deleteDealsReducer,

  //MANAGE_DEALS_CATEGORY REDUCERS
  getAllDealCategory: getAllDealCategoryReducer,
  getSingleDealCategory: getSingleDealCategoryReducer,
  postDealCategory: postDealCategoryReducer,
  putDealCategory: putDealCategoryReducer,
  deleteDealCategory: deleteDealCategoryReducer,

  //Member-List-Update-KYC-Form REDUCERS
  putEnterpriseKycFormAdmin: putEnterpriseKycFormAdminReducer,
  putInvestorKycFormAdmin: putInvestorKycFormAdminReducer,
  putEnterpriseBusinessDocsAdmin: putEnterpriseBusinessDocsAdminReducer,
  putEnterpriseRegistrationDocsAdmin: putEnterpriseRegistrationDocsAdminReducer,
  putEnterprisePanOrVatAdmin: putEnterprisePanOrVatAdminReducer,
  putEnterpriseMobilizationDocsAdmin: putEnterpriseMobilizationDocsAdminReducer,
  putEnterpriseProfitablityDocsAdmin: putEnterpriseProfitablityDocsAdminReducer,
  postEnterpriseBusinessDocsAdmin: postEnterpriseBusinessDocsAdminReducer,
  postEnterpriseRegistrationDocsAdmin:
    postEnterpriseRegistrationDocsAdminReducer,
  postEnterprisePanOrVatAdmin: postEnterprisePanOrVatAdminReducer,
  postEnterpriseMobilizationDocsAdmin:
    postEnterpriseMobilizationDocsAdminReducer,
  postEnterpriseProfitablityDocsAdmin:
    postEnterpriseProfitablityDocsAdminReducer,

  //MEMBER SIDE REDUCERS HERE:
  //KYC AND MEMBER PROFILE REDUCERS:
  getMemberProfileside: getMemberProfilesideReducer,
  postCreateEnterpriseKyc: postCreateEnterpriseKycReducer,
  getSingleMemberProfileside: getSingleMemberProfilesideReducer,
  postCreateInvestorKyc: postCreateInvestorKycReducer,
  postEnterpriseBusinessPlan: postEnterpriseBusinessPlanReducer,
  postEnterpriseRegistrationComp: postEnterpriseRegistrationCompReducer,
  getEnterpriseBusinessPlanDoc: getEnterpriseBusinessPlanDocReducer,
  getEnterpriseRegistrationCompDoc: getEnterpriseRegistrationCompDocReducer,
  postEnterprisePanOrVatDoc: postEnterprisePanOrVatDocReducer,
  postEnterpriseMobilizationPlanDocs: postEnterpriseMobilizationPlanDocsReducer,
  postEnterpriseRevenueProfitabilityDocs:
    postEnterpriseRevenueProfitabilityDocsReducer,
  getEnterprisePanVatDoc: getEnterprisePanVatDocReducer,
  getEnterpriseMobilizationPlanDoc: getEnterpriseMobilizationPlanDocReducer,
  getEnterpriseRevenueProfitabilityDoc:
    getEnterpriseRevenueProfitabilityDocReducer,

  //PUBLIC ROUTES OF HOMEPAGE REDUCERS
  getAboutCompanyPublic: getAboutCompanyPublicReducer,
  getWorkWithUsPublic: getWorkWithUsPublicReducer,
  getTeamsPublic: getTeamsPublicReducer,
  getSingleTeamPublic: getSingleTeamPublicReducer,
  getAdvisorsPublic: getAdvisorsPublicReducer,
  getSingleAdvisorCompanyPublic: getSingleAdvisorCompanyPublicReducer,
  getPartnersPublic: getPartnersPublicReducer,
  getSinglePartnerPublic: getSinglePartnerPublicReducer,
  getAllSuccessStoriesPublic: getAllSuccessStoriesPublicReducer,
  getAllBlogsPublic: getAllBlogsPublicReducer,
  getBlogsSlugPublic: getBlogsSlugPublicReducer,
  getAllNewsPublic: getAllNewsPublicReducer,
  getNewsSlugPublic: getNewsSlugPublicReducer,
  getAllInvestorFaqsPublic: getAllInvestorFaqsPublicReducer,
  getAllEnterpriseFaqsPublic: getAllEnterpriseFaqsPublicReducer,
  getAllContactDetailsPublic: getAllContactDetailsPublicReducer,
  postContactFormPublic: postContactFormPublicReducer,
  getAllDealsPublic: getAllDealsPublicReducer,
  getSingleDealsPublic: getSingleDealsPublicReducer,
  getAllDealCategoryPublic: getAllDealCategoryPublicReducer,
  postVerifyMember: postVerifyMemberReducer,

  //PRIVATE ROUTES OF MEMBERSIDE:
  getDetailDealsPrivate: getDetailDealsPrivateReducer,
  postProfileData: postProfileDataReducer,
  postChangePassword: postChangePasswordReducer,
  postShowInterest: postShowInterestReducer,
  getAllDealCategoryFilter: getAllDealCategoryFilterReducer,
  getAllSdgsFilterList: getAllSdgsFilterListReducer,
  getDetailedPersonalInfo: getDetailedPersonalInfoReducer,
  getShowInterestedDeals: getShowInterestedDealsReducer,
  getProfileAsDealsById: getProfileAsDealsByIdReducer,
});

import {
  API_URL,
  axiosAdminPrivate,
  tokenConfig,
  tokenConfigForm,
} from "../../api/axios";
import {
  DELETE_PAGES_FAIL,
  DELETE_PAGES_REQUEST,
  DELETE_PAGES_SUCCESS,
  GET_ALL_PAGES_FAIL,
  GET_ALL_PAGES_REQUEST,
  GET_ALL_PAGES_SUCCESS,
  GET_SINGLE_PAGES_FAIL,
  GET_SINGLE_PAGES_REQUEST,
  GET_SINGLE_PAGES_SUCCESS,
  POST_PAGES_FAIL,
  POST_PAGES_REQUEST,
  POST_PAGES_SUCCESS,
  PUT_PAGES_FAIL,
  PUT_PAGES_REQUEST,
  PUT_PAGES_SUCCESS,
} from "./ManagePagesConstants";

export const getAllPagesAction = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_PAGES_REQUEST });

    const { data } = await axiosAdminPrivate.get(
      `${API_URL}/api/v1/admin/pages`
    );

    dispatch({ type: GET_ALL_PAGES_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_ALL_PAGES_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

export const getSinglePagesAction = (unique_id) => async (dispatch) => {
  try {
    dispatch({ type: GET_SINGLE_PAGES_REQUEST });

    const { data } = await axiosAdminPrivate.get(
      `${API_URL}/api/v1/admin/pages/${unique_id}`
    );

    dispatch({ type: GET_SINGLE_PAGES_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_SINGLE_PAGES_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

export const postPagesAction = (addPages, image) => async (dispatch) => {
  try {
    dispatch({ type: POST_PAGES_REQUEST });

    const formData = new FormData();
    formData.append("page_image", image);
    formData.append("page_title", addPages?.page_title);
    formData.append("page_slug", addPages?.page_slug);
    formData.append("page_description", addPages?.page_description);
    formData.append("status", addPages?.status);

    const { data } = await axiosAdminPrivate.post(
      `${API_URL}/api/v1/admin/pages`,
      formData
    );
    dispatch({ type: POST_PAGES_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: POST_PAGES_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

export const putPagesAction =
  (updatePages, image, unique_id) => async (dispatch) => {
    try {
      dispatch({ type: PUT_PAGES_REQUEST });

      const formData = new FormData();
      formData.append("page_image", image);
      formData.append("page_title", updatePages?.page_title);
      formData.append("page_slug", updatePages?.page_slug);
      formData.append("page_description", updatePages?.page_description);
      formData.append("status", updatePages?.status);

      const { data } = await axiosAdminPrivate.put(
        `${API_URL}/api/v1/admin/pages/${unique_id}`,
        formData
      );
      dispatch({ type: PUT_PAGES_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: PUT_PAGES_FAIL,
        payload: error.message ? error.message : error.Message,
      });
    }
  };

export const deletePagesAction = (unique_id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_PAGES_REQUEST });

    const { data } = await axiosAdminPrivate.delete(
      `${API_URL}/api/v1/admin/pages/${unique_id}`,
      tokenConfig()
    );

    dispatch({ type: DELETE_PAGES_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: DELETE_PAGES_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

export const GET_ALL_DEAL_CATEGORY_REQUEST = "GET_ALL_DEAL_CATEGORY_REQUEST";
export const GET_ALL_DEAL_CATEGORY_SUCCESS = "GET_ALL_DEAL_CATEGORY_SUCCESS";
export const GET_ALL_DEAL_CATEGORY_FAIL = "GET_ALL_DEAL_CATEGORY_FAIL";
export const GET_ALL_DEAL_CATEGORY_RESET = "GET_ALL_DEAL_CATEGORY_RESET";

export const GET_SINGLE_DEAL_CATEGORY_REQUEST =
  "GET_SINGLE_DEAL_CATEGORY_REQUEST";
export const GET_SINGLE_DEAL_CATEGORY_SUCCESS =
  "GET_SINGLE_DEAL_CATEGORY_SUCCESS";
export const GET_SINGLE_DEAL_CATEGORY_FAIL = "GET_SINGLE_DEAL_CATEGORY_FAIL";
export const GET_SINGLE_DEAL_CATEGORY_RESET = "GET_SINGLE_DEAL_CATEGORY_RESET";

export const POST_DEAL_CATEGORY_REQUEST = "POST_DEAL_CATEGORY_REQUEST";
export const POST_DEAL_CATEGORY_SUCCESS = "POST_DEAL_CATEGORY_SUCCESS";
export const POST_DEAL_CATEGORY_FAIL = "POST_DEAL_CATEGORY_FAIL";
export const POST_DEAL_CATEGORY_RESET = "POST_DEAL_CATEGORY_RESET";

export const PUT_DEAL_CATEGORY_REQUEST = "PUT_DEAL_CATEGORY_REQUEST";
export const PUT_DEAL_CATEGORY_SUCCESS = "PUT_DEAL_CATEGORY_SUCCESS";
export const PUT_DEAL_CATEGORY_FAIL = "PUT_DEAL_CATEGORY_FAIL";
export const PUT_DEAL_CATEGORY_RESET = "PUT_DEAL_CATEGORY_RESET";

export const DELETE_DEAL_CATEGORY_REQUEST = "DELETE_DEAL_CATEGORY_REQUEST";
export const DELETE_DEAL_CATEGORY_SUCCESS = "DELETE_DEAL_CATEGORY_SUCCESS";
export const DELETE_DEAL_CATEGORY_FAIL = "DELETE_DEAL_CATEGORY_FAIL";
export const DELETE_DEAL_CATEGORY_RESET = "DELETE_DEAL_CATEGORY_RESET";

import React from "react";
import { Button, TableRow, TableCell } from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import { API_URL } from "../../api/axios";
import Moment from "react-moment";
import Chip from "@mui/material/Chip";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { getAccessToken } from "../../services/storage";
import jwtDecode from "jwt-decode";

const ManageUsersTableCollapse = ({
  item,
  updateUserHandler,
  deleteUserHandler,
}) => {
  const accessToken = getAccessToken();

  const decodedToken = jwtDecode(accessToken);

  const payload = decodedToken;

  return (
    <TableRow>
      {item.profile_image ? (
        <TableCell sx={{ fontSize: "13px" }}>
          <img
            alt=""
            height="30px"
            width="30px"
            style={{ cursor: "pointer", borderRadius: "50%" }}
            src={`${API_URL}/uploads/admin/profile_images/${item.profile_image}`}
          />{" "}
          &nbsp;
          {item.first_name} {item.last_name}
        </TableCell>
      ) : (
        <TableCell sx={{ fontSize: "13px" }}>
          <img
            alt=""
            height="30px"
            width="30px"
            style={{ cursor: "pointer", borderRadius: "50%" }}
            src="https://i.postimg.cc/vZvJdnRS/blank-head-profile-pic-for-a-man.jpg"
          />{" "}
          &nbsp;
          {item.first_name} {item.last_name}
        </TableCell>
      )}

      <TableCell sx={{ fontSize: "13px" }}>{item.email_address}</TableCell>
      <TableCell sx={{ fontSize: "13px" }}>{item.mobile}</TableCell>
      <TableCell sx={{ fontSize: "13px" }}>
        <Moment format="YYYY/MM/DD">{item.created_date.slice(0, 10)}</Moment>
        <br />(<Moment fromNow>{item.created_date}</Moment>)
      </TableCell>
      <TableCell sx={{ fontSize: "13px" }}>
        <Chip
          style={{
            backgroundColor: "#42c12c",
            color: "#ffffff",
            marginLeft: "15%",
          }}
          label={item.is_super_admin ? "Yes" : "No"}
        />
      </TableCell>
      <TableCell sx={{ fontSize: "13px" }}>
        {item.status ? (
          <CheckIcon style={{ color: " #69CCA4" }} />
        ) : (
          <ClearIcon style={{ color: "red" }} />
        )}
      </TableCell>
      <TableCell>
        <Button
          style={{
            marginRight: "3px",
            padding: "5px",
            minWidth: "10px",
            fontSize: "13px",
            backgroundColor: "#2a80f9",
          }}
          variant="contained"
          onClick={() => updateUserHandler(item.unique_id)}
        >
          <Edit style={{ fontSize: 13 }} />
        </Button>

        {payload && payload.is_super_admin === 1 ? (
          <>
            <Button
              style={{
                marginRight: "1px",
                padding: "5px",
                minWidth: "10px",
                fontSize: "13px",
                backgroundColor: "#ea3f4d",
              }}
              variant="contained"
              onClick={() => deleteUserHandler(item.unique_id)}
            >
              <Delete style={{ fontSize: 13 }} />
            </Button>
          </>
        ) : (
          ""
        )}
      </TableCell>
    </TableRow>
  );
};

export default ManageUsersTableCollapse;

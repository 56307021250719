import {
  API_URL,
  axiosAdminPrivate,
  tokenConfig,
  tokenConfigForm,
} from "../../api/axios";
import {
  DELETE_SUCCESS_STORIES_FAIL,
  DELETE_SUCCESS_STORIES_REQUEST,
  DELETE_SUCCESS_STORIES_SUCCESS,
  GET_ALL_SUCCESS_STORIES_FAIL,
  GET_ALL_SUCCESS_STORIES_REQUEST,
  GET_ALL_SUCCESS_STORIES_SUCCESS,
  GET_SINGLE_SUCCESS_STORIES_FAIL,
  GET_SINGLE_SUCCESS_STORIES_REQUEST,
  GET_SINGLE_SUCCESS_STORIES_SUCCESS,
  POST_SUCCESS_STORIES_FAIL,
  POST_SUCCESS_STORIES_REQUEST,
  POST_SUCCESS_STORIES_SUCCESS,
  PUT_SUCCESS_STORIES_FAIL,
  PUT_SUCCESS_STORIES_REQUEST,
  PUT_SUCCESS_STORIES_SUCCESS,
} from "./SuccessStoriesConstants";

export const getAllSuccessStoriesAction = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_SUCCESS_STORIES_REQUEST });

    const { data } = await axiosAdminPrivate.get(
      `${API_URL}/api/v1/admin/success_stories`
    );

    dispatch({ type: GET_ALL_SUCCESS_STORIES_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_ALL_SUCCESS_STORIES_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

export const getSingleSuccessStoriesAction =
  (unique_id) => async (dispatch) => {
    try {
      dispatch({ type: GET_SINGLE_SUCCESS_STORIES_REQUEST });

      const { data } = await axiosAdminPrivate.get(
        `${API_URL}/api/v1/admin/success_stories/${unique_id}`
      );

      dispatch({ type: GET_SINGLE_SUCCESS_STORIES_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: GET_SINGLE_SUCCESS_STORIES_FAIL,
        payload: error.message ? error.message : error.Message,
      });
    }
  };

export const postSuccessStoriesAction =
  (addSuccess, image) => async (dispatch) => {
    try {
      dispatch({ type: POST_SUCCESS_STORIES_REQUEST });
      let formData = new FormData();
      formData.append("ss_avatar_image", image);
      formData.append("partner_id", addSuccess?.partner_id);
      formData.append("ss_name", addSuccess?.ss_name);
      formData.append("ss_message", addSuccess?.ss_message);
      formData.append("status", addSuccess?.status);

      const { data } = await axiosAdminPrivate.post(
        `${API_URL}/api/v1/admin/success_stories`,
        formData
      );

      dispatch({ type: POST_SUCCESS_STORIES_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: POST_SUCCESS_STORIES_FAIL,
        payload: error.message ? error.message : error.Message,
      });
    }
  };

export const putSuccessStoriesAction =
  (updateSuccess, unique_id, image) => async (dispatch) => {
    try {
      dispatch({ type: PUT_SUCCESS_STORIES_REQUEST });
      let formData = new FormData();
      formData.append("ss_avatar_image", image);
      formData.append(
        "partner_company_name",
        updateSuccess?.partner_company_name
      );
      formData.append("partner_id", updateSuccess?.partner_id);
      formData.append("partner_logo_image", updateSuccess?.partner_logo_image);
      formData.append("ss_message", updateSuccess?.ss_message);
      formData.append("ss_name", updateSuccess?.ss_name);
      formData.append("status", updateSuccess?.status);

      const { data } = await axiosAdminPrivate.put(
        `${API_URL}/api/v1/admin/success_stories/${unique_id}`,
        formData
      );

      dispatch({ type: PUT_SUCCESS_STORIES_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: PUT_SUCCESS_STORIES_FAIL,
        payload: error.message ? error.message : error.Message,
      });
    }
  };

export const deleteSuccessStoriesAction = (unique_id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_SUCCESS_STORIES_REQUEST });

    const { data } = await axiosAdminPrivate.delete(
      `${API_URL}/api/v1/admin/success_stories/${unique_id}`,
      tokenConfig()
    );

    dispatch({ type: DELETE_SUCCESS_STORIES_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: DELETE_SUCCESS_STORIES_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

import React from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate, useParams } from "react-router-dom";
import { TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { postVerifyMemberAction } from "../public-routes/PublicRoutesActions";
import { toast, ToastContainer } from "react-toastify";

const VerifyMember = () => {
  const [loading, setLoading] = useState(false);
  const { verification_code } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const initialFormValues = {
    verification_code: verification_code,
  };

  const handleVerifyMember = (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(postVerifyMemberAction(initialFormValues))
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.message);
          setTimeout(() => {
            navigate("/verify/verified");
          }, 1000);
        } else {
          toast.error(res.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        toast.error(error.message);
        setLoading(false);
      });
  };
  const backSpaceHandler = () => {
    navigate("/login");
  };
  return (
    <>
      <div
        className="card"
        style={{
          width: "30%",
          marginLeft: "36%",
          marginTop: "8%",
          backgroundColor: "#f7f7f7",
        }}
      >
        <div>
          <ArrowBackIosIcon
            onClick={backSpaceHandler}
            style={{
              color: "#0b6885",
              marginTop: "8%",
              marginLeft: "4%",
              cursor: "pointer",
              fontSize: "26px",
            }}
          />
        </div>
        <div>
          <h4 style={{ color: "#0b6885", textAlign: "center", padding: "5%" }}>
            Verification Code
          </h4>
        </div>
        <div style={{ textAlign: "center", fontSize: "14px" }}>
          Please Verify your code sent to your Email Address
        </div>
        <div style={{ padding: "20px", marginTop: "10%" }}>
          <TextField
            InputProps={{
              style: {
                pointerEvents: "none",
              },
            }}
            name="verification_code"
            label="Verification Code"
            fullWidth
            value={verification_code}
            style={{ alignItems: "center" }}
          />
        </div>
        <div style={{ padding: "20px" }}>
          <LoadingButton
            loading={loading}
            loadingPosition="end"
            type="submit"
            onClick={handleVerifyMember}
            fullWidth
            variant="contained"
            style={{ backgroundColor: "#0b6885", color: "ffffff" }}
            sx={{ mt: 2, mb: 2 }}
          >
            Verify Your Account
          </LoadingButton>
          <ToastContainer
            position="top-right"
            autoClose={2000}
            theme="colored"
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
          />
        </div>
      </div>
    </>
  );
};

export default VerifyMember;

import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import useAuthentication from "./components/hooks/useAuthentication";
import SideMenu from "./components/SideMenu";
import Header from "./components/Header";
import ArrowButton from "./frontend/components/ArrowButton";
import "react-toastify/dist/ReactToastify.css";
import useAdminAuthentication from "./components/hooks/useAdminAuthentication";

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);

  const { isAuth } = useAuthentication();

  const { isAdminAuthenticated } = useAdminAuthentication();

  const navigate = useNavigate();

  useEffect(() => {
    if (!isAdminAuthenticated) {
      return navigate("/admin/login");
    }
  }, [isAdminAuthenticated, navigate]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <SideMenu isSidebar={isSidebar} />
          <ArrowButton />
          <main className="content">
            <Header setIsSidebar={setIsSidebar} />
            <Outlet />
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;

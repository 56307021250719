import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import ArrowButton from "../components/ArrowButton";
import LoadingComp from "../../components/LoadingComp";
import {
  getDetailedPersonalInfoAction,
  postProfileDataAction,
} from "../private-routes/PrivateActions";
import DashboardSideMenu from "../components/DashboardSideMenu";
import { API_URL } from "../../api/axios";
import { toast, ToastContainer } from "react-toastify";
import { getMemberProfileSideAction } from "../kyc-state-handler/KycActions";

const MemberProfile = () => {
  const [image, setImage] = useState(null);
  const [imgSrc, setImgSrc] = useState(null);
  const [prevMemberProfileSide, setPrevMemberProfileSide] = useState(null);
  const [profile, setProfile] = useState([]);
  const [values, setValues] = useState({
    username: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    mobile: "",
    company_name: "",
    address: "",
    date_of_birth: "",
    profile_image: "",
  });

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleImageChange = (event) => {
    const imageFile = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (x) => {
      setImgSrc(x.target.result);
    };
    reader.readAsDataURL(imageFile);
    if (imageFile) {
      setImage(imageFile);
      setValues({
        ...values,
        [event.target.name]: imageFile,
      });
    }
  };

  const { memberProfileSide } = useSelector(
    (state) => state.getMemberProfileside
  );

  const { detailedPersonalInfo, loading } = useSelector(
    (state) => state.getDetailedPersonalInfo
  );

  useEffect(() => {
    dispatch(getDetailedPersonalInfoAction());
  }, [dispatch]);

  useEffect(() => {
    if (memberProfileSide && memberProfileSide !== prevMemberProfileSide) {
      setProfile(memberProfileSide?.data);
    }
  }, [memberProfileSide, prevMemberProfileSide]);

  useEffect(() => {
    setPrevMemberProfileSide(memberProfileSide);
  }, [memberProfileSide]);

  useEffect(() => {
    dispatch(getMemberProfileSideAction());
  }, [dispatch]);

  const dataEnterpriseArray = profile?.find(
    (value) => value.profile_type === "Enterprise"
  );
  const dataInvestorArray = profile?.find(
    (value) => value.profile_type === "Investor"
  );

  const profileVerified = profile?.find((value) => value.is_verified === 0);
  useEffect(() => {
    if (!detailedPersonalInfo) {
      dispatch(getDetailedPersonalInfoAction());
    }
    if (detailedPersonalInfo) {
      setValues(detailedPersonalInfo?.data[0]);
    }
  }, [detailedPersonalInfo, dispatch]);

  const addNewProfile = (e) => {
    e.preventDefault();
    if (dataEnterpriseArray && dataInvestorArray) {
      toast.error("You already have both profiles!");
    } else if (dataEnterpriseArray) {
      setTimeout(() => {
        navigate("/investor_kyc");
      }, 800);
    } else if (dataInvestorArray) {
      setTimeout(() => {
        navigate("/enterprise_kyc");
      }, 800);
    }
  };

  const submitProfile = (e) => {
    e.preventDefault();
    dispatch(postProfileDataAction(values, image)).then((res) => {
      if (res) {
        setTimeout(() => {
          navigate("/dashboard");
        }, 1800);
      } else {
        toast.error("Error while updating profile!");
      }
    });
  };

  return (
    <>
      <div className="page-title">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h1>Dashboard</h1>
              <Link className="link" to="/">
                Home
              </Link>{" "}
              {""} /{""}
              <Link className="link" to="/dashboard">
                {" "}
                Dashboard
              </Link>{" "}
              {""} / Profile
            </div>
          </div>
        </div>
      </div>
      <ArrowButton />

      <div className="container pt-5 pb-5 mb-5">
        <div className="row">
          <div className="col-md-3">
            <DashboardSideMenu />
          </div>

          {loading ? (
            <LoadingComp />
          ) : (
            <>
              <div className="col-md-9">
                <div className="dash-personal">
                  <div
                    className="d-flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    <h3>Personal Details</h3>
                    {/* {profile?.length > 0 && !profileVerified && (
                      <Button
                        startIcon={<Add />}
                        onClick={addNewProfile}
                        style={{
                          backgroundColor: "#0b6885",
                          height: "35px",
                        }}
                        type="submit"
                        className="send-button"
                        variant="contained"
                      >
                        Add Profile
                      </Button>
                    )} */}
                  </div>
                  <form onSubmit={submitProfile}>
                    <div className="row">
                      <div className="col-md-6" style={{ marginTop: "8%" }}>
                        Profile Image
                        <input
                          type="file"
                          style={{ backgroundColor: "#ffffff" }}
                          name="profile_image"
                          id="profile_image"
                          defaultValue={image}
                          onChange={handleImageChange}
                        />
                      </div>
                      <div className="col-md-6">
                        {values.profile_image && values.profile_image ? (
                          <img
                            alt=""
                            style={{ borderRadius: "10%" }}
                            src={
                              imgSrc
                                ? imgSrc
                                : values &&
                                  `${API_URL}/uploads/members/profile_images/${values?.profile_image}`
                            }
                            height={130}
                            width={140}
                          />
                        ) : (
                          <img
                            alt=""
                            height={130}
                            width={140}
                            style={{ cursor: "pointer", borderRadius: "10%" }}
                            src="https://i.postimg.cc/vZvJdnRS/blank-head-profile-pic-for-a-man.jpg"
                          />
                        )}
                      </div>
                      <div className="col-md-6">
                        Username
                        <input
                          type="text"
                          id="username"
                          name="username"
                          value={values.username}
                          onChange={handleChange}
                          autoComplete="off"
                        />
                      </div>
                      <div className="col-md-6">
                        First Name
                        <input
                          type="text"
                          id="first_name"
                          name="first_name"
                          autoComplete="off"
                          value={values?.first_name}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-md-6">
                        Middle Name
                        <input
                          type="text"
                          id="middle_name"
                          name="middle_name"
                          autoComplete="off"
                          value={values?.middle_name}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-md-6">
                        Last Name
                        <input
                          type="text"
                          label="Last Name"
                          id="last_name"
                          name="last_name"
                          autoComplete="off"
                          value={values?.last_name}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-md-6">
                        Email Address
                        <input
                          disabled
                          type="email"
                          value={values.email}
                          style={{ backgroundColor: "#ffffff" }}
                        />
                      </div>
                      <div className="col-md-6">
                        Contact Number
                        <input
                          type="number"
                          id="mobile"
                          name="mobile"
                          value={values?.mobile}
                          onWheel={(e) => e.target.blur()}
                          autoComplete="off"
                          onChange={handleChange}
                        />
                      </div>

                      <div className="col-md-6">
                        Company Name
                        <input
                          type="text"
                          id="company_name"
                          name="company_name"
                          value={values?.company_name}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-md-6">
                        Address
                        <input
                          type="text"
                          id="address"
                          name="address"
                          value={values?.address}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="col-md-6">
                        Date of Birth
                        <input
                          type="date"
                          name="date_of_birth"
                          value={values?.date_of_birth?.slice(0, 10)}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="offset-md-9 col-md-4">
                        <button type="submit" className="blue-btn">
                          Save Changes
                        </button>
                        <ToastContainer
                          position="top-right"
                          autoClose={2000}
                          theme="colored"
                          hideProgressBar={false}
                          newestOnTop={false}
                          closeOnClick
                          rtl={false}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default MemberProfile;

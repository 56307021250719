import React, { useState } from "react";
import { Button, Grid, TextField } from "@mui/material";
import { useForm, Form } from "../../components/hooks/useForm";
import { useDispatch } from "react-redux";
import { putUserAction } from "./ManageUsersActions";
import { useEffect } from "react";
import CheckBoxControl from "../../components/controls/CheckBoxControl";
import { API_URL } from "../../api/axios";
import LoadingComp from "../../components/LoadingComp";
import { getAccessToken } from "../../services/storage";
import jwtDecode from "jwt-decode";

const ManageUsersEditForm = ({
  singleUser,
  setOpenEditPopup,
  loadingEditForm,
}) => {
  const [image, setImage] = useState(null);
  const [imgSrc, setImgSrc] = useState(null);
  const dispatch = useDispatch();

  const accessToken = getAccessToken();

  const decodedToken = jwtDecode(accessToken);

  const payload = decodedToken;

  const initialFormValues = {
    email_address: "",
    first_name: "",
    last_name: "",
    profile_image: "",
    mobile: "",
    is_super_admin: 0,
    status: 0,
  };

  const { values, setValues, handleInputChange } = useForm(initialFormValues);

  const handleImage = (event) => {
    let imageFile = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (x) => {
      setImgSrc(x.target.result);
    };
    reader.readAsDataURL(imageFile);
    if (imageFile) {
      setImage(imageFile);
      setValues({
        ...values,
        [event.target.name]: imageFile,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(putUserAction(values, singleUser?.unique_id, image));
    setOpenEditPopup(false);
  };

  useEffect(() => {
    if (singleUser) {
      setValues({ ...singleUser });
    }
  }, [singleUser, setValues]);

  return (
    <Form onSubmit={handleSubmit}>
      {loadingEditForm ? (
        <LoadingComp />
      ) : (
        <>
          <Grid container spacing={2} style={{ fontSize: "12px" }}>
            <Grid item xs={6}>
              <TextField
                name="first_name"
                label="First Name"
                value={values.first_name}
                style={{ width: "100%" }}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="last_name"
                label="Last Name"
                value={values.last_name}
                style={{ width: "100%" }}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="email_address"
                label="Email Address*"
                style={{ width: "100%" }}
                value={values.email_address}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="mobile"
                label="Phone Number*"
                type="number"
                style={{ width: "100%" }}
                value={values.mobile}
                onWheel={(e) => e.target.blur()}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="advisor_image"
                style={{ width: "100%" }}
                onChange={(e) => handleImage(e)}
                type="file"
              />{" "}
              <br />
              <br />
              {singleUser?.profile_image && singleUser?.profile_image ? (
                <img
                  alt=""
                  src={
                    imgSrc
                      ? imgSrc
                      : singleUser &&
                        `${API_URL}/uploads/admin/profile_images/${singleUser?.profile_image}`
                  }
                  height={150}
                  width={150}
                />
              ) : (
                <span style={{ color: "red" }}>*Choose Image</span>
              )}
            </Grid>
            <Grid item xs={6}>
              <CheckBoxControl
                name="status"
                label="Active"
                value={values.status}
                onChange={handleInputChange}
              />
            </Grid>
            {payload && payload.is_super_admin === 1 ? (
              <Grid item xs={6}>
                <CheckBoxControl
                  name="is_super_admin"
                  label="Super Admin"
                  value={values.is_super_admin}
                  onChange={handleInputChange}
                />
              </Grid>
            ) : (
              ""
            )}
          </Grid>

          <div
            style={{
              display: "flex",
              justifyContent: "end",
              paddingTop: "10px",
              marginTop: "10px",
              borderTop: "1px solid #0b6885",
            }}
          >
            <Button
              variant="contained"
              onClick={() => setOpenEditPopup(false)}
              style={{ margin: "10px 0 0 10px", backgroundColor: "#ea3f4d" }}
            >
              CANCEL
            </Button>
            <Button
              variant="contained"
              type="submit"
              style={{ margin: "10px 0 0 10px", backgroundColor: "#0b6885" }}
            >
              SUBMIT
            </Button>
          </div>
        </>
      )}
    </Form>
  );
};

export default ManageUsersEditForm;

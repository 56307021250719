import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import "../../styles/style.css";
import UserHeader from "../components/UserHeader";
import UserFooter from "../components/UserFooter";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllBlogsPublicAction,
  getAllNewsPublicAction,
  getBlogsSlugPublicAction,
  getNewsSlugPublicAction,
} from "../public-routes/PublicRoutesActions";
import Moment from "react-moment";
import { API_URL } from "../../api/axios";
import LoadingComp from "../../components/LoadingComp";
import ArrowButton from "../components/ArrowButton";

const NewsBlogsPublic = () => {
  const [showFull, setShowFull] = useState(false);
  const [showBlogsData, setShowBlogsData] = useState(false);
  const [showNewsData, setShowNewsData] = useState(true);
  const [blogs, setBlogs] = useState([]);
  const [news, setNews] = useState([]);
  const [singleNews, setSingleNews] = useState();
  const [singleBlogs, setSingleBlogs] = useState();
  const [activeButton, setActiveButton] = useState("news");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(9);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { allNewsPublic } = useSelector((state) => state.getAllNewsPublic);

  const { allBlogsPublic, loading: loadingBlogs } = useSelector(
    (state) => state.getAllBlogsPublic
  );

  const filteredNews = news.filter((item) => item.status === 1);
  const filteredBlogs = blogs.filter((item) => item.status === 1);

  useEffect(() => {
    if (!allNewsPublic) {
      dispatch(getAllNewsPublicAction());
    }
    if (allNewsPublic) {
      setNews(allNewsPublic?.data);
    }
  }, [dispatch, allNewsPublic]);

  useEffect(() => {
    if (!allBlogsPublic) {
      dispatch(getAllBlogsPublicAction());
    }
    if (allBlogsPublic) {
      setBlogs(allBlogsPublic?.data);
    }
  }, [dispatch, allBlogsPublic]);

  useEffect(() => {
    dispatch(getAllBlogsPublicAction());
    dispatch(getAllNewsPublicAction());
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  const handleButtonClick = (buttonType) => {
    setActiveButton(buttonType);
    setShowNewsData(buttonType === "news");
    setShowBlogsData(buttonType === "blogs");
    dispatch(getAllBlogsPublicAction());
    dispatch(getAllNewsPublicAction());
  };

  const handleNewsBlogsClick = (buttonName) => {
    handleButtonClick(buttonName);
  };

  const singleNewsHandle = (slug) => {
    dispatch(getNewsSlugPublicAction(slug)).then((res) => {
      setSingleNews(res.data);
      localStorage.setItem("singleNews", JSON.stringify(res.data));
      navigate("/news-&-blogs/news-single-post", {
        state: { singleNews: res.data },
      });
    });
  };

  const singleBlogsHandle = (slug) => {
    dispatch(getBlogsSlugPublicAction(slug)).then((res) => {
      setSingleBlogs(res.data);
      localStorage.setItem("singleBlogs", JSON.stringify(res.data));
      navigate("/news-&-blogs/blogs-single-post", {
        state: { singleBlogs: res.data },
      });
    });
  };

  // Get current posts
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentBlogs = filteredBlogs.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredBlogs.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const pageNumbersNews = [];
  for (let i = 1; i <= Math.ceil(filteredNews.length / itemsPerPage); i++) {
    pageNumbersNews.push(i);
  }

  return (
    <>
      <UserHeader />
      <Helmet>
        <meta charset="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="description" content="" />
        <meta name="author" content="" />

        <title>Million Deals</title>
        {/* <!-- Linking the font Poppins--> */}
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />

        {/* <!-- Linking Bootstrap Icon--> */}
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css"
        />
      </Helmet>
      <ArrowButton />
      <div className="page-title">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h1>News & Blogs</h1>
              <Link className="link" to="/">
                Home
              </Link>{" "}
              / News & Blog{" "}
            </div>
          </div>
        </div>
      </div>

      <div className="news">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <h3 className="section-subtitle">News & Blogs</h3>
              <h2 className="section-title">Stay Updated With Us</h2>
            </div>

            <div className="col-md-5 text-right">
              <button
                className={`dark-btn ${
                  activeButton === "news" ? "active-btn" : ""
                }`}
                onClick={() => handleNewsBlogsClick("news")}
              >
                Us in News
              </button>
              <button
                className={`dark-btn ${
                  activeButton === "blogs" ? "active-btn" : ""
                }`}
                onClick={() => handleNewsBlogsClick("blogs")}
              >
                Blog Posts
              </button>
            </div>
          </div>
          {loadingBlogs ? (
            <LoadingComp />
          ) : (
            <>
              {showBlogsData ? (
                blogs.length > 0 ? (
                  <div className="row pt-4">
                    {currentBlogs.map((option, index) => (
                      <div className="col-md-4" key={index}>
                        <div className="news-single">
                          {option?.blog_image && option?.blog_image ? (
                            <img
                              onClick={() =>
                                singleBlogsHandle(option.blog_slug)
                              }
                              style={{ cursor: "pointer" }}
                              alt=""
                              src={`${API_URL}/uploads/blogs/${option.blog_image}`}
                            />
                          ) : (
                            <img
                              onClick={() =>
                                singleBlogsHandle(option.blog_slug)
                              }
                              style={{ cursor: "pointer" }}
                              alt=""
                              src="https://i.postimg.cc/vZvJdnRS/blank-head-profile-pic-for-a-man.jpg"
                            />
                          )}
                          <h4>
                            <i className="bi bi-calendar-fill"></i> &nbsp;
                            <Moment format="MMM D, YYYY">
                              {option.created_at}
                            </Moment>
                          </h4>
                          <h2
                            style={{ cursor: "pointer" }}
                            onClick={() => singleBlogsHandle(option.blog_slug)}
                          >
                            {option.blog_name}
                          </h2>
                          <p
                            className="section-text section-text-blog"
                            style={{ textAlign: "justify" }}
                            dangerouslySetInnerHTML={{
                              __html:
                                showFull === index
                                  ? option.blog_description
                                  : option?.blog_description?.length > 300
                                  ? option?.blog_description.slice(0, 300) +
                                    "......"
                                  : option?.blog_description,
                            }}
                          ></p>
                          {showFull !== index && (
                            <Link
                              style={{ textDecoration: "none" }}
                              onClick={() => setShowFull(index)}
                            >
                              Read More{">>"}
                            </Link>
                          )}

                          {showFull === index && (
                            <Link
                              style={{ textDecoration: "none" }}
                              onClick={() => setShowFull(false)}
                            >
                              {"<<"} Show Less
                            </Link>
                          )}
                        </div>
                      </div>
                    ))}
                    <div className="pagination-container">
                      <div className="row pt-5 mt-5">
                        <div className="col-md-12 justify-content-center">
                          <nav aria-label="Page navigation example">
                            <ul className="pagination justify-content-center">
                              <li
                                className={`page-item ${
                                  currentPage === 1 ? "disabled" : ""
                                }`}
                              >
                                <Link
                                  className="page-link"
                                  tabIndex="-1"
                                  onClick={() =>
                                    setCurrentPage(currentPage - 1)
                                  }
                                >
                                  {"<<"} Previous
                                </Link>
                              </li>
                              {pageNumbers.map((pageNumber) => (
                                <li
                                  key={pageNumber}
                                  className={`page-item ${
                                    currentPage === pageNumber ? "active" : ""
                                  }`}
                                >
                                  <Link
                                    className="page-link"
                                    onClick={() => setCurrentPage(pageNumber)}
                                  >
                                    {pageNumber}
                                  </Link>
                                </li>
                              ))}
                              <li
                                className={`page-item ${
                                  currentPage === pageNumbers.length
                                    ? "disabled"
                                    : ""
                                }`}
                              >
                                <Link
                                  className="page-link"
                                  onClick={() =>
                                    currentPage !== pageNumbers.length
                                      ? setCurrentPage(currentPage + 1)
                                      : ""
                                  }
                                >
                                  Next {">>"}
                                </Link>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )
              ) : showNewsData ? (
                news.length > 0 ? (
                  <div className="row pt-4">
                    {filteredNews.slice(0, 9).map((option, index) => (
                      <div className="col-md-4" key={index}>
                        <div className="news-single">
                          {option?.news_image && option?.news_image ? (
                            <img
                              style={{ cursor: "pointer" }}
                              onClick={() => singleNewsHandle(option.news_slug)}
                              alt=""
                              src={`${API_URL}/uploads/news/${option.news_image}`}
                            />
                          ) : (
                            <img
                              style={{ cursor: "pointer" }}
                              onClick={() => singleNewsHandle(option.news_slug)}
                              alt=""
                              src="https://i.postimg.cc/vZvJdnRS/blank-head-profile-pic-for-a-man.jpg"
                            />
                          )}
                          <h4>
                            <i className="bi bi-calendar-fill"></i> &nbsp;
                            <Moment format="MMM D, YYYY">
                              {option.created_at}
                            </Moment>
                          </h4>
                          <h2
                            style={{ cursor: "pointer" }}
                            onClick={() => singleNewsHandle(option.news_slug)}
                          >
                            {option.news_title}
                          </h2>
                          <p
                            className="section-text section-text-blog"
                            style={{ textAlign: "justify" }}
                            dangerouslySetInnerHTML={{
                              __html:
                                showFull === index
                                  ? option.news_description
                                  : option?.news_description?.length > 300
                                  ? option?.news_description.slice(0, 300) +
                                    "......"
                                  : option?.news_description,
                            }}
                          ></p>
                          {showFull !== index && (
                            <Link
                              style={{ textDecoration: "none" }}
                              onClick={() => setShowFull(index)}
                            >
                              Read More{">>"}
                            </Link>
                          )}

                          {showFull === index && (
                            <Link
                              style={{ textDecoration: "none" }}
                              onClick={() => setShowFull(false)}
                            >
                              {"<<"}Show Less
                            </Link>
                          )}
                        </div>
                      </div>
                    ))}
                    <div className="pagination-container">
                      <div className="row pt-5 mt-5">
                        <div className="col-md-12 justify-content-center">
                          <nav aria-label="Page navigation example">
                            <ul className="pagination justify-content-center">
                              <li
                                className={`page-item ${
                                  currentPage === 1 ? "disabled" : ""
                                }`}
                              >
                                <Link
                                  className="page-link"
                                  tabIndex="-1"
                                  onClick={() =>
                                    setCurrentPage(currentPage - 1)
                                  }
                                >
                                  {"<<"} Previous
                                </Link>
                              </li>
                              {pageNumbersNews.map((pageNumber) => (
                                <li
                                  key={pageNumber}
                                  className={`page-item ${
                                    currentPage === pageNumber ? "active" : ""
                                  }`}
                                >
                                  <Link
                                    className="page-link"
                                    onClick={() => setCurrentPage(pageNumber)}
                                  >
                                    {pageNumber}
                                  </Link>
                                </li>
                              ))}
                              <li
                                className={`page-item ${
                                  currentPage === pageNumbersNews.length
                                    ? "disabled"
                                    : ""
                                }`}
                              >
                                <Link
                                  className="page-link"
                                  onClick={() =>
                                    currentPage !== pageNumbersNews.length
                                      ? setCurrentPage(currentPage + 1)
                                      : ""
                                  }
                                >
                                  Next {">>"}
                                </Link>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </>
          )}
        </div>
      </div>
      <UserFooter />
    </>
  );
};

export default NewsBlogsPublic;

export const GET_ALL_MEMBERS_LIST_REQUEST = "GET_ALL_MEMBERS_LIST_REQUEST";
export const GET_ALL_MEMBERS_LIST_SUCCESS = "GET_ALL_MEMBERS_LIST_SUCCESS";
export const GET_ALL_MEMBERS_LIST_FAIL = "GET_ALL_MEMBERS_LIST_FAIL";
export const GET_ALL_MEMBERS_LIST_RESET = "GET_ALL_MEMBERS_LIST_RESET";

export const GET_INDIVIDUAL_MEMBERS_LIST_REQUEST =
  "GET_INDIVIDUAL_MEMBERS_LIST_REQUEST";
export const GET_INDIVIDUAL_MEMBERS_LIST_SUCCESS =
  "GET_INDIVIDUAL_MEMBERS_LIST_SUCCESS";
export const GET_INDIVIDUAL_MEMBERS_LIST_FAIL =
  "GET_INDIVIDUAL_MEMBERS_LIST_FAIL";
export const GET_INDIVIDUAL_MEMBERS_LIST_RESET =
  "GET_INDIVIDUAL_MEMBERS_LIST_RESET";

export const PUT_VERIFIED_MEMBERS_LIST_REQUEST =
  "PUT_VERIFIED_MEMBERS_LIST_REQUEST";
export const PUT_VERIFIED_MEMBERS_LIST_SUCCESS =
  "PUT_VERIFIED_MEMBERS_LIST_SUCCESS";
export const PUT_VERIFIED_MEMBERS_LIST_FAIL = "PUT_VERIFIED_MEMBERS_LIST_FAIL";
export const PUT_VERIFIED_MEMBERS_LIST_RESET =
  "PUT_VERIFIED_MEMBERS_LIST_RESET";

export const GET_SINGLE_MEMBER_PROFILE_LIST_REQUEST =
  "GET_SINGLE_MEMBER_PROFILE_LIST_REQUEST";
export const GET_SINGLE_MEMBER_PROFILE_LIST_SUCCESS =
  "GET_SINGLE_MEMBER_PROFILE_LIST_SUCCESS";
export const GET_SINGLE_MEMBER_PROFILE_LIST_FAIL =
  "GET_SINGLE_MEMBER_PROFILE_LIST_FAIL";
export const GET_SINGLE_MEMBER_PROFILE_LIST_RESET =
  "GET_SINGLE_MEMBER_PROFILE_LIST_RESET";

export const GET_MEMBER_PROFILES_REQUEST = "GET_MEMBER_PROFILES_REQUEST";
export const GET_MEMBER_PROFILES_SUCCESS = "GET_MEMBER_PROFILES_SUCCESS";
export const GET_MEMBER_PROFILES_FAIL = "GET_MEMBER_PROFILES_FAIL";
export const GET_MEMBER_PROFILES_RESET = "GET_MEMBER_PROFILES_RESET";

export const GET_ENTERPRISE_BUSSINESS_DOCS_ADMIN_REQUEST =
  "GET_ENTERPRISE_BUSSINESS_DOCS_ADMIN_REQUEST";
export const GET_ENTERPRISE_BUSSINESS_DOCS_ADMIN_SUCCESS =
  "GET_ENTERPRISE_BUSSINESS_DOCS_ADMIN_SUCCESS";
export const GET_ENTERPRISE_BUSSINESS_DOCS_ADMIN_FAIL =
  "GET_ENTERPRISE_BUSSINESS_DOCS_ADMIN_FAIL";
export const GET_ENTERPRISE_BUSSINESS_DOCS_ADMIN_RESET =
  "GET_ENTERPRISE_BUSSINESS_DOCS_ADMIN_RESET";

export const POST_ENTERPRISE_BUSSINESS_DOCS_ADMIN_REQUEST =
  "POST_ENTERPRISE_BUSSINESS_DOCS_ADMIN_REQUEST";
export const POST_ENTERPRISE_BUSSINESS_DOCS_ADMIN_SUCCESS =
  "POST_ENTERPRISE_BUSSINESS_DOCS_ADMIN_SUCCESS";
export const POST_ENTERPRISE_BUSSINESS_DOCS_ADMIN_FAIL =
  "POST_ENTERPRISE_BUSSINESS_DOCS_ADMIN_FAIL";
export const POST_ENTERPRISE_BUSSINESS_DOCS_ADMIN_RESET =
  "POST_ENTERPRISE_BUSSINESS_DOCS_ADMIN_RESET";

export const PUT_ENTERPRISE_BUSSINESS_DOCS_ADMIN_REQUEST =
  "PUT_ENTERPRISE_BUSSINESS_DOCS_ADMIN_REQUEST";
export const PUT_ENTERPRISE_BUSSINESS_DOCS_ADMIN_SUCCESS =
  "PUT_ENTERPRISE_BUSSINESS_DOCS_ADMIN_SUCCESS";
export const PUT_ENTERPRISE_BUSSINESS_DOCS_ADMIN_FAIL =
  "PUT_ENTERPRISE_BUSSINESS_DOCS_ADMIN_FAIL";
export const PUT_ENTERPRISE_BUSSINESS_DOCS_ADMIN_RESET =
  "PUT_ENTERPRISE_BUSSINESS_DOCS_ADMIN_RESET";

export const GET_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_REQUEST =
  "GET_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_REQUEST";
export const GET_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_SUCCESS =
  "GET_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_SUCCESS";
export const GET_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_FAIL =
  "GET_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_FAIL";
export const GET_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_RESET =
  "GET_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_RESET";

export const POST_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_REQUEST =
  "POST_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_REQUEST";
export const POST_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_SUCCESS =
  "POST_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_SUCCESS";
export const POST_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_FAIL =
  "POST_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_FAIL";
export const POST_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_RESET =
  "POST_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_RESET";

export const PUT_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_REQUEST =
  "PUT_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_REQUEST";
export const PUT_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_SUCCESS =
  "PUT_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_SUCCESS";
export const PUT_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_FAIL =
  "PUT_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_FAIL";
export const PUT_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_RESET =
  "PUT_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_RESET";

export const GET_PAN_OR_VAT_DOCS_ADMIN_REQUEST =
  "GET_PAN_OR_VAT_DOCS_ADMIN_REQUEST";
export const GET_PAN_OR_VAT_DOCS_ADMIN_SUCCESS =
  "GET_PAN_OR_VAT_DOCS_ADMIN_SUCCESS";
export const GET_PAN_OR_VAT_DOCS_ADMIN_FAIL = "GET_PAN_OR_VAT_DOCS_ADMIN_FAIL";
export const GET_PAN_OR_VAT_DOCS_ADMIN_RESET =
  "GET_PAN_OR_VAT_DOCS_ADMIN_RESET";

export const POST_PAN_OR_VAT_DOCS_ADMIN_REQUEST =
  "POST_PAN_OR_VAT_DOCS_ADMIN_REQUEST";
export const POST_PAN_OR_VAT_DOCS_ADMIN_SUCCESS =
  "POST_PAN_OR_VAT_DOCS_ADMIN_SUCCESS";
export const POST_PAN_OR_VAT_DOCS_ADMIN_FAIL =
  "POST_PAN_OR_VAT_DOCS_ADMIN_FAIL";
export const POST_PAN_OR_VAT_DOCS_ADMIN_RESET =
  "POST_PAN_OR_VAT_DOCS_ADMIN_RESET";

export const PUT_PAN_OR_VAT_DOCS_ADMIN_REQUEST =
  "PUT_PAN_OR_VAT_DOCS_ADMIN_REQUEST";
export const PUT_PAN_OR_VAT_DOCS_ADMIN_SUCCESS =
  "PUT_PAN_OR_VAT_DOCS_ADMIN_SUCCESS";
export const PUT_PAN_OR_VAT_DOCS_ADMIN_FAIL = "PUT_PAN_OR_VAT_DOCS_ADMIN_FAIL";
export const PUT_PAN_OR_VAT_DOCS_ADMIN_RESET =
  "PUT_PAN_OR_VAT_DOCS_ADMIN_RESET";

export const GET_MOBILIZATION_PLAN_DOCS_ADMIN_REQUEST =
  "GET_MOBILIZATION_PLAN_DOCS_ADMIN_REQUEST";
export const GET_MOBILIZATION_PLAN_DOCS_ADMIN_SUCCESS =
  "GET_MOBILIZATION_PLAN_DOCS_ADMIN_SUCCESS";
export const GET_MOBILIZATION_PLAN_DOCS_ADMIN_FAIL =
  "GET_MOBILIZATION_PLAN_DOCS_ADMIN_FAIL";
export const GET_MOBILIZATION_PLAN_DOCS_ADMIN_RESET =
  "GET_MOBILIZATION_PLAN_DOCS_ADMIN_RESET";

export const POST_MOBILIZATION_PLAN_DOCS_ADMIN_REQUEST =
  "POST_MOBILIZATION_PLAN_DOCS_ADMIN_REQUEST";
export const POST_MOBILIZATION_PLAN_DOCS_ADMIN_SUCCESS =
  "POST_MOBILIZATION_PLAN_DOCS_ADMIN_SUCCESS";
export const POST_MOBILIZATION_PLAN_DOCS_ADMIN_FAIL =
  "POST_MOBILIZATION_PLAN_DOCS_ADMIN_FAIL";
export const POST_MOBILIZATION_PLAN_DOCS_ADMIN_RESET =
  "POST_MOBILIZATION_PLAN_DOCS_ADMIN_RESET";

export const PUT_MOBILIZATION_PLAN_DOCS_ADMIN_REQUEST =
  "PUT_MOBILIZATION_PLAN_DOCS_ADMIN_REQUEST";
export const PUT_MOBILIZATION_PLAN_DOCS_ADMIN_SUCCESS =
  "PUT_MOBILIZATION_PLAN_DOCS_ADMIN_SUCCESS";
export const PUT_MOBILIZATION_PLAN_DOCS_ADMIN_FAIL =
  "PUT_MOBILIZATION_PLAN_DOCS_ADMIN_FAIL";
export const PUT_MOBILIZATION_PLAN_DOCS_ADMIN_RESET =
  "PUT_MOBILIZATION_PLAN_DOCS_ADMIN_RESET";

export const GET_REVENUE_PROFITABILITY_DOCS_ADMIN_REQUEST =
  "GET_REVENUE_PROFITABILITY_DOCS_ADMIN_REQUEST";
export const GET_REVENUE_PROFITABILITY_DOCS_ADMIN_SUCCESS =
  "GET_REVENUE_PROFITABILITY_DOCS_ADMIN_SUCCESS";
export const GET_REVENUE_PROFITABILITY_DOCS_ADMIN_FAIL =
  "GET_REVENUE_PROFITABILITY_DOCS_ADMIN_FAIL";
export const GET_REVENUE_PROFITABILITY_DOCS_ADMIN_RESET =
  "GET_REVENUE_PROFITABILITY_DOCS_ADMIN_RESET";

export const POST_REVENUE_PROFITABILITY_DOCS_ADMIN_REQUEST =
  "POST_REVENUE_PROFITABILITY_DOCS_ADMIN_REQUEST";
export const POST_REVENUE_PROFITABILITY_DOCS_ADMIN_SUCCESS =
  "POST_REVENUE_PROFITABILITY_DOCS_ADMIN_SUCCESS";
export const POST_REVENUE_PROFITABILITY_DOCS_ADMIN_FAIL =
  "POST_REVENUE_PROFITABILITY_DOCS_ADMIN_FAIL";
export const POST_REVENUE_PROFITABILITY_DOCS_ADMIN_RESET =
  "POST_REVENUE_PROFITABILITY_DOCS_ADMIN_RESET";

export const PUT_REVENUE_PROFITABILITY_DOCS_ADMIN_REQUEST =
  "PUT_REVENUE_PROFITABILITY_DOCS_ADMIN_REQUEST";
export const PUT_REVENUE_PROFITABILITY_DOCS_ADMIN_SUCCESS =
  "PUT_REVENUE_PROFITABILITY_DOCS_ADMIN_SUCCESS";
export const PUT_REVENUE_PROFITABILITY_DOCS_ADMIN_FAIL =
  "PUT_REVENUE_PROFITABILITY_DOCS_ADMIN_FAIL";
export const PUT_REVENUE_PROFITABILITY_DOCS_ADMIN_RESET =
  "PUT_REVENUE_PROFITABILITY_DOCS_ADMIN_RESET";

export const GET_ALL_DEAL_CATEGORY_FILTER_ADMIN_REQUEST =
  "GET_ALL_DEAL_CATEGORY_FILTER_ADMIN_REQUEST";
export const GET_ALL_DEAL_CATEGORY_FILTER_ADMIN_SUCCESS =
  "GET_ALL_DEAL_CATEGORY_FILTER_ADMIN_SUCCESS";
export const GET_ALL_DEAL_CATEGORY_FILTER_ADMIN_FAIL =
  "GET_ALL_DEAL_CATEGORY_FILTER_ADMIN_FAIL";
export const GET_ALL_DEAL_CATEGORY_FILTER_ADMIN_RESET =
  "GET_ALL_DEAL_CATEGORY_FILTER_ADMIN_RESET";

export const GET_ALL_SDG_FILTER_ADMIN_REQUEST =
  "GET_ALL_DEAL_CATEGORY_FILTER_ADMIN_REQUEST";
export const GET_ALL_SDG_FILTER_ADMIN_SUCCESS =
  "GET_ALL_SDG_FILTER_ADMIN_SUCCESS";
export const GET_ALL_SDG_FILTER_ADMIN_FAIL = "GET_ALL_SDG_FILTER_ADMIN_FAIL";
export const GET_ALL_SDG_FILTER_ADMIN_RESET = "GET_ALL_SDG_FILTER_ADMIN_RESET";

export const PUT_ENTERPRISE_KYC_FORM_ADMIN_REQUEST =
  "PUT_ENTERPRISE_KYC_FORM_ADMIN_REQUEST";
export const PUT_ENTERPRISE_KYC_FORM_ADMIN_SUCCESS =
  "PUT_ENTERPRISE_KYC_FORM_ADMIN_SUCCESS";
export const PUT_ENTERPRISE_KYC_FORM_ADMIN_FAIL =
  "PUT_ENTERPRISE_KYC_FORM_ADMIN_FAIL";
export const PUT_ENTERPRISE_KYC_FORM_ADMIN_RESET =
  "PUT_ENTERPRISE_KYC_FORM_ADMIN_RESET";

export const PUT_INVESTOR_KYC_FORM_ADMIN_REQUEST =
  "PUT_INVESTOR_KYC_FORM_ADMIN_REQUEST";
export const PUT_INVESTOR_KYC_FORM_ADMIN_SUCCESS =
  "PUT_INVESTOR_KYC_FORM_ADMIN_SUCCESS";
export const PUT_INVESTOR_KYC_FORM_ADMIN_FAIL =
  "PUT_INVESTOR_KYC_FORM_ADMIN_FAIL";
export const PUT_INVESTOR_KYC_FORM_ADMIN_RESET =
  "PUT_INVESTOR_KYC_FORM_ADMIN_RESET";

import React, { useEffect } from "react";
import {
  Button,
  Grid,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Box,
} from "@mui/material";
import { Form, useForm } from "../../components/hooks/useForm";
import { useDispatch } from "react-redux";
import { putQuestionarieAction } from "./QuestionarieActions";
import LoadingComp from "../../components/LoadingComp";

const QuestionarieEditForm = ({
  setOpenEditPopup,
  loadingEditForm,
  singleQuestionarie,
}) => {
  const dispatch = useDispatch();
  const initialFormValues = {
    question: "",
    answer_type: "",
    objective_answer: "",
    member_type: "",
  };

  const { values, setValues, handleInputChange } = useForm(initialFormValues);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(putQuestionarieAction(values, singleQuestionarie?.unique_id));
    setOpenEditPopup(false);
  };

  useEffect(() => {
    if (singleQuestionarie) {
      setValues({ ...singleQuestionarie });
    }
  }, [singleQuestionarie, setValues]);

  return (
    <Form onSubmit={handleSubmit}>
      {loadingEditForm ? (
        <LoadingComp />
      ) : (
        <>
          <Grid container spacing={2} style={{ fontSize: "14px" }}>
            <Grid item xs={12}>
              <TextField
                name="question"
                label="Question"
                value={values.question}
                style={{ width: "100%" }}
                onChange={handleInputChange}
                required
              />
              &nbsp;
              <FormControl fullWidth>
                <InputLabel id="answer_type">Answer Type</InputLabel>
                <Select
                  labelId="answer_type"
                  id="answer_type"
                  value={values.answer_type}
                  label="Answer Type"
                  onChange={(e) =>
                    setValues((prev = {}) => ({
                      ...prev,
                      answer_type: e.target.value,
                    }))
                  }
                >
                  <MenuItem value={"Subjective"}>Subjective</MenuItem>
                  <MenuItem value={"Objective"}>Objective</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {values?.answer_type === "Objective" ? (
              <Box
                sx={{
                  ml: "5%",
                }}
              >
                &nbsp;
                <Grid container spacing={2} style={{ fontSize: "14px" }}>
                  <Grid item xs={6}>
                    <TextField
                      name="objective_answer"
                      label="Option A"
                      style={{ width: "100%" }}
                      value={values.objective_answer.A}
                      onChange={(e) =>
                        setValues((prev = {}) => ({
                          ...prev,
                          objective_answer: {
                            ...prev.objective_answer,
                            A: e.target.value,
                          },
                        }))
                      }
                      required
                    />
                    &nbsp;
                    <TextField
                      name="objective_answer"
                      label="Option C"
                      style={{ width: "100%" }}
                      value={values.objective_answer.C}
                      onChange={(e) =>
                        setValues((prev = {}) => ({
                          ...prev,

                          objective_answer: {
                            ...prev.objective_answer,
                            C: e.target.value,
                          },
                        }))
                      }
                      required
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      name="objective_answer"
                      label="Option B"
                      value={values.objective_answer.B}
                      style={{ width: "100%" }}
                      onChange={(e) =>
                        setValues((prev = {}) => ({
                          ...prev,
                          objective_answer: {
                            ...prev.objective_answer,
                            B: e.target.value,
                          },
                        }))
                      }
                      required
                    />{" "}
                    &nbsp;
                    <TextField
                      name="objective_answer"
                      label="Option D"
                      value={values.objective_answer.D}
                      style={{ width: "100%" }}
                      onChange={(e) =>
                        setValues((prev = {}) => ({
                          ...prev,
                          objective_answer: {
                            ...prev.objective_answer,
                            D: e.target.value,
                          },
                        }))
                      }
                      required
                    />
                  </Grid>
                </Grid>
              </Box>
            ) : (
              ""
            )}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="member-type">Member Type</InputLabel>
                <Select
                  labelId="member-type"
                  id="member-type"
                  value={values.member_type}
                  label="Member Type"
                  onChange={handleInputChange}
                >
                  <MenuItem value={"Enterprise"}>Enterprise</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              paddingTop: "10px",
              marginTop: "10px",
              borderTop: "1px solid #0b6885",
            }}
          >
            <Button
              variant="contained"
              onClick={() => setOpenEditPopup(false)}
              style={{ margin: "10px 0 0 10px", backgroundColor: "#ea3f4d" }}
            >
              CANCEL
            </Button>
            <Button
              variant="contained"
              type="submit"
              style={{ margin: "10px 0 0 10px", backgroundColor: "#0b6885" }}
            >
              SUBMIT
            </Button>
          </div>
        </>
      )}
    </Form>
  );
};

export default QuestionarieEditForm;

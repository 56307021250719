import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import ArrowButton from "../components/ArrowButton";
import { postChangePasswordAction } from "../private-routes/PrivateActions";
import { LoadingButton } from "@mui/lab";
import { toast, ToastContainer } from "react-toastify";
import DashboardSideMenu from "../components/DashboardSideMenu";

const MemberChangePassword = () => {
  const [loading, setLoading] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const initialFormValues = {
    old_password: oldPassword,
    new_password: newPassword,
  };

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChangePassword = (e) => {
    e.preventDefault();
    setLoading(true);

    if (oldPassword?.length === 0) {
      toast.error("Old Password is Required!");
      setLoading(false);
      return;
    }
    if (newPassword?.length === 0) {
      toast.error("New Password is Required");
      setLoading(false);
      return;
    }
    if (confirmPassword?.length === 0) {
      toast.error("Confirm Password is Required");
    }
    if (newPassword !== confirmPassword) {
      toast.error("Passwords do not match");
      setLoading(false);
      return;
    }
    dispatch(postChangePasswordAction(initialFormValues))
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.message);
          // Remove the session from localStorage
          localStorage.removeItem("memberAccessToken");
          localStorage.removeItem("memberRefreshToken");
          localStorage.removeItem("memberType");
          setTimeout(() => {
            navigate("/");
          }, 1200);
        } else {
          toast.error(res?.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        toast.error(error.message);
        setLoading(false);
      });
  };

  return (
    <>
      <div className="page-title">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h1>Dashboard</h1>
              <Link className="link" to="/">
                Home
              </Link>{" "}
              {""} /{""}
              <Link className="link" to="/dashboard">
                {" "}
                Dashboard
              </Link>{" "}
              {""} / Change Password
            </div>
          </div>
        </div>
      </div>
      <ArrowButton />

      <div className="container pt-5 pb-5 mb-5">
        <div className="row">
          <div className="col-md-3">
            <DashboardSideMenu />
          </div>
          <div className="col-md-9">
            <div className="dash-personal">
              <h3>Change Password</h3>
              <form onSubmit={handleChangePassword}>
                <div className="row">
                  <div className="col-md-12">
                    Current Password
                    <input
                      type="password"
                      fullwidth
                      id="old_password"
                      name="old_password"
                      onChange={(e) => setOldPassword(e.target.value)}
                      value={oldPassword}
                    />
                  </div>
                  <div className="col-md-6">
                    New Password
                    <input
                      type="password"
                      fullwidth
                      id="new_password"
                      name="new_password"
                      onChange={(e) => setNewPassword(e.target.value)}
                      value={newPassword}
                    />
                  </div>
                  <div className="col-md-6">
                    Confirm Password
                    <input
                      type="password"
                      fullwidth
                      id="confirmPassword"
                      name="confirmPassword"
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      value={confirmPassword}
                    />
                  </div>

                  <div className="offset-md-8 col-md-6">
                    <LoadingButton
                      loading={loading}
                      loadingPosition="end"
                      variant="contained"
                      style={{
                        marginTop: "2%",
                        marginLeft: "7%",
                        width: "57%",
                        backgroundColor: "#0b6885",
                        color: "#ffffff",
                      }}
                      type="submit"
                      value="Change Password"
                      className="submit-btn"
                    >
                      Change Password
                    </LoadingButton>
                    <ToastContainer
                      position="top-right"
                      autoClose={2000}
                      theme="colored"
                      hideProgressBar={false}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MemberChangePassword;

import {
  API_URL,
  membertokenConfig,
  axiosPrivate,
  membertokenConfigForm,
} from "../../api/axios";
import {
  GET_ENTERPRISE_BUSINESS_PLAN_DOCS_FAIL,
  GET_ENTERPRISE_BUSINESS_PLAN_DOCS_REQUEST,
  GET_ENTERPRISE_BUSINESS_PLAN_DOCS_SUCCESS,
  GET_ENTERPRISE_REGISTRATION_COMP_DOCS_FAIL,
  GET_ENTERPRISE_REGISTRATION_COMP_DOCS_REQUEST,
  GET_ENTERPRISE_REGISTRATION_COMP_DOCS_SUCCESS,
  GET_MEMBERS_PROFILES_FAIL,
  GET_MEMBERS_PROFILES_REQUEST,
  GET_MEMBERS_PROFILES_SUCCESS,
  GET_MOBILIZATION_PLAN_DOCS_FAIL,
  GET_MOBILIZATION_PLAN_DOCS_REQUEST,
  GET_MOBILIZATION_PLAN_DOCS_SUCCESS,
  GET_PAN_OR_VAT_DOCS_FAIL,
  GET_PAN_OR_VAT_DOCS_REQUEST,
  GET_PAN_OR_VAT_DOCS_SUCCESS,
  GET_REVENUE_PROFITABILITY_DOCS_FAIL,
  GET_REVENUE_PROFITABILITY_DOCS_REQUEST,
  GET_REVENUE_PROFITABILITY_DOCS_SUCCESS,
  GET_SINGLE_MEMBERS_PROFILE_FAIL,
  GET_SINGLE_MEMBERS_PROFILE_REQUEST,
  GET_SINGLE_MEMBERS_PROFILE_SUCCESS,
  POST_CREATE_ENTERPRISE_KYC_FAIL,
  POST_CREATE_ENTERPRISE_KYC_REQUEST,
  POST_CREATE_ENTERPRISE_KYC_SUCCESS,
  POST_CREATE_INVESTOR_KYC_FAIL,
  POST_CREATE_INVESTOR_KYC_REQUEST,
  POST_CREATE_INVESTOR_KYC_SUCCESS,
  POST_ENTERPRISE_BUSINESS_PLAN_DOC_FAIL,
  POST_ENTERPRISE_BUSINESS_PLAN_DOC_REQUEST,
  POST_ENTERPRISE_BUSINESS_PLAN_DOC_SUCCESS,
  POST_ENTERPRISE_REGISRATION_COMP_DOC_FAIL,
  POST_ENTERPRISE_REGISRATION_COMP_DOC_REQUEST,
  POST_ENTERPRISE_REGISRATION_COMP_DOC_SUCCESS,
  POST_MOBILIZATION_PLAN_DOCS_FAIL,
  POST_MOBILIZATION_PLAN_DOCS_REQUEST,
  POST_MOBILIZATION_PLAN_DOCS_SUCCESS,
  POST_PAN_OR_VAT_DOCS_FAIL,
  POST_PAN_OR_VAT_DOCS_REQUEST,
  POST_PAN_OR_VAT_DOCS_SUCCESS,
  POST_REVENUE_PROFITABILITY_DOCS_FAIL,
  POST_REVENUE_PROFITABILITY_DOCS_REQUEST,
  POST_REVENUE_PROFITABILITY_DOCS_SUCCESS,
} from "./KycConstants";

export const getMemberProfileSideAction = () => async (dispatch) => {
  try {
    dispatch({ type: GET_MEMBERS_PROFILES_REQUEST });

    const { data } = await axiosPrivate.get(
      `${API_URL}/api/v1/members/profiles`
    );

    dispatch({ type: GET_MEMBERS_PROFILES_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_MEMBERS_PROFILES_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

export const postCreateEnterpriseAction = (addKyc) => async (dispatch) => {
  try {
    dispatch({ type: POST_CREATE_ENTERPRISE_KYC_REQUEST });

    const jsonData = JSON.stringify(addKyc);

    const { data } = await axiosPrivate.post(
      `${API_URL}/api/v1/members/profiles/create_enterprise`,
      jsonData,
      membertokenConfig()
    );

    dispatch({ type: POST_CREATE_ENTERPRISE_KYC_SUCCESS, payload: data });
    return data;
  } catch (error) {
    dispatch({
      type: POST_CREATE_ENTERPRISE_KYC_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

export const getSingleMemberProfileSideAction =
  (member_profile_id) => async (dispatch) => {
    try {
      dispatch({ type: GET_SINGLE_MEMBERS_PROFILE_REQUEST });

      const { data } = await axiosPrivate.get(
        `${API_URL}/api/v1/members/profiles/${member_profile_id}`
      );

      dispatch({ type: GET_SINGLE_MEMBERS_PROFILE_SUCCESS, payload: data });

      return data;
    } catch (error) {
      dispatch({
        type: GET_SINGLE_MEMBERS_PROFILE_FAIL,
        payload: error.message ? error.message : error.Message,
      });
    }
  };

export const getEnterpriseBusinessPlanDocAction =
  (enterprise_kyc_id) => async (dispatch) => {
    try {
      dispatch({ type: GET_ENTERPRISE_BUSINESS_PLAN_DOCS_REQUEST });

      const { data } = await axiosPrivate.get(
        `${API_URL}/api/v1/members/profiles/enterprise_business_plan_docs/${enterprise_kyc_id}`
      );

      dispatch({
        type: GET_ENTERPRISE_BUSINESS_PLAN_DOCS_SUCCESS,
        payload: data,
      });

      return data;
    } catch (error) {
      dispatch({
        type: GET_ENTERPRISE_BUSINESS_PLAN_DOCS_FAIL,
        payload: error.message ? error.message : error.Message,
      });
    }
  };

export const getEnterpriseRegistrationCompDocAction =
  (enterprise_kyc_id) => async (dispatch) => {
    try {
      dispatch({ type: GET_ENTERPRISE_REGISTRATION_COMP_DOCS_REQUEST });

      const { data } = await axiosPrivate.get(
        `${API_URL}/api/v1/members/profiles/enterprise_registration_of_company_docs/${enterprise_kyc_id}`
      );

      dispatch({
        type: GET_ENTERPRISE_REGISTRATION_COMP_DOCS_SUCCESS,
        payload: data,
      });

      return data;
    } catch (error) {
      dispatch({
        type: GET_ENTERPRISE_REGISTRATION_COMP_DOCS_FAIL,
        payload: error.message ? error.message : error.Message,
      });
    }
  };

export const postCreateInvestorKycAction =
  (addInvestorKyc) => async (dispatch) => {
    try {
      dispatch({ type: POST_CREATE_INVESTOR_KYC_REQUEST });

      const jsonData = JSON.stringify(addInvestorKyc);

      const { data } = await axiosPrivate.post(
        `${API_URL}/api/v1/members/profiles/create_investor`,
        jsonData,
        membertokenConfig()
      );

      dispatch({ type: POST_CREATE_INVESTOR_KYC_SUCCESS, payload: data });
      return data;
    } catch (error) {
      dispatch({
        type: POST_CREATE_INVESTOR_KYC_FAIL,
        payload: error.message ? error.message : error.Message,
      });
    }
  };

export const postEnterpriseBusinessPlanAction =
  (businessDoc, enterprise_kyc_id) => async (dispatch) => {
    try {
      dispatch({ type: POST_ENTERPRISE_BUSINESS_PLAN_DOC_REQUEST });

      const businessData = new FormData();
      for (let i = 0; i < businessDoc.length; i++) {
        businessData.append(
          `enterprise_business_plan_doc[${i}]`,
          businessDoc[i]
        );
      }
      businessData.append("enterprise_kyc_id", enterprise_kyc_id);
      const { data } = await axiosPrivate.post(
        `${API_URL}/api/v1/members/profiles/upload_enterprise_business_plan_docs`,
        businessData
      );

      dispatch({
        type: POST_ENTERPRISE_BUSINESS_PLAN_DOC_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: POST_ENTERPRISE_BUSINESS_PLAN_DOC_FAIL,
        payload: error.message ? error.message : error.Message,
      });
    }
  };

export const postEnterpriseRegistrationCompAction =
  (company, enterprise_kyc_id) => async (dispatch) => {
    try {
      dispatch({ type: POST_ENTERPRISE_REGISRATION_COMP_DOC_REQUEST });

      const companyData = new FormData();
      for (let i = 0; i < company.length; i++) {
        companyData.append(
          `enterprise_registration_of_company_doc[${i}]`,
          company[i]
        );
      }

      companyData.append("enterprise_kyc_id", enterprise_kyc_id);

      const { data } = await axiosPrivate.post(
        `${API_URL}/api/v1/members/profiles/upload_enterprise_registration_of_company_docs`,
        companyData
      );

      dispatch({
        type: POST_ENTERPRISE_REGISRATION_COMP_DOC_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: POST_ENTERPRISE_REGISRATION_COMP_DOC_FAIL,
        payload: error.message ? error.message : error.Message,
      });
    }
  };

export const getEnterprisePanVatDocAction =
  (enterprise_kyc_id) => async (dispatch) => {
    try {
      dispatch({ type: GET_PAN_OR_VAT_DOCS_REQUEST });

      const { data } = await axiosPrivate.get(
        `${API_URL}/api/v1/members/profiles/enterprise_charter_docs/${enterprise_kyc_id}`
      );

      dispatch({
        type: GET_PAN_OR_VAT_DOCS_SUCCESS,
        payload: data,
      });

      return data;
    } catch (error) {
      dispatch({
        type: GET_PAN_OR_VAT_DOCS_FAIL,
        payload: error.message ? error.message : error.Message,
      });
    }
  };

export const postEnterprisePanOrVatDocAction =
  (panVat, enterprise_kyc_id) => async (dispatch) => {
    try {
      dispatch({ type: POST_PAN_OR_VAT_DOCS_REQUEST });

      const panVatData = new FormData();
      for (let i = 0; i < panVat.length; i++) {
        panVatData.append(`enterprise_charter_doc[${i}]`, panVat[i]);
      }

      panVatData.append("enterprise_kyc_id", enterprise_kyc_id);

      const { data } = await axiosPrivate.post(
        `${API_URL}/api/v1/members/profiles/upload_enterprise_charter_docs`,
        panVatData
      );

      dispatch({
        type: POST_PAN_OR_VAT_DOCS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: POST_PAN_OR_VAT_DOCS_FAIL,
        payload: error.message ? error.message : error.Message,
      });
    }
  };

export const getEnterpriseMobilizationPlanDocAction =
  (enterprise_kyc_id) => async (dispatch) => {
    try {
      dispatch({ type: GET_MOBILIZATION_PLAN_DOCS_REQUEST });

      const { data } = await axiosPrivate.get(
        `${API_URL}/api/v1/members/profiles/enterprise_mobilization_plan_docs/${enterprise_kyc_id}`
      );

      dispatch({
        type: GET_MOBILIZATION_PLAN_DOCS_SUCCESS,
        payload: data,
      });

      return data;
    } catch (error) {
      dispatch({
        type: GET_MOBILIZATION_PLAN_DOCS_FAIL,
        payload: error.message ? error.message : error.Message,
      });
    }
  };

export const postEnterpriseMobilizationPlanDocsAction =
  (mobilization, enterprise_kyc_id) => async (dispatch) => {
    try {
      dispatch({ type: POST_MOBILIZATION_PLAN_DOCS_REQUEST });

      const mobilizationData = new FormData();
      for (let i = 0; i < mobilization.length; i++) {
        mobilizationData.append(
          `enterprise_mobilization_plan_doc[${i}]`,
          mobilization[i]
        );
      }

      mobilizationData.append("enterprise_kyc_id", enterprise_kyc_id);

      const { data } = await axiosPrivate.post(
        `${API_URL}/api/v1/members/profiles/upload_enterprise_mobilization_plan_docs`,
        mobilizationData
      );

      dispatch({
        type: POST_MOBILIZATION_PLAN_DOCS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: POST_MOBILIZATION_PLAN_DOCS_FAIL,
        payload: error.message ? error.message : error.Message,
      });
    }
  };

export const getEnterpriseRevenueProfitabilityDocAction =
  (enterprise_kyc_id) => async (dispatch) => {
    try {
      dispatch({ type: GET_REVENUE_PROFITABILITY_DOCS_REQUEST });

      const { data } = await axiosPrivate.get(
        `${API_URL}/api/v1/members/profiles/enterprise_revenue_profitability_docs/${enterprise_kyc_id}`
      );

      dispatch({
        type: GET_REVENUE_PROFITABILITY_DOCS_SUCCESS,
        payload: data,
      });

      return data;
    } catch (error) {
      dispatch({
        type: GET_REVENUE_PROFITABILITY_DOCS_FAIL,
        payload: error.message ? error.message : error.Message,
      });
    }
  };

export const postEnterpriseRevenueProfitabilityDocsAction =
  (revenue, enterprise_kyc_id) => async (dispatch) => {
    try {
      dispatch({ type: POST_REVENUE_PROFITABILITY_DOCS_REQUEST });

      const revenueData = new FormData();
      for (let i = 0; i < revenue.length; i++) {
        revenueData.append(
          `enterprise_revenue_profitability_doc[${i}]`,
          revenue[i]
        );
      }

      revenueData.append("enterprise_kyc_id", enterprise_kyc_id);

      const { data } = await axiosPrivate.post(
        `${API_URL}/api/v1/members/profiles/upload_enterprise_revenue_profitability_docs`,
        revenueData
      );

      dispatch({
        type: POST_REVENUE_PROFITABILITY_DOCS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: POST_REVENUE_PROFITABILITY_DOCS_FAIL,
        payload: error.message ? error.message : error.Message,
      });
    }
  };

import React from "react";
import UserHeader from "../components/UserHeader";
import UserFooter from "../components/UserFooter";
import { Helmet } from "react-helmet";
import ArrowButton from "../components/ArrowButton";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect } from "react";

const OurProcess = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <UserHeader />
      <Helmet>
        <meta charset="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="description" content="" />
        <meta name="author" content="" />

        <title>Million Deals</title>
        {/* <!-- Linking the font Poppins--> */}
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />

        {/* <!-- Linking Bootstrap Icon--> */}
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css"
        />
      </Helmet>
      <ArrowButton />
      <div className="page-title">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h1>Our Process</h1>
              <Link className="link" to="/">
                Home
              </Link>
              {""} / Our Process
            </div>
          </div>
        </div>
      </div>

      <div className="about-process">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <h3 className="section-subtitle">How we work & Connect</h3>
            </div>

            <span style={{ marginLeft: "5px", textAlign: "justify" }}>
              Joining and engaging with our platform is an efficient and
              streamlined process for both Entrepreneurs and Investors. By
              joining our platform, you not only have access to the expertise
              and support of our team in your entrepreneurial and investment
              journeys, you can also engage with other stakeholders in the
              industry. By facilitating successful connections between
              entrepreneurs and investors, we are committed to driving economic
              growth and development.
            </span>
          </div>
        </div>
      </div>

      <div className="about-process-enterprise">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <h3 className="section-subtitle">I am an Entrepreneur</h3>
            </div>
            <TableContainer sx={{ maxHeight: "100%" }}>
              <Table
                stickyHeader
                aria-label="sticky table"
                className="table-style "
              >
                <TableHead style={{ height: "50px" }}>
                  <TableRow
                    sx={{
                      "& :hover": {
                        color: "white !important",
                      },
                      "& :focus:not(:focus-visible)": {
                        color: "white !important",
                      },
                    }}
                  >
                    <TableCell
                      style={{
                        backgroundColor: "#0b6885",
                        padding: "0.5vw",
                        letterSpacing: "0.5px",
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "white",
                      }}
                    >
                      Register on our Platform
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "#0b6885",
                        padding: "0.5vw",
                        letterSpacing: "0.5px",
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "white",
                      }}
                    >
                      Create your Deal
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "#0b6885",
                        padding: "0.5vw",
                        letterSpacing: "0.5px",
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "white",
                      }}
                    >
                      Get Verified
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "#0b6885",
                        padding: "0.5vw",
                        letterSpacing: "0.5px",
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "white",
                      }}
                    >
                      Engage with our Due Diligence Team
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "#0b6885",
                        padding: "0.5vw",
                        letterSpacing: "0.5px",
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "white",
                      }}
                    >
                      Get Listed and Ready to meet Investors
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "#0b6885",
                        padding: "0.5vw",
                        letterSpacing: "0.5px",
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "white",
                      }}
                    >
                      Access continual support from our team
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow style={{ backgroundColor: "#edf1f5" }}>
                    <TableCell>
                      Create an account on our platform, using your email, to
                      access a wealth of resources, support, and potential
                      investors. Upon registration, you will be directed to
                      create a deal
                    </TableCell>

                    <TableCell>
                      Once registered, create your deal by answering our
                      questionnaire to let us know more about your business,
                      team, and investment opportunity.
                    </TableCell>

                    <TableCell>
                      Our team will review your answers and conduct initial
                      checks to verify your company and to ensure your deal
                      meets our standards and is suitable for our network of
                      investors, before being listed on our platform.
                    </TableCell>

                    <TableCell>
                      Once your deal is verified we will contact you to discuss
                      specific details and to ensure that all aspects of your
                      business are accurately represented, and that potential
                      investors have all the information they need to make
                      informed investment decisions. Our Due Diligence team will
                      prepare a Due Diligence report together with you
                    </TableCell>

                    <TableCell>
                      Once your deal is verified and our Due Diligence team has
                      undergone initial engagement with you, your deal will be
                      listed on our platform, making it visible to our network
                      of investors. As investors show interest our team will
                      engage with you to support you in the deal making process.
                    </TableCell>

                    <TableCell>
                      Our team will be there to support you throughout the
                      investment process as well as to provide you additional
                      business development support and resources. Once you and
                      an investor decide to engage in exclusive deal discussion,
                      we will freeze your listing from our platform until a
                      final decision is made. If you and the investor decide to
                      engage in deal discussions without the engagement of our
                      team, we will ensure you have access to all relevant
                      information.
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>

      <div className="about-process-investor">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <h3 className="section-subtitle">I am an Investor</h3>
            </div>
            <TableContainer sx={{ maxHeight: "100%" }}>
              <Table
                stickyHeader
                aria-label="sticky table"
                className="table-style "
              >
                <TableHead style={{ height: "50px" }}>
                  <TableRow
                    sx={{
                      "& :hover": {
                        color: "white !important",
                      },
                      "& :focus:not(:focus-visible)": {
                        color: "white !important",
                      },
                    }}
                  >
                    <TableCell
                      style={{
                        backgroundColor: "#0b6885",
                        padding: "0.5vw",
                        letterSpacing: "0.5px",
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "white",
                      }}
                    >
                      Register on our Platform
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "#0b6885",
                        padding: "0.5vw",
                        letterSpacing: "0.5px",
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "white",
                      }}
                    >
                      Get Verified
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "#0b6885",
                        padding: "0.5vw",
                        letterSpacing: "0.5px",
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "white",
                      }}
                    >
                      Access Vetted Deals
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "#0b6885",
                        padding: "0.5vw",
                        letterSpacing: "0.5px",
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "white",
                      }}
                    >
                      Show interest in particular Deal(s)
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "#0b6885",
                        padding: "0.5vw",
                        letterSpacing: "0.5px",
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "white",
                      }}
                    >
                      Access detailed information
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "#0b6885",
                        padding: "0.5vw",
                        letterSpacing: "0.5px",
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "white",
                      }}
                    >
                      Start Investing
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow style={{ backgroundColor: "#edf1f5" }}>
                    <TableCell>
                      Create an account on our platform, using your email, to
                      access a wealth of investment opportunities, resources,
                      and support. Upon registration, you will be directed to a
                      short questionnaire.
                    </TableCell>

                    <TableCell>
                      Our team will review your answers and conduct initial
                      checks to verify your account and to ensure that investors
                      on our platform are seriously committed to supporting our
                      entrepreneurs.
                    </TableCell>

                    <TableCell>
                      Once your account is verified, you can access our network
                      of vetted deals, carefully selected by our due diligence
                      team to ensure they meet our high standards.
                    </TableCell>

                    <TableCell>
                      You can identify investment opportunities that align with
                      your investment criteria using our filters by sector,
                      geography, investment stage, ticket size, instrument etc.
                      You can show interest in those deals that align with your
                      criteria and receive more information.
                    </TableCell>

                    <TableCell>
                      Once you have shown interest in a particular deal our team
                      will reach out to you to ensure confidentiality, following
                      which you will initially have access to a detailed fact
                      sheet. Following the review of the fact sheet, you can
                      request for our thorough due diligence report which will
                      provide you with all the information you need to make an
                      informed investment decision.
                    </TableCell>

                    <TableCell>
                      Once you have reviewed the due diligence report, and made
                      the decision to invest, our team will be there to support
                      you in finalizing the deal. Once you and the entrepreneur
                      decide to engage in exclusive deal discussion, we will
                      freeze the specific deal listing from our platform until a
                      final decision is made. If you and the entrepreneur decide
                      to engage in deal discussions without the engagement of
                      our team, we will ensure you have access to all relevant
                      information.
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>

      <UserFooter />
    </>
  );
};

export default OurProcess;

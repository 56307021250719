import React, { useState } from "react";
import { Button, Grid, TextField } from "@mui/material";
import { Form } from "../../components/hooks/useForm";
import { useDispatch } from "react-redux";
import CheckBoxControl from "../../components/controls/CheckBoxControl";
import { postUserAction } from "./ManageUsersActions";
import { API_URL } from "../../api/axios";

const ManageUsersForm = ({ singleUser, setOpenAddPopup }) => {
  const [email_address, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [image, setImage] = useState(null);
  const [imgSrc, setImgSrc] = useState(null);
  const [mobile, setMobile] = useState("");
  const [isActive, setIsActive] = useState(0);
  const [isSuperAdmin, setIsSuperAdmin] = useState(0);

  const initialFormValues = {
    email_address: email_address,
    first_name: firstName,
    last_name: lastName,
    is_super_admin: isSuperAdmin,
    mobile: mobile,
    profile_image: image,
    status: isActive,
  };
  const dispatch = useDispatch();

  const handleImage = (event) => {
    let imageFile = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (x) => {
      setImgSrc(x.target.result);
    };
    reader.readAsDataURL(imageFile);
    setImage(event.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(postUserAction(initialFormValues, image));
    setOpenAddPopup(false);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container spacing={2} style={{ fontSize: "14px" }}>
        <Grid item xs={6}>
          <TextField
            name="first_name"
            label="First Name"
            style={{ width: "100%" }}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            name="last_name"
            label="Last Name"
            style={{ width: "100%" }}
            onWheel={(e) => e.target.blur()}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            type="email"
            name="email_address"
            label="Email Address"
            style={{ width: "100%" }}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            name="mobile"
            label="Phone Number"
            type="number"
            style={{ width: "100%" }}
            onWheel={(e) => e.target.blur()}
            onChange={(e) => setMobile(e.target.value)}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="advisor_image"
            style={{ width: "100%" }}
            onChange={(e) => handleImage(e)}
            type="file"
          />{" "}
          <br />
          <br />
          {imgSrc && imgSrc ? (
            <img
              alt=""
              src={
                imgSrc
                  ? imgSrc
                  : singleUser &&
                    `${API_URL}/uploads/admin/profile_images/${singleUser?.profile_image}`
              }
              height={150}
              width={150}
            />
          ) : (
            <span style={{ color: "red" }}>*Choose Image</span>
          )}
        </Grid>
        <Grid item xs={6}>
          <CheckBoxControl
            name="status"
            label="Active"
            onChange={(e) => setIsActive(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <CheckBoxControl
            name="is_super_admin"
            label="Super Admin"
            onChange={(e) => setIsSuperAdmin(e.target.value)}
          />
        </Grid>
      </Grid>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          paddingTop: "10px",
          marginTop: "10px",
          borderTop: "1px solid #0b6885",
        }}
      >
        <Button
          variant="contained"
          onClick={() => setOpenAddPopup(false)}
          style={{ margin: "10px 0 0 10px", backgroundColor: "#ea3f4d" }}
        >
          CANCEL
        </Button>
        <Button
          variant="contained"
          type="submit"
          style={{ margin: "10px 0 0 10px", backgroundColor: "#0b6885" }}
        >
          SUBMIT
        </Button>
      </div>
    </Form>
  );
};

export default ManageUsersForm;

import React, { useEffect } from "react";
import CustomContainer from "../../../components/CustomContainer";
import Topbar from "../../../components/Topbar";
import { useDispatch, useSelector } from "react-redux";
import {
  Toolbar,
  Button,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import BackspaceIcon from "@mui/icons-material/Backspace";
import { putMemberVerifyAction } from "./AllMembersAction";
import LoadingComp from "../../../components/LoadingComp";
import { useState } from "react";
import { getAllSdgsFilterAdminAction } from "./AllMembersAction";
import { getCategoryListAdminAdminAction } from "./AllMembersAction";
import Popup from "../../../components/Popup";
import UpdateInvestorKYCFormAdmin from "./UpdateInvestorKYCFormAdmin";

const amountRanges = [
  { amount: "1", min: 100000, max: 500000, label: "1 to 5 Lakhs" },
  { amount: "2", min: 500000, max: 1000000, label: "5 to 10 Lakhs" },
  { amount: "3", min: 1000000, max: 3000000, label: "10 to 30 Lakhs" },
  { amount: "4", min: 3000000, max: 5000000, label: "30 to 50 Lakhs" },
  { amount: "5", min: 5000000, max: 10000000, label: "50 to 1 Crore" },
  { amount: "6", min: 10000000, max: 50000000, label: "1 to 5 Crore" },
  { amount: "7", min: 50000000, max: 100000000, label: "5 to 10 Crore" },
  { amount: "8", min: 100000000, max: Infinity, label: "10 Crore And Above" },
];

const InvestorKYCProfile = () => {
  const [investorValue, setInvestorValue] = useState(
    JSON.parse(localStorage.getItem("kycValues")) || {}
  );
  const [buttonValue, setButtonValue] = useState(
    JSON.parse(localStorage.getItem("buttonValues")) || {}
  );

  const [sectorList, setSectorList] = useState([]);
  const [sdgList, setSdgList] = useState([]);
  const [openInvestorKycPopup, setOpenInvestorKycPopup] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { singleMemberProfile, loading } = useSelector(
    (state) => state.getSingleMemberProfileList
  );

  const { sdgListAdmin } = useSelector((state) => state.getSdgListAdmin);

  const { categoryListAdmin } = useSelector(
    (state) => state.getCategoryListAdmin
  );

  useEffect(() => {
    if (!sdgListAdmin) {
      dispatch(getAllSdgsFilterAdminAction());
    }
    if (sdgListAdmin) {
      setSdgList(sdgListAdmin?.data);
    }
  }, [sdgListAdmin, dispatch]);

  useEffect(() => {
    if (!categoryListAdmin) {
      dispatch(getCategoryListAdminAdminAction());
    }
    if (categoryListAdmin) {
      setSectorList(categoryListAdmin?.data);
    }
  }, [categoryListAdmin, dispatch]);

  useEffect(() => {
    dispatch(getAllSdgsFilterAdminAction());
    dispatch(getCategoryListAdminAdminAction());
  }, [dispatch]);

  useEffect(() => {
    localStorage.setItem("kycValues", JSON.stringify(investorValue));
  }, [investorValue]);

  useEffect(() => {
    localStorage.setItem("buttonValues", JSON.stringify(buttonValue));
  }, [buttonValue]);

  useEffect(() => {
    if (singleMemberProfile) {
      setInvestorValue(singleMemberProfile?.data?.investor_kyc);
    }
    if (singleMemberProfile) {
      setButtonValue(singleMemberProfile?.data);
    }
  }, [singleMemberProfile]);

  const sdgNames = sdgList
    ?.filter((sdg) => investorValue?.sdgs?.includes(sdg.sdg_id))
    ?.map((sdg) => <Chip className="me-1 mb-2" label={sdg?.sdg_name} />);

  const sectorName = sectorList
    ?.filter((sector) =>
      investorValue?.invest_in?.includes(sector.deal_category_id)
    )
    ?.map((sector) => (
      <Chip className="me-1 mb-2" label={sector.deal_cat_name} />
    ));

  const amountName = amountRanges
    ?.filter((amount) =>
      investorValue?.investment_size?.includes(amount.amount)
    )
    ?.map((amount) => <Chip className="me-1 mb-2" label={amount.label} />);

  const backSpaceHandler = (e) => {
    e.preventDefault();
    navigate("/admin/members-list/profile");
  };

  const acceptKycProfile = () => {
    if (
      dispatch(
        putMemberVerifyAction(
          { is_verified: 1 },
          singleMemberProfile?.data?.member_profile_id
        )
      )
    ) {
      navigate("/admin/members-list");
    }
  };

  const rejectKycProfile = () => {
    if (
      dispatch(
        putMemberVerifyAction(
          { is_verified: 2 },
          singleMemberProfile?.data?.member_profile_id
        )
      )
    ) {
      navigate("/admin/members-list");
    }
  };

  const handlePrint = () => {
    var divToPrint = document.getElementById("printTable");
    var newWin = window.open("");
    newWin.document.write(divToPrint.outerHTML);
    newWin.print();
    newWin.close();
  };

  const handleUpdateKyc = (e) => {
    e.preventDefault();
    setOpenInvestorKycPopup(true);
  };

  return (
    <>
      <CustomContainer>
        <Toolbar>
          <Topbar subtitle="Investor KYC Detail" />{" "}
          <BackspaceIcon
            onClick={backSpaceHandler}
            style={{
              color: "#0b6885",
              marginBottom: "12px",
              cursor: "pointer",
              fontSize: "26px",
            }}
          />{" "}
        </Toolbar>
        {loading ? (
          <LoadingComp />
        ) : (
          <>
            <div id="printTable">
              <TableContainer component={Paper} sx={{ maxHeight: "100%" }}>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  className="table-style "
                >
                  <TableHead style={{ height: "50px" }}>
                    <TableRow
                      sx={{
                        "& :hover": {
                          color: "white !important",
                        },
                        "& :focus:not(:focus-visible)": {
                          color: "white !important",
                        },
                      }}
                    >
                      <TableCell
                        style={{
                          backgroundColor: "#0b6885",
                          padding: "0.5vw",
                          letterSpacing: "0.5px",
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "white",
                        }}
                      >
                        TITLE
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          backgroundColor: "#0b6885",
                          padding: "0.5vw",
                          letterSpacing: "0.5px",
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "white",
                        }}
                      >
                        DESCRIPTION
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        component="th"
                        style={{ fontWeight: "600" }}
                        scope="row"
                      >
                        Full Name
                      </TableCell>
                      <TableCell align="left">{investorValue.name}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        style={{ fontWeight: "600" }}
                        scope="row"
                      >
                        Contact Number
                      </TableCell>
                      <TableCell align="left">{investorValue.phone}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        style={{ fontWeight: "600" }}
                        scope="row"
                      >
                        Email Address
                      </TableCell>
                      <TableCell align="left">{investorValue.email}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        style={{ fontWeight: "600" }}
                        scope="row"
                      >
                        Linkedin profile or other online profile
                      </TableCell>
                      <TableCell align="left">
                        {investorValue.linkin_or_online_profile}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        style={{ fontWeight: "600" }}
                        scope="row"
                      >
                        Contact Address
                      </TableCell>
                      <TableCell align="left">
                        {investorValue.contact_address}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        style={{ fontWeight: "600" }}
                        scope="row"
                      >
                        Brief information about their profile and background
                      </TableCell>
                      <TableCell align="left">{investorValue.bio}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        style={{ fontWeight: "600" }}
                        scope="row"
                      >
                        Individual Investor or Institutional Investor
                      </TableCell>
                      <TableCell align="left">
                        {investorValue.ind_or_ins_investor}
                      </TableCell>
                    </TableRow>
                    {investorValue.ind_or_ins_investor === "Other" && (
                      <>
                        <TableRow>
                          <TableCell
                            component="th"
                            style={{ fontWeight: "600" }}
                            scope="row"
                          >
                            Please describe if any others
                          </TableCell>
                          <TableCell align="left">
                            {investorValue.ind_or_ins_investor_other}
                          </TableCell>
                        </TableRow>
                      </>
                    )}

                    <TableRow>
                      <TableCell
                        component="th"
                        style={{ fontWeight: "600" }}
                        scope="row"
                      >
                        Role in company
                      </TableCell>
                      <TableCell align="left">
                        {investorValue.company_role}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        style={{ fontWeight: "600" }}
                        scope="row"
                      >
                        Company or Organization Associated with
                      </TableCell>
                      <TableCell align="left">
                        {investorValue.company_name}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        style={{ fontWeight: "600" }}
                        scope="row"
                      >
                        Company website or LinkedIn Page link
                      </TableCell>
                      <TableCell align="left">
                        {investorValue.company_website}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        style={{ fontWeight: "600" }}
                        scope="row"
                      >
                        Brief about company's profile and background
                      </TableCell>
                      <TableCell align="left">
                        {investorValue.company_bio}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        style={{ fontWeight: "600" }}
                        scope="row"
                      >
                        Which of the following describes you the best?
                      </TableCell>
                      <TableCell align="left">
                        {investorValue.describes_your_best}
                      </TableCell>
                    </TableRow>
                    {investorValue.describes_your_best ===
                      "Other Financial Intermediary" && (
                      <>
                        <TableRow>
                          <TableCell
                            component="th"
                            style={{ fontWeight: "600" }}
                            scope="row"
                          >
                            Please write what describe you the best if others
                          </TableCell>
                          <TableCell align="left">
                            {investorValue.describes_your_best_other}
                          </TableCell>
                        </TableRow>
                      </>
                    )}

                    <TableRow>
                      <TableCell
                        component="th"
                        style={{ fontWeight: "600" }}
                        scope="row"
                      >
                        Preferred Investment Size
                      </TableCell>
                      <TableCell align="left">{amountName}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        style={{ fontWeight: "600" }}
                        scope="row"
                      >
                        Preferred Sector to Invest
                      </TableCell>
                      <TableCell align="left">{sectorName}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        style={{ fontWeight: "600" }}
                        scope="row"
                      >
                        Wish to have a sustainability or impact focus in
                        investments
                      </TableCell>
                      <TableCell align="left">
                        {investorValue.is_sustainability === "1" ? "Yes" : "No"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        style={{ fontWeight: "600" }}
                        scope="row"
                      >
                        SDGS contribution through investments
                      </TableCell>
                      <TableCell align="left">
                        {investorValue.is_sustainability === "1"
                          ? sdgNames
                          : "N/A"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        style={{ fontWeight: "600" }}
                        scope="row"
                      >
                        Preferred stage of investment
                      </TableCell>
                      <TableCell align="left">
                        {investorValue.stage_of_investment === "1"
                          ? "Idea Stage"
                          : ""}
                        {investorValue.stage_of_investment === "2"
                          ? "Growth Stage"
                          : ""}
                        {investorValue.stage_of_investment === "3"
                          ? "Neutral"
                          : ""}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        style={{ fontWeight: "600" }}
                        scope="row"
                      >
                        Terms Confirmation
                      </TableCell>
                      <TableCell align="left">
                        {investorValue.terms_confirmation ? "Yes" : "No"}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                paddingTop: "5px",
                marginTop: "5px",
              }}
            >
              {buttonValue?.is_verified === 1 ? (
                <>
                  <Button
                    variant="contained"
                    style={{
                      margin: "10px 0 0 10px",
                      backgroundColor: "#2a80f9",
                    }}
                    onClick={handleUpdateKyc}
                  >
                    Update KYC Form
                  </Button>
                  <Button
                    disabled
                    variant="contained"
                    type="submit"
                    style={{
                      margin: "10px 0 0 10px",
                      backgroundColor: "#0b6885",
                      color: "#ffffff",
                    }}
                  >
                    Verified
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      margin: "10px 0 0 10px",
                      backgroundColor: "#ed5e5e",
                    }}
                    onClick={handlePrint}
                  >
                    Print
                  </Button>
                </>
              ) : (
                <>
                  {buttonValue?.is_verified === 2 ? (
                    <Button
                      disabled
                      variant="contained"
                      type="submit"
                      style={{
                        margin: "10px 0 0 10px",
                        backgroundColor: "#ed5e5e",
                        color: "#ffffff",
                      }}
                    >
                      Rejected
                    </Button>
                  ) : (
                    <>
                      <Button
                        variant="contained"
                        style={{
                          margin: "10px 0 0 10px",
                          backgroundColor: "#ed5e5e",
                        }}
                        onClick={handlePrint}
                      >
                        Print
                      </Button>{" "}
                      <Button
                        variant="contained"
                        onClick={rejectKycProfile}
                        style={{
                          margin: "10px 0 0 10px",
                          backgroundColor: "#ea3f4d",
                          color: "#ffffff",
                        }}
                      >
                        Reject
                      </Button>
                      <Button
                        variant="contained"
                        type="submit"
                        onClick={acceptKycProfile}
                        style={{
                          margin: "10px 0 0 10px",
                          backgroundColor: "#0b6885",
                          color: "#ffffff",
                        }}
                      >
                        Accept
                      </Button>
                    </>
                  )}
                </>
              )}
            </div>
          </>
        )}
      </CustomContainer>
      <Popup
        openPopup={openInvestorKycPopup}
        setOpenPopup={setOpenInvestorKycPopup}
        title="Update Member's KYC Form"
      >
        <UpdateInvestorKYCFormAdmin
          formData={investorValue}
          setFormData={setInvestorValue}
          sectorList={sectorList}
          sdgList={sdgList}
          setOpenInvestorKycPopup={setOpenInvestorKycPopup}
        />
      </Popup>
    </>
  );
};
export default InvestorKYCProfile;

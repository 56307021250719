import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import React from "react";

const Notification = ({ notify, setNotify }) => {
  const handleClose = (e, reason) => {
    setNotify({ ...notify, isOpen: false });
  };

  // Check if notify.type is a valid value, otherwise set it to "info"
  const severity = ["error", "info", "success", "warning"].includes(notify.type)
    ? notify.type
    : "info";

  return (
    <Snackbar
      open={notify.isOpen}
      autoHideDuration={3000}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      onClose={handleClose}
    >
      <Alert severity={severity} onClose={handleClose}>
        {notify.message}
      </Alert>
    </Snackbar>
  );
};

export default Notification;

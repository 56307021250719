import React, { useState, useEffect } from "react";
import CustomContainer from "../../../components/CustomContainer";
import Topbar from "../../../components/Topbar";
import { useDispatch, useSelector } from "react-redux";
import useCustomTable from "../../../components/hooks/useCustomTable";
import {
  Toolbar,
  Button,
  Grid,
  TextField,
  List,
  ListItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import BackspaceIcon from "@mui/icons-material/Backspace";
import { toast, ToastContainer } from "react-toastify";
import { useForm } from "../../../components/hooks/useForm";

const ServiceProviderKYCProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const backSpaceHandler = (e) => {
    e.preventDefault();
    navigate("/admin/members-list/profile");
  };

  return (
    <CustomContainer>
      <Toolbar>
        <Topbar subtitle="Service-Provider KYC" />{" "}
        <BackspaceIcon
          onClick={backSpaceHandler}
          style={{
            color: "#49c1a1",
            marginBottom: "12px",
            cursor: "pointer",
            fontSize: "26px",
          }}
        />{" "}
      </Toolbar>
      <Grid container>
        <Grid md={4}>
          <List>
            <ListItem>
              <strong>company_name</strong>:
            </ListItem>
            <ListItem>
              <strong>is_founder_or_cofounder</strong>:
            </ListItem>
            <ListItem>
              <strong>founder_or_cofounder_name</strong>:
            </ListItem>
            <ListItem>
              <strong>company_email</strong>:
            </ListItem>
            <ListItem>
              <strong>designation</strong>:
            </ListItem>
            <ListItem>
              <strong>contact_number</strong>:
            </ListItem>
            <ListItem>
              <strong>established_date</strong>:
            </ListItem>
            <ListItem>
              <strong>company_website_or_social</strong>:
            </ListItem>
            <ListItem>
              <strong>registered_address</strong>:
            </ListItem>
            <ListItem>
              <strong>no_of_founders</strong>:
            </ListItem>
            <ListItem>
              <strong>structure_of_enterprise</strong>:
            </ListItem>
            <ListItem>
              <strong>pan_or_vat</strong>:
            </ListItem>
          </List>
        </Grid>
        <Grid md={4}>
          <List>
            <ListItem>
              <strong>profile_of_founder_or_cofounder</strong>:{" "}
            </ListItem>
            <ListItem>
              <strong>no_of_employees</strong>:{" "}
            </ListItem>
            <ListItem>
              <strong>sector</strong>:{" "}
            </ListItem>
            <ListItem>
              <strong>sector_description</strong>:
            </ListItem>
            <ListItem>
              <strong>about_company</strong>:
            </ListItem>
            <ListItem>
              <strong>target_product_description</strong>:
            </ListItem>
            <ListItem>
              <strong>headquarters</strong>:
            </ListItem>
            <ListItem>
              <strong>company_operate</strong>:
            </ListItem>
            <ListItem>
              <strong>create_positive_impact</strong>:
            </ListItem>
            <ListItem>
              <strong>sdg</strong>:
            </ListItem>
            <ListItem>
              <strong>mitigates_risks</strong>:
            </ListItem>
            <ListItem>
              <strong>about_stakeholders</strong>:
            </ListItem>
          </List>
        </Grid>
        <Grid md={4}>
          <List>
            <ListItem>
              <strong>total_investment</strong>:
            </ListItem>
            <ListItem>
              <strong>current_financial</strong>:
            </ListItem>
            <ListItem>
              <strong>amount_of_investment</strong>:
            </ListItem>
            <ListItem>
              <strong>amount_or_percentage_breakdown</strong>:
            </ListItem>
            <ListItem>
              <strong>mode_of_funding</strong>:
            </ListItem>
            <ListItem>
              <strong>mode_of_funding_others</strong>:
            </ListItem>
            <ListItem>
              <strong>non_financial_support</strong>:
            </ListItem>
            <ListItem>
              <strong>registraton_of_company_doc_id</strong>:
            </ListItem>
            <ListItem>
              <strong>upload_business_plan_doc_id</strong>:
            </ListItem>
            <ListItem>
              <strong>terms_confirmation</strong>:
            </ListItem>
          </List>
        </Grid>
      </Grid>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          paddingTop: "5px",
          marginTop: "5px",
          borderTop: "1px solid #0b6885",
        }}
      >
        <Button
          variant="contained"
          // onClick={() => setOpenAddPopup(false)}
          style={{ margin: "10px 0 0 10px", backgroundColor: "#ea3f4d" }}
        >
          Reject
        </Button>
        <Button
          variant="contained"
          type="submit"
          style={{ margin: "10px 0 0 10px", backgroundColor: "#70d8bd" }}
        >
          Verify
        </Button>
      </div>
    </CustomContainer>
  );
};
export default ServiceProviderKYCProfile;

import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import React from "react";
import { Clear } from "@mui/icons-material";

const Popup = ({ title, children, openPopup, setOpenPopup }) => {
  return (
    <Dialog disableEnforceFocus maxWidth="lg" open={openPopup}>
      <DialogTitle>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <h5 style={{ flexGrow: 1, margin: "10px 0" }}>{title}</h5>{" "}
          <Button
            style={{
              marginRight: "1px",
              padding: "5px",
              minWidth: "10px",
              fontSize: "10px",
              height: "20px",
              backgroundColor: "#0b6885",
              color: "secondary",
            }}
            variant="contained"
            onClick={() => setOpenPopup(false)}
          >
            <Clear fontSize="small" />
          </Button>
        </div>
      </DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
    </Dialog>
  );
};

export default Popup;

import axios from "axios";
import {
  getAccessToken,
  getAdminUserType,
  getMemberAccessToken,
  getMemberRefreshToken,
  getRefreshToken,
  getUserType,
  setAccessToken,
  setMemberAccessToken,
  setRefreshToken,
} from "../services/storage";
import { setMemberRefreshToken } from "../services/storage";

//export const API_URL = "http://localhost:5001";

export const API_URL = "https://mdapi.icn.com.np";

export const USER_SESSION = localStorage.getItem("accessToken");

export const MEMBER_SESSION = localStorage.getItem("memberAccessToken");

export const tokenConfig = () => {
  return {
    headers: {
      "Content-Type": "application/json ",
    },
  };
};

export const tokenConfigForm = () => {
  return {
    headers: {
      authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  };
};

export const axiosPublic = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const membertokenConfig = () => {
  return {
    headers: {
      "Content-Type": "application/json ",
    },
  };
};

export const membertokenConfigForm = () => {
  return {
    headers: {
      authorization: `Bearer ${localStorage.getItem("memberAccessToken")}`,
    },
  };
};

const getAuthAdminToken = () => {
  const adminMemberType = getAdminUserType();

  let token = "";

  if (!adminMemberType || !["admin"].includes(adminMemberType)) {
    return "";
  }

  if (adminMemberType === "admin") {
    token = getAccessToken();
  }

  return `Bearer ${token}`;
};

//AdminSide Interceptors
export const axiosAdminPrivate = axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

axiosAdminPrivate.interceptors.request.use(
  (config) => {
    // Modify the request config here
    config.headers["authorization"] = getAuthAdminToken();
    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

let unauthorizedAdminRequestQueue = [];
let isAdminRefreshingAccessToken = false;

/**
 * Initialize the unauthorized response interceptors.
 */
axiosAdminPrivate.interceptors.response.use(
  (response) => response,
  /**
   * This interceptor checks if the response had a 401 status code, which means
   * that the access token used for the request has expired. It then refreshes
   * the access token and resends the original request.
   */
  unauthorizedAdminResponseHandlerInterceptor
);

export async function unauthorizedAdminResponseHandlerInterceptor(err) {
  const originalRequest = err.config;
  const code = err.response && err.response.status;
  const path = originalRequest.url;
  const REFRESH_TOKEN_ADMIN_URL = `${API_URL}/api/v1/admin/auth/refresh`;

  const refreshToken = getRefreshToken();

  if (
    !refreshToken ||
    ([403, 401].includes(code) &&
      originalRequest.url === REFRESH_TOKEN_ADMIN_URL)
  ) {
    // authService.redirectToLogin();
    // localStorage clean -> accessToken,refershToken and memberType clear out
    // redirect -> /admin/login
    // window.location
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("adminMemberType");
    window.location("/admin/login");

    return Promise.reject(err);
  }

  if ([403, 401].includes(code) && path !== REFRESH_TOKEN_ADMIN_URL) {
    if (isAdminRefreshingAccessToken) {
      return subscribeToAdminAccessTokenRefresh(originalRequest);
    }

    try {
      isAdminRefreshingAccessToken = true;

      // const refreshedAccessToken = referesh token ko api call garne yeta
      // refersh token ra accessToken set garne localstorage pheri
      const { data } = await axios.post(REFRESH_TOKEN_ADMIN_URL, {
        token: localStorage.getItem("refreshToken"),
      });

      const { refreshToken, accessToken } = data.tokens;

      setAccessToken(accessToken);
      setRefreshToken(refreshToken);

      const newRequest = updateAdminAccessToken(originalRequest, accessToken);

      callRequestsFromUnauthorizedAdminQueue(accessToken);
      clearUnauthorizedRequestAdminQueue();

      isAdminRefreshingAccessToken = false;

      return axiosAdminPrivate.request(newRequest);
    } catch (error) {
      // redirect -> /admin/login
      // window.location
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("adminMemberType");
      window.location("/admin/login");
    }
  }

  throw err;
}

/**
 * Changes access token of the provided request.
 *
 * @param {Object} originalRequest
 * @param {Object} newToken
 */
function updateAdminAccessToken(originalRequest, newToken) {
  return {
    ...originalRequest,
    headers: {
      ...originalRequest.headers,
      authorization: `Bearer ${newToken}`,
    },
  };
}

/**
 * Subscribe retry request to access token refresh.
 * Add request to unauthorized request queue.
 *
 * @param {Object} originalRequest
 */
function subscribeToAdminAccessTokenRefresh(originalRequest) {
  return new Promise((resolve) => {
    unauthorizedAdminRequestQueue.push(function (refreshedAccessToken) {
      const newRequest = updateAccessToken(
        originalRequest,
        refreshedAccessToken
      );

      resolve(axiosAdminPrivate.request(newRequest));
    });
  });
}

/**
 * Clear unauthorized request queue.
 */
function clearUnauthorizedRequestAdminQueue() {
  unauthorizedAdminRequestQueue = [];
}

/**
 * Call pending requests from unauthorized request queue.
 *
 * @param {String} refreshedAccessToken
 */
function callRequestsFromUnauthorizedAdminQueue(refreshedAccessToken) {
  unauthorizedAdminRequestQueue.map((cb) => cb(refreshedAccessToken));
}

const getAuthMemberToken = () => {
  const userType = getUserType();

  let token = "";

  if (!userType || !["member"].includes(userType)) {
    return "";
  }

  if (userType === "member") {
    token = getMemberAccessToken();
  }

  return `Bearer ${token}`;
};

//MemberSide Interceptors
export const axiosPrivate = axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

axiosPrivate.interceptors.request.use(
  (config) => {
    // Modify the request config here
    config.headers["authorization"] = getAuthMemberToken();
    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

let unauthorizedRequestQueue = [];
let isRefreshingAccessToken = false;

/**
 * Initialize the unauthorized response interceptors.
 */
axiosPrivate.interceptors.response.use(
  (response) => response,
  /**
   * This interceptor checks if the response had a 401 status code, which means
   * that the access token used for the request has expired. It then refreshes
   * the access token and resends the original request.
   */
  unauthorizedResponseHandlerInterceptor
);

export async function unauthorizedResponseHandlerInterceptor(err) {
  const originalRequest = err.config;
  const code = err.response && err.response.status;
  const path = originalRequest.url;
  const REFRESH_TOKEN_URL = `${API_URL}/api/v1/members/auth/refresh`;

  const refreshToken = getMemberRefreshToken();

  if (
    !refreshToken ||
    ([403, 401].includes(code) && originalRequest.url === REFRESH_TOKEN_URL)
  ) {
    // authService.redirectToLogin();
    // localStorage clean -> accessToken,refershToken and memberType clear out
    // redirect -> /login
    // window.location
    localStorage.removeItem("memberAccessToken");
    localStorage.removeItem("memberRefreshToken");
    localStorage.removeItem("memberType");
    window.location("/login");

    return Promise.reject(err);
  }

  if ([403, 401].includes(code) && path !== REFRESH_TOKEN_URL) {
    if (isRefreshingAccessToken) {
      return subscribeToAccessTokenRefresh(originalRequest);
    }

    try {
      isRefreshingAccessToken = true;

      // const refreshedAccessToken = referesh token ko api call garne yeta
      // refersh token ra accessToken set garne localstorage pheri
      const { data } = await axios.post(REFRESH_TOKEN_URL, {
        token: localStorage.getItem("memberRefreshToken"),
      });

      const { refreshToken, accessToken } = data.tokens;

      setMemberAccessToken(accessToken);
      setMemberRefreshToken(refreshToken);

      const newRequest = updateAccessToken(originalRequest, accessToken);

      callRequestsFromUnauthorizedQueue(accessToken);
      clearUnauthorizedRequestQueue();

      isRefreshingAccessToken = false;

      return axiosPrivate.request(newRequest);
    } catch (error) {
      // redirect -> /login
      // window.location
      localStorage.removeItem("memberAccessToken");
      localStorage.removeItem("memberRefreshToken");
      localStorage.removeItem("memberType");
      window.location("/");
    }
  }

  throw err;
}

/**
 * Changes access token of the provided request.
 *
 * @param {Object} originalRequest
 * @param {Object} newToken
 */
function updateAccessToken(originalRequest, newToken) {
  return {
    ...originalRequest,
    headers: {
      ...originalRequest.headers,
      authorization: `Bearer ${newToken}`,
    },
  };
}

/**
 * Subscribe retry request to access token refresh.
 * Add request to unauthorized request queue.
 *
 * @param {Object} originalRequest
 */
function subscribeToAccessTokenRefresh(originalRequest) {
  return new Promise((resolve) => {
    unauthorizedRequestQueue.push(function (refreshedAccessToken) {
      const newRequest = updateAccessToken(
        originalRequest,
        refreshedAccessToken
      );

      resolve(axiosPrivate.request(newRequest));
    });
  });
}

/**
 * Clear unauthorized request queue.
 */
function clearUnauthorizedRequestQueue() {
  unauthorizedRequestQueue = [];
}

/**
 * Call pending requests from unauthorized request queue.
 *
 * @param {String} refreshedAccessToken
 */
function callRequestsFromUnauthorizedQueue(refreshedAccessToken) {
  unauthorizedRequestQueue.map((cb) => cb(refreshedAccessToken));
}

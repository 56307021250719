import {
  DELETE_NEWS_FAIL,
  DELETE_NEWS_REQUEST,
  DELETE_NEWS_RESET,
  DELETE_NEWS_SUCCESS,
  GET_ALL_NEWS_FAIL,
  GET_ALL_NEWS_REQUEST,
  GET_ALL_NEWS_RESET,
  GET_ALL_NEWS_SUCCESS,
  GET_SINGLE_NEWS_FAIL,
  GET_SINGLE_NEWS_REQUEST,
  GET_SINGLE_NEWS_RESET,
  GET_SINGLE_NEWS_SUCCESS,
  POST_NEWS_FAIL,
  POST_NEWS_REQUEST,
  POST_NEWS_RESET,
  POST_NEWS_SUCCESS,
  PUT_NEWS_FAIL,
  PUT_NEWS_REQUEST,
  PUT_NEWS_RESET,
  PUT_NEWS_SUCCESS,
} from "./NewsConstants";

export const getAllNewsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_NEWS_REQUEST:
      return { loading: true };
    case GET_ALL_NEWS_SUCCESS:
      return { loading: false, allNews: action.payload };
    case GET_ALL_NEWS_FAIL:
      return { loading: false, error: action.payload };
    case GET_ALL_NEWS_RESET:
      return {};
    default:
      return state;
  }
};

export const getSingleNewsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_SINGLE_NEWS_REQUEST:
      return { loading: true };
    case GET_SINGLE_NEWS_SUCCESS:
      return { loading: false, singleNews: action.payload };
    case GET_SINGLE_NEWS_FAIL:
      return { loading: false, error: action.payload };
    case GET_SINGLE_NEWS_RESET:
      return {};
    default:
      return state;
  }
};

export const postNewsReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_NEWS_REQUEST:
      return { loading: true };
    case POST_NEWS_SUCCESS:
      return { loading: false, success: true };
    case POST_NEWS_FAIL:
      return { loading: false, error: action.payload };
    case POST_NEWS_RESET:
      return {};
    default:
      return state;
  }
};

export const putNewsReducer = (state = {}, action) => {
  switch (action.type) {
    case PUT_NEWS_REQUEST:
      return { loading: true };
    case PUT_NEWS_SUCCESS:
      return { loading: false, success: true };
    case PUT_NEWS_FAIL:
      return { loading: false, error: action.payload };
    case PUT_NEWS_RESET:
      return {};
    default:
      return state;
  }
};

export const deleteNewsReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_NEWS_REQUEST:
      return { loading: true };
    case DELETE_NEWS_SUCCESS:
      return { loading: false, success: true };
    case DELETE_NEWS_FAIL:
      return { loading: false, error: action.payload };
    case DELETE_NEWS_RESET:
      return {};
    default:
      return state;
  }
};

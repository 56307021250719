import {
  GET_WORK_WITH_US_FAIL,
  GET_WORK_WITH_US_REQUEST,
  GET_WORK_WITH_US_RESET,
  GET_WORK_WITH_US_SUCCESS,
  PUT_WORK_WITH_US_FAIL,
  PUT_WORK_WITH_US_REQUEST,
  PUT_WORK_WITH_US_RESET,
  PUT_WORK_WITH_US_SUCCESS,
} from "./WorkWithUsConstants";

export const getWorkWithUsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_WORK_WITH_US_REQUEST:
      return { loading: true };
    case GET_WORK_WITH_US_SUCCESS:
      return { loading: false, workWithUs: action.payload };
    case GET_WORK_WITH_US_FAIL:
      return { loading: false, error: action.payload };
    case GET_WORK_WITH_US_RESET:
      return {};
    default:
      return state;
  }
};

export const putWorkWithUsReducer = (state = {}, action) => {
  switch (action.type) {
    case PUT_WORK_WITH_US_REQUEST:
      return { loading: true };
    case PUT_WORK_WITH_US_SUCCESS:
      return { loading: false, success: true, putWorkWith: action.payload };
    case PUT_WORK_WITH_US_FAIL:
      return { loading: false, error: action.payload };
    case PUT_WORK_WITH_US_RESET:
      return {};
    default:
      return state;
  }
};

import { Button } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { API_URL, axiosAdminPrivate, tokenConfig } from "../api/axios";

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const navigate = useNavigate();

  const initialFormValues = {
    old_password: oldPassword,
    new_password: newPassword,
  };

  const passwordHandle = JSON.stringify(initialFormValues);

  const submitHandler = async (e) => {
    e.preventDefault();
    const { data } = await axiosAdminPrivate.post(
      `${API_URL}/api/v1/admin/auth/change_password`,
      passwordHandle,
      tokenConfig()
    );
    if (data?.status === 200) {
      toast.success(data?.message);
      // Remove the session from localStorage
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("adminMemberType");

      // Redirect to the login page
      setTimeout(() => {
        navigate("/admin/login");
      }, 1000);
    }
    if (data?.status === 400) {
      toast.error(data?.message);
    }
  };

  return (
    <div className="container vh-100 d-flex align-items-center justify-content-center">
      <div className="auth" style={{ width: "40%", marginBottom: "20%" }}>
        <form>
          <div className="auth-body">
            <h4
              className="text-center"
              style={{ color: "#0b6885", fontSize: "26px" }}
            >
              Change Password?
            </h4>
            <br />
            <div className="mb-3">
              <label
                htmlFor="old_password"
                className="form-label"
                style={{ color: "black", fontSize: "18px" }}
              >
                Old Password
              </label>
              <input
                type="password"
                placeholder="Old Password"
                // value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                className="form-control"
                autoComplete="off"
                required
              />
            </div>
            <div className="mb-3">
              <label
                htmlFor="password"
                className="form-label"
                style={{ color: "black", fontSize: "18px" }}
              >
                New Password
              </label>
              <input
                type="password"
                placeholder="New Password"
                // value={newPassword}
                autoComplete="off"
                onChange={(e) => setNewPassword(e.target.value)}
                className="form-control"
                required
              />
            </div>

            <Button
              disabled={!oldPassword || !newPassword}
              type="submit"
              onClick={submitHandler}
              className="btn"
              style={{
                backgroundColor: "#0b6885",
                color: "white",
                fontSize: "12px",
              }}
            >
              Submit
            </Button>
            {/* <ToastContainer
              position="top-right"
              autoClose={2000}
              theme="colored"
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
            /> */}
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;

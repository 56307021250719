import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { API_URL, axiosPublic } from "../api/axios";
import UserFooter from "./components/UserFooter";
import UserHeader from "./components/UserHeader";

const MemberForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const returnHomePage = (e) => {
    e.preventDefault();
    navigate("/");
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data } = await axiosPublic.post(
        `${API_URL}/api/v1/members/auth/forgot_password`,
        { email: email }
      );

      if (data?.status === 200) {
        toast.success(data.message);
        setTimeout(() => {
          navigate("/member/reset_password");
        }, 1000);
      } else {
        setLoading(false);
        toast.error(data.message);
      }
    } catch (error) {
      // Handle any other errors that may occur during the request
      if (error.response) {
        // If the server responded with an error, display the error message
        toast.error(error.response.data.message);
      } else {
        // If the error is not from the server response, display a generic error message
        toast.error("An error occurred. Please try again later.");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <UserHeader />
      <div className="container vh-100 d-flex align-items-center justify-content-center">
        <div className="card" style={{ width: "40%", marginBottom: "15%" }}>
          <div className="auth-body">
            <h4
              className="text-center"
              style={{ color: "#0b6885", fontSize: "26px" }}
            >
              Forgot Password?
            </h4>
            <br />
            <div className="mb-3">
              <label
                htmlFor="exampleInputEmail1"
                className="form-label"
                style={{ color: "black", fontSize: "18px" }}
              >
                Enter your Email to get Reset Link
              </label>
              <input
                type="email"
                value={email}
                className="form-control"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="@example: John@email.com"
                id="exampleInputEmail1"
                autoComplete="off"
                required
              />
            </div>

            <LoadingButton
              disabled={!email}
              loading={loading}
              loadingPosition="end"
              onClick={submitHandler}
              type="submit"
              className="btn"
              style={{
                width: "25%",
                backgroundColor: "#0b6885",
                color: "white",
                fontSize: "12px",
              }}
            >
              Submit
            </LoadingButton>
            <ToastContainer
              position="top-right"
              autoClose={2000}
              theme="colored"
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
            />

            <div>
              <Button
                onClick={returnHomePage}
                style={{
                  backgroundColor: "white",
                  color: "#0b6885",
                  fontSize: "14px",
                  fontFamily: "inherit",
                  textDecorationLine: "underline",
                }}
              >
                Return back to Home Page.
              </Button>
            </div>
          </div>
        </div>
      </div>
      <UserFooter />
    </>
  );
};

export default MemberForgotPassword;

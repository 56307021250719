import React, { useState, useEffect } from "react";
import Notification from "../../../components/Notification";
import ConfirmDialog from "../../../components/ConfirmDialog";
import CustomContainer from "../../../components/CustomContainer";
import Topbar from "../../../components/Topbar";
import { useDispatch, useSelector } from "react-redux";
import LoadingComp from "../../../components/LoadingComp";
import { GET_ALL_MEMBERS_LIST_RESET } from "./AllMembersConstant";
import {
  getAllMembersAction,
  getIndividualMembersListAction,
  getMemberProfilesAction,
} from "./AllMembersAction";
import useCustomTable from "../../../components/hooks/useCustomTable";
import { Toolbar, TableBody } from "@mui/material";
import AllMembersTableCollapse from "./AllMembersTableCollapse";
import { useNavigate } from "react-router-dom";

const tableHeader = [
  { id: "username", label: "Username" },
  { id: "first_name", label: "Full Name" },
  { id: "email_address", label: "Email Address" },
  { id: "mobile", label: "Phone Number" },
  { id: "status", label: "Verified Accounts" },
  { id: "created_date", label: "Created Date" },
  { id: "profile", label: "Profiles", disableSorting: true },
];

const AllMembers = () => {
  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate();
  const [filterFn, setFilterFn] = useState({
    fn: (item) => {
      return item;
    },
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const dispatch = useDispatch();

  const { allMembers, error, loading } = useSelector(
    (state) => state.getAllMembers
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (error) {
    setNotify({
      isOpen: true,
      message: error,
      type: "error",
    });
    dispatch({ type: GET_ALL_MEMBERS_LIST_RESET });
  }

  useEffect(() => {
    if (!allMembers) {
      dispatch(getAllMembersAction());
    }
    if (allMembers) {
      setTableData(allMembers?.data);
    }
  }, [dispatch, allMembers]);

  useEffect(() => {
    dispatch(getAllMembersAction());
  }, [dispatch]);

  const {
    TableContainer,
    TblHead,
    TblPagination,
    tableDataAfterPagingAndSorting,
  } = useCustomTable(tableData, tableHeader, filterFn);

  const profileHandler = (member_id) => {
    Promise.all([
      dispatch(getIndividualMembersListAction(member_id)),
      dispatch(getMemberProfilesAction(member_id)),
    ])
      .then(([membersListRes, memberProfilesRes]) => {
        localStorage.setItem(
          "membersList",
          JSON.stringify(membersListRes.data)
        );
        localStorage.setItem(
          "memberProfiles",
          JSON.stringify(memberProfilesRes.data)
        );
        navigate("/admin/members-list/profile");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      <CustomContainer>
        <Toolbar>
          <Topbar title="All Members" subtitle="List of all members" />
        </Toolbar>
        {loading ? (
          <LoadingComp />
        ) : (
          <>
            <TableContainer>
              <TblHead />
              <TableBody>
                {tableDataAfterPagingAndSorting().map((item) => (
                  <AllMembersTableCollapse
                    item={item}
                    key={item.unique_id}
                    profileHandler={profileHandler}
                  />
                ))}
              </TableBody>
            </TableContainer>
            <TblPagination />
          </>
        )}
      </CustomContainer>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
};

export default AllMembers;

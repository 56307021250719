import React, { useEffect, useState } from "react";
import { Button, Grid, TextField } from "@mui/material";
import { Form, useForm } from "../../components/hooks/useForm";
import { useDispatch } from "react-redux";
import CheckBoxControl from "../../components/controls/CheckBoxControl";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { API_URL } from "../../api/axios";
import { putNewsAction } from "./NewsActions";
import LoadingComp from "../../components/LoadingComp";

const NewsEditForm = ({ loadingEditForm, singleNews, setOpenEditPopup }) => {
  const [image, setImage] = useState(null);
  const [imgSrc, setImgSrc] = useState(null);

  const dispatch = useDispatch();

  const initialFormValues = {
    news_image: image,
    news_title: "",
    news_slug: "",
    news_description: "",
    status: 0,
  };

  const { values, setValues, handleInputChange } = useForm(initialFormValues);

  const handleImage = (event) => {
    let imageFile = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (x) => {
      setImgSrc(x.target.result);
    };
    reader.readAsDataURL(imageFile);
    setImage(event.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(putNewsAction(values, image, values.unique_id));
    setOpenEditPopup(false);
  };

  useEffect(() => {
    if (singleNews) {
      setValues({ ...singleNews });
    }
  }, [singleNews, setValues]);

  return (
    <Form onSubmit={handleSubmit}>
      {loadingEditForm ? (
        <LoadingComp />
      ) : (
        <>
          <Grid container spacing={2} style={{ fontSize: "12px" }}>
            <Grid item xs={12}>
              <TextField
                name="news_title"
                label="News Title"
                style={{ width: "100%" }}
                value={values.news_title}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <div className="ckeditor-container" tabIndex="0">
                <CKEditor
                  editor={ClassicEditor}
                  id="news_description"
                  name="news_description"
                  config={{
                    link: {
                      defaultProtocol: "https://",
                      addTargetToExternalLinks: true,
                      decorators: [
                        {
                          attributes: {
                            target: "_blank",
                            rel: "noopener noreferrer",
                          },
                        },
                      ],
                    },
                  }}
                  onReady={(editor) => {
                    editor.setData(values.news_description);
                  }}
                  onChange={(event, editor) =>
                    setValues({ ...values, news_description: editor.getData() })
                  }
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <CheckBoxControl
                name="status"
                label="Active"
                value={values.status}
                onChange={(e) =>
                  setValues((prev = {}) => ({
                    ...prev,
                    status: e.target.value ? 1 : 0,
                  }))
                }
              />
            </Grid>

            <Grid item xs={12}>
              <input
                type="file"
                name="news_image"
                style={{ width: "100%" }}
                onChange={handleImage}
              />{" "}
              <br />
              <br />
              {singleNews?.news_image && singleNews?.news_image ? (
                <img
                  alt=""
                  src={
                    imgSrc
                      ? imgSrc
                      : singleNews &&
                        `${API_URL}/uploads/news/${singleNews?.news_image}`
                  }
                  height={150}
                  width={150}
                />
              ) : (
                <span style={{ color: "red" }}>*Choose Image</span>
              )}
            </Grid>
          </Grid>

          <div
            style={{
              display: "flex",
              justifyContent: "end",
              paddingTop: "10px",
              marginTop: "10px",
              borderTop: "1px solid #0b6885",
            }}
          >
            <Button
              variant="contained"
              onClick={() => setOpenEditPopup(false)}
              style={{ margin: "10px 0 0 10px", backgroundColor: "#ea3f4d" }}
            >
              CANCEL
            </Button>
            <Button
              variant="contained"
              type="submit"
              style={{ margin: "10px 0 0 10px", backgroundColor: "#0b6885" }}
            >
              SUBMIT
            </Button>
          </div>
        </>
      )}
    </Form>
  );
};

export default NewsEditForm;

import React from "react";
import { Button, TableRow, TableCell } from "@mui/material";
import Moment from "react-moment";

const ManageContactsTableCollapse = ({ item, contactHandler }) => {
  return (
    <TableRow>
      <TableCell sx={{ fontSize: "13px" }}>{item.full_name}</TableCell>
      <TableCell sx={{ fontSize: "13px" }}>{item.email}</TableCell>
      <TableCell sx={{ fontSize: "13px" }}>
        {item.phone ? item.phone : ""}
      </TableCell>
      {/* <TableCell sx={{ fontSize: "13px" }}>
        {item.message?.slice(0, 30)}...
      </TableCell> */}
      <TableCell sx={{ fontSize: "13px" }}>
        <Moment format="YYYY/MM/DD">{item.created_at?.slice(0, 10)}</Moment>
        <br />
        <Moment fromNow>{item.created_at}</Moment>
      </TableCell>
      <TableCell align="left" sx={{ fontSize: "13px" }}>
        <Button
          variant="contained"
          size="small"
          style={{
            backgroundColor: "#2a80f9",
          }}
          onClick={() => contactHandler(item.unique_id)}
        >
          {" "}
          View More
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default ManageContactsTableCollapse;

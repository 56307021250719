export const GET_ALL_TEAM_REQUEST = "GET_ALL_TEAM_REQUEST";
export const GET_ALL_TEAM_SUCCESS = "GET_ALL_TEAM_SUCCESS";
export const GET_ALL_TEAM_FAIL = "GET_ALL_TEAM_FAIL";
export const GET_ALL_TEAM_RESET = "GET_ALL_TEAM_RESET";

export const GET_SINGLE_TEAM_REQUEST = "GET_SINGLE_TEAM_REQUEST";
export const GET_SINGLE_TEAM_SUCCESS = "GET_SINGLE_TEAM_SUCCESS";
export const GET_SINGLE_TEAM_FAIL = "GET_SINGLE_TEAM_FAIL";
export const GET_SINGLE_TEAM_RESET = "GET_SINGLE_TEAM_RESET";

export const POST_TEAM_REQUEST = "POST_TEAM_REQUEST";
export const POST_TEAM_SUCCESS = "POST_TEAM_SUCCESS";
export const POST_TEAM_FAIL = "POST_TEAM_FAIL";
export const POST_TEAM_RESET = "POST_TEAM_RESET";

export const PUT_TEAM_REQUEST = "PUT_TEAM_REQUEST";
export const PUT_TEAM_SUCCESS = "PUT_TEAM_SUCCESS";
export const PUT_TEAM_FAIL = "PUT_TEAM_FAIL";
export const PUT_TEAM_RESET = "PUT_TEAM_RESET";

export const DELETE_TEAM_REQUEST = "DELETE_TEAM_REQUEST";
export const DELETE_TEAM_SUCCESS = "DELETE_TEAM_SUCCESS";
export const DELETE_TEAM_FAIL = "DELETE_TEAM_FAIL";
export const DELETE_TEAM_RESET = "DELETE_TEAM_RESET";

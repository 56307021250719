import {
  axiosAdminPrivate,
  API_URL,
  tokenConfig,
  tokenConfigForm,
} from "../../../api/axios";
import {
  DELETE_DEAL_CATEGORY_FAIL,
  DELETE_DEAL_CATEGORY_REQUEST,
  DELETE_DEAL_CATEGORY_SUCCESS,
  GET_ALL_DEAL_CATEGORY_FAIL,
  GET_ALL_DEAL_CATEGORY_REQUEST,
  GET_ALL_DEAL_CATEGORY_SUCCESS,
  GET_SINGLE_DEAL_CATEGORY_FAIL,
  GET_SINGLE_DEAL_CATEGORY_REQUEST,
  GET_SINGLE_DEAL_CATEGORY_SUCCESS,
  POST_DEAL_CATEGORY_FAIL,
  POST_DEAL_CATEGORY_REQUEST,
  POST_DEAL_CATEGORY_SUCCESS,
  PUT_DEAL_CATEGORY_FAIL,
  PUT_DEAL_CATEGORY_REQUEST,
  PUT_DEAL_CATEGORY_SUCCESS,
} from "./ManageDealsCategoryConstants";

export const getAllDealCategoryAction = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_DEAL_CATEGORY_REQUEST });

    const { data } = await axiosAdminPrivate.get(
      `${API_URL}/api/v1/admin/deal_categories`
    );
    dispatch({ type: GET_ALL_DEAL_CATEGORY_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_ALL_DEAL_CATEGORY_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

export const getSingleDealCategoryAction = (unique_id) => async (dispatch) => {
  try {
    dispatch({ type: GET_SINGLE_DEAL_CATEGORY_REQUEST });

    const { data } = await axiosAdminPrivate.get(
      `${API_URL}/api/v1/admin/deal_categories/${unique_id}`
    );
    dispatch({ type: GET_SINGLE_DEAL_CATEGORY_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_SINGLE_DEAL_CATEGORY_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

export const postDealCategoryAction =
  (addDealCategory, image) => async (dispatch) => {
    try {
      dispatch({ type: POST_DEAL_CATEGORY_REQUEST });

      const formData = new FormData();
      formData.append("deals_cat_image", image);
      formData.append("deal_cat_name", addDealCategory?.deal_cat_name);
      formData.append("deal_cat_slug", addDealCategory?.deal_cat_slug);
      formData.append(
        "deal_cat_description",
        addDealCategory?.deal_cat_description
      );
      formData.append("status", addDealCategory?.status);

      const { data } = await axiosAdminPrivate.post(
        `${API_URL}/api/v1/admin/deal_category`,
        formData
      );

      dispatch({ type: POST_DEAL_CATEGORY_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: POST_DEAL_CATEGORY_FAIL,
        payload: error.message ? error.message : error.Message,
      });
    }
  };

export const putDealCategoryAction =
  (unique_id, updateDealCategory, image) => async (dispatch) => {
    try {
      dispatch({ type: PUT_DEAL_CATEGORY_REQUEST });

      const formData = new FormData();
      formData.append("deals_cat_image", image);
      formData.append("deal_cat_name", updateDealCategory?.deal_cat_name);
      formData.append("deal_cat_slug", updateDealCategory?.deal_cat_slug);
      formData.append(
        "deal_cat_description",
        updateDealCategory?.deal_cat_description
      );
      formData.append("status", updateDealCategory?.status);

      const { data } = await axiosAdminPrivate.put(
        `${API_URL}/api/v1/admin/deal_category/${unique_id}`,
        formData
      );
      dispatch({ type: PUT_DEAL_CATEGORY_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: PUT_DEAL_CATEGORY_FAIL,
        payload: error.message ? error.message : error.Message,
      });
    }
  };

export const deleteDealCategoryAction = (unique_id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_DEAL_CATEGORY_REQUEST });

    const { data } = await axiosAdminPrivate.delete(
      `${API_URL}/api/v1/admin/deal_category/${unique_id}`,
      tokenConfig()
    );

    dispatch({ type: DELETE_DEAL_CATEGORY_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: DELETE_DEAL_CATEGORY_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

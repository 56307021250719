import React, { useState } from "react";
import { Button, TableRow, TableCell, Tooltip } from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import Moment from "react-moment";
import { API_URL } from "../../api/axios";

const SuccessStoriesTableCollapse = ({
  item,
  detailViewHandler,
  updateSuccessStoriesHandler,
  deleteSuccessStoriesHandler,
}) => {
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (event) => {
    setTooltipPosition({ x: event.clientX, y: event.clientY });
  };
  return (
    <TableRow>
      {item.partner_logo_image ? (
        <TableCell sx={{ fontSize: "13px" }}>
          <img
            alt=""
            height="30px"
            width="30px"
            style={{ cursor: "pointer", borderRadius: "50%" }}
            src={`${API_URL}/uploads/partners/${item.partner_logo_image}`}
          />{" "}
          &nbsp;
          {item.partner_company_name}
        </TableCell>
      ) : (
        <TableCell>
          <img
            alt=""
            height="30px"
            width="30px"
            style={{ cursor: "pointer", borderRadius: "50%" }}
            src={`${API_URL}/uploads/success_stories/${item.ss_avatar_image}`}
          />{" "}
          &nbsp;
          {item.partner_company_name ? item.partner_company_name : item.ss_name}
        </TableCell>
      )}

      <TableCell
        sx={{ fontSize: "13px" }}
        onMouseMove={handleMouseMove}
        onMouseLeave={() => setTooltipPosition({ x: 0, y: 0 })}
      >
        {item.ss_message?.length > 60 ? (
          <Tooltip
            open={tooltipPosition.x !== 0 && tooltipPosition.y !== 0}
            title={
              <span dangerouslySetInnerHTML={{ __html: item?.ss_message }} />
            }
            placement="top"
            arrow
            PopperProps={{
              style: {
                left: tooltipPosition.x + 10,
                top: tooltipPosition.y + 10,
                transform: "translate(-50%, 0%)",
              },
            }}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: item?.ss_message?.slice(0, 60) + "...",
              }}
            ></span>
          </Tooltip>
        ) : (
          <span dangerouslySetInnerHTML={{ __html: item?.ss_message }}></span>
        )}
      </TableCell>
      <TableCell sx={{ fontSize: "13px" }}>
        <Moment format="YYYY/MM/DD">{item.created_at.slice(0, 10)}</Moment>
        <br />(<Moment fromNow>{item.created_at}</Moment>)
      </TableCell>
      <TableCell sx={{ fontSize: "13px" }}>
        {item.status ? (
          <CheckIcon style={{ color: " #69CCA4" }} />
        ) : (
          <ClearIcon style={{ color: "red" }} />
        )}
      </TableCell>
      <TableCell>
        <Button
          style={{
            marginRight: "3px",
            padding: "5px",
            minWidth: "10px",
            fontSize: "13px",
            backgroundColor: "#0b6885",
          }}
          variant="contained"
          onClick={() => detailViewHandler(item.ss_uid)}
        >
          <VisibilityIcon style={{ fontSize: 13 }} />
        </Button>
        <Button
          style={{
            marginRight: "3px",
            padding: "5px",
            minWidth: "10px",
            fontSize: "13px",
            backgroundColor: "#2a80f9",
          }}
          variant="contained"
          onClick={() => updateSuccessStoriesHandler(item.ss_uid)}
        >
          <Edit style={{ fontSize: 13 }} />
        </Button>
        <Button
          style={{
            marginRight: "1px",
            padding: "5px",
            minWidth: "10px",
            fontSize: "13px",
            backgroundColor: "#ea3f4d",
          }}
          variant="contained"
          onClick={() => deleteSuccessStoriesHandler(item.ss_uid)}
        >
          <Delete style={{ fontSize: 13 }} />
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default SuccessStoriesTableCollapse;

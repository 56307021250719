import {
  DELETE_QUESTIONARIE_FAIL,
  DELETE_QUESTIONARIE_REQUEST,
  DELETE_QUESTIONARIE_RESET,
  DELETE_QUESTIONARIE_SUCCESS,
  GET_ALL_QUESTIONARIE_FAIL,
  GET_ALL_QUESTIONARIE_REQUEST,
  GET_ALL_QUESTIONARIE_RESET,
  GET_ALL_QUESTIONARIE_SUCCESS,
  GET_SINGLE_QUESTIONARIE_FAIL,
  GET_SINGLE_QUESTIONARIE_REQUEST,
  GET_SINGLE_QUESTIONARIE_RESET,
  GET_SINGLE_QUESTIONARIE_SUCCESS,
  POST_QUESTIONARIE_FAIL,
  POST_QUESTIONARIE_REQUEST,
  POST_QUESTIONARIE_RESET,
  POST_QUESTIONARIE_SUCCESS,
  PUT_QUESTIONARIE_FAIL,
  PUT_QUESTIONARIE_REQUEST,
  PUT_QUESTIONARIE_RESET,
  PUT_QUESTIONARIE_SUCCESS,
} from "./QuestionarieConstants";

export const getAllQuestionarieReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_QUESTIONARIE_REQUEST:
      return { loading: true };
    case GET_ALL_QUESTIONARIE_SUCCESS:
      return { loading: false, allQuestionarie: action.payload };
    case GET_ALL_QUESTIONARIE_FAIL:
      return { loading: false, error: action.payload };
    case GET_ALL_QUESTIONARIE_RESET:
      return {};
    default:
      return state;
  }
};

export const getSingleQuestionarieReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_SINGLE_QUESTIONARIE_REQUEST:
      return { loading: true };
    case GET_SINGLE_QUESTIONARIE_SUCCESS:
      return { loading: false, singleQuestionarie: action.payload };
    case GET_SINGLE_QUESTIONARIE_FAIL:
      return { loading: false, error: action.payload };
    case GET_SINGLE_QUESTIONARIE_RESET:
      return {};
    default:
      return state;
  }
};

export const postQuestionarieReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_QUESTIONARIE_REQUEST:
      return { loading: true };
    case POST_QUESTIONARIE_SUCCESS:
      return { loading: false, success: true };
    case POST_QUESTIONARIE_FAIL:
      return { loading: false, error: action.payload };
    case POST_QUESTIONARIE_RESET:
      return {};
    default:
      return state;
  }
};

export const putQuestionarieReducer = (state = {}, action) => {
  switch (action.type) {
    case PUT_QUESTIONARIE_REQUEST:
      return { loading: true };
    case PUT_QUESTIONARIE_SUCCESS:
      return { loading: false, success: true };
    case PUT_QUESTIONARIE_FAIL:
      return { loading: false, error: action.payload };
    case PUT_QUESTIONARIE_RESET:
      return {};
    default:
      return state;
  }
};

export const deleteQuestionarieReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_QUESTIONARIE_REQUEST:
      return { loading: true };
    case DELETE_QUESTIONARIE_SUCCESS:
      return { loading: false, success: true };
    case DELETE_QUESTIONARIE_FAIL:
      return { loading: false, error: action.payload };
    case DELETE_QUESTIONARIE_RESET:
      return {};
    default:
      return state;
  }
};

import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { getFilterDealsAction } from "../admin-portal/manage-deals/ManageDealsActions";
import { getAllUserAction } from "../admin-portal/manage-users/ManageUsersActions";
import { getAllPagesAction } from "../admin-portal/manage-pages/ManagePagesActions";
import { getAllContactsAction } from "../admin-portal/manage-contacts/ManageContactsActions";
import { getAllMembersAction } from "../admin-portal/members-list/all-members/AllMembersAction";

ChartJS.register(ArcElement, Tooltip, Legend);

const DynamicData = () => {
  const [totalUsers, setTotalUsers] = useState([]);
  const [membersCount, setMembersCount] = useState([]);
  const [totalContacts, setTotalContacts] = useState([]);
  const [totalDeals, setTotalDeals] = useState([]);
  const [category, setCategory] = useState("");
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [province, setProvince] = useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [sort, setSort] = useState("");

  const dispatch = useDispatch();

  const { allUsers } = useSelector((state) => state.getAllUser);
  const { allMembers } = useSelector((state) => state.getAllMembers);
  const { allContacts } = useSelector((state) => state.getAllContacts);
  const { filterDeals } = useSelector((state) => state.getFilterDeals);

  useEffect(() => {
    if (!allUsers) {
      dispatch(getAllUserAction());
    } else {
      setTotalUsers(allUsers?.data);
    }
  }, [dispatch, allUsers]);

  useEffect(() => {
    if (!allMembers) {
      dispatch(getAllMembersAction());
    } else {
      setMembersCount(allMembers?.data);
    }
  }, [dispatch, allMembers]);

  useEffect(() => {
    if (!allContacts) {
      dispatch(getAllContactsAction());
    } else {
      setTotalContacts(allContacts?.data);
    }
  }, [dispatch, allContacts]);

  useEffect(() => {
    if (!filterDeals) {
      dispatch(
        getFilterDealsAction(category, limit, page, province, from, to, sort)
      );
    } else {
      setTotalDeals(filterDeals?.total);
    }
  }, [dispatch, filterDeals, category, limit, page, province, from, to, sort]);

  const data = {
    labels: ["Total Users", "Total Members", "Total Deals"],
    datasets: [
      {
        label: "# of Votes",
        data: [totalUsers.length, membersCount.length, totalDeals],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(153, 102, 255, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(153, 102, 255, 1)",
        ],
        borderWidth: 1,
      },
    ],
    // plugins: {
    //   legend: {
    //     labels: {
    //       fontSize: 12,
    //     },
    //   },
    // },
  };

  return (
    <>
      <Doughnut data={data} style={{ marginLeft: "18%" }} />
    </>
  );
};

export default DynamicData;

import React, { useEffect, useState } from "react";
import { Grid, Card, CardHeader, Avatar, Link, Paper } from "@mui/material";
import { red, blue, green } from "@mui/material/colors";
import { useDispatch, useSelector } from "react-redux";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import HandshakeIcon from "@mui/icons-material/Handshake";
import ContactsIcon from "@mui/icons-material/Contacts";
import Groups2Icon from "@mui/icons-material/Groups2";
import { getAllUserAction } from "../manage-users/ManageUsersActions";
import { getAllPagesAction } from "../manage-pages/ManagePagesActions";
import { getAllContactsAction } from "../manage-contacts/ManageContactsActions";
import { getFilterDealsAction } from "../manage-deals/ManageDealsActions";
import LoadingComp from "../../components/LoadingComp";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import DynamicData from "../../components/PieChart";
import { getAllMembersAction } from "../members-list/all-members/AllMembersAction";

const Dashboard = () => {
  const [userCount, setUserCount] = useState([]);
  const [pageCount, setPageCount] = useState([]);
  const [membersCount, setMembersCount] = useState([]);
  const [contactsCount, setContactsCount] = useState([]);
  const [dealsCount, setDealsCount] = useState([]);
  const [category, setCategory] = useState("");
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [province, setProvince] = useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [sort, setSort] = useState("");
  const [firstDeal, setFirstDeal] = useState("");
  const [secondDeal, setSecondDeal] = useState("");
  const [thirdDeal, setThirdDeal] = useState("");
  const [fourthDeal, setFourthDeal] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { allUsers, loading } = useSelector((state) => state.getAllUser);

  const { allPages } = useSelector((state) => state.getAllPages);

  const { allMembers } = useSelector((state) => state.getAllMembers);

  const { allContacts } = useSelector((state) => state.getAllContacts);

  const { filterDeals } = useSelector((state) => state.getFilterDeals);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (allUsers) {
      setUserCount(allUsers?.data);
    }
  }, [dispatch, allUsers]);

  useEffect(() => {
    if (allPages) {
      setPageCount(allPages?.data);
    }
  }, [dispatch, allPages]);

  useEffect(() => {
    if (allMembers) {
      setMembersCount(allMembers?.data);
    }
  }, [allMembers]);

  useEffect(() => {
    if (allContacts) {
      setContactsCount(allContacts?.data);
    }
  }, [dispatch, allContacts]);

  useEffect(() => {
    if (!filterDeals) {
      dispatch(
        getFilterDealsAction(category, limit, page, province, from, to, sort)
      );
    }
    if (filterDeals) {
      setDealsCount(filterDeals?.total);
      setFirstDeal(filterDeals?.data[0]);
      setSecondDeal(filterDeals?.data[1]);
      setThirdDeal(filterDeals?.data[2]);
      setFourthDeal(filterDeals?.data[3]);
    }
  }, [dispatch, filterDeals, category, limit, page, province, from, to, sort]);

  useEffect(() => {
    dispatch(getAllUserAction());

    dispatch(getAllPagesAction());

    dispatch(getAllMembersAction());
    dispatch(getAllContactsAction());

    dispatch(
      getFilterDealsAction(category, limit, page, province, from, to, sort)
    );
  }, [dispatch, category, limit, page, province, from, to, sort]);

  useEffect(() => {
    dispatch({ type: "GET_LINK", payload: "/admin" });
  }, [dispatch]);

  const viewDealsNav = () => {
    return navigate("/admin/manage-deals");
  };
  return (
    <>
      {loading ? (
        <LoadingComp />
      ) : (
        <>
          <Grid container rowSpacing={4.5} columnSpacing={2.75}>
            <Grid item xs={12} sm={6} md={4} lg={3} mt={2}>
              <Card
                style={{
                  margin: "20px",
                  padding: "20px",
                  color: "#ffffff",
                  backgroundColor: "#775ab5",
                  boxShadow: "5px 5px 5px #d4d4d4",
                }}
              >
                <h5 style={{ margin: "0" }}>
                  Total Users <PeopleOutlinedIcon fontSize="large" />
                </h5>
                <h4
                  style={{
                    fontSize: "40px",
                    fontWeight: "bold",
                    margin: "0",
                  }}
                >
                  {userCount?.length}
                </h4>
              </Card>
            </Grid>
            {/* <Grid item xs={12} sm={6} md={4} lg={3} mt={2}>
              <Card
                style={{
                  margin: "20px",
                  padding: "20px",
                  color: "#ffffff",
                  backgroundColor: "#559fe0",
                  boxShadow: "5px 5px 5px #d4d4d4",
                }}
              >
                <h5 style={{ margin: "0" }}>
                  Total Pages <LibraryBooksIcon fontSize="large" />
                </h5>
                <h4
                  style={{
                    fontSize: "40px",
                    fontWeight: "bold",
                    margin: "0",
                  }}
                >
                  {pageCount?.length}
                </h4>
              </Card>
            </Grid> */}

            <Grid item xs={12} sm={6} md={4} lg={3} mt={2}>
              <Card
                style={{
                  margin: "20px",
                  padding: "20px",
                  color: "#ffffff",
                  backgroundColor: "#559fe0",
                  boxShadow: "5px 5px 5px #d4d4d4",
                }}
              >
                <h5 style={{ margin: "0" }}>
                  Total Members <Groups2Icon fontSize="large" />
                </h5>
                <h4
                  style={{
                    fontSize: "40px",
                    fontWeight: "bold",
                    margin: "0",
                  }}
                >
                  {membersCount?.length}
                </h4>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3} mt={2}>
              <Card
                style={{
                  margin: "20px",
                  padding: "20px",
                  color: "#ffffff",
                  backgroundColor: "#ffcf42",
                  boxShadow: "5px 5px 5px #d4d4d4",
                }}
              >
                <h5 style={{ margin: "0" }}>
                  Total Contacts <ContactsIcon fontSize="large" />
                </h5>
                <h4
                  style={{
                    fontSize: "40px",
                    fontWeight: "bold",
                    margin: "0",
                  }}
                >
                  {contactsCount?.length}
                </h4>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} mt={2}>
              <Card
                style={{
                  margin: "20px",
                  padding: "20px",
                  color: "#ffffff",
                  backgroundColor: "#b2a3d9",
                  boxShadow: "5px 5px 5px #d4d4d4",
                }}
              >
                <h5 style={{ margin: "0" }}>
                  Total Deals <HandshakeIcon fontSize="large" />
                </h5>
                <h4
                  style={{
                    fontSize: "40px",
                    fontWeight: "bold",
                    margin: "0",
                  }}
                >
                  {dealsCount}
                </h4>
              </Card>
            </Grid>
          </Grid>

          <Grid container>
            {filterDeals?.length > 0 && (
              <Grid item xs={6}>
                <Paper
                  style={{
                    backgroundColor: "#eef2f6",
                    margin: "28px",
                    padding: " 24px",
                  }}
                >
                  <Grid container>
                    <Grid item xs={6}>
                      <h5 style={{ marginLeft: "1%" }}>Some of the Deals</h5>
                    </Grid>
                    <Grid item xs={6} textAlign="right">
                      <Link
                        underline="none"
                        style={{
                          cursor: "pointer",
                          color: "#0b6885",
                        }}
                        onClick={viewDealsNav}
                        alignItems="center"
                      >
                        More Deals...
                      </Link>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12}>
                      {firstDeal?.deal_title && (
                        <Card
                          sx={{ maxWidth: "100%", backgroundColor: "#f2f3f4" }}
                        >
                          <CardHeader
                            avatar={
                              <Avatar
                                sx={{ bgcolor: red[500] }}
                                aria-label="recipe"
                              >
                                {firstDeal?.deal_title?.slice(0, 1)}
                              </Avatar>
                            }
                            title={firstDeal?.deal_title?.slice(0, 50) + "..."}
                            subheader=<Moment format="YYYY/MM/DD">
                              {firstDeal?.updated_at}
                            </Moment>
                          />
                        </Card>
                      )}
                    </Grid>
                  </Grid>
                  <br />
                  <Grid container>
                    <Grid item xs={12}>
                      {secondDeal?.deal_title && (
                        <Card
                          sx={{ maxWidth: "100%", backgroundColor: "#f2f3f4" }}
                        >
                          <CardHeader
                            avatar={
                              <Avatar
                                sx={{ bgcolor: blue[500] }}
                                aria-label="recipe"
                              >
                                {secondDeal?.deal_title?.slice(0, 1)}
                              </Avatar>
                            }
                            title={secondDeal?.deal_title?.slice(0, 50) + "..."}
                            subheader=<Moment fromNow format="YYYY/MM/DD">
                              {secondDeal?.updated_at}
                            </Moment>
                          />
                        </Card>
                      )}
                    </Grid>
                  </Grid>
                  <br />
                  <Grid container>
                    <Grid item xs={12}>
                      {thirdDeal?.deal_title && (
                        <Card
                          sx={{ maxWidth: "100%", backgroundColor: "#f2f3f4" }}
                        >
                          <CardHeader
                            avatar={
                              <Avatar
                                sx={{ bgcolor: green[500] }}
                                aria-label="recipe"
                              >
                                {thirdDeal?.deal_title?.slice(0, 1)}
                              </Avatar>
                            }
                            title={thirdDeal?.deal_title?.slice(0, 50) + "..."}
                            subheader=<Moment fromNow format="YYYY/MM/DD">
                              {thirdDeal?.updated_at}
                            </Moment>
                          />
                        </Card>
                      )}
                    </Grid>
                  </Grid>
                  <br />
                  <Grid container>
                    <Grid item xs={12}>
                      {fourthDeal?.deal_title && (
                        <Card
                          sx={{ maxWidth: "100%", backgroundColor: "#f2f3f4" }}
                        >
                          <CardHeader
                            avatar={
                              <Avatar
                                sx={{ bgcolor: "#8c7cb7" }}
                                aria-label="recipe"
                              >
                                {fourthDeal?.deal_title?.slice(0, 1)}
                              </Avatar>
                            }
                            title={fourthDeal?.deal_title}
                            subheader=<Moment fromNow format="YYYY/MM/DD">
                              {fourthDeal?.updated_at}
                            </Moment>
                          />
                        </Card>
                      )}
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            )}
            <Grid item xs={6}>
              <Paper
                style={{
                  backgroundColor: "#eef2f6",
                  margin: "28px",
                  padding: "20px",
                  height: "88.5%",
                }}
              >
                <DynamicData />
              </Paper>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default Dashboard;

import React, { useEffect } from "react";
import { Grid, TextField } from "@mui/material";
import { Form, useForm } from "../../components/hooks/useForm";
import CheckBoxControl from "../../components/controls/CheckBoxControl";
import { API_URL } from "../../api/axios";
import LoadingComp from "../../components/LoadingComp";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const ManagePagesDetail = ({ singlePage, loadingEditForm }) => {
  const initialFormValues = {
    page_image: "",
    page_title: "",
    page_slug: "",
    page_description: "",
    status: 0,
  };

  const { values, setValues } = useForm(initialFormValues);

  useEffect(() => {
    if (singlePage) {
      setValues({ ...singlePage });
    }
  }, [singlePage, setValues]);

  return (
    <Form>
      {loadingEditForm ? (
        <LoadingComp />
      ) : (
        <>
          <Grid container spacing={2} style={{ fontSize: "12px" }}>
            <Grid item xs={12}>
              <TextField
                InputProps={{
                  style: {
                    pointerEvents: "none",
                  },
                }}
                name="page_title"
                label="Page Title"
                style={{ width: "100%" }}
                value={values.page_title}
                required
              />
            </Grid>

            <Grid item xs={12}>
              <CKEditor
                disabled
                editor={ClassicEditor}
                id="description"
                name="description"
                onReady={(editor) => {
                  editor.setData(values.page_description);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <CheckBoxControl
                name="status"
                label="Active"
                value={values.status}
                onChange={() => ""}
              />
            </Grid>
            <Grid item xs={12}>
              {singlePage?.page_image && singlePage?.page_image ? (
                <img
                  alt=""
                  src={
                    singlePage &&
                    `${API_URL}/uploads/pages/${singlePage?.page_image}`
                  }
                  height={100}
                  width={100}
                />
              ) : (
                <img
                  alt=""
                  height={100}
                  width={100}
                  style={{ cursor: "pointer", borderRadius: "3%" }}
                  src="https://i.postimg.cc/vZvJdnRS/blank-head-profile-pic-for-a-man.jpg"
                />
              )}
            </Grid>
          </Grid>
        </>
      )}
    </Form>
  );
};

export default ManagePagesDetail;

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getMemberProfileSideAction } from "../kyc-state-handler/KycActions";
import CompanyInformation from "./CompanyInformation";
import DocumentsAndUploads from "./DocumentsAndUploads";
import FinancialInformation from "./FinancialInformation";
import GeneralInformation from "./GeneralInformation";
import InformationSustainabilityAndImpact from "./InformationSustainabilityAndImpact";
import Popup from "../../components/Popup";
import ReviewKycForm from "./ReviewKycForm";

const steps = [
  { id: 1, component: GeneralInformation },
  { id: 2, component: CompanyInformation },
  {
    id: 3,
    component: InformationSustainabilityAndImpact,
  },
  { id: 4, component: FinancialInformation },
  { id: 5, component: DocumentsAndUploads },
];

const EnterpriseKycBackup = () => {
  const [formData, setFormData] = useState({
    full_name: "",
    company_name: "",
    is_founder_or_cofounder: "",
    founder_or_cofounder_name: "",
    designation: "",
    company_email: "",
    contact_number: "",
    company_website_or_social: "",
    no_of_founders: "",
    structure_of_enterprise: "",
    structure_of_enterprise_other: "",
    established_date: "",
    registered_address: "",
    profile_of_founder_or_cofounder: "",
    no_of_employees: "",
    sector: "",
    about_company: "",
    target_product_description: "",
    headquarters: "",
    company_operate: "",
    create_positive_impact: "",
    sdg: [],
    mitigates_risks: "",
    about_stakeholders: "",
    amount_of_investment: "",
    current_financial: "",
    terms_of_ebitda: "",
    raise_investment: "",
    amount_or_percentage_breakdown: "",
    mode_of_funding: "",
    mode_of_funding_others: "",
    non_financial_support: "",
    non_financial_support_other: "",
    terms_confirmation: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [formDataDoc, setFormDataDoc] = useState({});
  const [registerDoc, setRegisterDoc] = useState({});
  const [panOrVatDocs, setPanOrVatDocs] = useState({});
  const [mobilizationDocs, setMobilizationDocs] = useState({});
  const [revenueDocs, setRevenueDocs] = useState({});
  const [currentStep, setCurrentStep] = useState(1);

  const [openPopup, setOpenPopup] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  const validateCurrentStep = () => {
    let errors = {};
    switch (currentStep) {
      case 1:
        // validate GeneralInformation component
        if (!formData.full_name) {
          errors.full_name = "(*Full name is required)";
        }
        if (!formData.company_name) {
          errors.company_name = "(*Company name is required)";
        }
        if (!formData.is_founder_or_cofounder) {
          errors.is_founder_or_cofounder =
            "(*Are you founder/co-founder of the company? is required)";
        }
        // add more validation rules as needed
        break;
      case 2:
        // validate CompanyInformation component
        if (!formData?.company_email) {
          errors.company_email = "(*Email Address is required)";
        }
        if (!formData?.contact_number) {
          errors.contact_number = "(*Contact Number is required)";
        }

        if (!formData?.company_website_or_social) {
          errors.company_website_or_social =
            "(*Company's website, Linkedin page, or other social media page is required)";
        }

        if (
          formData?.is_founder_or_cofounder === "0" &&
          !formData?.founder_or_cofounder_name
        ) {
          errors.founder_or_cofounder_name = "(*Founder's Name is required)";
        }

        if (
          formData?.is_founder_or_cofounder === "0" &&
          !formData?.designation
        ) {
          errors.designation =
            "(*What is your designation in the company? is required)";
        }

        if (!formData.no_of_founders) {
          errors.no_of_founders = "(*No. of Founders is required)";
        }
        if (!formData.structure_of_enterprise) {
          errors.structure_of_enterprise =
            "(*Structure of the Enterprise is required)";
        }

        if (
          formData.structure_of_enterprise !== "Not registered/Idea Stage" &&
          formData.structure_of_enterprise !== "Other" &&
          formData.structure_of_enterprise?.length > 0 &&
          !formData?.pan_or_vat
        ) {
          errors.pan_or_vat =
            "(*Company registration number and PAN/ VAT number is required)";
        }

        if (
          formData.structure_of_enterprise === "Other" &&
          !formData.structure_of_enterprise_other
        ) {
          errors.structure_of_enterprise_other =
            "(*Other Structure of the Enterprise is required)";
        }

        if (!formData.established_date) {
          errors.established_date =
            "(*Date of Incorporation of the company is required)";
        }
        if (!formData.registered_address) {
          errors.registered_address = "(*Registered address is required)";
        }

        if (!formData.no_of_employees) {
          errors.no_of_employees = "(*No. of employees is required)";
        }

        if (!formData.profile_of_founder_or_cofounder) {
          errors.profile_of_founder_or_cofounder =
            "(*Profile of a founder/ co-founder is required)";
        }
        if (!formData.sector) {
          errors.sector = "(*Sector is required)";
        }

        if (!formData.about_company) {
          errors.about_company = "(*About your company is required)";
        }
        if (!formData.target_product_description) {
          errors.target_product_description = "(*Target market is required)";
        }
        if (!formData.headquarters) {
          errors.headquarters = "(*Headquarter is required)";
        }
        if (!formData.company_operate) {
          errors.company_operate = "(*Company operate is required)";
        }
        // add more validation rules as needed
        break;
      case 3:
        // validate InformationSustainabilityAndImpact component
        if (!formData.create_positive_impact) {
          errors.create_positive_impact =
            "(*Create positive impact is required)";
        }
        if (!formData.mitigates_risks) {
          errors.mitigates_risks = "(*Mitigate risks is required)";
        }
        if (!formData.about_stakeholders) {
          errors.about_stakeholders = "(*About Stakeholders is required)";
        }

        if (
          formData.create_positive_impact !== "0" &&
          formData.sdg.length === 0
        ) {
          errors.sdg = "(*Atleast one Sdg is required)";
        }
        // add more validation rules as needed
        break;
      case 4:
        // validate FinancialInformation component
        if (!formData.amount_of_investment) {
          errors.amount_of_investment = "(*Total investment is required)";
        }
        if (!formData.current_financial) {
          errors.current_financial = "(*Current financial is required)";
        }
        if (!formData.terms_of_ebitda) {
          errors.terms_of_ebitda = "(*Terms of EBITDA is required)";
        }
        if (!formData.raise_investment) {
          errors.raise_investment =
            "(*Amount of Investment to Raise is required)";
        }
        if (!formData.amount_or_percentage_breakdown) {
          errors.amount_or_percentage_breakdown =
            "(*Please provide detailed information and amount or percentage breakdown as far as possible)";
        }

        if (!formData.mode_of_funding) {
          errors.mode_of_funding = "(*Preferred mode of funding is required)";
        }

        if (
          formData?.mode_of_funding === "Others" &&
          !formData.mode_of_funding_others
        ) {
          errors.mode_of_funding_others =
            "(*Others Preferred mode of funding is required)";
        }

        if (formData.non_financial_support.length === 0) {
          errors.non_financial_support =
            "(*Select atleast one Non-financial support is required)";
        }

        if (
          formData?.non_financial_support?.includes(7) &&
          !formData.non_financial_support_other
        ) {
          errors.non_financial_support_other =
            "(*Please describe non-financial support is required)";
        }
        // add more validation rules as needed
        break;
      case 5:
        // validate DocumentsAndUploads component
        if (formData.terms_confirmation !== "1") {
          errors.terms_confirmation =
            "(*Please accept all the terms before proceed further)";
        }
        // add more validation rules as needed
        break;
      default:
        break;
    }
    return errors;
  };

  const handleNext = () => {
    const currentStepErrors = validateCurrentStep();
    if (Object.keys(currentStepErrors).length === 0) {
      setCurrentStep(currentStep + 1);
      setFormErrors({});
    } else {
      setFormErrors(currentStepErrors);
    }
  };

  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const currentStepErrors = validateCurrentStep();

    // Check if there are any errors specific to case 5
    if (currentStep === 5 && currentStepErrors.terms_confirmation) {
      setFormErrors(currentStepErrors);
      return;
    }

    setOpenPopup(true);
  };
  useEffect(() => {
    dispatch(getMemberProfileSideAction());
  }, [dispatch]);

  const CurrentStepComponent = steps[currentStep - 1].component;

  return (
    <>
      <CurrentStepComponent
        formData={formData}
        setFormData={setFormData}
        formErrors={formErrors}
        formDataDoc={formDataDoc}
        setFormDataDoc={setFormDataDoc}
        registerDoc={registerDoc}
        setRegisterDoc={setRegisterDoc}
        panOrVatDocs={panOrVatDocs}
        setPanOrVatDocs={setPanOrVatDocs}
        mobilizationDocs={mobilizationDocs}
        setMobilizationDocs={setMobilizationDocs}
        revenueDocs={revenueDocs}
        setRevenueDocs={setRevenueDocs}
      />
      <div className="page-content" style={{ padding: "20px 0px" }}>
        <div className="container">
          <div className="row">
            <div className="col-9">
              <div className="invest-form">
                {currentStep > 1 && (
                  <button className="blue-btn" onClick={handlePrev}>
                    Previous
                  </button>
                )}
                {currentStep < steps.length && (
                  <button className="blue-btn" onClick={handleNext}>
                    Next
                  </button>
                )}
                {currentStep === steps.length && (
                  <button className="blue-btn" onClick={handleSubmit}>
                    Submit Form
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Popup
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        title="KYC FORM (*Check before you submit. Once submitted cannot undo the changes.)"
      >
        <ReviewKycForm
          formData={formData}
          docPanVat={panOrVatDocs}
          setPanOrVatDocs={setPanOrVatDocs}
          docFile={formDataDoc}
          setFormDataDoc={setFormDataDoc}
          docRegFile={registerDoc}
          setRegisterDoc={setRegisterDoc}
          docMobilization={mobilizationDocs}
          setMobilizationDocs={setMobilizationDocs}
          docRevenue={revenueDocs}
          setRevenueDocs={setRevenueDocs}
          setOpenPopup={setOpenPopup}
          setFormData={setFormData}
        />
      </Popup>
    </>
  );
};

export default EnterpriseKycBackup;

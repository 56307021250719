import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ArrowButton from "../components/ArrowButton";
import "../../styles/style.css";

const GeneralInformation = ({ formData, setFormData, formErrors }) => {
  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="description" content="" />
        <meta name="author" content="" />

        <title>Million Deals</title>
        {/* <!-- Linking the font Poppins--> */}
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />

        {/* <!-- Linking Bootstrap Icon--> */}
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css"
        />
      </Helmet>
      <ArrowButton />

      <div className="page-title">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h1>Enterprise Form</h1>
              <Link className="link" to="/">
                Home
              </Link>{" "}
              /{" "}
              <Link className="link" to="/dashboard">
                Dashboard
              </Link>{" "}
              / Enterprise Form
            </div>
          </div>
        </div>
      </div>

      <div className="page-content">
        <div className="container">
          <div className="row">
            <div className="col-9">
              <p>
                If you are an enterprise looking for investments and/or 
                professional services and advice, please fill the form below to
                register your company in our platform. Our team at Million Deals
                will reach out to you to take this forward.
              </p>

              <p>
                Have any questions? Please contact us at:&nbsp;
                <Link
                  to="info@milliondeals.com.np"
                  title="info@milliondeals.com.np"
                >
                  info@milliondeals.com.np
                </Link>
              </p>

              <div className="invest-form">
                <form>
                  <h2>General Information</h2>
                  Full Name{" "}
                  {formErrors.full_name && (
                    <span className="error">{formErrors.full_name}</span>
                  )}
                  <input
                    type="text"
                    name="full_name"
                    value={formData.full_name || ""}
                    onChange={handleInputChange}
                  />
                  Company Name{" "}
                  {formErrors.company_name && (
                    <span className="error">{formErrors.company_name}</span>
                  )}
                  <input
                    type="text"
                    name="company_name"
                    value={formData.company_name || ""}
                    onChange={handleInputChange}
                    required
                  />
                  Are you founder/co-founder of the company?{" "}
                  {formErrors.is_founder_or_cofounder && (
                    <span className="error">
                      {formErrors.is_founder_or_cofounder}
                    </span>
                  )}
                  <select
                    name="is_founder_or_cofounder"
                    value={formData.is_founder_or_cofounder || ""}
                    onChange={handleInputChange}
                  >
                    <option value="">Select an option</option>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                  </select>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GeneralInformation;

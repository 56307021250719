import React, { useState, useEffect } from "react";
import { Button, TableBody, Toolbar } from "@mui/material";
import useCustomTable from "../../components/hooks/useCustomTable";
import { Add } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import CustomContainer from "../../components/CustomContainer";
import Topbar from "../../components/Topbar";
import Notification from "../../components/Notification";
import LoadingComp from "../../components/LoadingComp";
import Popup from "../../components/Popup";
import {
  DELETE_NEWS_RESET,
  GET_ALL_NEWS_RESET,
  GET_SINGLE_NEWS_RESET,
  POST_NEWS_RESET,
  PUT_NEWS_RESET,
} from "./NewsConstants";
import {
  deleteNewsAction,
  getAllNewsAction,
  getSingleNewsAction,
} from "./NewsActions";
import NewsTableCollapse from "./NewsTableCollapse";
import NewsAddForm from "./NewsAddForm";
import NewsEditForm from "./NewsEditForm";
import NewsDetail from "./NewsDetail";

const tableHeader = [
  { id: "news_image", label: "News Image" },
  { id: "news_title", label: "News Title" },
  { id: "created_at", label: "Created Date" },
  { id: "status", label: "Status" },
  { id: "actions", label: "Actions", disableSorting: true },
];

const News = () => {
  const [tableData, setTableData] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [openDetailPopup, setOpenDetailPopup] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [filterFn, setFilterFn] = useState({
    fn: (item) => {
      return item;
    },
  });

  const dispatch = useDispatch();

  const { allNews, error, loading } = useSelector((state) => state.getAllNews);

  const {
    singleNews,
    error: singleNewsError,
    loading: loadingEditForm,
  } = useSelector((state) => state.getSingleNews);

  const { success: postNewsSuccess, error: postNewsError } = useSelector(
    (state) => state.postNews
  );

  const { success: putNewsSuccess, error: putNewsError } = useSelector(
    (state) => state.putNews
  );

  const { success: deleteNewsSuccess, error: deleteNewsError } = useSelector(
    (state) => state.deleteNews
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (error) {
    setNotify({
      isOpen: true,
      message: error,
      type: "error",
    });
    dispatch({ type: GET_ALL_NEWS_RESET });
  }

  if (singleNewsError) {
    setNotify({
      isOpen: true,
      message: singleNewsError,
      type: "error",
    });
    dispatch({ type: GET_SINGLE_NEWS_RESET });
  }

  if (postNewsError) {
    setNotify({
      isOpen: true,
      message: postNewsError,
      type: "error",
    });
    dispatch({ type: POST_NEWS_RESET });
  }

  if (putNewsError) {
    setNotify({
      isOpen: true,
      message: putNewsError,
      type: "error",
    });
    dispatch({ type: PUT_NEWS_RESET });
  }

  if (deleteNewsError) {
    setNotify({
      isOpen: true,
      message: deleteNewsError,
      type: "error",
    });
    dispatch({ type: DELETE_NEWS_RESET });
  }

  if (postNewsSuccess) {
    dispatch(getAllNewsAction());
    setNotify({
      isOpen: true,
      message: "News Added Successfully.",
      type: "success",
    });
    dispatch({ type: POST_NEWS_RESET });
    setOpenPopup(false);
  }

  if (putNewsSuccess) {
    dispatch(getAllNewsAction());
    setNotify({
      isOpen: true,
      message: "News Updated Successfully.",
      type: "success",
    });
    dispatch({ type: PUT_NEWS_RESET });
    setOpenEditPopup(false);
  }

  if (deleteNewsSuccess) {
    dispatch(getAllNewsAction());
    setNotify({
      isOpen: true,
      message: "News Deleted Successfully.",
      type: "success",
    });
    dispatch({ type: DELETE_NEWS_RESET });
  }

  useEffect(() => {
    if (!allNews) {
      dispatch(getAllNewsAction());
    }
    if (allNews) {
      setTableData(allNews?.data);
    }
  }, [allNews, dispatch]);

  useEffect(() => {
    dispatch(getAllNewsAction());
  }, [dispatch]);

  const {
    TableContainer,
    TblHead,
    TblPagination,
    tableDataAfterPagingAndSorting,
  } = useCustomTable(tableData, tableHeader, filterFn);

  const addNewsHandler = () => {
    dispatch({ type: GET_SINGLE_NEWS_RESET });
    setOpenPopup(true);
  };

  const updateNewsHandler = (id) => {
    dispatch(getSingleNewsAction(id));
    setOpenEditPopup(true);
  };

  const detailViewHandler = (id) => {
    dispatch(getSingleNewsAction(id));
    setOpenDetailPopup(true);
  };

  const deleteNewsHandler = (id) => {
    dispatch(deleteNewsAction(id));
  };

  return (
    <>
      <CustomContainer>
        <Toolbar>
          <Topbar title="News" subtitle="Manage all your News here." />
          <Button
            variant="contained"
            startIcon={<Add />}
            style={{
              position: "absolute",
              right: "10px",
              backgroundColor: "#2a80f9",
            }}
            onClick={addNewsHandler}
          >
            {" "}
            Add News
          </Button>
        </Toolbar>
        {loading ? (
          <LoadingComp />
        ) : (
          <>
            <TableContainer style={{ margin: "2vw 0.5vw", padding: "24px 0" }}>
              <TblHead />
              <TableBody>
                {tableDataAfterPagingAndSorting().map((item) => (
                  <NewsTableCollapse
                    item={item}
                    key={item.unique_id}
                    detailViewHandler={detailViewHandler}
                    updateNewsHandler={updateNewsHandler}
                    deleteNewsHandler={deleteNewsHandler}
                  />
                ))}
              </TableBody>
            </TableContainer>
            <TblPagination />
          </>
        )}
      </CustomContainer>
      <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} title="Add News">
        <NewsAddForm
          singleNews={singleNews && singleNews?.data}
          setOpenPopup={setOpenPopup}
        />
      </Popup>
      <Popup
        openPopup={openEditPopup}
        setOpenPopup={setOpenEditPopup}
        title="Edit News"
      >
        <NewsEditForm
          loadingEditForm={loadingEditForm}
          singleNews={singleNews && singleNews?.data}
          setOpenEditPopup={setOpenEditPopup}
        />
      </Popup>
      <Popup
        openPopup={openDetailPopup}
        setOpenPopup={setOpenDetailPopup}
        title="Detail View"
      >
        <NewsDetail
          loadingEditForm={loadingEditForm}
          singleNews={singleNews && singleNews?.data}
          setOpenDetailPopup={setOpenDetailPopup}
        />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};

export default News;

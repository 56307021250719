import React, { useState, useEffect } from "react";
import { Button, TableBody, Toolbar } from "@mui/material";
import useCustomTable from "../../components/hooks/useCustomTable";
import { Add } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import CustomContainer from "../../components/CustomContainer";
import Topbar from "../../components/Topbar";
import Notification from "../../components/Notification";
import LoadingComp from "../../components/LoadingComp";
import Popup from "../../components/Popup";
import {
  DELETE_BLOGS_RESET,
  GET_ALL_BLOGS_RESET,
  GET_SINGLE_BLOGS_RESET,
  POST_BLOGS_RESET,
  PUT_BLOGS_RESET,
} from "./BlogsConstants";
import {
  deleteBlogsAction,
  getAllBlogsAction,
  getSingleBlogsAction,
} from "./BlogsActions";
import BlogsTableCollapse from "./BlogsTableCollapse";
import BlogsDetail from "./BlogsDetail";
import BlogsEditForm from "./BlogsEditForm";
import BlogsAddForm from "./BlogsAddForm";

const tableHeader = [
  { id: "blog_image", label: "Blog Image" },
  { id: "blog_name", label: "Blog Name" },
  //   { id: "blog_description", label: "Blog Description" },
  { id: "created_at", label: "Created Date" },
  { id: "status", label: "Status" },
  { id: "actions", label: "Actions", disableSorting: true },
];

const Blogs = () => {
  const [tableData, setTableData] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [openDetailPopup, setOpenDetailPopup] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [filterFn, setFilterFn] = useState({
    fn: (item) => {
      return item;
    },
  });

  const dispatch = useDispatch();

  const { allBlogs, error, loading } = useSelector(
    (state) => state.getAllBlogs
  );

  const {
    singleBlogs,
    error: singleBlogsError,
    loading: loadingEditForm,
  } = useSelector((state) => state.getSingleBlogs);

  const { success: postBlogsSuccess, error: postBlogsError } = useSelector(
    (state) => state.postBlogs
  );

  const { success: putBlogsSuccess, error: putBlogsError } = useSelector(
    (state) => state.putBlogs
  );

  const { success: deleteBlogsSuccess, error: deleteBlogsError } = useSelector(
    (state) => state.deleteBlogs
  );

  if (error) {
    setNotify({
      isOpen: true,
      message: error,
      type: "error",
    });
    dispatch({ type: GET_ALL_BLOGS_RESET });
  }

  if (singleBlogsError) {
    setNotify({
      isOpen: true,
      message: singleBlogsError,
      type: "error",
    });
    dispatch({ type: GET_SINGLE_BLOGS_RESET });
  }

  if (postBlogsError) {
    setNotify({
      isOpen: true,
      message: postBlogsError,
      type: "error",
    });
    dispatch({ type: POST_BLOGS_RESET });
  }

  if (putBlogsError) {
    setNotify({
      isOpen: true,
      message: putBlogsError,
      type: "error",
    });
    dispatch({ type: PUT_BLOGS_RESET });
  }

  if (deleteBlogsError) {
    setNotify({
      isOpen: true,
      message: deleteBlogsError,
      type: "error",
    });
    dispatch({ type: DELETE_BLOGS_RESET });
  }

  if (postBlogsSuccess) {
    dispatch(getAllBlogsAction());
    setNotify({
      isOpen: true,
      message: "Blogs Added Successfully.",
      type: "success",
    });
    dispatch({ type: POST_BLOGS_RESET });
    setOpenPopup(false);
  }

  if (putBlogsSuccess) {
    dispatch(getAllBlogsAction());
    setNotify({
      isOpen: true,
      message: "Blogs Updated Successfully.",
      type: "success",
    });
    dispatch({ type: PUT_BLOGS_RESET });
    setOpenEditPopup(false);
  }

  if (deleteBlogsSuccess) {
    dispatch(getAllBlogsAction());
    setNotify({
      isOpen: true,
      message: "Blogs Deleted Successfully.",
      type: "success",
    });
    dispatch({ type: DELETE_BLOGS_RESET });
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!allBlogs) {
      dispatch(getAllBlogsAction());
    }
    if (allBlogs) {
      setTableData(allBlogs?.data);
    }
  }, [allBlogs, dispatch]);

  useEffect(() => {
    dispatch(getAllBlogsAction());
  }, [dispatch]);

  const {
    TableContainer,
    TblHead,
    TblPagination,
    tableDataAfterPagingAndSorting,
  } = useCustomTable(tableData, tableHeader, filterFn);

  const addBlogsHandler = () => {
    dispatch({ type: GET_SINGLE_BLOGS_RESET });
    setOpenPopup(true);
  };

  const updateBlogsHandler = (id) => {
    dispatch(getSingleBlogsAction(id));
    setOpenEditPopup(true);
  };

  const detailViewHandler = (id) => {
    dispatch(getSingleBlogsAction(id));
    setOpenDetailPopup(true);
  };

  const deleteBlogsHandler = (id) => {
    dispatch(deleteBlogsAction(id));
  };

  return (
    <>
      <CustomContainer>
        <Toolbar>
          <Topbar title="Blogs" subtitle="Manage all your Blogs here." />
          <Button
            variant="contained"
            startIcon={<Add />}
            style={{
              position: "absolute",
              right: "10px",
              backgroundColor: "#2a80f9",
            }}
            onClick={addBlogsHandler}
          >
            {" "}
            Add Blogs
          </Button>
        </Toolbar>
        {loading ? (
          <LoadingComp />
        ) : (
          <>
            <TableContainer style={{ margin: "2vw 0.5vw", padding: "24px 0" }}>
              <TblHead />
              <TableBody>
                {tableDataAfterPagingAndSorting().map((item) => (
                  <BlogsTableCollapse
                    item={item}
                    key={item.unique_id}
                    detailViewHandler={detailViewHandler}
                    updateBlogsHandler={updateBlogsHandler}
                    deleteBlogsHandler={deleteBlogsHandler}
                  />
                ))}
              </TableBody>
            </TableContainer>
            <TblPagination />
          </>
        )}
      </CustomContainer>
      <Popup
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        title="Add Blogs"
      >
        <BlogsAddForm
          singleBlogs={singleBlogs && singleBlogs?.data}
          setOpenPopup={setOpenPopup}
        />
      </Popup>
      <Popup
        openPopup={openEditPopup}
        setOpenPopup={setOpenEditPopup}
        title="Edit Blogs"
      >
        <BlogsEditForm
          loadingEditForm={loadingEditForm}
          singleBlogs={singleBlogs && singleBlogs?.data}
          setOpenEditPopup={setOpenEditPopup}
        />
      </Popup>
      <Popup
        openPopup={openDetailPopup}
        setOpenPopup={setOpenDetailPopup}
        title="Detail View"
      >
        <BlogsDetail
          loadingEditForm={loadingEditForm}
          singleBlogs={singleBlogs && singleBlogs?.data}
          setOpenDetailPopup={setOpenDetailPopup}
        />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};

export default Blogs;

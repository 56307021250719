import {
  getRefreshToken,
  getAccessToken,
  getMemberAccessToken,
  getMemberRefreshToken,
} from "../../services/storage";

const useAuthentication = () => {
  const accessToken = getAccessToken();
  const refreshToken = getRefreshToken();
  const memberAccessToken = getMemberAccessToken();
  const memberRefreshToken = getMemberRefreshToken();

  const isAuth = accessToken && refreshToken;
  const isMemAuth = memberAccessToken && memberRefreshToken;

  return {
    accessToken,
    refreshToken,
    memberAccessToken,
    memberRefreshToken,
    isAuth,
    isMemAuth,
  };
};

export default useAuthentication;

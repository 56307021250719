const ACCESS_TOKEN_KEY = "accessToken";
const REFRESH_TOKEN_KEY = "refreshToken";
const ACCESSS_TOKEN_KEY_MEMBER = "memberAccessToken";
const REFRESH_TOKEN_KEY_MEMBER = "memberRefreshToken";
const MEMBER_TYPE = "memberType";
const ADMIN_MEMBER_TYPE = "adminMemberType";

export const getAccessToken = () => {
  return localStorage.getItem(ACCESS_TOKEN_KEY);
};

export const getRefreshToken = () => {
  return localStorage.getItem(REFRESH_TOKEN_KEY);
};

export const setAccessToken = (token) => {
  localStorage.setItem(ACCESS_TOKEN_KEY, token);
};

export const setRefreshToken = (token) => {
  localStorage.setItem(REFRESH_TOKEN_KEY, token);
};

export const getMemberAccessToken = () => {
  return localStorage.getItem(ACCESSS_TOKEN_KEY_MEMBER);
};

export const getMemberRefreshToken = () => {
  return localStorage.getItem(REFRESH_TOKEN_KEY_MEMBER);
};

export const setMemberAccessToken = (token) => {
  localStorage.setItem(ACCESSS_TOKEN_KEY_MEMBER, token);
};

export const setMemberRefreshToken = (token) => {
  localStorage.setItem(REFRESH_TOKEN_KEY_MEMBER, token);
};

export const setUserType = (userType) => {
  localStorage.setItem(MEMBER_TYPE, userType);
};

export const getUserType = () => localStorage.getItem(MEMBER_TYPE);

export const setAdminUserType = (adminMemberType) => {
  localStorage.setItem(ADMIN_MEMBER_TYPE, adminMemberType);
};

export const getAdminUserType = () => localStorage.getItem(ADMIN_MEMBER_TYPE);

import {
  GET_ENTERPRISE_BUSINESS_PLAN_DOCS_FAIL,
  GET_ENTERPRISE_BUSINESS_PLAN_DOCS_REQUEST,
  GET_ENTERPRISE_BUSINESS_PLAN_DOCS_RESET,
  GET_ENTERPRISE_BUSINESS_PLAN_DOCS_SUCCESS,
  GET_ENTERPRISE_REGISTRATION_COMP_DOCS_FAIL,
  GET_ENTERPRISE_REGISTRATION_COMP_DOCS_REQUEST,
  GET_ENTERPRISE_REGISTRATION_COMP_DOCS_RESET,
  GET_ENTERPRISE_REGISTRATION_COMP_DOCS_SUCCESS,
  GET_MEMBERS_PROFILES_FAIL,
  GET_MEMBERS_PROFILES_REQUEST,
  GET_MEMBERS_PROFILES_RESET,
  GET_MEMBERS_PROFILES_SUCCESS,
  GET_MOBILIZATION_PLAN_DOCS_FAIL,
  GET_MOBILIZATION_PLAN_DOCS_REQUEST,
  GET_MOBILIZATION_PLAN_DOCS_RESET,
  GET_MOBILIZATION_PLAN_DOCS_SUCCESS,
  GET_PAN_OR_VAT_DOCS_FAIL,
  GET_PAN_OR_VAT_DOCS_REQUEST,
  GET_PAN_OR_VAT_DOCS_RESET,
  GET_PAN_OR_VAT_DOCS_SUCCESS,
  GET_REVENUE_PROFITABILITY_DOCS_FAIL,
  GET_REVENUE_PROFITABILITY_DOCS_REQUEST,
  GET_REVENUE_PROFITABILITY_DOCS_RESET,
  GET_REVENUE_PROFITABILITY_DOCS_SUCCESS,
  GET_SINGLE_MEMBERS_PROFILE_FAIL,
  GET_SINGLE_MEMBERS_PROFILE_REQUEST,
  GET_SINGLE_MEMBERS_PROFILE_RESET,
  GET_SINGLE_MEMBERS_PROFILE_SUCCESS,
  POST_CREATE_ENTERPRISE_KYC_FAIL,
  POST_CREATE_ENTERPRISE_KYC_REQUEST,
  POST_CREATE_ENTERPRISE_KYC_RESET,
  POST_CREATE_ENTERPRISE_KYC_SUCCESS,
  POST_CREATE_INVESTOR_KYC_FAIL,
  POST_CREATE_INVESTOR_KYC_REQUEST,
  POST_CREATE_INVESTOR_KYC_RESET,
  POST_CREATE_INVESTOR_KYC_SUCCESS,
  POST_ENTERPRISE_BUSINESS_PLAN_DOC_FAIL,
  POST_ENTERPRISE_BUSINESS_PLAN_DOC_REQUEST,
  POST_ENTERPRISE_BUSINESS_PLAN_DOC_RESET,
  POST_ENTERPRISE_BUSINESS_PLAN_DOC_SUCCESS,
  POST_ENTERPRISE_REGISRATION_COMP_DOC_FAIL,
  POST_ENTERPRISE_REGISRATION_COMP_DOC_REQUEST,
  POST_ENTERPRISE_REGISRATION_COMP_DOC_RESET,
  POST_ENTERPRISE_REGISRATION_COMP_DOC_SUCCESS,
  POST_MOBILIZATION_PLAN_DOCS_FAIL,
  POST_MOBILIZATION_PLAN_DOCS_REQUEST,
  POST_MOBILIZATION_PLAN_DOCS_RESET,
  POST_MOBILIZATION_PLAN_DOCS_SUCCESS,
  POST_PAN_OR_VAT_DOCS_FAIL,
  POST_PAN_OR_VAT_DOCS_REQUEST,
  POST_PAN_OR_VAT_DOCS_RESET,
  POST_PAN_OR_VAT_DOCS_SUCCESS,
  POST_REVENUE_PROFITABILITY_DOCS_FAIL,
  POST_REVENUE_PROFITABILITY_DOCS_REQUEST,
  POST_REVENUE_PROFITABILITY_DOCS_RESET,
  POST_REVENUE_PROFITABILITY_DOCS_SUCCESS,
} from "./KycConstants";

export const getMemberProfilesideReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_MEMBERS_PROFILES_REQUEST:
      return { loading: true };
    case GET_MEMBERS_PROFILES_SUCCESS:
      return { loading: false, memberProfileSide: action.payload };
    case GET_MEMBERS_PROFILES_FAIL:
      return { loading: false, error: action.payload };
    case GET_MEMBERS_PROFILES_RESET:
      return {};
    default:
      return state;
  }
};

export const postCreateEnterpriseKycReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_CREATE_ENTERPRISE_KYC_REQUEST:
      return { loading: true };
    case POST_CREATE_ENTERPRISE_KYC_SUCCESS:
      return { loading: false, success: true, postCreateKyc: action.payload };
    case POST_CREATE_ENTERPRISE_KYC_FAIL:
      return { loading: false, error: action.payload };
    case POST_CREATE_ENTERPRISE_KYC_RESET:
      return {};
    default:
      return state;
  }
};

export const postCreateInvestorKycReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_CREATE_INVESTOR_KYC_REQUEST:
      return { loading: true };
    case POST_CREATE_INVESTOR_KYC_SUCCESS:
      return { loading: false, success: true };
    case POST_CREATE_INVESTOR_KYC_FAIL:
      return { loading: false, error: action.payload };
    case POST_CREATE_INVESTOR_KYC_RESET:
      return {};
    default:
      return state;
  }
};

export const getSingleMemberProfilesideReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_SINGLE_MEMBERS_PROFILE_REQUEST:
      return { loading: true };
    case GET_SINGLE_MEMBERS_PROFILE_SUCCESS:
      return { loading: false, singleMemberProfileSide: action.payload };
    case GET_SINGLE_MEMBERS_PROFILE_FAIL:
      return { loading: false, error: action.payload };
    case GET_SINGLE_MEMBERS_PROFILE_RESET:
      return {};
    default:
      return state;
  }
};

export const postEnterpriseBusinessPlanReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_ENTERPRISE_BUSINESS_PLAN_DOC_REQUEST:
      return { loading: true };
    case POST_ENTERPRISE_BUSINESS_PLAN_DOC_SUCCESS:
      return { loading: false, success: true };
    case POST_ENTERPRISE_BUSINESS_PLAN_DOC_FAIL:
      return { loading: false, error: action.payload };
    case POST_ENTERPRISE_BUSINESS_PLAN_DOC_RESET:
      return {};
    default:
      return state;
  }
};

export const postEnterpriseRegistrationCompReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_ENTERPRISE_REGISRATION_COMP_DOC_REQUEST:
      return { loading: true };
    case POST_ENTERPRISE_REGISRATION_COMP_DOC_SUCCESS:
      return { loading: false, success: true };
    case POST_ENTERPRISE_REGISRATION_COMP_DOC_FAIL:
      return { loading: false, error: action.payload };
    case POST_ENTERPRISE_REGISRATION_COMP_DOC_RESET:
      return {};
    default:
      return state;
  }
};

export const getEnterpriseBusinessPlanDocReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ENTERPRISE_BUSINESS_PLAN_DOCS_REQUEST:
      return { loading: true };
    case GET_ENTERPRISE_BUSINESS_PLAN_DOCS_SUCCESS:
      return { loading: false, businessPlanDoc: action.payload };
    case GET_ENTERPRISE_BUSINESS_PLAN_DOCS_FAIL:
      return { loading: false, error: action.payload };
    case GET_ENTERPRISE_BUSINESS_PLAN_DOCS_RESET:
      return {};
    default:
      return state;
  }
};

export const getEnterpriseRegistrationCompDocReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ENTERPRISE_REGISTRATION_COMP_DOCS_REQUEST:
      return { loading: true };
    case GET_ENTERPRISE_REGISTRATION_COMP_DOCS_SUCCESS:
      return { loading: false, registrationCompDoc: action.payload };
    case GET_ENTERPRISE_REGISTRATION_COMP_DOCS_FAIL:
      return { loading: false, error: action.payload };
    case GET_ENTERPRISE_REGISTRATION_COMP_DOCS_RESET:
      return {};
    default:
      return state;
  }
};

export const postEnterprisePanOrVatDocReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_PAN_OR_VAT_DOCS_REQUEST:
      return { loading: true };
    case POST_PAN_OR_VAT_DOCS_SUCCESS:
      return { loading: false, success: true };
    case POST_PAN_OR_VAT_DOCS_FAIL:
      return { loading: false, error: action.payload };
    case POST_PAN_OR_VAT_DOCS_RESET:
      return {};
    default:
      return state;
  }
};

export const postEnterpriseMobilizationPlanDocsReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case POST_MOBILIZATION_PLAN_DOCS_REQUEST:
      return { loading: true };
    case POST_MOBILIZATION_PLAN_DOCS_SUCCESS:
      return { loading: false, success: true };
    case POST_MOBILIZATION_PLAN_DOCS_FAIL:
      return { loading: false, error: action.payload };
    case POST_MOBILIZATION_PLAN_DOCS_RESET:
      return {};
    default:
      return state;
  }
};

export const postEnterpriseRevenueProfitabilityDocsReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case POST_REVENUE_PROFITABILITY_DOCS_REQUEST:
      return { loading: true };
    case POST_REVENUE_PROFITABILITY_DOCS_SUCCESS:
      return { loading: false, success: true };
    case POST_REVENUE_PROFITABILITY_DOCS_FAIL:
      return { loading: false, error: action.payload };
    case POST_REVENUE_PROFITABILITY_DOCS_RESET:
      return {};
    default:
      return state;
  }
};

export const getEnterprisePanVatDocReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PAN_OR_VAT_DOCS_REQUEST:
      return { loading: true };
    case GET_PAN_OR_VAT_DOCS_SUCCESS:
      return { loading: false, panVatDoc: action.payload };
    case GET_PAN_OR_VAT_DOCS_FAIL:
      return { loading: false, error: action.payload };
    case GET_PAN_OR_VAT_DOCS_RESET:
      return {};
    default:
      return state;
  }
};

export const getEnterpriseMobilizationPlanDocReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_MOBILIZATION_PLAN_DOCS_REQUEST:
      return { loading: true };
    case GET_MOBILIZATION_PLAN_DOCS_SUCCESS:
      return { loading: false, mobilizationPlanDoc: action.payload };
    case GET_MOBILIZATION_PLAN_DOCS_FAIL:
      return { loading: false, error: action.payload };
    case GET_MOBILIZATION_PLAN_DOCS_RESET:
      return {};
    default:
      return state;
  }
};

export const getEnterpriseRevenueProfitabilityDocReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case GET_REVENUE_PROFITABILITY_DOCS_REQUEST:
      return { loading: true };
    case GET_REVENUE_PROFITABILITY_DOCS_SUCCESS:
      return { loading: false, revenueProfitabilityDoc: action.payload };
    case GET_REVENUE_PROFITABILITY_DOCS_FAIL:
      return { loading: false, error: action.payload };
    case GET_REVENUE_PROFITABILITY_DOCS_RESET:
      return {};
    default:
      return state;
  }
};

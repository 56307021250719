import { Paper } from "@mui/material";
import React from "react";

const CustomContainer = ({ children }) => {
  return (
    <Paper
      style={{ backgroundColor: "#f7f7f7", margin: "20px", padding: " 24px" }}
    >
      {children}
    </Paper>
  );
};

export default CustomContainer;

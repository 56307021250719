import React from "react";
import { TableRow, TableCell, Button } from "@mui/material";
import VerifiedIcon from "@mui/icons-material/Verified";
import CancelIcon from "@mui/icons-material/Cancel";
import VisibilityIcon from "@mui/icons-material/Visibility";

const AllMemberProfileTableCollapse = ({ item, kycHandler }) => {
  return (
    <TableRow>
      <TableCell sx={{ fontSize: "13px" }}>
        {item.company_name ? (
          item.company_name
        ) : item.profile_type === "Investor" ? (
          <>{item.profile_type}</>
        ) : (
          <>
            {item.profile_type}&nbsp;{item.member_profile_id}
          </>
        )}{" "}
        &nbsp;
        {item?.is_verified === 1 ? (
          <VerifiedIcon style={{ color: "#42a5f5", fontSize: "medium" }} />
        ) : (
          <CancelIcon style={{ color: "#ed5e5e", fontSize: "medium" }} />
        )}
      </TableCell>
      <TableCell sx={{ fontSize: "13px" }}>
        {item?.member_profile_id !== 0 ? (
          <Button
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#2a80f9",
            }}
            onClick={() => kycHandler(item.member_profile_id)}
          >
            <VisibilityIcon style={{ fontSize: "small" }} /> &nbsp; View
          </Button>
        ) : (
          ""
        )}
      </TableCell>
    </TableRow>
  );
};

export default AllMemberProfileTableCollapse;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import LoadingComp from "../../components/LoadingComp";
import { Helmet } from "react-helmet";
import ArrowButton from "../components/ArrowButton";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
} from "@mui/material";
import { useState } from "react";
import { API_URL } from "../../api/axios";
import {
  getAllDealCategoryFilterAction,
  getAllSdgsFilterAction,
} from "../private-routes/PrivateActions";

const nonFinancialList = [
  {
    id: 1,
    label: "Financial projections and valuation",
  },
  { id: 2, label: "Branding" },
  { id: 3, label: "Legal Services" },
  { id: 4, label: "Accounting Services" },
  { id: 5, label: "Auditing and Taxation Services" },
  { id: 6, label: "Not seeking any non-financial support" },
  { id: 7, label: "Others" },
];

const provinceList = [
  {
    id: 1,
    label: "Province 1",
  },
  {
    id: 2,
    label: "Madhesh Pradesh",
  },
  {
    id: 3,
    label: "Bagmati Pradesh",
  },
  { id: 4, label: "Gandaki Pradesh" },
  {
    id: 5,
    label: "Lumbini Pradesh",
  },
  {
    id: 6,
    label: "Karnali Pradesh",
  },
  { id: 7, label: "Sudurpaschim Pradesh" },
];

const EnterpriseDetailKyc = () => {
  const [memberEnterpriseKYCValue, setMemberEnterpriseKYCValue] = useState(
    JSON.parse(localStorage.getItem("memberEnterpriseKYC")) || {}
  );
  const [docFile, setDocFile] = useState(
    JSON.parse(localStorage.getItem("documentsFiles")) || []
  );
  const [docRegFile, setDocRegFile] = useState(
    JSON.parse(localStorage.getItem("documentsRegistrationFiles")) || []
  );
  const [docPanVat, setDocPanVat] = useState(
    JSON.parse(localStorage.getItem("charterDocs")) || []
  );
  const [docMobilization, setDocMobilization] = useState(
    JSON.parse(localStorage.getItem("mobilizationDocs")) || []
  );
  const [docRevenue, setDocRevenue] = useState(
    JSON.parse(localStorage.getItem("revenueDocs")) || []
  );

  const [sectorList, setSectorList] = useState([]);
  const [sdgList, setSdgList] = useState([]);

  const dispatch = useDispatch();

  const { allSdgsFilter } = useSelector((state) => state.getAllSdgsFilterList);

  const { allDealsCategoryFilter } = useSelector(
    (state) => state.getAllDealCategoryFilter
  );

  const { singleMemberProfileSide, loading } = useSelector(
    (state) => state.getSingleMemberProfileside
  );

  const { businessPlanDoc } = useSelector(
    (state) => state.getEnterpriseBusinessPlanDoc
  );

  const { registrationCompDoc } = useSelector(
    (state) => state.getEnterpriseRegistrationCompDoc
  );

  const { panVatDoc } = useSelector((state) => state.getEnterprisePanVatDoc);

  const { mobilizationPlanDoc } = useSelector(
    (state) => state.getEnterpriseMobilizationPlanDoc
  );

  const { revenueProfitabilityDoc } = useSelector(
    (state) => state.getEnterpriseRevenueProfitabilityDoc
  );
  useEffect(() => {
    localStorage.setItem(
      "memberEnterpriseKYC",
      JSON.stringify(memberEnterpriseKYCValue)
    );
  }, [memberEnterpriseKYCValue]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    localStorage.setItem("documentsFiles", JSON.stringify(docFile));
  }, [docFile]);

  useEffect(() => {
    localStorage.setItem(
      "documentsRegistrationFiles",
      JSON.stringify(docRegFile)
    );
  }, [docRegFile]);

  useEffect(() => {
    localStorage.setItem("charterDocs", JSON.stringify(docPanVat));
  }, [docPanVat]);

  useEffect(() => {
    localStorage.setItem("mobilizationDocs", JSON.stringify(docMobilization));
  }, [docMobilization]);

  useEffect(() => {
    localStorage.setItem("revenueDocs", JSON.stringify(docRevenue));
  }, [docRevenue]);

  useEffect(() => {
    if (singleMemberProfileSide) {
      setMemberEnterpriseKYCValue(
        singleMemberProfileSide?.data?.enterprise_kyc
      );
    }
  }, [singleMemberProfileSide]);

  useEffect(() => {
    if (businessPlanDoc) {
      setDocFile(businessPlanDoc?.data);
    }
  }, [businessPlanDoc]);

  useEffect(() => {
    if (registrationCompDoc) {
      setDocRegFile(registrationCompDoc?.data);
    }
  }, [registrationCompDoc]);

  useEffect(() => {
    if (panVatDoc) {
      setDocPanVat(panVatDoc?.data);
    }
  }, [panVatDoc]);

  useEffect(() => {
    if (mobilizationPlanDoc) {
      setDocMobilization(mobilizationPlanDoc?.data);
    }
  }, [mobilizationPlanDoc]);

  useEffect(() => {
    if (revenueProfitabilityDoc) {
      setDocRevenue(revenueProfitabilityDoc?.data);
    }
  }, [revenueProfitabilityDoc]);

  useEffect(() => {
    if (!allSdgsFilter) {
      dispatch(getAllSdgsFilterAction());
    }
    if (allSdgsFilter) {
      setSdgList(allSdgsFilter?.data);
    }
  }, [allSdgsFilter, dispatch]);

  useEffect(() => {
    if (!allDealsCategoryFilter) {
      dispatch(getAllDealCategoryFilterAction());
    }
    if (allDealsCategoryFilter) {
      setSectorList(allDealsCategoryFilter?.data);
    }
  }, [allDealsCategoryFilter, dispatch]);

  useEffect(() => {
    dispatch(getAllSdgsFilterAction());
    dispatch(getAllDealCategoryFilterAction());
  }, [dispatch]);

  const handlePrint = () => {
    var divToPrint = document.getElementById("printTable");
    var newWin = window.open("");
    newWin.document.write(divToPrint.outerHTML);
    newWin.print();
    newWin.close();
  };

  const sdgNames = sdgList
    ?.filter((sdg) => memberEnterpriseKYCValue?.sdg?.includes(sdg?.sdg_id))
    ?.map((sdg) => <Chip className="me-1 mb-2" label={sdg?.sdg_name} />);

  const nonFinancialListNames = nonFinancialList
    ?.filter((nonFinancial) =>
      memberEnterpriseKYCValue.non_financial_support?.includes(nonFinancial.id)
    )
    ?.map((nonFinancial) => (
      <Chip className="me-1 mb-2" label={nonFinancial.label} />
    ));

  const sectorName = sectorList
    ?.filter((sector) =>
      memberEnterpriseKYCValue?.sector?.includes(sector.deal_category_id)
    )
    ?.map((sector) => sector.deal_cat_name)
    ?.join(", ");

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="description" content="" />
        <meta name="author" content="" />

        <title>Million Deals</title>
        {/* <!-- Linking the font Poppins--> */}
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />

        {/* <!-- Linking Bootstrap Icon--> */}
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css"
        />
      </Helmet>
      <ArrowButton />
      <div className="page-title">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h1>Enterprise KYC Detail</h1>
              <Link className="link" to="/">
                Home
              </Link>{" "}
              /{" "}
              <Link className="link" to="/dashboard">
                Dashboard
              </Link>{" "}
              / Enterprise KYC Detail
            </div>
          </div>
        </div>
      </div>
      <div>
        {loading ? (
          <LoadingComp />
        ) : (
          <>
            <div className="page-content-detail">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <p>
                      If you are an enterprise looking for investments and/or 
                      professional services and advice, please fill the form
                      below to register your company in our platform. Our team
                      at Million Deals will reach out to you to take this
                      forward.
                    </p>

                    <p>
                      Have any questions? Please contact us at:&nbsp;
                      <Link
                        style={{ textDecoration: "none" }}
                        onClick={() =>
                          (window.location = "mailto:info@milliondeals.com.np")
                        }
                      >
                        info@milliondeals.com.np
                      </Link>
                    </p>

                    <>
                      <div id="printTable">
                        <TableContainer
                          component={Paper}
                          sx={{ maxHeight: "100%" }}
                        >
                          <Table
                            stickyHeader
                            aria-label="sticky table"
                            className="table-style "
                          >
                            <TableHead style={{ height: "50px" }}>
                              <TableRow
                                sx={{
                                  "& :hover": {
                                    color: "white !important",
                                  },
                                  "& :focus:not(:focus-visible)": {
                                    color: "white !important",
                                  },
                                }}
                              >
                                <TableCell
                                  style={{
                                    backgroundColor: "#0b6885",
                                    padding: "0.5vw",
                                    letterSpacing: "0.5px",
                                    fontSize: "16px",
                                    fontWeight: "600",
                                    color: "white",
                                  }}
                                >
                                  TITLE
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{
                                    backgroundColor: "#0b6885",
                                    padding: "0.5vw",
                                    letterSpacing: "0.5px",
                                    fontSize: "16px",
                                    fontWeight: "600",
                                    color: "white",
                                  }}
                                >
                                  DESCRIPTION
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell
                                  component="th"
                                  style={{ fontWeight: "600" }}
                                  scope="row"
                                >
                                  Full Name
                                </TableCell>
                                <TableCell align="left">
                                  {memberEnterpriseKYCValue.full_name}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  component="th"
                                  style={{ fontWeight: "600" }}
                                  scope="row"
                                >
                                  Company Name
                                </TableCell>
                                <TableCell align="left">
                                  {memberEnterpriseKYCValue.company_name}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  component="th"
                                  style={{ fontWeight: "600" }}
                                  scope="row"
                                >
                                  Founder/Co-Founder
                                </TableCell>
                                <TableCell align="left">
                                  {memberEnterpriseKYCValue.is_founder_or_cofounder ===
                                  1
                                    ? "Yes"
                                    : "No"}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  component="th"
                                  style={{ fontWeight: "600" }}
                                  scope="row"
                                >
                                  Founder/Co-Founder Name
                                </TableCell>
                                <TableCell align="left">
                                  {memberEnterpriseKYCValue.is_founder_or_cofounder ===
                                    0 &&
                                  memberEnterpriseKYCValue.founder_or_cofounder_name
                                    ? memberEnterpriseKYCValue.founder_or_cofounder_name
                                    : "N/A"}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  component="th"
                                  style={{ fontWeight: "600" }}
                                  scope="row"
                                >
                                  Company Email
                                </TableCell>
                                <TableCell align="left">
                                  {memberEnterpriseKYCValue.company_email}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  component="th"
                                  style={{ fontWeight: "600" }}
                                  scope="row"
                                >
                                  Designation
                                </TableCell>
                                <TableCell align="left">
                                  {memberEnterpriseKYCValue.is_founder_or_cofounder ===
                                    0 && memberEnterpriseKYCValue.designation
                                    ? memberEnterpriseKYCValue.designation
                                    : "N/A"}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  component="th"
                                  style={{ fontWeight: "600" }}
                                  scope="row"
                                >
                                  Contact Number
                                </TableCell>
                                <TableCell align="left">
                                  {memberEnterpriseKYCValue.contact_number}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  component="th"
                                  style={{ fontWeight: "600" }}
                                  scope="row"
                                >
                                  Established Date
                                </TableCell>
                                <TableCell align="left">
                                  {memberEnterpriseKYCValue.established_date?.slice(
                                    0,
                                    10
                                  )}
                                </TableCell>
                              </TableRow>

                              <TableRow>
                                <TableCell
                                  component="th"
                                  style={{ fontWeight: "600" }}
                                  scope="row"
                                >
                                  Company Website/Social
                                </TableCell>
                                <TableCell align="left">
                                  {
                                    memberEnterpriseKYCValue.company_website_or_social
                                  }
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  component="th"
                                  style={{ fontWeight: "600" }}
                                  scope="row"
                                >
                                  Registered Address
                                </TableCell>
                                <TableCell align="left">
                                  {memberEnterpriseKYCValue.registered_address}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  component="th"
                                  style={{ fontWeight: "600" }}
                                  scope="row"
                                >
                                  No. of Founders
                                </TableCell>
                                <TableCell align="left">
                                  {memberEnterpriseKYCValue.no_of_founders}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  component="th"
                                  style={{ fontWeight: "600" }}
                                  scope="row"
                                >
                                  Structure of Enterprise
                                </TableCell>
                                <TableCell align="left">
                                  {
                                    memberEnterpriseKYCValue.structure_of_enterprise
                                  }
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  component="th"
                                  style={{ fontWeight: "600" }}
                                  scope="row"
                                >
                                  Pan/Vat
                                </TableCell>
                                <TableCell align="left">
                                  {memberEnterpriseKYCValue.pan_or_vat
                                    ? memberEnterpriseKYCValue.pan_or_vat
                                    : "N/A"}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  component="th"
                                  style={{ fontWeight: "600" }}
                                  scope="row"
                                >
                                  Profile of Founder/Co-Founder
                                </TableCell>
                                <TableCell align="left">
                                  {
                                    memberEnterpriseKYCValue.profile_of_founder_or_cofounder
                                  }
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  component="th"
                                  style={{ fontWeight: "600" }}
                                  scope="row"
                                >
                                  No. of Employees
                                </TableCell>
                                <TableCell align="left">
                                  {memberEnterpriseKYCValue.no_of_employees}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  component="th"
                                  style={{ fontWeight: "600" }}
                                  scope="row"
                                >
                                  Deal Title
                                </TableCell>
                                <TableCell align="left">
                                  {memberEnterpriseKYCValue.deal_title
                                    ? memberEnterpriseKYCValue.deal_title
                                    : "N/A"}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  component="th"
                                  style={{ fontWeight: "600" }}
                                  scope="row"
                                >
                                  Deal Overview
                                </TableCell>
                                <TableCell align="left">
                                  {memberEnterpriseKYCValue.deal_overview
                                    ? memberEnterpriseKYCValue.deal_overview
                                    : "N/A"}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  component="th"
                                  style={{ fontWeight: "600" }}
                                  scope="row"
                                >
                                  Sector
                                </TableCell>
                                <TableCell align="left">{sectorName}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  component="th"
                                  style={{ fontWeight: "600" }}
                                  scope="row"
                                >
                                  About Company
                                </TableCell>
                                <TableCell align="left">
                                  {memberEnterpriseKYCValue.about_company}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  component="th"
                                  style={{ fontWeight: "600" }}
                                  scope="row"
                                >
                                  Target Product Description
                                </TableCell>
                                <TableCell align="left">
                                  {
                                    memberEnterpriseKYCValue.target_product_description
                                  }
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  component="th"
                                  style={{ fontWeight: "600" }}
                                  scope="row"
                                >
                                  Headquarters Location
                                </TableCell>
                                <TableCell align="left">
                                  {memberEnterpriseKYCValue.headquarters}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  component="th"
                                  style={{ fontWeight: "600" }}
                                  scope="row"
                                >
                                  Company Operate
                                </TableCell>
                                <TableCell align="left">
                                  {
                                    provinceList.find(
                                      (province) =>
                                        province.id ===
                                        memberEnterpriseKYCValue.company_operate
                                    )?.label
                                  }
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  component="th"
                                  style={{ fontWeight: "600" }}
                                  scope="row"
                                >
                                  Create Positive Impact
                                </TableCell>
                                <TableCell align="left">
                                  {memberEnterpriseKYCValue?.create_positive_impact ===
                                  "1"
                                    ? "Yes"
                                    : memberEnterpriseKYCValue?.create_positive_impact ===
                                      "0"
                                    ? "No"
                                    : memberEnterpriseKYCValue?.create_positive_impact ===
                                      "3"
                                    ? "Maybe"
                                    : ""}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  component="th"
                                  style={{ fontWeight: "600" }}
                                  scope="row"
                                >
                                  Sdg
                                </TableCell>
                                <TableCell align="left">
                                  {memberEnterpriseKYCValue?.create_positive_impact ===
                                  "0"
                                    ? "N/A"
                                    : sdgNames}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  component="th"
                                  style={{ fontWeight: "600" }}
                                  scope="row"
                                >
                                  Mitigates Risks
                                </TableCell>
                                <TableCell align="left">
                                  {memberEnterpriseKYCValue.mitigates_risks}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  component="th"
                                  style={{ fontWeight: "600" }}
                                  scope="row"
                                >
                                  About Stakeholders
                                </TableCell>
                                <TableCell align="left">
                                  {memberEnterpriseKYCValue.about_stakeholders}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  component="th"
                                  style={{ fontWeight: "600" }}
                                  scope="row"
                                >
                                  Total Investment
                                </TableCell>
                                <TableCell align="left">
                                  {
                                    memberEnterpriseKYCValue.amount_of_investment
                                  }
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  component="th"
                                  style={{ fontWeight: "600" }}
                                  scope="row"
                                >
                                  Current Financial
                                </TableCell>
                                <TableCell align="left">
                                  {memberEnterpriseKYCValue.current_financial}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  component="th"
                                  style={{ fontWeight: "600" }}
                                  scope="row"
                                >
                                  Raise Investment
                                </TableCell>
                                <TableCell align="left">
                                  {memberEnterpriseKYCValue.raise_investment}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  component="th"
                                  style={{ fontWeight: "600" }}
                                  scope="row"
                                >
                                  Amount/Percentage Breakdown
                                </TableCell>
                                <TableCell align="left">
                                  {
                                    memberEnterpriseKYCValue.amount_or_percentage_breakdown
                                  }
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  component="th"
                                  style={{ fontWeight: "600" }}
                                  scope="row"
                                >
                                  Mode of Funding
                                </TableCell>
                                <TableCell align="left">
                                  {memberEnterpriseKYCValue.mode_of_funding}
                                </TableCell>
                              </TableRow>
                              {memberEnterpriseKYCValue.mode_of_funding ===
                                "Others" && (
                                <>
                                  <TableRow>
                                    <TableCell
                                      component="th"
                                      style={{ fontWeight: "600" }}
                                      scope="row"
                                    >
                                      Mode of Funding Others
                                    </TableCell>
                                    <TableCell align="left">
                                      {memberEnterpriseKYCValue.mode_of_funding_others
                                        ? memberEnterpriseKYCValue.mode_of_funding_others
                                        : "N/A"}
                                    </TableCell>
                                  </TableRow>
                                </>
                              )}

                              <TableRow>
                                <TableCell
                                  component="th"
                                  style={{ fontWeight: "600" }}
                                  scope="row"
                                >
                                  Non Financial Support
                                </TableCell>
                                <TableCell align="left">
                                  {nonFinancialListNames}
                                </TableCell>
                              </TableRow>
                              {memberEnterpriseKYCValue?.non_financial_support?.includes(
                                7
                              ) && (
                                <>
                                  <TableRow>
                                    <TableCell
                                      component="th"
                                      style={{ fontWeight: "600" }}
                                      scope="row"
                                    >
                                      Please describe non-financial support
                                      required
                                    </TableCell>
                                    <TableCell align="left">
                                      {memberEnterpriseKYCValue.non_financial_support_other
                                        ? memberEnterpriseKYCValue.non_financial_support_other
                                        : "N/A"}
                                    </TableCell>
                                  </TableRow>
                                </>
                              )}
                              {docPanVat.length > 0 &&
                                docPanVat?.slice(0, 5)?.map((data) => (
                                  <>
                                    <TableRow>
                                      <TableCell
                                        component="th"
                                        style={{ fontWeight: "600" }}
                                        scope="row"
                                      >
                                        Charter Doc (PAN/VAT)
                                      </TableCell>

                                      <TableCell align="left">
                                        {data.charter_doc_file ? (
                                          <>
                                            {data.charter_doc_file
                                              .toLowerCase()
                                              .endsWith(".pdf") ? (
                                              <a
                                                href={`${API_URL}/uploads/kyc/enterprise/charter_docs/${data.charter_doc_file}`}
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                View PDF
                                              </a>
                                            ) : data.charter_doc_file
                                                .toLowerCase()
                                                .endsWith(".xlsx") ? (
                                              <a
                                                href={`${API_URL}/uploads/kyc/enterprise/charter_docs/${data.charter_doc_file}`}
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                View Excel
                                              </a>
                                            ) : data.charter_doc_file
                                                .toLowerCase()
                                                .endsWith(".docx") ? (
                                              <a
                                                href={`${API_URL}/uploads/kyc/enterprise/charter_docs/${data.charter_doc_file}`}
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                View Docs
                                              </a>
                                            ) : (
                                              <img
                                                src={`${API_URL}/uploads/kyc/enterprise/charter_docs/${data.charter_doc_file}`}
                                                alt=""
                                                height="50px"
                                                width="50px"
                                                style={{
                                                  cursor: "pointer",
                                                  objectFit: "contain",
                                                }}
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  const url = e.target.src;
                                                  window.open(url, "_blank");
                                                }}
                                              />
                                            )}
                                          </>
                                        ) : (
                                          <span style={{ color: "red" }}>
                                            No document available
                                          </span>
                                        )}
                                      </TableCell>
                                    </TableRow>
                                  </>
                                ))}
                              {docMobilization.length > 0 &&
                                docMobilization?.slice(0, 5)?.map((data) => (
                                  <>
                                    <TableRow>
                                      <TableCell
                                        component="th"
                                        style={{ fontWeight: "600" }}
                                        scope="row"
                                      >
                                        Mobilization Plan Doc
                                      </TableCell>

                                      <TableCell align="left">
                                        {data.mobilization_plan_doc_file ? (
                                          <>
                                            {data.mobilization_plan_doc_file
                                              .toLowerCase()
                                              .endsWith(".pdf") ? (
                                              <a
                                                href={`${API_URL}/uploads/kyc/enterprise/mobilization_plan_docs/${data.mobilization_plan_doc_file}`}
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                View PDF
                                              </a>
                                            ) : data.mobilization_plan_doc_file
                                                .toLowerCase()
                                                .endsWith(".xlsx") ? (
                                              <a
                                                href={`${API_URL}/uploads/kyc/enterprise/mobilization_plan_docs/${data.mobilization_plan_doc_file}`}
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                View Excel
                                              </a>
                                            ) : data.mobilization_plan_doc_file
                                                .toLowerCase()
                                                .endsWith(".docx") ? (
                                              <a
                                                href={`${API_URL}/uploads/kyc/enterprise/mobilization_plan_docs/${data.mobilization_plan_doc_file}`}
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                View Docs
                                              </a>
                                            ) : (
                                              <img
                                                src={`${API_URL}/uploads/kyc/enterprise/mobilization_plan_docs/${data.mobilization_plan_doc_file}`}
                                                alt=""
                                                height="50px"
                                                width="50px"
                                                style={{
                                                  cursor: "pointer",
                                                  objectFit: "contain",
                                                }}
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  const url = e.target.src;
                                                  window.open(url, "_blank");
                                                }}
                                              />
                                            )}
                                          </>
                                        ) : (
                                          <span style={{ color: "red" }}>
                                            No document available
                                          </span>
                                        )}
                                      </TableCell>
                                    </TableRow>
                                  </>
                                ))}
                              {docRevenue.length > 0 &&
                                docRevenue?.slice(0, 5)?.map((data) => (
                                  <>
                                    <TableRow>
                                      <TableCell
                                        component="th"
                                        style={{ fontWeight: "600" }}
                                        scope="row"
                                      >
                                        Revenue/Profitability Plan Doc
                                      </TableCell>

                                      <TableCell align="left">
                                        {data.revenue_profitability_doc_file ? (
                                          <>
                                            {data.revenue_profitability_doc_file
                                              .toLowerCase()
                                              .endsWith(".pdf") ? (
                                              <a
                                                href={`${API_URL}/uploads/kyc/enterprise/revenue_profitability_docs/${data.revenue_profitability_doc_file}`}
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                View PDF
                                              </a>
                                            ) : data.revenue_profitability_doc_file
                                                .toLowerCase()
                                                .endsWith(".xlsx") ? (
                                              <a
                                                href={`${API_URL}/uploads/kyc/enterprise/revenue_profitability_docs/${data.revenue_profitability_doc_file}`}
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                View Excel
                                              </a>
                                            ) : data.revenue_profitability_doc_file
                                                .toLowerCase()
                                                .endsWith(".docx") ? (
                                              <a
                                                href={`${API_URL}/uploads/kyc/enterprise/revenue_profitability_docs/${data.revenue_profitability_doc_file}`}
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                View Docs
                                              </a>
                                            ) : (
                                              <img
                                                src={`${API_URL}/uploads/kyc/enterprise/revenue_profitability_docs/${data.revenue_profitability_doc_file}`}
                                                alt=""
                                                height="50px"
                                                width="50px"
                                                style={{
                                                  cursor: "pointer",
                                                  objectFit: "contain",
                                                }}
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  const url = e.target.src;
                                                  window.open(url, "_blank");
                                                }}
                                              />
                                            )}
                                          </>
                                        ) : (
                                          <span style={{ color: "red" }}>
                                            No document available
                                          </span>
                                        )}
                                      </TableCell>
                                    </TableRow>
                                  </>
                                ))}
                              {docRegFile.length > 0 &&
                                docRegFile?.slice(0, 5)?.map((doc) => (
                                  <>
                                    <TableRow>
                                      <TableCell
                                        component="th"
                                        style={{ fontWeight: "600" }}
                                        scope="row"
                                      >
                                        Registration of Company Doc
                                      </TableCell>
                                      <TableCell align="left">
                                        {doc.registration_of_company_doc_file ? (
                                          <>
                                            {doc.registration_of_company_doc_file
                                              .toLowerCase()
                                              .endsWith(".pdf") ? (
                                              <a
                                                href={`${API_URL}/uploads/kyc/enterprise/registration_of_company_docs/${doc.registration_of_company_doc_file}`}
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                View PDF
                                              </a>
                                            ) : doc.registration_of_company_doc_file
                                                .toLowerCase()
                                                .endsWith(".xlsx") ? (
                                              <a
                                                href={`${API_URL}/uploads/kyc/enterprise/registration_of_company_docs/${doc.registration_of_company_doc_file}`}
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                View Excel
                                              </a>
                                            ) : doc.registration_of_company_doc_file
                                                .toLowerCase()
                                                .endsWith(".docx") ? (
                                              <a
                                                href={`${API_URL}/uploads/kyc/enterprise/registration_of_company_docs/${doc.registration_of_company_doc_file}`}
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                View Docs
                                              </a>
                                            ) : (
                                              <img
                                                src={`${API_URL}/uploads/kyc/enterprise/registration_of_company_docs/${doc.registration_of_company_doc_file}`}
                                                alt=""
                                                height="50px"
                                                width="50px"
                                                style={{
                                                  cursor: "pointer",
                                                  objectFit: "contain",
                                                }}
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  const url = e.target.src;
                                                  window.open(url, "_blank");
                                                }}
                                              />
                                            )}
                                          </>
                                        ) : (
                                          <span style={{ color: "red" }}>
                                            No document available
                                          </span>
                                        )}
                                      </TableCell>
                                    </TableRow>
                                  </>
                                ))}

                              {docFile.length > 0 &&
                                docFile?.slice(0, 5)?.map((doc) => (
                                  <>
                                    <TableRow>
                                      <TableCell
                                        component="th"
                                        style={{ fontWeight: "600" }}
                                        scope="row"
                                      >
                                        Uploaded Business Plan Doc
                                      </TableCell>
                                      <TableCell align="left">
                                        {doc.business_plan_doc_file ? (
                                          <>
                                            {doc.business_plan_doc_file
                                              .toLowerCase()
                                              .endsWith(".pdf") ? (
                                              <a
                                                href={`${API_URL}/uploads/kyc/enterprise/business_plan_docs/${doc.business_plan_doc_file}`}
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                View PDF
                                              </a>
                                            ) : doc.business_plan_doc_file
                                                .toLowerCase()
                                                .endsWith(".xlsx") ||
                                              doc.business_plan_doc_file
                                                .toLowerCase()
                                                .endsWith(".xls") ? (
                                              <a
                                                href={`${API_URL}/uploads/kyc/enterprise/business_plan_docs/${doc.business_plan_doc_file}`}
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                View Excel
                                              </a>
                                            ) : doc.business_plan_doc_file
                                                .toLowerCase()
                                                .endsWith(".docx") ||
                                              doc.business_plan_doc_file
                                                .toLowerCase()
                                                .endsWith(".doc") ? (
                                              <a
                                                href={`${API_URL}/uploads/kyc/enterprise/business_plan_docs/${doc.business_plan_doc_file}`}
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                View Doc
                                              </a>
                                            ) : (
                                              <img
                                                src={`${API_URL}/uploads/kyc/enterprise/business_plan_docs/${doc.business_plan_doc_file}`}
                                                alt=""
                                                height="50px"
                                                width="50px"
                                                style={{
                                                  cursor: "pointer",
                                                  objectFit: "contain",
                                                }}
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  const url = e.target.src;
                                                  window.open(url, "_blank");
                                                }}
                                              />
                                            )}
                                          </>
                                        ) : (
                                          <span style={{ color: "red" }}>
                                            No document available
                                          </span>
                                        )}
                                      </TableCell>
                                    </TableRow>
                                  </>
                                ))}
                              <TableRow>
                                <TableCell
                                  component="th"
                                  style={{ fontWeight: "600" }}
                                  scope="row"
                                >
                                  Terms Confirmation
                                </TableCell>
                                <TableCell align="left">
                                  {memberEnterpriseKYCValue.terms_confirmation
                                    ? "Yes"
                                    : "No"}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          paddingTop: "5px",
                          marginTop: "5px",
                          // borderTop: "1px solid #0b6885",
                        }}
                      >
                        <Button
                          variant="contained"
                          style={{
                            margin: "10px 0 0 10px",
                            backgroundColor: "#ed5e5e",
                          }}
                          onClick={handlePrint}
                        >
                          Print
                        </Button>
                      </div>
                    </>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default EnterpriseDetailKyc;

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React from "react";

const ConfirmDialog = ({ confirmDialog, setConfirmDialog }) => {
  return (
    <Dialog
      style={{
        padding: "theme.spacing(2)",
        position: "absolue",
        top: "theme.spacing(5)",
      }}
      open={confirmDialog.isOpen}
    >
      <DialogTitle></DialogTitle>
      <DialogContent style={{ textAlign: "center" }}>
        <Typography variant="h6">{confirmDialog.title}</Typography>
        <Typography variant="subtitle2">{confirmDialog.subTitle}</Typography>
      </DialogContent>
      <DialogActions style={{ justifyContent: "center" }}>
        <Button
          variant="contained"
          style={{ backgroundColor: "#ea3f4d" }}
          onClick={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}
        >
          No
        </Button>
        <Button
          variant="contained"
          style={{ backgroundColor: "#70d8bd" }}
          onClick={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;

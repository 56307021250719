import React, { useState } from "react";
import LoadingComp from "../../components/LoadingComp";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  postCreateEnterpriseAction,
  postEnterpriseBusinessPlanAction,
  postEnterpriseMobilizationPlanDocsAction,
  postEnterprisePanOrVatDocAction,
  postEnterpriseRegistrationCompAction,
  postEnterpriseRevenueProfitabilityDocsAction,
} from "../kyc-state-handler/KycActions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getAllSdgsFilterAction } from "../private-routes/PrivateActions";
import { getAllDealCategoryFilterAction } from "../private-routes/PrivateActions";
import { ToastContainer, toast } from "react-toastify";
import Popup from "../../components/Popup";
import UpdateKycForm from "./UpdateKycForm";

const nonFinancialList = [
  {
    id: 1,
    label: "Financial projections and valuation",
  },
  { id: 2, label: "Branding" },
  { id: 3, label: "Legal Services" },
  { id: 4, label: "Accounting Services" },
  { id: 5, label: "Auditing and Taxation Services" },
  { id: 6, label: "Not seeking any non-financial support" },
  { id: 7, label: "Others" },
];

const provinceList = [
  {
    id: 1,
    label: "Province 1",
  },
  {
    id: 2,
    label: "Madhesh Pradesh",
  },
  {
    id: 3,
    label: "Bagmati Pradesh",
  },
  { id: 4, label: "Gandaki Pradesh" },
  {
    id: 5,
    label: "Lumbini Pradesh",
  },
  {
    id: 6,
    label: "Karnali Pradesh",
  },
  { id: 7, label: "Sudurpaschim Pradesh" },
];

const ReviewKycForm = ({
  formData,
  docPanVat,
  setPanOrVatDocs,
  docFile,
  setFormDataDoc,
  docRegFile,
  setRegisterDoc,
  docMobilization,
  setMobilizationDocs,
  docRevenue,
  setRevenueDocs,
  setFormData,
  setOpenPopup,
}) => {
  const [loading, setLoading] = useState(false);
  const [sdgList, setSdgList] = useState([]);
  const [sectorList, setSectorList] = useState([]);
  const [editOpenPopup, setEditOpenPopup] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { allSdgsFilter } = useSelector((state) => state.getAllSdgsFilterList);

  const { allDealsCategoryFilter } = useSelector(
    (state) => state.getAllDealCategoryFilter
  );

  useEffect(() => {
    if (!allSdgsFilter) {
      dispatch(getAllSdgsFilterAction());
    }
    if (allSdgsFilter) {
      setSdgList(allSdgsFilter?.data);
    }
  }, [allSdgsFilter, dispatch]);

  useEffect(() => {
    if (!allDealsCategoryFilter) {
      dispatch(getAllDealCategoryFilterAction());
    }
    if (allDealsCategoryFilter) {
      setSectorList(allDealsCategoryFilter?.data);
    }
  }, [allDealsCategoryFilter, dispatch]);

  useEffect(() => {
    dispatch(getAllSdgsFilterAction());
    dispatch(getAllDealCategoryFilterAction());
  }, [dispatch]);

  const sdgNames = sdgList
    ?.filter((sdg) => formData?.sdg?.includes(sdg.sdg_id))
    ?.map((sdg) => <Chip className="me-1 mb-2" label={sdg?.sdg_name} />);

  const nonFinancialListNames = nonFinancialList
    ?.filter((nonFinancial) =>
      formData.non_financial_support?.includes(nonFinancial.id)
    )
    ?.map((nonFinancial) => (
      <Chip className="me-1 mb-2" label={nonFinancial.label} />
    ));

  const sectorName = sectorList
    ?.filter((sector) => formData?.sector?.includes(sector.deal_category_id))
    ?.map((sector) => sector.deal_cat_name)
    ?.join(", ");

  const provinceName = provinceList
    ?.filter((province) => formData?.company_operate?.includes(province.id))
    ?.map((province) => province.label)
    ?.join(", ");

  const handleEdit = (e) => {
    e.preventDefault();
    setEditOpenPopup(true);
  };

  const handleSubmit = () => {
    if (Object.keys(formData).length === 0 || formData === "") {
      window.alert("Please fill out the form!");
    } else {
      dispatch(postCreateEnterpriseAction(formData)).then((res) => {
        const enterpriseKycId = res?.data?.enterprise_kyc_id;

        if (docFile.business_plan_docs) {
          dispatch(
            postEnterpriseBusinessPlanAction(
              docFile.business_plan_docs,
              enterpriseKycId
            )
          );
        }
        if (docRegFile?.registration_of_company_docs) {
          dispatch(
            postEnterpriseRegistrationCompAction(
              docRegFile.registration_of_company_docs,
              enterpriseKycId
            )
          );
        }
        if (docPanVat?.charter_docs) {
          dispatch(
            postEnterprisePanOrVatDocAction(
              docPanVat?.charter_docs,
              enterpriseKycId
            )
          );
        }
        if (docMobilization?.mobilization_plan_docs) {
          dispatch(
            postEnterpriseMobilizationPlanDocsAction(
              docMobilization?.mobilization_plan_docs,
              enterpriseKycId
            )
          );
        }
        if (docRevenue?.revenue_profitability_docs) {
          dispatch(
            postEnterpriseRevenueProfitabilityDocsAction(
              docRevenue?.revenue_profitability_docs,
              enterpriseKycId
            )
          );
        }
        if (res?.status === 200) {
          toast.success(
            "Thank you for joining Million Deals, we will verify your information to fully activate your account; this process may take up to 2 business days."
          );

          setTimeout(() => {
            navigate("/dashboard");
          }, 1500);
        } else {
          // Handle error case where enterpriseKycId is null or undefined
          console.log("Error: enterpriseKycId is null or undefined");
        }
      });
    }
  };

  const fileNames = docPanVat?.charter_docs?.map((file) => file.name);
  const fileDocFileNames = docFile?.business_plan_docs?.map(
    (file) => file.name
  );
  const fileDocRegFileNames = docRegFile?.registration_of_company_docs?.map(
    (file) => file.name
  );
  const fileDocMobilizationNames = docMobilization?.mobilization_plan_docs?.map(
    (file) => file.name
  );
  const fileDocRevenueNames = docRevenue?.revenue_profitability_docs?.map(
    (file) => file.name
  );

  return (
    <>
      {loading ? (
        <LoadingComp />
      ) : (
        <>
          <div className="page-content-review">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <p>
                    If you are an enterprise looking for investments and/or 
                    professional services and advice, please fill the form below
                    to register your company in our platform. Our team at
                    Million Deals will reach out to you to take this forward.
                  </p>

                  <p>
                    Have any questions? Please contact us at:&nbsp;
                    <Link
                      to="info@milliondeals.com.np"
                      title="info@milliondeals.com.np"
                    >
                      info@milliondeals.com.np
                    </Link>
                  </p>

                  <>
                    <div id="printTable">
                      <TableContainer
                        component={Paper}
                        sx={{ maxHeight: "100%" }}
                      >
                        <Table
                          stickyHeader
                          aria-label="sticky table"
                          className="table-style "
                        >
                          <TableHead style={{ height: "50px" }}>
                            <TableRow
                              sx={{
                                "& :hover": {
                                  color: "white !important",
                                },
                                "& :focus:not(:focus-visible)": {
                                  color: "white !important",
                                },
                              }}
                            >
                              <TableCell
                                style={{
                                  backgroundColor: "#0b6885",
                                  padding: "0.5vw",
                                  letterSpacing: "0.5px",
                                  fontSize: "16px",
                                  fontWeight: "600",
                                  color: "white",
                                }}
                              >
                                TITLE
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  backgroundColor: "#0b6885",
                                  padding: "0.5vw",
                                  letterSpacing: "0.5px",
                                  fontSize: "16px",
                                  fontWeight: "600",
                                  color: "white",
                                }}
                              >
                                DESCRIPTION
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Full Name
                              </TableCell>
                              <TableCell align="left">
                                {formData.full_name}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Company Name
                              </TableCell>
                              <TableCell align="left">
                                {formData.company_name}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Founder/Co-Founder
                              </TableCell>
                              <TableCell align="left">
                                {formData.is_founder_or_cofounder === "1"
                                  ? "Yes"
                                  : "No"}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Founder/Co-Founder Name
                              </TableCell>
                              <TableCell align="left">
                                {formData.is_founder_or_cofounder !== "1" &&
                                formData.founder_or_cofounder_name
                                  ? formData.founder_or_cofounder_name
                                  : "N/A"}
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Designation
                              </TableCell>
                              <TableCell align="left">
                                {formData.is_founder_or_cofounder !== "1" &&
                                formData.designation
                                  ? formData?.designation
                                  : "N/A"}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Company Email
                              </TableCell>
                              <TableCell align="left">
                                {formData.company_email}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Contact Number
                              </TableCell>
                              <TableCell align="left">
                                {formData.contact_number}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Established Date
                              </TableCell>
                              <TableCell align="left">
                                {formData.established_date?.slice(0, 10)}
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Company Website/Social
                              </TableCell>
                              <TableCell align="left">
                                {formData.company_website_or_social}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                No. of Founders
                              </TableCell>
                              <TableCell align="left">
                                {formData.no_of_founders}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Structure of Enterprise
                              </TableCell>
                              <TableCell align="left">
                                {formData.structure_of_enterprise}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Registered Address
                              </TableCell>
                              <TableCell align="left">
                                {formData.registered_address}
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Company registration number and PAN/ VAT number
                              </TableCell>
                              <TableCell align="left">
                                {formData.pan_or_vat
                                  ? formData.pan_or_vat
                                  : "N/A"}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Structure of the Enterprise Other
                              </TableCell>
                              <TableCell align="left">
                                {formData.structure_of_enterprise_other
                                  ? formData.structure_of_enterprise_other
                                  : "N/A"}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Profile of Founder/Co-Founder
                              </TableCell>
                              <TableCell align="left">
                                {formData.profile_of_founder_or_cofounder}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                No. of Employees
                              </TableCell>
                              <TableCell align="left">
                                {formData.no_of_employees}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Deal Title
                              </TableCell>
                              <TableCell align="left">
                                {formData?.deal_title
                                  ? formData?.deal_title
                                  : "N/A"}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Deal Overview
                              </TableCell>
                              <TableCell align="left">
                                {formData?.deal_overview
                                  ? formData?.deal_overview
                                  : "N/A"}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Sector
                              </TableCell>
                              <TableCell align="left">{sectorName}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                About Company
                              </TableCell>
                              <TableCell align="left">
                                {formData.about_company}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Target Product Description
                              </TableCell>
                              <TableCell align="left">
                                {formData.target_product_description}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Headquarters Location
                              </TableCell>
                              <TableCell align="left">
                                {formData.headquarters}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Company Operate
                              </TableCell>
                              <TableCell align="left">{provinceName}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Create Positive Impact
                              </TableCell>
                              <TableCell align="left">
                                {formData.create_positive_impact === "1"
                                  ? "Yes"
                                  : formData.create_positive_impact === "0"
                                  ? "No"
                                  : formData.create_positive_impact === "3"
                                  ? "Maybe"
                                  : ""}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Sdg
                              </TableCell>
                              <TableCell align="left">
                                {formData.create_positive_impact === "0"
                                  ? "N/A"
                                  : sdgNames}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Mitigates Risks
                              </TableCell>
                              <TableCell align="left">
                                {formData.mitigates_risks}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                About Stakeholders
                              </TableCell>
                              <TableCell align="left">
                                {formData.about_stakeholders}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Total Investment
                              </TableCell>
                              <TableCell align="left">
                                {formData.amount_of_investment}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Current Financial
                              </TableCell>
                              <TableCell align="left">
                                {formData.current_financial}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Terms of EBITDA
                              </TableCell>
                              <TableCell align="left">
                                {formData.terms_of_ebitda}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Raise of Investment
                              </TableCell>
                              <TableCell align="left">
                                {formData.raise_investment}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Amount or Percentage Breakdown
                              </TableCell>
                              <TableCell align="left">
                                {formData.amount_or_percentage_breakdown}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Mode of Funding
                              </TableCell>
                              <TableCell align="left">
                                {formData.mode_of_funding}
                              </TableCell>
                            </TableRow>
                            {formData?.mode_of_funding === "Others" && (
                              <TableRow>
                                <TableCell
                                  component="th"
                                  style={{ fontWeight: "600" }}
                                  scope="row"
                                >
                                  Mode of Funding Others
                                </TableCell>
                                <TableCell align="left">
                                  {formData.mode_of_funding_others}
                                </TableCell>
                              </TableRow>
                            )}

                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Non Financial Support
                              </TableCell>
                              <TableCell align="left">
                                {nonFinancialListNames}
                              </TableCell>
                            </TableRow>

                            {formData?.non_financial_support?.includes(7) && (
                              <>
                                <TableRow>
                                  <TableCell
                                    component="th"
                                    style={{ fontWeight: "600" }}
                                    scope="row"
                                  >
                                    Please describe non-financial support
                                    required
                                  </TableCell>
                                  <TableCell align="left">
                                    {formData.non_financial_support_other
                                      ? formData.non_financial_support_other
                                      : "N/A"}
                                  </TableCell>
                                </TableRow>
                              </>
                            )}
                            {fileNames &&
                              fileNames.length > 0 &&
                              fileNames?.slice(0, 5)?.map((name) => (
                                <>
                                  <TableRow>
                                    <TableCell
                                      component="th"
                                      style={{ fontWeight: "600" }}
                                      scope="row"
                                    >
                                      Charter Doc (PAN/VAT)
                                    </TableCell>

                                    <TableCell align="left">
                                      <div>{name}</div>
                                    </TableCell>
                                  </TableRow>
                                </>
                              ))}
                            {fileDocMobilizationNames &&
                              fileDocMobilizationNames.length > 0 &&
                              fileDocMobilizationNames
                                ?.slice(0, 5)
                                ?.map((name) => (
                                  <>
                                    <TableRow>
                                      <TableCell
                                        component="th"
                                        style={{ fontWeight: "600" }}
                                        scope="row"
                                      >
                                        Mobilization Plan Doc
                                      </TableCell>

                                      <TableCell align="left">
                                        <div>{name}</div>
                                      </TableCell>
                                    </TableRow>
                                  </>
                                ))}
                            {fileDocRevenueNames &&
                              fileDocRevenueNames.length > 0 &&
                              fileDocRevenueNames?.slice(0, 5)?.map((name) => (
                                <>
                                  <TableRow>
                                    <TableCell
                                      component="th"
                                      style={{ fontWeight: "600" }}
                                      scope="row"
                                    >
                                      Revenue/Profitability Plan Doc
                                    </TableCell>

                                    <TableCell align="left">
                                      <div>{name}</div>
                                    </TableCell>
                                  </TableRow>
                                </>
                              ))}
                            {fileDocRegFileNames &&
                              fileDocRegFileNames.length > 0 &&
                              fileDocRegFileNames?.slice(0, 5)?.map((name) => (
                                <>
                                  <TableRow>
                                    <TableCell
                                      component="th"
                                      style={{ fontWeight: "600" }}
                                      scope="row"
                                    >
                                      Registration of Company Doc
                                    </TableCell>

                                    <TableCell align="left">
                                      <div>{name}</div>
                                    </TableCell>
                                  </TableRow>
                                </>
                              ))}
                            {fileDocFileNames &&
                              fileDocFileNames.length > 0 &&
                              fileDocFileNames?.slice(0, 5)?.map((name) => (
                                <>
                                  <TableRow>
                                    <TableCell
                                      component="th"
                                      style={{ fontWeight: "600" }}
                                      scope="row"
                                    >
                                      Uploaded Business Plan Doc
                                    </TableCell>

                                    <TableCell align="left">
                                      <div>{name}</div>
                                    </TableCell>
                                  </TableRow>
                                </>
                              ))}

                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ fontWeight: "600" }}
                                scope="row"
                              >
                                Terms Confirmation
                              </TableCell>
                              <TableCell align="left">
                                {formData.terms_confirmation === "1"
                                  ? "Yes"
                                  : "No"}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        paddingTop: "5px",
                        marginTop: "5px",
                        // borderTop: "1px solid #0b6885",
                      }}
                    >
                      <Button
                        variant="contained"
                        onClick={handleEdit}
                        style={{
                          margin: "10px 0 0 10px",
                          backgroundColor: "rgb(42, 128, 249)",
                          color: "#ffffff",
                        }}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="contained"
                        style={{
                          margin: "10px 0 0 10px",
                          backgroundColor: "#0b6885",
                        }}
                        onClick={handleSubmit}
                      >
                        Submit
                      </Button>
                      <ToastContainer
                        position="top-right"
                        autoClose={2000}
                        theme="colored"
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                      />
                    </div>
                  </>
                </div>
              </div>
            </div>
          </div>
          <Popup
            openPopup={editOpenPopup}
            setOpenPopup={setEditOpenPopup}
            title="Edit KYC Form (*Click X button to save your changes.)"
          >
            <UpdateKycForm
              setEditOpenPopup={setEditOpenPopup}
              formData={formData}
              docPanVat={docPanVat}
              setPanOrVatDocs={setPanOrVatDocs}
              docFile={docFile}
              setFormDataDoc={setFormDataDoc}
              docRegFile={docRegFile}
              setRegisterDoc={setRegisterDoc}
              docMobilization={docMobilization}
              setMobilizationDocs={setMobilizationDocs}
              docRevenue={docRevenue}
              setRevenueDocs={setRevenueDocs}
              setFormData={setFormData}
              setOpenPopup={setOpenPopup}
            />
          </Popup>
        </>
      )}
    </>
  );
};

export default ReviewKycForm;

import React, { useEffect, useState } from "react";
import CustomContainer from "../../../components/CustomContainer";
import Topbar from "../../../components/Topbar";
import { useDispatch, useSelector } from "react-redux";
import {
  Toolbar,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import BackspaceIcon from "@mui/icons-material/Backspace";
import {
  getAllSdgsFilterAdminAction,
  getCategoryListAdminAdminAction,
  putMemberVerifyAction,
} from "./AllMembersAction";
import LoadingComp from "../../../components/LoadingComp";
import { postNewDealsAction } from "../../manage-deals/ManageDealsActions";
import { API_URL } from "../../../api/axios";
import Popup from "../../../components/Popup";
import UpdateEnterpriseKYCFormAdmin from "./UpdateEnterpriseKYCFormAdmin";
import { ToastContainer, toast } from "react-toastify";

const nonFinancialList = [
  {
    id: 1,
    label: "Financial projections and valuation",
  },
  { id: 2, label: "Branding" },
  { id: 3, label: "Legal Services" },
  { id: 4, label: "Accounting Services" },
  { id: 5, label: "Auditing and Taxation Services" },
  { id: 6, label: "Not seeking any non-financial support" },
  { id: 7, label: "Others" },
];

const provinceList = [
  {
    id: 1,
    label: "Province 1",
  },
  {
    id: 2,
    label: "Madhesh Pradesh",
  },
  {
    id: 3,
    label: "Bagmati Pradesh",
  },
  { id: 4, label: "Gandaki Pradesh" },
  {
    id: 5,
    label: "Lumbini Pradesh",
  },
  {
    id: 6,
    label: "Karnali Pradesh",
  },
  { id: 7, label: "Sudurpaschim Pradesh" },
];

const EnterpriceKYCProfile = () => {
  const [enterpriseValue, setEnterpriseValue] = useState(
    JSON.parse(localStorage.getItem("kycValues")) || {}
  );
  const [buttonValue, setButtonValue] = useState(
    JSON.parse(localStorage.getItem("buttonValues")) || {}
  );

  const [regFileAdmin, setRegFileAdmin] = useState(
    JSON.parse(localStorage.getItem("regFileAdmin")) || []
  );
  const [businessDocAdmin, setBusinessDocAdmin] = useState(
    JSON.parse(localStorage.getItem("businessDocAdmin")) || []
  );
  const [panOrVatAdmin, setPanOrVatAdmin] = useState(
    JSON.parse(localStorage.getItem("panOrVatAdmin")) || []
  );
  const [mobilizationAdmin, setMobilizationAdmin] = useState(
    JSON.parse(localStorage.getItem("mobilizationAdmin")) || []
  );
  const [revenueAdmin, setRevenueAdmin] = useState(
    JSON.parse(localStorage.getItem("revenueAdmin")) || []
  );

  const [sectorList, setSectorList] = useState([]);
  const [sdgList, setSdgList] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [openEnterpriseKycPopup, setOpenEnterpriseKycPopup] = useState(false);

  const { singleMemberProfile, loading } = useSelector(
    (state) => state.getSingleMemberProfileList
  );

  const { adminBusinessDoc } = useSelector(
    (state) => state.getEnterpriseBusinessPlanDocAdmin
  );
  const { adminRegDoc } = useSelector(
    (state) => state.getEnterpriseRegistrationCompDocAdmin
  );
  const { adminPanVatDoc } = useSelector(
    (state) => state.getEnterprisePanVatDocAdmin
  );
  const { adminMobilization } = useSelector(
    (state) => state.getEnterpriseMobilizationPlanDocAdmin
  );
  const { adminRevenue } = useSelector(
    (state) => state.getEnterpriseRevenueProfitabilityDocAdmin
  );

  const { sdgListAdmin } = useSelector((state) => state.getSdgListAdmin);

  const { categoryListAdmin } = useSelector(
    (state) => state.getCategoryListAdmin
  );

  const makeADeal = {
    unique_id: enterpriseValue.unique_id,
    enterprise_kyc_id: enterpriseValue.enterprise_kyc_id,
    deal_cat_id: enterpriseValue.sector,
    province_id: enterpriseValue.company_operate,
    deal_title: enterpriseValue.deal_title || "",
    deal_overview: enterpriseValue.deal_overview || "",
    amount: enterpriseValue.amount_of_investment,
    location: enterpriseValue.headquarters,
    sdg_id: enterpriseValue.sdg,
    created_by: enterpriseValue.deal_created_by,
  };

  const acceptKycProfile = () => {
    if (
      dispatch(
        putMemberVerifyAction(
          { is_verified: 1 },
          singleMemberProfile?.data?.member_profile_id
        )
      )
    ) {
      toast.success("Verified Successfully");
      setTimeout(() => {
        navigate("/admin/members-list");
      }, 1200);
    }
  };

  const rejectKycProfile = () => {
    if (
      dispatch(
        putMemberVerifyAction(
          { is_verified: 2 },
          singleMemberProfile?.data?.member_profile_id
        )
      )
    ) {
      toast.success("Rejected Successfully");
      setTimeout(() => {
        navigate("/admin/members-list");
      }, 1200);
    }
  };

  useEffect(() => {
    localStorage.setItem("kycValues", JSON.stringify(enterpriseValue));
  }, [enterpriseValue]);

  useEffect(() => {
    localStorage.setItem("buttonValues", JSON.stringify(buttonValue));
  }, [buttonValue]);

  useEffect(() => {
    localStorage.setItem("businessDocAdmin", JSON.stringify(businessDocAdmin));
  }, [businessDocAdmin]);

  useEffect(() => {
    localStorage.setItem("regFileAdmin", JSON.stringify(regFileAdmin));
  }, [regFileAdmin]);

  useEffect(() => {
    localStorage.setItem("panOrVatAdmin", JSON.stringify(panOrVatAdmin));
  }, [panOrVatAdmin]);

  useEffect(() => {
    localStorage.setItem(
      "mobilizationAdmin",
      JSON.stringify(mobilizationAdmin)
    );
  }, [mobilizationAdmin]);

  useEffect(() => {
    localStorage.setItem("revenueAdmin", JSON.stringify(revenueAdmin));
  }, [revenueAdmin]);

  useEffect(() => {
    if (singleMemberProfile) {
      setEnterpriseValue(singleMemberProfile?.data?.enterprise_kyc);
    }
    if (singleMemberProfile) {
      setButtonValue(singleMemberProfile?.data);
    }
  }, [singleMemberProfile]);

  useEffect(() => {
    if (adminBusinessDoc) {
      setBusinessDocAdmin(adminBusinessDoc?.data);
    }
  }, [adminBusinessDoc]);

  useEffect(() => {
    if (adminRegDoc) {
      setRegFileAdmin(adminRegDoc?.data);
    }
  }, [adminRegDoc]);

  useEffect(() => {
    if (adminPanVatDoc) {
      setPanOrVatAdmin(adminPanVatDoc?.data);
    }
  }, [adminPanVatDoc]);

  useEffect(() => {
    if (adminMobilization) {
      setMobilizationAdmin(adminMobilization?.data);
    }
  }, [adminMobilization]);

  useEffect(() => {
    if (adminRevenue) {
      setRevenueAdmin(adminRevenue?.data);
    }
  }, [adminRevenue]);

  useEffect(() => {
    if (!sdgListAdmin) {
      dispatch(getAllSdgsFilterAdminAction());
    }
    if (sdgListAdmin) {
      setSdgList(sdgListAdmin?.data);
    }
  }, [sdgListAdmin, dispatch]);

  useEffect(() => {
    if (!categoryListAdmin) {
      dispatch(getCategoryListAdminAdminAction());
    }
    if (categoryListAdmin) {
      setSectorList(categoryListAdmin?.data);
    }
  }, [categoryListAdmin, dispatch]);

  useEffect(() => {
    dispatch(getAllSdgsFilterAdminAction());
    dispatch(getCategoryListAdminAdminAction());
  }, [dispatch]);

  const backSpaceHandler = (e) => {
    e.preventDefault();
    navigate("/admin/members-list/profile");
  };

  const handlePrint = () => {
    var divToPrint = document.getElementById("printTable");
    var newWin = window.open("");
    newWin.document.write(divToPrint.outerHTML);
    newWin.print();
    newWin.close();
  };

  const handleUpdateKyc = (e) => {
    e.preventDefault();
    setOpenEnterpriseKycPopup(true);
  };

  const handleDeal = (e) => {
    e.preventDefault();
    dispatch(postNewDealsAction(makeADeal)).then((res) => {
      if (res?.status === 200) {
        toast.success(res.message);
        setTimeout(() => {
          navigate("/admin/manage-deals");
        }, 1200);
      } else {
        toast.error("Error Occured while creating Deals");
      }
    });
  };

  const sdgNames = sdgList
    ?.filter((sdg) => enterpriseValue?.sdg?.includes(sdg.sdg_id))
    ?.map((sdg) => <Chip className="me-1 mb-2" label={sdg.sdg_name} />);

  const nonFinancialListNames = nonFinancialList
    ?.filter((nonFinancial) =>
      enterpriseValue.non_financial_support?.includes(nonFinancial.id)
    )
    ?.map((nonFinancial) => (
      <Chip className="me-1 mb-2" label={nonFinancial.label} />
    ));

  const selectedSectorId = parseInt(enterpriseValue?.sector) || 0;

  const sectorName = sectorList
    ?.filter((sector) => sector.deal_category_id === selectedSectorId)
    ?.map((sector) => sector.deal_cat_name);

  return (
    <>
      <CustomContainer>
        <Toolbar>
          <Topbar subtitle="Enterprise KYC Detail" />{" "}
          <BackspaceIcon
            onClick={backSpaceHandler}
            style={{
              color: "#0b6885",
              marginBottom: "12px",
              cursor: "pointer",
              fontSize: "26px",
            }}
          />{" "}
        </Toolbar>
        {loading ? (
          <LoadingComp />
        ) : (
          <>
            <div id="printTable">
              <TableContainer component={Paper} sx={{ maxHeight: "100%" }}>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  className="table-style "
                >
                  <TableHead style={{ height: "50px" }}>
                    <TableRow
                      sx={{
                        "& :hover": {
                          color: "white !important",
                        },
                        "& :focus:not(:focus-visible)": {
                          color: "white !important",
                        },
                      }}
                    >
                      <TableCell
                        style={{
                          backgroundColor: "#0b6885",
                          padding: "0.5vw",
                          letterSpacing: "0.5px",
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "white",
                        }}
                      >
                        TITLE
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          backgroundColor: "#0b6885",
                          padding: "0.5vw",
                          letterSpacing: "0.5px",
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "white",
                        }}
                      >
                        DESCRIPTION
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        component="th"
                        style={{ fontWeight: "600" }}
                        scope="row"
                      >
                        Full Name
                      </TableCell>
                      <TableCell align="left">
                        {enterpriseValue.full_name}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        style={{ fontWeight: "600" }}
                        scope="row"
                      >
                        Company Name
                      </TableCell>
                      <TableCell align="left">
                        {enterpriseValue.company_name}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        style={{ fontWeight: "600" }}
                        scope="row"
                      >
                        Founder/Co-Founder
                      </TableCell>
                      <TableCell align="left">
                        {enterpriseValue.is_founder_or_cofounder === 1
                          ? "Yes"
                          : "No"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        style={{ fontWeight: "600" }}
                        scope="row"
                      >
                        Founder/Co-Founder Name
                      </TableCell>
                      <TableCell align="left">
                        {enterpriseValue.is_founder_or_cofounder !== 1 &&
                        enterpriseValue.founder_or_cofounder_name
                          ? enterpriseValue.founder_or_cofounder_name
                          : "N/A"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        style={{ fontWeight: "600" }}
                        scope="row"
                      >
                        Company Email
                      </TableCell>
                      <TableCell align="left">
                        {enterpriseValue.company_email}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        style={{ fontWeight: "600" }}
                        scope="row"
                      >
                        Designation
                      </TableCell>
                      <TableCell align="left">
                        {enterpriseValue.is_founder_or_cofounder !== 1 &&
                        enterpriseValue.designation
                          ? enterpriseValue.designation
                          : "N/A"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        style={{ fontWeight: "600" }}
                        scope="row"
                      >
                        Contact Number
                      </TableCell>
                      <TableCell align="left">
                        {enterpriseValue.contact_number}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        style={{ fontWeight: "600" }}
                        scope="row"
                      >
                        Established Date
                      </TableCell>
                      <TableCell align="left">
                        {enterpriseValue.established_date?.slice(0, 10)}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        component="th"
                        style={{ fontWeight: "600" }}
                        scope="row"
                      >
                        Company Website/Social
                      </TableCell>
                      <TableCell align="left">
                        {enterpriseValue.company_website_or_social}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        style={{ fontWeight: "600" }}
                        scope="row"
                      >
                        Registered Address
                      </TableCell>
                      <TableCell align="left">
                        {enterpriseValue.registered_address}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        style={{ fontWeight: "600" }}
                        scope="row"
                      >
                        No. of Founders
                      </TableCell>
                      <TableCell align="left">
                        {enterpriseValue.no_of_founders}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        style={{ fontWeight: "600" }}
                        scope="row"
                      >
                        Structure of Enterprise
                      </TableCell>
                      <TableCell align="left">
                        {enterpriseValue.structure_of_enterprise}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        style={{ fontWeight: "600" }}
                        scope="row"
                      >
                        Pan/Vat
                      </TableCell>
                      <TableCell align="left">
                        {enterpriseValue.pan_or_vat
                          ? enterpriseValue.pan_or_vat
                          : "N/A"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        style={{ fontWeight: "600" }}
                        scope="row"
                      >
                        Profile of Founder/Co-Founder
                      </TableCell>
                      <TableCell align="left">
                        {enterpriseValue.profile_of_founder_or_cofounder}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        style={{ fontWeight: "600" }}
                        scope="row"
                      >
                        No. of Employees
                      </TableCell>
                      <TableCell align="left">
                        {enterpriseValue.no_of_employees}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        style={{ fontWeight: "600" }}
                        scope="row"
                      >
                        Deal Title
                      </TableCell>
                      <TableCell align="left">
                        {enterpriseValue.deal_title
                          ? enterpriseValue.deal_title
                          : "N/A"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        style={{ fontWeight: "600" }}
                        scope="row"
                      >
                        Deal Overview
                      </TableCell>
                      <TableCell align="left">
                        {enterpriseValue.deal_overview
                          ? enterpriseValue.deal_overview
                          : "N/A"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        style={{ fontWeight: "600" }}
                        scope="row"
                      >
                        Sector
                      </TableCell>
                      <TableCell align="left">{sectorName}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        style={{ fontWeight: "600" }}
                        scope="row"
                      >
                        About Company
                      </TableCell>
                      <TableCell align="left">
                        {enterpriseValue.about_company}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        style={{ fontWeight: "600" }}
                        scope="row"
                      >
                        Target Product Description
                      </TableCell>
                      <TableCell align="left">
                        {enterpriseValue.target_product_description}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        style={{ fontWeight: "600" }}
                        scope="row"
                      >
                        Headquarters Location
                      </TableCell>
                      <TableCell align="left">
                        {enterpriseValue.headquarters}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        style={{ fontWeight: "600" }}
                        scope="row"
                      >
                        Company Operate
                      </TableCell>
                      <TableCell align="left">
                        {
                          provinceList.find(
                            (province) =>
                              province.id === enterpriseValue.company_operate
                          )?.label
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        style={{ fontWeight: "600" }}
                        scope="row"
                      >
                        Create Positive Impact
                      </TableCell>
                      <TableCell align="left">
                        {enterpriseValue.create_positive_impact === "1"
                          ? "Yes"
                          : ""}
                        {enterpriseValue.create_positive_impact === "0"
                          ? "No"
                          : ""}
                        {enterpriseValue.create_positive_impact === "3"
                          ? "Maybe"
                          : ""}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        style={{ fontWeight: "600" }}
                        scope="row"
                      >
                        Sdg
                      </TableCell>
                      <TableCell align="left">
                        {enterpriseValue.create_positive_impact === "0"
                          ? "N/A"
                          : sdgNames}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        style={{ fontWeight: "600" }}
                        scope="row"
                      >
                        Mitigates Risks
                      </TableCell>
                      <TableCell align="left">
                        {enterpriseValue.mitigates_risks}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        style={{ fontWeight: "600" }}
                        scope="row"
                      >
                        About Stakeholders
                      </TableCell>
                      <TableCell align="left">
                        {enterpriseValue.about_stakeholders}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        style={{ fontWeight: "600" }}
                        scope="row"
                      >
                        Total Investment
                      </TableCell>
                      <TableCell align="left">
                        {enterpriseValue.amount_of_investment}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        style={{ fontWeight: "600" }}
                        scope="row"
                      >
                        Current Financial
                      </TableCell>
                      <TableCell align="left">
                        {enterpriseValue.current_financial}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        style={{ fontWeight: "600" }}
                        scope="row"
                      >
                        Raise of Investment
                      </TableCell>
                      <TableCell align="left">
                        {enterpriseValue.raise_investment}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        style={{ fontWeight: "600" }}
                        scope="row"
                      >
                        Amount/Percentage Breakdown
                      </TableCell>
                      <TableCell align="left">
                        {enterpriseValue.amount_or_percentage_breakdown}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        style={{ fontWeight: "600" }}
                        scope="row"
                      >
                        Mode of Funding
                      </TableCell>
                      <TableCell align="left">
                        {enterpriseValue.mode_of_funding}
                      </TableCell>
                    </TableRow>
                    {enterpriseValue.mode_of_funding === "Others" && (
                      <>
                        <TableRow>
                          <TableCell
                            component="th"
                            style={{ fontWeight: "600" }}
                            scope="row"
                          >
                            Mode of Funding Others
                          </TableCell>
                          <TableCell align="left">
                            {enterpriseValue.mode_of_funding_others
                              ? enterpriseValue.mode_of_funding_others
                              : "N/A"}
                          </TableCell>
                        </TableRow>
                      </>
                    )}

                    <TableRow>
                      <TableCell
                        component="th"
                        style={{ fontWeight: "600" }}
                        scope="row"
                      >
                        Non Financial Support
                      </TableCell>
                      <TableCell align="left">
                        {nonFinancialListNames}
                      </TableCell>
                    </TableRow>

                    {enterpriseValue?.non_financial_support?.includes(7) && (
                      <>
                        <TableRow>
                          <TableCell
                            component="th"
                            style={{ fontWeight: "600" }}
                            scope="row"
                          >
                            Please describe non-financial support required
                          </TableCell>
                          <TableCell align="left">
                            {enterpriseValue.non_financial_support_other
                              ? enterpriseValue.non_financial_support_other
                              : "N/A"}
                          </TableCell>
                        </TableRow>
                      </>
                    )}

                    {panOrVatAdmin.length > 0 &&
                      panOrVatAdmin?.map((data) => (
                        <>
                          <TableRow>
                            <TableCell
                              component="th"
                              style={{ fontWeight: "600" }}
                              scope="row"
                            >
                              Charter Doc (PAN/VAT)
                            </TableCell>

                            <TableCell align="left">
                              {data.charter_doc_file ? (
                                <>
                                  {data.charter_doc_file
                                    .toLowerCase()
                                    .endsWith(".pdf") ? (
                                    <a
                                      href={`${API_URL}/uploads/kyc/enterprise/charter_docs/${data.charter_doc_file}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      View PDF
                                    </a>
                                  ) : data.charter_doc_file
                                      .toLowerCase()
                                      .endsWith(".xlsx") ? (
                                    <a
                                      href={`${API_URL}/uploads/kyc/enterprise/charter_docs/${data.charter_doc_file}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      View Excel
                                    </a>
                                  ) : data.charter_doc_file
                                      .toLowerCase()
                                      .endsWith(".docx") ? (
                                    <a
                                      href={`${API_URL}/uploads/kyc/enterprise/charter_docs/${data.charter_doc_file}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      View Docs
                                    </a>
                                  ) : (
                                    <img
                                      src={`${API_URL}/uploads/kyc/enterprise/charter_docs/${data.charter_doc_file}`}
                                      alt=""
                                      height="50px"
                                      width="50px"
                                      style={{
                                        cursor: "pointer",
                                        objectFit: "contain",
                                      }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        const url = e.target.src;
                                        window.open(url, "_blank");
                                      }}
                                    />
                                  )}
                                </>
                              ) : (
                                <span style={{ color: "red" }}>
                                  No document available
                                </span>
                              )}
                            </TableCell>
                          </TableRow>
                        </>
                      ))}
                    {mobilizationAdmin.length > 0 &&
                      mobilizationAdmin?.slice(0, 5)?.map((data) => (
                        <>
                          <TableRow>
                            <TableCell
                              component="th"
                              style={{ fontWeight: "600" }}
                              scope="row"
                            >
                              Mobilization Plan Doc
                            </TableCell>

                            <TableCell align="left">
                              {data?.mobilization_plan_doc_file ? (
                                <>
                                  {data?.mobilization_plan_doc_file
                                    .toLowerCase()
                                    .endsWith(".pdf") ? (
                                    <a
                                      href={`${API_URL}/uploads/kyc/enterprise/mobilization_plan_docs/${data.mobilization_plan_doc_file}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      View PDF
                                    </a>
                                  ) : data?.mobilization_plan_doc_file
                                      .toLowerCase()
                                      .endsWith(".xlsx") ? (
                                    <a
                                      href={`${API_URL}/uploads/kyc/enterprise/mobilization_plan_docs/${data.mobilization_plan_doc_file}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      View Excel
                                    </a>
                                  ) : data?.mobilization_plan_doc_file
                                      .toLowerCase()
                                      .endsWith(".docx") ? (
                                    <a
                                      href={`${API_URL}/uploads/kyc/enterprise/mobilization_plan_docs/${data.mobilization_plan_doc_file}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      View Docs
                                    </a>
                                  ) : (
                                    <img
                                      src={`${API_URL}/uploads/kyc/enterprise/mobilization_plan_docs/${data.mobilization_plan_doc_file}`}
                                      alt=""
                                      height="50px"
                                      width="50px"
                                      style={{
                                        cursor: "pointer",
                                        objectFit: "contain",
                                      }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        const url = e.target.src;
                                        window.open(url, "_blank");
                                      }}
                                    />
                                  )}
                                </>
                              ) : (
                                <span style={{ color: "red" }}>
                                  No document available
                                </span>
                              )}
                            </TableCell>
                          </TableRow>
                        </>
                      ))}
                    {revenueAdmin.length > 0 &&
                      revenueAdmin?.map((data) => (
                        <>
                          <TableRow>
                            <TableCell
                              component="th"
                              style={{ fontWeight: "600" }}
                              scope="row"
                            >
                              Revenue/Profitability Plan Doc
                            </TableCell>

                            <TableCell align="left">
                              {data.revenue_profitability_doc_file ? (
                                <>
                                  {data.revenue_profitability_doc_file
                                    .toLowerCase()
                                    .endsWith(".pdf") ? (
                                    <a
                                      href={`${API_URL}/uploads/kyc/enterprise/revenue_profitability_docs/${data.revenue_profitability_doc_file}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      View PDF
                                    </a>
                                  ) : data.revenue_profitability_doc_file
                                      .toLowerCase()
                                      .endsWith(".xlsx") ? (
                                    <a
                                      href={`${API_URL}/uploads/kyc/enterprise/revenue_profitability_docs/${data.revenue_profitability_doc_file}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      View Excel
                                    </a>
                                  ) : data.revenue_profitability_doc_file
                                      .toLowerCase()
                                      .endsWith(".docx") ? (
                                    <a
                                      href={`${API_URL}/uploads/kyc/enterprise/revenue_profitability_docs/${data.revenue_profitability_doc_file}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      View Docs
                                    </a>
                                  ) : (
                                    <img
                                      src={`${API_URL}/uploads/kyc/enterprise/revenue_profitability_docs/${data.revenue_profitability_doc_file}`}
                                      alt=""
                                      height="50px"
                                      width="50px"
                                      style={{
                                        cursor: "pointer",
                                        objectFit: "contain",
                                      }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        const url = e.target.src;
                                        window.open(url, "_blank");
                                      }}
                                    />
                                  )}
                                </>
                              ) : (
                                <span style={{ color: "red" }}>
                                  No document available
                                </span>
                              )}
                            </TableCell>
                          </TableRow>
                        </>
                      ))}
                    {regFileAdmin.length > 0 &&
                      regFileAdmin?.map((doc) => (
                        <>
                          <TableRow>
                            <TableCell
                              component="th"
                              style={{ fontWeight: "600" }}
                              scope="row"
                            >
                              Registration of Company Doc
                            </TableCell>
                            <TableCell align="left">
                              {doc.registration_of_company_doc_file ? (
                                <>
                                  {doc.registration_of_company_doc_file
                                    .toLowerCase()
                                    .endsWith(".pdf") ? (
                                    <a
                                      href={`${API_URL}/uploads/kyc/enterprise/registration_of_company_docs/${doc.registration_of_company_doc_file}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      View PDF
                                    </a>
                                  ) : doc.registration_of_company_doc_file
                                      .toLowerCase()
                                      .endsWith(".xlsx") ? (
                                    <a
                                      href={`${API_URL}/uploads/kyc/enterprise/registration_of_company_docs/${doc.registration_of_company_doc_file}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      View Excel
                                    </a>
                                  ) : doc.registration_of_company_doc_file
                                      .toLowerCase()
                                      .endsWith(".docx") ? (
                                    <a
                                      href={`${API_URL}/uploads/kyc/enterprise/registration_of_company_docs/${doc.registration_of_company_doc_file}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      View Docs
                                    </a>
                                  ) : (
                                    <img
                                      src={`${API_URL}/uploads/kyc/enterprise/registration_of_company_docs/${doc.registration_of_company_doc_file}`}
                                      alt=""
                                      height="50px"
                                      width="50px"
                                      style={{
                                        cursor: "pointer",
                                        objectFit: "contain",
                                      }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        const url = e.target.src;
                                        window.open(url, "_blank");
                                      }}
                                    />
                                  )}
                                </>
                              ) : (
                                <span style={{ color: "red" }}>
                                  No document available
                                </span>
                              )}
                            </TableCell>
                          </TableRow>
                        </>
                      ))}

                    {businessDocAdmin.length > 0 &&
                      businessDocAdmin.map((doc) => (
                        <>
                          <TableRow>
                            <TableCell
                              component="th"
                              style={{ fontWeight: "600" }}
                              scope="row"
                            >
                              Uploaded Business Plan Doc
                            </TableCell>
                            <TableCell align="left">
                              {doc.business_plan_doc_file ? (
                                <>
                                  {doc.business_plan_doc_file
                                    .toLowerCase()
                                    .endsWith(".pdf") ? (
                                    <a
                                      href={`${API_URL}/uploads/kyc/enterprise/business_plan_docs/${doc.business_plan_doc_file}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      View PDF
                                    </a>
                                  ) : doc.business_plan_doc_file
                                      .toLowerCase()
                                      .endsWith(".xlsx") ||
                                    doc.business_plan_doc_file
                                      .toLowerCase()
                                      .endsWith(".xls") ? (
                                    <a
                                      href={`${API_URL}/uploads/kyc/enterprise/business_plan_docs/${doc.business_plan_doc_file}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      View Excel
                                    </a>
                                  ) : doc.business_plan_doc_file
                                      .toLowerCase()
                                      .endsWith(".docx") ||
                                    doc.business_plan_doc_file
                                      .toLowerCase()
                                      .endsWith(".doc") ? (
                                    <a
                                      href={`${API_URL}/uploads/kyc/enterprise/business_plan_docs/${doc.business_plan_doc_file}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      View Docs
                                    </a>
                                  ) : (
                                    <img
                                      src={`${API_URL}/uploads/kyc/enterprise/business_plan_docs/${doc.business_plan_doc_file}`}
                                      alt=""
                                      height="50px"
                                      width="50px"
                                      style={{
                                        cursor: "pointer",
                                        objectFit: "contain",
                                      }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        const url = e.target.src;
                                        window.open(url, "_blank");
                                      }}
                                    />
                                  )}
                                </>
                              ) : (
                                <span style={{ color: "red" }}>
                                  No document available
                                </span>
                              )}
                            </TableCell>
                          </TableRow>
                        </>
                      ))}

                    <TableRow>
                      <TableCell
                        component="th"
                        style={{ fontWeight: "600" }}
                        scope="row"
                      >
                        Terms Confirmation
                      </TableCell>
                      <TableCell align="left">
                        {enterpriseValue.terms_confirmation ? "Yes" : "No"}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                paddingTop: "5px",
                marginTop: "5px",
                // borderTop: "1px solid #0b6885",
              }}
            >
              {buttonValue?.is_verified === 1 ? (
                <>
                  <Button
                    variant="contained"
                    style={{
                      margin: "10px 0 0 10px",
                      backgroundColor: "#00453B",
                      color: "#E5E4AE",
                    }}
                    onClick={handleDeal}
                  >
                    Make a Deal
                  </Button>
                  <ToastContainer
                    position="top-right"
                    autoClose={2000}
                    theme="colored"
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                  />
                  <Button
                    variant="contained"
                    style={{
                      margin: "10px 0 0 10px",
                      backgroundColor: "#2a80f9",
                    }}
                    onClick={handleUpdateKyc}
                  >
                    Update KYC Form
                  </Button>
                  <Button
                    disabled
                    variant="contained"
                    type="submit"
                    style={{
                      margin: "10px 0 0 10px",
                      backgroundColor: "#0b6885",
                      color: "#ffffff",
                    }}
                  >
                    Verified
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      margin: "10px 0 0 10px",
                      backgroundColor: "#ed5e5e",
                    }}
                    onClick={handlePrint}
                  >
                    Print
                  </Button>
                </>
              ) : (
                <>
                  {buttonValue?.is_verified === 2 ? (
                    <Button
                      disabled
                      variant="contained"
                      type="submit"
                      style={{
                        margin: "10px 0 0 10px",
                        backgroundColor: "#ed5e5e",
                        color: "#ffffff",
                      }}
                    >
                      Rejected
                    </Button>
                  ) : (
                    <>
                      <Button
                        variant="contained"
                        style={{
                          margin: "10px 0 0 10px",
                          backgroundColor: "#ed5e5e",
                        }}
                        onClick={handlePrint}
                      >
                        Print
                      </Button>{" "}
                      <Button
                        variant="contained"
                        onClick={rejectKycProfile}
                        style={{
                          margin: "10px 0 0 10px",
                          backgroundColor: "#ea3f4d",
                          color: "#ffffff",
                        }}
                      >
                        Reject
                      </Button>
                      <Button
                        variant="contained"
                        type="submit"
                        onClick={acceptKycProfile}
                        style={{
                          margin: "10px 0 0 10px",
                          backgroundColor: "#0b6885",
                        }}
                      >
                        Accept
                      </Button>
                      <ToastContainer
                        position="top-right"
                        autoClose={2000}
                        theme="colored"
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                      />
                    </>
                  )}
                </>
              )}
            </div>
          </>
        )}
      </CustomContainer>
      <Popup
        openPopup={openEnterpriseKycPopup}
        setOpenPopup={setOpenEnterpriseKycPopup}
        title="Update Member's KYC Form"
      >
        <UpdateEnterpriseKYCFormAdmin
          formData={enterpriseValue}
          setFormData={setEnterpriseValue}
          regFileAdmin={regFileAdmin}
          setRegFileAdmin={setRegFileAdmin}
          businessDocAdmin={businessDocAdmin}
          setBusinessDocAdmin={setBusinessDocAdmin}
          panOrVatAdmin={panOrVatAdmin}
          setPanOrVatAdmin={setPanOrVatAdmin}
          revenueAdmin={revenueAdmin}
          setRevenueAdmin={setRevenueAdmin}
          setOpenEnterpriseKycPopup={setOpenEnterpriseKycPopup}
        />
      </Popup>
    </>
  );
};

export default EnterpriceKYCProfile;

import React, { useState } from "react";
import {
  Button,
  Grid,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Box,
} from "@mui/material";
import { Form } from "../../components/hooks/useForm";
import { useDispatch } from "react-redux";
import { postQuestionarieAction } from "./QuestionarieActions";

const QuestionarieForm = ({ setOpenPopup }) => {
  const [question, setQuestion] = useState("");
  const [answerType, setAnswertype] = useState("Subjective");
  const [objective, setObjective] = useState();
  const [memberType, setMemberType] = useState("Enterprise");

  const initialFormValues = {
    question: question,
    answer_type: answerType,
    objective_answer: objective,
    member_type: memberType,
  };

  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(postQuestionarieAction(initialFormValues));
    setOpenPopup(false);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container spacing={2} style={{ fontSize: "14px" }}>
        <Grid item xs={12}>
          <TextField
            name="question"
            label="Question"
            style={{ width: "100%" }}
            onChange={(e) => setQuestion(e.target.value)}
            required
          />
          &nbsp;
          <FormControl fullWidth>
            <InputLabel id="answer_type">Answer Type</InputLabel>
            <Select
              labelId="answer_type"
              id="answer_type"
              value={answerType}
              label="Answer Type"
              onChange={(event) => setAnswertype(event.target.value)}
            >
              <MenuItem value={"Subjective"}>Subjective</MenuItem>
              <MenuItem value={"Objective"}>Objective</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {answerType === "Objective" ? (
          <Box
            sx={{
              ml: "5%",
            }}
          >
            &nbsp;
            <Grid container spacing={2} style={{ fontSize: "14px" }}>
              <Grid item xs={6}>
                <TextField
                  name="objective_answer"
                  label="Option A"
                  style={{ width: "100%" }}
                  onChange={(e) =>
                    setObjective((prev = {}) => ({
                      ...prev,
                      A: e.target.value,
                    }))
                  }
                  required
                />
                &nbsp;
                <TextField
                  name="objective_answer"
                  label="Option C"
                  style={{ width: "100%" }}
                  onChange={(e) =>
                    setObjective((prev = {}) => ({
                      ...prev,
                      C: e.target.value,
                    }))
                  }
                  required
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  name="objective_answer"
                  label="Option B"
                  style={{ width: "100%" }}
                  onChange={(e) =>
                    setObjective((prev = {}) => ({
                      ...prev,
                      B: e.target.value,
                    }))
                  }
                  required
                />{" "}
                &nbsp;
                <TextField
                  name="objective_answer"
                  label="Option D"
                  style={{ width: "100%" }}
                  onChange={(e) =>
                    setObjective((prev = {}) => ({
                      ...prev,
                      D: e.target.value,
                    }))
                  }
                  required
                />
              </Grid>
            </Grid>
          </Box>
        ) : (
          ""
        )}
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="member-type">Member Type</InputLabel>
            <Select
              labelId="member-type"
              id="member-type"
              value={memberType}
              label="Member Type"
              onChange={(event) => setMemberType(event.target.value)}
            >
              <MenuItem value={"Enterprise"}>Enterprise</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          paddingTop: "10px",
          marginTop: "10px",
          borderTop: "1px solid #0b6885",
        }}
      >
        <Button
          variant="contained"
          onClick={() => setOpenPopup(false)}
          style={{ margin: "10px 0 0 10px", backgroundColor: "#ea3f4d" }}
        >
          CANCEL
        </Button>
        <Button
          variant="contained"
          type="submit"
          style={{ margin: "10px 0 0 10px", backgroundColor: "#0b6885" }}
        >
          SUBMIT
        </Button>
      </div>
    </Form>
  );
};

export default QuestionarieForm;

import {
  DELETE_BLOGS_FAIL,
  DELETE_BLOGS_REQUEST,
  DELETE_BLOGS_RESET,
  DELETE_BLOGS_SUCCESS,
  GET_ALL_BLOGS_FAIL,
  GET_ALL_BLOGS_REQUEST,
  GET_ALL_BLOGS_RESET,
  GET_ALL_BLOGS_SUCCESS,
  GET_SINGLE_BLOGS_FAIL,
  GET_SINGLE_BLOGS_REQUEST,
  GET_SINGLE_BLOGS_RESET,
  GET_SINGLE_BLOGS_SUCCESS,
  POST_BLOGS_FAIL,
  POST_BLOGS_REQUEST,
  POST_BLOGS_RESET,
  POST_BLOGS_SUCCESS,
  PUT_BLOGS_FAIL,
  PUT_BLOGS_REQUEST,
  PUT_BLOGS_RESET,
  PUT_BLOGS_SUCCESS,
} from "./BlogsConstants";

export const getAllBlogsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_BLOGS_REQUEST:
      return { loading: true };
    case GET_ALL_BLOGS_SUCCESS:
      return { loading: false, allBlogs: action.payload };
    case GET_ALL_BLOGS_FAIL:
      return { loading: false, error: action.payload };
    case GET_ALL_BLOGS_RESET:
      return {};
    default:
      return state;
  }
};

export const getSingleBlogsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_SINGLE_BLOGS_REQUEST:
      return { loading: true };
    case GET_SINGLE_BLOGS_SUCCESS:
      return { loading: false, singleBlogs: action.payload };
    case GET_SINGLE_BLOGS_FAIL:
      return { loading: false, error: action.payload };
    case GET_SINGLE_BLOGS_RESET:
      return {};
    default:
      return state;
  }
};

export const postBlogsReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_BLOGS_REQUEST:
      return { loading: true };
    case POST_BLOGS_SUCCESS:
      return { loading: false, success: true };
    case POST_BLOGS_FAIL:
      return { loading: false, error: action.payload };
    case POST_BLOGS_RESET:
      return {};
    default:
      return state;
  }
};

export const putBlogsReducer = (state = {}, action) => {
  switch (action.type) {
    case PUT_BLOGS_REQUEST:
      return { loading: true };
    case PUT_BLOGS_SUCCESS:
      return { loading: false, success: true };
    case PUT_BLOGS_FAIL:
      return { loading: false, error: action.payload };
    case PUT_BLOGS_RESET:
      return {};
    default:
      return state;
  }
};

export const deleteBlogsReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_BLOGS_REQUEST:
      return { loading: true };
    case DELETE_BLOGS_SUCCESS:
      return { loading: false, success: true };
    case DELETE_BLOGS_FAIL:
      return { loading: false, error: action.payload };
    case DELETE_BLOGS_RESET:
      return {};
    default:
      return state;
  }
};

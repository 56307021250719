export const GET_ALL_SUCCESS_STORIES_REQUEST =
  "GET_ALL_SUCCESS_STORIES_REQUEST";
export const GET_ALL_SUCCESS_STORIES_SUCCESS =
  "GET_ALL_SUCCESS_STORIES_SUCCESS";
export const GET_ALL_SUCCESS_STORIES_FAIL = "GET_ALL_SUCCESS_STORIES_FAIL";
export const GET_ALL_SUCCESS_STORIES_RESET = "GET_ALL_SUCCESS_STORIES_RESET";

export const GET_SINGLE_SUCCESS_STORIES_REQUEST =
  "GET_SINGLE_SUCCESS_STORIES_REQUEST";
export const GET_SINGLE_SUCCESS_STORIES_SUCCESS =
  "GET_SINGLE_SUCCESS_STORIES_SUCCESS";
export const GET_SINGLE_SUCCESS_STORIES_FAIL =
  "GET_SINGLE_SUCCESS_STORIES_FAIL";
export const GET_SINGLE_SUCCESS_STORIES_RESET =
  "GET_SINGLE_SUCCESS_STORIES_RESET";

export const POST_SUCCESS_STORIES_REQUEST = "POST_SUCCESS_STORIES_REQUEST";
export const POST_SUCCESS_STORIES_SUCCESS = "POST_SUCCESS_STORIES_SUCCESS";
export const POST_SUCCESS_STORIES_FAIL = "POST_SUCCESS_STORIES_FAIL";
export const POST_SUCCESS_STORIES_RESET = "POST_SUCCESS_STORIES_RESET";

export const PUT_SUCCESS_STORIES_REQUEST = "PUT_SUCCESS_STORIES_REQUEST";
export const PUT_SUCCESS_STORIES_SUCCESS = "PUT_SUCCESS_STORIES_SUCCESS";
export const PUT_SUCCESS_STORIES_FAIL = "PUT_SUCCESS_STORIES_FAIL";
export const PUT_SUCCESS_STORIES_RESET = "PUT_SUCCESS_STORIES_RESET";

export const DELETE_SUCCESS_STORIES_REQUEST = "DELETE_SUCCESS_STORIES_REQUEST";
export const DELETE_SUCCESS_STORIES_SUCCESS = "DELETE_SUCCESS_STORIES_SUCCESS";
export const DELETE_SUCCESS_STORIES_FAIL = "DELETE_SUCCESS_STORIES_FAIL";
export const DELETE_SUCCESS_STORIES_RESET = "DELETE_SUCCESS_STORIES_RESET";

export const GET_ALL_BLOGS_REQUEST = "GET_ALL_BLOGS_REQUEST";
export const GET_ALL_BLOGS_SUCCESS = "GET_ALL_BLOGS_SUCCESS";
export const GET_ALL_BLOGS_FAIL = "GET_ALL_BLOGS_FAIL";
export const GET_ALL_BLOGS_RESET = "GET_ALL_BLOGS_RESET";

export const GET_SINGLE_BLOGS_REQUEST = "GET_SINGLE_BLOGS_REQUEST";
export const GET_SINGLE_BLOGS_SUCCESS = "GET_SINGLE_BLOGS_SUCCESS";
export const GET_SINGLE_BLOGS_FAIL = "GET_SINGLE_BLOGS_FAIL";
export const GET_SINGLE_BLOGS_RESET = "GET_SINGLE_BLOGS_RESET";

export const POST_BLOGS_REQUEST = "POST_BLOGS_REQUEST";
export const POST_BLOGS_SUCCESS = "POST_BLOGS_SUCCESS";
export const POST_BLOGS_FAIL = "POST_BLOGS_FAIL";
export const POST_BLOGS_RESET = "POST_BLOGS_RESET";

export const PUT_BLOGS_REQUEST = "PUT_BLOGS_REQUEST";
export const PUT_BLOGS_SUCCESS = "PUT_BLOGS_SUCCESS";
export const PUT_BLOGS_FAIL = "PUT_BLOGS_FAIL";
export const PUT_BLOGS_RESET = "PUT_BLOGS_RESET";

export const DELETE_BLOGS_REQUEST = "DELETE_BLOGS_REQUEST";
export const DELETE_BLOGS_SUCCESS = "DELETE_BLOGS_SUCCESS";
export const DELETE_BLOGS_FAIL = "DELETE_BLOGS_FAIL";
export const DELETE_BLOGS_RESET = "DELETE_BLOGS_RESET";

export const GET_ALL_NEWS_REQUEST = "GET_ALL_NEWS_REQUEST";
export const GET_ALL_NEWS_SUCCESS = "GET_ALL_NEWS_SUCCESS";
export const GET_ALL_NEWS_FAIL = "GET_ALL_NEWS_FAIL";
export const GET_ALL_NEWS_RESET = "GET_ALL_NEWS_RESET";

export const GET_SINGLE_NEWS_REQUEST = "GET_SINGLE_NEWS_REQUEST";
export const GET_SINGLE_NEWS_SUCCESS = "GET_SINGLE_NEWS_SUCCESS";
export const GET_SINGLE_NEWS_FAIL = "GET_SINGLE_NEWS_FAIL";
export const GET_SINGLE_NEWS_RESET = "GET_SINGLE_NEWS_RESET";

export const POST_NEWS_REQUEST = "POST_NEWS_REQUEST";
export const POST_NEWS_SUCCESS = "POST_NEWS_SUCCESS";
export const POST_NEWS_FAIL = "POST_NEWS_FAIL";
export const POST_NEWS_RESET = "POST_NEWS_RESET";

export const PUT_NEWS_REQUEST = "PUT_NEWS_REQUEST";
export const PUT_NEWS_SUCCESS = "PUT_NEWS_SUCCESS";
export const PUT_NEWS_FAIL = "PUT_NEWS_FAIL";
export const PUT_NEWS_RESET = "PUT_NEWS_RESET";

export const DELETE_NEWS_REQUEST = "DELETE_NEWS_REQUEST";
export const DELETE_NEWS_SUCCESS = "DELETE_NEWS_SUCCESS";
export const DELETE_NEWS_FAIL = "DELETE_NEWS_FAIL";
export const DELETE_NEWS_RESET = "DELETE_NEWS_RESET";

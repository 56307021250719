import React, { useEffect, useState } from "react";
import { Button, Grid, MenuItem, OutlinedInput, Select } from "@mui/material";
import { Form, useForm } from "../../components/hooks/useForm";
import { useDispatch, useSelector } from "react-redux";
import CheckBoxControl from "../../components/controls/CheckBoxControl";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { putSuccessStoriesAction } from "./SuccessStoriesActions";
import LoadingComp from "../../components/LoadingComp";
import { getAllPartnerAction } from "../about-us/partners/PartnersActions";
import { API_URL } from "../../api/axios";

const SuccessStoriesUpdateForm = ({
  singleSuccessStories,
  loadingEditForm,
  setOpenEditPopup,
}) => {
  const [partnerName, setPartnerName] = useState([]);
  const [image, setImage] = useState(null);
  const [imgSrc, setImgSrc] = useState(null);

  const dispatch = useDispatch();

  const { allPartner } = useSelector((state) => state.getAllPartner);

  useEffect(() => {
    if (!allPartner) {
      dispatch(getAllPartnerAction());
    }
    if (allPartner) {
      setPartnerName(allPartner?.data);
    }
  }, [dispatch, allPartner]);

  const initialFormValues = {
    partner_id: "",
    partner_company_name: "",
    partner_logo_image: "",
    ss_avatar_image: "",
    ss_message: "",
    ss_name: "",
    status: 0,
    ss_uid: "",
  };

  const { values, setValues } = useForm(initialFormValues);

  const handleImage = (event) => {
    let imageFile = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (x) => {
      setImgSrc(x.target.result);
    };
    reader.readAsDataURL(imageFile);
    if (imageFile) {
      setImage(imageFile);
      setValues({
        ...values,
        [event.target.name]: imageFile,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      putSuccessStoriesAction(values, singleSuccessStories?.ss_uid, image)
    );
    setOpenEditPopup(false);
  };

  useEffect(() => {
    if (singleSuccessStories) {
      setValues({ ...singleSuccessStories });
    }
  }, [singleSuccessStories, setValues]);

  return (
    <Form onSubmit={handleSubmit}>
      {loadingEditForm ? (
        <LoadingComp />
      ) : (
        <>
          <Grid container spacing={2} style={{ fontSize: "13px" }}>
            <Grid item xs={12}>
              <Select
                name="partner_company_name"
                label="Partner Name"
                value={values.partner_id}
                style={{ width: "100%" }}
                onChange={(e) =>
                  setValues({ ...values, partner_id: e.target.value })
                }
              >
                <MenuItem value="Client Stories">Client Stories</MenuItem>
                {partnerName.map((option) => (
                  <MenuItem key={option.partner_id} value={option.partner_id}>
                    {option.company_name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            {values.partner_id === "Client Stories" && (
              <Grid item xs={12}>
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  Client/User Name:
                </span>
                <OutlinedInput
                  name="ss_name"
                  label="Client Name"
                  style={{ width: "100%", marginTop: "6px" }}
                  value={values.ss_name}
                  onChange={(e) =>
                    setValues({ ...values, ss_name: e.target.value })
                  }
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <div className="ckeditor-container" tabIndex="0">
                <CKEditor
                  editor={ClassicEditor}
                  id="ssMessage"
                  name="ssMessage"
                  config={{
                    link: {
                      defaultProtocol: "https://",
                      addTargetToExternalLinks: true,
                      decorators: [
                        {
                          attributes: {
                            target: "_blank",
                            rel: "noopener noreferrer",
                          },
                        },
                      ],
                    },
                  }}
                  onReady={(editor) => {
                    editor.setData(values.ss_message);
                  }}
                  onChange={(event, editor) =>
                    setValues({
                      ...values,
                      ss_message: editor.getData(),
                    })
                  }
                />
              </div>
            </Grid>
            {values.partner_id === "Client Stories" && (
              <Grid item xs={12}>
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  Client Avatar:
                </span>
                <input
                  type="file"
                  name="ss_avatar_image"
                  className="form-control"
                  onChange={handleImage}
                />
                <br />
                <br />
                {singleSuccessStories?.ss_avatar_image &&
                singleSuccessStories?.ss_avatar_image ? (
                  <img
                    alt=""
                    src={
                      imgSrc
                        ? imgSrc
                        : singleSuccessStories &&
                          `${API_URL}/uploads/success_stories/${singleSuccessStories?.ss_avatar_image}`
                    }
                    height={150}
                    width={150}
                  />
                ) : (
                  <span style={{ color: "red" }}>*Choose Image</span>
                )}
              </Grid>
            )}
            <Grid item xs={12}>
              <CheckBoxControl
                name="status"
                label="Active"
                value={values.status}
                onChange={(e) =>
                  setValues((prev = {}) => ({
                    ...prev,
                    status: e.target.value ? 1 : 0,
                  }))
                }
              />
            </Grid>
          </Grid>

          <div
            style={{
              display: "flex",
              justifyContent: "end",
              paddingTop: "10px",
              marginTop: "10px",
              borderTop: "1px solid #0b6885",
            }}
          >
            <Button
              variant="contained"
              onClick={() => setOpenEditPopup(false)}
              style={{ margin: "10px 0 0 10px", backgroundColor: "#ea3f4d" }}
            >
              CANCEL
            </Button>
            <Button
              variant="contained"
              type="submit"
              style={{ margin: "10px 0 0 10px", backgroundColor: "#0b6885" }}
            >
              SUBMIT
            </Button>
          </div>
        </>
      )}
    </Form>
  );
};

export default SuccessStoriesUpdateForm;

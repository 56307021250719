import { axiosPublic, API_URL } from "../../api/axios";
import {
  GET_ABOUT_COMPANY_PUBLIC_FAIL,
  GET_ABOUT_COMPANY_PUBLIC_REQUEST,
  GET_ABOUT_COMPANY_PUBLIC_SUCCESS,
  GET_ADVISORS_PUBLIC_FAIL,
  GET_ADVISORS_PUBLIC_REQUEST,
  GET_ADVISORS_PUBLIC_SUCCESS,
  GET_ALL_BLOGS_PUBLIC_FAIL,
  GET_ALL_BLOGS_PUBLIC_REQUEST,
  GET_ALL_BLOGS_PUBLIC_SUCCESS,
  GET_ALL_CONTACT_DETAILS_PUBLIC_FAIL,
  GET_ALL_CONTACT_DETAILS_PUBLIC_REQUEST,
  GET_ALL_CONTACT_DETAILS_PUBLIC_SUCCESS,
  GET_ALL_DEALS_PUBLIC_FAIL,
  GET_ALL_DEALS_PUBLIC_REQUEST,
  GET_ALL_DEALS_PUBLIC_SUCCESS,
  GET_ALL_DEAL_CATEGORY_PUBLIC_FAIL,
  GET_ALL_DEAL_CATEGORY_PUBLIC_REQUEST,
  GET_ALL_DEAL_CATEGORY_PUBLIC_SUCCESS,
  GET_ALL_ENTERPRISE_FAQS_PUBLIC_FAIL,
  GET_ALL_ENTERPRISE_FAQS_PUBLIC_REQUEST,
  GET_ALL_ENTERPRISE_FAQS_PUBLIC_SUCCESS,
  GET_ALL_INVESTORS_FAQS_PUBLIC_FAIL,
  GET_ALL_INVESTORS_FAQS_PUBLIC_REQUEST,
  GET_ALL_INVESTORS_FAQS_PUBLIC_SUCCESS,
  GET_ALL_NEWS_PUBLIC_FAIL,
  GET_ALL_NEWS_PUBLIC_REQUEST,
  GET_ALL_NEWS_PUBLIC_SUCCESS,
  GET_ALL_SUCCESS_STORIES_PUBLIC_FAIL,
  GET_ALL_SUCCESS_STORIES_PUBLIC_REQUEST,
  GET_ALL_SUCCESS_STORIES_PUBLIC_SUCCESS,
  GET_BLOGS_SLUG_PUBLIC_FAIL,
  GET_BLOGS_SLUG_PUBLIC_REQUEST,
  GET_BLOGS_SLUG_PUBLIC_SUCCESS,
  GET_NEWS_SLUG_PUBLIC_FAIL,
  GET_NEWS_SLUG_PUBLIC_REQUEST,
  GET_NEWS_SLUG_PUBLIC_SUCCESS,
  GET_PARTNERS_PUBLIC_FAIL,
  GET_PARTNERS_PUBLIC_REQUEST,
  GET_PARTNERS_PUBLIC_SUCCESS,
  GET_SINGLE_ADVISOR_PUBLIC_FAIL,
  GET_SINGLE_ADVISOR_PUBLIC_REQUEST,
  GET_SINGLE_ADVISOR_PUBLIC_SUCCESS,
  GET_SINGLE_DEALS_PUBLIC_FAIL,
  GET_SINGLE_DEALS_PUBLIC_REQUEST,
  GET_SINGLE_DEALS_PUBLIC_SUCCESS,
  GET_SINGLE_PARTNER_PUBLIC_FAIL,
  GET_SINGLE_PARTNER_PUBLIC_REQUEST,
  GET_SINGLE_PARTNER_PUBLIC_SUCCESS,
  GET_SINGLE_TEAMS_PUBLIC_FAIL,
  GET_SINGLE_TEAMS_PUBLIC_REQUEST,
  GET_SINGLE_TEAMS_PUBLIC_SUCCESS,
  GET_TEAMS_PUBLIC_FAIL,
  GET_TEAMS_PUBLIC_REQUEST,
  GET_TEAMS_PUBLIC_SUCCESS,
  GET_WORK_WITH_US_PUBLIC_FAIL,
  GET_WORK_WITH_US_PUBLIC_REQUEST,
  GET_WORK_WITH_US_PUBLIC_SUCCESS,
  POST_CONTACT_FORM_PUBLIC_FAIL,
  POST_CONTACT_FORM_PUBLIC_REQUEST,
  POST_CONTACT_FORM_PUBLIC_SUCCESS,
  POST_VERIFY_MEMBER_FAIL,
  POST_VERIFY_MEMBER_REQUEST,
  POST_VERIFY_MEMBER_SUCCESS,
} from "./PublicRoutesConstants";

export const getAllAboutPublicAction = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ABOUT_COMPANY_PUBLIC_REQUEST });

    const { data } = await axiosPublic.get(`${API_URL}/api/v1/about_company`);

    dispatch({ type: GET_ABOUT_COMPANY_PUBLIC_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_ABOUT_COMPANY_PUBLIC_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

export const getWorkWithUsPublicAction = () => async (dispatch) => {
  try {
    dispatch({ type: GET_WORK_WITH_US_PUBLIC_REQUEST });

    const { data } = await axiosPublic.get(`${API_URL}/api/v1/work_with_us`);
    dispatch({ type: GET_WORK_WITH_US_PUBLIC_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_WORK_WITH_US_PUBLIC_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

export const getTeamsPublicAction = () => async (dispatch) => {
  try {
    dispatch({ type: GET_TEAMS_PUBLIC_REQUEST });

    const { data } = await axiosPublic.get(`${API_URL}/api/v1/teams`);
    dispatch({ type: GET_TEAMS_PUBLIC_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_TEAMS_PUBLIC_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

export const getSingleTeamPublicAction = (unique_id) => async (dispatch) => {
  try {
    dispatch({ type: GET_SINGLE_TEAMS_PUBLIC_REQUEST });

    const { data } = await axiosPublic.get(
      `${API_URL}/api/v1/teams/${unique_id}`
    );
    dispatch({ type: GET_SINGLE_TEAMS_PUBLIC_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_SINGLE_TEAMS_PUBLIC_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

export const getAdvisorPublicAction = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ADVISORS_PUBLIC_REQUEST });

    const { data } = await axiosPublic.get(`${API_URL}/api/v1/advisors`);

    dispatch({ type: GET_ADVISORS_PUBLIC_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_ADVISORS_PUBLIC_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

export const getSingleAdvisorPublicAction = (unique_id) => async (dispatch) => {
  try {
    dispatch({ type: GET_SINGLE_ADVISOR_PUBLIC_REQUEST });

    const { data } = await axiosPublic.get(
      `${API_URL}/api/v1/advisors/${unique_id}`
    );

    dispatch({ type: GET_SINGLE_ADVISOR_PUBLIC_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_SINGLE_ADVISOR_PUBLIC_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

export const getAllPartnerPublicAction = () => async (dispatch) => {
  try {
    dispatch({ type: GET_PARTNERS_PUBLIC_REQUEST });

    const { data } = await axiosPublic.get(`${API_URL}/api/v1/partners`);

    dispatch({ type: GET_PARTNERS_PUBLIC_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_PARTNERS_PUBLIC_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

export const getSinglePartnerPublicAction = (unique_id) => async (dispatch) => {
  try {
    dispatch({ type: GET_SINGLE_PARTNER_PUBLIC_REQUEST });

    const { data } = await axiosPublic.get(
      `${API_URL}/api/v1/partners/${unique_id}`
    );

    dispatch({ type: GET_SINGLE_PARTNER_PUBLIC_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_SINGLE_PARTNER_PUBLIC_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

export const getAllSuccessStoriesPublicAction = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_SUCCESS_STORIES_PUBLIC_REQUEST });

    const { data } = await axiosPublic.get(`${API_URL}/api/v1/success_stories`);

    dispatch({ type: GET_ALL_SUCCESS_STORIES_PUBLIC_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_ALL_SUCCESS_STORIES_PUBLIC_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

export const getAllBlogsPublicAction = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_BLOGS_PUBLIC_REQUEST });

    const { data } = await axiosPublic.get(`${API_URL}/api/v1/blogs`);

    dispatch({ type: GET_ALL_BLOGS_PUBLIC_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_ALL_BLOGS_PUBLIC_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

export const getBlogsSlugPublicAction = (slug) => async (dispatch) => {
  try {
    dispatch({ type: GET_BLOGS_SLUG_PUBLIC_REQUEST });

    const { data } = await axiosPublic.get(`${API_URL}/api/v1/blogs/${slug}`);

    dispatch({ type: GET_BLOGS_SLUG_PUBLIC_SUCCESS, payload: data });
    return data;
  } catch (error) {
    dispatch({
      type: GET_BLOGS_SLUG_PUBLIC_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

export const getAllNewsPublicAction = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_NEWS_PUBLIC_REQUEST });

    const { data } = await axiosPublic.get(`${API_URL}/api/v1/news`);

    dispatch({ type: GET_ALL_NEWS_PUBLIC_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_ALL_NEWS_PUBLIC_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

export const getNewsSlugPublicAction = (slug) => async (dispatch) => {
  try {
    dispatch({ type: GET_NEWS_SLUG_PUBLIC_REQUEST });

    const { data } = await axiosPublic.get(`${API_URL}/api/v1/news/${slug}`);

    dispatch({ type: GET_NEWS_SLUG_PUBLIC_SUCCESS, payload: data });
    return data;
  } catch (error) {
    dispatch({
      type: GET_NEWS_SLUG_PUBLIC_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

export const getAllInvestorFaqsPublicAction = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_INVESTORS_FAQS_PUBLIC_REQUEST });

    const { data } = await axiosPublic.get(`${API_URL}/api/v1/investor_faqs`);

    dispatch({ type: GET_ALL_INVESTORS_FAQS_PUBLIC_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_ALL_INVESTORS_FAQS_PUBLIC_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

export const getAllEnterpriseFaqsPublicAction = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_ENTERPRISE_FAQS_PUBLIC_REQUEST });

    const { data } = await axiosPublic.get(`${API_URL}/api/v1/enterprise_faqs`);

    dispatch({ type: GET_ALL_ENTERPRISE_FAQS_PUBLIC_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_ALL_ENTERPRISE_FAQS_PUBLIC_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

export const getAllContactDetailsPublicAction = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_CONTACT_DETAILS_PUBLIC_REQUEST });

    const { data } = await axiosPublic.get(`${API_URL}/api/v1/contact_details`);

    dispatch({ type: GET_ALL_CONTACT_DETAILS_PUBLIC_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_ALL_CONTACT_DETAILS_PUBLIC_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

export const postContactFormAction = (addContact) => async (dispatch) => {
  try {
    dispatch({ type: POST_CONTACT_FORM_PUBLIC_REQUEST });

    const jsonData = JSON.stringify(addContact);

    const { data } = await axiosPublic.post(
      `${API_URL}/api/v1/contact_submit`,
      jsonData
    );
    dispatch({ type: POST_CONTACT_FORM_PUBLIC_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: POST_CONTACT_FORM_PUBLIC_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

export const getAllDealsPublicAction = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_DEALS_PUBLIC_REQUEST });

    const { data } = await axiosPublic.get(`${API_URL}/api/v1/deals`);

    dispatch({ type: GET_ALL_DEALS_PUBLIC_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_ALL_DEALS_PUBLIC_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

export const getSingleDealsPublicAction = (unique_id) => async (dispatch) => {
  try {
    dispatch({ type: GET_SINGLE_DEALS_PUBLIC_REQUEST });

    const { data } = await axiosPublic.get(
      `${API_URL}/api/v1/deals/${unique_id}`
    );

    dispatch({ type: GET_SINGLE_DEALS_PUBLIC_SUCCESS, payload: data });
    return data;
  } catch (error) {
    dispatch({
      type: GET_SINGLE_DEALS_PUBLIC_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

export const getAllDealCategoryPublicAction = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_DEAL_CATEGORY_PUBLIC_REQUEST });

    const { data } = await axiosPublic.get(`${API_URL}/api/v1/deal_categories`);
    dispatch({ type: GET_ALL_DEAL_CATEGORY_PUBLIC_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_ALL_DEAL_CATEGORY_PUBLIC_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

export const postVerifyMemberAction =
  (addVerificationCode) => async (dispatch) => {
    try {
      dispatch({ type: POST_VERIFY_MEMBER_REQUEST });

      const jsonData = JSON.stringify(addVerificationCode);

      const { data } = await axiosPublic.post(
        `${API_URL}/api/v1/members/auth/verify`,
        jsonData
      );
      dispatch({ type: POST_VERIFY_MEMBER_SUCCESS, payload: data });
      return data;
    } catch (error) {
      dispatch({
        type: POST_VERIFY_MEMBER_FAIL,
        payload: error.message ? error.message : error.Message,
      });
    }
  };

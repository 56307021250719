import {
  GET_ALL_DEAL_CATEGORY_FILTER_FAIL,
  GET_ALL_DEAL_CATEGORY_FILTER_REQUEST,
  GET_ALL_DEAL_CATEGORY_FILTER_RESET,
  GET_ALL_DEAL_CATEGORY_FILTER_SUCCESS,
  GET_ALL_SDGS_FILTER_LIST_FAIL,
  GET_ALL_SDGS_FILTER_LIST_REQUEST,
  GET_ALL_SDGS_FILTER_LIST_RESET,
  GET_ALL_SDGS_FILTER_LIST_SUCCESS,
  GET_DETAILED_DEALS_PRIVATE_FAIL,
  GET_DETAILED_DEALS_PRIVATE_REQUEST,
  GET_DETAILED_DEALS_PRIVATE_RESET,
  GET_DETAILED_DEALS_PRIVATE_SUCCESS,
  GET_DETAILED_PERSONAL_INFO_FAIL,
  GET_DETAILED_PERSONAL_INFO_REQUEST,
  GET_DETAILED_PERSONAL_INFO_RESET,
  GET_DETAILED_PERSONAL_INFO_SUCCESS,
  GET_PROFILE_AS_DEALS_BY_ID_FAIL,
  GET_PROFILE_AS_DEALS_BY_ID_REQUEST,
  GET_PROFILE_AS_DEALS_BY_ID_RESET,
  GET_PROFILE_AS_DEALS_BY_ID_SUCCESS,
  GET_SHOW_INTERESTED_DEALS_FAIL,
  GET_SHOW_INTERESTED_DEALS_REQUEST,
  GET_SHOW_INTERESTED_DEALS_RESET,
  GET_SHOW_INTERESTED_DEALS_SUCCESS,
  POST_CHANGE_PASSWORD_FAIL,
  POST_CHANGE_PASSWORD_REQUEST,
  POST_CHANGE_PASSWORD_RESET,
  POST_CHANGE_PASSWORD_SUCCESS,
  POST_PROFILE_DATA_FAIL,
  POST_PROFILE_DATA_REQUEST,
  POST_PROFILE_DATA_RESET,
  POST_PROFILE_DATA_SUCCESS,
  POST_SHOW_INTEREST_FAIL,
  POST_SHOW_INTEREST_REQUEST,
  POST_SHOW_INTEREST_RESET,
  POST_SHOW_INTEREST_SUCCESS,
} from "./PrivateConstants";

export const getDetailDealsPrivateReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_DETAILED_DEALS_PRIVATE_REQUEST:
      return { loading: true };
    case GET_DETAILED_DEALS_PRIVATE_SUCCESS:
      return { loading: false, detailDealPrivate: action.payload };
    case GET_DETAILED_DEALS_PRIVATE_FAIL:
      return { loading: false, error: action.payload };
    case GET_DETAILED_DEALS_PRIVATE_RESET:
      return {};
    default:
      return state;
  }
};

export const postProfileDataReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_PROFILE_DATA_REQUEST:
      return { loading: true };
    case POST_PROFILE_DATA_SUCCESS:
      return { loading: false, success: true };
    case POST_PROFILE_DATA_FAIL:
      return { loading: false, error: action.payload };
    case POST_PROFILE_DATA_RESET:
      return {};
    default:
      return state;
  }
};

export const postChangePasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_CHANGE_PASSWORD_REQUEST:
      return { loading: true };
    case POST_CHANGE_PASSWORD_SUCCESS:
      return { loading: false, success: true };
    case POST_CHANGE_PASSWORD_FAIL:
      return { loading: false, error: action.payload };
    case POST_CHANGE_PASSWORD_RESET:
      return {};
    default:
      return state;
  }
};

export const postShowInterestReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_SHOW_INTEREST_REQUEST:
      return { loading: true };
    case POST_SHOW_INTEREST_SUCCESS:
      return { loading: false, success: true };
    case POST_SHOW_INTEREST_FAIL:
      return { loading: false, error: action.payload };
    case POST_SHOW_INTEREST_RESET:
      return {};
    default:
      return state;
  }
};

export const getAllDealCategoryFilterReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_DEAL_CATEGORY_FILTER_REQUEST:
      return { loading: true };
    case GET_ALL_DEAL_CATEGORY_FILTER_SUCCESS:
      return { loading: false, allDealsCategoryFilter: action.payload };
    case GET_ALL_DEAL_CATEGORY_FILTER_FAIL:
      return { loading: false, error: action.payload };
    case GET_ALL_DEAL_CATEGORY_FILTER_RESET:
      return {};
    default:
      return state;
  }
};

export const getAllSdgsFilterListReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_SDGS_FILTER_LIST_REQUEST:
      return { loading: true };
    case GET_ALL_SDGS_FILTER_LIST_SUCCESS:
      return { loading: false, allSdgsFilter: action.payload };
    case GET_ALL_SDGS_FILTER_LIST_FAIL:
      return { loading: false, error: action.payload };
    case GET_ALL_SDGS_FILTER_LIST_RESET:
      return {};
    default:
      return state;
  }
};

export const getDetailedPersonalInfoReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_DETAILED_PERSONAL_INFO_REQUEST:
      return { loading: true };
    case GET_DETAILED_PERSONAL_INFO_SUCCESS:
      return { loading: false, detailedPersonalInfo: action.payload };
    case GET_DETAILED_PERSONAL_INFO_FAIL:
      return { loading: false, error: action.payload };
    case GET_DETAILED_PERSONAL_INFO_RESET:
      return {};
    default:
      return state;
  }
};

export const getShowInterestedDealsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_SHOW_INTERESTED_DEALS_REQUEST:
      return { loading: true };
    case GET_SHOW_INTERESTED_DEALS_SUCCESS:
      return { loading: false, showInterestedDeals: action.payload };
    case GET_SHOW_INTERESTED_DEALS_FAIL:
      return { loading: false, error: action.payload };
    case GET_SHOW_INTERESTED_DEALS_RESET:
      return {};
    default:
      return state;
  }
};

export const getProfileAsDealsByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PROFILE_AS_DEALS_BY_ID_REQUEST:
      return { loading: true };
    case GET_PROFILE_AS_DEALS_BY_ID_SUCCESS:
      return { loading: false, profileAsDealsById: action.payload };
    case GET_PROFILE_AS_DEALS_BY_ID_FAIL:
      return { loading: false, error: action.payload };
    case GET_PROFILE_AS_DEALS_BY_ID_RESET:
      return {};
    default:
      return state;
  }
};

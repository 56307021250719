import React, { useState, useEffect } from "react";
import { Button, TableBody, Toolbar } from "@mui/material";
import useCustomTable from "../../components/hooks/useCustomTable";
import { Add } from "@mui/icons-material";
import Popup from "../../components/Popup";
import { useDispatch, useSelector } from "react-redux";
import CustomContainer from "../../components/CustomContainer";
import Topbar from "../../components/Topbar";
import QuestionarieTableCollapse from "./QuestionarieTableCollapse";
import QuestionarieForm from "./QuestionarieForm";
import QuestionarieEditForm from "./QuestionarieEditForm";
import LoadingComp from "../../components/LoadingComp";
import {
  DELETE_QUESTIONARIE_RESET,
  GET_ALL_QUESTIONARIE_RESET,
  GET_SINGLE_QUESTIONARIE_RESET,
  POST_QUESTIONARIE_RESET,
  PUT_QUESTIONARIE_RESET,
} from "./QuestionarieConstants";
import {
  deleteQuestionarieAction,
  getAllQuestionarieAction,
  getSingleQuestionarieAction,
} from "./QuestionarieActions";
import Notification from "../../components/Notification";

const tableHeader = [
  { id: "question", label: "Question" },
  { id: "answer_type", label: "Answer Type" },
  { id: "objective_answer", label: "Objective Answer" },
  { id: "created_at", label: "Created Date" },
  { id: "actions", label: "Actions", disableSorting: true },
];

const Questionarie = () => {
  const [tableData, setTableData] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [filterFn, setFilterFn] = useState({
    fn: (item) => {
      return item;
    },
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const dispatch = useDispatch();

  const { allQuestionarie, error, loading } = useSelector(
    (state) => state.getAllQuestionarie
  );

  const {
    singleQuestionarie,
    error: singleQuestionarieError,
    loading: loadingEditForm,
  } = useSelector((state) => state.getSingleQuestionarie);

  const { success: postQuestionarieSuccess, error: postQuestionarieError } =
    useSelector((state) => state.postQuestionarie);

  const { success: putQuestionarieSuccess, error: putQuestionarieError } =
    useSelector((state) => state.putQuestionarie);

  const { success: deleteQuestionarieSuccess, error: deleteQuestionarieError } =
    useSelector((state) => state.deleteQuestionarie);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (error) {
    setNotify({
      isOpen: true,
      message: error,
      type: "error",
    });
    dispatch({ type: GET_ALL_QUESTIONARIE_RESET });
  }

  if (singleQuestionarieError) {
    setNotify({
      isOpen: true,
      message: singleQuestionarieError,
      type: "error",
    });
    dispatch({ type: GET_SINGLE_QUESTIONARIE_RESET });
  }

  if (postQuestionarieError) {
    setNotify({
      isOpen: true,
      message: postQuestionarieError,
      type: "error",
    });
    dispatch({ type: POST_QUESTIONARIE_RESET });
  }

  if (putQuestionarieError) {
    setNotify({
      isOpen: true,
      message: putQuestionarieError,
      type: "error",
    });
    dispatch({ type: PUT_QUESTIONARIE_RESET });
  }

  if (deleteQuestionarieError) {
    setNotify({
      isOpen: true,
      message: deleteQuestionarieError,
      type: "error",
    });
    dispatch({ type: DELETE_QUESTIONARIE_RESET });
  }

  if (postQuestionarieSuccess) {
    dispatch(getAllQuestionarieAction());
    setNotify({
      isOpen: true,
      message: "Questionnaire Added Successfully!",
      type: "success",
    });
    setOpenPopup(false);
    dispatch({ type: POST_QUESTIONARIE_RESET });
  }

  if (putQuestionarieSuccess) {
    dispatch(getAllQuestionarieAction());
    setNotify({
      isOpen: true,
      message: "Questionnaire Updated Successfully!",
      type: "success",
    });
    setOpenEditPopup(false);
    dispatch({ type: PUT_QUESTIONARIE_RESET });
  }

  if (deleteQuestionarieSuccess) {
    dispatch(getAllQuestionarieAction());
    setNotify({
      isOpen: true,
      message: "Questionnaire Deleted Successfully!",
      type: "success",
    });
    dispatch({ type: DELETE_QUESTIONARIE_RESET });
  }

  useEffect(() => {
    if (!allQuestionarie) {
      dispatch(getAllQuestionarieAction());
    }
    if (allQuestionarie) {
      setTableData(allQuestionarie?.data);
    }
  }, [dispatch, allQuestionarie]);

  useEffect(() => {
    dispatch(getAllQuestionarieAction());
  }, [dispatch]);

  const {
    TableContainer,
    TblHead,
    TblPagination,
    tableDataAfterPagingAndSorting,
  } = useCustomTable(tableData, tableHeader, filterFn);

  const addQuestionarieHandler = () => {
    dispatch({ type: GET_SINGLE_QUESTIONARIE_RESET });
    setOpenPopup(true);
  };

  const updateQuestionarieHandler = (id) => {
    dispatch(getSingleQuestionarieAction(id));
    setOpenEditPopup(true);
  };

  const deleteQuestionarieHandler = (id) => {
    dispatch(deleteQuestionarieAction(id));
  };

  return (
    <>
      <CustomContainer>
        <Toolbar>
          <Topbar title="Questionnaire" />
          <Button
            variant="contained"
            startIcon={<Add />}
            style={{
              position: "absolute",
              right: "10px",
              backgroundColor: "#2a80f9",
            }}
            onClick={addQuestionarieHandler}
          >
            {" "}
            Add Questionnaire
          </Button>
        </Toolbar>
        {loading ? (
          <LoadingComp />
        ) : (
          <>
            <TableContainer style={{ margin: "2vw 0.5vw", padding: "24px 0" }}>
              <TblHead />
              <TableBody>
                {tableDataAfterPagingAndSorting().map((item) => (
                  <QuestionarieTableCollapse
                    item={item}
                    key={item.unique_id}
                    updateQuestionarieHandler={updateQuestionarieHandler}
                    deleteQuestionarieHandler={deleteQuestionarieHandler}
                  />
                ))}
              </TableBody>
            </TableContainer>
            <TblPagination />
          </>
        )}
      </CustomContainer>
      <Popup
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        title="Add Questionnaire"
      >
        <QuestionarieForm
          singleQuestionarie={singleQuestionarie && singleQuestionarie?.data}
          setOpenPopup={setOpenPopup}
        />
      </Popup>
      <Popup
        openPopup={openEditPopup}
        setOpenPopup={setOpenEditPopup}
        title="Edit Questionnaire"
      >
        <QuestionarieEditForm
          loadingEditForm={loadingEditForm}
          singleQuestionarie={singleQuestionarie && singleQuestionarie?.data}
          setOpenEditPopup={setOpenEditPopup}
        />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};
export default Questionarie;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import success from "../front-end-images/success.png";

const VerifiedMember = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    navigate("/login");
  };

  return (
    <>
      <div
        className="card"
        style={{
          width: "30%",
          marginLeft: "36%",
          marginTop: "6%",
          backgroundColor: "#f7f7f7",
        }}
      >
        <div
          style={{ textAlign: "center", marginTop: "15%", marginBottom: "5%" }}
        >
          <img src={success} alt="" width="240px" height="120px" />
        </div>
        <div>
          <h4
            style={{
              color: "#0b6885",
              textAlign: "center",
              padding: "6%",
              fontSize: "28px",
            }}
          >
            Congratulations!
          </h4>
        </div>
        <div style={{ textAlign: "center", fontSize: "14px" }}>
          Welcome to Login Page
        </div>
        <div style={{ padding: "20px" }}>
          <LoadingButton
            loading={loading}
            loadingPosition="end"
            type="submit"
            onClick={handleSubmit}
            fullWidth
            variant="contained"
            style={{ backgroundColor: "#0b6885" }}
            sx={{ mt: 2, mb: 2 }}
          >
            Done
          </LoadingButton>
        </div>
      </div>
    </>
  );
};

export default VerifiedMember;

import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL, axiosPublic } from "../../api/axios";
import useAuth from "../../components/hooks/useAuth";
import useAuthentication from "../../components/hooks/useAuthentication";
import {
  setMemberAccessToken,
  setMemberRefreshToken,
} from "../../services/storage";
import useMemberAuthentication from "../../components/hooks/useMemberAuthentication";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const { isAuthenticated, login } = useMemberAuthentication();
  const { setMemberAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const from = location.state?.from?.pathname || "/dashboard";

  const emailRef = useRef();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    emailRef.current.focus();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      return navigate(from, { replace: true });
    }
  }, [isAuthenticated, from, navigate]);
  // }, [isMemAuth, from, navigate]);

  const setMemToken = (memberAccessToken, memberRefreshToken) => {
    setMemberAccessToken(memberAccessToken);
    setMemberRefreshToken(memberRefreshToken);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axiosPublic.post(
        `${API_URL}/api/v1/members/auth/login`,
        JSON.stringify({ email_address: email, password })
      );

      const memberAccessToken = response?.data?.data?.accessToken;
      const memberRefreshToken = response?.data?.data?.refreshToken;

      login({
        refreshToken: memberRefreshToken,
        accessToken: memberAccessToken,
      });

      // setMemToken(memberAccessToken, memberRefreshToken);

      setMemberAuth({
        email_address: email,
        password: password,
        accessToken: memberAccessToken,
        refreshToken: memberRefreshToken,
      });

      setEmail("");
      setPassword("");

      navigate(from, { replace: true });
    } catch (err) {
      if (!err?.response) {
        toast.error("No Server Response");
      } else if (err.response?.status === 400) {
        toast.error(err?.response?.data?.message);
      } else if (err.response?.status === 401) {
        toast.error("Unauthorized");
      } else {
        toast.error("Login Failed");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleMemberForgotPassword = (e) => {
    e.preventDefault();
    navigate("/forgot_password");
  };

  return (
    <div
      className="tab-pane fade show active"
      id="login-div"
      role="tabpanel"
      aria-labelledby="login-tab"
    >
      <div className="userlog-div">
        <form onSubmit={handleLogin}>
          Username/ Email Address
          <br />
          <input
            type="email"
            id="email_address"
            name="email_address"
            ref={emailRef}
            autoFocus
            autoComplete="off"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            required
          />
          Password
          <input
            type="password"
            id="password"
            name="password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            autoComplete="off"
            required
          />
          <div className="text-right">
            <Button
              onClick={handleMemberForgotPassword}
              style={{
                backgroundColor: "white",
                color: "#212529",
                fontSize: "13px",
                fontWeight: "600",
                textTransform: "lowercase",
              }}
            >
              Forgot Password?
            </Button>
          </div>
          <LoadingButton
            loading={loading}
            loadingPosition="end"
            variant="contained"
            style={{
              marginTop: "5px",
              backgroundColor: "#0b6885",
              color: "#ffffff",
            }}
            type="submit"
            fullWidth
            value="Log In"
            className="submit-btn"
          >
            Log In
          </LoadingButton>
          {/* <ToastContainer
            position="top-right"
            autoClose={2000}
            theme="colored"
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
          /> */}
        </form>
      </div>
      <div className="modal-footer">
        <div className="text-center">
          Connect with more than 5,000+ businesses and more than 500 investors
        </div>
      </div>
    </div>
  );
};

export default Login;

import { Button } from "@mui/material";
import React from "react";
import { Clear } from "@mui/icons-material";

const LegalDocumentsApproval = ({ handleClose }) => {
  return (
    <div style={{ fontSize: "14px" }}>
      <div style={{ textAlign: "right" }}>
        <Button
          style={{
            padding: "15px 6px",
            minWidth: "10px",
            fontSize: "10px",
            height: "20px",
            backgroundColor: "#0b6885",
            color: "secondary",
          }}
          variant="contained"
          onClick={() => handleClose()}
        >
          <Clear fontSize="small" />
        </Button>
      </div>
      <div>
        <h2 style={{ textAlign: "center" }}>Terms &amp; Conditions</h2>
      </div>

      <p>
        Last updated: 2021 31<sup>st</sup> Dec
      </p>
      <div className="termscondition">
        <h4>Introduction</h4>
        <p>
          Welcome to Million Deals! By using our website and/or using the
          services that are provided, you acknowledge that you have read,
          understood, and agree to be bound by our Terms and Conditions. These
          Terms and Conditions unconditionally extend and apply to all related
          applications, internet service, or website extensions. If you are not
          in agreement with all of these Terms and Conditions, you are
          prohibited from using this Website, and you may discontinue use
          immediately. Million Deals recommends that you save or print a copy of
          these Terms and Conditions for future reference.
        </p>
        <h4>Agreement to Terms and Conditions</h4>
        <p>
          Million Deals Terms And Conditions (these &quot;Terms&quot; or these
          &quot;Terms and Conditions&quot;) contained in this Agreement shall
          govern your use of this Website and all its content (collectively
          referred to herein as this &quot;Website&quot;). These Terms outline
          the rules and regulations guiding the use of Million Deals located at
          https://million-deals.icn.com.np/. All
          materials/information/documents/services or all other entities
          (collectively referred to as content) that appear on the Million Deals
          shall be administered subject to these Terms and Conditions. These
          Terms and Conditions apply in full force and effect to your use of
          this Website, and the use of this Website constitutes an express
          agreement with all the terms and conditions contained herein in full.
          Do not continue to use this Website if you have any objection to any
          of the Terms and Conditions stated on this page.
        </p>
        <h4>Definitions/Terminology</h4>
        <p>
          The following definitions apply to these Terms and Conditions, Privacy
          Statement, Disclaimer Notice and all Agreements: &quot;User&quot;,
          &quot;Visitor,&quot; &quot;Client,&quot; &quot;Customer,&quot;
          &quot;You&quot; and &quot;Your&quot; refers to you, the person(s) that
          use this Website. &quot;Million Deals&quot;, &quot;We&quot;,
          &quot;Our&quot; and &quot;Us&quot;, refers to our Website/Company.
          &quot;Party,&quot; &quot;Parties,&quot; or &quot;Us,&quot; refers to
          both you and us. All terms refer to all considerations of Million
          Deals necessary to undertake support to you for the express purpose of
          meeting your User needs in respect of our services, under and subject
          to, prevailing law of the state or country in which [WEBSITE NAME]
          operates (Nepal). Any use of these definitions or other glossary in
          the singular, plural, capitalization, and/or pronoun are
          interchangeable but refer to the same.
        </p>
        <h4>Intellectual Property Rights</h4>
        <p>
          Other than the content you own and opted to include on this Website,
          under these Terms, Million Deals and/or its licensors own and reserve
          all intellectual property rights of this Website. You are granted a
          limited license, subject to the restrictions entailed in these Terms
          and Conditions, for purposes of viewing this Website&apos;s content.
        </p>
        <h4>Services</h4>
        <p>
          The content of this Website is not intended for use or distribution to
          any person or entity in any jurisdiction, geographical location, or
          country/state where such use or distribution will be contrary to the
          laws and regulations or subject Million Deals to any form of
          registration, claims, demands, costs, liabilities, damages, or
          expenses.
        </p>
        <p>
          The Website is intended for users who are at least 18 years of age. If
          you are under the age of 18, you cannot use or register to use this
          Website or its services without parental permission or consent. By
          agreeing to these Terms and Conditions, you have the necessary legal
          capacity to comply and be bound by these Terms and Conditions.
        </p>
        <h4>Acceptable Use</h4>
        <p>
          You may use this Website as permitted by these Terms and Conditions
          and may not use this Website for any purpose other than for which
          Million Deals makes the Website and its services available.
        </p>
        <h4>Cookies</h4>
        <p>
          Million Deals employs the use of cookies. By accessing Our Website,
          You agree to use cookies in Agreement with our Cookie Policy.
        </p>
        <p>
          Our interactive Website&apos;s optimal functionality uses cookies to
          retrieve the User&apos;s information for each visit. Some of our
          affiliate partners may also use cookies.
        </p>
        <h4>License</h4>
        <p>
          Unless otherwise stated, Million Deals and/or its licensors own the
          intellectual property rights for all content on Million Deals. All
          intellectual property rights are reserved. You may access any Website
          content from Million Deals for your personal use subject to
          restrictions set in these terms and conditions.
        </p>
        <p>Million Deals hereby restricts you from all of the following:</p>
        <ol>
          <li>Republishing any Million Deals content in any media;</li>
          <li>
            Reproducing, duplicating, or copying any Million Deals content ;
          </li>
          <li>
            Selling, renting, sublicensing, and/or otherwise commercializing any
            Million Deals content;
          </li>
          <li>
            Publicly performing and/or displaying any Million Deals content;
          </li>
          <li>
            Using this Website in a manner that is, or maybe, damaging and/or
            impacts user access to this Website;
          </li>
          <li>
            Using this Website contrary to the relevant rules, laws, and
            regulations of your country of residence, or in a manner that
            causes, or may cause, harm to the Website, or any person or business
            entity;
          </li>
          <li>
            Conducting data mining or any other similar activity concerning this
            Website, or while using this Website; and
          </li>
          <li>
            Using this Website to engage in any form of business advertising or
            marketing.
          </li>
        </ol>
        <p>
          Specific areas of this Website may be restricted from user access, and
          Million Deals may further extend such restriction to the entire
          Website, at any time, and in its sole discretion. Any user
          identification, security key, or password you may have on this Website
          are confidential, and you are responsible for maintaining such
          information confidentiality.
        </p>
        <h4>Linking and Hyperlinking Rights</h4>
        <p>
          We reserve the right to file requests that you remove all links or any
          particular link created by you that redirect to our Website, and you
          approve to immediately remove such links to our Website upon request.
          We may amend the terms and conditions of these linking rights at any
          time. By continuously linking to our Website, you agree to be bound to
          and follow the terms of this linking policy.
        </p>
        <p>
          Feel free to contact us if you find any link on our Website that is
          offensive, and we may consider requests to remove such links. Still,
          we are not obligated to do so or respond to you directly or
          immediately.
        </p>
        <p>
          <strong>Hyperlinking to our Content</strong>
        </p>
        <p>
          Organizations such as search engines, government agencies, news
          organizations, and online directories may link to our Website without
          prior written approval. We may review other link requests from popular
          consumer and/or information specialists, charity organizations,
          internet portals, educational institutions, trade associations, and
          dot.com community sites. Any interested organization must inform and
          contact us for further information regarding our linking policy.
          However, such linking does not imply endorsement, sponsorship,
          partnership, or approval by us of any kind.
        </p>
        <p>
          No use of our logo or other design intellectual property will be
          allowed for linking, except a trademark license agreement.
        </p>
        <p>
          <strong>Link to third-party content</strong>
        </p>
        <p>
          This Website may contain links to websites or applications operated by
          third parties. Please know that we do not control any such third-party
          websites or applications or the third party operator. Million Deals is
          not responsible for and does not endorse any third-party websites or
          applications or their availability or content.
        </p>
        <p>
          Million Deals accepts no responsibility for adverts contained within
          the Website. You agree that you do so at your own risk when you
          purchase any goods and/or services from any such third party. The
          advertiser, and not us, remains responsible for such goods and/or
          services, and if you have any questions or complaints about them, you
          should contact the advertiser
        </p>
        <h4>User Content</h4>
        <p>
          In these Website Standard Terms and Conditions, &quot;User
          Content&quot; shall mean any audio, video, text, images, or other
          material or content you choose to display on this Website. Concerning
          user content, by displaying it, you grant Million Deals a
          non-exclusive, worldwide, irrevocable, royalty-free, sub-licensable
          license to use, reproduce, adapt, publish, translate and distribute it
          on any media.
        </p>
        <p>
          User Content must be your own and must not be infringing on any third
          party&apos;s rights. Million Deals reserves the right to remove any of
          your content from this Website at any time, without notice.
        </p>
        <p>
          Million Deals is permitted to monitor your activities on the Website
          and remove any user content considered inappropriate, offensive,
          contrary to applicable laws and regulations, or causes a breach of
          these Terms and Conditions.
        </p>
        <p>You warrant and represent that:</p>
        <ol>
          <li>
            You are entitled to upload/input/publicize content on our Website
            and have the necessary legal capacity, license or consent to do so;
          </li>
          <li>
            Your content does not infringe any intellectual property right,
            including without limitation to copyright, patent, or trademark of
            any third party;
          </li>
          <li>
            Your content is true, accurate, current, complete, and relate to you
            and not a third party;
          </li>
          <li>
            Your content does not contain any libelous, defamatory, offensive,
            immoral, or otherwise illegal material which is an invasion of
            privacy; and
          </li>
          <li>
            The content will not be used to solicit or promote business or
            custom or present commercial activities or unlawful activity.
          </li>
        </ol>
        <p>
          You now grant Million Deals a non-exclusive license to use, reproduce,
          edit and authorize others approved by us to use, reproduce and edit
          any of your content in any form, format, or media.
        </p>
        <h4>Privacy Policy</h4>
        <p>
          By using this Website and its services, you may provide us with
          certain personal information. By using Million Deals or its services,
          you authorize us to use your information in any country or state that
          we operate in. We reserve the right to use such information to improve
          your user experience and facilitate mailing and traffic, and market
          analytics.
        </p>
        <p>
          By accessing this Website, specific information about the User, such
          as Internet protocol (IP) addresses, site navigation, user software,
          and the surfing time, along with other similar information, will be
          stored on Million Deals servers. Information about their identities,
          such as name, address, contact details, billing information, and other
          information stored on this Website, will strictly be used only for
          statistical purposes and will not be published for general access.
          Million Deals, however, assumes no responsibility for the security of
          this information.
        </p>
        <h4>Disclaimers/Warranties/Limitation of Liabilities</h4>
        <p>
          Million Deals Website is provided &quot;as is,&quot; with all
          liabilities, and Million Deals makes no express or implied
          undertakings, representations, or warranties, of any kind related to
          this Website or the content contained on this Website.
        </p>
        <p>
          Million Deals does not make any endorsements, warranties, or
          representations about the accuracy, reliability, expertise, or
          completeness of any such content. You agree that reliance on any such
          content shall be at the User&apos;s risk. The Million Deals
          periodically changes, adds, modifies, improves, or updates this
          Website&apos;s consent with or without prior notice. Under no
          circumstance shall Million Deals be liable for any loss, damage,
          injury, liability, or expense incurred or suffered from the use of
          this Website, including, without limitation, any fault, error,
          omission, commission, delay, failure, interruption, deletion,
          alteration, disruption, cessation or incursion concerning such use by
          us, our affiliates or any third party. Under no circumstance shall
          Million Deals or any of its partners and affiliates be liable for any
          direct, indirect, consequential, accidental, or special damages, even
          if Million Deals has been advised against the risk or possibility of
          such damages. The User agrees that Million Deals will not be liable
          for any conduct or behaviour of the User arising from the use of this
          Website. As a result, the use of this Website and all or any of its
          content is at the User&apos;s sole risk.
        </p>
        <p>
          In no event shall Million Deals, nor any of its officers, directors,
          employees, and affiliates, be liable for any loss, injury, or damage
          arising out of your use of this Website, whether, under contract,
          tort, or otherwise, and Million Deals, including its officers,
          directors, employees, and affiliates shall not be liable for any
          indirect, consequential or special liability arising out of your use
          of this Website.
        </p>
        <h4>Indemnification</h4>
        <p>
          As a condition for the use of this Website, the User agrees to
          indemnify Million Deals and its affiliates to the fullest extent, from
          and against all actions, claims, liabilities, losses, damages, costs,
          demands, and expenses (including reasonable attorney&apos;s fees)
          arising out of the User&apos;s use of this Website, including without
          limitation, any claim related to the breach of any of the provisions
          of these Terms and Conditions. If dissatisfied with any or all of the
          content on this Website or any or all of its Terms and Conditions, the
          User may discontinue using this Website.
        </p>
        <h4>Termination</h4>
        <p>
          The provisions of these Terms and Conditions shall remain in full
          force and effect while you use the Million Deals or its services.
          Users may terminate their use by following the instructions for
          terminating user accounts in your account settings or by contacting us
          at info@milliondeals.com.np.
        </p>
        <p>
          We reserve the right and sole discretion to, and without notice or
          liability, deny access to and use of the Website (including blocking
          specific IP addresses) to any user for any reason including but not
          limited to breach of any representation, warranty, or Agreement in
          these Terms or any applicable law or regulation.
        </p>
        <p>
          We also reserve the right, if, in our sole discretion, we determine
          that your use of the Website or its services is in breach of these
          Terms and Conditions or of any applicable law or regulation, to
          terminate your use of the Website and its services or delete your
          account and any or all of your content, without warning or prior
          notice. Suppose we terminate or suspend your account for any reason
          set out under this section. In that case, you are prohibited from
          registering and creating a new account under your name, or a false
          identity, or the expression of a third party. In addition to
          terminating or suspending your account, Million Deals reserves the
          right to take appropriate legal action(s), including without
          limitation pursuing civil, criminal, and injunctive redress.
        </p>
        <h4>General Provisions</h4>
        <p>
          <strong>Language</strong>
        </p>
        <p>All correspondence made under this Agreement shall be in English.</p>
        <p>
          <strong>Governing Law &amp; Jurisdiction</strong>
        </p>
        <p>
          The Terms and Conditions of this Website will be governed by and
          construed under the laws of the country or state in which Million
          Deals operates. You hereby unconditionally submit to the non-exclusive
          jurisdiction of the courts located in Nepal for the resolution of any
          disputes.
        </p>
        <p>
          <strong>Severability</strong>
        </p>
        <p>
          Suppose any of Term or Condition is proven to be unenforceable or void
          under any applicable law. In that case, such shall not render the
          entirety of these Terms and Conditions unenforceable or invalid. As a
          result, any such provision shall be deleted without affecting the
          remaining provisions herein. The provisions of these Terms and
          Conditions that are unlawful, void, or unenforceable are deemed
          severable from these Terms and Conditions and do not affect any
          remaining provisions&apos; validity and enforceability.
        </p>
        <p>
          <strong>Variation of Terms</strong>
        </p>
        <p>
          Million Deals reserves the right to revise these Terms at any time as
          it sees fit. By using Million Deals, you are expected to review such
          Terms regularly to ensure you comprehend all the Terms and Conditions
          regarding the use of this Website.
        </p>
        <p>
          <strong>Assignment</strong>
        </p>
        <p>
          Million Deals reserves the right to assign, transfer, and subcontract
          its rights and/or obligations under this Agreement without any prior
          notification or consent required. Users shall not be permitted to
          assign, transfer, or subcontract any of your rights and/or obligations
          under these Terms. Furthermore, a person who is not a party to these
          Terms and Conditions shall have no right to enforce any provision
          contained therein.
        </p>
        <p>
          <strong>Preservation of Immunities</strong>
        </p>
        <p>
          Nothing herein shall constitute a limitation upon the privileges and
          immunities of Million Deals, which are specifically reserved.
        </p>
        <p>
          <strong>Waiver</strong>
        </p>
        <p>
          Our failure to exercise any or all of these Terms and Conditions&apos;
          provisions at any point in time shall not operate as a waiver of such
          right or provision.
        </p>
        <p>
          <strong>Entire Agreement</strong>
        </p>
        <p>
          These Terms and Conditions, including any legal notices and
          disclaimers on this Website, constitute the entire Agreement between
          Million Deals and you concerning your use of this Website. Ultimately,
          this Agreement supersedes all prior agreements and understandings
          concerning the same.
        </p>
        <h4>Contact us</h4>
        <p>
          To resolve any complaint or clarification regarding the use of this
          Website or its services or receive information concerning that, please
          contact us at info@milliondeals.com.np.
        </p>
      </div>
    </div>
  );
};

export default LegalDocumentsApproval;

import { React, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import "../../styles/style.css";
import { Helmet } from "react-helmet";
import "../../frontend/vendor/bootstrap/css/bootstrap.min.css";
import logo from "../front-end-images/logo.png";
import { Box, Fade, Modal } from "@mui/material";
import MainLoginComponent from "./MainLoginComponent";
import useMemberAuthentication from "../../components/hooks/useMemberAuthentication";
import { API_URL, axiosPrivate } from "../../api/axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "27px",
  boxShadow: 24,
  p: 4,
};

const UserHeader = () => {
  const [isExpanded, setExpanded] = useState(false);
  const [openLoginPopup, setOpenLoginPopup] = useState(false);
  const [selectedTab, setSelectedTab] = useState("login");

  const handleToggle = () => setExpanded(!isExpanded);

  const handleOpen = () => setOpenLoginPopup(true);
  const handleClose = () => setOpenLoginPopup(false);

  const { isAuthenticated } = useMemberAuthentication();

  const navigate = useNavigate();

  const handleToken = () => {
    return {
      token: `${localStorage.getItem("memberRefreshToken")}`,
    };
  };

  const handleLogout = async (e) => {
    e.preventDefault();

    const { data } = await axiosPrivate.post(
      `${API_URL}/api/v1/members/auth/logout`,
      handleToken()
    );

    if (data?.status === 200) {
      localStorage.removeItem("memberAccessToken");
      localStorage.removeItem("memberRefreshToken");
      localStorage.removeItem("singleDealsClient");
      localStorage.removeItem("memberInvestorKYC");
      localStorage.removeItem("documentsRegistrationFiles");
      localStorage.removeItem("documentsFiles");
      localStorage.removeItem("charterDocs");
      localStorage.removeItem("mobilizationDocs");
      localStorage.removeItem("revenueDocs");
      localStorage.removeItem("singleIntrestedDealsClient");
      localStorage.removeItem("memberEnterpriseKYC");
      localStorage.removeItem("memberType");
      navigate("/");
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  return (
    <>
      {" "}
      <Helmet>
        <meta charset="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="description" content="" />
        <meta name="author" content="" />

        <title>Million Deals</title>
        {/* <!-- Linking the font Poppins--> */}
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />

        {/* <!-- Linking Bootstrap Icon--> */}
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css"
        />
      </Helmet>
      <div className="header">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-3">
              <Link to="/">
                <img alt="" src={logo} className="site-logo" />
              </Link>
            </div>

            <div className="col-lg-7 col-md-7">
              <Navbar className="main-menu" expand="lg" expanded={isExpanded}>
                <Navbar.Toggle
                  style={{ marginLeft: "84%" }}
                  className="navbar-toggler"
                  onClick={handleToggle}
                />
                <Navbar.Collapse id="navbarSupportedContent">
                  <Nav className="ml-auto">
                    <Nav.Link
                      style={{ color: "black", fontWeight: 500 }}
                      as={Link}
                      to="/"
                      activeclassname="active"
                    >
                      Home
                    </Nav.Link>

                    <NavDropdown title="About Us" className="my-nav-dropdown">
                      <NavDropdown.Item
                        as={Link}
                        to="/about-us"
                        activestyle={{ color: "black" }}
                      >
                        About Us
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item
                        as={Link}
                        to="/our-teams"
                        activestyle={{ color: "black" }}
                      >
                        Our Team
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item
                        as={Link}
                        to="/our-process"
                        activestyle={{ color: "black" }}
                      >
                        Our Process
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item
                        as={Link}
                        to="/enterprise-only"
                        activestyle={{ color: "black" }}
                      >
                        For Enterprise
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item
                        as={Link}
                        to="/investor-only"
                        activestyle={{ color: "black" }}
                      >
                        For Investor
                      </NavDropdown.Item>
                    </NavDropdown>

                    <Nav.Link
                      style={{ color: "black", fontWeight: 500 }}
                      as={Link}
                      to="/deals-listing"
                    >
                      Listings
                    </Nav.Link>

                    <Nav.Link
                      style={{ color: "black", fontWeight: 500 }}
                      as={Link}
                      to="/news-&-blogs"
                    >
                      News
                    </Nav.Link>
                    <Nav.Link
                      style={{ color: "black", fontWeight: 500 }}
                      as={Link}
                      to="/contact-us"
                    >
                      Contact
                    </Nav.Link>
                    <Nav.Link
                      style={{ color: "black", fontWeight: 500 }}
                      as={Link}
                      to="/faqs"
                    >
                      FAQs
                    </Nav.Link>
                    {isAuthenticated && (
                      <Nav.Link
                        style={{ color: "black", fontWeight: 500 }}
                        as={Link}
                        to="/dashboard"
                      >
                        Dashboard
                      </Nav.Link>
                    )}
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
            </div>
            {!isAuthenticated ? (
              <div className="col-lg-2 col-md-2">
                <button className="dark-btn log-btn" onClick={handleOpen}>
                  Log In / Sign Up
                </button>
              </div>
            ) : (
              <>
                <div className="col-lg-2 col-md-2">
                  <button
                    className="dark-btn log-out-btn "
                    onClick={handleLogout}
                  >
                    Logout
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openLoginPopup}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={openLoginPopup}>
          <Box sx={style}>
            <MainLoginComponent
              selectedTab={selectedTab}
              handleTabChange={handleTabChange}
              handleClose={handleClose}
            />
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default UserHeader;

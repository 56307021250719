import React, { useState } from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { API_URL, axiosPublic } from "../../api/axios";
import { LoadingButton } from "@mui/lab";

const MemberResetPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [forgotPasswordCode, setForgotPasswordCode] = useState();
  const [newPassword, setNewPassword] = useState("");

  const returnHomePage = (e) => {
    e.preventDefault();
    navigate("/");
  };

  const resetPasswordHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data } = await axiosPublic.post(
        `${API_URL}/api/v1/members/auth/reset_password`,
        { forgot_password_code: forgotPasswordCode, new_password: newPassword }
      );
      if (data?.status === 200) {
        toast.success(data.message);
        // Remove the session from localStorage
        localStorage.removeItem("memberAccessToken");
        localStorage.removeItem("memberRefreshToken");

        // Redirect to the login page
        setTimeout(() => {
          navigate("/login");
        }, 1000);
      }
      if (data?.status === 400) {
        toast.error(data.message);
        setLoading(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setLoading(false);
    }
  };

  return (
    <div className="container vh-100 d-flex align-items-center justify-content-center">
      <div className="auth" style={{ width: "40%", marginBottom: "20%" }}>
        <div className="auth-body">
          <h4
            className="text-center"
            style={{ color: "#0b6885", fontSize: "26px" }}
          >
            Forgot Password?
          </h4>
          <br />
          <div className="mb-3">
            <label
              htmlFor="forgot_password_code"
              className="form-label"
              style={{ color: "black", fontSize: "16px" }}
            >
              Forgot Password Code(check Email for the code)
            </label>
            <input
              type="number"
              placeholder="Reset Code"
              value={forgotPasswordCode}
              onChange={(e) => setForgotPasswordCode(e.target.value)}
              className="form-control"
              required
            />
          </div>
          <div className="mb-3">
            <label
              htmlFor="new_password"
              className="form-label"
              style={{ color: "black", fontSize: "16px" }}
            >
              New Password
            </label>
            <input
              type="password"
              placeholder="New Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="form-control"
              autoComplete="off"
              required
            />
          </div>

          <LoadingButton
            disabled={!forgotPasswordCode || !newPassword}
            loading={loading}
            loadingPosition="end"
            type="submit"
            onClick={resetPasswordHandler}
            className="btn"
            style={{
              width: "25%",
              backgroundColor: "#0b6885",
              color: "white",
              fontSize: "12px",
            }}
          >
            Submit
          </LoadingButton>
          <div>
            <Button
              onClick={returnHomePage}
              style={{
                backgroundColor: "white",
                color: "black",
                fontSize: "14px",
                fontFamily: "inherit",
                textDecorationLine: "underline",
              }}
            >
              Return back to HomePage.
            </Button>
            <ToastContainer
              position="top-right"
              autoClose={2000}
              theme="colored"
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MemberResetPassword;

import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import LoadingComp from "../../components/LoadingComp";
import UserFooter from "../components/UserFooter";
import UserHeader from "../components/UserHeader";
import "../../styles/style.css";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { API_URL } from "../../api/axios";
import { useDispatch, useSelector } from "react-redux";
import { getAllBlogsPublicAction } from "../public-routes/PublicRoutesActions";
import ArrowButton from "../components/ArrowButton";

const BlogsSinglePagePublic = () => {
  const [singleBlogs, setSingleBlogs] = useState(
    JSON.parse(localStorage.getItem("singleBlogs")) || {}
  );
  const [allBlogs, setAllBlogs] = useState([]);

  const { allBlogsPublic, loading } = useSelector(
    (state) => state.getAllBlogsPublic
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!allBlogsPublic) {
      dispatch(getAllBlogsPublicAction());
    }
    if (allBlogsPublic) {
      setAllBlogs(allBlogsPublic?.data);
    }
  }, [dispatch, allBlogsPublic]);

  return (
    <>
      <UserHeader />
      <Helmet>
        <meta charset="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="description" content="" />
        <meta name="author" content="" />

        <title>Million Deals</title>
        {/* <!-- Linking the font Poppins--> */}
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />

        {/* <!-- Linking Bootstrap Icon--> */}
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css"
        />
      </Helmet>
      <ArrowButton />
      {loading ? (
        <LoadingComp />
      ) : (
        <>
          <div className="page-title">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <h1>{singleBlogs.blog_name}</h1>
                  <Link className="link" to="/">
                    Home
                  </Link>{" "}
                  /{" "}
                  <Link className="link" to="/news-&-blogs">
                    News & Blog{" "}
                  </Link>
                  / {singleBlogs.blog_name}
                </div>
              </div>
            </div>
          </div>

          <div className="page-content">
            <div className="container">
              <div className="row">
                <div className="col-md-9">
                  <h2>{singleBlogs.blog_name}</h2>
                  {singleBlogs.blog_image && singleBlogs.blog_image ? (
                    <img
                      style={{ cursor: "pointer" }}
                      alt=""
                      src={`${API_URL}/uploads/blogs/${singleBlogs.blog_image}`}
                    />
                  ) : (
                    <img
                      style={{ cursor: "pointer" }}
                      alt=""
                      src="https://i.postimg.cc/vZvJdnRS/blank-head-profile-pic-for-a-man.jpg"
                    />
                  )}

                  <p
                    className="section-text"
                    dangerouslySetInnerHTML={{
                      __html: singleBlogs.blog_description,
                    }}
                  ></p>
                </div>
                <div className="col-md-3">
                  <h3>Popular Posts</h3>
                  {allBlogs?.length > 0
                    ? allBlogs?.slice(0, 5)?.map((option) => (
                        <div className="popular-single">
                          <Link className="link" href="#">
                            <h4>
                              <i className="bi bi-calendar-fill"></i>{" "}
                              <Moment format="MMM D, YYYY">
                                {option.created_at}
                              </Moment>
                            </h4>
                            <h2>{option.blog_name?.slice(0, 150)}</h2>
                          </Link>
                        </div>
                      ))
                    : "No Popular Post Found"}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <UserFooter />
    </>
  );
};

export default BlogsSinglePagePublic;

import {
  DELETE_SUCCESS_STORIES_FAIL,
  DELETE_SUCCESS_STORIES_REQUEST,
  DELETE_SUCCESS_STORIES_RESET,
  DELETE_SUCCESS_STORIES_SUCCESS,
  GET_ALL_SUCCESS_STORIES_FAIL,
  GET_ALL_SUCCESS_STORIES_REQUEST,
  GET_ALL_SUCCESS_STORIES_RESET,
  GET_ALL_SUCCESS_STORIES_SUCCESS,
  GET_SINGLE_SUCCESS_STORIES_FAIL,
  GET_SINGLE_SUCCESS_STORIES_REQUEST,
  GET_SINGLE_SUCCESS_STORIES_RESET,
  GET_SINGLE_SUCCESS_STORIES_SUCCESS,
  POST_SUCCESS_STORIES_FAIL,
  POST_SUCCESS_STORIES_REQUEST,
  POST_SUCCESS_STORIES_RESET,
  POST_SUCCESS_STORIES_SUCCESS,
  PUT_SUCCESS_STORIES_FAIL,
  PUT_SUCCESS_STORIES_REQUEST,
  PUT_SUCCESS_STORIES_RESET,
  PUT_SUCCESS_STORIES_SUCCESS,
} from "./SuccessStoriesConstants";

export const getAllSuccessStoriesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_SUCCESS_STORIES_REQUEST:
      return { loading: true };
    case GET_ALL_SUCCESS_STORIES_SUCCESS:
      return { loading: false, allSuccessStories: action.payload };
    case GET_ALL_SUCCESS_STORIES_FAIL:
      return { loading: false, error: action.payload };
    case GET_ALL_SUCCESS_STORIES_RESET:
      return {};
    default:
      return state;
  }
};

export const getSingleSuccessStoriesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_SINGLE_SUCCESS_STORIES_REQUEST:
      return { loading: true };
    case GET_SINGLE_SUCCESS_STORIES_SUCCESS:
      return { loading: false, singleSuccessStories: action.payload };
    case GET_SINGLE_SUCCESS_STORIES_FAIL:
      return { loading: false, error: action.payload };
    case GET_SINGLE_SUCCESS_STORIES_RESET:
      return {};
    default:
      return state;
  }
};

export const postSuccessStoriesReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_SUCCESS_STORIES_REQUEST:
      return { loading: true };
    case POST_SUCCESS_STORIES_SUCCESS:
      return { loading: false, success: true };
    case POST_SUCCESS_STORIES_FAIL:
      return { loading: false, error: action.payload };
    case POST_SUCCESS_STORIES_RESET:
      return {};
    default:
      return state;
  }
};

export const putSuccessStoriesReducer = (state = {}, action) => {
  switch (action.type) {
    case PUT_SUCCESS_STORIES_REQUEST:
      return { loading: true };
    case PUT_SUCCESS_STORIES_SUCCESS:
      return { loading: false, success: true };
    case PUT_SUCCESS_STORIES_FAIL:
      return { loading: false, error: action.payload };
    case PUT_SUCCESS_STORIES_RESET:
      return {};
    default:
      return state;
  }
};

export const deleteSuccessStoriesReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_SUCCESS_STORIES_REQUEST:
      return { loading: true };
    case DELETE_SUCCESS_STORIES_SUCCESS:
      return { loading: false, success: true };
    case DELETE_SUCCESS_STORIES_FAIL:
      return { loading: false, error: action.payload };
    case DELETE_SUCCESS_STORIES_RESET:
      return {};
    default:
      return state;
  }
};

import { TableBody, Toolbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomContainer from "../../components/CustomContainer";
import Topbar from "../../components/Topbar";
import LoadingComp from "../../components/LoadingComp";
import { useDispatch, useSelector } from "react-redux";
import Popup from "../../components/Popup";
import {
  GET_ALL_CONTACTS_RESET,
  GET_SINGLE_CONTACTS_RESET,
} from "./ManageContactsConstants";
import {
  getAllContactsAction,
  getSingleContactsAction,
} from "./ManageContactsActions";
import ManageContactsTableCollapse from "./ManageContactsTableCollapse";
import useCustomTable from "../../components/hooks/useCustomTable";
import Notification from "../../components/Notification";
import ManageContactsSingleDetail from "./ManageContactsSingleDetail";

const tableHeader = [
  { id: "full_name", label: "Full Name" },
  { id: "email", label: "Email Address" },
  { id: "phone", label: "Phone" },
  { id: "created_at", label: "Created Date" },
  { id: "details", label: "Details", disableSorting: true },
];

const ManageContacts = () => {
  const [tableData, setTableData] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [filterFn, setFilterFn] = useState({
    fn: (item) => {
      return item;
    },
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const dispatch = useDispatch();

  const { allContacts, error, loading } = useSelector(
    (state) => state.getAllContacts
  );

  const {
    singleContact,
    error: singleContactError,
    loading: loadingDetail,
  } = useSelector((state) => state.getSingleContact);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (error) {
    setNotify({
      isOpen: true,
      message: error,
      type: "error",
    });
    dispatch({ type: GET_ALL_CONTACTS_RESET });
  }

  if (singleContactError) {
    setNotify({
      isOpen: true,
      message: singleContactError,
      type: "error",
    });
    dispatch({ type: GET_SINGLE_CONTACTS_RESET });
  }

  useEffect(() => {
    if (!allContacts) {
      dispatch(getAllContactsAction());
    }
    if (allContacts) {
      setTableData(allContacts?.data);
    }
  }, [allContacts, dispatch]);

  useEffect(() => {
    dispatch(getAllContactsAction());
  }, [dispatch]);

  const {
    TableContainer,
    TblHead,
    TblPagination,
    tableDataAfterPagingAndSorting,
  } = useCustomTable(tableData, tableHeader, filterFn);

  const contactHandler = (id) => {
    dispatch(getSingleContactsAction(id));
    setOpenPopup(true);
  };
  return (
    <>
      <CustomContainer>
        <Toolbar>
          <Topbar title="Feedbacks" subtitle="See all the Feedbacks" />
        </Toolbar>
        {loading ? (
          <LoadingComp />
        ) : (
          <>
            <TableContainer>
              <TblHead />
              <TableBody>
                {tableDataAfterPagingAndSorting().map((item) => (
                  <ManageContactsTableCollapse
                    item={item}
                    key={item.unique_id}
                    contactHandler={contactHandler}
                  />
                ))}
              </TableBody>
            </TableContainer>
            <TblPagination />
          </>
        )}
      </CustomContainer>
      <Popup
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        title="Contact Details"
      >
        <ManageContactsSingleDetail
          singleContact={singleContact && singleContact?.data}
          loadingDetail={loadingDetail}
          setOpenPopup={setOpenPopup}
        />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};

export default ManageContacts;

import React, { useState } from "react";

export const useForm = (initialFormValues, detailFormValues) => {
  const [values, setValues] = useState(initialFormValues);
  const [detailValues, setDetailValues] = useState(detailFormValues);
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues((prev) => ({ ...prev, [name]: value }));
  };

  return {
    values,
    setValues,
    detailValues,
    setDetailValues,
    handleInputChange,
    errors,
    setErrors,
  };
};

export const Form = (props) => {
  const { children, ...other } = props;
  return (
    <form
      style={{ width: "80%", marginLeft: "10%" }}
      autoComplete="off"
      {...other}
    >
      {props.children}
    </form>
  );
};

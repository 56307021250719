import {
  DELETE_ADVISORS_FAIL,
  DELETE_ADVISORS_REQUEST,
  DELETE_ADVISORS_RESET,
  DELETE_ADVISORS_SUCCESS,
  GET_ALL_ADVISORS_FAIL,
  GET_ALL_ADVISORS_REQUEST,
  GET_ALL_ADVISORS_RESET,
  GET_ALL_ADVISORS_SUCCESS,
  GET_SINGLE_ADVISORS_FAIL,
  GET_SINGLE_ADVISORS_REQUEST,
  GET_SINGLE_ADVISORS_RESET,
  GET_SINGLE_ADVISORS_SUCCESS,
  POST_ADVISORS_FAIL,
  POST_ADVISORS_REQUEST,
  POST_ADVISORS_RESET,
  POST_ADVISORS_SUCCESS,
  PUT_ADVISORS_FAIL,
  PUT_ADVISORS_REQUEST,
  PUT_ADVISORS_RESET,
  PUT_ADVISORS_SUCCESS,
} from "./AdvisorsConstants";

export const getAllAdvisorReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_ADVISORS_REQUEST:
      return { loading: true };
    case GET_ALL_ADVISORS_SUCCESS:
      return { loading: false, allAdvisor: action.payload };
    case GET_ALL_ADVISORS_FAIL:
      return { loading: false, error: action.payload };
    case GET_ALL_ADVISORS_RESET:
      return {};
    default:
      return state;
  }
};

export const getSingleAdvisorReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_SINGLE_ADVISORS_REQUEST:
      return { loading: true };
    case GET_SINGLE_ADVISORS_SUCCESS:
      return { loading: false, singleAdvisor: action.payload };
    case GET_SINGLE_ADVISORS_FAIL:
      return { loading: false, error: action.payload };
    case GET_SINGLE_ADVISORS_RESET:
      return {};
    default:
      return state;
  }
};

export const postAdvisorReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_ADVISORS_REQUEST:
      return { loading: true };
    case POST_ADVISORS_SUCCESS:
      return { loading: false, success: action.payload };
    case POST_ADVISORS_FAIL:
      return { loading: false, error: action.payload };
    case POST_ADVISORS_RESET:
      return {};
    default:
      return state;
  }
};

export const putAdvisorReducer = (state = {}, action) => {
  switch (action.type) {
    case PUT_ADVISORS_REQUEST:
      return { loading: true };
    case PUT_ADVISORS_SUCCESS:
      return { loading: false, success: action.payload };
    case PUT_ADVISORS_FAIL:
      return { loading: false, error: action.payload };
    case PUT_ADVISORS_RESET:
      return {};
    default:
      return state;
  }
};

export const deleteAdvisorReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_ADVISORS_REQUEST:
      return { loading: true };
    case DELETE_ADVISORS_SUCCESS:
      return { loading: false, success: action.payload };
    case DELETE_ADVISORS_FAIL:
      return { loading: false, error: action.payload };
    case DELETE_ADVISORS_RESET:
      return {};
    default:
      return state;
  }
};

import {
  DELETE_PARTNERS_FAIL,
  DELETE_PARTNERS_REQUEST,
  DELETE_PARTNERS_RESET,
  DELETE_PARTNERS_SUCCESS,
  GET_ALL_PARTNERS_FAIL,
  GET_ALL_PARTNERS_REQUEST,
  GET_ALL_PARTNERS_RESET,
  GET_ALL_PARTNERS_SUCCESS,
  GET_SINGLE_PARTNERS_FAIL,
  GET_SINGLE_PARTNERS_REQUEST,
  GET_SINGLE_PARTNERS_RESET,
  GET_SINGLE_PARTNERS_SUCCESS,
  POST_PARTNERS_FAIL,
  POST_PARTNERS_REQUEST,
  POST_PARTNERS_RESET,
  POST_PARTNERS_SUCCESS,
  PUT_PARTNERS_FAIL,
  PUT_PARTNERS_REQUEST,
  PUT_PARTNERS_RESET,
  PUT_PARTNERS_SUCCESS,
} from "./PartnersConstants";

export const getAllPartnerReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_PARTNERS_REQUEST:
      return { loading: true };
    case GET_ALL_PARTNERS_SUCCESS:
      return { loading: false, allPartner: action.payload };
    case GET_ALL_PARTNERS_FAIL:
      return { loading: false, error: action.payload };
    case GET_ALL_PARTNERS_RESET:
      return {};
    default:
      return state;
  }
};

export const getSinglePartnerReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_SINGLE_PARTNERS_REQUEST:
      return { loading: true };
    case GET_SINGLE_PARTNERS_SUCCESS:
      return { loading: false, singlePartner: action.payload };
    case GET_SINGLE_PARTNERS_FAIL:
      return { loading: false, error: action.payload };
    case GET_SINGLE_PARTNERS_RESET:
      return {};
    default:
      return state;
  }
};

export const postPartnerReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_PARTNERS_REQUEST:
      return { loading: true };
    case POST_PARTNERS_SUCCESS:
      return { loading: false, success: action.payload };
    case POST_PARTNERS_FAIL:
      return { loading: false, error: action.payload };
    case POST_PARTNERS_RESET:
      return {};
    default:
      return state;
  }
};

export const putPartnerReducer = (state = {}, action) => {
  switch (action.type) {
    case PUT_PARTNERS_REQUEST:
      return { loading: true };
    case PUT_PARTNERS_SUCCESS:
      return { loading: false, success: action.payload };
    case PUT_PARTNERS_FAIL:
      return { loading: false, error: action.payload };
    case PUT_PARTNERS_RESET:
      return {};
    default:
      return state;
  }
};

export const deletePartnerReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_PARTNERS_REQUEST:
      return { loading: true };
    case DELETE_PARTNERS_SUCCESS:
      return { loading: false, success: action.payload };
    case DELETE_PARTNERS_FAIL:
      return { loading: false, error: action.payload };
    case DELETE_PARTNERS_RESET:
      return {};
    default:
      return state;
  }
};

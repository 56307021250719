export const GET_ALL_ABOUT_MILLION_DEALS_REQUEST =
  "GET_ALL_ABOUT_MILLION_DEALS_REQUEST";
export const GET_ALL_ABOUT_MILLION_DEALS_SUCCESS =
  "GET_ALL_ABOUT_MILLION_DEALS_SUCCESS";
export const GET_ALL_ABOUT_MILLION_DEALS_FAIL =
  "GET_ALL_ABOUT_MILLION_DEALS_FAIL";
export const GET_ALL_ABOUT_MILLION_DEALS_RESET =
  "GET_ALL_ABOUT_MILLION_DEALS_RESET";

export const PUT_ABOUT_MILLION_DEALS_REQUEST =
  "PUT_ABOUT_MILLION_DEALS_REQUEST";
export const PUT_ABOUT_MILLION_DEALS_SUCCESS =
  "PUT_ABOUT_MILLION_DEALS_SUCCESS";
export const PUT_ABOUT_MILLION_DEALS_FAIL = "PUT_ABOUT_MILLION_DEALS_FAIL";
export const PUT_ABOUT_MILLION_DEALS_RESET = "PUT_ABOUT_MILLION_DEALS_RESET";

import React, { useRef, useState, useEffect } from "react";
import { axiosPublic } from "../api/axios";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  CssBaseline,
  TextField,
  Box,
  Typography,
  Container,
  CircularProgress,
} from "@mui/material";
import logo from "../frontend/front-end-images/logo.png";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import useAuth from "../components/hooks/useAuth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useAuthentication from "../components/hooks/useAuthentication";
import useAdminAuthentication from "../components/hooks/useAdminAuthentication";
import { setAccessToken, setRefreshToken } from "../services/storage";

const LOGIN_URL = "/api/v1/admin/auth/login";

const theme = createTheme();

const LoginAdmin = () => {
  const [loading, setLoading] = useState(false);
  const { isAuth } = useAuthentication();

  const { isAdminAuthenticated, loginAdmin } = useAdminAuthentication();

  const { setAuth } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();

  const from = location.state?.from?.pathname || "/admin";

  const emailRef = useRef();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    emailRef.current.focus();
  }, []);

  useEffect(() => {
    if (isAdminAuthenticated) {
      return navigate("/admin");
    }
  }, [isAdminAuthenticated, navigate]);

  const setToken = (accessToken, refreshToken) => {
    setAccessToken(accessToken);
    setRefreshToken(refreshToken);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axiosPublic.post(
        LOGIN_URL,
        JSON.stringify({ email_address: email, password }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      const accessToken = response?.data?.data?.accessToken;
      const refreshToken = response?.data?.data?.refreshToken;

      loginAdmin({
        accessToken,
        refreshToken,
      });

      // setToken(accessToken, refreshToken);

      setAuth({
        email_address: email,
        password: password,
        accessToken: accessToken,
        refreshToken: refreshToken,
      });
      setEmail("");
      setPassword("");

      navigate(from, { replace: true });
    } catch (err) {
      if (!err?.response) {
        toast.error("No Server Response");
      } else if (err.response?.status === 400) {
        toast.error(err?.response?.data?.message) ||
          toast.error("Invalid Credentials");
      } else if (err.response?.status === 401) {
        toast.error("Unauthorized");
      } else {
        toast.error("Login Failed");
      }
    } finally {
      setLoading(false); // set loading state back to false when response is received
    }
  };

  const handleForgotPassword = () => {
    navigate("/admin/forgot_password");
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs" style={{ overflow: "hidden" }}>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 20,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img alt="" src={logo} />
          {/* <Avatar sx={{ m: 2, bgcolor: "#0b6885" }}>
            <LockOutlined />
          </Avatar> */}

          {/* <Typography component="h1" variant="h4">
            Million Deals
          </Typography> */}
          <Typography component="h1" style={{ fontWeight: "600" }}>
            Admin Panel
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              type="email"
              fullWidth
              label="Email Address"
              id="email_address"
              name="email_address"
              autoFocus
              ref={emailRef}
              autoComplete="off"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              required
            />

            <TextField
              margin="normal"
              label="Password"
              fullWidth
              type="password"
              id="password"
              name="password"
              autoComplete="off"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              required
            />

            <LoadingButton
              loading={loading}
              loadingPosition="end"
              type="submit"
              fullWidth
              variant="contained"
              style={{ backgroundColor: "#0b6885" }}
              sx={{ mt: 3, mb: 2 }}
              endIcon={
                loading ? <CircularProgress size={14} color="inherit" /> : ""
              }
            >
              Sign In
            </LoadingButton>

            <ToastContainer
              position="top-right"
              autoClose={2000}
              theme="colored"
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
            />
            <div>
              <Button
                onClick={handleForgotPassword}
                style={{
                  backgroundColor: "white",
                  color: "black",
                }}
              >
                {" "}
                Forgot Password?
              </Button>
            </div>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default LoginAdmin;

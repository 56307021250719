import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import useAuthentication from "../../components/hooks/useAuthentication";
import "../styles.css";
import UserFooter from "./UserFooter";
import UserHeader from "./UserHeader";
import useMemberAuthentication from "../../components/hooks/useMemberAuthentication";

const AuthenticatedMemberLayout = () => {
  const { isAuthenticated } = useMemberAuthentication();

  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      return navigate("/login");
    }
  }, [isAuthenticated, navigate]);

  return (
    <div>
      <UserHeader />
      <Outlet />
      <UserFooter />
    </div>
  );
};

export default AuthenticatedMemberLayout;

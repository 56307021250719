import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ArrowButton from "../components/ArrowButton";
import "../../styles/style.css";
import { useState } from "react";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getAllDealCategoryFilterAction,
  getAllSdgsFilterAction,
} from "../private-routes/PrivateActions";

const amountRanges = [
  { amount: "1", min: 100000, max: 500000, label: "1 to 5 Lakhs" },
  { amount: "2", min: 500000, max: 1000000, label: "5 to 10 Lakhs" },
  { amount: "3", min: 1000000, max: 3000000, label: "10 to 30 Lakhs" },
  { amount: "4", min: 3000000, max: 5000000, label: "30 to 50 Lakhs" },
  { amount: "5", min: 5000000, max: 10000000, label: "50 to 1 Crore" },
  { amount: "6", min: 10000000, max: 50000000, label: "1 to 5 Crore" },
  { amount: "7", min: 50000000, max: 100000000, label: "5 to 10 Crore" },
  { amount: "8", min: 100000000, max: Infinity, label: "10 Crore And Above" },
];

const InvestmentDetails = ({ formData, setFormData, formErrors }) => {
  const [sdgList, setSdgList] = useState([]);
  const [sectorList, setSectorList] = useState([]);

  const dispatch = useDispatch();

  const { allSdgsFilter } = useSelector((state) => state.getAllSdgsFilterList);

  const { allDealsCategoryFilter } = useSelector(
    (state) => state.getAllDealCategoryFilter
  );

  useEffect(() => {
    if (!allDealsCategoryFilter) {
      dispatch(getAllDealCategoryFilterAction());
    }
    if (allDealsCategoryFilter) {
      setSectorList(allDealsCategoryFilter?.data);
    }
  }, [dispatch, allDealsCategoryFilter]);

  useEffect(() => {
    if (!allSdgsFilter) {
      dispatch(getAllSdgsFilterAction());
    }
    if (allSdgsFilter) {
      setSdgList(allSdgsFilter?.data);
    }
  }, [allSdgsFilter, dispatch]);

  useEffect(() => {
    dispatch(getAllDealCategoryFilterAction());
    dispatch(getAllSdgsFilterAction());
  }, [dispatch]);

  const handleSdgChange = (e) => {
    const { name, checked } = e.target;
    if (checked) {
      setFormData((prevData) => {
        const sdgSupportList = Array.isArray(prevData.sdgs)
          ? prevData.sdgs
          : [];

        if (!sdgSupportList.includes(Number(name))) {
          sdgSupportList.push(Number(name));
        }
        return {
          ...prevData,
          sdgs: sdgSupportList,
        };
      });
    } else {
      setFormData((prevData) => {
        const sdgSupportList = Array.isArray(prevData.sdgs)
          ? prevData.sdgs
          : [];
        const index = sdgSupportList.indexOf(Number(name));
        if (index !== -1) {
          sdgSupportList.splice(index, 1);
        }
        return {
          ...prevData,
          sdgs: sdgSupportList,
        };
      });
    }
  };

  const handleSectorChange = (e) => {
    const { name, checked } = e.target;
    if (checked) {
      setFormData((prevData) => {
        const sectorSupportList = Array.isArray(prevData.invest_in)
          ? prevData.invest_in
          : [];

        if (!sectorSupportList.includes(Number(name))) {
          sectorSupportList.push(Number(name));
        }
        return {
          ...prevData,
          invest_in: sectorSupportList,
        };
      });
    } else {
      setFormData((prevData) => {
        const sectorSupportList = Array.isArray(prevData.invest_in)
          ? prevData.invest_in
          : [];
        const index = sectorSupportList.indexOf(Number(name));
        if (index !== -1) {
          sectorSupportList.splice(index, 1);
        }
        return {
          ...prevData,
          invest_in: sectorSupportList,
        };
      });
    }
  };

  const handleAmountChange = (e) => {
    const { name, checked } = e.target;

    if (checked) {
      setFormData((prevData) => {
        const amountList = Array.isArray(prevData.investment_size)
          ? prevData.investment_size
          : [];

        const selectedAmount = amountRanges.find(
          (range) => range.amount === name
        );

        if (selectedAmount && !amountList.includes(selectedAmount.amount)) {
          amountList.push(selectedAmount.amount);
        }

        return {
          ...prevData,
          investment_size: amountList,
        };
      });
    } else {
      setFormData((prevData) => {
        const amountList = Array.isArray(prevData.investment_size)
          ? prevData.investment_size
          : [];

        const selectedAmount = amountRanges.find(
          (range) => range.amount === name
        );

        if (selectedAmount) {
          const index = amountList.indexOf(selectedAmount.amount);
          if (index !== -1) {
            amountList.splice(index, 1);
          }
        }

        return {
          ...prevData,
          investment_size: amountList,
        };
      });
    }
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="description" content="" />
        <meta name="author" content="" />

        <title>Million Deals</title>
        {/* <!-- Linking the font Poppins--> */}
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />

        {/* <!-- Linking Bootstrap Icon--> */}
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css"
        />
      </Helmet>
      <ArrowButton />

      <div className="page-title">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h1>Investor Form</h1>
              <Link className="link" to="/">
                Home
              </Link>{" "}
              /{" "}
              <Link className="link" to="/dashboard">
                Dashboard
              </Link>{" "}
              / Investor Form
            </div>
          </div>
        </div>
      </div>

      <div className="page-content">
        <div className="container">
          <div className="row">
            <div className="col-md-9">
              <div className="invest-form">
                <form>
                  <h2>Investment Details</h2>
                  Preferred Investment Size?{" "}
                  {formErrors.investment_size && (
                    <span className="error">{formErrors.investment_size}</span>
                  )}
                  <br />
                  <div className="form-check">
                    <FormGroup sx={{ "& .MuiSvgIcon-root": { fontSize: 16 } }}>
                      {amountRanges?.map((option) => (
                        <FormControlLabel
                          key={option.amount}
                          control={
                            <Checkbox
                              checked={formData.investment_size.includes(
                                option.amount
                              )}
                              onChange={handleAmountChange}
                              name={option.amount}
                              value={option.amount}
                              inputprops={{ "aria-label": "controlled" }}
                            />
                          }
                          label={option.label}
                        />
                      ))}
                    </FormGroup>
                  </div>
                  <br />
                  Preferred Sectors to Invest In?{" "}
                  {formErrors.invest_in && (
                    <span className="error">{formErrors.invest_in}</span>
                  )}
                  <br />
                  <div className="form-check">
                    <FormGroup sx={{ "& .MuiSvgIcon-root": { fontSize: 16 } }}>
                      {sectorList?.map((option) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={formData.invest_in.includes(
                                option.deal_category_id
                              )}
                              onChange={handleSectorChange}
                              name={option.deal_category_id}
                              value={option.deal_category_id}
                              inputprops={{ "aria-label": "controlled" }}
                            />
                          }
                          label={option.deal_cat_name}
                        />
                      ))}
                    </FormGroup>
                  </div>
                  <br />
                  Do you have or wish to have a sustainability or impact focus
                  in your investments?
                  <br />
                  {formErrors.is_sustainability && (
                    <span className="error">
                      {formErrors.is_sustainability}
                    </span>
                  )}
                  <select
                    name="is_sustainability"
                    id="is_sustainability"
                    value={formData.is_sustainability || ""}
                    onChange={handleInputChange}
                    displayempty="true"
                    inputprops={{ "aria-label": "Without label" }}
                  >
                    <option value="">Select an option</option>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                  </select>
                  <br />
                  {formData.is_sustainability &&
                    formData.is_sustainability !== "0" && (
                      <>
                        Which of the following SDGs do you or do you with you
                        contribute to via your investments?
                        <br />
                        {formErrors.sdgs && (
                          <span className="error">{formErrors.sdgs}</span>
                        )}
                        <br />
                        <div className="form-check">
                          <FormGroup
                            sx={{ "& .MuiSvgIcon-root": { fontSize: 16 } }}
                          >
                            {sdgList?.slice(0, 17)?.map((option) => (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={formData.sdgs.includes(
                                      option.sdg_id
                                    )}
                                    onChange={handleSdgChange}
                                    name={option.sdg_id}
                                    value={option.sdg_id}
                                    inputprops={{ "aria-label": "controlled" }}
                                  />
                                }
                                label={option.sdg_name}
                              />
                            ))}
                          </FormGroup>
                        </div>
                      </>
                    )}
                  <br />
                  Preferred stage of investment?{" "}
                  {formErrors.stage_of_investment && (
                    <span className="error">
                      {formErrors.stage_of_investment}
                    </span>
                  )}
                  <select
                    name="stage_of_investment"
                    id="stage_of_investment"
                    value={formData.stage_of_investment || ""}
                    onChange={handleInputChange}
                  >
                    <option value="">Select an option</option>
                    <option value="1">Idea Stage</option>
                    <option value="2">Growth Stage</option>
                    <option value="3">Neutral</option>
                  </select>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvestmentDetails;

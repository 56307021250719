import React, { useState, useEffect } from "react";
import LoadingComp from "../../components/LoadingComp";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "../../styles/style.css";
import UserHeader from "../components/UserHeader";
import Notification from "../../components/Notification";
import UserFooter from "../components/UserFooter";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllContactDetailsPublicAction,
  postContactFormAction,
} from "../public-routes/PublicRoutesActions";
import { Form } from "../../components/hooks/useForm";
import { Button } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";

const ContactusPagePublic = () => {
  const [contactDetails, setContactDetails] = useState({});
  const [orgName, setOrgName] = useState("");
  const [contact, setContact] = useState();
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const initialFormValues = {
    full_name: orgName,
    phone: contact,
    email: email,
    message: message,
  };

  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { allContactDetailsPublic, loading } = useSelector(
    (state) => state.getAllContactDetailsPublic
  );

  useEffect(() => {
    if (!allContactDetailsPublic) {
      dispatch(getAllContactDetailsPublicAction());
    }
    if (allContactDetailsPublic) {
      setContactDetails(allContactDetailsPublic?.data);
    }
  }, [dispatch, allContactDetailsPublic]);

  const handleContactFormSubmit = (e) => {
    e.preventDefault();
    let errors = {};
    if (!orgName) {
      errors["orgName"] = "Organization name is required";
    }
    if (!contact) {
      errors["contact"] = "Contact number is required";
    }
    if (!email) {
      errors["email"] = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors["email"] = "Email is invalid";
    }
    if (!message) {
      errors["message"] = "Message is required";
    }

    setErrors(errors);

    if (Object.keys(errors).length === 0) {
      dispatch(postContactFormAction(initialFormValues)).then(() => {
        toast.success("Your Feedback has been Submitted Successfully.");
      });
      setOrgName("");
      setContact("");
      setEmail("");
      setMessage("");
    }
  };

  return (
    <>
      <UserHeader />
      <Helmet>
        <meta charset="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="description" content="" />
        <meta name="author" content="" />

        <title>Million Deals</title>
        {/* <!-- Linking the font Poppins--> */}
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />

        {/* <!-- Linking Bootstrap Icon--> */}
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css"
        />
      </Helmet>

      <div className="page-title">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h1>Contact Us</h1>
              <Link className="link" to="/">
                Home
              </Link>
              {""} / Contact Us
            </div>
          </div>
        </div>
      </div>

      <Form onSubmit={handleContactFormSubmit}>
        <div className="contact-page">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <h2>Contact Form</h2>

                <input
                  type="text"
                  id="orgName"
                  value={orgName}
                  className={`form-control ${
                    errors["orgName"] ? "is-invalid" : ""
                  }`}
                  placeholder="Your Name / Organization Name"
                  onChange={(e) => setOrgName(e.target.value)}
                />
                {errors["orgName"] && (
                  <div className="invalid-feedback">*{errors["orgName"]}</div>
                )}

                <input
                  type="number"
                  id="contact"
                  value={contact}
                  className={`form-control ${
                    errors["contact"] ? "is-invalid" : ""
                  }`}
                  placeholder="Contact Number"
                  onChange={(e) => setContact(e.target.value)}
                />
                {errors["contact"] && (
                  <div className="invalid-feedback">*{errors["contact"]}</div>
                )}

                <input
                  type="email"
                  className={`form-control ${
                    errors["email"] ? "is-invalid" : ""
                  }`}
                  id="email"
                  value={email}
                  placeholder="Email Address"
                  onChange={(e) => setEmail(e.target.value)}
                />
                {errors["email"] && (
                  <div className="invalid-feedback">*{errors["email"]}</div>
                )}

                <textarea
                  className={`form-control ${
                    errors["message"] ? "is-invalid" : ""
                  }`}
                  id="message"
                  value={message}
                  placeholder="Explain the service you are looking for..."
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
                {errors["message"] && (
                  <div className="invalid-feedback">*{errors["message"]}</div>
                )}
                <Button
                  type="submit"
                  className="slide-send-btn"
                  variant="contained"
                  style={{ backgroundColor: "#0b6885" }}
                >
                  Send
                </Button>
                <ToastContainer
                  position="top-right"
                  autoClose={2000}
                  theme="colored"
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                />
              </div>

              {loading ? (
                <LoadingComp />
              ) : (
                <>
                  <div className="col-lg-5">
                    <div className="contact-dtl">
                      <h3>Contact Details</h3>

                      <h5>Location</h5>
                      {/* <p>{contactDetails?.location}</p> */}
                      <p>Lalitpur-12, Nepal</p>

                      {/* <h5>Call Us</h5> */}
                      {/* <p>{contactDetails?.phone}</p> */}
                      {/* <p>9876548987</p> */}

                      <h5>Email Address</h5>
                      {/* <p>{contactDetails?.email}</p> */}
                      <p>
                        <Link
                          onClick={() =>
                            (window.location =
                              "mailto:info@milliondeals.com.np")
                          }
                          title="info@milliondeals.com.np"
                          style={{
                            fontSize: "16px",
                            textDecoration: "none",
                            color: "#0b6885",
                          }}
                        >
                          info@milliondeals.com.np
                        </Link>
                      </p>

                      <h5>Website</h5>
                      {/* <p>{contactDetails?.website}</p> */}
                      <p>
                        {" "}
                        <a
                          href="https://milliondeals.com.np"
                          target="_blank"
                          rel="noreferrer"
                          style={{
                            fontSize: "16px",
                            textDecoration: "none",
                            color: "#0b6885",
                          }}
                        >
                          www.milliondeals.com.np
                        </a>
                      </p>
                    </div>

                    {/* <div className="contact-dtl">
                      <h3>Follow Us</h3>

                      <Link>
                        <i className="bi bi-messenger"></i>
                      </Link>
                      <Link style={{ color: "#128c7e" }}>
                        <i className="bi bi-whatsapp"></i>
                      </Link>
                      <Link style={{ color: "#cd486b" }}>
                        <i className="bi bi-instagram"></i>
                      </Link>

                      <Link style={{ color: "#0072b1" }}>
                        <i className="bi bi-linkedin"></i>
                      </Link>
                    </div> */}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </Form>
      <Notification notify={notify} setNotify={setNotify} />

      <UserFooter />
    </>
  );
};

export default ContactusPagePublic;

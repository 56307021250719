import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "../../styles/style.css";
import ArrowButton from "../components/ArrowButton";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getAllDealCategoryPublicAction } from "../public-routes/PublicRoutesActions";

const provinceList = [
  {
    id: 1,
    label: "Province 1",
  },
  {
    id: 2,
    label: "Madhesh Pradesh",
  },
  {
    id: 3,
    label: "Bagmati Pradesh",
  },
  { id: 4, label: "Gandaki Pradesh" },
  {
    id: 5,
    label: "Lumbini Pradesh",
  },
  {
    id: 6,
    label: "Karnali Pradesh",
  },
  { id: 7, label: "Sudurpaschim Pradesh" },
];

const MAX_COUNT = 2;

const CompanyInformation = ({
  formData,
  setFormData,
  panOrVatDocs,
  setPanOrVatDocs,
  formErrors,
}) => {
  const [panOrVatFiles, setPanOrVatFiles] = useState([]);
  const [fileLimit, setFileLimit] = useState(false);
  const [categoryList, setCategoryList] = useState([]);

  const dispatch = useDispatch();

  const { allDealsCategoryPublic } = useSelector(
    (state) => state.getAllDealCategoryPublic
  );

  useEffect(() => {
    if (!allDealsCategoryPublic) {
      dispatch(getAllDealCategoryPublicAction());
    }
    if (allDealsCategoryPublic) {
      setCategoryList(allDealsCategoryPublic?.data);
    }
  }, [allDealsCategoryPublic, dispatch]);

  useEffect(() => {
    dispatch(getAllDealCategoryPublicAction());
  }, [dispatch]);

  const handleUploadPanVatFiles = (files) => {
    const uploaded = [...panOrVatFiles];
    let limitExceeded = false;
    files.forEach((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        if (uploaded.length === MAX_COUNT) setFileLimit(true);
        if (uploaded.length > MAX_COUNT) {
          toast.error(`You can only add a maximum of ${MAX_COUNT} files`);
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
      }
    });
    if (!limitExceeded) {
      setPanOrVatFiles(uploaded);
      setPanOrVatDocs({
        ...panOrVatDocs,
        charter_docs: uploaded,
      });
    }
  };

  const handleFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadPanVatFiles(chosenFiles);
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    setPanOrVatDocs({
      ...panOrVatDocs,
      [e.target.name]: e.target.files,
      charter_docs: panOrVatFiles,
    });
  };

  const date = new Date();

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="description" content="" />
        <meta name="author" content="" />

        <title>Million Deals</title>
        {/* <!-- Linking the font Poppins--> */}
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />

        {/* <!-- Linking Bootstrap Icon--> */}
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css"
        />
      </Helmet>
      <ArrowButton />

      <div className="page-title">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h1>Enterprise Form</h1>
              <Link className="link" to="/">
                Home
              </Link>{" "}
              /{" "}
              <Link className="link" to="/dashboard">
                Dashboard
              </Link>{" "}
              / Enterprise Form
            </div>
          </div>
        </div>
      </div>
      <div className="page-content">
        <div className="container">
          <div className="row">
            <div className="col-9">
              <div className="invest-form">
                <form>
                  <h2>Company's Information</h2>
                  {formData?.is_founder_or_cofounder === "0" && (
                    <>
                      Founder's Name{" "}
                      {formErrors.founder_or_cofounder_name && (
                        <span className="error">
                          {formErrors.founder_or_cofounder_name}
                        </span>
                      )}
                      <input
                        type="text"
                        name="founder_or_cofounder_name"
                        value={formData.founder_or_cofounder_name || ""}
                        onChange={handleInputChange}
                        required
                      />
                      {/* <br /> */}
                      What is your designation in the company?{" "}
                      {formErrors.designation && (
                        <span className="error">{formErrors.designation}</span>
                      )}
                      <input
                        type="text"
                        name="designation"
                        value={formData.designation || ""}
                        onChange={handleInputChange}
                        required
                      />
                      {/* <br /> */}
                      Email Address{" "}
                      {formErrors.company_email && (
                        <span className="error">
                          {formErrors.company_email}
                        </span>
                      )}
                      <input
                        type="email"
                        name="company_email"
                        value={formData.company_email || ""}
                        onChange={handleInputChange}
                        required
                      />
                      {/* <br /> */}
                      Contact Number{" "}
                      {formErrors.contact_number && (
                        <span className="error">
                          {formErrors.contact_number}
                        </span>
                      )}
                      <input
                        type="number"
                        name="contact_number"
                        value={formData.contact_number || ""}
                        onChange={handleInputChange}
                        required
                      />
                      {/* <br /> */}
                      Link to company's website, Linkedin page, or other social
                      media page <br />
                      {formErrors.company_website_or_social && (
                        <span className="error">
                          {formErrors.company_website_or_social}
                        </span>
                      )}
                      <input
                        type="text"
                        name="company_website_or_social"
                        value={formData.company_website_or_social || ""}
                        onChange={handleInputChange}
                        required
                      />
                      {/* <br /> */}
                      No. of Founders{" "}
                      {formErrors.no_of_founders && (
                        <span className="error">
                          {formErrors.no_of_founders}
                        </span>
                      )}
                      <select
                        name="no_of_founders"
                        id="no_of_founders"
                        value={formData.no_of_founders || ""}
                        onChange={handleInputChange}
                        displayempty="true"
                        inputprops={{ "aria-label": "Without label" }}
                      >
                        <option value="">Select an option</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="More Than 5">More Than 5</option>
                      </select>
                      {/* <br /> */}
                    </>
                  )}
                  {formData?.is_founder_or_cofounder === "1" && (
                    <>
                      Email Address{" "}
                      {formErrors.company_email && (
                        <span className="error">
                          {formErrors.company_email}
                        </span>
                      )}
                      <input
                        type="email"
                        name="company_email"
                        value={formData.company_email || ""}
                        onChange={handleInputChange}
                        required
                      />
                      {/* <br /> */}
                      Contact Number{" "}
                      {formErrors.contact_number && (
                        <span className="error">
                          {formErrors.contact_number}
                        </span>
                      )}
                      <input
                        type="number"
                        name="contact_number"
                        value={formData.contact_number || ""}
                        onChange={handleInputChange}
                        required
                      />
                      {/* <br /> */}
                      Link to company's website, Linkedin page, or other social
                      media page
                      <br />
                      {formErrors.company_website_or_social && (
                        <span className="error">
                          {formErrors.company_website_or_social}
                        </span>
                      )}
                      <input
                        type="text"
                        name="company_website_or_social"
                        value={formData.company_website_or_social || ""}
                        onChange={handleInputChange}
                        required
                      />
                      {/* <br /> */}
                      No. of Founders{" "}
                      {formErrors.no_of_founders && (
                        <span className="error">
                          {formErrors.no_of_founders}
                        </span>
                      )}
                      <select
                        name="no_of_founders"
                        id="no_of_founders"
                        value={formData.no_of_founders || ""}
                        onChange={handleInputChange}
                        displayempty="true"
                        inputprops={{ "aria-label": "Without label" }}
                      >
                        <option value="">Select an option</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="More Than 5">More Than 5</option>
                      </select>
                      {/* <br /> */}
                    </>
                  )}
                  Structure of the Enterprise{" "}
                  {formErrors.structure_of_enterprise && (
                    <span className="error">
                      {formErrors.structure_of_enterprise}
                    </span>
                  )}
                  <select
                    id="structure_of_enterprise"
                    name="structure_of_enterprise"
                    onChange={handleInputChange}
                    value={formData.structure_of_enterprise || ""}
                    displayempty="true"
                    inputprops={{ "aria-label": "Without label" }}
                  >
                    <option value="">Select an option</option>
                    <option value="Sole Proprietorship">
                      Sole Proprietorship
                    </option>
                    <option value="Private Limited">Private Limited</option>
                    <option value="Partnership Firm">Partnership Firm</option>
                    <option value="Not registered/Idea Stage">
                      Not registered/Idea Stage
                    </option>
                    <option value="Other">Other</option>
                  </select>
                  {/* <br /> */}
                  {formData.structure_of_enterprise === "Other" && (
                    <>
                      Please describe the other Structure of the Enterprise{" "}
                      {formErrors.structure_of_enterprise_other && (
                        <span className="error">
                          {formErrors.structure_of_enterprise_other}
                        </span>
                      )}
                      <input
                        type="text"
                        name="structure_of_enterprise_other"
                        value={formData.structure_of_enterprise_other || ""}
                        onChange={handleInputChange}
                        required
                      />
                      {/* <br /> */}
                    </>
                  )}
                  Date of Incorporation of the company{" "}
                  {formErrors.established_date && (
                    <span className="error">{formErrors.established_date}</span>
                  )}
                  <input
                    type="date"
                    defaultValue={date}
                    name="established_date"
                    value={formData.established_date || ""}
                    onChange={handleInputChange}
                  />
                  {/* <br /> */}
                  Registered Address{" "}
                  {formErrors.registered_address && (
                    <span className="error">
                      {formErrors.registered_address}
                    </span>
                  )}
                  <input
                    type="text"
                    name="registered_address"
                    value={formData.registered_address || ""}
                    onChange={handleInputChange}
                    required
                  />
                  {/* <br /> */}
                  {formData.structure_of_enterprise !==
                    "Not registered/Idea Stage" &&
                    formData.structure_of_enterprise !== "Other" &&
                    formData.structure_of_enterprise?.length > 0 && (
                      <>
                        Registration details of the company - Company
                        registration number and PAN/ VAT number
                        <br />
                        {formErrors.pan_or_vat && (
                          <span className="error">{formErrors.pan_or_vat}</span>
                        )}
                        <textarea
                          type="number"
                          name="pan_or_vat"
                          value={formData.pan_or_vat || ""}
                          onChange={handleInputChange}
                          required
                        />
                        {/* <br /> */}
                      </>
                    )}
                  {formData.structure_of_enterprise !==
                    "Not registered/Idea Stage" &&
                    formData.structure_of_enterprise?.length > 0 && (
                      <>
                        Please upload your company's charter documents - Company
                        registration certificate and PAN/VAT certificate
                        <input
                          name="charter_docs"
                          className="form-control"
                          type="file"
                          multiple
                          onChange={handleFileEvent}
                          disabled={fileLimit}
                        />
                        {panOrVatFiles.length > 0 &&
                          panOrVatFiles.map((file, index) => (
                            <img
                              key={index}
                              src={URL.createObjectURL(file)}
                              alt={`Uploaded File ${index + 1}`}
                              style={{
                                borderRadius: "0px",
                                height: "150px",
                                width: "150px",
                                margin: "5px",
                              }}
                            />
                          ))}
                      </>
                    )}
                  {panOrVatFiles.length > 0 && <br />}
                  Profile of a founder/ co-founder (either type in the profile
                  or provide a link to online profile) *
                  <br />
                  {formErrors.profile_of_founder_or_cofounder && (
                    <span className="error">
                      {formErrors.profile_of_founder_or_cofounder}
                    </span>
                  )}
                  <textarea
                    type="text"
                    name="profile_of_founder_or_cofounder"
                    value={formData.profile_of_founder_or_cofounder || ""}
                    onChange={handleInputChange}
                    required
                  />
                  {/* <br /> */}
                  No. of Employees{" "}
                  {formErrors.no_of_employees && (
                    <span className="error">{formErrors.no_of_employees}</span>
                  )}
                  <select
                    id="no_of_employees"
                    name="no_of_employees"
                    value={formData.no_of_employees || ""}
                    onChange={handleInputChange}
                    displayempty="true"
                    inputprops={{ "aria-label": "Without label" }}
                  >
                    <option value="">Select an option</option>
                    <option value="0-5">0-5</option>
                    <option value="5-10">5-10</option>
                    <option value="10-20">10-20</option>
                    <option value="20-50">20-50</option>
                    <option value="50-100">50-100</option>
                    <option value="101 or more">101 or more</option>
                  </select>
                  {/* <br /> */}
                  Title for your Deal (optional)
                  <input
                    type="text"
                    name="deal_title"
                    value={formData.deal_title || ""}
                    onChange={handleInputChange}
                  />
                  Deal Overview (optional)
                  <input
                    type="text"
                    name="deal_overview"
                    value={formData.deal_overview || ""}
                    onChange={handleInputChange}
                  />
                  Which primary sector is your company in?{" "}
                  {formErrors.sector && (
                    <span className="error">{formErrors.sector}</span>
                  )}
                  {/* <br /> */}
                  <select
                    id="sector"
                    name="sector"
                    value={formData.sector || ""}
                    onChange={handleInputChange}
                    displayempty="true"
                    inputprops={{ "aria-label": "Without label" }}
                  >
                    <option value="">Select your sector</option>
                    {categoryList?.length > 0 &&
                      categoryList?.map((option) => (
                        <option
                          key={option.deal_category_id}
                          value={option.deal_category_id}
                        >
                          {option.deal_cat_name}
                        </option>
                      ))}
                  </select>
                  {/* <br /> */}
                  Tell us more about your company/ idea (Include information on
                  your products, services, business model and value proposition){" "}
                  {formErrors.about_company && (
                    <span className="error">{formErrors.about_company}</span>
                  )}
                  <textarea
                    type="text"
                    name="about_company"
                    value={formData.about_company || ""}
                    onChange={handleInputChange}
                    required
                  />
                  {/* <br /> */}
                  Tell us more about your product/ service's target market
                  (Include information about your market research and what you
                  have found about the size of the market and competition)
                  <br />
                  {formErrors.target_product_description && (
                    <span className="error">
                      {formErrors.target_product_description}
                    </span>
                  )}
                  <textarea
                    type="text"
                    name="target_product_description"
                    value={formData.target_product_description || ""}
                    onChange={handleInputChange}
                    required
                  />
                  {/* <br /> */}
                  Where is your company headquarters located (if different from
                  the registered address)?
                  <br />
                  {formErrors.headquarters && (
                    <span className="error">{formErrors.headquarters}</span>
                  )}
                  <input
                    type="text"
                    name="headquarters"
                    value={formData.headquarters || ""}
                    required
                    onChange={handleInputChange}
                  />
                  {/* <br /> */}
                  Where does your company operate?{" "}
                  {formErrors.company_operate && (
                    <span className="error">{formErrors.company_operate}</span>
                  )}
                  <select
                    name="company_operate"
                    id="company_operate"
                    value={formData.company_operate || ""}
                    onChange={handleInputChange}
                    displayempty="true"
                    inputprops={{ "aria-label": "Without label" }}
                  >
                    <option value="">Select your Location</option>
                    {provinceList?.length > 0 &&
                      provinceList?.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.label}
                        </option>
                      ))}
                  </select>
                  {/* <br /> */}
                  <ToastContainer
                    position="top-right"
                    autoClose={2000}
                    theme="colored"
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyInformation;

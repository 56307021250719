export const GET_ALL_ADVISORS_REQUEST = "GET_ALL_ADVISORS_REQUEST";
export const GET_ALL_ADVISORS_SUCCESS = "GET_ALL_ADVISORS_SUCCESS";
export const GET_ALL_ADVISORS_FAIL = "GET_ALL_ADVISORS_FAIL";
export const GET_ALL_ADVISORS_RESET = "GET_ALL_ADVISORS_RESET";

export const GET_SINGLE_ADVISORS_REQUEST = "GET_SINGLE_ADVISORS_REQUEST";
export const GET_SINGLE_ADVISORS_SUCCESS = "GET_SINGLE_ADVISORS_SUCCESS";
export const GET_SINGLE_ADVISORS_FAIL = "GET_SINGLE_ADVISORS_FAIL";
export const GET_SINGLE_ADVISORS_RESET = "GET_SINGLE_ADVISORS_RESET";

export const POST_ADVISORS_REQUEST = "POST_ADVISORS_REQUEST";
export const POST_ADVISORS_SUCCESS = "POST_ADVISORS_SUCCESS";
export const POST_ADVISORS_FAIL = "POST_ADVISORS_FAIL";
export const POST_ADVISORS_RESET = "POST_ADVISORS_RESET";

export const PUT_ADVISORS_REQUEST = "PUT_ADVISORS_REQUEST";
export const PUT_ADVISORS_SUCCESS = "PUT_ADVISORS_SUCCESS";
export const PUT_ADVISORS_FAIL = "PUT_ADVISORS_FAIL";
export const PUT_ADVISORS_RESET = "PUT_ADVISORS_RESET";

export const DELETE_ADVISORS_REQUEST = "DELETE_ADVISORS_REQUEST";
export const DELETE_ADVISORS_SUCCESS = "DELETE_ADVISORS_SUCCESS";
export const DELETE_ADVISORS_FAIL = "DELETE_ADVISORS_FAIL";
export const DELETE_ADVISORS_RESET = "DELETE_ADVISORS_RESET";

import React, { useEffect, useState } from "react";
import { Grid, MenuItem, OutlinedInput, Select } from "@mui/material";
import { Form, useForm } from "../../components/hooks/useForm";
import { useDispatch, useSelector } from "react-redux";
import CheckBoxControl from "../../components/controls/CheckBoxControl";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import LoadingComp from "../../components/LoadingComp";
import { getAllPartnerAction } from "../about-us/partners/PartnersActions";
import { API_URL } from "../../api/axios";

const SuccessStoriesUpdateForm = ({
  singleSuccessStories,
  loadingEditForm,
  setOpenDetailPopup,
}) => {
  const [partnerName, setPartnerName] = useState([]);
  const dispatch = useDispatch();

  const { allPartner } = useSelector((state) => state.getAllPartner);

  useEffect(() => {
    if (!allPartner) {
      dispatch(getAllPartnerAction());
    }
    if (allPartner) {
      setPartnerName(allPartner?.data);
    }
  }, [dispatch, allPartner]);

  const initialFormValues = {
    partner_id: "",
    partner_company_name: "",
    partner_logo_image: "",
    ss_avatar_image: "",
    ss_message: "",
    ss_name: "",
    status: 0,
    ss_uid: "",
  };

  const { values, setValues } = useForm(initialFormValues);

  useEffect(() => {
    if (singleSuccessStories) {
      setValues({ ...singleSuccessStories });
    }
  }, [setValues, singleSuccessStories]);

  return (
    <Form>
      {loadingEditForm ? (
        <LoadingComp />
      ) : (
        <>
          <Grid container spacing={2} style={{ fontSize: "13px" }}>
            <Grid item xs={12}>
              <Select
                name="partner_company_name"
                label="Partner Name"
                value={values.partner_id}
                style={{ width: "100%" }}
              >
                <MenuItem value="Client Stories">Client Stories</MenuItem>
                {partnerName.map((option) => (
                  <MenuItem key={option.partner_id} value={option.partner_id}>
                    {option.company_name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            {values.partner_id === "Client Stories" && (
              <Grid item xs={12}>
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  Client/User Name:
                </span>
                <OutlinedInput
                  name="ss_name"
                  label="Client Name"
                  style={{ width: "100%", marginTop: "6px" }}
                  value={values.ss_name}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <CKEditor
                disabled
                editor={ClassicEditor}
                id="ss_message"
                name="ss_message"
                value={values.ss_message}
                onReady={(editor) => {
                  editor.setData(values.ss_message);
                }}
              />
            </Grid>
            {values.partner_id === "Client Stories" && (
              <Grid item xs={12}>
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  Client Avatar:
                </span>

                <br />
                {singleSuccessStories?.ss_avatar_image &&
                singleSuccessStories?.ss_avatar_image ? (
                  <img
                    alt=""
                    src={`${API_URL}/uploads/success_stories/${singleSuccessStories?.ss_avatar_image}`}
                    height={150}
                    width={150}
                  />
                ) : (
                  <span style={{ color: "red" }}>*No Image Found</span>
                )}
              </Grid>
            )}
            <Grid item xs={12}>
              <CheckBoxControl
                name="status"
                label="Active"
                value={values.status}
                onChange={() => ""}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Form>
  );
};

export default SuccessStoriesUpdateForm;

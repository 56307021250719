import React, { useEffect, useState } from "react";
import UserHeader from "../../components/UserHeader";
import { Helmet } from "react-helmet";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ArrowButton from "../../components/ArrowButton";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllEnterpriseFaqsPublicAction,
  postContactFormAction,
} from "../../public-routes/PublicRoutesActions";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import logo1 from "../../front-end-images/1.png";
import UserFooter from "../../components/UserFooter";

const ForEnterprise = () => {
  const [faqsEnterprise, setFaqsEnterprise] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const [orgName, setOrgName] = useState("");
  const [contact, setContact] = useState();
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});

  const dispatch = useDispatch();

  const { allEnterpriseFaqsPublic } = useSelector(
    (state) => state.getAllEnterpriseFaqsPublic
  );

  const initialFormValues = {
    full_name: orgName,
    phone: contact,
    email: email,
    message: message,
  };

  const filteredEnterprise = faqsEnterprise.filter((item) => item.status === 1);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!allEnterpriseFaqsPublic) {
      dispatch(getAllEnterpriseFaqsPublicAction());
    } else {
      setFaqsEnterprise(allEnterpriseFaqsPublic?.data);
    }
  }, [dispatch, allEnterpriseFaqsPublic]);

  useEffect(() => {
    dispatch(getAllEnterpriseFaqsPublicAction());
  }, [dispatch]);

  const handleContactFormSubmit = (e) => {
    e.preventDefault();
    let errors = {};
    if (!orgName) {
      errors["orgName"] = "Bussiness/Your name is required";
    }
    if (!contact) {
      errors["contact"] = "Contact number is required";
    }
    if (!email) {
      errors["email"] = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors["email"] = "Email is invalid";
    }
    if (!message) {
      errors["message"] = "Message is required";
    }

    setErrors(errors);

    if (Object.keys(errors).length === 0) {
      dispatch(postContactFormAction(initialFormValues)).then(() => {
        toast.success("Your Feedback has been Submitted Successfully.");
      });
      setOrgName("");
      setContact("");
      setEmail("");
      setMessage("");
    }
  };
  return (
    <>
      <UserHeader />
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="description" content="" />
        <meta name="author" content="" />

        <title>Million Deals</title>
        {/* <!-- Linking the font Poppins--> */}
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />

        {/* <!-- Linking Bootstrap Icon--> */}
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css"
        />
      </Helmet>
      <ArrowButton />
      <div className="page-title">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h2>All About Enterprise</h2>
              <Link className="link" to="/">
                Home
              </Link>
              {""} / For Enterprise
            </div>
          </div>
        </div>
      </div>

      <div className="slider">
        <div className="container">
          <div className="row position-relative">
            <div className="col-lg-6 col-md-12">
              <h2 className="section-title">Our offer to Enterprises</h2>
              <p
                className="section-text-for_enterprise white-text"
                style={{ textAlign: "justify" }}
              >
                We offer entrepreneurs a platform to showcase their business
                ideas to a wide range of investors, alongside continual support
                and business consulting services from our highly experienced
                team of business analysts and consultants. By joining us you can
                have access to a wide range of resources and support to help
                your business grow and thrive. Our team of experienced business
                consultants will offer you customized solutions for financial
                management, market analysis, and strategy development, etc. to
                help you navigate the challenges of growing a business. On our
                platform, you can create your deals, which are then thoroughly
                verified by our due diligence team. You will also have access to
                a pool of verified investors who are committed to supporting the
                growth and development of Nepali businesses. By joining us, you
                can gain access to a diverse pool of investors and experts,
                increasing your chances of securing funding and securing your
                business goals. You can be confident in the knowledge that you
                are part of a community that is committed to your success.
              </p>
              <br />
            </div>
            <div className="col-lg-6 col-md-8">
              <img alt="" src={logo1} />
            </div>
          </div>
        </div>
      </div>

      <div className="process">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-5">
              <h3 className="section-subtitle">Our Process</h3>
              <h2 className="section-title">How We Work & Connect</h2>
            </div>
            <TableContainer sx={{ maxHeight: "100%" }}>
              <Table
                stickyHeader
                aria-label="sticky table"
                className="table-style "
              >
                <TableHead style={{ height: "50px" }}>
                  <TableRow
                    sx={{
                      "& :hover": {
                        color: "white !important",
                      },
                      "& :focus:not(:focus-visible)": {
                        color: "white !important",
                      },
                    }}
                  >
                    <TableCell
                      style={{
                        backgroundColor: "#0b6885",
                        padding: "0.5vw",
                        letterSpacing: "0.5px",
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "white",
                      }}
                    >
                      Register on our Platform
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "#0b6885",
                        padding: "0.5vw",
                        letterSpacing: "0.5px",
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "white",
                      }}
                    >
                      Create your Deal
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "#0b6885",
                        padding: "0.5vw",
                        letterSpacing: "0.5px",
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "white",
                      }}
                    >
                      Get Verified
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "#0b6885",
                        padding: "0.5vw",
                        letterSpacing: "0.5px",
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "white",
                      }}
                    >
                      Engage with our Due Diligence Team
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "#0b6885",
                        padding: "0.5vw",
                        letterSpacing: "0.5px",
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "white",
                      }}
                    >
                      Get Listed and Ready to meet Investors
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "#0b6885",
                        padding: "0.5vw",
                        letterSpacing: "0.5px",
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "white",
                      }}
                    >
                      Access continual support from our team
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow style={{ backgroundColor: "#FFF" }}>
                    <TableCell>
                      Create an account on our platform, using your email, to
                      access a wealth of resources, support, and potential
                      investors. Upon registration, you will be directed to
                      create a deal
                    </TableCell>

                    <TableCell>
                      Once registered, create your deal by answering our
                      questionnaire to let us know more about your business,
                      team, and investment opportunity.
                    </TableCell>

                    <TableCell>
                      Our team will review your answers and conduct initial
                      checks to verify your company and to ensure your deal
                      meets our standards and is suitable for our network of
                      investors, before being listed on our platform.
                    </TableCell>

                    <TableCell>
                      Once your deal is verified we will contact you to discuss
                      specific details and to ensure that all aspects of your
                      business are accurately represented, and that potential
                      investors have all the information they need to make
                      informed investment decisions. Our Due Diligence team will
                      prepare a Due Diligence report together with you
                    </TableCell>

                    <TableCell>
                      Once your deal is verified and our Due Diligence team has
                      undergone initial engagement with you, your deal will be
                      listed on our platform, making it visible to our network
                      of investors. As investors show interest our team will
                      engage with you to support you in the deal making process.
                    </TableCell>

                    <TableCell>
                      Our team will be there to support you throughout the
                      investment process as well as to provide you additional
                      business development support and resources. Once you and
                      an investor decide to engage in exclusive deal discussion,
                      we will freeze your listing from our platform until a
                      final decision is made. If you and the investor decide to
                      engage in deal discussions without the engagement of our
                      team, we will ensure you have access to all relevant
                      information.
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>

      <div className="home-faq ">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="row">
                <div className="col-lg-6 col-md-5">
                  <h3 className="section-subtitle">
                    Frequently Asked Questions
                  </h3>
                  <h2 className="section-title">Clear All Doubts</h2>(If you
                  have any questions not answered in the FAQs, please reach out
                  to us at{" "}
                  <Link
                    style={{ textDecoration: "none" }}
                    onClick={() =>
                      (window.location = "mailto:info@milliondeals.com.np")
                    }
                  >
                    info@milliondeals.com.np
                  </Link>
                  )
                </div>
              </div>
              {filteredEnterprise.length > 0 ? (
                filteredEnterprise.map((item, index) => (
                  <div className="row pt-2">
                    <div className="accordin" id={`accordionExample${index}`}>
                      <div className="card card-responsive">
                        <Accordion TransitionProps={{ unmountOnExit: true }}>
                          <AccordionSummary
                            style={{
                              backgroundColor: "#0b6885",
                              color: "#ffffff",
                            }}
                            expandIcon={
                              <AddIcon
                                style={{
                                  color: "#ffffff",
                                }}
                              />
                            }
                            onClick={() => {
                              setSelectedIndex(index);
                            }}
                          >
                            <Typography
                              className="mb-0"
                              variant="h5"
                              fontSize={18}
                              letterSpacing={1}
                            >
                              {item.question}
                            </Typography>
                          </AccordionSummary>
                          <div
                            id={`collapse${index}`}
                            className={
                              selectedIndex === index
                                ? "collapse show"
                                : "collapse"
                            }
                            aria-labelledby={`heading${index}`}
                            data-parent={`#accordionExample${index}`}
                          >
                            <AccordionDetails>
                              <Typography>
                                {" "}
                                <p
                                  className="section-text"
                                  style={{ textAlign: "justify" }}
                                  dangerouslySetInnerHTML={{
                                    __html: item?.text,
                                  }}
                                ></p>
                              </Typography>
                            </AccordionDetails>
                          </div>
                        </Accordion>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <span className="error">*No FAQs Found</span>
              )}
            </div>

            <div className="col-lg-4 mt-3">
              <div className="short-form">
                <h3>Have Us Contact You</h3>
                <p>Fill up the form and have us contact you</p>

                <form onSubmit={handleContactFormSubmit}>
                  <input
                    type="text"
                    id="orgName"
                    value={orgName}
                    className={`form-control ${
                      errors["orgName"] ? "is-invalid" : ""
                    }`}
                    placeholder="Your Business / Your Name"
                    onChange={(e) => setOrgName(e.target.value)}
                  />
                  {errors["orgName"] && (
                    <div className="invalid-feedback">{errors["orgName"]}</div>
                  )}

                  <input
                    type="number"
                    id="contact"
                    value={contact}
                    className={`form-control ${
                      errors["contact"] ? "is-invalid" : ""
                    }`}
                    placeholder="Phone Number"
                    onChange={(e) => setContact(e.target.value)}
                  />
                  {errors["contact"] && (
                    <div className="invalid-feedback">{errors["contact"]}</div>
                  )}

                  <input
                    type="email"
                    placeholder="Email Address"
                    className={`form-control ${
                      errors["email"] ? "is-invalid" : ""
                    }`}
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {errors["email"] && (
                    <div className="invalid-feedback">{errors["email"]}</div>
                  )}
                  <textarea
                    className={`form-control ${
                      errors["message"] ? "is-invalid" : ""
                    }`}
                    id="message"
                    value={message}
                    placeholder="Your Message or Concern"
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                  {errors["message"] && (
                    <div className="invalid-feedback">{errors["message"]}</div>
                  )}
                  <Button
                    type="submit"
                    style={{
                      backgroundColor: "#0b6885",
                      width: "92%",
                    }}
                    className="send-button"
                    variant="contained"
                  >
                    Send
                  </Button>
                  <ToastContainer
                    position="top-right"
                    autoClose={2000}
                    theme="colored"
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <UserFooter />
    </>
  );
};

export default ForEnterprise;

import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LoadingComp from "../../components/LoadingComp";
import ArrowButton from "../components/ArrowButton";
import { getMemberAccessToken } from "../../services/storage";
import jwtDecode from "jwt-decode";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VerifiedIcon from "@mui/icons-material/Verified";
import PendingIcon from "@mui/icons-material/Pending";
import {
  getMemberProfileSideAction,
  getSingleMemberProfileSideAction,
  getEnterpriseBusinessPlanDocAction,
  getEnterpriseRegistrationCompDocAction,
  getEnterprisePanVatDocAction,
  getEnterpriseMobilizationPlanDocAction,
  getEnterpriseRevenueProfitabilityDocAction,
} from "../kyc-state-handler/KycActions";
import DashboardSideMenu from "../components/DashboardSideMenu";
import { Tooltip } from "@mui/material";

const MemberDashboard = () => {
  const [values, setValues] = useState([]);
  const [prevMemberProfileSide, setPrevMemberProfileSide] = useState(null);

  const memberAccessToken = getMemberAccessToken();

  const decodedToken = memberAccessToken && jwtDecode(memberAccessToken);

  const payload = decodedToken;

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { memberProfileSide, loading } = useSelector(
    (state) => state.getMemberProfileside
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (memberProfileSide && memberProfileSide !== prevMemberProfileSide) {
      setValues(memberProfileSide?.data);
    }
  }, [memberProfileSide, prevMemberProfileSide]);

  useEffect(() => {
    setPrevMemberProfileSide(memberProfileSide);
  }, [memberProfileSide]);

  useEffect(() => {
    dispatch(getMemberProfileSideAction());
  }, [dispatch]);

  const verifiedKyc = values?.reduce(
    (acc, cv) => ({ ...acc, [cv.profile_type]: cv }),
    {}
  );

  const EnterpriseDetailKycView = (memberProfileId) => {
    if (
      dispatch(getSingleMemberProfileSideAction(memberProfileId)).then(
        (res) => {
          localStorage.setItem("memberEnterpriseKYC", JSON.stringify(res.data));

          dispatch(
            getEnterpriseBusinessPlanDocAction(
              res?.data?.enterprise_kyc?.enterprise_kyc_id
            )
          ).then((businessDoc) => {
            localStorage.setItem(
              "documentsFiles",
              JSON.stringify(businessDoc?.data)
            );
          });
          dispatch(
            getEnterpriseRegistrationCompDocAction(
              res?.data?.enterprise_kyc?.enterprise_kyc_id
            )
          ).then((registrationCompDoc) => {
            localStorage.setItem(
              "documentsRegistrationFiles",
              JSON.stringify(registrationCompDoc?.data)
            );
          });
          dispatch(
            getEnterprisePanVatDocAction(
              res?.data?.enterprise_kyc?.enterprise_kyc_id
            )
          ).then((panOrVat) => {
            localStorage.setItem("charterDocs", JSON.stringify(panOrVat?.data));
          });
          dispatch(
            getEnterpriseMobilizationPlanDocAction(
              res?.data?.enterprise_kyc?.enterprise_kyc_id
            )
          ).then((mobilizationDoc) => {
            localStorage.setItem(
              "mobilizationDocs",
              JSON.stringify(mobilizationDoc?.data)
            );
          });
          dispatch(
            getEnterpriseRevenueProfitabilityDocAction(
              res?.data?.enterprise_kyc?.enterprise_kyc_id
            )
          ).then((revenueProfitability) => {
            localStorage.setItem(
              "revenueDocs",
              JSON.stringify(revenueProfitability?.data)
            );
          });
        }
      )
    ) {
      setTimeout(() => {
        navigate("/dashboard/enterprise_detail_kyc");
      }, 1000);
    }
  };

  const InvestorDetailKycView = (memberProfileId) => {
    if (
      dispatch(getSingleMemberProfileSideAction(memberProfileId)).then(
        (res) => {
          localStorage.setItem("memberInvestorKYC", JSON.stringify(res.data));
        }
      )
    ) {
      setTimeout(() => {
        navigate("/dashboard/investor_detail_kyc");
      }, 1000);
    }
  };

  const handleButtonClick = () => {
    navigate("/enterprise_kyc");
  };

  const handleInvestorButtonClick = () => {
    navigate("/investor_kyc");
  };

  return (
    <>
      <div className="page-title">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h1>Dashboard</h1>
              <Link className="link" to="/">
                Home
              </Link>{" "}
              {""} / Dashboard
            </div>
          </div>
        </div>
      </div>
      <ArrowButton />

      <div className="container pt-5 pb-5 mb-5">
        <div className="row">
          <div className="col-lg-3 col-md-4">
            <DashboardSideMenu />
          </div>

          {loading ? (
            <LoadingComp />
          ) : (
            <>
              {" "}
              {values.length === 0 && (
                <div className="col-lg-9 col-md-8 pt-5">
                  <div className="dash-welcome">
                    <h3>
                      Welcome,{" "}
                      {payload?.username ? (
                        payload?.username
                      ) : (
                        <>
                          {payload?.email?.split(
                            "@gmail.com" ||
                              "@yahoo.com" ||
                              "@hotmail.com" ||
                              "@outlook.com"
                          )}
                        </>
                      )}
                    </h3>
                    <p>
                      We are almost done, please select your purpose to fill up
                      the form to continue.
                    </p>
                    <button
                      className="blue-btn mb-1"
                      onClick={handleButtonClick}
                    >
                      Continue as Enterprise
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button
                      className="blue-btn"
                      onClick={handleInvestorButtonClick}
                    >
                      Continue as Investor
                    </button>
                  </div>
                </div>
              )}
              <div className="col-lg-9 col-md-8">
                <div className="row">
                  {verifiedKyc["Enterprise"] &&
                    values?.map((option) => (
                      <>
                        <div className="col-lg-6 col-md-9">
                          <div className="dash-forms">
                            <div className="dash-form-single">
                              {option.company_name ? (
                                <Tooltip title={option.company_name}>
                                  <h6>
                                    {" "}
                                    {option.company_name.length > 25
                                      ? option.company_name.slice(0, 25) + "..."
                                      : option.company_name}
                                  </h6>
                                </Tooltip>
                              ) : (
                                <h4>Enterprise {option.member_profile_id}</h4>
                              )}
                              <br />
                              {option.profile_type === "Enterprise" &&
                                option.is_verified === 0 && (
                                  <>
                                    <div className="d-flex align-items-center">
                                      <p
                                        style={{
                                          color: "#0b6885",
                                          marginLeft: "3%",
                                        }}
                                      >
                                        Pending
                                        <PendingIcon
                                          style={{
                                            fontSize: "18px",
                                            color: "#0b6885",
                                            marginLeft: "5px",
                                          }}
                                        />
                                      </p>
                                      <p
                                        style={{
                                          color: "#0b6885",
                                          marginLeft: "50%",
                                        }}
                                      >
                                        <VisibilityIcon
                                          onClick={() =>
                                            EnterpriseDetailKycView(
                                              option.member_profile_id
                                            )
                                          }
                                          style={{
                                            fontSize: "20px",
                                            color: "#0b6885",
                                            cursor: "pointer",
                                          }}
                                        />
                                      </p>
                                    </div>
                                  </>
                                )}{" "}
                              {option.profile_type === "Enterprise" &&
                                option.is_verified === 1 && (
                                  <>
                                    <div className="d-flex align-items-center">
                                      <p
                                        style={{
                                          color: "#0b6885",
                                          marginLeft: "3%",
                                        }}
                                      >
                                        Verified
                                        <VerifiedIcon
                                          style={{
                                            fontSize: "18px",
                                            color: "#0b6885",
                                            marginLeft: "5px",
                                          }}
                                        />
                                      </p>
                                      <p
                                        style={{
                                          color: "#0b6885",
                                          marginLeft: "50%",
                                        }}
                                      >
                                        <VisibilityIcon
                                          onClick={() =>
                                            EnterpriseDetailKycView(
                                              option.member_profile_id
                                            )
                                          }
                                          style={{
                                            fontSize: "20px",
                                            color: "#0b6885",
                                            cursor: "pointer",
                                          }}
                                        />
                                      </p>
                                    </div>
                                  </>
                                )}
                              {option.profile_type === "Enterprise" &&
                                option.is_verified === 2 && (
                                  <>
                                    <div className="d-flex align-items-center">
                                      <p
                                        style={{
                                          color: "#0b6885",
                                          marginLeft: "3%",
                                        }}
                                      >
                                        Rejected
                                        <PendingIcon
                                          style={{
                                            fontSize: "18px",
                                            color: "#0b6885",
                                            marginLeft: "5px",
                                          }}
                                        />
                                      </p>
                                      <p
                                        style={{
                                          color: "#0b6885",
                                          marginLeft: "50%",
                                        }}
                                      >
                                        <Link
                                          to="/enterprise_kyc"
                                          title="Fill KYC"
                                          style={{
                                            color: "#0b6885",
                                          }}
                                        >
                                          Fill KYC
                                        </Link>
                                      </p>
                                    </div>
                                  </>
                                )}
                            </div>
                          </div>
                        </div>
                      </>
                    ))}

                  {verifiedKyc["Investor"] &&
                    values?.map((option) => (
                      <>
                        <div className="col-lg-6 col-md-9">
                          <div className="dash-forms">
                            <div className="dash-form-single">
                              <h4>Investor KYC</h4>
                              <br />
                              {verifiedKyc["Investor"] &&
                                verifiedKyc["Investor"].is_verified === 1 && (
                                  <>
                                    <div className="d-flex align-items-center">
                                      <p
                                        style={{
                                          color: "#0b6885",
                                          marginLeft: "3%",
                                        }}
                                      >
                                        Verified
                                        <VerifiedIcon
                                          style={{
                                            fontSize: "18px",
                                            color: "#0b6885",
                                            marginLeft: "5px",
                                          }}
                                        />
                                      </p>
                                      <p
                                        style={{
                                          color: "#0b6885",
                                          marginLeft: "50%",
                                        }}
                                      >
                                        <VisibilityIcon
                                          onClick={() =>
                                            InvestorDetailKycView(
                                              option.member_profile_id
                                            )
                                          }
                                          style={{
                                            fontSize: "20px",
                                            color: "#0b6885",
                                            cursor: "pointer",
                                            marginLeft: "50%",
                                          }}
                                        />
                                      </p>
                                    </div>
                                  </>
                                )}
                              {verifiedKyc["Investor"] &&
                                verifiedKyc["Investor"].is_verified === 0 && (
                                  <>
                                    <div className="d-flex align-items-center">
                                      <p
                                        style={{
                                          color: "#0b6885",
                                          marginLeft: "3%",
                                        }}
                                      >
                                        Pending
                                        <PendingIcon
                                          style={{
                                            fontSize: "18px",
                                            color: "#0b6885",
                                            marginLeft: "5px",
                                          }}
                                        />
                                      </p>
                                      <p
                                        style={{
                                          color: "#0b6885",
                                          marginLeft: "50%",
                                        }}
                                      >
                                        <VisibilityIcon
                                          onClick={() =>
                                            InvestorDetailKycView(
                                              option.member_profile_id
                                            )
                                          }
                                          style={{
                                            fontSize: "20px",
                                            color: "#0b6885",

                                            cursor: "pointer",
                                          }}
                                        />
                                      </p>
                                    </div>
                                  </>
                                )}{" "}
                              {verifiedKyc["Investor"] &&
                                verifiedKyc["Investor"].is_verified === 2 && (
                                  <>
                                    <div className="d-flex align-items-center">
                                      <p
                                        style={{
                                          color: "#0b6885",
                                          marginLeft: "3%",
                                        }}
                                      >
                                        Rejected
                                        <PendingIcon
                                          style={{
                                            fontSize: "18px",
                                            color: "#0b6885",
                                            marginLeft: "5px",
                                          }}
                                        />
                                      </p>
                                      <p
                                        style={{
                                          color: "#0b6885",
                                          marginLeft: "50%",
                                        }}
                                      >
                                        <Link
                                          to="/investor_kyc"
                                          title="Fill KYC"
                                          style={{
                                            color: "#0b6885",
                                          }}
                                        >
                                          Fill KYC
                                        </Link>
                                      </p>
                                    </div>
                                  </>
                                )}
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default MemberDashboard;

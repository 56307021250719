import React, { useEffect, useState } from "react";
import { Button, TableRow, TableCell, Grid } from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import Moment from "react-moment";

const QuestionarieTableCollapse = ({
  item,
  updateQuestionarieHandler,
  deleteQuestionarieHandler,
}) => {
  const [options, setOptions] = useState({});

  useEffect(() => {
    if (item.answer_type === "Objective") {
      setOptions(JSON.parse(item.objective_answer));
    }
  }, [item.answer_type, item.objective_answer]);

  return (
    <TableRow>
      <TableCell sx={{ fontSize: "13px" }}>{item.question}</TableCell>
      <TableCell sx={{ fontSize: "13px" }}>{item.answer_type}</TableCell>
      {item.answer_type === "Objective" ? (
        <>
          <TableCell>
            <Grid container style={{ fontSize: "13px" }}>
              <Grid item xs={6}>
                A:&nbsp;{options["A"]?.slice(0, 30)}
              </Grid>
              <Grid item xs={6}>
                B:&nbsp;{options["B"]?.slice(0, 30)}
              </Grid>
              <Grid item xs={6}>
                C:&nbsp;{options["C"]?.slice(0, 30)}
              </Grid>
              <Grid item xs={6}>
                D:&nbsp;{options["D"]?.slice(0, 30)}
              </Grid>
            </Grid>
          </TableCell>
        </>
      ) : (
        <TableCell sx={{ fontSize: "13px" }}></TableCell>
      )}
      <TableCell sx={{ fontSize: "13px" }}>
        <Moment format="YYYY/MM/DD">{item.created_at.slice(0, 10)}</Moment>
        <br />(<Moment fromNow>{item.created_at}</Moment>)
      </TableCell>
      <TableCell>
        <Button
          style={{
            marginRight: "3px",
            padding: "5px",
            minWidth: "10px",
            fontSize: "13px",
            backgroundColor: "#2a80f9",
          }}
          variant="contained"
          onClick={() => updateQuestionarieHandler(item.unique_id)}
        >
          <Edit style={{ fontSize: 13 }} />
        </Button>
        <Button
          style={{
            marginRight: "1px",
            padding: "5px",
            minWidth: "10px",
            fontSize: "13px",
            backgroundColor: "#ea3f4d",
          }}
          variant="contained"
          onClick={() => deleteQuestionarieHandler(item.unique_id)}
        >
          <Delete style={{ fontSize: 13 }} />
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default QuestionarieTableCollapse;

export const GET_ALL_PAGES_REQUEST = "GET_ALL_PAGES_REQUEST";
export const GET_ALL_PAGES_SUCCESS = "GET_ALL_PAGES_SUCCESS";
export const GET_ALL_PAGES_FAIL = "GET_ALL_PAGES_FAIL";
export const GET_ALL_PAGES_RESET = "GET_ALL_PAGES_RESET";

export const GET_SINGLE_PAGES_REQUEST = "GET_SINGLE_PAGES_REQUEST";
export const GET_SINGLE_PAGES_SUCCESS = "GET_SINGLE_PAGES_SUCCESS";
export const GET_SINGLE_PAGES_FAIL = "GET_SINGLE_PAGES_FAIL";
export const GET_SINGLE_PAGES_RESET = "GET_SINGLE_PAGES_RESET";

export const POST_PAGES_REQUEST = "POST_PAGES_REQUEST";
export const POST_PAGES_SUCCESS = "POST_PAGES_SUCCESS";
export const POST_PAGES_FAIL = "POST_PAGES_FAIL";
export const POST_PAGES_RESET = "POST_PAGES_RESET";

export const PUT_PAGES_REQUEST = "PUT_PAGES_REQUEST";
export const PUT_PAGES_SUCCESS = "PUT_PAGES_SUCCESS";
export const PUT_PAGES_FAIL = "PUT_PAGES_FAIL";
export const PUT_PAGES_RESET = "PUT_PAGES_RESET";

export const DELETE_PAGES_REQUEST = "DELETE_PAGES_REQUEST";
export const DELETE_PAGES_SUCCESS = "DELETE_PAGES_SUCCESS";
export const DELETE_PAGES_FAIL = "DELETE_PAGES_FAIL";
export const DELETE_PAGES_RESET = "DELETE_PAGES_RESET";

import {
  API_URL,
  axiosAdminPrivate,
  tokenConfig,
  tokenConfigForm,
} from "../../api/axios";
import {
  DELETE_NEWS_FAIL,
  DELETE_NEWS_REQUEST,
  DELETE_NEWS_SUCCESS,
  GET_ALL_NEWS_FAIL,
  GET_ALL_NEWS_REQUEST,
  GET_ALL_NEWS_SUCCESS,
  GET_SINGLE_NEWS_FAIL,
  GET_SINGLE_NEWS_REQUEST,
  GET_SINGLE_NEWS_SUCCESS,
  POST_NEWS_FAIL,
  POST_NEWS_REQUEST,
  POST_NEWS_SUCCESS,
  PUT_NEWS_FAIL,
  PUT_NEWS_REQUEST,
  PUT_NEWS_SUCCESS,
} from "./NewsConstants";

export const getAllNewsAction = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_NEWS_REQUEST });

    const { data } = await axiosAdminPrivate.get(
      `${API_URL}/api/v1/admin/news`
    );

    dispatch({ type: GET_ALL_NEWS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_ALL_NEWS_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

export const getSingleNewsAction = (unique_id) => async (dispatch) => {
  try {
    dispatch({ type: GET_SINGLE_NEWS_REQUEST });

    const { data } = await axiosAdminPrivate.get(
      `${API_URL}/api/v1/admin/news/${unique_id}`
    );

    dispatch({ type: GET_SINGLE_NEWS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_SINGLE_NEWS_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

export const postNewsAction = (addNews, image) => async (dispatch) => {
  try {
    dispatch({ type: POST_NEWS_REQUEST });

    const formData = new FormData();
    formData.append("news_image", image);
    formData.append("news_title", addNews?.news_title);
    formData.append("news_slug", addNews?.news_slug);
    formData.append("news_description", addNews?.news_description);
    formData.append("status", addNews?.status);

    const { data } = await axiosAdminPrivate.post(
      `${API_URL}/api/v1/admin/news`,
      formData
    );

    dispatch({ type: POST_NEWS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: POST_NEWS_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

export const putNewsAction =
  (updateNews, image, unique_id) => async (dispatch) => {
    try {
      dispatch({ type: PUT_NEWS_REQUEST });

      const formData = new FormData();
      formData.append("news_image", image);
      formData.append("news_title", updateNews?.news_title);
      formData.append("news_slug", updateNews?.news_slug);
      formData.append("news_description", updateNews?.news_description);
      formData.append("status", updateNews?.status);

      const { data } = await axiosAdminPrivate.put(
        `${API_URL}/api/v1/admin/news/${unique_id}`,
        formData
      );

      dispatch({ type: PUT_NEWS_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: PUT_NEWS_FAIL,
        payload: error.message ? error.message : error.Message,
      });
    }
  };

export const deleteNewsAction = (unique_id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_NEWS_REQUEST });

    const { data } = await axiosAdminPrivate.delete(
      `${API_URL}/api/v1/admin/news/${unique_id}`,
      tokenConfig()
    );

    dispatch({ type: DELETE_NEWS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: DELETE_NEWS_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import LoadingComp from "../../../components/LoadingComp";
import ArrowButton from "../../components/ArrowButton";
import DashboardSideMenu from "../../components/DashboardSideMenu";
import { getShowInterestedDealsAction } from "../../private-routes/PrivateActions";
import {
  getAllDealCategoryPublicAction,
  getSingleDealsPublicAction,
} from "../../public-routes/PublicRoutesActions";
import { Tooltip } from "@mui/material";

const provinceList = [
  {
    id: 1,
    label: "Province 1",
  },
  {
    id: 2,
    label: "Madhesh Pradesh",
  },
  {
    id: 3,
    label: "Bagmati Pradesh",
  },
  { id: 4, label: "Gandaki Pradesh" },
  {
    id: 5,
    label: "Lumbini Pradesh",
  },
  {
    id: 6,
    label: "Karnali Pradesh",
  },
  { id: 7, label: "Sudurpaschim Pradesh" },
];

const MemberDealsShowInterest = () => {
  const [category, setCategory] = useState([]);
  const [interestedDeals, setInterestedDeals] = useState([]);
  const [singleInterestDeals, setSingleInterestDeals] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(9);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { showInterestedDeals, loading } = useSelector(
    (state) => state.getShowInterestedDeals
  );

  const { allDealsCategoryPublic } = useSelector(
    (state) => state.getAllDealCategoryPublic
  );

  useEffect(() => {
    if (!showInterestedDeals) {
      dispatch(getShowInterestedDealsAction());
    }
    if (showInterestedDeals) {
      setInterestedDeals(showInterestedDeals?.data);
    }
  }, [dispatch, showInterestedDeals]);

  useEffect(() => {
    if (!allDealsCategoryPublic) {
      dispatch(getAllDealCategoryPublicAction());
    } else {
      setCategory(allDealsCategoryPublic?.data);
    }
  }, [dispatch, allDealsCategoryPublic]);

  useEffect(() => {
    dispatch(getShowInterestedDealsAction());
    dispatch(getAllDealCategoryPublicAction());
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  // Get current posts
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentInterestedDeals = interestedDeals.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  // Change page
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(interestedDeals.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const singleInterestDealsView = (id) => {
    dispatch(getSingleDealsPublicAction(id)).then((res) => {
      setSingleInterestDeals(res.data);
      localStorage.setItem(
        "singleIntrestedDealsClient",
        JSON.stringify(res.data)
      );
      navigate("/dashboard/interested-deals/single-interest", {
        state: { singleInterestDeals: res.data },
      });
    });
  };

  console.log("singleInterestDeals", singleInterestDeals);

  return (
    <>
      <div className="page-title">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h1>Dashboard</h1>
              <Link className="link" to="/">
                Home
              </Link>{" "}
              {""} /{""}
              <Link className="link" to="/dashboard">
                {" "}
                Dashboard
              </Link>{" "}
              {""} / Interested Deals
            </div>
          </div>
        </div>
      </div>
      <ArrowButton />
      <div className="container pt-5 pb-5 mb-5">
        <div className="row">
          <div className="col-md-3">
            <DashboardSideMenu />
          </div>
          <div className="col-md-9">
            <h3>Interested Deals</h3>

            {loading ? (
              <LoadingComp />
            ) : (
              <>
                <div className="row">
                  {currentInterestedDeals.map((option) => {
                    const categoryName = category.find(
                      (value) => value.deal_category_id === option.deal_cat_id
                    )?.deal_cat_name;
                    return (
                      <>
                        {categoryName?.length > 0 && (
                          <div className="col-md-4" key={option.unique_id}>
                            <div className="deal-single">
                              <Tooltip title={option.deal_title}>
                                <h2 style={{ cursor: "pointer" }}>
                                  {option.deal_title.length > 20
                                    ? option.deal_title?.slice(0, 20) + "..."
                                    : option.deal_title}
                                </h2>
                              </Tooltip>
                              <font className="blue-color">
                                <i className="bi bi-geo-alt-fill"></i>
                              </font>{" "}
                              {
                                provinceList.find(
                                  (province) =>
                                    province.id === option?.province_id
                                )?.label
                              }
                              <br />
                              <font className="blue-color">
                                <i className="bi bi-stack"></i>
                              </font>{" "}
                              {categoryName}
                              <h3>Investment Range</h3>
                              {option.amount}
                              <br />
                              <br />
                              <button
                                // disabled={!dataInvestorArray}
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  singleInterestDealsView(option.deal_id)
                                }
                                className="dark-btn link"
                              >
                                More Information
                              </button>
                            </div>
                          </div>
                        )}
                      </>
                    );
                  })}
                  {currentInterestedDeals.some((option) => {
                    const categoryName = category.find(
                      (value) => value.deal_category_id === option.deal_cat_id
                    )?.deal_cat_name;
                    return categoryName?.length > 0;
                  }) && interestedDeals?.length > 0 ? (
                    <div className="pagination-container">
                      <div className="row pt-5 mt-5">
                        <div className="col-md-12 justify-content-center">
                          <nav aria-label="Page navigation example">
                            <ul className="pagination justify-content-center">
                              <li
                                className={`page-item ${
                                  currentPage === 1 ? "disabled" : ""
                                }`}
                              >
                                <Link
                                  className="page-link"
                                  tabIndex="-1"
                                  onClick={() =>
                                    setCurrentPage(currentPage - 1)
                                  }
                                >
                                  {"<<"} Previous
                                </Link>
                              </li>
                              {pageNumbers.map((pageNumber) => (
                                <li
                                  key={pageNumber}
                                  className={`page-item ${
                                    currentPage === pageNumber ? "active" : ""
                                  }`}
                                >
                                  <Link
                                    className="page-link"
                                    onClick={() => setCurrentPage(pageNumber)}
                                  >
                                    {pageNumber}
                                  </Link>
                                </li>
                              ))}
                              <li
                                className={`page-item ${
                                  currentPage === pageNumbers.length
                                    ? "disabled"
                                    : ""
                                }`}
                              >
                                <Link
                                  className="page-link"
                                  onClick={() =>
                                    currentPage !== pageNumbers.length
                                      ? setCurrentPage(currentPage + 1)
                                      : ""
                                  }
                                >
                                  Next {">>"}
                                </Link>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="col-md-12 justify-content-center">
                      <p style={{ color: "#f94848" }}>*No Record Found</p>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MemberDealsShowInterest;

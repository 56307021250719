import {
  GET_ABOUT_COMPANY_PUBLIC_FAIL,
  GET_ABOUT_COMPANY_PUBLIC_REQUEST,
  GET_ABOUT_COMPANY_PUBLIC_RESET,
  GET_ABOUT_COMPANY_PUBLIC_SUCCESS,
  GET_ADVISORS_PUBLIC_FAIL,
  GET_ADVISORS_PUBLIC_REQUEST,
  GET_ADVISORS_PUBLIC_RESET,
  GET_ADVISORS_PUBLIC_SUCCESS,
  GET_ALL_BLOGS_PUBLIC_FAIL,
  GET_ALL_BLOGS_PUBLIC_REQUEST,
  GET_ALL_BLOGS_PUBLIC_RESET,
  GET_ALL_BLOGS_PUBLIC_SUCCESS,
  GET_ALL_CONTACT_DETAILS_PUBLIC_FAIL,
  GET_ALL_CONTACT_DETAILS_PUBLIC_REQUEST,
  GET_ALL_CONTACT_DETAILS_PUBLIC_RESET,
  GET_ALL_CONTACT_DETAILS_PUBLIC_SUCCESS,
  GET_ALL_DEALS_PUBLIC_FAIL,
  GET_ALL_DEALS_PUBLIC_REQUEST,
  GET_ALL_DEALS_PUBLIC_RESET,
  GET_ALL_DEALS_PUBLIC_SUCCESS,
  GET_ALL_DEAL_CATEGORY_PUBLIC_FAIL,
  GET_ALL_DEAL_CATEGORY_PUBLIC_REQUEST,
  GET_ALL_DEAL_CATEGORY_PUBLIC_RESET,
  GET_ALL_DEAL_CATEGORY_PUBLIC_SUCCESS,
  GET_ALL_ENTERPRISE_FAQS_PUBLIC_FAIL,
  GET_ALL_ENTERPRISE_FAQS_PUBLIC_REQUEST,
  GET_ALL_ENTERPRISE_FAQS_PUBLIC_RESET,
  GET_ALL_ENTERPRISE_FAQS_PUBLIC_SUCCESS,
  GET_ALL_INVESTORS_FAQS_PUBLIC_FAIL,
  GET_ALL_INVESTORS_FAQS_PUBLIC_REQUEST,
  GET_ALL_INVESTORS_FAQS_PUBLIC_RESET,
  GET_ALL_INVESTORS_FAQS_PUBLIC_SUCCESS,
  GET_ALL_NEWS_PUBLIC_FAIL,
  GET_ALL_NEWS_PUBLIC_REQUEST,
  GET_ALL_NEWS_PUBLIC_RESET,
  GET_ALL_NEWS_PUBLIC_SUCCESS,
  GET_ALL_SUCCESS_STORIES_PUBLIC_FAIL,
  GET_ALL_SUCCESS_STORIES_PUBLIC_REQUEST,
  GET_ALL_SUCCESS_STORIES_PUBLIC_RESET,
  GET_ALL_SUCCESS_STORIES_PUBLIC_SUCCESS,
  GET_BLOGS_SLUG_PUBLIC_FAIL,
  GET_BLOGS_SLUG_PUBLIC_REQUEST,
  GET_BLOGS_SLUG_PUBLIC_RESET,
  GET_BLOGS_SLUG_PUBLIC_SUCCESS,
  GET_NEWS_SLUG_PUBLIC_FAIL,
  GET_NEWS_SLUG_PUBLIC_REQUEST,
  GET_NEWS_SLUG_PUBLIC_RESET,
  GET_NEWS_SLUG_PUBLIC_SUCCESS,
  GET_PARTNERS_PUBLIC_FAIL,
  GET_PARTNERS_PUBLIC_REQUEST,
  GET_PARTNERS_PUBLIC_RESET,
  GET_PARTNERS_PUBLIC_SUCCESS,
  GET_SINGLE_ADVISOR_PUBLIC_FAIL,
  GET_SINGLE_ADVISOR_PUBLIC_REQUEST,
  GET_SINGLE_ADVISOR_PUBLIC_RESET,
  GET_SINGLE_ADVISOR_PUBLIC_SUCCESS,
  GET_SINGLE_DEALS_PUBLIC_FAIL,
  GET_SINGLE_DEALS_PUBLIC_REQUEST,
  GET_SINGLE_DEALS_PUBLIC_RESET,
  GET_SINGLE_DEALS_PUBLIC_SUCCESS,
  GET_SINGLE_PARTNER_PUBLIC_FAIL,
  GET_SINGLE_PARTNER_PUBLIC_REQUEST,
  GET_SINGLE_PARTNER_PUBLIC_RESET,
  GET_SINGLE_PARTNER_PUBLIC_SUCCESS,
  GET_SINGLE_TEAMS_PUBLIC_FAIL,
  GET_SINGLE_TEAMS_PUBLIC_REQUEST,
  GET_SINGLE_TEAMS_PUBLIC_RESET,
  GET_SINGLE_TEAMS_PUBLIC_SUCCESS,
  GET_TEAMS_PUBLIC_FAIL,
  GET_TEAMS_PUBLIC_REQUEST,
  GET_TEAMS_PUBLIC_RESET,
  GET_TEAMS_PUBLIC_SUCCESS,
  GET_WORK_WITH_US_PUBLIC_FAIL,
  GET_WORK_WITH_US_PUBLIC_REQUEST,
  GET_WORK_WITH_US_PUBLIC_RESET,
  GET_WORK_WITH_US_PUBLIC_SUCCESS,
  POST_CONTACT_FORM_PUBLIC_FAIL,
  POST_CONTACT_FORM_PUBLIC_REQUEST,
  POST_CONTACT_FORM_PUBLIC_RESET,
  POST_CONTACT_FORM_PUBLIC_SUCCESS,
  POST_VERIFY_MEMBER_FAIL,
  POST_VERIFY_MEMBER_REQUEST,
  POST_VERIFY_MEMBER_RESET,
  POST_VERIFY_MEMBER_SUCCESS,
} from "./PublicRoutesConstants";

export const getAboutCompanyPublicReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ABOUT_COMPANY_PUBLIC_REQUEST:
      return { loading: true };
    case GET_ABOUT_COMPANY_PUBLIC_SUCCESS:
      return { loading: false, allAboutPublic: action.payload };
    case GET_ABOUT_COMPANY_PUBLIC_FAIL:
      return { loading: false, error: action.payload };
    case GET_ABOUT_COMPANY_PUBLIC_RESET:
      return {};
    default:
      return state;
  }
};

export const getWorkWithUsPublicReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_WORK_WITH_US_PUBLIC_REQUEST:
      return { loading: true };
    case GET_WORK_WITH_US_PUBLIC_SUCCESS:
      return { loading: false, allWorkwithusPublic: action.payload };
    case GET_WORK_WITH_US_PUBLIC_FAIL:
      return { loading: false, error: action.payload };
    case GET_WORK_WITH_US_PUBLIC_RESET:
      return {};
    default:
      return state;
  }
};
export const getTeamsPublicReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_TEAMS_PUBLIC_REQUEST:
      return { loading: true };
    case GET_TEAMS_PUBLIC_SUCCESS:
      return { loading: false, allTeamsPublic: action.payload };
    case GET_TEAMS_PUBLIC_FAIL:
      return { loading: false, error: action.payload };
    case GET_TEAMS_PUBLIC_RESET:
      return {};
    default:
      return state;
  }
};
export const getSingleTeamPublicReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_SINGLE_TEAMS_PUBLIC_REQUEST:
      return { loading: true };
    case GET_SINGLE_TEAMS_PUBLIC_SUCCESS:
      return { loading: false, singleTeamPublic: action.payload };
    case GET_SINGLE_TEAMS_PUBLIC_FAIL:
      return { loading: false, error: action.payload };
    case GET_SINGLE_TEAMS_PUBLIC_RESET:
      return {};
    default:
      return state;
  }
};
export const getAdvisorsPublicReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ADVISORS_PUBLIC_REQUEST:
      return { loading: true };
    case GET_ADVISORS_PUBLIC_SUCCESS:
      return { loading: false, allAdvisorsPublic: action.payload };
    case GET_ADVISORS_PUBLIC_FAIL:
      return { loading: false, error: action.payload };
    case GET_ADVISORS_PUBLIC_RESET:
      return {};
    default:
      return state;
  }
};
export const getSingleAdvisorCompanyPublicReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_SINGLE_ADVISOR_PUBLIC_REQUEST:
      return { loading: true };
    case GET_SINGLE_ADVISOR_PUBLIC_SUCCESS:
      return { loading: false, singleAdvisorPublic: action.payload };
    case GET_SINGLE_ADVISOR_PUBLIC_FAIL:
      return { loading: false, error: action.payload };
    case GET_SINGLE_ADVISOR_PUBLIC_RESET:
      return {};
    default:
      return state;
  }
};

export const getPartnersPublicReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PARTNERS_PUBLIC_REQUEST:
      return { loading: true };
    case GET_PARTNERS_PUBLIC_SUCCESS:
      return { loading: false, allPartnersPublic: action.payload };
    case GET_PARTNERS_PUBLIC_FAIL:
      return { loading: false, error: action.payload };
    case GET_PARTNERS_PUBLIC_RESET:
      return {};
    default:
      return state;
  }
};

export const getSinglePartnerPublicReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_SINGLE_PARTNER_PUBLIC_REQUEST:
      return { loading: true };
    case GET_SINGLE_PARTNER_PUBLIC_SUCCESS:
      return { loading: false, singlePartnerPublic: action.payload };
    case GET_SINGLE_PARTNER_PUBLIC_FAIL:
      return { loading: false, error: action.payload };
    case GET_SINGLE_PARTNER_PUBLIC_RESET:
      return {};
    default:
      return state;
  }
};

export const getAllSuccessStoriesPublicReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_SUCCESS_STORIES_PUBLIC_REQUEST:
      return { loading: true };
    case GET_ALL_SUCCESS_STORIES_PUBLIC_SUCCESS:
      return { loading: false, allSuccessStoriesPublic: action.payload };
    case GET_ALL_SUCCESS_STORIES_PUBLIC_FAIL:
      return { loading: false, error: action.payload };
    case GET_ALL_SUCCESS_STORIES_PUBLIC_RESET:
      return {};
    default:
      return state;
  }
};

export const getAllBlogsPublicReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_BLOGS_PUBLIC_REQUEST:
      return { loading: true };
    case GET_ALL_BLOGS_PUBLIC_SUCCESS:
      return { loading: false, allBlogsPublic: action.payload };
    case GET_ALL_BLOGS_PUBLIC_FAIL:
      return { loading: false, error: action.payload };
    case GET_ALL_BLOGS_PUBLIC_RESET:
      return {};
    default:
      return state;
  }
};

export const getBlogsSlugPublicReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_BLOGS_SLUG_PUBLIC_REQUEST:
      return { loading: true };
    case GET_BLOGS_SLUG_PUBLIC_SUCCESS:
      return { loading: false, blogsSlugPublic: action.payload };
    case GET_BLOGS_SLUG_PUBLIC_FAIL:
      return { loading: false, error: action.payload };
    case GET_BLOGS_SLUG_PUBLIC_RESET:
      return {};
    default:
      return state;
  }
};

export const getAllNewsPublicReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_NEWS_PUBLIC_REQUEST:
      return { loading: true };
    case GET_ALL_NEWS_PUBLIC_SUCCESS:
      return { loading: false, allNewsPublic: action.payload };
    case GET_ALL_NEWS_PUBLIC_FAIL:
      return { loading: false, error: action.payload };
    case GET_ALL_NEWS_PUBLIC_RESET:
      return {};
    default:
      return state;
  }
};

export const getNewsSlugPublicReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_NEWS_SLUG_PUBLIC_REQUEST:
      return { loading: true };
    case GET_NEWS_SLUG_PUBLIC_SUCCESS:
      return { loading: false, newsSlugPublic: action.payload };
    case GET_NEWS_SLUG_PUBLIC_FAIL:
      return { loading: false, error: action.payload };
    case GET_NEWS_SLUG_PUBLIC_RESET:
      return {};
    default:
      return state;
  }
};

export const getAllInvestorFaqsPublicReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_INVESTORS_FAQS_PUBLIC_REQUEST:
      return { loading: true };
    case GET_ALL_INVESTORS_FAQS_PUBLIC_SUCCESS:
      return { loading: false, allInvestorFaqsPublic: action.payload };
    case GET_ALL_INVESTORS_FAQS_PUBLIC_FAIL:
      return { loading: false, error: action.payload };
    case GET_ALL_INVESTORS_FAQS_PUBLIC_RESET:
      return {};
    default:
      return state;
  }
};

export const getAllEnterpriseFaqsPublicReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_ENTERPRISE_FAQS_PUBLIC_REQUEST:
      return { loading: true };
    case GET_ALL_ENTERPRISE_FAQS_PUBLIC_SUCCESS:
      return { loading: false, allEnterpriseFaqsPublic: action.payload };
    case GET_ALL_ENTERPRISE_FAQS_PUBLIC_FAIL:
      return { loading: false, error: action.payload };
    case GET_ALL_ENTERPRISE_FAQS_PUBLIC_RESET:
      return {};
    default:
      return state;
  }
};

export const getAllContactDetailsPublicReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_CONTACT_DETAILS_PUBLIC_REQUEST:
      return { loading: true };
    case GET_ALL_CONTACT_DETAILS_PUBLIC_SUCCESS:
      return { loading: false, allContactDetailsPublic: action.payload };
    case GET_ALL_CONTACT_DETAILS_PUBLIC_FAIL:
      return { loading: false, error: action.payload };
    case GET_ALL_CONTACT_DETAILS_PUBLIC_RESET:
      return {};
    default:
      return state;
  }
};

export const postContactFormPublicReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_CONTACT_FORM_PUBLIC_REQUEST:
      return { loading: true };
    case POST_CONTACT_FORM_PUBLIC_SUCCESS:
      return { loading: false, success: true };
    case POST_CONTACT_FORM_PUBLIC_FAIL:
      return { loading: false, error: action.payload };
    case POST_CONTACT_FORM_PUBLIC_RESET:
      return {};
    default:
      return state;
  }
};

export const getAllDealsPublicReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_DEALS_PUBLIC_REQUEST:
      return { loading: true };
    case GET_ALL_DEALS_PUBLIC_SUCCESS:
      return { loading: false, allDealsPublic: action.payload };
    case GET_ALL_DEALS_PUBLIC_FAIL:
      return { loading: false, error: action.payload };
    case GET_ALL_DEALS_PUBLIC_RESET:
      return {};
    default:
      return state;
  }
};

export const getSingleDealsPublicReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_SINGLE_DEALS_PUBLIC_REQUEST:
      return { loading: true };
    case GET_SINGLE_DEALS_PUBLIC_SUCCESS:
      return { loading: false, singleDealsPublic: action.payload };
    case GET_SINGLE_DEALS_PUBLIC_FAIL:
      return { loading: false, error: action.payload };
    case GET_SINGLE_DEALS_PUBLIC_RESET:
      return {};
    default:
      return state;
  }
};

export const getAllDealCategoryPublicReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_DEAL_CATEGORY_PUBLIC_REQUEST:
      return { loading: true };
    case GET_ALL_DEAL_CATEGORY_PUBLIC_SUCCESS:
      return { loading: false, allDealsCategoryPublic: action.payload };
    case GET_ALL_DEAL_CATEGORY_PUBLIC_FAIL:
      return { loading: false, error: action.payload };
    case GET_ALL_DEAL_CATEGORY_PUBLIC_RESET:
      return {};
    default:
      return state;
  }
};

export const postVerifyMemberReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_VERIFY_MEMBER_REQUEST:
      return { loading: true };
    case POST_VERIFY_MEMBER_SUCCESS:
      return { loading: false, success: true };
    case POST_VERIFY_MEMBER_FAIL:
      return { loading: false, error: action.payload };
    case POST_VERIFY_MEMBER_RESET:
      return {};
    default:
      return state;
  }
};

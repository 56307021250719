export const GET_ALL_QUESTIONARIE_REQUEST = "GET_ALL_QUESTIONARIE_REQUEST";
export const GET_ALL_QUESTIONARIE_SUCCESS = "GET_ALL_QUESTIONARIE_SUCCESS";
export const GET_ALL_QUESTIONARIE_FAIL = "GET_ALL_QUESTIONARIE_FAIL";
export const GET_ALL_QUESTIONARIE_RESET = "GET_ALL_QUESTIONARIE_RESET";

export const GET_SINGLE_QUESTIONARIE_REQUEST =
  "GET_SINGLE_QUESTIONARIE_REQUEST";
export const GET_SINGLE_QUESTIONARIE_SUCCESS =
  "GET_SINGLE_QUESTIONARIE_SUCCESS";
export const GET_SINGLE_QUESTIONARIE_FAIL = "GET_SINGLE_QUESTIONARIE_FAIL";
export const GET_SINGLE_QUESTIONARIE_RESET = "GET_SINGLE_QUESTIONARIE_RESET";

export const POST_QUESTIONARIE_REQUEST = "POST_QUESTIONARIE_REQUEST";
export const POST_QUESTIONARIE_SUCCESS = "POST_QUESTIONARIE_SUCCESS";
export const POST_QUESTIONARIE_FAIL = "POST_QUESTIONARIE_FAIL";
export const POST_QUESTIONARIE_RESET = "POST_QUESTIONARIE_RESET";

export const PUT_QUESTIONARIE_REQUEST = "PUT_QUESTIONARIE_REQUEST";
export const PUT_QUESTIONARIE_SUCCESS = "PUT_QUESTIONARIE_SUCCESS";
export const PUT_QUESTIONARIE_FAIL = "PUT_QUESTIONARIE_FAIL";
export const PUT_QUESTIONARIE_RESET = "PUT_QUESTIONARIE_RESET";

export const DELETE_QUESTIONARIE_REQUEST = "DELETE_QUESTIONARIE_REQUEST";
export const DELETE_QUESTIONARIE_SUCCESS = "DELETE_QUESTIONARIE_SUCCESS";
export const DELETE_QUESTIONARIE_FAIL = "DELETE_QUESTIONARIE_FAIL";
export const DELETE_QUESTIONARIE_RESET = "DELETE_QUESTIONARIE_RESET";

export const GET_ALL_USERS_REQUEST = "GET_ALL_USERS_REQUEST";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const GET_ALL_USERS_FAIL = "GET_ALL_USERS_FAIL";
export const GET_ALL_USERS_RESET = "GET_ALL_USERS_FAIL";

export const GET_SINGLE_USERS_REQUEST = "GET_SINGLE_USERS_REQUEST";
export const GET_SINGLE_USERS_SUCCESS = "GET_SINGLE_USERS_SUCCESS";
export const GET_SINGLE_USERS_FAIL = "GET_SINGLE_USERS_FAIL";
export const GET_SINGLE_USERS_RESET = "GET_SINGLE_USERS_RESET";

export const POST_USERS_REQUEST = "POST_USERS_REQUEST";
export const POST_USERS_SUCCESS = "POST_USERS_SUCCESS";
export const POST_USERS_FAIL = "POST_USERS_FAIL";
export const POST_USERS_RESET = "POST_USERS_RESET";

export const PUT_USERS_REQUEST = "PUT_USERS_REQUEST";
export const PUT_USERS_SUCCESS = "PUT_USERS_SUCCESS";
export const PUT_USERS_FAIL = "PUT_USERS_FAIL";
export const PUT_USERS_RESET = "PUT_USERS_RESET";

export const DELETE_USERS_REQUEST = "DELETE_USERS_REQUEST";
export const DELETE_USERS_SUCCESS = "DELETE_USERS_SUCCESS";
export const DELETE_USERS_FAIL = "DELETE_USERS_FAIL";
export const DELETE_USERS_RESET = "DELETE_USERS_RESET";

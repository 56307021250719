import React from "react";
import { TableRow, TableCell, Button } from "@mui/material";
import Moment from "react-moment";
import VerifiedIcon from "@mui/icons-material/Verified";
import CancelIcon from "@mui/icons-material/Cancel";

const AllMembersTableCollapse = ({ item, profileHandler }) => {
  return (
    <TableRow>
      <TableCell sx={{ fontSize: "13px" }}>{item.username}</TableCell>
      <TableCell sx={{ fontSize: "13px" }}>
        {item.first_name} {item.middle_name} {item.last_name}
      </TableCell>
      <TableCell sx={{ fontSize: "13px" }}>{item.email}</TableCell>
      <TableCell sx={{ fontSize: "13px" }}>{item.mobile}</TableCell>

      <TableCell sx={{ fontSize: "13px" }}>
        {item.status === 1 ? (
          <VerifiedIcon style={{ color: "#42a5f5", fontSize: "large" }} />
        ) : (
          <CancelIcon style={{ color: "#ed5e5e", fontSize: "large" }} />
        )}{" "}
      </TableCell>
      <TableCell sx={{ fontSize: "13px" }}>
        <Moment format="YYYY/MM/DD">{item.created_date.slice(0, 10)}</Moment>
        <br />(<Moment fromNow>{item.created_date}</Moment>)
      </TableCell>
      <TableCell align="left">
        <Button
          variant="contained"
          size="small"
          style={{
            backgroundColor: "#2a80f9",
            fontSize: "13px",
            fontWeight: "600",
          }}
          onClick={() => profileHandler(item.member_id)}
        >
          {" "}
          Profiles
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default AllMembersTableCollapse;

import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import store from "./store";
import "./index.css";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import App from "./App";
import { Provider } from "react-redux";
import { AuthProvider } from "./context/AuthProvider";
import LoginAdmin from "./login-admin-portal/LoginAdmin";
import Dashboard from "./admin-portal/dashboard/Dashboard";
import ManageUsers from "./admin-portal/manage-users/ManageUsers";
import AboutUs from "./admin-portal/about-us/AboutUs";
import Layout from "./frontend/components/Layout";
import HomePage from "./frontend/HomePage";
import MemberLogin from "./frontend/MemberLogin";
//import EnterpriseKyc from "./frontend/enterprise-kyc-step-forms/EnterpriseKyc";
// import InvestorKyc from "./frontend/InvestorKyc";
import ForgotPassword from "./components/ForgotPassword";
import MemberForgotPassword from "./frontend/MemberForgotPassword";
import ChangePassword from "./components/ChangePassword";
import ResetPassword from "./components/ResetPassword";
import AllMembersProfileDetails from "./admin-portal/members-list/all-members/AllMembersProfileDetails";
import EnterpriceKYCProfile from "./admin-portal/members-list/all-members/EnterpriceKYCProfile";
import InvestorKYCProfile from "./admin-portal/members-list/all-members/InvestorKYCProfile";
import ServiceProviderKYCProfile from "./admin-portal/members-list/all-members/ServiceProviderKYCProfile";
import Questionarie from "./admin-portal/questionarie/Questionarie";
import AllMembers from "./admin-portal/members-list/all-members/AllMembers";
import ManagePages from "./admin-portal/manage-pages/ManagePages";
import EnterpriseDetailKyc from "./frontend/kyc-state-handler/EnterpriseDetailKyc";
import InvestorDetailKyc from "./frontend/kyc-state-handler/InvestorDetailKyc";
import ManageContacts from "./admin-portal/manage-contacts/ManageContacts";
import ManageContactsSingleDetail from "./admin-portal/manage-contacts/ManageContactsSingleDetail";
import AboutUsPagePublic from "./frontend/public-pages/AboutUsPagePublic";
import FAQsPagePublic from "./frontend/public-pages/FAQsPagePublic";
import ContactusPagePublic from "./frontend/public-pages/ContactusPagePublic";
import ManageDealsDetailView from "./admin-portal/manage-deals/ManageDealsDetailView";
import ManageDealsMainPage from "./admin-portal/manage-deals/manage-deals-main/ManageDealsMainPage";
import SuccessStories from "./admin-portal/success-stories/SuccessStories";
import Blogs from "./admin-portal/blogs/Blogs";
import News from "./admin-portal/news/News";
import NewsBlogsPublic from "./frontend/public-pages/NewsBlogsPublic";
import FAQsMain from "./admin-portal/faqs/FAQsMain";
import NewsSinglePagePublic from "./frontend/public-pages/NewsSinglePagePublic";
import BlogsSinglePagePublic from "./frontend/public-pages/BlogsSinglePagePublic";
import DealsListingPagePublic from "./frontend/public-pages/DealsListingPagePublic";
import SingleDeals from "./frontend/private-pages/SingleDeals";
import MemberDashboard from "./frontend/private-pages/MemberDashboard";
import MemberProfile from "./frontend/private-pages/MemberProfile";
import MemberChangePassword from "./frontend/private-pages/MemberChangePassword";
import EnterpriseKycBackup from "./frontend/enterprise-kyc-step-forms/EnterpriseKycBackup";
import InvestorKycMain from "./frontend/investor-kyc-step-forms/InvestorKycMain";
import VerifyMember from "./frontend/components/VerifyMember";
import VerifiedMember from "./frontend/components/VerifiedMember";
import MemberResetPassword from "./frontend/components/MemberResetPassword";
import "react-toastify/dist/ReactToastify.css";
import MemberDealsTab from "./frontend/private-pages/MemberDeals/MemberDealsTab";
import MemberDealsShowInterest from "./frontend/private-pages/MemberDeals/MemberDealsShowInterest";
import SingleShowInterestDeal from "./frontend/private-pages/MemberDeals/SingleShowInterestDeal";
import ForEnterprise from "./frontend/public-pages/public-dropdown-pages/ForEnterprise";
import ForInvestor from "./frontend/public-pages/public-dropdown-pages/ForInvestor";
import LegalDocumentsApproval from "./frontend/components/LegalDocumentsApproval";
import AuthenticatedMemberLayout from "./frontend/components/AuthenticatedMemberLayout";
import OurProcess from "./frontend/public-pages/OurProcess";
import FAQs from "./frontend/public-pages/FAQs";
import OurTeam from "./frontend/public-pages/OurTeam";
// import Test from "./admin-portal/manage-pages/Test";

if (process.env.NODE_ENV === "production") {
  disableReactDevTools();
}

const root = ReactDOM.createRoot(document.getElementById("root"));

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  { path: "/verify/:verification_code", element: <VerifyMember /> },
  { path: "/verify/verified", element: <VerifiedMember /> },
  {
    path: "/about-us",
    element: <AboutUsPagePublic />,
  },
  {
    path: "/our-teams/:index",
    element: <OurTeam />,
  },
  {
    path: "/our-teams",
    element: <OurTeam />,
  },
  { path: "/our-process", element: <OurProcess /> },
  { path: "/enterprise-only", element: <ForEnterprise /> },
  { path: "/investor-only", element: <ForInvestor /> },
  {
    path: "/faqs",
    element: <FAQs />,
  },

  // {
  //   path: "/faqs",
  //   element: <FAQsPagePublic />,
  // },

  { path: "/contact-us", element: <ContactusPagePublic /> },
  { path: "/news-&-blogs", element: <NewsBlogsPublic /> },
  { path: "/news-&-blogs/news-single-post", element: <NewsSinglePagePublic /> },
  { path: "/deals-listing", element: <DealsListingPagePublic /> },
  {
    path: "/news-&-blogs/blogs-single-post",
    element: <BlogsSinglePagePublic />,
  },
  {
    path: "/login",
    element: <MemberLogin />,
  },
  { path: "/terms", element: <LegalDocumentsApproval /> },
  {
    path: "/forgot_password",
    element: <MemberForgotPassword />,
  },
  {
    path: "/member/reset_password",
    element: <MemberResetPassword />,
  },

  {
    path: "/",
    element: <AuthenticatedMemberLayout />,
    children: [
      {
        path: "/dashboard",
        element: <MemberDashboard />,
      },
      {
        path: "/dashboard/profile",
        element: <MemberProfile />,
      },
      {
        path: "/dashboard/memberdeals",
        element: <MemberDealsTab />,
      },
      {
        path: "/dashboard/interested-deals",
        element: <MemberDealsShowInterest />,
      },
      {
        path: "/dashboard/interested-deals/single-interest",
        element: <SingleShowInterestDeal />,
      },
      {
        path: "/dashboard/change-password",
        element: <MemberChangePassword />,
      },
      {
        path: "/dashboard/enterprise_detail_kyc",
        element: <EnterpriseDetailKyc />,
      },
      {
        path: "/dashboard/investor_detail_kyc",
        element: <InvestorDetailKyc />,
      },
      // {
      //   path: "/enterprise_kyc",
      //   element: <EnterpriseKyc />,
      // },
      {
        path: "/enterprise_kyc",
        element: <EnterpriseKycBackup />,
      },
      // {
      //   path: "/investor_kyc_ref",
      //   element: <InvestorKyc />,
      // },
      {
        path: "/investor_kyc",
        element: <InvestorKycMain />,
      },
      {
        path: "/deals-listing/single-deals",
        element: <SingleDeals />,
      },
    ],
  },
  {
    path: "/admin/login",
    element: <LoginAdmin />,
  },
  {
    path: "/admin/forgot_password",
    element: <ForgotPassword />,
  },
  {
    path: "/admin/reset_password",
    element: <ResetPassword />,
  },
  {
    path: "/admin",
    element: <App />,
    errorElement: <LoginAdmin />,
    children: [
      {
        path: "/admin",
        element: <Dashboard />,
      },
      {
        path: "/admin/users",
        element: <ManageUsers />,
      },
      {
        path: "/admin/manage-pages",
        element: <ManagePages />,
      },
      {
        path: "/admin/members-list",
        element: <AllMembers />,
      },
      {
        path: "/admin/abouts",
        element: <AboutUs />,
      },
      {
        path: "/admin/manage-contacts",
        element: <ManageContacts />,
      },
      {
        path: "/admin/manage-contacts/details",
        element: <ManageContactsSingleDetail />,
      },
      {
        path: "/admin/faqs",
        element: <FAQsMain />,
      },
      {
        path: "/admin/success-stories",
        element: <SuccessStories />,
      },
      { path: "/admin/blogs", element: <Blogs /> },
      { path: "/admin/news", element: <News /> },
      // {
      //   path: "/admin/test",
      //   element: <Test />,
      // },
      {
        path: "/admin/manage-deals",
        element: <ManageDealsMainPage />,
      },
      {
        path: "/admin/manage-deals/details",
        element: <ManageDealsDetailView />,
      },
      {
        path: "/admin/questionnaire",
        element: <Questionarie />,
      },
      {
        path: "/admin/change_password",
        element: <ChangePassword />,
      },
      // {
      //   path: "/admin/reset_password_code",
      //   element: <ResetPasswordCode />,
      // },

      {
        path: "/admin/members-list/profile",
        element: <AllMembersProfileDetails />,
      },
      {
        path: "/admin/members-list/profile/enterprise_kyc_profile",
        element: <EnterpriceKYCProfile />,
      },
      {
        path: "/admin/members-list/profile/investor_kyc_profile",
        element: <InvestorKYCProfile />,
      },
      {
        path: "/admin/members-list/profile/serviceprovider_kyc_profile",
        element: <ServiceProviderKYCProfile />,
      },
    ],
  },
]);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <AuthProvider>
        <RouterProvider router={router} />
      </AuthProvider>
    </Provider>
  </React.StrictMode>
);

import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ArrowButton from "../components/ArrowButton";
import "../../styles/style.css";

const CompanyOrgDetails = ({ formData, setFormData, formErrors }) => {
  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="description" content="" />
        <meta name="author" content="" />

        <title>Million Deals</title>
        {/* <!-- Linking the font Poppins--> */}
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />

        {/* <!-- Linking Bootstrap Icon--> */}
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css"
        />
      </Helmet>
      <ArrowButton />

      <div className="page-title">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h1>Investor Form</h1>
              <Link className="link" to="/">
                Home
              </Link>{" "}
              /{" "}
              <Link className="link" to="/dashboard">
                Dashboard
              </Link>{" "}
              / Investor Form
            </div>
          </div>
        </div>
      </div>

      <div className="page-content">
        <div className="container">
          <div className="row">
            <div className="col-md-9">
              <div className="invest-form">
                <form>
                  <h2>Company/Organization Details</h2>
                  Company or Organization you are associated with?{" "}
                  {formErrors.company_name && (
                    <span className="error">{formErrors.company_name}</span>
                  )}
                  <input
                    type="text"
                    name="company_name"
                    value={formData.company_name || ""}
                    onChange={handleInputChange}
                  />
                  Company website or LinkedIn Page link{" "}
                  {formErrors.company_website && (
                    <span className="error">{formErrors.company_website}</span>
                  )}
                  <input
                    type="text"
                    name="company_website"
                    value={formData.company_website || ""}
                    onChange={handleInputChange}
                  />
                  Please provide a brief about your company's profile and
                  background
                  <br />
                  {formErrors.company_bio && (
                    <span className="error">{formErrors.company_bio}</span>
                  )}
                  <textarea
                    type="text"
                    name="company_bio"
                    value={formData.company_bio || ""}
                    onChange={handleInputChange}
                  />
                  Which of the following describes you the best?{" "}
                  {formErrors.describes_your_best && (
                    <span className="error">
                      {formErrors.describes_your_best}
                    </span>
                  )}
                  <select
                    name="describes_your_best"
                    id="describes_your_best"
                    value={formData.describes_your_best || ""}
                    onChange={handleInputChange}
                  >
                    <option value="">Select an option</option>
                    <option value="Endowments and Foundation">
                      Endowments and Foundation
                    </option>
                    <option value="Bank">Bank</option>
                    <option value="Insurance Company">Insurance Company</option>
                    <option value="Investment Manager including PE/VC">
                      Investment Manager including PE/VC
                    </option>
                    <option value="Other Financial Intermediary">
                      Other Financial Intermediary
                    </option>
                    <option value=" Professional / Active Investor">
                      Professional / Active Investor
                    </option>
                    <option value="Passive Investor">Passive Investor</option>
                    <option value="Business Owner">Business Owner</option>
                  </select>
                  {formData.describes_your_best ===
                    "Other Financial Intermediary" && (
                    <>
                      Please write what describe you the best if others{" "}
                      {formErrors.describes_your_best_other && (
                        <span className="error">
                          {formErrors.describes_your_best_other}
                        </span>
                      )}
                      <textarea
                        type="text"
                        name="describes_your_best_other"
                        value={formData.describes_your_best_other || ""}
                        onChange={handleInputChange}
                      />
                    </>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyOrgDetails;

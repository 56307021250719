import {
  GET_ALL_ABOUT_MILLION_DEALS_FAIL,
  GET_ALL_ABOUT_MILLION_DEALS_REQUEST,
  GET_ALL_ABOUT_MILLION_DEALS_RESET,
  GET_ALL_ABOUT_MILLION_DEALS_SUCCESS,
  PUT_ABOUT_MILLION_DEALS_FAIL,
  PUT_ABOUT_MILLION_DEALS_REQUEST,
  PUT_ABOUT_MILLION_DEALS_RESET,
  PUT_ABOUT_MILLION_DEALS_SUCCESS,
} from "./AboutMillionDealsConstants";

export const getAllAboutMillionDealsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_ABOUT_MILLION_DEALS_REQUEST:
      return { loading: true };
    case GET_ALL_ABOUT_MILLION_DEALS_SUCCESS:
      return { loading: false, allAboutMillionDeals: action.payload };
    case GET_ALL_ABOUT_MILLION_DEALS_FAIL:
      return { loading: false, error: action.payload };
    case GET_ALL_ABOUT_MILLION_DEALS_RESET:
      return {};
    default:
      return state;
  }
};

export const putAllAboutMillionDealsReducer = (state = {}, action) => {
  switch (action.type) {
    case PUT_ABOUT_MILLION_DEALS_REQUEST:
      return { loading: true };
    case PUT_ABOUT_MILLION_DEALS_SUCCESS:
      return { loading: false, success: true };
    case PUT_ABOUT_MILLION_DEALS_FAIL:
      return { loading: false, error: action.payload };
    case PUT_ABOUT_MILLION_DEALS_RESET:
      return {};
    default:
      return state;
  }
};

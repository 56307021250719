import {
  DELETE_FAQS_FAIL,
  DELETE_FAQS_REQUEST,
  DELETE_FAQS_RESET,
  DELETE_FAQS_SUCCESS,
  GET_ALL_ENTERPRISE_FAQS_FAIL,
  GET_ALL_ENTERPRISE_FAQS_REQUEST,
  GET_ALL_ENTERPRISE_FAQS_RESET,
  GET_ALL_ENTERPRISE_FAQS_SUCCESS,
  GET_ALL_INVESTOR_FAQS_FAIL,
  GET_ALL_INVESTOR_FAQS_REQUEST,
  GET_ALL_INVESTOR_FAQS_RESET,
  GET_ALL_INVESTOR_FAQS_SUCCESS,
  GET_SINGLE_FAQS_FAIL,
  GET_SINGLE_FAQS_REQUEST,
  GET_SINGLE_FAQS_RESET,
  GET_SINGLE_FAQS_SUCCESS,
  POST_FAQS_FAIL,
  POST_FAQS_REQUEST,
  POST_FAQS_RESET,
  POST_FAQS_SUCCESS,
  PUT_FAQS_FAIL,
  PUT_FAQS_REQUEST,
  PUT_FAQS_RESET,
  PUT_FAQS_SUCCESS,
} from "./FAQsConstants";

export const getAllEnterpriseFaqsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_ENTERPRISE_FAQS_REQUEST:
      return { loading: true };
    case GET_ALL_ENTERPRISE_FAQS_SUCCESS:
      return { loading: false, allEnterpriseFaqs: action.payload };
    case GET_ALL_ENTERPRISE_FAQS_FAIL:
      return { loading: false, error: action.payload };
    case GET_ALL_ENTERPRISE_FAQS_RESET:
      return {};
    default:
      return state;
  }
};

export const getAllInvestorFaqsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_INVESTOR_FAQS_REQUEST:
      return { loading: true };
    case GET_ALL_INVESTOR_FAQS_SUCCESS:
      return { loading: false, allInvestorFaqs: action.payload };
    case GET_ALL_INVESTOR_FAQS_FAIL:
      return { loading: false, error: action.payload };
    case GET_ALL_INVESTOR_FAQS_RESET:
      return {};
    default:
      return state;
  }
};

export const getSingleFaqsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_SINGLE_FAQS_REQUEST:
      return { loading: true };
    case GET_SINGLE_FAQS_SUCCESS:
      return { loading: false, singleFaqs: action.payload };
    case GET_SINGLE_FAQS_FAIL:
      return { loading: false, error: action.payload };
    case GET_SINGLE_FAQS_RESET:
      return {};
    default:
      return state;
  }
};

export const postFaqsReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_FAQS_REQUEST:
      return { loading: true };
    case POST_FAQS_SUCCESS:
      return {
        loading: false,
        success: action.payload,
      };
    case POST_FAQS_FAIL:
      return { loading: false, error: action.payload };
    case POST_FAQS_RESET:
      return {};
    default:
      return state;
  }
};

export const putFaqsReducer = (state = {}, action) => {
  switch (action.type) {
    case PUT_FAQS_REQUEST:
      return { loading: true };
    case PUT_FAQS_SUCCESS:
      return { loading: false, success: action.payload };
    case PUT_FAQS_FAIL:
      return { loading: false, error: action.payload };
    case PUT_FAQS_RESET:
      return {};
    default:
      return state;
  }
};

export const deleteFaqsReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_FAQS_REQUEST:
      return { loading: true };
    case DELETE_FAQS_SUCCESS:
      return { loading: false, success: action.payload };
    case DELETE_FAQS_FAIL:
      return { loading: false, error: action.payload };
    case DELETE_FAQS_RESET:
      return {};
    default:
      return state;
  }
};

import { Clear } from "@mui/icons-material";
import { Button, Tab, Tabs } from "@mui/material";
import React from "react";
import Login from "./LogIn";
import SignUp from "./SignUp";

const MainLoginComponent = ({ selectedTab, handleTabChange, handleClose }) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Tabs value={selectedTab} onChange={handleTabChange}>
          <Tab label="Log In" value="login" />
          <Tab label="Sign Up" value="signup" />
        </Tabs>

        <Button
          style={{
            marginRight: "1px",
            padding: "15px 6px",
            minWidth: "10px",
            fontSize: "10px",
            height: "20px",
            backgroundColor: "#0b6885",
            color: "secondary",
          }}
          variant="contained"
          onClick={() => handleClose()}
        >
          <Clear fontSize="small" />
        </Button>
      </div>
      {selectedTab === "login" && (
        <div>
          <Login />
        </div>
      )}
      {selectedTab === "signup" && (
        <div>
          <SignUp />
        </div>
      )}
    </>
  );
};

export default MainLoginComponent;

import { Button, TextField } from "@mui/material";
import React, { useRef, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import useAuth from "../components/hooks/useAuth";
import UserFooter from "./components/UserFooter";
import UserHeader from "./components/UserHeader";
import {
  setMemberAccessToken,
  setMemberRefreshToken,
} from "../services/storage";
import { API_URL, axiosPublic } from "../api/axios";
import useAuthentication from "../components/hooks/useAuthentication";
import { LoadingButton } from "@mui/lab";
import useMemberAuthentication from "../components/hooks/useMemberAuthentication";

const UserLogin = () => {
  const [loading, setLoading] = useState(false);
  const { isAuthenticated, login } = useMemberAuthentication();
  const { setMemberAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const from = location.state?.from?.pathname || "/dashboard";

  const emailRef = useRef();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    emailRef.current.focus();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      return navigate(from, { replace: true });
    }
  });

  useEffect(() => {
    ("");
  }, [email, password]);

  const setMemToken = (memberAccessToken, memberRefreshToken) => {
    setMemberAccessToken(memberAccessToken);
    setMemberRefreshToken(memberRefreshToken);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axiosPublic.post(
        `${API_URL}/api/v1/members/auth/login`,
        JSON.stringify({ email_address: email, password })
      );

      const memberAccessToken = response?.data?.data?.accessToken;
      const memberRefreshToken = response?.data?.data?.refreshToken;

      login({
        accessToken: memberAccessToken,
        refreshToken: memberRefreshToken,
      });

      // setMemToken(memberAccessToken, memberRefreshToken);

      setMemberAuth({
        email_address: email,
        password: password,
        accessToken: memberAccessToken,
        refreshToken: memberRefreshToken,
      });

      setEmail("");
      setPassword("");

      // redirect after login
      navigate(from, { replace: true });
    } catch (err) {
      if (!err?.response) {
        toast.error("No Server Response");
      } else if (err.response?.status === 400) {
        toast.error(err?.response?.data?.message);
      } else if (err.response?.status === 401) {
        toast.error("Unauthorized");
      } else {
        toast.error("Login Failed");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleMemberForgotPassword = (e) => {
    e.preventDefault();
    navigate("/forgot_password");
  };

  return (
    <>
      <UserHeader />
      <form onSubmit={handleLogin}>
        <div className="container vh-100 d-flex align-items-center justify-content-center">
          <div className="card w-50" style={{ marginBottom: "13%" }}>
            <div className="auth-body">
              <h4 className="text-center">Member login</h4>
              <br />
              <div className="mb-3">
                <TextField
                  margin="normal"
                  type="email"
                  fullWidth
                  label="Email Address or Mobile"
                  id="email_address"
                  name="email_address"
                  ref={emailRef}
                  autoFocus
                  autoComplete="off"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  required
                />
              </div>

              {/* <div className="mb-3">
              <TextField
                margin="normal"
                type="number"
                fullWidth
                label="Mobile"
                id="mobile"
                name="mobile"
                autoFocus
                autoComplete="off"
                onChange={(e) => setMobile(e.target.value)}
                value={mobile}
                required
              />
            </div> */}

              <div className="mb-3">
                <TextField
                  margin="normal"
                  label="Password"
                  fullWidth
                  type="password"
                  id="password"
                  name="password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  autoComplete="off"
                  required
                />
              </div>

              <LoadingButton
                loading={loading}
                loadingPosition="end"
                type="submit"
                fullWidth
                variant="contained"
                style={{ backgroundColor: "#0b6885", color: "#ffffff" }}
                sx={{ mt: 3, mb: 2 }}
              >
                Login
              </LoadingButton>
              <ToastContainer
                position="top-right"
                autoClose={2000}
                theme="colored"
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
              />
              <div>
                <Button
                  onClick={handleMemberForgotPassword}
                  style={{
                    backgroundColor: "white",
                    textDecorationLine: "underline",
                    textTransform: "lowercase",
                  }}
                >
                  Forgot Password?
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <UserFooter />
    </>
  );
};

export default UserLogin;

import React, { useState, useEffect } from "react";
import { Button, TableBody, Toolbar } from "@mui/material";
import useCustomTable from "../../components/hooks/useCustomTable";
import { Add } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import CustomContainer from "../../components/CustomContainer";
import Topbar from "../../components/Topbar";
import Notification from "../../components/Notification";
import ManagePagesTableCollapse from "./ManagePagesTableCollapse";
import ManagePagesEditForm from "./ManagePagesEditForm";
import LoadingComp from "../../components/LoadingComp";
import ManagePagesForm from "./ManagePagesForm";
import {
  DELETE_PAGES_RESET,
  GET_ALL_PAGES_RESET,
  GET_SINGLE_PAGES_RESET,
  POST_PAGES_RESET,
  PUT_PAGES_RESET,
} from "./ManagePagesConstants";
import {
  deletePagesAction,
  getAllPagesAction,
  getSinglePagesAction,
} from "./ManagePagesActions";
import Popup from "../../components/Popup";
import ManagePagesDetail from "./ManagePagesDetail";

const tableHeader = [
  { id: "page_image", label: "Photo" },
  { id: "page_title", label: "Page Title" },
  { id: "status", label: "Status" },
  { id: "actions", label: "Actions", disableSorting: true },
];

const ManagePages = () => {
  const [tableData, setTableData] = useState([]);
  const [filterFn, setFilterFn] = useState({
    fn: (item) => {
      return item;
    },
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [openPopup, setOpenPopup] = useState(false);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [openDetailPopup, setOpenDetailPopup] = useState(false);

  const dispatch = useDispatch();

  const { allPages, error, loading } = useSelector(
    (state) => state.getAllPages
  );

  const {
    singlePage,
    error: singlePageError,
    loading: loadingEditForm,
  } = useSelector((state) => state.getSinglePages);

  const { success: postPagesSuccess, error: postPagesError } = useSelector(
    (state) => state.postPages
  );

  const { success: putPagesSuccess, error: putPagesError } = useSelector(
    (state) => state.putPages
  );

  const { success: deletePagesSuccess, error: deletePagesError } = useSelector(
    (state) => state.deletePages
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (error) {
    setNotify({
      isOpen: true,
      message: error,
      type: "error",
    });
    dispatch({ type: GET_ALL_PAGES_RESET });
  }

  if (singlePageError) {
    setNotify({
      isOpen: true,
      message: singlePageError,
      type: "error",
    });
    dispatch({ type: GET_SINGLE_PAGES_RESET });
  }

  if (postPagesError) {
    setNotify({
      isOpen: true,
      message: postPagesError,
      type: "error",
    });
    dispatch({ type: POST_PAGES_RESET });
  }

  if (putPagesError) {
    setNotify({
      isOpen: true,
      message: putPagesError,
      type: "error",
    });
    dispatch({ type: PUT_PAGES_RESET });
  }

  if (deletePagesError) {
    setNotify({
      isOpen: true,
      message: deletePagesError,
      type: "error",
    });
    dispatch({ type: DELETE_PAGES_RESET });
  }

  if (postPagesSuccess) {
    dispatch(getAllPagesAction());
    setNotify({
      isOpen: true,
      message: "Pages Added Successfully!",
      type: "success",
    });
    setOpenPopup(false);
    dispatch({ type: POST_PAGES_RESET });
  }

  if (putPagesSuccess) {
    dispatch(getAllPagesAction());
    setNotify({
      isOpen: true,
      message: "Pages Updated Successfully!",
      type: "success",
    });
    setOpenEditPopup(false);
    dispatch({ type: PUT_PAGES_RESET });
  }

  if (deletePagesSuccess) {
    dispatch(getAllPagesAction());
    setNotify({
      isOpen: true,
      message: "Page Deleted Successfully!",
      type: "success",
    });
    dispatch({ type: DELETE_PAGES_RESET });
  }

  useEffect(() => {
    if (!allPages) {
      dispatch(getAllPagesAction());
    }
    if (allPages) {
      setTableData(allPages?.data);
    }
  }, [dispatch, allPages]);

  useEffect(() => {
    dispatch(getAllPagesAction());
  }, [dispatch]);

  const {
    TableContainer,
    TblHead,
    TblPagination,
    tableDataAfterPagingAndSorting,
  } = useCustomTable(tableData, tableHeader, filterFn);

  const addPageHandler = () => {
    dispatch({ type: GET_SINGLE_PAGES_RESET });
    setOpenPopup(true);
  };

  const detailViewHandler = (id) => {
    dispatch(getSinglePagesAction(id));
    setOpenDetailPopup(true);
  };
  const updatePageHandler = (id) => {
    dispatch(getSinglePagesAction(id));
    setOpenEditPopup(true);
  };

  const deletePageHandler = (id) => {
    dispatch(deletePagesAction(id));
  };

  return (
    <>
      <CustomContainer>
        <Toolbar>
          <Topbar title="Manage Pages" />
          <Button
            variant="contained"
            startIcon={<Add />}
            style={{
              position: "absolute",
              right: "10px",
              backgroundColor: "#2a80f9",
            }}
            onClick={addPageHandler}
          >
            {" "}
            Add Pages
          </Button>
        </Toolbar>
        {loading ? (
          <LoadingComp />
        ) : (
          <>
            <TableContainer style={{ margin: "2vw 0.5vw", padding: "24px 0" }}>
              <TblHead />
              <TableBody>
                {tableDataAfterPagingAndSorting().map((item) => (
                  <ManagePagesTableCollapse
                    item={item}
                    key={item.unique_id}
                    detailViewHandler={detailViewHandler}
                    updatePageHandler={updatePageHandler}
                    deletePageHandler={deletePageHandler}
                  />
                ))}
              </TableBody>
            </TableContainer>
            <TblPagination />
          </>
        )}
      </CustomContainer>
      <Popup
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        title="Add Pages"
      >
        <ManagePagesForm
          singlePage={singlePage && singlePage?.data}
          setOpenPopup={setOpenPopup}
        />
      </Popup>
      <Popup
        openPopup={openEditPopup}
        setOpenPopup={setOpenEditPopup}
        title="Edit Pages"
      >
        <ManagePagesEditForm
          loadingEditForm={loadingEditForm}
          singlePage={singlePage && singlePage?.data}
          setOpenEditPopup={setOpenEditPopup}
        />
      </Popup>
      <Popup
        openPopup={openDetailPopup}
        setOpenPopup={setOpenDetailPopup}
        title="Detail View"
      >
        <ManagePagesDetail
          loadingEditForm={loadingEditForm}
          singlePage={singlePage && singlePage?.data}
          setOpenDetailPopup={setOpenDetailPopup}
        />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};

export default ManagePages;

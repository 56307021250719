import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import logowhite from "../front-end-images/logowhite.png";
import { Box, Fade, Modal } from "@mui/material";
import MainLoginComponent from "./MainLoginComponent";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "27px",
  boxShadow: 24,
  p: 4,
};

const UserFooter = () => {
  const [openLoginPopup, setOpenLoginPopup] = useState(false);
  const [selectedTab, setSelectedTab] = useState("signup");

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleOpen = () => setOpenLoginPopup(true);
  const handleClose = () => setOpenLoginPopup(false);
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="description" content="" />
        <meta name="author" content="" />

        <title>Million Deals</title>
        {/* <!-- Linking the font Poppins--> */}
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />

        {/* <!-- Linking Bootstrap Icon--> */}
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css"
        />
      </Helmet>
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <img alt="" src={logowhite} />
              <p>
                Million Deals is a private limited company, incorporated in 2021
                AD/ 2078 BS, operating from Lalitpur, Nepal.
              </p>
            </div>

            <div className="col-lg-4">
              <div className="row">
                <div className="col-lg-6">
                  <h2>Get Started</h2>
                  <ul>
                    <li>
                      <Link
                        onClick={handleOpen}
                        style={{ textDecoration: "none", color: "#ffffff" }}
                      >
                        Register as Enterprise
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={handleOpen}
                        style={{ textDecoration: "none", color: "#ffffff" }}
                      >
                        Register as Investor
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/deals-listing"
                        style={{ textDecoration: "none", color: "#ffffff" }}
                      >
                        Deal Listing
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/contact-us"
                        style={{ textDecoration: "none", color: "#ffffff" }}
                      >
                        Partner with us
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="col-lg-6">
                  <h2>Company</h2>
                  <ul>
                    <li>
                      <Link
                        to="/about-us"
                        style={{ textDecoration: "none", color: "#ffffff" }}
                      >
                        About us
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/our-teams"
                        style={{ textDecoration: "none", color: "#ffffff" }}
                      >
                        Our team
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/about-us"
                        style={{ textDecoration: "none", color: "#ffffff" }}
                      >
                        Work with us
                      </Link>
                    </li>
                    {/* <li>
                      <Link
                        style={{ textDecoration: "none", color: "#ffffff" }}
                      >
                        About Deals
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-3">
              <h2>Contact Us</h2>
              <p>Address: Lalitpur-12, Nepal</p>
              <p>
                {/* <i className="bi bi-telephone-fill"></i> 01-234567
                <br /> */}
                <i className="bi bi-envelope-fill"></i>{" "}
                <Link
                  style={{ textDecoration: "none", color: "#fff" }}
                  onClick={() =>
                    (window.location = "mailto:info@milliondeals.com.np")
                  }
                >
                  info@milliondeals.com.np
                </Link>
                <br />
                <i className="bi bi-globe2"></i>{" "}
                <a
                  href="https://milliondeals.com.np"
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    fontSize: "16px",
                    textDecoration: "none",
                    color: "#fff",
                  }}
                >
                  www.milliondeals.com.np
                </a>
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-credit">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <hr style={{ background: "#fff" }} />
            </div>

            <div className="col-lg-6">
              All rights reserved &copy; Million Deals
            </div>

            <div className="col-lg-6">
              <div className="footer-right">
                Website by - Improvement Company Nepal
              </div>
            </div>
          </div>
        </div>
      </div>
      <script src="vendor/jquery/jquery.slim.min.js"></script>
      <script src="vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
      <script src="vendor/jquery/custom.js"></script>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openLoginPopup}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={openLoginPopup}>
          <Box sx={style}>
            <MainLoginComponent
              selectedTab={selectedTab}
              handleTabChange={handleTabChange}
              handleClose={handleClose}
            />
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default UserFooter;

import {
  GET_ALL_DEAL_CATEGORY_FILTER_ADMIN_FAIL,
  GET_ALL_DEAL_CATEGORY_FILTER_ADMIN_REQUEST,
  GET_ALL_DEAL_CATEGORY_FILTER_ADMIN_RESET,
  GET_ALL_DEAL_CATEGORY_FILTER_ADMIN_SUCCESS,
  GET_ALL_MEMBERS_LIST_FAIL,
  GET_ALL_MEMBERS_LIST_REQUEST,
  GET_ALL_MEMBERS_LIST_RESET,
  GET_ALL_MEMBERS_LIST_SUCCESS,
  GET_ALL_SDG_FILTER_ADMIN_FAIL,
  GET_ALL_SDG_FILTER_ADMIN_REQUEST,
  GET_ALL_SDG_FILTER_ADMIN_RESET,
  GET_ALL_SDG_FILTER_ADMIN_SUCCESS,
  GET_ENTERPRISE_BUSSINESS_DOCS_ADMIN_FAIL,
  GET_ENTERPRISE_BUSSINESS_DOCS_ADMIN_REQUEST,
  GET_ENTERPRISE_BUSSINESS_DOCS_ADMIN_RESET,
  GET_ENTERPRISE_BUSSINESS_DOCS_ADMIN_SUCCESS,
  GET_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_FAIL,
  GET_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_REQUEST,
  GET_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_RESET,
  GET_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_SUCCESS,
  GET_INDIVIDUAL_MEMBERS_LIST_FAIL,
  GET_INDIVIDUAL_MEMBERS_LIST_REQUEST,
  GET_INDIVIDUAL_MEMBERS_LIST_RESET,
  GET_INDIVIDUAL_MEMBERS_LIST_SUCCESS,
  GET_MEMBER_PROFILES_FAIL,
  GET_MEMBER_PROFILES_REQUEST,
  GET_MEMBER_PROFILES_RESET,
  GET_MEMBER_PROFILES_SUCCESS,
  GET_MOBILIZATION_PLAN_DOCS_ADMIN_FAIL,
  GET_MOBILIZATION_PLAN_DOCS_ADMIN_REQUEST,
  GET_MOBILIZATION_PLAN_DOCS_ADMIN_RESET,
  GET_MOBILIZATION_PLAN_DOCS_ADMIN_SUCCESS,
  GET_PAN_OR_VAT_DOCS_ADMIN_FAIL,
  GET_PAN_OR_VAT_DOCS_ADMIN_REQUEST,
  GET_PAN_OR_VAT_DOCS_ADMIN_RESET,
  GET_PAN_OR_VAT_DOCS_ADMIN_SUCCESS,
  GET_REVENUE_PROFITABILITY_DOCS_ADMIN_FAIL,
  GET_REVENUE_PROFITABILITY_DOCS_ADMIN_REQUEST,
  GET_REVENUE_PROFITABILITY_DOCS_ADMIN_RESET,
  GET_REVENUE_PROFITABILITY_DOCS_ADMIN_SUCCESS,
  GET_SINGLE_MEMBER_PROFILE_LIST_FAIL,
  GET_SINGLE_MEMBER_PROFILE_LIST_REQUEST,
  GET_SINGLE_MEMBER_PROFILE_LIST_RESET,
  GET_SINGLE_MEMBER_PROFILE_LIST_SUCCESS,
  POST_ENTERPRISE_BUSSINESS_DOCS_ADMIN_FAIL,
  POST_ENTERPRISE_BUSSINESS_DOCS_ADMIN_REQUEST,
  POST_ENTERPRISE_BUSSINESS_DOCS_ADMIN_RESET,
  POST_ENTERPRISE_BUSSINESS_DOCS_ADMIN_SUCCESS,
  POST_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_FAIL,
  POST_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_REQUEST,
  POST_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_RESET,
  POST_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_SUCCESS,
  POST_MOBILIZATION_PLAN_DOCS_ADMIN_FAIL,
  POST_MOBILIZATION_PLAN_DOCS_ADMIN_REQUEST,
  POST_MOBILIZATION_PLAN_DOCS_ADMIN_RESET,
  POST_MOBILIZATION_PLAN_DOCS_ADMIN_SUCCESS,
  POST_PAN_OR_VAT_DOCS_ADMIN_FAIL,
  POST_PAN_OR_VAT_DOCS_ADMIN_REQUEST,
  POST_PAN_OR_VAT_DOCS_ADMIN_RESET,
  POST_PAN_OR_VAT_DOCS_ADMIN_SUCCESS,
  POST_REVENUE_PROFITABILITY_DOCS_ADMIN_FAIL,
  POST_REVENUE_PROFITABILITY_DOCS_ADMIN_REQUEST,
  POST_REVENUE_PROFITABILITY_DOCS_ADMIN_RESET,
  POST_REVENUE_PROFITABILITY_DOCS_ADMIN_SUCCESS,
  PUT_ENTERPRISE_BUSSINESS_DOCS_ADMIN_FAIL,
  PUT_ENTERPRISE_BUSSINESS_DOCS_ADMIN_REQUEST,
  PUT_ENTERPRISE_BUSSINESS_DOCS_ADMIN_RESET,
  PUT_ENTERPRISE_BUSSINESS_DOCS_ADMIN_SUCCESS,
  PUT_ENTERPRISE_KYC_FORM_ADMIN_FAIL,
  PUT_ENTERPRISE_KYC_FORM_ADMIN_REQUEST,
  PUT_ENTERPRISE_KYC_FORM_ADMIN_RESET,
  PUT_ENTERPRISE_KYC_FORM_ADMIN_SUCCESS,
  PUT_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_FAIL,
  PUT_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_REQUEST,
  PUT_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_RESET,
  PUT_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_SUCCESS,
  PUT_INVESTOR_KYC_FORM_ADMIN_FAIL,
  PUT_INVESTOR_KYC_FORM_ADMIN_REQUEST,
  PUT_INVESTOR_KYC_FORM_ADMIN_RESET,
  PUT_INVESTOR_KYC_FORM_ADMIN_SUCCESS,
  PUT_MOBILIZATION_PLAN_DOCS_ADMIN_FAIL,
  PUT_MOBILIZATION_PLAN_DOCS_ADMIN_REQUEST,
  PUT_MOBILIZATION_PLAN_DOCS_ADMIN_RESET,
  PUT_MOBILIZATION_PLAN_DOCS_ADMIN_SUCCESS,
  PUT_PAN_OR_VAT_DOCS_ADMIN_FAIL,
  PUT_PAN_OR_VAT_DOCS_ADMIN_REQUEST,
  PUT_PAN_OR_VAT_DOCS_ADMIN_RESET,
  PUT_PAN_OR_VAT_DOCS_ADMIN_SUCCESS,
  PUT_REVENUE_PROFITABILITY_DOCS_ADMIN_FAIL,
  PUT_REVENUE_PROFITABILITY_DOCS_ADMIN_REQUEST,
  PUT_REVENUE_PROFITABILITY_DOCS_ADMIN_RESET,
  PUT_REVENUE_PROFITABILITY_DOCS_ADMIN_SUCCESS,
  PUT_VERIFIED_MEMBERS_LIST_FAIL,
  PUT_VERIFIED_MEMBERS_LIST_REQUEST,
  PUT_VERIFIED_MEMBERS_LIST_RESET,
  PUT_VERIFIED_MEMBERS_LIST_SUCCESS,
} from "./AllMembersConstant";

export const getAllMembersReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_MEMBERS_LIST_REQUEST:
      return { loading: true };
    case GET_ALL_MEMBERS_LIST_SUCCESS:
      return { loading: false, allMembers: action.payload };
    case GET_ALL_MEMBERS_LIST_FAIL:
      return { loading: false, error: action.payload };
    case GET_ALL_MEMBERS_LIST_RESET:
      return {};
    default:
      return state;
  }
};

export const getIndividualMembersListReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_INDIVIDUAL_MEMBERS_LIST_REQUEST:
      return { loading: true };
    case GET_INDIVIDUAL_MEMBERS_LIST_SUCCESS:
      return { loading: false, individualMembers: action.payload };
    case GET_INDIVIDUAL_MEMBERS_LIST_FAIL:
      return { loading: false, error: action.payload };
    case GET_INDIVIDUAL_MEMBERS_LIST_RESET:
      return {};
    default:
      return state;
  }
};

export const putVerifiedMembersListReducer = (state = {}, action) => {
  switch (action.type) {
    case PUT_VERIFIED_MEMBERS_LIST_REQUEST:
      return { loading: true };
    case PUT_VERIFIED_MEMBERS_LIST_SUCCESS:
      return { loading: false, success: action.payload };
    case PUT_VERIFIED_MEMBERS_LIST_FAIL:
      return { loading: false, error: action.payload };
    case PUT_VERIFIED_MEMBERS_LIST_RESET:
      return {};
    default:
      return state;
  }
};

export const getSingleMemberProfileListReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_SINGLE_MEMBER_PROFILE_LIST_REQUEST:
      return { loading: true };
    case GET_SINGLE_MEMBER_PROFILE_LIST_SUCCESS:
      return { loading: false, singleMemberProfile: action.payload };
    case GET_SINGLE_MEMBER_PROFILE_LIST_FAIL:
      return { loading: false, error: action.payload };
    case GET_SINGLE_MEMBER_PROFILE_LIST_RESET:
      return {};
    default:
      return state;
  }
};

export const getMemberProfilesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_MEMBER_PROFILES_REQUEST:
      return { loading: true };
    case GET_MEMBER_PROFILES_SUCCESS:
      return { loading: false, memberProfiles: action.payload };
    case GET_MEMBER_PROFILES_FAIL:
      return { loading: false, error: action.payload };
    case GET_MEMBER_PROFILES_RESET:
      return {};
    default:
      return state;
  }
};

export const getEnterpriseBusinessPlanDocAdminReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case GET_ENTERPRISE_BUSSINESS_DOCS_ADMIN_REQUEST:
      return { loading: true };
    case GET_ENTERPRISE_BUSSINESS_DOCS_ADMIN_SUCCESS:
      return { loading: false, adminBusinessDoc: action.payload };
    case GET_ENTERPRISE_BUSSINESS_DOCS_ADMIN_FAIL:
      return { loading: false, error: action.payload };
    case GET_ENTERPRISE_BUSSINESS_DOCS_ADMIN_RESET:
      return {};
    default:
      return state;
  }
};

export const getEnterpriseRegistrationCompDocAdminReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case GET_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_REQUEST:
      return { loading: true };
    case GET_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_SUCCESS:
      return { loading: false, adminRegDoc: action.payload };
    case GET_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_FAIL:
      return { loading: false, error: action.payload };
    case GET_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_RESET:
      return {};
    default:
      return state;
  }
};

export const getEnterprisePanVatDocAdminReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PAN_OR_VAT_DOCS_ADMIN_REQUEST:
      return { loading: true };
    case GET_PAN_OR_VAT_DOCS_ADMIN_SUCCESS:
      return { loading: false, adminPanVatDoc: action.payload };
    case GET_PAN_OR_VAT_DOCS_ADMIN_FAIL:
      return { loading: false, error: action.payload };
    case GET_PAN_OR_VAT_DOCS_ADMIN_RESET:
      return {};
    default:
      return state;
  }
};

export const getEnterpriseMobilizationPlanDocAdminReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case GET_MOBILIZATION_PLAN_DOCS_ADMIN_REQUEST:
      return { loading: true };
    case GET_MOBILIZATION_PLAN_DOCS_ADMIN_SUCCESS:
      return { loading: false, adminMobilization: action.payload };
    case GET_MOBILIZATION_PLAN_DOCS_ADMIN_FAIL:
      return { loading: false, error: action.payload };
    case GET_MOBILIZATION_PLAN_DOCS_ADMIN_RESET:
      return {};
    default:
      return state;
  }
};

export const getEnterpriseRevenueProfitabilityDocAdminReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case GET_REVENUE_PROFITABILITY_DOCS_ADMIN_REQUEST:
      return { loading: true };
    case GET_REVENUE_PROFITABILITY_DOCS_ADMIN_SUCCESS:
      return { loading: false, adminRevenue: action.payload };
    case GET_REVENUE_PROFITABILITY_DOCS_ADMIN_FAIL:
      return { loading: false, error: action.payload };
    case GET_REVENUE_PROFITABILITY_DOCS_ADMIN_RESET:
      return {};
    default:
      return state;
  }
};

export const getCategoryListAdminReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_DEAL_CATEGORY_FILTER_ADMIN_REQUEST:
      return { loading: true };
    case GET_ALL_DEAL_CATEGORY_FILTER_ADMIN_SUCCESS:
      return { loading: false, categoryListAdmin: action.payload };
    case GET_ALL_DEAL_CATEGORY_FILTER_ADMIN_FAIL:
      return { loading: false, error: action.payload };
    case GET_ALL_DEAL_CATEGORY_FILTER_ADMIN_RESET:
      return {};
    default:
      return state;
  }
};

export const getSdgListAdminReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_SDG_FILTER_ADMIN_REQUEST:
      return { loading: true };
    case GET_ALL_SDG_FILTER_ADMIN_SUCCESS:
      return { loading: false, sdgListAdmin: action.payload };
    case GET_ALL_SDG_FILTER_ADMIN_FAIL:
      return { loading: false, error: action.payload };
    case GET_ALL_SDG_FILTER_ADMIN_RESET:
      return {};
    default:
      return state;
  }
};

export const putEnterpriseKycFormAdminReducer = (state = {}, action) => {
  switch (action.type) {
    case PUT_ENTERPRISE_KYC_FORM_ADMIN_REQUEST:
      return { loading: true };
    case PUT_ENTERPRISE_KYC_FORM_ADMIN_SUCCESS:
      return { loading: false, success: action.payload };
    case PUT_ENTERPRISE_KYC_FORM_ADMIN_FAIL:
      return { loading: false, error: action.payload };
    case PUT_ENTERPRISE_KYC_FORM_ADMIN_RESET:
      return {};
    default:
      return state;
  }
};

export const putInvestorKycFormAdminReducer = (state = {}, action) => {
  switch (action.type) {
    case PUT_INVESTOR_KYC_FORM_ADMIN_REQUEST:
      return { loading: true };
    case PUT_INVESTOR_KYC_FORM_ADMIN_SUCCESS:
      return { loading: false, success: action.payload };
    case PUT_INVESTOR_KYC_FORM_ADMIN_FAIL:
      return { loading: false, error: action.payload };
    case PUT_INVESTOR_KYC_FORM_ADMIN_RESET:
      return {};
    default:
      return state;
  }
};

export const putEnterpriseBusinessDocsAdminReducer = (state = {}, action) => {
  switch (action.type) {
    case PUT_ENTERPRISE_BUSSINESS_DOCS_ADMIN_REQUEST:
      return { loading: true };
    case PUT_ENTERPRISE_BUSSINESS_DOCS_ADMIN_SUCCESS:
      return { loading: false, success: action.payload };
    case PUT_ENTERPRISE_BUSSINESS_DOCS_ADMIN_FAIL:
      return { loading: false, error: action.payload };
    case PUT_ENTERPRISE_BUSSINESS_DOCS_ADMIN_RESET:
      return {};
    default:
      return state;
  }
};

export const putEnterpriseRegistrationDocsAdminReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case PUT_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_REQUEST:
      return { loading: true };
    case PUT_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_SUCCESS:
      return { loading: false, success: action.payload };
    case PUT_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_FAIL:
      return { loading: false, error: action.payload };
    case PUT_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_RESET:
      return {};
    default:
      return state;
  }
};

export const putEnterprisePanOrVatAdminReducer = (state = {}, action) => {
  switch (action.type) {
    case PUT_PAN_OR_VAT_DOCS_ADMIN_REQUEST:
      return { loading: true };
    case PUT_PAN_OR_VAT_DOCS_ADMIN_SUCCESS:
      return { loading: false, success: action.payload };
    case PUT_PAN_OR_VAT_DOCS_ADMIN_FAIL:
      return { loading: false, error: action.payload };
    case PUT_PAN_OR_VAT_DOCS_ADMIN_RESET:
      return {};
    default:
      return state;
  }
};

export const putEnterpriseMobilizationDocsAdminReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case PUT_MOBILIZATION_PLAN_DOCS_ADMIN_REQUEST:
      return { loading: true };
    case PUT_MOBILIZATION_PLAN_DOCS_ADMIN_SUCCESS:
      return { loading: false, success: action.payload };
    case PUT_MOBILIZATION_PLAN_DOCS_ADMIN_FAIL:
      return { loading: false, error: action.payload };
    case PUT_MOBILIZATION_PLAN_DOCS_ADMIN_RESET:
      return {};
    default:
      return state;
  }
};

export const putEnterpriseProfitablityDocsAdminReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case PUT_REVENUE_PROFITABILITY_DOCS_ADMIN_REQUEST:
      return { loading: true };
    case PUT_REVENUE_PROFITABILITY_DOCS_ADMIN_SUCCESS:
      return { loading: false, success: action.payload };
    case PUT_REVENUE_PROFITABILITY_DOCS_ADMIN_FAIL:
      return { loading: false, error: action.payload };
    case PUT_REVENUE_PROFITABILITY_DOCS_ADMIN_RESET:
      return {};
    default:
      return state;
  }
};

export const postEnterpriseBusinessDocsAdminReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_ENTERPRISE_BUSSINESS_DOCS_ADMIN_REQUEST:
      return { loading: true };
    case POST_ENTERPRISE_BUSSINESS_DOCS_ADMIN_SUCCESS:
      return { loading: false, success: action.payload };
    case POST_ENTERPRISE_BUSSINESS_DOCS_ADMIN_FAIL:
      return { loading: false, error: action.payload };
    case POST_ENTERPRISE_BUSSINESS_DOCS_ADMIN_RESET:
      return {};
    default:
      return state;
  }
};

export const postEnterpriseRegistrationDocsAdminReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case POST_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_REQUEST:
      return { loading: true };
    case POST_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_SUCCESS:
      return { loading: false, success: action.payload };
    case POST_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_FAIL:
      return { loading: false, error: action.payload };
    case POST_ENTERPRISE_REGISTRATION_COMP_DOCS_ADMIN_RESET:
      return {};
    default:
      return state;
  }
};

export const postEnterprisePanOrVatAdminReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_PAN_OR_VAT_DOCS_ADMIN_REQUEST:
      return { loading: true };
    case POST_PAN_OR_VAT_DOCS_ADMIN_SUCCESS:
      return { loading: false, success: action.payload };
    case POST_PAN_OR_VAT_DOCS_ADMIN_FAIL:
      return { loading: false, error: action.payload };
    case POST_PAN_OR_VAT_DOCS_ADMIN_RESET:
      return {};
    default:
      return state;
  }
};

export const postEnterpriseMobilizationDocsAdminReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case POST_MOBILIZATION_PLAN_DOCS_ADMIN_REQUEST:
      return { loading: true };
    case POST_MOBILIZATION_PLAN_DOCS_ADMIN_SUCCESS:
      return { loading: false, success: action.payload };
    case POST_MOBILIZATION_PLAN_DOCS_ADMIN_FAIL:
      return { loading: false, error: action.payload };
    case POST_MOBILIZATION_PLAN_DOCS_ADMIN_RESET:
      return {};
    default:
      return state;
  }
};

export const postEnterpriseProfitablityDocsAdminReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case POST_REVENUE_PROFITABILITY_DOCS_ADMIN_REQUEST:
      return { loading: true };
    case POST_REVENUE_PROFITABILITY_DOCS_ADMIN_SUCCESS:
      return { loading: false, success: action.payload };
    case POST_REVENUE_PROFITABILITY_DOCS_ADMIN_FAIL:
      return { loading: false, error: action.payload };
    case POST_REVENUE_PROFITABILITY_DOCS_ADMIN_RESET:
      return {};
    default:
      return state;
  }
};

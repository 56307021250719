export const GET_ABOUT_COMPANY_PUBLIC_REQUEST =
  "GET_ABOUT_COMPANY_PUBLIC_REQUEST";
export const GET_ABOUT_COMPANY_PUBLIC_SUCCESS =
  "GET_ABOUT_COMPANY_PUBLIC_SUCCESS";
export const GET_ABOUT_COMPANY_PUBLIC_FAIL = "GET_ABOUT_COMPANY_PUBLIC_FAIL";
export const GET_ABOUT_COMPANY_PUBLIC_RESET = "GET_ABOUT_COMPANY_PUBLIC_RESET";

export const GET_WORK_WITH_US_PUBLIC_REQUEST =
  "GET_WORK_WITH_US_PUBLIC_REQUEST";
export const GET_WORK_WITH_US_PUBLIC_SUCCESS =
  "GET_WORK_WITH_US_PUBLIC_SUCCESS";
export const GET_WORK_WITH_US_PUBLIC_FAIL = "GET_WORK_WITH_US_PUBLIC_FAIL";
export const GET_WORK_WITH_US_PUBLIC_RESET = "GET_WORK_WITH_US_PUBLIC_RESET";

export const GET_TEAMS_PUBLIC_REQUEST = "GET_TEAMS_PUBLIC_REQUEST";
export const GET_TEAMS_PUBLIC_SUCCESS = "GET_TEAMS_PUBLIC_SUCCESS";
export const GET_TEAMS_PUBLIC_FAIL = "GET_TEAMS_PUBLIC_FAIL";
export const GET_TEAMS_PUBLIC_RESET = "GET_TEAMS_PUBLIC_RESET";

export const GET_SINGLE_TEAMS_PUBLIC_REQUEST =
  "GET_SINGLE_TEAMS_PUBLIC_REQUEST";
export const GET_SINGLE_TEAMS_PUBLIC_SUCCESS =
  "GET_SINGLE_TEAMS_PUBLIC_SUCCESS";
export const GET_SINGLE_TEAMS_PUBLIC_FAIL = "GET_SINGLE_TEAMS_PUBLIC_FAIL";
export const GET_SINGLE_TEAMS_PUBLIC_RESET = "GET_SINGLE_TEAMS_PUBLIC_RESET";

export const GET_ADVISORS_PUBLIC_REQUEST = "GET_ADVISORS_PUBLIC_REQUEST";
export const GET_ADVISORS_PUBLIC_SUCCESS = "GET_ADVISORS_PUBLIC_SUCCESS";
export const GET_ADVISORS_PUBLIC_FAIL = "GET_ADVISORS_PUBLIC_FAIL";
export const GET_ADVISORS_PUBLIC_RESET = "GET_ADVISORS_PUBLIC_RESET";

export const GET_SINGLE_ADVISOR_PUBLIC_REQUEST =
  "GET_SINGLE_ADVISOR_PUBLIC_REQUEST";
export const GET_SINGLE_ADVISOR_PUBLIC_SUCCESS =
  "GET_SINGLE_ADVISOR_PUBLIC_SUCCESS";
export const GET_SINGLE_ADVISOR_PUBLIC_FAIL = "GET_SINGLE_ADVISOR_PUBLIC_FAIL";
export const GET_SINGLE_ADVISOR_PUBLIC_RESET =
  "GET_SINGLE_ADVISOR_PUBLIC_RESET";

export const GET_PARTNERS_PUBLIC_REQUEST = "GET_PARTNERS_PUBLIC_REQUEST";
export const GET_PARTNERS_PUBLIC_SUCCESS = "GET_PARTNERS_PUBLIC_SUCCESS";
export const GET_PARTNERS_PUBLIC_FAIL = "GET_PARTNERS_PUBLIC_FAIL";
export const GET_PARTNERS_PUBLIC_RESET = "GET_PARTNERS_PUBLIC_RESET";

export const GET_SINGLE_PARTNER_PUBLIC_REQUEST =
  "GET_SINGLE_PARTNER_PUBLIC_REQUEST";
export const GET_SINGLE_PARTNER_PUBLIC_SUCCESS =
  "GET_SINGLE_PARTNER_PUBLIC_SUCCESS";
export const GET_SINGLE_PARTNER_PUBLIC_FAIL = "GET_SINGLE_PARTNER_PUBLIC_FAIL";
export const GET_SINGLE_PARTNER_PUBLIC_RESET =
  "GET_SINGLE_PARTNER_PUBLIC_RESET";

export const GET_ALL_SUCCESS_STORIES_PUBLIC_REQUEST =
  "GET_ALL_SUCCESS_STORIES_PUBLIC_REQUEST";
export const GET_ALL_SUCCESS_STORIES_PUBLIC_SUCCESS =
  "GET_ALL_SUCCESS_STORIES_PUBLIC_SUCCESS";
export const GET_ALL_SUCCESS_STORIES_PUBLIC_FAIL =
  "GET_ALL_SUCCESS_STORIES_PUBLIC_FAIL";
export const GET_ALL_SUCCESS_STORIES_PUBLIC_RESET =
  "GET_ALL_SUCCESS_STORIES_PUBLIC_RESET";

export const GET_ALL_BLOGS_PUBLIC_REQUEST = "GET_ALL_BLOGS_PUBLIC_REQUEST";
export const GET_ALL_BLOGS_PUBLIC_SUCCESS = "GET_ALL_BLOGS_PUBLIC_SUCCESS";
export const GET_ALL_BLOGS_PUBLIC_FAIL = "GET_ALL_BLOGS_PUBLIC_FAIL";
export const GET_ALL_BLOGS_PUBLIC_RESET = "GET_ALL_BLOGS_PUBLIC_RESET";

export const GET_BLOGS_SLUG_PUBLIC_REQUEST = "GET_BLOGS_SLUG_PUBLIC_REQUEST";
export const GET_BLOGS_SLUG_PUBLIC_SUCCESS = "GET_BLOGS_SLUG_PUBLIC_SUCCESS";
export const GET_BLOGS_SLUG_PUBLIC_FAIL = "GET_BLOGS_SLUG_PUBLIC_FAIL";
export const GET_BLOGS_SLUG_PUBLIC_RESET = "GET_BLOGS_SLUG_PUBLIC_RESET";

export const GET_ALL_NEWS_PUBLIC_REQUEST = "GET_ALL_NEWS_PUBLIC_REQUEST";
export const GET_ALL_NEWS_PUBLIC_SUCCESS = "GET_ALL_NEWS_PUBLIC_SUCCESS";
export const GET_ALL_NEWS_PUBLIC_FAIL = "GET_ALL_NEWS_PUBLIC_FAIL";
export const GET_ALL_NEWS_PUBLIC_RESET = "GET_ALL_NEWS_PUBLIC_RESET";

export const GET_NEWS_SLUG_PUBLIC_REQUEST = "GET_NEWS_SLUG_PUBLIC_REQUEST";
export const GET_NEWS_SLUG_PUBLIC_SUCCESS = "GET_NEWS_SLUG_PUBLIC_SUCCESS";
export const GET_NEWS_SLUG_PUBLIC_FAIL = "GET_NEWS_SLUG_PUBLIC_FAIL";
export const GET_NEWS_SLUG_PUBLIC_RESET = "GET_NEWS_SLUG_PUBLIC_RESET";

export const GET_ALL_INVESTORS_FAQS_PUBLIC_REQUEST =
  "GET_ALL_INVESTORS_FAQS_PUBLIC_REQUEST";
export const GET_ALL_INVESTORS_FAQS_PUBLIC_SUCCESS =
  "GET_ALL_INVESTORS_FAQS_PUBLIC_SUCCESS";
export const GET_ALL_INVESTORS_FAQS_PUBLIC_FAIL =
  "GET_ALL_INVESTORS_FAQS_PUBLIC_FAIL";
export const GET_ALL_INVESTORS_FAQS_PUBLIC_RESET =
  "GET_ALL_INVESTORS_FAQS_PUBLIC_RESET";

export const GET_ALL_ENTERPRISE_FAQS_PUBLIC_REQUEST =
  "GET_ALL_ENTERPRISE_FAQS_PUBLIC_REQUEST";
export const GET_ALL_ENTERPRISE_FAQS_PUBLIC_SUCCESS =
  "GET_ALL_ENTERPRISE_FAQS_PUBLIC_SUCCESS";
export const GET_ALL_ENTERPRISE_FAQS_PUBLIC_FAIL =
  "GET_ALL_ENTERPRISE_FAQS_PUBLIC_FAIL";
export const GET_ALL_ENTERPRISE_FAQS_PUBLIC_RESET =
  "GET_ALL_ENTERPRISE_FAQS_PUBLIC_RESET";

export const GET_ALL_CONTACT_DETAILS_PUBLIC_REQUEST =
  "GET_ALL_CONTACT_DETAILS_PUBLIC_REQUEST";
export const GET_ALL_CONTACT_DETAILS_PUBLIC_SUCCESS =
  "GET_ALL_CONTACT_DETAILS_PUBLIC_SUCCESS";
export const GET_ALL_CONTACT_DETAILS_PUBLIC_FAIL =
  "GET_ALL_CONTACT_DETAILS_PUBLIC_FAIL";
export const GET_ALL_CONTACT_DETAILS_PUBLIC_RESET =
  "GET_ALL_CONTACT_DETAILS_PUBLIC_RESET";

export const POST_CONTACT_FORM_PUBLIC_REQUEST =
  "GET_ALL_CONTACT_DETAILS_PUBLIC_REQUEST";
export const POST_CONTACT_FORM_PUBLIC_SUCCESS =
  "GET_ALL_CONTACT_DETAILS_PUBLIC_SUCCESS";
export const POST_CONTACT_FORM_PUBLIC_FAIL =
  "GET_ALL_CONTACT_DETAILS_PUBLIC_FAIL";
export const POST_CONTACT_FORM_PUBLIC_RESET =
  "GET_ALL_CONTACT_DETAILS_PUBLIC_RESET";

export const GET_ALL_DEALS_PUBLIC_REQUEST = "GET_ALL_DEALS_PUBLIC_REQUEST";
export const GET_ALL_DEALS_PUBLIC_SUCCESS = "GET_ALL_DEALS_PUBLIC_SUCCESS";
export const GET_ALL_DEALS_PUBLIC_FAIL = "GET_ALL_DEALS_PUBLIC_FAIL";
export const GET_ALL_DEALS_PUBLIC_RESET = "GET_ALL_DEALS_PUBLIC_RESET";

export const GET_SINGLE_DEALS_PUBLIC_REQUEST =
  "GET_SINGLE_DEALS_PUBLIC_REQUEST";
export const GET_SINGLE_DEALS_PUBLIC_SUCCESS =
  "GET_SINGLE_DEALS_PUBLIC_SUCCESS";
export const GET_SINGLE_DEALS_PUBLIC_FAIL = "GET_SINGLE_DEALS_PUBLIC_FAIL";
export const GET_SINGLE_DEALS_PUBLIC_RESET = "GET_SINGLE_DEALS_PUBLIC_RESET";

export const GET_ALL_DEAL_CATEGORY_PUBLIC_REQUEST =
  "GET_ALL_DEAL_CATEGORY_PUBLIC_REQUEST";
export const GET_ALL_DEAL_CATEGORY_PUBLIC_SUCCESS =
  "GET_ALL_DEAL_CATEGORY_PUBLIC_SUCCESS";
export const GET_ALL_DEAL_CATEGORY_PUBLIC_FAIL =
  "GET_ALL_DEAL_CATEGORY_PUBLIC_FAIL";
export const GET_ALL_DEAL_CATEGORY_PUBLIC_RESET =
  "GET_ALL_DEAL_CATEGORY_PUBLIC_RESET";

export const POST_VERIFY_MEMBER_REQUEST = "POST_VERIFY_MEMBER_REQUEST";
export const POST_VERIFY_MEMBER_SUCCESS = "POST_VERIFY_MEMBER_SUCCESS";
export const POST_VERIFY_MEMBER_FAIL = "POST_VERIFY_MEMBER_FAIL";
export const POST_VERIFY_MEMBER_RESET = "POST_VERIFY_MEMBER_RESET";

import {
  API_URL,
  axiosAdminPrivate,
  tokenConfig,
  tokenConfigForm,
} from "../../../api/axios";
import {
  DELETE_PARTNERS_FAIL,
  DELETE_PARTNERS_REQUEST,
  DELETE_PARTNERS_SUCCESS,
  GET_ALL_PARTNERS_FAIL,
  GET_ALL_PARTNERS_REQUEST,
  GET_ALL_PARTNERS_SUCCESS,
  GET_SINGLE_PARTNERS_FAIL,
  GET_SINGLE_PARTNERS_REQUEST,
  GET_SINGLE_PARTNERS_SUCCESS,
  POST_PARTNERS_FAIL,
  POST_PARTNERS_REQUEST,
  POST_PARTNERS_SUCCESS,
  PUT_PARTNERS_FAIL,
  PUT_PARTNERS_REQUEST,
  PUT_PARTNERS_SUCCESS,
} from "./PartnersConstants";

export const getAllPartnerAction = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_PARTNERS_REQUEST });

    const { data } = await axiosAdminPrivate.get(
      `${API_URL}/api/v1/admin/about/partners`
    );

    dispatch({ type: GET_ALL_PARTNERS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_ALL_PARTNERS_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

export const getSinglePartnerAction = (unique_id) => async (dispatch) => {
  try {
    dispatch({ type: GET_SINGLE_PARTNERS_REQUEST });

    const { data } = await axiosAdminPrivate.get(
      `${API_URL}/api/v1/admin/about/partners/${unique_id}`
    );

    dispatch({ type: GET_SINGLE_PARTNERS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_SINGLE_PARTNERS_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

export const postPartnerAction = (addPartner, image) => async (dispatch) => {
  try {
    dispatch({ type: POST_PARTNERS_REQUEST });

    const formData = new FormData();
    formData.append("partner_image", image);
    formData.append("company_name", addPartner?.company_name);
    formData.append("description", addPartner?.description);

    const { data } = await axiosAdminPrivate.post(
      `${API_URL}/api/v1/admin/about/partners`,
      formData
    );

    dispatch({ type: POST_PARTNERS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: POST_PARTNERS_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

export const putPartnerAction =
  (unique_id, updatePartner, image) => async (dispatch) => {
    try {
      dispatch({ type: PUT_PARTNERS_REQUEST });

      const formData = new FormData();
      formData.append("partner_image", image);
      formData.append("company_name", updatePartner?.company_name);
      formData.append("description", updatePartner?.description);

      const { data } = await axiosAdminPrivate.put(
        `${API_URL}/api/v1/admin/about/partners/${unique_id}`,
        formData
      );

      dispatch({ type: PUT_PARTNERS_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: PUT_PARTNERS_FAIL,
        payload: error.message ? error.message : error.Message,
      });
    }
  };

export const deletePartnerAction = (unique_id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_PARTNERS_REQUEST });

    const { data } = await axiosAdminPrivate.delete(
      `${API_URL}/api/v1/admin/about/partners/${unique_id}`,
      tokenConfig()
    );

    dispatch({ type: DELETE_PARTNERS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: DELETE_PARTNERS_FAIL,
      payload: error.message ? error.message : error.Message,
    });
  }
};

import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import LoadingComp from "../../components/LoadingComp";
import { API_URL } from "../../api/axios";
import { Helmet } from "react-helmet";
import UserFooter from "../components/UserFooter";
import UserHeader from "../components/UserHeader";
import { Link, useNavigate } from "react-router-dom";
import "../../styles/style.css";
import {
  getAdvisorPublicAction,
  getAllAboutPublicAction,
  getAllPartnerPublicAction,
  getTeamsPublicAction,
  getWorkWithUsPublicAction,
} from "../public-routes/PublicRoutesActions";
import ArrowButton from "../components/ArrowButton";
import { Tooltip } from "@mui/material";

const AboutUsPagePublic = () => {
  const [aboutValues, setAboutValaues] = useState({});
  const [aboutWork, setAboutWork] = useState({});
  const [aboutTeams, setAboutTeams] = useState([]);
  const [aboutAdvisor, setAboutAdvisor] = useState([]);
  const [aboutPartner, setAboutPartner] = useState([]);
  const [activeProvider, setActiveProvider] = useState("");

  const providerRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { allAboutPublic, loading } = useSelector(
    (state) => state.getAboutCompanyPublic
  );

  const { allWorkwithusPublic } = useSelector(
    (state) => state.getWorkWithUsPublic
  );

  const { allTeamsPublic } = useSelector((state) => state.getTeamsPublic);

  const { allAdvisorsPublic } = useSelector((state) => state.getAdvisorsPublic);

  const { allPartnersPublic } = useSelector((state) => state.getPartnersPublic);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    function handleScroll() {
      if (providerRef.current) {
        const providerPosition = providerRef.current.getBoundingClientRect();

        if (
          providerPosition.top >= 0 &&
          providerPosition.bottom <= window.innerHeight
        ) {
          // the provider section is in view
          return;
        }
      }

      setActiveProvider("");
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!allAboutPublic) {
      dispatch(getAllAboutPublicAction());
    }
    if (allAboutPublic) {
      setAboutValaues(allAboutPublic?.data);
    }
  }, [dispatch, allAboutPublic]);

  useEffect(() => {
    if (!allWorkwithusPublic) {
      dispatch(getWorkWithUsPublicAction());
    }
    if (allWorkwithusPublic) {
      setAboutWork(allWorkwithusPublic?.data);
    }
  }, [dispatch, allWorkwithusPublic]);

  useEffect(() => {
    if (!allTeamsPublic) {
      dispatch(getTeamsPublicAction());
    }
    if (allTeamsPublic) {
      setAboutTeams(allTeamsPublic?.data);
    }
  }, [dispatch, allTeamsPublic]);

  useEffect(() => {
    if (!allAdvisorsPublic) {
      dispatch(getAdvisorPublicAction());
    }
    if (allAdvisorsPublic) {
      setAboutAdvisor(allAdvisorsPublic?.data);
    }
  }, [dispatch, allAdvisorsPublic]);

  useEffect(() => {
    if (!allPartnersPublic) {
      dispatch(getAllPartnerPublicAction());
    }
    if (allPartnersPublic) {
      setAboutPartner(allPartnersPublic?.data);
    }
  }, [dispatch, allPartnersPublic]);

  const navigateOurTeamPage = (e, index) => {
    e.preventDefault();
    setTimeout(() => {
      navigate(`/our-teams/${index}`);
    }, 400);
  };

  useEffect(() => {
    dispatch(getAllAboutPublicAction());
    dispatch(getWorkWithUsPublicAction());
    dispatch(getTeamsPublicAction());
    dispatch(getAdvisorPublicAction());
    dispatch(getAllPartnerPublicAction());
  }, [dispatch]);

  return (
    <>
      <UserHeader />
      <Helmet>
        <meta charset="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="description" content="" />
        <meta name="author" content="" />

        <title>Million Deals</title>
        {/* <!-- Linking the font Poppins--> */}
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />

        {/* <!-- Linking Bootstrap Icon--> */}
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css"
        />
      </Helmet>
      <ArrowButton />
      <div className="page-title">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h1>About Us</h1>
              <Link className="link" to="/">
                Home
              </Link>
              {""} / About Us
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <LoadingComp />
      ) : (
        <>
          <div className="about">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  {aboutValues?.about_image && aboutValues?.about_image ? (
                    <img
                      alt=""
                      src={`${API_URL}/uploads/about/${aboutValues?.about_image}`}
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-6">
                  <h3 className="section-subtitle">{aboutValues?.title} </h3>
                  {/* <h2 className="section-title">
                    All {allAboutPublic?.message}
                  </h2> */}

                  <p
                    className="section-text-about"
                    dangerouslySetInnerHTML={{
                      __html: aboutValues?.description,
                    }}
                  ></p>
                </div>
              </div>
            </div>
          </div>
          <div className="about">
            <div className="container">
              <div className="row">
                <div className="col-md-7">
                  <h3 className="section-subtitle">Our Mission</h3>
                </div>

                <span style={{ marginLeft: "5px", textAlign: "justify" }}>
                  Our mission at Million Deals is to unlock the full potential
                  of Nepali entrepreneurship and investment ecosystem by
                  connecting high potential enterprises with reputable investors
                  and experts.
                </span>
                <br />
                <span style={{ marginLeft: "5px", textAlign: "justify" }}>
                  We know that access to capital and expertise is critical to
                  the success of any business, we also know that an economy fit
                  for the future needs innovation, sustainability and growth. By
                  connecting Nepali entrepreneurs to the resources they need to
                  grow and thrive, we aim to create a thriving community of
                  businesses and investors that contribute to Nepal's economic
                  development, prosperity and sustainability goals. We strive to
                  be a catalyst for positive change, and to drive innovation and
                  progress in the Nepali economic landscape.
                </span>
              </div>
            </div>
          </div>
          <div className="about">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <h3 className="section-subtitle">{aboutWork?.title}</h3>
                  {/* <h2 className="section-title">
                    {" "}
                    {allWorkwithusPublic?.message
                      ? "Reason Working With Us"
                      : ""}
                  </h2> */}
                  <p
                    style={{ textAlign: "justify" }}
                    className="section-text"
                    dangerouslySetInnerHTML={{
                      __html: aboutWork?.description,
                    }}
                  ></p>
                  <div>
                    <button
                      className={`dark-btn ${
                        activeProvider === "1" ? "active-btn" : ""
                      }`}
                      style={{ marginTop: "2%" }}
                      onClick={() => setActiveProvider("1")}
                    >
                      I am an Enterprise
                    </button>
                    &nbsp;
                    <button
                      className={`dark-btn ${
                        activeProvider === "2" ? "active-btn" : ""
                      }`}
                      onClick={() => setActiveProvider("2")}
                    >
                      I am an Investor
                    </button>
                  </div>
                </div>
                <div className="col-md-6">
                  {aboutWork?.image && aboutWork?.image ? (
                    <img
                      alt=""
                      src={`${API_URL}/uploads/work_with_us/${aboutWork?.image}`}
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          {activeProvider === "1" && (
            <div ref={providerRef}>
              <div className="home-listings-join_us">
                <div className="container">
                  <div className="row">
                    <div className="col-md-7">
                      <h3 className="section-subtitle">I am an Enterprise</h3>
                    </div>

                    <span style={{ marginLeft: "5px", textAlign: "justify" }}>
                      If you are an entrepreneur, by joining Million Deals you
                      can have access to a wide range of resources and support
                      to help your business grow and thrive. Our team of
                      experienced business consultants will offer you customized
                      solutions for financial management, market analysis, and
                      strategy development, etc. to help you navigate the
                      challenges of growing a business. You will also have
                      access to a pool of verified investors who are committed
                      to supporting the growth and development of Nepali
                      businesses. By joining us, you can be confident in the
                      knowledge that you are part of a community that is
                      committed to your success.
                      <br />
                      <br />
                      <Link
                        to="/enterprise-only"
                        style={{
                          textDecoration: "none",
                          color: "#ffffff",
                          width: "28%",
                        }}
                        className="dark-btn"
                      >
                        Find out more about our process
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
          {activeProvider === "2" && (
            <div ref={providerRef}>
              <div className="home-listings-join_us">
                <div className="container">
                  <div className="row">
                    <div className="col-md-7">
                      <h3 className="section-subtitle">I am an Investor</h3>
                    </div>

                    <span style={{ marginLeft: "5px", textAlign: "justify" }}>
                      If you are an investor, by joining Million Deals you can
                      have access to a pool of verified investment opportunities
                      in sectors, geographies and ticket sizes that work for
                      you. By joining our platform you can access the necessary
                      due diligence information to make informed investment
                      decisions. We can also offer you additional bespoke
                      services in your deal-making process. We are here to help
                      you access the right investment opportunities and the
                      right information as well as to help you navigate the
                      landscape. By joining us, you can also connect to a
                      community of like-minded stakeholders committed to driving
                      the economic growth and sustainable development of Nepal.
                      <br />
                      <br />
                      <Link
                        to="/investor-only"
                        style={{
                          textDecoration: "none",
                          color: "#ffffff",
                          width: "28%",
                        }}
                        className="dark-btn"
                      >
                        Find out more about our process
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="team">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <h2 className="section-title">Meet Our Team </h2>
                </div>
              </div>
              <div className="row pt-3 justify-content-center">
                {aboutTeams.slice(0, 3).map((item, index) => (
                  <div
                    key={item.team_id}
                    className="col-md-6 col-lg-6 col-xl-3"
                  >
                    <div
                      className="team-single-about-us"
                      onClick={(e) => navigateOurTeamPage(e, index)}
                    >
                      {item.image ? (
                        <img
                          alt=""
                          src={`${API_URL}/uploads/teams/${item.image}`}
                        />
                      ) : (
                        <img
                          alt=""
                          src="https://i.postimg.cc/vZvJdnRS/blank-head-profile-pic-for-a-man.jpg"
                        />
                      )}

                      <h3>{item?.name}</h3>
                      <h5>{item?.designation}</h5>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <br />
          <br /> <br />
          <br />
          <div className="team">
            {aboutAdvisor?.length > 0 && (
              <div className="container">
                <div className="row">
                  <div className="col-md-12 text-center">
                    <h2 className="section-title">Our Advisors </h2>
                  </div>
                </div>
                <div className="row pt-3 justify-content-center">
                  {aboutAdvisor.slice(0, 4).map((item) => (
                    <div
                      className="col-md-6 col-lg-6 col-xl-3"
                      key={item.advisor_id}
                    >
                      <div className="team-single">
                        {item.image ? (
                          <img
                            alt=""
                            src={`${API_URL}/uploads/advisors/${item.image}`}
                          />
                        ) : (
                          <img
                            alt=""
                            src="https://i.postimg.cc/vZvJdnRS/blank-head-profile-pic-for-a-man.jpg"
                          />
                        )}
                        <h3>{item?.name}</h3>
                        <Tooltip title={item?.designation}>
                          <h5>
                            {item?.designation.length > 15
                              ? item?.designation.slice(0, 15) + "..."
                              : item?.designation}
                          </h5>
                        </Tooltip>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className="partner">
            {aboutPartner?.length > 0 && (
              <div className="container">
                <div className="row">
                  <div className="col-md-12 text-center">
                    <h2 className="section-title">Our Partners</h2>
                  </div>
                </div>
                <div className="row pt-4 justify-content-center">
                  {aboutPartner.slice(0, 6).map((item) => (
                    <div
                      className="col-md-6 col-lg-6 col-xl-3"
                      key={item.partner_id}
                    >
                      <div className="partner-single">
                        {item.logo_image ? (
                          <img
                            alt=""
                            src={`${API_URL}/uploads/partners/${item.logo_image}`}
                            style={{ aspectRatio: "2/1", borderRadius: "9%" }}
                          />
                        ) : (
                          <img
                            alt=""
                            style={{ aspectRatio: "2/1", borderRadius: "9%" }}
                            src="https://i.postimg.cc/vZvJdnRS/blank-head-profile-pic-for-a-man.jpg"
                          />
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </>
      )}
      <UserFooter />
    </>
  );
};

export default AboutUsPagePublic;

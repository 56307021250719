import React, { useState } from "react";
import { Button, Grid, TextField } from "@mui/material";
import { Form } from "../../components/hooks/useForm";
import { useDispatch } from "react-redux";
import CheckBoxControl from "../../components/controls/CheckBoxControl";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { postBlogsAction } from "./BlogsActions";
import { API_URL } from "../../api/axios";

const BlogsAddForm = ({ singleBlogs, setOpenPopup }) => {
  const [blogName, setBlogName] = useState("");
  const [blogDescription, setBlogDescription] = useState("");
  const [blogSlug, setBlogSlug] = useState("");
  const [status, setStatus] = useState(0);
  const [image, setImage] = useState(null);
  const [imgSrc, setImgSrc] = useState(null);

  const dispatch = useDispatch();

  const initialFormValues = {
    blog_image: image,
    blog_name: blogName,
    blog_slug: blogSlug,
    blog_description: blogDescription,
    status: status,
  };

  const handleImage = (event) => {
    let imageFile = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (x) => {
      setImgSrc(x.target.result);
    };
    reader.readAsDataURL(imageFile);
    setImage(event.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(postBlogsAction(initialFormValues, image));
    setOpenPopup(false);
  };
  return (
    <Form onSubmit={handleSubmit}>
      <Grid container spacing={2} style={{ fontSize: "12px" }}>
        <Grid item xs={12}>
          <TextField
            name="blog_name"
            label="Blog Name"
            style={{ width: "100%" }}
            onChange={(e) => setBlogName(e.target.value)}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <div className="ckeditor-container" tabIndex="0">
            <CKEditor
              editor={ClassicEditor}
              id="blog_description"
              name="blog_description"
              config={{
                link: {
                  defaultProtocol: "https://",
                  addTargetToExternalLinks: true,
                  decorators: [
                    {
                      attributes: {
                        target: "_blank",
                        rel: "noopener noreferrer",
                      },
                    },
                  ],
                },
              }}
              onReady={(editor) => {
                editor.setData(initialFormValues.blog_description);
              }}
              onChange={(event, editor) => setBlogDescription(editor.getData())}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <CheckBoxControl
            name="status"
            label="Active"
            onChange={(e) => setStatus(e.target.value ? 1 : 0)}
          />
        </Grid>

        <Grid item xs={12}>
          <input
            type="file"
            name="blog_image"
            style={{ width: "100%" }}
            onChange={handleImage}
          />{" "}
          <br />
          <br />
          {imgSrc && imgSrc ? (
            <img
              alt=""
              src={
                imgSrc
                  ? imgSrc
                  : singleBlogs &&
                    `${API_URL}/uploads/blogs/${singleBlogs?.blog_image}`
              }
              height={150}
              width={150}
            />
          ) : (
            <span style={{ color: "red" }}>*Choose Image</span>
          )}
        </Grid>
      </Grid>

      <div
        style={{
          display: "flex",
          justifyContent: "end",
          paddingTop: "10px",
          marginTop: "10px",
          borderTop: "1px solid #0b6885",
        }}
      >
        <Button
          variant="contained"
          onClick={() => setOpenPopup(false)}
          style={{ margin: "10px 0 0 10px", backgroundColor: "#ea3f4d" }}
        >
          CANCEL
        </Button>
        <Button
          variant="contained"
          type="submit"
          style={{ margin: "10px 0 0 10px", backgroundColor: "#0b6885" }}
        >
          SUBMIT
        </Button>
      </div>
    </Form>
  );
};

export default BlogsAddForm;

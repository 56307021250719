import {
  GET_VERIFIED_MEMBERS_FAIL,
  GET_VERIFIED_MEMBERS_REQUEST,
  GET_VERIFIED_MEMBERS_RESET,
  GET_VERIFIED_MEMBERS_SUCCESS,
} from "./VerifiedMembersConstant";

export const getAllVerifiedMembersReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_VERIFIED_MEMBERS_REQUEST:
      return { loading: true };
    case GET_VERIFIED_MEMBERS_SUCCESS:
      return { loading: false, allVerifiedMembers: action.payload };
    case GET_VERIFIED_MEMBERS_FAIL:
      return { loading: false, error: action.payload };
    case GET_VERIFIED_MEMBERS_RESET:
      return {};
    default:
      return state;
  }
};

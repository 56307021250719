import React, { useState } from "react";
import { Link } from "react-router-dom";
import ArrowButton from "../components/ArrowButton";
import UserHeader from "../components/UserHeader";
import { Helmet } from "react-helmet";
import UserFooter from "../components/UserFooter";
import { ToastContainer, toast } from "react-toastify";
import AddIcon from "@mui/icons-material/Add";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Typography,
} from "@mui/material";
import {
  getAllEnterpriseFaqsPublicAction,
  getAllInvestorFaqsPublicAction,
  postContactFormAction,
} from "../public-routes/PublicRoutesActions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

const FAQs = () => {
  const [faqsEnterprise, setFaqsEnterprise] = useState([]);
  const [faqsInvestor, setFaqsInvestor] = useState([]);
  const [showEnterprise, setShowEnterprise] = useState(true);
  const [showInvestor, setShowInvestor] = useState(false);
  const [activeFaqs, setActiveFaqs] = useState("enterprise");

  const [orgName, setOrgName] = useState("");
  const [contact, setContact] = useState();
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});

  const [selectedIndex, setSelectedIndex] = useState(-1);

  const dispatch = useDispatch();

  const { allInvestorFaqsPublic } = useSelector(
    (state) => state.getAllInvestorFaqsPublic
  );

  const { allEnterpriseFaqsPublic } = useSelector(
    (state) => state.getAllEnterpriseFaqsPublic
  );

  const initialFormValues = {
    full_name: orgName,
    phone: contact,
    email: email,
    message: message,
  };

  useEffect(() => {
    if (!allInvestorFaqsPublic) {
      dispatch(getAllInvestorFaqsPublicAction());
    } else {
      setFaqsInvestor(allInvestorFaqsPublic?.data);
    }
  }, [dispatch, allInvestorFaqsPublic]);

  useEffect(() => {
    if (!allEnterpriseFaqsPublic) {
      dispatch(getAllEnterpriseFaqsPublicAction());
    } else {
      setFaqsEnterprise(allEnterpriseFaqsPublic?.data);
    }
  }, [dispatch, allEnterpriseFaqsPublic]);

  useEffect(() => {
    dispatch(getAllEnterpriseFaqsPublicAction());
    dispatch(getAllInvestorFaqsPublicAction());
  }, [dispatch]);

  const handleFaqsButton = (buttonType) => {
    setActiveFaqs(buttonType);
    setShowEnterprise(buttonType === "enterprise");
    setShowInvestor(buttonType === "investor");
    dispatch(getAllEnterpriseFaqsPublicAction());
    dispatch(getAllInvestorFaqsPublicAction());
  };

  const handleFaqsBothClick = (buttonName) => {
    handleFaqsButton(buttonName);
  };

  const filteredEnterprise = faqsEnterprise.filter((item) => item.status === 1);
  const filteredInvestor = faqsInvestor.filter((item) => item.status === 1);

  const handleContactFormSubmit = (e) => {
    e.preventDefault();
    let errors = {};
    if (!orgName) {
      errors["orgName"] = "Bussiness/Your name is required";
    }
    if (!contact) {
      errors["contact"] = "Contact number is required";
    }
    if (!email) {
      errors["email"] = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors["email"] = "Email is invalid";
    }
    if (!message) {
      errors["message"] = "Message is required";
    }

    setErrors(errors);

    if (Object.keys(errors).length === 0) {
      dispatch(postContactFormAction(initialFormValues)).then(() => {
        toast.success("Your Feedback has been Submitted Successfully.");
      });
      setOrgName("");
      setContact("");
      setEmail("");
      setMessage("");
    }
  };

  return (
    <>
      <UserHeader />
      <Helmet>
        <meta charset="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="description" content="" />
        <meta name="author" content="" />

        <title>Million Deals</title>
        {/* <!-- Linking the font Poppins--> */}
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />

        {/* <!-- Linking Bootstrap Icon--> */}
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css"
        />
      </Helmet>
      <ArrowButton />
      <div className="page-title">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h1>FAQs</h1>
              <Link className="link" to="/">
                Home
              </Link>
              {""} / FAQs
            </div>
          </div>
        </div>
      </div>

      <div className="home-faq">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="row">
                <div className="col-lg-6 col-md-5">
                  <h3 className="section-subtitle">
                    Frequently Asked Questions
                  </h3>
                  <h2 className="section-title">Clear All Doubts</h2>
                </div>

                <div className="col-lg-6 col-md-7 text-right">
                  <button
                    className={`dark-btn ${
                      activeFaqs === "enterprise" ? "active-btn" : ""
                    }`}
                    onClick={() => {
                      handleFaqsBothClick("enterprise");
                    }}
                  >
                    For Enterprise
                  </button>
                  <button
                    className={`dark-btn ${
                      activeFaqs === "investor" ? "active-btn" : ""
                    }`}
                    onClick={() => {
                      handleFaqsBothClick("investor");
                    }}
                  >
                    For Investor
                  </button>
                </div>
              </div>
              {showEnterprise ? (
                filteredEnterprise.length > 0 ? (
                  filteredEnterprise.map((item, index) => (
                    <div className="row pt-2" key={item.id}>
                      <div
                        className="accordion"
                        id={`accordionExample${index}`}
                      >
                        <div className="card card-responsive">
                          <Accordion TransitionProps={{ unmountOnExit: true }}>
                            <AccordionSummary
                              style={{
                                backgroundColor: "#0b6885",
                                color: "#ffffff",
                              }}
                              expandIcon={
                                <AddIcon
                                  style={{
                                    color: "#ffffff",
                                  }}
                                />
                              }
                              onClick={() => {
                                setSelectedIndex(index);
                              }}
                            >
                              <Typography
                                className="mb-0"
                                variant="h5"
                                fontSize={18}
                                letterSpacing={1}
                              >
                                {item.question}
                              </Typography>
                            </AccordionSummary>
                            <div
                              id={`collapse${index}`}
                              className={
                                selectedIndex === index
                                  ? "collapse show"
                                  : "collapse"
                              }
                              aria-labelledby={`heading${index}`}
                              data-parent={`#accordionExample${index}`}
                            >
                              <AccordionDetails>
                                <Typography>
                                  <p
                                    className="section-text"
                                    style={{ textAlign: "justify" }}
                                    dangerouslySetInnerHTML={{
                                      __html: item?.text,
                                    }}
                                  ></p>
                                </Typography>
                              </AccordionDetails>
                            </div>
                          </Accordion>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <span className="error">*No FAQs Found</span>
                )
              ) : showInvestor ? (
                filteredInvestor.length > 0 ? (
                  filteredInvestor.map((item, index) => (
                    <div className="row pt-2" key={item.id}>
                      <div
                        className="accordion"
                        id={`accordionExample${index}`}
                      >
                        <div className="card card-responsive">
                          <Accordion TransitionProps={{ unmountOnExit: true }}>
                            <AccordionSummary
                              style={{
                                backgroundColor: "#0b6885",
                                color: "#ffffff",
                              }}
                              expandIcon={
                                <AddIcon
                                  style={{
                                    color: "#ffffff",
                                  }}
                                />
                              }
                              onClick={() => {
                                setSelectedIndex(index);
                              }}
                            >
                              <Typography
                                className="mb-0"
                                variant="h5"
                                fontSize={18}
                                letterSpacing={1}
                              >
                                {item.question}
                              </Typography>
                            </AccordionSummary>
                            <div
                              id={`collapse${index}`}
                              className={
                                selectedIndex === index
                                  ? "collapse show"
                                  : "collapse"
                              }
                              aria-labelledby={`heading${index}`}
                              data-parent={`#accordionExample${index}`}
                            >
                              <AccordionDetails>
                                <Typography>
                                  <p
                                    className="section-text"
                                    style={{ textAlign: "justify" }}
                                    dangerouslySetInnerHTML={{
                                      __html: item?.text,
                                    }}
                                  ></p>
                                </Typography>
                              </AccordionDetails>
                            </div>
                          </Accordion>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <span className="error">*No FAQs Found</span>
                )
              ) : (
                <span className="error">*No Data Found</span>
              )}
            </div>

            <div className="col-lg-4 mt-3">
              <div className="short-form">
                <h3>Have Us Contact You</h3>
                <p>Fill up the form and have us contact you</p>
                <form onSubmit={handleContactFormSubmit}>
                  <input
                    type="text"
                    id="orgName"
                    value={orgName}
                    className={`form-control ${
                      errors["orgName"] ? "is-invalid" : ""
                    }`}
                    placeholder="Your Business / Your Name"
                    onChange={(e) => setOrgName(e.target.value)}
                  />
                  {errors["orgName"] && (
                    <div className="invalid-feedback">*{errors["orgName"]}</div>
                  )}

                  <input
                    type="number"
                    id="contact"
                    value={contact}
                    className={`form-control ${
                      errors["contact"] ? "is-invalid" : ""
                    }`}
                    placeholder="Phone Number"
                    onChange={(e) => setContact(e.target.value)}
                  />
                  {errors["contact"] && (
                    <div className="invalid-feedback">*{errors["contact"]}</div>
                  )}

                  <input
                    type="email"
                    placeholder="Email Address"
                    className={`form-control ${
                      errors["email"] ? "is-invalid" : ""
                    }`}
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {errors["email"] && (
                    <div className="invalid-feedback">*{errors["email"]}</div>
                  )}
                  <textarea
                    className={`form-control ${
                      errors["message"] ? "is-invalid" : ""
                    }`}
                    id="message"
                    value={message}
                    placeholder="Your Message or Concern"
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                  {errors["message"] && (
                    <div className="invalid-feedback">*{errors["message"]}</div>
                  )}
                  <Button
                    type="submit"
                    style={{
                      backgroundColor: "#0b6885",
                      width: "92%",
                    }}
                    className="send-button"
                    variant="contained"
                  >
                    Send
                  </Button>
                  <ToastContainer
                    position="top-right"
                    autoClose={2000}
                    theme="colored"
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <UserFooter />
    </>
  );
};

export default FAQs;

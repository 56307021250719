import React, { useEffect, useState } from "react";
import UserHeader from "../../components/UserHeader";
import { Helmet } from "react-helmet";
import ArrowButton from "../../components/ArrowButton";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import logo1 from "../../front-end-images/1.png";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllInvestorFaqsPublicAction,
  postContactFormAction,
} from "../../public-routes/PublicRoutesActions";
import { ToastContainer, toast } from "react-toastify";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import UserFooter from "../../components/UserFooter";

const ForInvestor = () => {
  const [faqsInvestor, setFaqsInvestor] = useState([]);

  const [orgName, setOrgName] = useState("");
  const [contact, setContact] = useState();
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});

  const [selectedIndex, setSelectedIndex] = useState(-1);

  const dispatch = useDispatch();

  const { allInvestorFaqsPublic } = useSelector(
    (state) => state.getAllInvestorFaqsPublic
  );

  const initialFormValues = {
    full_name: orgName,
    phone: contact,
    email: email,
    message: message,
  };

  const filteredInvestor = faqsInvestor.filter((item) => item.status === 1);

  useEffect(() => {
    if (!allInvestorFaqsPublic) {
      dispatch(getAllInvestorFaqsPublicAction());
    } else {
      setFaqsInvestor(allInvestorFaqsPublic?.data);
    }
  }, [dispatch, allInvestorFaqsPublic]);

  useEffect(() => {
    dispatch(getAllInvestorFaqsPublicAction());
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleContactFormSubmit = (e) => {
    e.preventDefault();
    let errors = {};
    if (!orgName) {
      errors["orgName"] = "Bussiness/Your name is required";
    }
    if (!contact) {
      errors["contact"] = "Contact number is required";
    }
    if (!email) {
      errors["email"] = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors["email"] = "Email is invalid";
    }
    if (!message) {
      errors["message"] = "Message is required";
    }

    setErrors(errors);

    if (Object.keys(errors).length === 0) {
      dispatch(postContactFormAction(initialFormValues)).then(() => {
        toast.success("Your Feedback has been Submitted Successfully.");
      });
      setOrgName("");
      setContact("");
      setEmail("");
      setMessage("");
    }
  };

  return (
    <>
      <UserHeader />
      <Helmet>
        <meta charset="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="description" content="" />
        <meta name="author" content="" />

        <title>Million Deals</title>
        {/* <!-- Linking the font Poppins--> */}
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />

        {/* <!-- Linking Bootstrap Icon--> */}
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css"
        />
      </Helmet>
      <ArrowButton />

      <div className="page-title">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h2>All About Investor</h2>
              <Link className="link" to="/">
                Home
              </Link>
              {""} / For Investor
            </div>
          </div>
        </div>
      </div>

      <div className="slider">
        <div className="container">
          <div className="row position-relative">
            <div className="col-lg-6 col-md-12">
              <h2 className="section-title">Our offer to Investors</h2>
              <p
                className="section-text-for_enterprise white-text"
                style={{ textAlign: "justify" }}
              >
                We offer investors a diverse range of vetted investment
                opportunities in Nepal alongside information and support from
                our experienced team throughout the investment process ensuring
                a smooth and efficient experience. By joining us you can have
                access to a pool of verified investment opportunities in
                sectors, geographies and ticket sizes that work for you. You can
                easily show interest in specific deals and access further
                information including the necessary due diligence reports to
                make informed investment decisions. We can also offer you
                additional bespoke services in your deal-making process. We are
                here to help you access the right investment opportunities and
                the right information as well as to help you navigate the
                landscape. By joining us, you can gain access to exciting
                investment opportunities as well as connect to a community of
                like-minded stakeholders committed to driving the economic
                growth and sustainable development of Nepal.
              </p>
              <br />
            </div>
            <div className="col-lg-6 col-md-8">
              <img alt="" src={logo1} />
            </div>
          </div>
        </div>
      </div>

      <div className="process">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-5">
              <h3 className="section-subtitle">Our Process</h3>
              <h2 className="section-title">How We Work & Connect</h2>
            </div>
            <TableContainer sx={{ maxHeight: "100%" }}>
              <Table
                stickyHeader
                aria-label="sticky table"
                className="table-style "
              >
                <TableHead style={{ height: "50px" }}>
                  <TableRow
                    sx={{
                      "& :hover": {
                        color: "white !important",
                      },
                      "& :focus:not(:focus-visible)": {
                        color: "white !important",
                      },
                    }}
                  >
                    <TableCell
                      style={{
                        backgroundColor: "#0b6885",
                        padding: "0.5vw",
                        letterSpacing: "0.5px",
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "white",
                      }}
                    >
                      Register on our Platform
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "#0b6885",
                        padding: "0.5vw",
                        letterSpacing: "0.5px",
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "white",
                      }}
                    >
                      Get Verified
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "#0b6885",
                        padding: "0.5vw",
                        letterSpacing: "0.5px",
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "white",
                      }}
                    >
                      Access Vetted Deals
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "#0b6885",
                        padding: "0.5vw",
                        letterSpacing: "0.5px",
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "white",
                      }}
                    >
                      Show interest in particular Deal(s)
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "#0b6885",
                        padding: "0.5vw",
                        letterSpacing: "0.5px",
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "white",
                      }}
                    >
                      Access detailed information
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "#0b6885",
                        padding: "0.5vw",
                        letterSpacing: "0.5px",
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "white",
                      }}
                    >
                      Start Investing
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow style={{ backgroundColor: "#FFF" }}>
                    <TableCell>
                      Create an account on our platform, using your email, to
                      access a wealth of investment opportunities, resources,
                      and support. Upon registration, you will be directed to a
                      short questionnaire.
                    </TableCell>

                    <TableCell>
                      Our team will review your answers and conduct initial
                      checks to verify your account and to ensure that investors
                      on our platform are seriously committed to supporting our
                      entrepreneurs.
                    </TableCell>

                    <TableCell>
                      Once your account is verified, you can access our network
                      of vetted deals, carefully selected by our due diligence
                      team to ensure they meet our high standards.
                    </TableCell>

                    <TableCell>
                      You can identify investment opportunities that align with
                      your investment criteria using our filters by sector,
                      geography, investment stage, ticket size, instrument etc.
                      You can show interest in those deals that align with your
                      criteria and receive more information.
                    </TableCell>

                    <TableCell>
                      Once you have shown interest in a particular deal our team
                      will reach out to you to ensure confidentiality, following
                      which you will initially have access to a detailed fact
                      sheet. Following the review of the fact sheet, you can
                      request for our thorough due diligence report which will
                      provide you with all the information you need to make an
                      informed investment decision.
                    </TableCell>

                    <TableCell>
                      Once you have reviewed the due diligence report, and made
                      the decision to invest, our team will be there to support
                      you in finalizing the deal. Once you and the entrepreneur
                      decide to engage in exclusive deal discussion, we will
                      freeze the specific deal listing from our platform until a
                      final decision is made. If you and the entrepreneur decide
                      to engage in deal discussions without the engagement of
                      our team, we will ensure you have access to all relevant
                      information.
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>

      <div className="home-faq">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="row">
                <div className="col-lg-6 col-md-5">
                  <h3 className="section-subtitle">
                    Frequently Asked Questions
                  </h3>
                  <h2 className="section-title">Clear All Doubts</h2>(If you
                  have any questions not answered in the FAQs, please reach out
                  to us at{" "}
                  <Link
                    style={{ textDecoration: "none" }}
                    onClick={() =>
                      (window.location = "mailto:info@milliondeals.com.np")
                    }
                  >
                    info@milliondeals.com.np
                  </Link>
                  )
                </div>
              </div>
              {filteredInvestor.length > 0 ? (
                filteredInvestor.map((item, index) => (
                  <div className="row pt-2">
                    <div className="accordin" id={`accordionExample${index}`}>
                      <div className="card card-responsive">
                        <Accordion TransitionProps={{ unmountOnExit: true }}>
                          <AccordionSummary
                            style={{
                              backgroundColor: "#0b6885",
                              color: "#ffffff",
                            }}
                            expandIcon={
                              <AddIcon
                                style={{
                                  color: "#ffffff",
                                }}
                              />
                            }
                            onClick={() => {
                              setSelectedIndex(index);
                            }}
                          >
                            <Typography
                              className="mb-0"
                              variant="h5"
                              fontSize={18}
                              letterSpacing={1}
                            >
                              {item.question}
                            </Typography>
                          </AccordionSummary>
                          <div
                            id={`collapse${index}`}
                            className={
                              selectedIndex === index
                                ? "collapse show"
                                : "collapse"
                            }
                            aria-labelledby={`heading${index}`}
                            data-parent={`#accordionExample${index}`}
                          >
                            <AccordionDetails>
                              <Typography>
                                {" "}
                                <p
                                  className="section-text"
                                  style={{ textAlign: "justify" }}
                                  dangerouslySetInnerHTML={{
                                    __html: item?.text,
                                  }}
                                ></p>
                              </Typography>
                            </AccordionDetails>
                          </div>
                        </Accordion>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <span className="error">*No FAQs Found</span>
              )}
            </div>

            <div className="col-lg-4 mt-3">
              <div className="short-form">
                <h3>Have Us Contact You</h3>
                <p>Fill up the form and have us contact you</p>

                <form onSubmit={handleContactFormSubmit}>
                  <input
                    type="text"
                    id="orgName"
                    value={orgName}
                    className={`form-control ${
                      errors["orgName"] ? "is-invalid" : ""
                    }`}
                    placeholder="Your Business / Your Name"
                    onChange={(e) => setOrgName(e.target.value)}
                  />
                  {errors["orgName"] && (
                    <div className="invalid-feedback">{errors["orgName"]}</div>
                  )}

                  <input
                    type="number"
                    id="contact"
                    value={contact}
                    className={`form-control ${
                      errors["contact"] ? "is-invalid" : ""
                    }`}
                    placeholder="Phone Number"
                    onChange={(e) => setContact(e.target.value)}
                  />
                  {errors["contact"] && (
                    <div className="invalid-feedback">{errors["contact"]}</div>
                  )}

                  <input
                    type="email"
                    placeholder="Email Address"
                    className={`form-control ${
                      errors["email"] ? "is-invalid" : ""
                    }`}
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {errors["email"] && (
                    <div className="invalid-feedback">{errors["email"]}</div>
                  )}
                  <textarea
                    className={`form-control ${
                      errors["message"] ? "is-invalid" : ""
                    }`}
                    id="message"
                    value={message}
                    placeholder="Your Message or Concern"
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                  {errors["message"] && (
                    <div className="invalid-feedback">{errors["message"]}</div>
                  )}
                  <Button
                    type="submit"
                    style={{
                      backgroundColor: "#0b6885",
                      width: "92%",
                    }}
                    className="send-button"
                    variant="contained"
                  >
                    Send
                  </Button>
                  <ToastContainer
                    position="top-right"
                    autoClose={2000}
                    theme="colored"
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <UserFooter />
    </>
  );
};

export default ForInvestor;

import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  getAllDealCategoryFilterAction,
  getAllSdgsFilterAction,
} from "../private-routes/PrivateActions";
import { postCreateInvestorKycAction } from "../kyc-state-handler/KycActions";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ArrowButton from "../components/ArrowButton";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";

const amountRanges = [
  { amount: "1", min: 100000, max: 500000, label: "1 to 5 Lakhs" },
  { amount: "2", min: 500000, max: 1000000, label: "5 to 10 Lakhs" },
  { amount: "3", min: 1000000, max: 3000000, label: "10 to 30 Lakhs" },
  { amount: "4", min: 3000000, max: 5000000, label: "30 to 50 Lakhs" },
  { amount: "5", min: 5000000, max: 10000000, label: "50 to 1 Crore" },
  { amount: "6", min: 10000000, max: 50000000, label: "1 to 5 Crore" },
  { amount: "7", min: 50000000, max: 100000000, label: "5 to 10 Crore" },
  { amount: "8", min: 100000000, max: Infinity, label: "10 Crore And Above" },
];

const UpdateInvestorKycForm = ({
  formData,
  setFormData,
  sectorList,
  sdgList,
  setOpenEditKycPopup,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSdgChange = (e) => {
    const { name, checked } = e.target;
    if (checked) {
      setFormData((prevData) => {
        const sdgSupportList = Array.isArray(prevData.sdgs)
          ? prevData.sdgs
          : [];

        if (!sdgSupportList.includes(Number(name))) {
          sdgSupportList.push(Number(name));
        }
        return {
          ...prevData,
          sdgs: sdgSupportList,
        };
      });
    } else {
      setFormData((prevData) => {
        const sdgSupportList = Array.isArray(prevData.sdgs)
          ? prevData.sdgs
          : [];
        const index = sdgSupportList.indexOf(Number(name));
        if (index !== -1) {
          sdgSupportList.splice(index, 1);
        }
        return {
          ...prevData,
          sdgs: sdgSupportList,
        };
      });
    }
  };

  const handleSectorChange = (e) => {
    const { name, checked } = e.target;
    if (checked) {
      setFormData((prevData) => {
        const sectorSupportList = Array.isArray(prevData.invest_in)
          ? prevData.invest_in
          : [];

        if (!sectorSupportList.includes(Number(name))) {
          sectorSupportList.push(Number(name));
        }
        return {
          ...prevData,
          invest_in: sectorSupportList,
        };
      });
    } else {
      setFormData((prevData) => {
        const sectorSupportList = Array.isArray(prevData.invest_in)
          ? prevData.invest_in
          : [];
        const index = sectorSupportList.indexOf(Number(name));
        if (index !== -1) {
          sectorSupportList.splice(index, 1);
        }
        return {
          ...prevData,
          invest_in: sectorSupportList,
        };
      });
    }
  };

  const handleAmountChange = (e) => {
    const { name, checked } = e.target;

    if (checked) {
      setFormData((prevData) => {
        const amountList = Array.isArray(prevData.investment_size)
          ? prevData.investment_size
          : [];

        const selectedAmount = amountRanges.find(
          (range) => range.amount === name
        );

        if (selectedAmount && !amountList.includes(selectedAmount.amount)) {
          amountList.push(selectedAmount.amount);
        }

        return {
          ...prevData,
          investment_size: amountList,
        };
      });
    } else {
      setFormData((prevData) => {
        const amountList = Array.isArray(prevData.investment_size)
          ? prevData.investment_size
          : [];

        const selectedAmount = amountRanges.find(
          (range) => range.amount === name
        );

        if (selectedAmount) {
          const index = amountList.indexOf(selectedAmount.amount);
          if (index !== -1) {
            amountList.splice(index, 1);
          }
        }

        return {
          ...prevData,
          investment_size: amountList,
        };
      });
    }
  };

  useEffect(() => {
    dispatch(getAllSdgsFilterAction());
    dispatch(getAllDealCategoryFilterAction());
  }, [dispatch]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    if (Object.keys(formData).length === 0 || formData === "") {
      window.alert("Please fill out all the required form!");
    } else {
      dispatch(postCreateInvestorKycAction(formData)).then((res) => {
        if (res?.status === 200) {
          toast.success(
            "Thank you for joining Million Deals, we will verify your information to fully activate your account; this process may take up to 2 business days."
          );

          setTimeout(() => {
            navigate("/dashboard");
          }, 1500);
        } else {
          // Handle error case where enterpriseKycId is null or undefined
          console.log("Error: enterpriseKycId is null or undefined");
        }
      });
    }
  };

  return (
    <>
      <ArrowButton />
      <div className="page-content-review">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="invest-form">
                <form onSubmit={handleSubmit}>
                  <h2>General Information</h2>
                  Full Name
                  <input
                    type="text"
                    name="name"
                    value={formData.name || ""}
                    onChange={handleInputChange}
                  />
                  Phone Number
                  <input
                    type="number"
                    name="phone"
                    value={formData.phone || ""}
                    onChange={handleInputChange}
                  />
                  Email Address
                  <input
                    type="email"
                    name="email"
                    value={formData.email || ""}
                    onChange={handleInputChange}
                  />
                  Are you an individual investor or institutional investor?
                  <select
                    name="ind_or_ins_investor"
                    id="ind_or_ins_investor"
                    value={formData.ind_or_ins_investor || ""}
                    onChange={handleInputChange}
                  >
                    <option value="Individual">
                      Individual (Inlcuding Angel Investors)
                    </option>
                    <option value="Institutional">Institutional</option>
                    <option value="Other">Other</option>
                  </select>
                  {formData.ind_or_ins_investor === "Other" && (
                    <>
                      Please describe if any others{" "}
                      <input
                        type="text"
                        name="ind_or_ins_investor_other"
                        id="ind_or_ins_investor_other"
                        value={formData.ind_or_ins_investor_other || ""}
                        onChange={handleInputChange}
                      />
                    </>
                  )}
                  Your Linkedin profile or other online profile
                  <input
                    type="text"
                    name="linkin_or_online_profile"
                    value={formData.linkin_or_online_profile}
                    onChange={handleInputChange}
                  />
                  Contact Address
                  <input
                    type="text"
                    name="contact_address"
                    value={formData.contact_address || ""}
                    onChange={handleInputChange}
                  />
                  Please provide a brief information about your profile and
                  background
                  <textarea
                    type="text"
                    name="bio"
                    value={formData.bio || ""}
                    onChange={handleInputChange}
                  />
                  Please state your investment objective - why are you
                  interested in investing in companies and projects in Nepal?
                  Please also state your risk appetite and return expectations.
                  <textarea
                    type="text"
                    name="interested_in_companies"
                    value={formData.interested_in_companies || ""}
                    onChange={handleInputChange}
                  />
                  What is your role in your company?{" "}
                  <input
                    type="text"
                    name="company_role"
                    value={formData.company_role || ""}
                    onChange={handleInputChange}
                  />
                  <br />
                  <br />
                  <h2>Company/Organization Details</h2>
                  Company or Organization you are associated with?
                  <input
                    type="text"
                    name="company_name"
                    value={formData.company_name || ""}
                    onChange={handleInputChange}
                  />
                  Company website or LinkedIn Page link
                  <input
                    type="text"
                    name="company_website"
                    value={formData.company_website || ""}
                    onChange={handleInputChange}
                  />
                  Please provide a brief about your company's profile and
                  background
                  <textarea
                    type="text"
                    name="company_bio"
                    value={formData.company_bio || ""}
                    onChange={handleInputChange}
                  />
                  Which of the following describes you the best?
                  <select
                    name="describes_your_best"
                    id="describes_your_best"
                    value={formData.describes_your_best || ""}
                    onChange={handleInputChange}
                  >
                    <option value="Endowments and Foundation">
                      Endowments and Foundation
                    </option>
                    <option value="Bank">Bank</option>
                    <option value="Insurance Company">Insurance Company</option>
                    <option value="Investment Manager including PE/VC">
                      Investment Manager including PE/VC
                    </option>
                    <option value="Other Financial Intermediary">
                      Other Financial Intermediary
                    </option>
                    <option value=" Professional / Active Investor">
                      Professional / Active Investor
                    </option>
                    <option value="Passive Investor">Passive Investor</option>
                    <option value="Business Owner">Business Owner</option>
                  </select>
                  {formData.describes_your_best ===
                    "Other Financial Intermediary" && (
                    <>
                      Please write what describe you the best if others{" "}
                      <textarea
                        type="text"
                        name="describes_your_best_other"
                        value={formData.describes_your_best_other || ""}
                        onChange={handleInputChange}
                      />
                    </>
                  )}
                  <br />
                  <br />
                  <h2>Investment Details</h2>
                  Preferred Investment Size?
                  <br />
                  <div className="form-check">
                    <FormGroup sx={{ "& .MuiSvgIcon-root": { fontSize: 16 } }}>
                      {amountRanges?.map((option) => (
                        <FormControlLabel
                          key={option.amount}
                          control={
                            <Checkbox
                              checked={formData.investment_size.includes(
                                option.amount
                              )}
                              onChange={handleAmountChange}
                              name={option.amount}
                              value={option.amount}
                              inputprops={{ "aria-label": "controlled" }}
                            />
                          }
                          label={option.label}
                        />
                      ))}
                    </FormGroup>
                  </div>
                  <br />
                  Preferred Sectors to Invest In?
                  <div className="form-check">
                    <FormGroup sx={{ "& .MuiSvgIcon-root": { fontSize: 16 } }}>
                      {sectorList?.map((option) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={formData.invest_in.includes(
                                option.deal_category_id
                              )}
                              onChange={handleSectorChange}
                              name={option.deal_category_id}
                              value={option.deal_category_id}
                              inputprops={{ "aria-label": "controlled" }}
                            />
                          }
                          label={option.deal_cat_name}
                        />
                      ))}
                    </FormGroup>
                  </div>
                  <br />
                  Do you have or wish to have a sustainability or impact focus
                  in your investments?
                  <select
                    name="is_sustainability"
                    id="is_sustainability"
                    value={formData.is_sustainability || ""}
                    onChange={handleInputChange}
                    displayempty="true"
                    inputprops={{ "aria-label": "Without label" }}
                  >
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                  </select>
                  <br />
                  {formData.is_sustainability &&
                    formData.is_sustainability !== "0" && (
                      <>
                        Which of the following SDGs do you or do you with you
                        contribute to via your investments?
                        <br />
                        <div className="form-check">
                          <FormGroup
                            sx={{ "& .MuiSvgIcon-root": { fontSize: 16 } }}
                          >
                            {sdgList?.slice(0, 17)?.map((option) => (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={formData.sdgs.includes(
                                      option.sdg_id
                                    )}
                                    onChange={handleSdgChange}
                                    name={option.sdg_id}
                                    value={option.sdg_id}
                                    inputprops={{ "aria-label": "controlled" }}
                                  />
                                }
                                label={option.sdg_name}
                              />
                            ))}
                          </FormGroup>
                        </div>
                      </>
                    )}
                  Preferred stage of investment?
                  <select
                    name="stage_of_investment"
                    id="stage_of_investment"
                    value={formData.stage_of_investment || ""}
                    onChange={handleInputChange}
                  >
                    <option value="1">Idea Stage</option>
                    <option value="2">Growth Stage</option>
                    <option value="3">Neutral</option>
                  </select>
                  {/* <button className="blue-btn" type="submit">
                    Submit
                  </button> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateInvestorKycForm;

import React, { useState, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet";
import LoadingComp from "../../components/LoadingComp";
import "../../styles/style.css";
import { useDispatch, useSelector } from "react-redux";
import UserHeader from "../components/UserHeader";
import UserFooter from "../components/UserFooter";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Checkbox,
  Fade,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  getAllDealsPublicAction,
  getSingleDealsPublicAction,
} from "../public-routes/PublicRoutesActions";
import useAuthentication from "../../components/hooks/useAuthentication";
import ArrowButton from "../components/ArrowButton";
import {
  getAllDealCategoryFilterAction,
  getAllSdgsFilterAction,
} from "../private-routes/PrivateActions";
import MainLoginComponent from "../components/MainLoginComponent";
import { getMemberProfileSideAction } from "../kyc-state-handler/KycActions";
import { ToastContainer } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "27px",
  boxShadow: 24,
  p: 4,
};

const provinceList = [
  {
    id: 1,
    label: "Province 1",
  },
  {
    id: 2,
    label: "Madhesh Pradesh",
  },
  {
    id: 3,
    label: "Bagmati Pradesh",
  },
  { id: 4, label: "Gandaki Pradesh" },
  {
    id: 5,
    label: "Lumbini Pradesh",
  },
  {
    id: 6,
    label: "Karnali Pradesh",
  },
  { id: 7, label: "Sudurpaschim Pradesh" },
];

const amountRanges = [
  { amount: "1", min: 100000, max: 500000, label: "1 to 5 Lakhs" },
  { amount: "2", min: 500000, max: 1000000, label: "5 to 10 Lakhs" },
  { amount: "3", min: 1000000, max: 3000000, label: "10 to 30 Lakhs" },
  { amount: "4", min: 3000000, max: 5000000, label: "30 to 50 Lakhs" },
  { amount: "5", min: 5000000, max: 10000000, label: "50 to 1 Crore" },
  { amount: "6", min: 10000000, max: 50000000, label: "1 to 5 Crore" },
  { amount: "7", min: 50000000, max: 100000000, label: "5 to 10 Crore" },
  { amount: "8", min: 100000000, max: Infinity, label: "10 Crore And Above" },
];

const DealsListingPagePublic = () => {
  const { isMemAuth } = useAuthentication();
  const [openLoginPopup, setOpenLoginPopup] = useState(false);
  const [selectedTab, setSelectedTab] = useState("login");
  const [dealsList, setDealsList] = useState([]);
  const [singleDeals, setSingleDeals] = useState("");
  const [values, setValues] = useState([]);
  const [category, setCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [amount, setAmount] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState([]);
  const [allSdgs, setAllSdgs] = useState([]);
  const [selectedSdgs, setSelectedSdgs] = useState([]);
  const [prevMemberProfileSide, setPrevMemberProfileSide] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(9);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { allDealsPublic, loading } = useSelector(
    (state) => state.getAllDealsPublic
  );

  const { memberProfileSide } = useSelector(
    (state) => state.getMemberProfileside
  );

  const { allDealsCategoryFilter } = useSelector(
    (state) => state.getAllDealCategoryFilter
  );

  const { allSdgsFilter } = useSelector((state) => state.getAllSdgsFilterList);

  useEffect(() => {
    dispatch(getMemberProfileSideAction());
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  useEffect(() => {
    if (memberProfileSide && memberProfileSide !== prevMemberProfileSide) {
      setValues(memberProfileSide?.data);
    }
  }, [memberProfileSide, prevMemberProfileSide]);

  useEffect(() => {
    setPrevMemberProfileSide(memberProfileSide);
  }, [memberProfileSide]);

  useEffect(() => {
    if (!allDealsPublic) {
      dispatch(getAllDealsPublicAction());
    }
    if (allDealsPublic) {
      setDealsList(allDealsPublic?.data);
    }
  }, [dispatch, allDealsPublic]);

  useEffect(() => {
    if (!allDealsCategoryFilter) {
      dispatch(getAllDealCategoryFilterAction());
    }
    if (allDealsCategoryFilter) {
      setCategory(allDealsCategoryFilter?.data);
    }
  }, [dispatch, allDealsCategoryFilter]);

  useEffect(() => {
    if (!allSdgsFilter) {
      dispatch(getAllSdgsFilterAction());
    }
    if (allSdgsFilter) {
      setAllSdgs(allSdgsFilter?.data);
    }
  }, [dispatch, allSdgsFilter]);

  useEffect(() => {
    dispatch(getAllDealsPublicAction());
    dispatch(getAllDealCategoryFilterAction());
    dispatch(getAllSdgsFilterAction());
  }, [dispatch]);

  const dataInvestorArray = values?.find(
    (value) => value.profile_type === "Investor" && value.is_verified === 1
  );

  const handleAmountChange = (event) => {
    const { value } = event.target;
    if (amount.includes(value)) {
      setAmount(amount.filter((item) => item !== value));
    } else {
      setAmount([...amount, value]);
    }
  };

  const handleProvinceChange = (e) => {
    const { value, checked } = e.target;

    if (checked) {
      setSelectedProvince((prevSelectedProvince) => [
        ...prevSelectedProvince,
        Number(value),
      ]);
    } else {
      setSelectedProvince((prevSelectedProvince) =>
        prevSelectedProvince.filter((id) => id !== Number(value))
      );
    }
  };

  const handleCategoryChange = (event) => {
    const { value, checked } = event.target;

    if (checked) {
      setSelectedCategory((prevSelectedCategory) => [
        ...prevSelectedCategory,
        Number(value),
      ]);
    } else {
      setSelectedCategory((prevSelectedCategory) =>
        prevSelectedCategory.filter((id) => id !== Number(value))
      );
    }
  };

  const handleSdgsChange = useCallback(
    (event) => {
      const { value } = event.target;

      setSelectedSdgs(value);
    },
    [setSelectedSdgs]
  );

  const filteredDeals = dealsList.filter((deal) => {
    let showDeal = true;

    if (
      selectedProvince.length > 0 &&
      !selectedProvince.includes(deal.company_operate)
    ) {
      showDeal = false;
    }

    if (
      selectedCategory.length > 0 &&
      !selectedCategory.includes(deal.deal_cat_u_id)
    ) {
      showDeal = false;
    }

    if (
      selectedSdgs.length > 0 &&
      !selectedSdgs.some((sdgId) => deal.sdg_id.includes(parseInt(sdgId)))
    ) {
      showDeal = false;
    }

    if (amount.length === 0) {
      return showDeal; // Return early if no amount filter is selected
    }

    const dealAmount = parseFloat(deal.amount);
    const selectedAmountRanges = amount.map((item) => parseInt(item));
    for (let i = 0; i < selectedAmountRanges.length; i++) {
      const selectedRange = amountRanges.find(
        (range) => range.amount === selectedAmountRanges[i].toString()
      );
      if (dealAmount >= selectedRange.min && dealAmount <= selectedRange.max) {
        return showDeal; // Return early if deal amount falls within selected range
      }
    }

    return false;
  });

  // Get current posts
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentDeals = filteredDeals.slice(indexOfFirstItem, indexOfLastItem);

  const filteredDealsList = currentDeals.filter(
    (item) => item.deal_status === 1
  );
  // Change page
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredDeals.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const singleDealsView = (id) => {
    dispatch(getSingleDealsPublicAction(id)).then((res) => {
      setSingleDeals(res.data);
      localStorage.setItem("singleDealsClient", JSON.stringify(res.data));
      navigate("/deals-listing/single-deals", {
        state: { singleDeals: res.data },
      });
    });
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const handleOpen = () => setOpenLoginPopup(true);
  const handleClose = () => setOpenLoginPopup(false);

  return (
    <>
      <UserHeader />
      <Helmet>
        <meta charset="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="description" content="" />
        <meta name="author" content="" />

        <title>Million Deals</title>
        {/* <!-- Linking the font Poppins--> */}
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />

        {/* <!-- Linking Bootstrap Icon--> */}
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css"
        />
      </Helmet>
      <ArrowButton />
      <div className="page-title">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h1>Listings</h1>
              <Link className="link" to="/">
                Home
              </Link>{" "}
              / Deals Listings
            </div>
          </div>
        </div>
      </div>

      <div className="listing-page">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-4">
              {!isMemAuth && (
                <div className="filter-single-notAuth">
                  <FormLabel
                    style={{
                      fontSize: "18px",
                      color: "black",
                      fontWeight: "700",
                    }}
                    component="legend"
                  >
                    Filter By Province
                  </FormLabel>
                  <FormGroup className="checkbox-label">
                    {provinceList.map((option) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={!isMemAuth}
                            size="8px"
                            checked={selectedProvince.includes(option.id)}
                            onChange={handleProvinceChange}
                            name={option.id}
                            value={option.id}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label={option.label}
                      />
                    ))}
                  </FormGroup>
                </div>
              )}
              {isMemAuth && (
                <div className="filter-single">
                  <FormLabel
                    style={{
                      fontSize: "18px",
                      color: "black",
                      fontWeight: "700",
                    }}
                    component="legend"
                  >
                    Filter By Province
                  </FormLabel>
                  <FormGroup className="checkbox-label">
                    {provinceList?.map((option) => (
                      <FormControlLabel
                        key={option.id}
                        control={
                          <Checkbox
                            disabled={!isMemAuth}
                            size="8px"
                            checked={selectedProvince.includes(option.id)}
                            onChange={handleProvinceChange}
                            name={option.id}
                            value={option.id}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label={option.label}
                      />
                    ))}
                  </FormGroup>
                </div>
              )}

              {isMemAuth && (
                <>
                  <div className="filter-single-sdgs">
                    <FormControl variant="standard" style={{ width: "98%" }}>
                      <InputLabel id="sdgs-label">Filter by SDGS</InputLabel>
                      <Select
                        disabled={!isMemAuth}
                        labelId="sdgs-label"
                        size="small"
                        multiple
                        value={selectedSdgs}
                        renderValue={(selected) => selected.join(", ")}
                        onChange={handleSdgsChange}
                        label="Filter by SDGS"
                        MenuProps={{
                          classes: { paper: "scroll-bar-sdgs" },
                          PaperProps: {
                            style: {
                              maxHeight: "300px",
                              width: "255px",
                              overflowX: "auto",
                            },
                          },
                        }}
                      >
                        {allSdgs.map((option) => (
                          <MenuItem key={option.sdg_id} value={option.sdg_id}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled={!isMemAuth}
                                  size="8px"
                                  checked={selectedSdgs.includes(option.sdg_id)}
                                />
                              }
                              label={
                                <ListItemText
                                  primary={
                                    <Typography
                                      variant="body2"
                                      style={{ fontSize: "14px" }}
                                    >
                                      {option.sdg_name}
                                    </Typography>
                                  }
                                />
                              }
                              htmlFor={`sdg-checkbox-${option.sdg_id}`}
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="filter-single-category">
                    <FormLabel
                      style={{
                        fontSize: "18px",
                        color: "black",
                        fontWeight: "700",
                      }}
                      component="legend"
                    >
                      Filter By Sector
                    </FormLabel>

                    <FormGroup className="checkbox-label">
                      {category?.map((option) => (
                        <FormControlLabel
                          key={option.deal_category_id}
                          control={
                            <Checkbox
                              disabled={!isMemAuth}
                              size="8px"
                              checked={selectedCategory.includes(
                                option.deal_category_id
                              )}
                              onChange={handleCategoryChange}
                              name={option.deal_category_id}
                              value={option.deal_category_id}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          }
                          label={option.deal_cat_name}
                        />
                      ))}
                    </FormGroup>
                  </div>
                  <div className="filter-single-amount">
                    <FormLabel
                      style={{
                        fontSize: "18px",
                        color: "black",
                        fontWeight: "700",
                      }}
                      component="legend"
                    >
                      Filter By Amount
                    </FormLabel>
                    <FormGroup className="checkbox-label">
                      {amountRanges?.map((option) => (
                        <FormControlLabel
                          key={option.amount}
                          control={
                            <Checkbox
                              disabled={!isMemAuth}
                              size="8px"
                              checked={amount.includes(option.amount)}
                              onChange={handleAmountChange}
                              name={option.amount}
                              value={option.amount}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          }
                          label={option.label}
                        />
                      ))}
                    </FormGroup>
                  </div>
                </>
              )}
            </div>

            <div className="col-lg-9 col-md-8">
              <h3>Business Deals</h3>
              {isMemAuth ? (
                <>
                  {loading ? (
                    <LoadingComp />
                  ) : (
                    <>
                      <div className="row">
                        {filteredDealsList.map((option) => (
                          <div
                            className="col-lg-4 col-md-6"
                            key={option.deal_id}
                          >
                            <div className="deal-single">
                              <Tooltip title={option.deal_title}>
                                <h2 style={{ cursor: "pointer" }}>
                                  {option.deal_title.length > 20
                                    ? option.deal_title?.slice(0, 20) + "..."
                                    : option.deal_title}
                                </h2>
                              </Tooltip>
                              <font className="blue-color">
                                <i className="bi bi-geo-alt-fill"></i>
                              </font>{" "}
                              {
                                provinceList.find(
                                  (province) =>
                                    province.id === option?.company_operate
                                )?.label
                              }
                              <br />
                              <font className="blue-color">
                                <i className="bi bi-stack"></i>
                              </font>{" "}
                              {option.deal_category}
                              <h3>Investment Range</h3>
                              {option.amount}
                              <br />
                              <br />
                              {!dataInvestorArray ? (
                                <button
                                  disabled={!dataInvestorArray}
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    singleDealsView(option.deal_id)
                                  }
                                  className="dark-btn link"
                                >
                                  For Verified Investor Only
                                </button>
                              ) : (
                                <button
                                  disabled={!dataInvestorArray}
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    singleDealsView(option.deal_id)
                                  }
                                  className="dark-btn link"
                                >
                                  More Information
                                </button>
                              )}
                            </div>
                          </div>
                        ))}
                        {filteredDealsList?.length > 0 ? (
                          <div className="pagination-container">
                            <div className="row pt-4 mt-4">
                              <div className="col-md-12 justify-content-center">
                                <nav aria-label="Page navigation example">
                                  <ul className="pagination justify-content-center">
                                    <li
                                      className={`page-item ${
                                        currentPage === 1 ? "disabled" : ""
                                      }`}
                                    >
                                      <Link
                                        className="page-link"
                                        tabIndex="-1"
                                        onClick={() =>
                                          setCurrentPage(currentPage - 1)
                                        }
                                      >
                                        {"<<"} Previous
                                      </Link>
                                    </li>
                                    {pageNumbers.map((pageNumber) => (
                                      <li
                                        key={pageNumber}
                                        className={`page-item ${
                                          currentPage === pageNumber
                                            ? "active"
                                            : ""
                                        }`}
                                      >
                                        <Link
                                          className="page-link"
                                          onClick={() =>
                                            setCurrentPage(pageNumber)
                                          }
                                        >
                                          {pageNumber}
                                        </Link>
                                      </li>
                                    ))}
                                    <li
                                      className={`page-item ${
                                        currentPage === pageNumbers.length
                                          ? "disabled"
                                          : ""
                                      }`}
                                    >
                                      <Link
                                        className="page-link"
                                        onClick={() =>
                                          currentPage !== pageNumbers.length
                                            ? setCurrentPage(currentPage + 1)
                                            : ""
                                        }
                                      >
                                        Next {">>"}
                                      </Link>
                                    </li>
                                  </ul>
                                </nav>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="col-md-12 justify-content-center">
                            <p style={{ color: "#f94848" }}>*No Record Found</p>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  <div className="col-md-12 justify-content-center">
                    <p style={{ color: "#f94848" }}>
                      *To view more deals and get their detailed information,
                      please login or signup first.
                    </p>
                  </div>
                  <div className="row">
                    {filteredDealsList.slice(0, 3).map((option) => (
                      <div className="col-lg-4 col-md-6" key={option.unique_id}>
                        <div className="deal-single_noauth">
                          <Tooltip title={option.deal_title}>
                            <h2 style={{ cursor: "pointer" }}>
                              {option.deal_title.length > 20
                                ? option.deal_title?.slice(0, 20) + "..."
                                : option.deal_title}
                            </h2>
                          </Tooltip>
                          <font className="blue-color">
                            <i className="bi bi-geo-alt-fill"></i>
                          </font>{" "}
                          {
                            provinceList.find(
                              (province) =>
                                province.id === option?.company_operate
                            )?.label
                          }
                          <br />
                          <font className="blue-color">
                            <i className="bi bi-stack"></i>
                          </font>{" "}
                          {option.deal_category}
                          <h3>Investment Range</h3>
                          {option.amount}
                          <br />
                          <br />
                          <button
                            onClick={handleOpen}
                            style={{ cursor: "pointer" }}
                            className="dark-btn link"
                          >
                            More Information
                          </button>
                          <ToastContainer
                            position="top-right"
                            autoClose={2000}
                            theme="colored"
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                  <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={openLoginPopup}
                    onClose={handleClose}
                    closeAfterTransition
                  >
                    <Fade in={openLoginPopup}>
                      <Box sx={style}>
                        <MainLoginComponent
                          selectedTab={selectedTab}
                          handleTabChange={handleTabChange}
                          handleClose={handleClose}
                        />
                      </Box>
                    </Fade>
                  </Modal>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <UserFooter />
    </>
  );
};

export default DealsListingPagePublic;
